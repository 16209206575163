import { Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { map, catchError, switchMap, exhaustMap } from 'rxjs/operators';

import ActionType from './types';
import { shiftSwapService } from '../../services';
import {
  ApplyToShiftSwapWithCounterAction,
  ApplyToShiftSwapWithCounterFulfilledAction,
  ApplyToShiftSwapWithCounterRejectedAction,
  CancelShiftSwapRequestAction,
  CancelShiftSwapRequestFulfilledAction,
  CancelShiftSwapRequestRejectedAction,
  CreateShiftSwapRequestAction,
  CreateShiftSwapRequestFulfilledAction,
  CreateShiftSwapRequestRejectedAction,
  GetShiftSwapsAction,
  GetShiftSwapsFulfilledAction,
  GetShiftSwapsRejectedAction,
  RequestShiftSwapAction,
  RequestShiftSwapFulfilledAction,
  RequestShiftSwapRejectedAction,
  UpdateShiftSwapApplicantStatusAction,
  UpdateShiftSwapApplicantStatusFulfilledAction,
  UpdateShiftSwapApplicantStatusRejectedAction
} from './actions';
import { GetPlanningAction } from '../schedule/actions';
import { UpdateApplicantStatus } from '../../models';

export const createShiftSwapRequestEpic$: Epic = action$ =>
  action$.ofType(ActionType.CreateShiftSwapRequest).pipe(
    switchMap(({ payload }: CreateShiftSwapRequestAction) =>
      from(shiftSwapService.createShiftSwapRequest(payload.date, payload.hasCounterSwap)).pipe(
        map(() => {
          payload.onSuccess?.();
          // Only send yyyy-MM-dd (10 chars)
          return new CreateShiftSwapRequestFulfilledAction({ date: payload.date });
        }),
        catchError(error => {
          payload.onError?.(error);
          return of(new CreateShiftSwapRequestRejectedAction({ error }));
        })
      )
    ),
  );

export const createShiftSwapRequestFulfilledEpic$: Epic = action$ =>
  action$.ofType(ActionType.CreateShiftSwapRequestFulfilled).pipe(
    exhaustMap(({ payload }: CancelShiftSwapRequestFulfilledAction) => {
      return of(new GetPlanningAction({ startDate: payload.date, endDate: payload.date }));
    })
  );

export const getShiftSwapsEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetShiftSwaps).pipe(
    switchMap(({ payload }: GetShiftSwapsAction) =>
      from(shiftSwapService.getShiftSwaps(payload.startDate, payload.endDate)).pipe(
        map((data) => new GetShiftSwapsFulfilledAction({ data, startDate: payload.startDate, endDate: payload.endDate })),
        catchError(error => of(new GetShiftSwapsRejectedAction({ error })))
      )
    ),
  );

export const cancelShiftSwapRequest$: Epic = action$ =>
  action$.ofType(ActionType.CancelShiftSwapRequest).pipe(
    switchMap(({ payload }: CancelShiftSwapRequestAction) =>
      from(shiftSwapService.cancelShiftSwapRequest(payload.id)).pipe(
        map(() => new CancelShiftSwapRequestFulfilledAction({ id: payload.id, date: payload.date })),
        catchError(error => {
          payload?.onError?.(error);
          return of(new CancelShiftSwapRequestRejectedAction({ error }));
        })
      )
    ),
  );

export const cancelShiftSwapRequestFulfilledEpic$: Epic = action$ =>
  action$.ofType(ActionType.CancelShiftSwapRequestFulfilled).pipe(
    exhaustMap(({ payload }: CancelShiftSwapRequestFulfilledAction) => {
      return of(new GetPlanningAction({ startDate: payload.date, endDate: payload.date }));
    })
  );

export const updateShiftSwapApplicantStatus$: Epic = action$ =>
  action$.ofType(ActionType.UpdateShiftSwapApplicantStatus).pipe(
    switchMap(({ payload }: UpdateShiftSwapApplicantStatusAction) =>
      from(shiftSwapService.updateShiftSwapApplicantStatus(payload.id, payload.type)).pipe(
        map(() => new UpdateShiftSwapApplicantStatusFulfilledAction({ id: payload.id, type: payload.type, date: payload.date })),
        catchError(error => {
          payload?.onError?.(error);
          return of(new UpdateShiftSwapApplicantStatusRejectedAction({ error }));
        })
      )
    ),
  );

export const applyToShiftSwapWithCounter$: Epic = action$ =>
  action$.ofType(ActionType.ApplyToShiftSwapWithCounter).pipe(
    switchMap(({ payload }: ApplyToShiftSwapWithCounterAction) =>
      from(shiftSwapService.applyToShiftSwapWithCounter(payload.id, payload.shifts)).pipe(
        map(() => {
          payload?.onSuccess?.();
          return new ApplyToShiftSwapWithCounterFulfilledAction({ id: payload.id, type: UpdateApplicantStatus.Apply, date: payload.date });
        }),
        catchError(error => {
          payload?.onError?.(error);
          return of(new ApplyToShiftSwapWithCounterRejectedAction({ error }));
        })
      )
    ),
  );

export const requestShiftSwapEpic$: Epic = action$ =>
  action$.ofType(ActionType.RequestShiftSwap).pipe(
    switchMap(({ payload }: RequestShiftSwapAction) =>
      from(shiftSwapService.requestShiftSwap(payload.id, payload.employmentId, payload.selectedCounterSwapDate)).pipe(
        map(() => {
          payload?.onSuccess?.();
          return new RequestShiftSwapFulfilledAction({ id: payload.id, employmentId: payload.employmentId });
        }),
        catchError(error => {
          payload?.onError?.(error);
          return of(new RequestShiftSwapRejectedAction({ error }));
        })
      )
    ),
  );

const epics = [
  applyToShiftSwapWithCounter$,
  cancelShiftSwapRequest$,
  cancelShiftSwapRequestFulfilledEpic$,
  createShiftSwapRequestEpic$,
  createShiftSwapRequestFulfilledEpic$,
  getShiftSwapsEpic$,
  requestShiftSwapEpic$,
  updateShiftSwapApplicantStatus$,
];

export default epics;
