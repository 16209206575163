import ActionType from './types';
import { Action } from 'redux';
import { IDateRange, IReducedShift, IShift } from '../../models';
import { IServerError } from '../../http/HttpError';

export class ToggleAdjustedShiftTimeModalAction implements Action<ActionType> {
  readonly type = ActionType.ToggleAdjustedShiftTimeModal;
  constructor(public payload?: { shift: IShift }) { }
}

export class CreateAdjustShiftTimeRequestAction implements Action<ActionType> {
  readonly type = ActionType.CreateAdjustedShiftTimeRequest;
  constructor(public payload?: { shift: IReducedShift, startDate: string, endDate: string, reason: string, onSuccess?: () => void; onError?: (error: IServerError) => void }) { }
}

export class CreateAdjustShiftTimeRequestFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.CreateAdjustedShiftTimeRequestFulfilled;
  constructor(public payload: { startDate: string, endDate: string }) { }
}

export class CreateAdjustShiftTimeRequestRejectedAction implements Action<ActionType> {
  readonly type = ActionType.CreateAdjustedShiftTimeRequestRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class CancelAdjustShiftTimeRequestAction implements Action<ActionType> {
  readonly type = ActionType.CancelAdjustedShiftTimeRequest;
  constructor(public payload: { shiftTimeAdjustmentId: string, startDate: string, endDate: string, onSuccess?: () => void, onError?: (error: IServerError) => void }) { }
}

export class CancelAdjustShiftTimeRequestFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.CancelAdjustedShiftTimeRequestFulfilled;
  constructor(public payload: { startDate: string, endDate: string }) { }
}

export class CancelAdjustShiftTimeRequestRejectedAction implements Action<ActionType> {
  readonly type = ActionType.CancelAdjustedShiftTimeRequestRejected;
  constructor(public payload: { error: IServerError }) { }
}

export type Actions =
  | ToggleAdjustedShiftTimeModalAction
  | CancelAdjustShiftTimeRequestAction
  | CancelAdjustShiftTimeRequestFulfilledAction
  | CancelAdjustShiftTimeRequestRejectedAction
  | CreateAdjustShiftTimeRequestAction
  | CreateAdjustShiftTimeRequestFulfilledAction
  | CreateAdjustShiftTimeRequestRejectedAction;
