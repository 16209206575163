/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Checkbox, InputDateField, InputDateType, InputField, InputType } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';

import RequestVacationFormBase from '../../../../../../shared/components/requestVacationForm/RequestVacationForm';
import { regexes } from '../../../../constants';
import { configSelectors } from '../../../../redux';
import { requestsActions, requestsSelectors } from '../../../../redux';
import { requestsUtils, translations } from '../../../../utils';
import Counters from '../../../requestInbox/components/counters/Counters';

import './requestVacation.scss';

const RequestVacationForm = (props) => {
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const companyConfig = useSelector(configSelectors.getCompanyConfig);
  const dispatch = useDispatch();
  const counters = useSelector(requestsSelectors.getVacationNewRequestCounters);
  const isCountersLoading = useSelector(requestsSelectors.isVacationNewRequestCountersLoading);

  const isValid = () => (forDate) => {
    return requestsUtils.isValidRequest({ requestPeriod: props.vacationPeriod, forDate });
  };

  return (
    <RequestVacationFormBase {...props}>
      {({ valueAllDay, valueFrom, setValueFrom, valueUntil, setValueUntil, valueTypeOfVacation, valueRemarks, toggleCheckbox }) => {
        const handleChangeFromTime = (date) => {
          setValueFrom(date);
          dispatch(new requestsActions.GetVacationRequestCountersAction({
            params: {
              startDate: `${date.date.toISOString().split('T')[0]}T${date.time}Z`,
              endDate: valueUntil.date.toISOString(),
              vacationTypeId: valueTypeOfVacation.value.toString(),
            },
          }));
        };

        const handleChangeUntilTime = (date) => {
          setValueUntil(date);
          dispatch(new requestsActions.GetVacationRequestCountersAction({
            params: {
              startDate: valueFrom.date.toISOString(),
              endDate: `${date.date.toISOString().split('T')[0]}T${date.time}Z`,
              vacationTypeId: valueTypeOfVacation.value.toString(),
            },
          }));
        };

        const handleChangeVacationType = (vacationType) => {
          valueTypeOfVacation.setValue(vacationType);
          dispatch(new requestsActions.GetVacationRequestCountersAction({
            params: {
              startDate: valueFrom.date.toISOString(),
              endDate: valueUntil.date.toISOString(),
              vacationTypeId: vacationType.toString(),
            },
          }));
        };

        const handleToggleCheckbox = () => {
          toggleCheckbox();
          dispatch(new requestsActions.GetVacationRequestCountersAction({
            params: {
              startDate: valueFrom.date.toISOString(),
              endDate: valueUntil.date.toISOString(),
              vacationTypeId: valueTypeOfVacation.value.toString(),
            },
          }));
        };

        return (
          <div className="request-vacation">
            {companyConfig.enableSpxcVacationRequests && <div>
              <Checkbox id="partOfDay" className="no-padding" disabled={!companyConfig.enableSpxcVacationRequests} isChecked={!valueAllDay} onCheck={handleToggleCheckbox} label={translations.getLabel('lblPartOfDay')} />
            </div>}
            <div>
              <InputDateField
                icon="CalendarIcon"
                error={!valueAllDay && !regexes.time.test(valueFrom.time)}
                filterDate={isValid()}
                label={translations.getLabel('lblFrom')}
                onChange={handleChangeFromTime}
                weekStartsOn={weekStartsOn}
                type={valueAllDay ? InputDateType.Date : InputDateType.DateTime}
                value={valueFrom}
                locale={translations.I18n.locale}
              />

              <InputDateField
                disabledDay={!valueAllDay}
                error={!valueAllDay && !regexes.time.test(valueUntil.time)}
                filterDate={isValid()}
                label={translations.getLabel('lblUntil')}
                onChange={handleChangeUntilTime}
                weekStartsOn={weekStartsOn}
                type={valueAllDay ? InputDateType.Date : InputDateType.DateTime}
                value={valueUntil}
                locale={translations.I18n.locale}
              />

              <InputField
                type={InputType.Select}
                options={valueAllDay ? props.vacationTypes.shift : props.vacationTypes.interval}
                value={valueTypeOfVacation.value}
                onChange={handleChangeVacationType}
                className="large-padding"
                icon="DashboardIcon"
                label={translations.getLabel('lblTypeOfVacation')}
                placeholder={translations.getLabel('lblPlaceholderVacationType')}
              />

              <InputField
                type={InputType.Text}
                value={valueRemarks.value}
                onChange={valueRemarks.setValue}
                className="large-padding"
                icon="ChatIcon"
                label={translations.getLabel('lblRemarks')}
              />

              {isCountersLoading && valueTypeOfVacation.value !== '' && <Skeleton height={24} />}
              {!isCountersLoading && counters?.data?.length && valueFrom && valueUntil && valueTypeOfVacation.value !== '' && <Counters counters={counters?.data} />}
            </div>
          </div>);
      }
      }
    </RequestVacationFormBase>
  );
};

RequestVacationForm.propTypes = RequestVacationFormBase.propTypes;
RequestVacationForm.defaultProps = RequestVacationFormBase.defaultProps;

export default RequestVacationForm;
