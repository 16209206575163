
import React, { useEffect, useRef, useState } from 'react';
import { Button, IconButton, Popup, Position } from 'react-ess-components';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { AppState } from '../../../../shared/redux/rootReducer';
import { magicNumbers, routes } from '../../constants';
import { useToggle } from '../../hooks';
import { TimeScale, Tools, UserRight, WeekStartsOn } from '../../models';
import { configSelectors, userSelectors } from '../../redux';
import { scheduleUtils, translations } from '../../utils';
import RangeSwitch from '../rangeSwitch/RangeSwitch';
import SchedulePageSwitch from '../schedulePageSwitch/SchedulePageSwitch';
import { ScheduleFilter } from './components/scheduleFilter/ScheduleFilter';
import ToolBarButton from './components/toolbarButton/ToolBarButton';

import './ToolBar.scss';

type Props = {
  changeDate: (date: Date) => void;
  changeDateRange: (timeScale: TimeScale) => void;
  dateRange: TimeScale;
  disabledFunctions: string[];
  isAvailabilityShown: boolean;
  isRequestsShown: boolean;
  onVacationRequest: () => void;
  onAdjustPeriod: (tool: string) => void;
  onAddShiftOffer: () => void;
  isSpxDown: boolean;
  selectedDate: Date;
  weekStartsOn: WeekStartsOn;
  toggleAvailabilities: () => void;
  toggleRequests: () => void;
  toggleSelfRoster: () => void;
}

const ToolBar = (props: Props) => {
  const companyConfig = useSelector(configSelectors.getCompanyConfig);
  const hasAvailabilitiesRequestRight = useSelector((state: AppState) => userSelectors.hasRight(state, UserRight.Availabilities));
  const hasVacationRequestRight = useSelector((state: AppState) => userSelectors.hasRight(state, UserRight.VacationRequests));
  const hasShiftTimeRight = useSelector((state: AppState) => userSelectors.hasRight(state, UserRight.AdjustedShiftTimes));
  const hasShiftOfferRight = useSelector((state: AppState) => userSelectors.hasRight(state, UserRight.ShiftOffer));
  const hasSelfRosterRight = useSelector((state: AppState) => userSelectors.hasRight(state, UserRight.SelfRoster));
  const isShiftTimeAdjustmentsEnabled = useSelector(configSelectors.isShiftTimeAdjustmentsEnabled);
  const isSelfRosteringEnabled = companyConfig?.modules?.selfRoster;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuVisible, toggleMenu] = useToggle(false);
  const [isPopupOpen, toggleIsPopupOpen] = useToggle(false);
  const modules = companyConfig?.modules;
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  const scrollListener = () => {
    if (window.scrollY > magicNumbers.DEFAULT_SCROLL_OFFSET) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const onSetActiveRange = (range: TimeScale) => {
    props.changeDateRange(range);
  };

  const changeDate = (modifier: number) => {
    props.changeDate(scheduleUtils.getModifiedDate(props.dateRange, props.selectedDate, modifier, props.weekStartsOn));
  };

  const setSpecificDate = (date: Date) => {
    props.changeDate(date);
  };

  const handleOnClickChangPeriod = () => {
    toggleIsPopupOpen(true);
  };

  const getAdjustPeriodsButton = () => {
    return (
      <div className="conditional-button">
        {
          (hasAvailabilitiesRequestRight || hasVacationRequestRight || hasShiftTimeRight) &&
          <ToolBarButton
            inverse
            id="change-period"
            icon="UnlockedIcon"
            disabled={!!props.disabledFunctions.find(f => f === Tools.Periods)}
            label={translations.getLabel('btnAdjustPeriod')}
            onClick={handleOnClickChangPeriod}
            isDropDown
          />
        }
        {
          (hasAvailabilitiesRequestRight || hasVacationRequestRight || hasShiftTimeRight) &&
          <Popup isDropdown position={Position.TopCenter} style={{ position: 'fixed', zIndex: 99, top: '12rem', minHeight: 0 }} open={isPopupOpen} requestClose={toggleIsPopupOpen}>
            <ul className="conditional-popup">
              {hasVacationRequestRight && <li><Button theme="transparent" onClick={() => props.onAdjustPeriod(Tools.Vacation)}>{translations.getLabel('lblVacation')}</Button></li>}
              {hasAvailabilitiesRequestRight && <li><Button theme="transparent" onClick={() => props.onAdjustPeriod(Tools.Availabilities)}>{translations.getLabel('titleAvailabilities')}</Button></li>}
              <li><Button theme="transparent" onClick={() => props.onAdjustPeriod(Tools.Schedule)}>{translations.getLabel('titleSchedule')}</Button></li>
              {(isShiftTimeAdjustmentsEnabled && hasShiftTimeRight) && <li><Button theme="transparent" onClick={() => props.onAdjustPeriod(Tools.ShiftTimeAdjustment)}>{translations.getLabel('titleShiftTimeAdjustment')}</Button></li>}
              {hasSelfRosterRight && modules?.selfRoster && <li><Button theme="transparent" onClick={() => props.toggleSelfRoster()}>{translations.getLabel('btnSelfRosterAdjustment')}</Button></li>}
            </ul>
          </Popup>
        }
      </div>
    );
  };

  const setRef = ref => container.current = ref;

  const className = classnames('toolbar', { scrolled: isScrolled });

  return (
    <div className={classnames('toolbar-background', { 'with-spx-error': props.isSpxDown, scrolled: isScrolled })} ref={setRef}>
      <div className={className}>
        <div className="content">
          <div className="toolbar-more">
            <IconButton large tag="PlusIcon" onClick={toggleMenu} color="white" title={translations.getLabel('lblMenu')} />
          </div>
          <div className="toolbar-container">
            <div>
              <ToolBarButton inverse id="open-availabilities" icon="FiltersIcon" disabled={!!props.disabledFunctions.find(f => f === Tools.Availabilities)} link={routes.scheduleAvailabilities} label={translations.getLabel('btnAvailability')} />
              <ToolBarButton inverse icon="CalendarIcon" id="request-leave" disabled={!!props.disabledFunctions.find(f => f === Tools.Vacation)} label={translations.getLabel('btnRequestVacation')} onClick={props.onVacationRequest} />
              {
                getAdjustPeriodsButton()
              }

              {isSelfRosteringEnabled && (
                <ToolBarButton inverse id="open-self-roster" icon="SelfRosteringIcon" disabled={!!props.disabledFunctions.find(f => f === Tools.SelfRoster)} link={routes.scheduleSelfRoster[0]} label={translations.getLabel('btnSelfRostering')} linkState={{ from: 'schedule' }} />
              )}
            </div>
            <div>
              {hasShiftOfferRight && companyConfig?.modules.shiftOffer && <ToolBarButton inverse id="change-period" icon="PlusIcon" disabled={!!props.disabledFunctions.find(f => f === Tools.ShiftOffers)} label={translations.getLabel('btnAddShiftOffer')} onClick={props.onAddShiftOffer} />}
            </div>
          </div>
          <div className="toolbar-filters">
            <div className="toolbar-spacer"/>
            <div className="toolbar-center-container">
              <ScheduleFilter isAvailabilityShown={props.isAvailabilityShown} toggleIsAvailabilityShown={props.toggleAvailabilities} isRequestsShown={props.isRequestsShown} toggleIsRequestsShown={props.toggleRequests} />
              <SchedulePageSwitch
                dateRange={props.dateRange}
                selectedDate={props.selectedDate}
                changeDate={changeDate}
                setSpecificDate={setSpecificDate}
              />
            </div>
            <RangeSwitch setActiveRange={onSetActiveRange} activeRange={props.dateRange} />
          </div>
        </div>
      </div>
      <Popup position={Position.RightBottom} style={{ position: 'fixed', bottom: '9rem', right: '2rem' }} open={isMenuVisible} requestClose={toggleMenu} excludePressArea={[container.current]}>
        <div className="tools">
          <ul>
            <li className="tool"><ToolBarButton normal id="open-availabilities" icon="FiltersIcon" disabled={!!props.disabledFunctions.find(f => f === Tools.Availabilities)} link={routes.scheduleAvailabilities} label={translations.getLabel('btnAvailability')} /></li>
            <li className="tool"><ToolBarButton normal icon="CalendarIcon" id="request-leave" disabled={!!props.disabledFunctions.find(f => f === Tools.Vacation)} label={translations.getLabel('btnRequestVacation')} onClick={props.onVacationRequest} /></li>
            {hasVacationRequestRight &&
              <li className="tool"><ToolBarButton normal id="change-period" icon="UnlockedIcon" disabled={!!props.disabledFunctions.find(f => f === Tools.Periods)} label={translations.getLabel('btnAdjustPeriodVacation')} onClick={() => props.onAdjustPeriod(Tools.Vacation)} /></li>}
            {hasAvailabilitiesRequestRight && <li className="tool">
              <ToolBarButton normal id="change-period" icon="UnlockedIcon" disabled={!!props.disabledFunctions.find(f => f === Tools.Periods)} label={translations.getLabel('btnAdjustPeriodAvailabilities')} onClick={() => props.onAdjustPeriod(Tools.Availabilities)} />
            </li>}
          </ul>
          {(hasShiftOfferRight && companyConfig?.modules.shiftOffer)
            && <li className="tool"><ToolBarButton normal id="change-period" icon="PlusIcon" disabled={!!props.disabledFunctions.find(f => f === Tools.ShiftOffers)} label={translations.getLabel('btnAddShiftOffer')} onClick={props.onAddShiftOffer} /></li>
          }
        </div>
      </Popup>
    </div>
  );
};

const noop = () => null;
ToolBar.defaultProps = {
  isAvailabilityShown: true,
  onVacationRequest: noop,
  onAdjustPeriod: noop,
  onAddShiftOffer: noop,
  disabledFunctions: [],
  hasShiftOfferRight: false,
};

export default ToolBar;
