import { Action } from 'redux';

import ActionType from './types';
import { IEmployee } from '../../models';
import { IServerError } from '../../http/HttpError';

export class GetEmployeeAction implements Action<ActionType> {
  readonly type = ActionType.GetEmployee;
  constructor(public payload: { id: string }) { }
}

export class GetEmployeeFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetEmployeeFulfilled;
  constructor(public payload: { data: IEmployee; }) { }
}

export class GetEmployeeRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetEmployeeRejected;
  constructor(public payload: { error: IServerError }) { }
}

export type Actions =
  | GetEmployeeAction
  | GetEmployeeFulfilledAction
  | GetEmployeeRejectedAction;