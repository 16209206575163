export enum ActionType {
  GetPlanning = 'GET_PLANNING',
  GetPlanningRejected = 'GET_PLANNING_REJECTED',
  GetPlanningFulfilled = 'GET_PLANNING_FULFILLED',

  GetPlanningToday = 'GET_TODAY',
  GetPlanningTodayRejected = 'GET_TODAY_REJECTED',
  GetPlanningTodayFulfilled = 'GET_TODAY_FULFILLED',

  GetUserPlanning = 'GET_USER_PLANNING',
  GetUserPlanningRejected = 'GET_USER_PLANNING_REJECTED',
  GetUserPlanningFulfilled = 'GET_USER_PLANNING_FULFILLED',

  GetUserPlanningExternalVacation = 'GET_USER_PLANNING_EXTERNAL_VACATION',
  GetUserPlanningExternalVacationRejected = 'GET_USER_PLANNING_EXTERNAL_VACATION_REJECTED',
  GetUserPlanningExternalVacationFulfilled = 'GET_USER_PLANNING_EXTERNAL_VACATION_FULFILLED',

  DeleteUserPlanningExternalVacation = 'DELETE_USER_PLANNING_EXTERNAL_VACATION',
  DeleteUserPlanningExternalVacationRejected = 'DELETE_USER_PLANNING_EXTERNAL_VACATION_REJECTED',
  DeleteUserPlanningExternalVacationFulfilled = 'DELETE_USER_PLANNING_EXTERNAL_VACATION_FULFILLED',

  GetDepartmentPlanning = 'GET_DEPARTMENT_PLANNING',
  GetDepartmentPlanningRejected = 'GET_DEPARTMENT_PLANNING_REJECTED',
  GetDepartmentPlanningFulfilled = 'GET_DEPARTMENT_PLANNING_FULFILLED',

  GetShiftOfferApplicantsPlanning = 'GET_SHIFT_OFFER_APPLICANTS_PLANNING',
  GetShiftOfferApplicantsPlanningRejected = 'GET_SHIFT_OFFER_APPLICANTS_PLANNING_REJECTED',
  GetShiftOfferApplicantsPlanningFulfilled = 'GET_SHIFT_OFFER_APPLICANTS_PLANNING_FULFILLED',

  SearchDepartmentPlanning = 'GET_DEPARTMENT_PLANNING_SEARCH',
  SearchDepartmentPlanningRejected = 'GET_DEPARTMENT_PLANNING_SEARCH_REJECTED',
  SearchDepartmentPlanningFulfilled = 'GET_DEPARTMENT_PLANNING_SEARCH_FULFILLED',

  SetSearchValue = 'SET_SEARCH_VALUE',

  GetShiftSwapApplicantsPlanning = 'GET_SHIFT_SWAP_APPLICANTS_PLANNING',
  GetShiftSwapApplicantsPlanningRejected = 'GET_SHIFT_SWAP_APPLICANTS_PLANNING_REJECTED',
  GetShiftSwapApplicantsPlanningFulfilled = 'GET_SHIFT_SWAP_APPLICANTS_PLANNING_FULFILLED',
}

export default ActionType;
