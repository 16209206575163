import React, { FC, useEffect, useRef, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { Button } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import classnames from 'classnames';

import { IMAGES } from '../../assets';
import { storageItems } from '../../constants';
import { configSelectors, forgotPasswordActions, loginActions } from '../../redux';
import { firebase, notificationUtils, translations } from '../../utils';

import './Login.scss';

interface State {
  token: string;
  isInit: boolean;
  isChangedDefault: boolean
}

const ResetPasswordView: FC<RouteComponentProps> = ({
  history,
}) => {
  const dispatch = useDispatch();
  const holdingPreferences = useSelector(configSelectors.getHoldingPreferences);

  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef(null);

  const location = useLocation();
  const state = location.state as State;
  const { token, isInit, isChangedDefault } = state;

  useEffect(() => {
    ref.current.focus();
  }, []);

  const onReset = async (event) => {
    event.preventDefault();

    const passwordsMatch = repeatPassword && password === repeatPassword;
    if (!passwordsMatch) {
      setHasError(true);
      setError(translations.getLabel('lblErrorNewPasswordsSame'));
      return false;
    }
    setIsLoading(true);

    await dispatch(new forgotPasswordActions.ResetPasswordAction({
      password: password,
      token: token,
      holding: holdingPreferences.name,
      shouldPersistSession: isChangedDefault,
      onError: (error) => {
        const errorMsg = error.detail || translations.getLabel('lblGenericError');
        setHasError(true);
        setIsLoading(false);
        setError(errorMsg);
      },
      onSuccess: () => {
        if (!isChangedDefault) {
          setIsSuccess(true);
        } else {
          history.push('/auth/employments');
        }
      },
    }));
  };

  const onChange = (event) => {
    setHasError(false);
    setError('');

    if (event.target.name === 'password') setPassword(event.target.value);
    if (event.target.name === 'repeatPassword') setRepeatPassword(event.target.value);
  };

  const handleLogout = () => {
    firebase.unRegister();
    dispatch(new loginActions.LogoutAction({
      onSuccess: () => {
        localStorage.setItem(storageItems.IS_LOGGED_IN, 'false');
        notificationUtils.removeNotificationInterval();
        setTimeout(() => {
          history.push('auth/login');
        });
      },
    }));
  };

  const goToLogin = () => {
    if (isChangedDefault) {
      handleLogout();
    } else {
      history.push('/auth/login');
    }
  };

  const className = classnames({ error: hasError });

  return (
    <DocumentTitle title={translations.getLabel('titleForgotPassword')}>
      <div className="login-view">
        <form className="form-container" onSubmit={onReset}>
          <img src={holdingPreferences?.logoUrl || IMAGES.LogoBackground} alt={holdingPreferences?.name} className="holding-logo" />
          <h1>{translations.getLabel('titleForgotPassword')}</h1>
          {isSuccess
            ? (
              <React.Fragment>
                <p className="paragraph">{translations.getLabel('lblResetPasswordSuccess')}</p>
                <div className="actions">
                  <Button type="button" onClick={goToLogin}>{translations.getLabel('btnLogin')}</Button>
                </div>
              </React.Fragment>
            )
            : (
              <React.Fragment>
                {
                  isInit ?
                    <p className="paragraph">{translations.getLabel('lblInitPassword')}</p>
                    :
                    isChangedDefault ?
                      <p className="paragraph">{translations.getLabel('lblChangePasswordFirstLogin')}</p>
                      :
                      <p className="paragraph">{translations.getLabel('lblResetPassword')}</p>
                }
                <input type="password" name="password" ref={ref} placeholder={translations.getLabel('lblPassword')} id="password" onChange={onChange} className={className} />
                <input type="password" name="repeatPassword" placeholder={translations.getLabel('lblRepeatPassword')} id="repeatPassword" onChange={onChange} className={className} />
                {
                  hasError &&
                  <p className="error-text">{error}</p>
                }
                <div className="actions">
                  <Button type="submit" disabled={!password || !repeatPassword || hasError} isLoading={isLoading} onClick={onReset}>{translations.getLabel('btnSend')}</Button>
                  <Button type="button" theme="transparent" onClick={goToLogin}>{translations.getLabel('cancel')}</Button>
                </div>
              </React.Fragment>
            )}

        </form>
        <aside style={{ backgroundColor: holdingPreferences.backgroundColor, backgroundImage: `url(${holdingPreferences.webStartpageImageUrl})` }} />
      </div>
    </DocumentTitle>
  );
};

export default ResetPasswordView;
