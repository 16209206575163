import React, { FC } from 'react';
import { IconButton } from 'react-ess-components';
import classnames from 'classnames';
import { parseISO } from 'date-fns';

import { regexes } from '../../../../constants';
import { IBaseInterval } from '../../../../models';
import { date, translations } from '../../../../utils';

export type ChangeInterval = (index: number, interval: IBaseInterval, isRemoved?: boolean) => void;

interface Props {
  interval: IBaseInterval;
  isEditable?: boolean;
  isRemovable?: boolean;
  changeInterval?: ChangeInterval;
  index?: number;
}

const Interval: FC<Props> = ({ isEditable, interval, changeInterval, index, isRemovable }) => {
  let timestamp = '';
  if (!isEditable) {
    const startDate = parseISO(interval.startDate);
    const endDate = parseISO(interval.endDate);
    timestamp = date.formatFromUntilTime(date.format, { startDate, endDate }) || `${interval.startDate}-${interval.endDate}`;
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedInterval = { ...interval, [event.target.name]: event.target.value };
    changeInterval(index, changedInterval);
  };

  return (
    <div className="interval">
      <p className={classnames('interval-timestamp', { editable: isEditable })}>
        {isEditable
          ? <>
            <input
              value={interval.startDate}
              onChange={onChange}
              className={classnames({ invalid: !regexes.time.test(interval.startDate) })}
              name="startDate" />
            <input
              value={interval.endDate}
              onChange={onChange}
              className={classnames({ invalid: !regexes.time.test(interval.endDate) })}
              name="endDate" />
          </>
          : timestamp
        }
      </p>
      {!!interval.timeType && <p>{interval.timeType}</p>}
      {isEditable && <IconButton disabled={!isRemovable} color="error" tag="ClearIcon" onClick={() => changeInterval(index, null, true)} title={translations.getLabel('lblClear')} />}
    </div>
  );
};

export default Interval;
