import React, { FC } from 'react';
import { Orb } from 'react-ess-components';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { availabilitiesSelectors } from '../../../../redux';
import { date, scheduleUtils,translations } from '../../../../utils';

interface Props {
  currentDate: Date;
}

const Footer: FC<Props> = ({ currentDate }) => {
  const timeTypes = useSelector(availabilitiesSelectors.getVisibleTimeTypes);
  const availabilities = useSelector((state: AppState) => availabilitiesSelectors.getAvailabilitiesForDay(state, currentDate));

  const className = classnames('availability-row');
  return (
    <div className={className}>
      {!!availabilities && scheduleUtils.getAvailabilityTypesAndDates(availabilities, timeTypes, (color, code, start, end, i) =>
      (<div className="availability" key={`orbs${i}`}>
        <Orb color={color} filled small />
        <p className="">{
          `${translations.getLabel('lblCodeFromUntil', { code, from: date.format(start, 'HH:mm'), until: date.format(end, 'HH:mm') })}`
        }</p>
      </div>)
      )}
    </div>
  );
};

export default Footer;
