import React, { FC } from 'react';
import { Icon } from 'react-ess-components';
import classNames from 'classnames';

import { translations } from '../../../../utils';

import './overstaffedWarning.scss';

type Props = {
  label?: string;
  disabled?: boolean;
}

const OverstaffedWarning: FC<Props> = ({ label = 'lblOverstaffed', disabled = false }) => {
  return (
    <span className={classNames('overstaffed-warning', { disabled })}>
      <Icon small tag="Alert" color={disabled ? 'gray' : 'error'} /> {translations.getLabel(label)}
    </span>
  );
};

export default OverstaffedWarning;
