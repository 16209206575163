import React, { FC, useEffect, useState } from 'react';
import { Button, Icon, Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { parseISO } from 'date-fns';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { SchedulePageSwitch } from '../../../../components';
import { identifiers } from '../../../../constants';
import { useToggle } from '../../../../hooks';
import { TimeScale } from '../../../../models';
import { configSelectors, requestsActions, requestsSelectors, userSelectors } from '../../../../redux';
import { date, requestsUtils, scheduleUtils, translations } from '../../../../utils';
import DayWeekSchedule from '../../../schedule/DayWeekSchedule';
import Counters from '../counters/Counters';
import Footer from './RequestFooter';

interface Props {
  id: string;
  isLoading: boolean;
  departmentId?: string;
}

const RequestDetail: FC<Props> = ({ id, isLoading, departmentId }) => {
  const dispatch = useDispatch();
  const [isRosterOpen, toggleRosterOpen] = useToggle(false);
  const [rosterEmployeeId, setRosterEmployeeId] = useState<string>('');
  const request = useSelector((state: AppState) => requestsSelectors.getDepartmentVacationRequest(state, id));
  const isLoadingDetail = useSelector(requestsSelectors.isVacationRequestLoading);
  const counterDetail = useSelector((state: AppState) => requestsSelectors.getVacationRequestCounters(state, id));
  const user = useSelector(userSelectors.getUser);
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const isAllDay = useSelector((state: AppState) => requestsSelectors.isAllDay(state, id));
  const isOneDay = useSelector((state: AppState) => requestsSelectors.isOneDay(state, id));
  const [selectedDate, setSelectedDate] = useState<Date>(null);

  const relativeCreatedDate = requestsUtils.getRelativeDate(request?.createdAt, date.format);

  useEffect(() => {
    if (id && !request) {
      dispatch(new requestsActions.GetDepartmentVacationRequestAction({
        id,
        departmentId,
        onError: (error) => {
          toast.error(error.detail);
        },
      }));
    }
  }, [id]);

  if (!request && !isLoading) return <section className="request-detail empty">{translations.getLabel('lblEmpyRequestInbox')}</section>;

  const startDate = parseISO(request?.startDate);
  const endDate = parseISO(request?.endDate);

  const openSchedule = () => {
    setSelectedDate(null);
    // replace employeeId with 'me'
    setRosterEmployeeId(user.employeeId === request.employeeId ? identifiers.me : request.employeeId);
    toggleRosterOpen();
  };

  const requestSelectedDate = selectedDate || startDate;
  return (
    <div className="request-detail department-request-detail">
      <div>
        <p>{isLoading ? <Skeleton width={300} /> : (relativeCreatedDate.shouldTranslate ? `${translations.getLabel(relativeCreatedDate.value)} ${relativeCreatedDate.time}` : `${relativeCreatedDate.value}`)}</p>
        <p className="title">{isLoading ? <Skeleton width={400} /> : translations.getLabel('titleDepartmentVacationRequestDetail')}</p>
        <p className="sub-title">{isLoading ? <Skeleton width={200} /> : translations.getLabel('lblBy')} {request?.requesterName}</p>
        <div className="request-message-container">
          {isLoading ? <Skeleton count={3} width={500} /> : (
            <p><Icon className="icon" tag="ChatIcon" color="primaryDark" />{!!request.requesterNote ? `${translations.getLabel('lblRequestReason')}: ${request.requesterNote}` : translations.getLabel('lblNoRequestReason')}</p>
          )}
        </div>
        <div className="request-date-information">
          <Icon tag="CalendarIcon" color="primaryDark" />
          <p>{isLoading ? <Skeleton width={100} /> : (isOneDay ? date.format(startDate, 'eee dd LLL') : `${date.format(startDate, 'eee dd LLL')} - ${date.format(endDate, 'eee dd LLL')}`)}</p>
          <Icon tag="ClockIcon" color="primaryDark" />
          <p>{isLoading ? <Skeleton width={100} /> : (isAllDay ? `${translations.getLabel('lblAllDay')}` : date.formatFromUntilTime(date.format, { startDate: startDate, endDate: endDate }))}</p>
          <Icon tag="DashboardIcon" color="primaryDark" />
          <p>{isLoading ? <Skeleton width={100} /> : request?.vacationType}</p>
        </div>
      </div>
      {!isLoading && <div className="request-schedule-action">
        <Button onClick={openSchedule}><Icon tag="CalendarIcon" color="white" /> {translations.getLabel('btnWatchInRoster')}</Button>
      </div>}
      <div className="hr" />
      {(!isLoadingDetail && counterDetail && counterDetail?.length) &&
        <Counters counters={counterDetail} />
      }
      {isLoadingDetail &&
        <Skeleton height={24} />
      }
      {request &&
        <div className="footer">
          <Footer id={id} departmentId={departmentId} />
        </div>
      }
      {isRosterOpen && <Modal open title="-" requestClose={toggleRosterOpen}>
        <div className="schedule-toolbar">
          <SchedulePageSwitch
            dateRange={TimeScale.Week}
            selectedDate={requestSelectedDate}
            changeDate={(modifier: number) => setSelectedDate(scheduleUtils.getModifiedDate(TimeScale.Week, requestSelectedDate, modifier, weekStartsOn))}
            setSpecificDate={(date: Date) => setSelectedDate(date)}
          />
        </div>
        <DayWeekSchedule
          departmentId={user.departmentId === departmentId ? undefined : departmentId}
          mainUserId={rosterEmployeeId}
          selectedDate={requestSelectedDate}
          hideShiftOffers
          showAvailabilities={false}
          timeScale={TimeScale.Week}
          highlightRange={{ startDate, endDate }} />
      </Modal>}
    </div>
  );
};

export default RequestDetail;
