import React, { useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import { NotificationItem } from '../../components';
import { useInfiniteScroll } from '../../hooks';
import { NotificationTrigger } from '../../models';
import { notificationsActions, notificationsSelectors, userSelectors } from '../../redux';
import { date, notificationUtils, translations } from '../../utils';

import './notifications.scss';

const Notifications = () => {
  const departmentId = useSelector(userSelectors.getDepartmentId);
  const notifications = useSelector(notificationsSelectors.notifications);
  const metadata = useSelector(notificationsSelectors.metadata);
  const isNotificationsLoading = useSelector(notificationsSelectors.isNotificationsLoading);

  useInfiniteScroll((offset: number) => dispatch(new notificationsActions.LoadMoreNotificationsAction({ offset })), metadata, isNotificationsLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    const urlParams = queryString.parse(window.location.search);
    if (urlParams?.redirect) {
      notificationUtils.goToNotification(urlParams.employmentId as string, urlParams.type as NotificationTrigger, urlParams.identifier as string);
      return;
    }
  }, []);

  const renderNotification = notification => {
    return <NotificationItem
      isSameDepartment={notification.departmentId === null || departmentId === notification.departmentId}
      markAsRead={() => dispatch(new notificationsActions.MarkAsReadAction({ id: notification.id }))}
      key={notification.id}
      {...notification}
      format={date.format}
      getLabel={translations.getLabel}
    />;
  };


  return (
    <DocumentTitle title={translations.getLabel('lblNotifications')}>
      <div className="page notifications-view-container fixed-width">
        <h3 className="notifications-title">{translations.getLabel('lblNotifications')}</h3>
        {notifications.map(renderNotification)}
      </div>
    </DocumentTitle>
  );
};

export default Notifications;
