import React from 'react';
import { Avatar, Icon,Orb } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';

import ScheduleTodayBase, { mapDispatchToProps,mapStateToProps } from '../../../../../shared/components/scheduleToday/ScheduleToday.container';
import { date, translations } from '../../../utils';

import './scheduleToday.scss';

export class ScheduleToday extends ScheduleTodayBase {
  renderShift = (shift) => {
    const color = this.getColor(shift);
    const isLoading = this.props.isLoading;
    return (
      <div className="shift-row" key={shift.shiftName}>
        {this.props.hasPlanningTodayError ? <p className="schedule-empty">{translations.getLabel('lblErrScheduleToday')}
        </p> : <>
            <div className="shift-row-details">
              <div className="shift-name">
                {isLoading ? <Skeleton height="1.2rem" width="1.2rem" /> : <Orb color={color} />}
                <p >{isLoading ? <Skeleton /> : shift.shiftName}</p>
              </div>
              <div className="shift-time">
                <p>{isLoading ? <Skeleton /> : shift.department} {shift.departmentSwitch && <Icon tag="SwitchIcon" customSize={1.2} color="textGray" />}</p>
                <p>{isLoading ? <Skeleton /> : this.formatTime(date.format, shift.startDate, shift.endDate)}</p>
                <p>{isLoading ? <Skeleton /> : shift.function}</p>
              </div>
            </div>
            <div className="shift-row-intervals">
              {isLoading ? this.SKELETON_INTERVALS.map(this.renderInterval) : shift.intervals.map(this.renderInterval)}
            </div>
          </>}
      </div>
    );
  }

  renderInterval = (interval, i) => {
    return (
      <p className="interval-row" key={`interval-${i}`}>
        {this.props.isLoading
          ? <Skeleton width={300} height="1.6rem" />
          :
          <React.Fragment>
            <span className="interval-time">{this.formatTime(date.format, interval.startDate, interval.endDate)}</span>
            {interval.timeType}
          </React.Fragment>
        }
      </p>
    );
  }

  render() {
    return (
      <div className="schedule-today">
        <aside className="personal-details">
          <Avatar border source={this.props.user.profilePictureUrl} firstName={this.props.user.firstName} lastName={this.props.user.lastName} large />
          <div className="personal-name">
            {this.props.user.firstName}<br />
            <span>{this.props.user.lastName}</span>
          </div>
        </aside>
        <section className="schedule-shifts">
          <h2>{translations.getLabel('titleMyScheduleToday')}</h2>
          {this.props.isLoading && this.renderShift({})}
          {!this.props.isLoading && this.props.schedule.length > 0 && this.props.schedule[0].shifts && this.props.schedule[0].shifts.length > 0 &&
            this.props.schedule[0].shifts.map(this.renderShift)
          }
          {!this.props.isLoading && (!this.props.schedule.length > 0 || !this.props.schedule[0].shifts || !this.props.schedule[0].shifts.length > 0) &&
            <div className="schedule-empty">{translations.getLabel('lblEmptyScheduleForToday')}</div>
          }
        </section>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleToday);
