import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { Button } from 'react-ess-components';
import PropTypes from 'prop-types';

import { EmploymentSelect, Loader } from '../../components';
import { storageItems } from '../../constants';
import { loginActions } from '../../redux';
import { translations } from '../../utils';

import './Login.scss';

class Employments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }
  componentDidMount() {
    if (this.props.employments?.length) {
      this.initEmployments();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.employments?.length && this.props.employments?.length) {
      this.initEmployments();
    }
  }

  initEmployments = () => {
    if (this.props.employments.length === 1) {
      this.switchEmployment(this.props.employments[0].id);
    } else {
      this.setState({ isLoading: false });
    }
  }

  switchEmployment = async (id) => {
    this.setState({ isLoading: true });
    this.props.dispatch(new loginActions.UpdateEmploymentAction({
      id,
      onSuccess: () => {
        this.onSwitchSuccess();
      },
    }));
  };

  onSwitchSuccess = async () => {
    window.location.pathname = '/';
  }

  handleLogout = async () => {
    this.props.dispatch(new loginActions.LogoutAction());
    localStorage.removeItem(storageItems.IS_LOGGED_IN);
    return this.props.history.push('/');
  }

  render() {
    return (
      <DocumentTitle title={translations.getLabel('titleEmployment')}>
        <div className="login-view">
          <div className="form-container employment-view">
            {
              this.state.isLoading
                ?
                <div className="loader-container">
                  <Loader />
                </div>
                :
                <React.Fragment>
                  <h1>{translations.getLabel('titleEmployment')}</h1>
                  <EmploymentSelect employments={this.props.employments} switchEmployment={this.switchEmployment} />
                  <div className="actions">
                    <Button type="button" theme="inverse" onClick={this.handleLogout}>{translations.getLabel('btnLogout')}</Button>
                  </div>
                </React.Fragment>
            }
          </div>
          <aside style={{ backgroundColor: this.props.holdingPreferences.backgroundColor, backgroundImage: `url(${this.props.holdingPreferences.webStartpageImageUrl})` }} />
        </div>
      </DocumentTitle>
    );
  }
}

Employments.propTypes = {
  employments: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  holdingPreferences: PropTypes.object.isRequired,
};

export default Employments;
