import { MutateOptions, useMutation, useQueryClient } from 'react-query';

import { HttpClient } from '../../http';
import { IServerError } from '../../http/HttpError';
import { IPostSelfRosterIteration } from '../../models';
import { selfRosterQueryKeys } from './queryKeys';

const putSelfRosterIterations = async (departmentId: string, body: IPostSelfRosterIteration[]): Promise<void> => {
  return await HttpClient.put<void>(`/v1/management/departments/${departmentId}/self-roster-iterations`, { selfRosterIterations: body });
};

export const useSaveSelfRosterIterations = (departmentId: string, { onSuccess, onError }: MutateOptions<void, IServerError, IPostSelfRosterIteration[]>) => {
  const queryClient = useQueryClient();
  return useMutation<void, IServerError, IPostSelfRosterIteration[]>(selfRosterQueryKeys.saveSchedule(), (body) => putSelfRosterIterations(departmentId, body), {
    onError,
    onSuccess: (data, variables, context) => {
      queryClient.resetQueries(selfRosterQueryKeys.all);
      onSuccess(data, variables, context);
    },
  });
};
