import React from 'react';
import { Icon } from 'react-ess-components';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { RequestStatus } from '../../../../models';
import { requestsSelectors } from '../../../../redux';
import { date,requestsUtils, translations } from '../../../../utils';
import CancelButton from './CancelButton';

interface Props {
  id: string;
}

const Footer = ({ id }: Props) => {
  const request = useSelector((state: AppState) => requestsSelectors.getVacationRequest(state, id));

  const className = classNames('status', {
    accepted: request.status === RequestStatus.Accepted,
    denied: request.status === RequestStatus.Denied,
  });

  const renderCommentSection = () => {
    const updatedAt = requestsUtils.getRelativeDate(request.updatedAt, date.format);
    let startDate = updatedAt.value;
    if (updatedAt.shouldTranslate) {
      startDate = `${translations.getLabel(updatedAt.value)} ${updatedAt.time}`;
    }

    return (
      <>
        <p> {translations.getLabel('lblRequestUpdateMessage', { startDate, updater: request.reviewerName })}</p>
        {!!request.reviewerNote && <p>{translations.getLabel('lblRequestComment', { comment: request.reviewerNote })}</p>}
      </>
    );
  };
  if (request.status === RequestStatus.Open) {
    return (
      <CancelButton requestId={id} />
    );
  }

  if (request.status === RequestStatus.Denied) {
    return (
      <>
        <div className={className}>
          <Icon tag="CrossIcon" small color="error" />
          <p>{requestsUtils.getRequestStatus(request.status, translations.getLabel)}</p>
        </div>
        {renderCommentSection()}
      </>
    );
  }

  if (request.status === RequestStatus.Accepted) {
    return (
      <>
        <div className={className}>
          <Icon tag="CheckIcon" small color="success" />
          <p>{requestsUtils.getRequestStatus(request.status, translations.getLabel)}</p>
        </div>
        {renderCommentSection()}
      </>
    );
  }
};

export default Footer;
