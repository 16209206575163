import React, { PureComponent } from 'react';
import { Button, InputField, InputType } from 'react-ess-components';
import { addWeeks, differenceInCalendarWeeks, endOfWeek, getWeek, isBefore, isSameWeek, startOfWeek } from 'date-fns';
import PropTypes from 'prop-types';

import { date, translations } from '../../../../utils';

export default class CopyWeek extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isCopyToThisWeek: true,
      date: addWeeks(this.props.startDate, 1),

    };
  }

  toggleRadios = () => {
    this.setState({ isCopyToThisWeek: !this.state.isCopyToThisWeek });
  };

  getWeekNumber = (modifier = 0) => getWeek(addWeeks(date.parseDate(this.props.firstWeekDate), modifier), { weekStartsOn: this.props.weekStartsOn, firstWeekContainsDate: 7 });
  getWeekDate = (modifier = 0) => addWeeks(date.parseDate(this.props.firstWeekDate), modifier).toISOString();

  copy = () => {
    this.props.copy(
      this.state.isCopyToThisWeek
        ? addWeeks(date.parseDate(this.props.startDate), -1)
        : this.props.startDate,
      this.state.isCopyToThisWeek ? this.props.startDate : this.state.date
    );
  };

  setDate = (e) => {
    this.setState({
      date: date.parseDate(e),
    });
  };

  options = () => {
    const weeks = differenceInCalendarWeeks(this.props.lastWeekDate, this.props.firstWeekDate, { weekStartsOn: this.props.weekStartsOn });
    const data = [];

    for (let i = 0; i <= weeks; i++) {
      const endOfThisWeek = endOfWeek(addWeeks(this.props.firstWeekDate, i), { weekStartsOn: this.props.weekStartsOn });
      if (isBefore(new Date(), endOfThisWeek) && !isSameWeek(this.props.startDate, endOfThisWeek, { weekStartsOn: this.props.weekStartsOn })) {
        data.push({
          value: this.getWeekDate(i),
          label: `${translations.getLabel('btnWeek')}
         ${this.getWeekNumber(i)}
          (${date.format(startOfWeek(addWeeks(this.props.firstWeekDate, i),
            { weekStartsOn: this.props.weekStartsOn }), 'd MMM')}
           -
           ${date.format(endOfThisWeek, 'd MMM')})`,
        });
      }
    }

    return data;
  }

  render() {
    return (
      <div className="copy-week-container">
        <h4>{translations.getLabel('titleCopyWeek')}</h4>
        <div className="input-group">
          <label>
            <input
              type="radio"
              checked={this.state.isCopyToThisWeek}
              onChange={this.toggleRadios}
            />
            {translations.getLabel('lblCopyLastWeek', {
              from: this.getWeekNumber(-1),
              until: this.getWeekNumber(0),
            })}
          </label>
        </div>
        <div className="input-group">
          <label className="inputfield-label">
            <input
              type="radio"
              checked={!this.state.isCopyToThisWeek}
              onChange={this.toggleRadios}
            />
            {translations.getLabel('lblCopyWeekTo', {
              from: this.getWeekNumber(0),
            })}
          </label>
          <InputField
            disabled={this.state.isCopyToThisWeek}
            emptyIcon
            type={InputType.Select}
            options={this.options()}
            onChange={this.setDate}
            value={this.state.date.toISOString()}
          />
        </div>
        <div className="buttons">
          <Button theme="inverse" onClick={this.props.close}>
            {translations.getLabel('cancel')}
          </Button>
          <Button onClick={this.copy}>
            {translations.getLabel('copy')}
          </Button>
        </div>
      </div>
    );
  }
}

CopyWeek.propTypes = {
  firstWeekDate: PropTypes.instanceOf(Date).isRequired,
  lastWeekDate: PropTypes.instanceOf(Date).isRequired,
  startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  close: PropTypes.func.isRequired,
  copy: PropTypes.func.isRequired,
  firstWeek: PropTypes.number.isRequired,
  lastWeek: PropTypes.number.isRequired,
  weekStartsOn: PropTypes.number.isRequired,
};
