import React, { FC, useEffect, useState } from 'react';
import { Icon, Modal, Switch } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useToggle } from '../../hooks';
import { INotificationPreference, NotificationType } from '../../models';
import { configSelectors, userActions, userSelectors } from '../../redux';
import { colors, firebase, getPreference, notificationUtils, translations } from '../../utils';
import { Widget } from '..';

import './NotificationPreferences.scss';

const preferenceValue = (preference: INotificationPreference): string => {
  if (preference.email && preference.browser) return `${translations.getLabel('email')}, ${translations.getLabel('browser')}`;
  if (preference.email) return translations.getLabel('email');
  if (preference.browser) return translations.getLabel('browser');
  return translations.getLabel('off');
};

const renderPreference = (preference: INotificationPreference, preferenceLabel: string, isEditMode = false, onSwitch?: (pref: INotificationPreference, isEmail: boolean, isBrowser: boolean) => void): JSX.Element => {
  if (preference) {
    return (
      <div className="notification-preference">
        <p className="preference-label">{preferenceLabel}</p>
        {isEditMode ? (
          <>
            <div className="preference-value">
              <Switch
                label={preferenceLabel + translations.getLabel('email')}
                onChange={() => onSwitch(preference, !preference.email, preference.browser)}
                checked={preference.email} onColor={colors.success}
                uncheckedIcon={false}
                checkedIcon={false}
                height={28}
                width={51}
              />
            </div>
            <div className="preference-value">
              <Switch
                label={preferenceLabel + translations.getLabel('browser')}
                onChange={() => onSwitch(preference, preference.email, !preference.browser)}
                checked={preference.browser}
                onColor={colors.success}
                uncheckedIcon={false}
                checkedIcon={false}
                height={28}
                width={51}
              />
            </div>
          </>
        ) : <p className="notification-preference-value">{preferenceValue(preference)}</p>}
      </div>
    );
  }
  return null;
};

export const NotificationPreferences: FC = () => {
  const dispatch = useDispatch();
  const [isModalOpen, toggleModal] = useToggle(false);
  const notificationPreferences = useSelector(userSelectors.getNotificationPreferences);
  const isNotificationPreferencesLoading = useSelector(userSelectors.isNotificationPreferencesLoading);
  const companyConfig = useSelector(configSelectors.getCompanyConfig);
  const [preferences, setPreferences] = useState<INotificationPreference[]>(notificationPreferences);

  useEffect(() => {
    dispatch(new userActions.GetNotificationPreferencesAction());
  }, []);

  useEffect(() => {
    setPreferences(notificationPreferences);
  }, [notificationPreferences.length]);

  const handleOnChangeSwitch = (pref: INotificationPreference, isEmail: boolean, isBrowser: boolean) => {
    const updatedPreferences = preferences.reduce((accu: INotificationPreference[], preference: INotificationPreference) => {
      if (preference.trigger === pref.trigger) {
        return [...accu, { ...preference, email: isEmail, browser: isBrowser }];
      }
      return [...accu, preference];
    }, []);
    setPreferences(updatedPreferences);
  };

  const savePreferences = async () => {
    try {
      await notificationUtils.checkBrowserNotifications(preferences);
    } catch (err) {
      toast.error(err.message);
    }
    dispatch(new userActions.UpdateNotificationPreferencesAction({
      preferences,
      onError: (error) => {
        toast.error(translations.getLabel(error.detail));
        setPreferences(notificationPreferences);
      },
    }));

    toggleModal();
  };

  const closeModal = () => {
    toggleModal();
    setPreferences(notificationPreferences);
  };

  const getPreferences = (prefs: INotificationPreference[]) => {
    const modules = companyConfig?.modules;
    return {
      changeOpenShift: modules?.schedule ? getPreference(prefs, NotificationType.OpenShift) : false,
      changeSchedule: modules?.schedule ? getPreference(prefs, NotificationType.UserSchedule) : false,
      changeSelfRoster: modules?.selfRoster ? getPreference(prefs, NotificationType.SelfRoster) : false,
      changeShiftOffer: modules?.shiftOffer ? getPreference(prefs, NotificationType.ShiftOffer) : false,
      changeShiftSwap: modules?.shiftSwap ? getPreference(prefs, NotificationType.ShiftSwap) : false,
      changeVacation: modules?.schedule ? getPreference(prefs, NotificationType.VacationRequest) : false,
      newChatMessage: getPreference(prefs, NotificationType.ChatMessage),
      newDocument: modules?.library ? getPreference(prefs, NotificationType.Document) : false,
      newNewsItem: modules?.news ? getPreference(prefs, NotificationType.NewsArticle) : false,
    };
  };

  const localPreferences = getPreferences(preferences);
  const savedPreferences = getPreferences(notificationPreferences);

  return (
    <Widget title={translations.getLabel('titleNotificationPreferences')} left buttonLabel={translations.getLabel('btnEdit')} onClickMore={toggleModal}>
      <>
        <div className="notification-preferences-container">
          {isNotificationPreferencesLoading && !preferences?.length && <Skeleton count={5} height={22} />}
          {savedPreferences.changeSchedule && renderPreference(savedPreferences.changeSchedule, translations.getLabel('lblChangeUserSchedule'))}
          {savedPreferences.changeVacation && renderPreference(savedPreferences.changeVacation, translations.getLabel('lblStatusChangeVacation'))}
          {savedPreferences.newChatMessage && renderPreference(savedPreferences.newChatMessage, translations.getLabel('lblNewChatMessage'))}
          {savedPreferences.newDocument && renderPreference(savedPreferences.newDocument, translations.getLabel('lblNewDocument'))}
          {savedPreferences.newNewsItem && renderPreference(savedPreferences.newNewsItem, translations.getLabel('lblNewNewsItem'))}
          {savedPreferences.changeShiftOffer && renderPreference(savedPreferences.changeShiftOffer, translations.getLabel('lblStatusChangeShiftOffer'))}
          {savedPreferences.changeShiftSwap && renderPreference(savedPreferences.changeShiftSwap, translations.getLabel('lblStatusChangeShiftSwap'))}
          {savedPreferences.changeSelfRoster && renderPreference(savedPreferences.changeSelfRoster, translations.getLabel('lblStatusChangeSelfRoster'))}
        </div>
        <Modal
          open={isModalOpen}
          requestClose={closeModal}
          title={translations.getLabel('titleNotificationPreferences')}
          rightButtonProps={{ label: translations.getLabel('save'), onClick: savePreferences }}
          leftButtonProps={{ label: translations.getLabel('cancel'), onClick: closeModal }}
        >
          <div className="notification-modal-content-container">
            <p className="notification-label">{translations.getLabel('lblWantToReceiveNotification')}</p>
            <div className="notification-preference">
              <p className="preference-value">{translations.getLabel('email')}</p>
              <p className="preference-value">{translations.getLabel('browser')}*</p>
            </div>
            {localPreferences.changeSchedule && renderPreference(localPreferences.changeSchedule, translations.getLabel('lblChangeUserSchedule'), true, handleOnChangeSwitch)}
            {localPreferences.changeVacation && renderPreference(localPreferences.changeVacation, translations.getLabel('lblStatusChangeVacation'), true, handleOnChangeSwitch)}
            {localPreferences.newChatMessage && renderPreference(localPreferences.newChatMessage, translations.getLabel('lblNewChatMessage'), true, handleOnChangeSwitch)}
            {localPreferences.newDocument && renderPreference(localPreferences.newDocument, translations.getLabel('lblNewDocument'), true, handleOnChangeSwitch)}
            {localPreferences.newNewsItem && renderPreference(localPreferences.newNewsItem, translations.getLabel('lblNewNewsItem'), true, handleOnChangeSwitch)}
            {localPreferences.changeShiftOffer && renderPreference(localPreferences.changeShiftOffer, translations.getLabel('lblStatusChangeShiftOffer'), true, handleOnChangeSwitch)}
            {localPreferences.changeShiftSwap && renderPreference(localPreferences.changeShiftSwap, translations.getLabel('lblStatusChangeShiftSwap'), true, handleOnChangeSwitch)}
            {localPreferences.changeSelfRoster && renderPreference(localPreferences.changeSelfRoster, translations.getLabel('lblStatusChangeSelfRoster'), true, handleOnChangeSwitch)}
            <p className="notification-info">*{translations.getLabel('lblNotificationPermissionInfo')}</p>
            {!firebase.isSupported() && <p className="notification-warning"> <Icon tag="WarningIcon" color="warning" title={translations.getLabel('lblGenericWarning')} />  {translations.getLabel('lblNoDesktopNotificationsSupport')}</p>}
            {'Notification' in window && Notification?.permission === 'denied' && <p className="notification-warning"> <Icon tag="WarningIcon" color="warning" title={translations.getLabel('lblGenericWarning')} />  {translations.getLabel('lblNoDesktopNotificationsEnabled')}</p>}
          </div>
        </Modal>
      </>
    </Widget>
  );
};

export default NotificationPreferences;
