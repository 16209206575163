import produce from 'immer';
import { Actions, RequestShiftSwapAction } from './actions';
import ActionType from './types';
import { ShiftSwapStatus, IShift, UpdateApplicantStatus, RequestStatus } from '../../models';
import { IDayShiftSwap, IShiftSwaps } from '../../models/shiftSwap';
import { eachDayOfInterval, parseISO } from 'date-fns';
import { formatDateForSchedule } from '../../utils/schedule';

export interface ShiftSwapState {
  showShiftSwapModal: boolean;
  shifts: IShift[];
  isCancelRequestLoading?: boolean;
  isCreateRequestLoading?: boolean;
  isUpdateApplicantStatusLoading?: boolean;
  isRequestLoading?: boolean;
  isShiftSwapsLoading: boolean;
  shiftSwaps: IShiftSwaps,
}

export const initialState: ShiftSwapState = {
  showShiftSwapModal: false,
  shifts: null,
  isCancelRequestLoading: false,
  isCreateRequestLoading: false,
  isUpdateApplicantStatusLoading: false,
  isRequestLoading: false,
  isShiftSwapsLoading: false,
  shiftSwaps: null,
};

const shiftSwap = produce((draft: ShiftSwapState, action: Actions) => {
  switch (action.type) {
    case ActionType.ToggleShiftSwapModal:
      if (action.payload) {
        draft.shifts = action.payload.shifts;
      } else {
        draft.shifts = null;
      }
      draft.showShiftSwapModal = !draft.showShiftSwapModal;
      break;

    case ActionType.CreateShiftSwapRequest:
      draft.isCreateRequestLoading = true;
      break;

    case ActionType.CreateShiftSwapRequestFulfilled:
      draft.isCreateRequestLoading = false;
      break;

    case ActionType.CreateShiftSwapRequestRejected:
      draft.isCreateRequestLoading = false;
      break;

    case ActionType.GetShiftSwaps:
      draft.isShiftSwapsLoading = true;
      break;

    case ActionType.GetShiftSwapsFulfilled:
      draft.isShiftSwapsLoading = false;

      const tmpShiftSwaps = { ...draft.shiftSwaps };
      eachDayOfInterval({ start: parseISO(action.payload.startDate), end: parseISO(action.payload.endDate) }).forEach((date) => {
        tmpShiftSwaps[formatDateForSchedule(date)] = [];
      });

      action.payload.data.forEach(item => {
        const { shifts } = item;
        if (shifts[0]) {
          const formattedDate = formatDateForSchedule(shifts[0].startDate);
          const index = tmpShiftSwaps[formattedDate].findIndex((shiftSwap: IDayShiftSwap) => shiftSwap.id === item.id);
          if (index >= 0) tmpShiftSwaps[formattedDate][index] = item;
          else tmpShiftSwaps[formattedDate].push(item);
        }
      });
      draft.shiftSwaps = tmpShiftSwaps;
      break;

    case ActionType.GetShiftSwapsRejected:
      draft.isShiftSwapsLoading = false;
      break;

    case ActionType.CancelShiftSwapRequest:
      draft.isCancelRequestLoading = true;
      break;

    case ActionType.CancelShiftSwapRequestFulfilled:
      draft.isCancelRequestLoading = false;

      break;

    case ActionType.CancelShiftSwapRequestRejected:
      draft.isCancelRequestLoading = false;
      break;

    case ActionType.ApplyToShiftSwapWithCounter:
    case ActionType.UpdateShiftSwapApplicantStatus:
      draft.isUpdateApplicantStatusLoading = true;
      break;

    case ActionType.ApplyToShiftSwapWithCounterFulfilled:
    case ActionType.UpdateShiftSwapApplicantStatusFulfilled:
      draft.isUpdateApplicantStatusLoading = false;
      if (action.payload.date) {
        draft.shiftSwaps[formatDateForSchedule(action.payload.date)].forEach((shiftSwap: IDayShiftSwap) => {
          if (shiftSwap.id === action.payload.id) {
            if (action.payload.type === UpdateApplicantStatus.Apply) {
              shiftSwap.applicantStatus = ShiftSwapStatus.Pending;
              shiftSwap.numberOfApplicants = shiftSwap.numberOfApplicants + 1;
            } else {
              shiftSwap.applicantStatus = null;
              shiftSwap.numberOfApplicants = shiftSwap.numberOfApplicants - 1;
            }
          }
        });
      }
      break;

    case ActionType.ApplyToShiftSwapWithCounterRejected:
    case ActionType.UpdateShiftSwapApplicantStatusRejected:
      draft.isUpdateApplicantStatusLoading = false;
      break;

    case ActionType.RequestShiftSwap:
      draft.isRequestLoading = true;
      break;

    case ActionType.RequestShiftSwapFulfilled:
    case ActionType.RequestShiftSwapRejected:
      draft.isRequestLoading = false;
      break;
  }
}, initialState);

export default shiftSwap;