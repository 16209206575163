import React, { FC, useRef } from 'react';
import classnames from 'classnames';
import { isSameDay, isWithinInterval, parseISO } from 'date-fns';

import { IDateRange, IDayPlanning } from '../../../../models';
import { translations } from '../../../../utils';
import { OpenPlanningFunction } from '../../schedule.models';
import Shift from './Shift';

interface Props {
  planning: IDayPlanning;
  openPlanning: OpenPlanningFunction;
  isCollapsed: boolean;
  highlightRange?: IDateRange;
  highlightDates?: string[];
}

const isHighlighted = (range: IDateRange, dates: string[], date: Date) => {
  if (range) return isWithinInterval(date, { start: range.startDate, end: range.endDate });
  if (dates) return !!dates.find(d => isSameDay(parseISO(d), date));
  return false;
};

const WeekDayShifts: FC<Props> = ({ planning, openPlanning, isCollapsed, highlightRange, highlightDates }) => {
  const ref = useRef(null);
  const noShifts = !planning.shifts?.length;
  const date = parseISO(planning.date);
  const isInHightlightRange = isHighlighted(highlightRange, highlightDates, date);
  const className = classnames('stripped-button week-day-shifts', { 'no-shifts': noShifts, highlighting: !!highlightRange || !!highlightDates, 'show-highlight': isInHightlightRange });
  // We filter the shifts that start on the prev day, since these shifts are only shown on the day roster
  return (
    <button aria-label={translations.getLabel('lblOpenScheduleDetails')} ref={ref} className={className} onClick={() => !noShifts && openPlanning(planning, ref.current)}>
      {planning.shifts
        ?.filter((shift) => isSameDay(parseISO(shift.startDate), date))
        .map((shift) => <Shift key={`${shift.id}-${shift.startDate}`} isCollapsed={isCollapsed} shift={shift} />)
      }
    </button>
  );
};

export default WeekDayShifts;
