import React, { FC } from 'react';
import { Modal } from 'react-ess-components';

import { translations } from '../../../../utils';

import './overstaffedShiftModal.scss';

type Props = {
  open: boolean;
  onSave: () => void;
  onClose: () => void;
};

const OverStaffedShiftModal: FC<Props> = ({ open, onSave, onClose }) => {
  return (
    <Modal
      title={translations.getLabel('titleConfirmDeselect')}
      open={open}
      className="self-roster-overstaffed-modal"
      leftButtonProps={{
        label: translations.getLabel('cancel'),
        onClick: () => onClose(),
      }}
      rightButtonProps={{
        label: translations.getLabel('btnDeleteOverstaffedShift'),
        onClick: onSave,
      }}
    >
      <p>{translations.getLabel('lblConfirmDeselectShift')}</p>
    </Modal >
  );
};

export default OverStaffedShiftModal;
