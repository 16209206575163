import React, { FC, useEffect, useState } from 'react';
import { initAccessibilityLabels } from 'react-ess-components';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { BrowserNotifications, ColorWatcher, Loader } from './components';
import { getAccessibilityLabels, storageItems } from './constants';
import { HttpClient } from './http';
import { configActions, configSelectors, loginActions, loginSelectors, userActions, userSelectors } from './redux';
import { AuthorizedLayout, AuthorizedRoute, UnauthorizedLayout, UnauthorizedRoute } from './routes';
import { ErrorView } from './scenes';
import { firebase, notificationUtils, queryClientConfig, translations } from './utils';
import { format } from './utils/date';
import { analytics } from './utils/firebase';

import 'react-ess-components/build/index.css';
import './app.scss';

const getHoldingFromURL = () => {
  const hostParts = window.location.host.split('.');
  if (hostParts.length <= 1) {
    if (process.env.REACT_APP_ENV === 'development') return 'dev';
    return '';
  }
  return hostParts[0];
};

// On init we should set the rootUrl.
HttpClient.setHttpClientParams(process.env.REACT_APP_API_HOST, `${process.env.REACT_APP_VERSION_NR} ${process.env.HEROKU_RELEASE_VERSION}`, 'Web', process.env.REACT_APP_API_KEY);
const queryClient = new QueryClient(queryClientConfig);

const App: FC = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoggedOut, setIsLoggedOut] = useState<boolean>(false);
  const hasHealthError = useSelector(configSelectors.hasHealthError);
  const hasHoldingError = useSelector(configSelectors.hasHoldingError);
  const isLoggedOutAutomatically = useSelector(loginSelectors.isLoggedOutAutomatically);
  const userId = useSelector(userSelectors.getUserId);
  const companyConfig = useSelector(configSelectors.getCompanyConfig);
  const companyPreferences = useSelector(configSelectors.getCompanyPreferences);
  const holdingPreferences = useSelector(configSelectors.getHoldingPreferences);
  const hasFatalError = hasHealthError || hasHoldingError;

  const checkActivity = (holding: string) => {
    // Check if we reported activity today
    const lastActive = localStorage.getItem(storageItems.LAST_ACTIVE);
    const today = format(new Date(), 'dd/MM/yyyy');
    if (lastActive !== today) {
      // Report new activity
      analytics?.logEvent('ACTIVE_USER_WEB', { holding });
      localStorage.setItem(storageItems.LAST_ACTIVE, today);
    }
  };

  useEffect(() => {
    const holding = getHoldingFromURL();

    if (!holding && window.location.pathname !== '/') {
      window.location.pathname = '/';
      return;
    }

    localStorage.setItem(storageItems.HOLDING, holding);

    dispatch(new configActions.GetHealthAction());
    dispatch(new configActions.GetHoldingPreferencesAction({ holdingName: holding }));
    dispatch(new configActions.GetApiVersionAction());

    dispatch(new userActions.GetUserAction({
      onSuccess: (user) => {
        checkActivity(holding);
        // We're logged in after a refresh, so continue to secure env and fetch other resources
        dispatch(new loginActions.GetEmploymentsAction());
        // Enable notifications
        notificationUtils.setNotificationInterval();
        // Extra Sentry logging
        Sentry.configureScope(function (scope) {
          scope.setUser({ email: user.email, id: user.id, companyId: user.companyId, employmentId: user.employmentId });
        });
        // Set translations based on user language
        translations.I18n.locale = user.language;
        initAccessibilityLabels(getAccessibilityLabels());
        // Fetch user periods
        dispatch(new userActions.GetUserPeriodsAction());
        // Get company preferences/config
        dispatch(new configActions.GetCompanyPreferencesAction({ companyId: user.companyId }));
        dispatch(new configActions.GetCompanyConfigAction({ companyId: user.companyId }));
      },
      onError: () => {
        // Nothing to worry about, we're not logged in, stop loading and goto login page
        localStorage.removeItem(storageItems.IS_LOGGED_IN);
        setIsLoggedOut(true);
      },
    }));

    return () => {
      notificationUtils.removeNotificationInterval();
    };
  }, []);

  // Stop loading when logged in
  useEffect(() => {
    if (isLoading && userId && companyPreferences && companyConfig) {
      setIsLoading(false);
      localStorage.setItem(storageItems.IS_LOGGED_IN, 'true');
    }
  }, [isLoading, userId, companyConfig, companyPreferences]);

  // Stop loading when not logged in
  useEffect(() => {
    if (isLoggedOut && holdingPreferences) {
      setIsLoading(false);
    }
  }, [isLoggedOut, holdingPreferences]);

  // When we are logged out due to inactivity or removal of our session
  useEffect(() => {
    if (isLoggedOutAutomatically && userId && localStorage.getItem(storageItems.IS_LOGGED_IN) === 'true') {
      dispatch(new loginActions.LogoutFulfilledAction());
      firebase.unRegister();
      localStorage.removeItem(storageItems.IS_LOGGED_IN);
      window.location.pathname = '/auth/login';
    }
  }, [isLoggedOutAutomatically, userId]);

  if (hasFatalError) {
    return <ErrorView />;
  }

  if (isLoading) {
    return <section className="loading-wrapper"><Loader /></section>;
  }

  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <BrowserRouter
        getUserConfirmation={() => {
          /* Empty callback to block the default browser prompt */
        }}
      >
        <BrowserNotifications />
        <ColorWatcher />
        <Switch>
          <UnauthorizedRoute path="/auth" userId={userId} component={UnauthorizedLayout} />
          {!getHoldingFromURL()
            ? <Route path="/" exact component={ErrorView} />
            : <AuthorizedRoute path="/" component={AuthorizedLayout}
              isVacationPeriodActive={false}
              isAvailabilityPeriodActive={false} />
          }
        </Switch>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
