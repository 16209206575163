import React, { FC, useEffect, useState } from 'react';
import { ILabelAndValue, SelectPopup } from 'react-ess-components';
import { useSelector } from 'react-redux';

import { configSelectors } from '../../redux';
import { translations } from '../../utils';

type Props = {
  selected: string[];
  isOpen?: boolean;
  requestClose: () => void;
  onSave: (selected: string[]) => void;
  defaultLanguage: ILabelAndValue;
}

const SelectLanguage: FC<Props> = ({ selected, isOpen = false, requestClose, onSave, defaultLanguage }) => {
  const languages = useSelector(configSelectors.languages);
  const [selectedArray, setSelectedArray] = useState<string[]>(selected);

  useEffect(() => {
    if (isOpen) setSelectedArray(selected);
  }, [isOpen]);

  const onClose = () => {
    setSelectedArray(selected);
    requestClose();
  };

  const onSelect = (selected: string[]) => {
    setSelectedArray(selected);
  };

  const mapLanguage = (language: ILabelAndValue) => {
    return {
      ...language,
      label: translations.getLabel(language.label),
    };
  };

  const getLanguages = () => languages.filter(lang => lang.value !== defaultLanguage.value).map(mapLanguage);

  return (
    <SelectPopup
      data={getLanguages()}
      popupOpen={isOpen}
      isSearchEnabled={false}
      isMultiple={true}
      isModal={true}
      selected={selectedArray}
      title={translations.getLabel('lblPublishFor')}
      acceptLabel={translations.getLabel('save')}
      cancelLabel={translations.getLabel('cancel')}
      requestClose={onClose}
      onValueSelected={onSelect}
      onSave={() => onSave(selectedArray)} />
  );
};

export default SelectLanguage;