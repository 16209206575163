import React from 'react';
import { connect } from 'react-redux';

import NewsWidgetBase, { mapDispatchToProps, mapStateToProps } from '../../../../../shared/components/newsWidget/NewsWidget.container';
import { routes } from '../../../constants';
import { translations } from '../../../utils';
import NewsList from '../../newsList/NewsList';
import Widget from '../../widget/Widget';

import './NewsWidget.scss';

class NewsWidget extends NewsWidgetBase {
  onClickMore = () => this.props.newsItems.length ? this.props.onClickMore(routes.news) : this.props.onClickMore(routes.upsertNewsItemWithoutId)

  renderButton = () => {
    const { hasRight, newsItems, isLoading } = this.props;
    if (isLoading) return null;
    if (newsItems.length) return translations.getLabel('btnSeeAllNewsItems');
    if (!newsItems.length && hasRight) return translations.getLabel('lblCreateNewNewsItem');

    return '';
  }

  render() {
    return (
      <Widget
        buttonLabel={this.renderButton()}   
        onClickMore={this.onClickMore}
        title={translations.getLabel('titleNewsWidget')}
      >
        <NewsList newsItems={this.props.newsItems} isLoading={this.props.isLoading} limit={this.props.limit} />
      </Widget>
    );
  }
}

export { NewsWidget };
export default connect(mapStateToProps, mapDispatchToProps)(NewsWidget);
