import React, { FC } from 'react';
import { parseISO } from 'date-fns';

import { IDoubleTimeRegistration } from '../../../models';
import { date } from '../../../utils';

interface Props {
  timeRegistration: IDoubleTimeRegistration;
}

const TimeRegistrationRow: FC<Props> = ({ timeRegistration }) => {
  return (
    <tr>
      <td className="bold">{timeRegistration.device}</td>
      <td>{timeRegistration.timeRegistrations.map((t, i) => <p key={`${timeRegistration.device}-${t.username}-${i}`}>{t.username || '-'}</p>)}</td>
      <td>{timeRegistration.timeRegistrations.map((t, i) => <p key={`${timeRegistration.device}-${t.description}-${i}`}>{t.description || '-'}</p>)}</td>
      <td>{timeRegistration.timeRegistrations.map((t, i) => <p key={`${timeRegistration.device}-${t.time}-${i}`}>{date.format(parseISO(t.time), 'EEEE dd/MM') || '-'}</p>)}</td>
      <td>{timeRegistration.timeRegistrations.map((t, i) => <p key={`time-${t.time}`}>{date.format(parseISO(t.time), 'hh:mm') || '-'}</p>)}</td>
      <td>{timeRegistration.timeRegistrations.map((t, i) => <p key={`${timeRegistration.device}-${t.location}-${i}`}>{t.location || '-'}</p>)}</td>
    </tr>
  );
};

export default TimeRegistrationRow;

