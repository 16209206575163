import React from 'react';
import Dotdotdot from 'react-dotdotdot';
import { Button, Icon, IconButton, Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import classnames from 'classnames';

import { newsService } from '../../../../../shared/services';
import { routes } from '../../../constants';
import { INewsItem } from '../../../models';
import { newsActions } from '../../../redux';
import { date, translations } from '../../../utils';
import { amountOfLinesInItem } from '../../../utils/newsUtils';

import './NewsItem.scss';

interface Props extends DispatchProp, RouteComponentProps {
  isDetails?: boolean;
  newsItem?: INewsItem;
  hasNewsRight: boolean;
  isLoading: boolean;
  isDeleting: boolean;
}

interface State {
  isModalOpen: boolean;
}

export class NewsItem extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };
  }

  onClickReadMore = () => {
    this.props.history.push(`/news/${this.props.newsItem.id}`);
  }

  openAttachment = (attachmentId: string) => async () => {
    try {
      const downloadUrl = await newsService.downloadAttachment(attachmentId);
      window.open(downloadUrl);
    } catch (error) {
      toast.error(translations.getLabel('lblErrorFileOpen'));
    }
  }

  onEdit = async () => {
    const { newsItem, history } = this.props;
    history.push({
      pathname: `${routes.upsertNewsItemWithoutId}/${newsItem.id}`,
    });
  }

  onDelete = async () => {
    this.props.dispatch(new newsActions.DeleteNewsItemAction({
      id: this.props.newsItem.id,
      onSuccess: () => {
        this.setState({ isModalOpen: false });
      },
      onError: () => {
        this.setState({ isModalOpen: false });
        toast.error(translations.getLabel('lblGenericError'));
      },
    }));

    if (this.props.isDetails) this.props.history.goBack();
  }

  renderAttachment = (attachment) => {
    return <button className="attachment-wrapper" onClick={this.openAttachment(attachment.id)} key={attachment.id}><Icon tag="DocumentIcon" title="" /><p> {attachment.name}</p></button>;
  };

  toggleDeleteModal = () => this.setState({ isModalOpen: !this.state.isModalOpen });

  renderSkeleton() {
    return (
      <div className="news-item">
        <div className="inner-container">
          <Skeleton width={120} />
          <div className="created-info-container">
            <Icon tag="ClockIcon" customSize={1.6} color="gray2" />
            <Skeleton width={100} />
            <Icon tag="UserIcon" customSize={1.6} color="gray2" />
            <Skeleton width={120} />
          </div>
          <Skeleton count={4} />
        </div>
      </div>
    );
  }

  render() {
    const { isLoading, newsItem, hasNewsRight, isDetails } = this.props;

    if (isLoading || !newsItem) return this.renderSkeleton();

    const contentClassnames = classnames('content-container', {
      'content-container-with-image': !!newsItem.imageUrl,
      'content-container-details': isDetails,
    });

    const titleClassNames = classnames('title', {
      'title-with-buttons': hasNewsRight,
    });

    const calenderDate = date.toRelativeDate(date.format, date.parseDate(newsItem.createdAt), 'EEEEEE dd/MM/yyyy');
    const createdBy = `${newsItem.firstName} ${newsItem.lastName}`;
    return (
      <div className="news-item">
        {newsItem.imageUrl && <img src={newsItem.imageUrl} alt="newsImage" />}
        <div className="inner-container">
          <p className={titleClassNames}>{newsItem.title}</p>
          {hasNewsRight && !isLoading && newsItem.hasEditRights &&
            <div className="options-container">
              <IconButton tag="EditIcon" onClick={this.onEdit} title={translations.getLabel('btnEdit')} />
              <IconButton tag="TrashIcon" onClick={this.toggleDeleteModal} title={translations.getLabel('lblDelete')} />
            </div>}
          <div className="created-info-container">
            <div className="info-item">
              <Icon tag="ClockIcon" customSize={1.6} color="gray2" />
              <p className="created-info">{calenderDate.shouldTranslate ? translations.getLabel(calenderDate.value) : `${calenderDate.value}, ${calenderDate.time}`}</p>
            </div>
            <div className="info-item">
              <Icon tag="UserIcon" customSize={1.6} color="gray2" />
              <p className="created-info">{createdBy}</p>
            </div>
          </div>
          <div className={contentClassnames}>
            {isDetails
              ? <div dangerouslySetInnerHTML={{ __html: newsItem.message }} />
              : <Dotdotdot className="content" clamp={amountOfLinesInItem(newsItem)}><p dangerouslySetInnerHTML={{ __html: newsItem.message }} /></Dotdotdot>
            }
          </div>
          {
            !isDetails && !isLoading && <div className="button-container">
              <Button theme="transparent" onClick={this.onClickReadMore}>{translations.getLabel('btnReadMore')}</Button>
            </div>
          }
          {
            isDetails && newsItem.attachments && newsItem.attachments.length > 0 && <div className="attachment-container">
              {newsItem.attachments.map(this.renderAttachment)}
            </div>
          }
        </div>
        <Modal
          title={translations.getLabel('lblDeleteNews')}
          requestClose={this.toggleDeleteModal}
          leftButtonProps={{
            label: translations.getLabel('cancel'),
            onClick: this.toggleDeleteModal,
          }}
          rightButtonProps={{
            label: translations.getLabel('lblDelete'),
            isLoading: this.props.isDeleting,
            onClick: this.onDelete,
          }}
          open={this.state.isModalOpen}>
          <p className="remove-news-message">{translations.getLabel('lblConfirmDeleteNews')}</p>
        </Modal>
      </div >
    );
  }
}

export default withRouter(NewsItem);
