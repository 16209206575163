import React, { FC } from 'react';
import { Icon } from 'react-ess-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { translations } from '../../utils';

import './backButton.scss';

interface Props extends RouteComponentProps {
  to?: string;
  label: string;
}

const BackButton: FC<Props> = ({ history, to, label }) => {
  const navigateBack = () => {
    if (to) history.replace(to);
    else history.goBack();
  };

  return (
    <button className="back-button" onClick={navigateBack} type="button">
      <Icon tag="ArrowLeft" color="primary" className="arrow" />
      {label}
    </button>
  );
};

BackButton.defaultProps = {
  label: translations.getLabel('btnBack'),
};

export { BackButton };
export default withRouter(BackButton);
