import AccountDetails from './AccountDetails.base';
import { userSelectors } from '../../redux';

export const mapStateToProps = state => ({
  user: userSelectors.getUser(state),
  isLoading: userSelectors.isLoading(state),
  isAvatarLoading: userSelectors.isUpdateAvatarLoading(state),
});

export default AccountDetails;

