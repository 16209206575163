import React, { FC, useEffect } from 'react';
import { Avatar } from 'react-ess-components';
import { Icon } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { identifiers } from '../../../../constants';
import { employeesActions, employeesSelectors, userSelectors } from '../../../../redux';
import { translations } from '../../../../utils';

import './employeeDetails.scss';

interface Props {
  employeeId: string;
}

const EmployeeDetails: FC<Props> = ({ employeeId }) => {
  const isLoading = useSelector(employeesSelectors.isLoading);
  const user = useSelector((state: AppState) => employeeId === identifiers.me ? userSelectors.getUser(state) : employeesSelectors.getEmployee(state, employeeId));
  const dispatch = useDispatch();
  useEffect(() => {
    if (employeeId !== identifiers.me) dispatch(new employeesActions.GetEmployeeAction({ id: employeeId }));
  }, []);

  return (
    <div className="employee-details">
      <Avatar large firstName={user?.firstName} lastName={user?.lastName} source={user?.profilePictureUrl} />
      <div>
        <h4>{user?.firstName} {user?.lastName}</h4>
        {user?.visibleContactData && <>
          <div className="icon-container">
            <Icon tag="PhoneIcon" />
            {(isLoading && !user?.phoneNumber)
              ? <p><Skeleton width={100} height={14} /></p>
              : <p>{user?.phoneNumber || translations.getLabel('lblNotFound')}</p>
            }
          </div>
          <div className="icon-container">
            <Icon tag="MailIcon" />
            {(isLoading && !user?.email)
              ? <p><Skeleton width={100} height={14} /></p>
              : <p>{user?.email ? <a href={`mailto:${user?.email}`}>{user?.email}</a> : translations.getLabel('lblNotFound')}</p>
            }
          </div>
        </>
        }
      </div>
    </div>
  );
};



export default EmployeeDetails;
