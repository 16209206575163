import React from 'react';
import io from 'socket.io-client';
import PropTypes from 'prop-types';
import { socketConstants } from '../../constants';
import { HttpClient } from '../../http';
import { chatActions } from '../../redux';

export default class Socket extends React.PureComponent {
  constructor(props) {
    super(props);
    this.socket = null;
  }

  mountSocket = (baseUrl) => {
    this.initConnection(baseUrl);
    this.addMessageListener();
    this.addConnectionListener();
    this.props.roomIds.map((id) => this.joinConversation(id));
  }

  unmountSocket() {
    this.props.roomIds.map((id) => this.leaveConversation(id));
    this.removeListeners();
  }

  joinConversation = roomId => {
    this.socket.emit(socketConstants.JOIN_ROOM, { roomId });
  };

  initConnection = (baseUrl) => {
    this.socket = io(`${baseUrl}/${this.props.holdingId}`,
      {
        path: socketConstants.PATH,
        transports: ['websocket'],
        extraHeaders: {
          Authorization: `Bearer ${HttpClient.getAuthToken()}`
        }
      });
  };

  leaveConversation = roomId => {
    this.socket.emit(socketConstants.LEAVE_ROOM, { roomId });
  };

  sendMessage = (roomId, message, attachment) => {
    if (this.socket) this.socket.emit(socketConstants.SEND_CHAT_MESSAGE, { roomId, message, attachment });
  };

  handleMessage = (msg) => {
    if (this.state.selectedId !== msg.conversationId) this.props.dispatch(new chatActions.MarkAsUnreadAction({ id: msg.conversationId }));
    this.props.dispatch(new chatActions.AddMessageToConversationAction({ message: msg, conversationId: msg.conversationId }));
  }

  addMessageListener = () => {
    this.socket.on(socketConstants.RECEIVE_CHAT_MESSAGE, this.handleMessage);
  };

  addConnectionListener = () => {
    this.socket.on(socketConstants.DISCONNECT, this.handleDisconnection);
  };

  handleDisconnection = () => {
    this.socket.on(socketConstants.CONNECT, this.handleConnection);
  };

  handleConnection = () => {
    this.socket.off(socketConstants.CONNECT);

    this.props.roomIds.forEach(id => {
      this.joinConversation(id);
    });
  };

  removeListeners = () => {
    this.socket.off(socketConstants.CONNECT);
    this.socket.off(socketConstants.DISCONNECT);
    this.socket.removeAllListeners(socketConstants.RECEIVE_CHAT_MESSAGE);
  };

  render() {
    return null;
  }
}

Socket.propTypes = {
  dispatch: PropTypes.any.isRequired,
  roomIds: PropTypes.array.isRequired,
  addMessage: PropTypes.func.isRequired,
  holdingId: PropTypes.string.isRequired,
};