import { HttpClient } from '../http';
import { transactionCategories } from '../mock/mockdata';
import { Transactiontype } from '../models';

export const createTransaction = async (type: string, value: number, date: string, reason: string): Promise<void> => {
  return HttpClient.post('/v1/transactions', {
    transactionTypeId: type,
    date,
    value,
    reason,
  });
};

export const getTransactionTypes = async (): Promise<Transactiontype[]> => {
  const result = await HttpClient.get<{ data: Transactiontype[] }>('/v1/transactions/transaction-types');
  return result.data;
};

export const getTransactionValue = async (transactionId: string, date: string): Promise<Transactiontype> => {
  const result = await HttpClient.get<{ data: Transactiontype }>(`/v1/transactions/transaction-types/${transactionId}/value?date=${date}`);
  return result.data;
};
