import React, { FC } from 'react';
import { Button, Orb } from 'react-ess-components';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { RequestStatus } from '../../../../models';
import { requestsSelectors } from '../../../../redux';
import { date, requestsUtils,translations } from '../../../../utils';

interface Props {
  id: string;
  departmentId?: string;
  openRoster: () => void;
  openCancelModal: () => void;
}

const Footer: FC<Props> = ({ id, openRoster, openCancelModal }) => {
  const request = useSelector((state: AppState) => requestsSelectors.getDepartmentShiftOffer(state, id));

  const cancelShiftOffer = () => openCancelModal();
  const submitShiftOffer = () => openRoster();

  const className = classNames('status', {
    completed: request.shiftOfferStatus === RequestStatus.Completed,
    cancelled: request.shiftOfferStatus === RequestStatus.Cancelled,
  });

  const isCompleted = request.shiftOfferStatus === RequestStatus.Completed;
  if (request.shiftOfferStatus === RequestStatus.Cancelled || isCompleted) {
    const lastUpdatedDate = requestsUtils.getRelativeDate(request?.updatedAt, date.format);
    return (<>
      <div className={className}>
        <Orb color={requestsUtils.getColorByRequestStatus(request.shiftOfferStatus)} filled small />
        <p>{requestsUtils.getShiftOfferRequestStatus(request.shiftOfferStatus, translations.getLabel)}</p>
      </div>
      <p className="update-details">{lastUpdatedDate.shouldTranslate ? `${translations.getLabel(lastUpdatedDate.value)} ${lastUpdatedDate.time}` : `${lastUpdatedDate.value}`} {translations.getLabel('lblBy')} {request?.reviewerName || '-'}</p>
    </>);
  }

  if (request.shiftOfferStatus === RequestStatus.Open) {
    return (
      <div className="buttons">
        <Button onClick={cancelShiftOffer} theme="inverse">{translations.getLabel('lblCancelShiftOffer')}</Button>
        <Button onClick={submitShiftOffer} disabled={request?.numberOfApplicants <= 0}>{translations.getLabel('lblFillInShiftOffer')}</Button>
      </div>
    );
  }
  return null;
};

export default Footer;
