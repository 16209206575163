import { HttpClient } from '../http';
import * as general from '../utils/general';  // Direct import to avoid circular dependency 
import { IUser, IEditUser, IPeriodDetail, Period, INotificationPreference } from '../models';

export const getUser = async (): Promise<IUser> => {
  const result = await HttpClient.get<{ data: IUser }>('/v1/me');
  return result.data;
};

export const getIcsUrl = async (): Promise<{ icsUrl: string }> => {
  const result = await HttpClient.get<{ data: { icsUrl: string } }>('/v1/me/ics-url');
  return result.data;
};

export const getPeriods = async (): Promise<IPeriodDetail[]> => {
  // TODO: Backend will provide a call to which you can send the types as a query param - AE1851
  const response = await HttpClient.get<{ data: IPeriodDetail }>('/v1/me/requests', { type: Period.AvailabilityRequestPeriod });
  const response2 = await HttpClient.get<{ data: IPeriodDetail }>('/v1/me/requests', { type: Period.VacationRequestPeriod });
  const response3 = await HttpClient.get<{ data: IPeriodDetail }>('/v1/me/requests', { type: Period.ShiftTimeAdjustmentPeriod });
  return [response.data, response2.data, response3.data];
};

export const updateAvatar = async (avatar: File): Promise<{ profilePictureUrl: string }> => {
  const data = new FormData;
  data.append('file', avatar);
  const result = await HttpClient.post<{ profilePictureUrl: string }>('/v1/me/profile-picture', data, { 'content-type': 'multipart/form-data' });

  // It is always the same url, we need some way to break the cache
  if (result.profilePictureUrl) result.profilePictureUrl = general.addCacheBreaker(result.profilePictureUrl);
  return result;
};

export const deleteAvatar = async (): Promise<void> => {
  return HttpClient.delete('/v1/me/profile-picture');
};

export const updateUser = async (body: IEditUser): Promise<void> => {
  return HttpClient.patch('/v1/me', body);
};

export const getNotificationPreferences = async (): Promise<INotificationPreference[]> => {
  const result = await HttpClient.get<{ data: INotificationPreference[] }>('/v1/me/notification-preferences');
  return result.data;
};

export const updateNotificationPreferences = async (body: INotificationPreference[]): Promise<{ preferences: INotificationPreference[] }> => {
  await HttpClient.put('/v1/me/notification-preferences', body);
  return { preferences: body };
};