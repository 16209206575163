import { Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { exhaustMap, map, catchError } from 'rxjs/operators';

import ActionType from './types';
import { loginService } from '../../services';
import {
  LoginAction,
  LoginFulfilledAction,
  LoginRejectedAction,
  LogoutFulfilledAction,
  LogoutRejectedAction,
  GetEmploymentsFulfilledAction,
  GetEmploymentsRejectedAction,
  UpdateEmploymentAction,
  UpdateEmploymentFulfilledAction,
  UpdateEmploymentRejectedAction,
  LogoutAction,
  LoginWithSsoAction,
  LoginWithSsoFulfilledAction,
  LoginWithSsoRejectedAction
} from './actions';
import { IServerError } from '../../http/HttpError';

export const loginEpic$: Epic = action$ =>
  action$.ofType(ActionType.Login).pipe(
    exhaustMap(({ payload }: LoginAction) =>
      from(loginService.login(payload.username, payload.password, payload.holding)).pipe(
        map(({ employments, status, token }) => {
          payload.onSuccess?.(employments);
          return new LoginFulfilledAction({ employments, status, token });
        }),
        catchError((error: IServerError) => {
          payload.onError?.(error);
          return of(new LoginRejectedAction({ error }));
        })
      )
    ),
  );

export const loginWithSsoEpic$: Epic = action$ =>
  action$.ofType(ActionType.LoginWithSso).pipe(
    exhaustMap(({ payload }: LoginWithSsoAction) =>
      from(loginService.loginWithSso(payload.token, payload.holding)).pipe(
        map(({ employments, status, token }) => {
          payload.onSuccess?.(employments);
          return new LoginWithSsoFulfilledAction({ employments, status, token });
        }),
        catchError((error: IServerError) => {
          payload.onError?.(error);
          return of(new LoginWithSsoRejectedAction({ error }));
        })
      )
    ),
  );

export const logoutEpic$: Epic = action$ =>
  action$.ofType(ActionType.Logout).pipe(
    exhaustMap(({ payload }: LogoutAction) =>
      from(loginService.logout()).pipe(
        map(() => {
          payload?.onSuccess?.();
          return new LogoutFulfilledAction();
        }),
        catchError(error => of(new LogoutRejectedAction({ error })))
      )
    ),
  );

export const getEmploymentsEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetEmployments).pipe(
    exhaustMap(() =>
      from(loginService.getEmployments()).pipe(
        map(data => new GetEmploymentsFulfilledAction({ data })),
        catchError(error => of(new GetEmploymentsRejectedAction({ error })))
      )
    ),
  );

export const updateEmploymentEpic$: Epic = action$ =>
  action$.ofType(ActionType.UpdateEmployment).pipe(
    exhaustMap(({ payload }: UpdateEmploymentAction) =>
      from(loginService.updateEmployment(payload.id)).pipe(
        map(() => {
          payload.onSuccess?.();
          return new UpdateEmploymentFulfilledAction();
        }),
        catchError(error => of(new UpdateEmploymentRejectedAction({ error })))
      )
    ),
  );

const epics = [
  loginEpic$,
  loginWithSsoEpic$,
  logoutEpic$,
  getEmploymentsEpic$,
  updateEmploymentEpic$,
];

export default epics;
