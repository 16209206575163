export const storageItems = {
  COMPANY: 'company',
  FIREBASE_TOKEN: 'firebaseToken',
  HOLDING: 'holding',
  IS_LOGGED_IN: 'isLoggedIn',
  SEARCH_VALUE: 'searchValue',
  IS_SHIFT_OFFERS_HIDDEN: 'isShiftOffersHidden',
  IS_SHIFT_SWAPS_HIDDEN: 'isShiftSwapsHidden',
  LAST_ACTIVE: 'lastActive',
};

export enum StorageItem {
  Company = 'company',
  FirebaseToken = 'firebaseToken',
  Holding = 'holding',
  IsLoggedIn = 'isLoggedIn',
  IsShiftOffersHidden = 'isShiftOffersHidden',
  IsShiftSwapsHidden = 'isShiftSwapsHidden',
  SearchValue = 'searchValue',
};

