import { Action } from 'redux';

import { newsService } from '../../services';
import ActionType from './types';
import { IServerError } from '../../http/HttpError';
import { INewsItem, INewsItemAdmin, IFileUploadFunction, INewsFiles } from '../../models';
import { HttpPagingResponse } from '../../http/HttpMetadata';

export class GetNewsAction implements Action<ActionType> {
  readonly type = ActionType.GetNews;
}

export class GetNewsFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetNewsFulfilled;
  constructor(public payload: HttpPagingResponse<INewsItem[]>) { }
}

export class GetNewsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetNewsRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class LoadMoreNewsAction implements Action<ActionType> {
  readonly type = ActionType.LoadMoreNews;
  constructor(public payload: { limit?: number, offset: number }) { }
}

export class LoadMoreNewsFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.LoadMoreNewsFulfilled;
  constructor(public payload: HttpPagingResponse<INewsItem[]>) { }
}

export class LoadMoreNewsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.LoadMoreNewsRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetNewsDetailAction implements Action<ActionType> {
  readonly type = ActionType.GetNewsDetail;
  constructor(public payload: { id: string, onSuccess?: (data: INewsItem) => void, onError?: (error: IServerError) => void }) { }
}

export class GetNewsDetailFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetNewsDetailFulfilled;
  constructor(public payload: { data: INewsItem }) { }
}

export class GetNewsDetailRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetNewsDetailRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetAdminNewsDetailAction implements Action<ActionType> {
  readonly type = ActionType.GetAdminNewsDetail;
  constructor(public payload: { id: string, onSuccess?: () => void, onError?: (error: IServerError) => void }) { }
}

export class GetAdminNewsDetailFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetAdminNewsDetailFulfilled;
  constructor(public payload: { data: INewsItemAdmin }) { }
}

export class GetAdminNewsDetailRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetAdminNewsDetailRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class DeleteNewsItemAction implements Action<ActionType> {
  readonly type = ActionType.DeleteNewsItem;
  constructor(public payload: { id: string, onSuccess?: () => void, onError?: (error: IServerError) => void }) { }
}

export class DeleteNewsItemFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.DeleteNewsItemFulfilled;
  constructor(public payload: { id: string }) { }
}

export class DeleteNewsItemRejectedAction implements Action<ActionType> {
  readonly type = ActionType.DeleteNewsItemRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class UpdateNewsItemAction implements Action<ActionType> {
  readonly type = ActionType.UpdateNewsItem;
  constructor(public payload: { id?: string; data: INewsItemAdmin, files: INewsFiles, fileUploadFunc: IFileUploadFunction; onSuccess?: () => void; onError?: (error: IServerError) => void }) { }
}

export class UpdateNewsItemFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.UpdateNewsItemFulfilled;
}

export class UpdateNewsItemRejectedAction implements Action<ActionType> {
  readonly type = ActionType.UpdateNewsItemRejected;
  constructor(public payload: { error: IServerError }) { }
}

export type Actions =
  | GetNewsAction
  | GetNewsFulfilledAction
  | GetNewsRejectedAction
  | LoadMoreNewsAction
  | LoadMoreNewsFulfilledAction
  | LoadMoreNewsRejectedAction
  | GetNewsDetailAction
  | GetNewsDetailFulfilledAction
  | GetNewsDetailRejectedAction
  | GetAdminNewsDetailAction
  | GetAdminNewsDetailFulfilledAction
  | GetAdminNewsDetailRejectedAction
  | DeleteNewsItemAction
  | DeleteNewsItemFulfilledAction
  | DeleteNewsItemRejectedAction
  | UpdateNewsItemAction
  | UpdateNewsItemFulfilledAction
  | UpdateNewsItemRejectedAction;