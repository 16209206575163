import { createSelector } from 'reselect';

import { DocumentsState } from './reducer';
import { AppState } from '../rootReducer';

const selectNode = (state: AppState) => state.documents;

export const isLoading = createSelector(
  selectNode,
  (state: DocumentsState) => state.isLoading,
);

export const getAllDocuments = createSelector(
  selectNode,
  (state: DocumentsState) => state.all,
);

export const hasUploadError = createSelector(
  selectNode,
  (state: DocumentsState) => state.hasUploadError
);