import React, { FC, useRef } from 'react';
import { Button, Icon, Modal, Popup } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useToggle } from '../../../../hooks';
import { IExternalVacationRequest, RequestStatus} from '../../../../models';
import { scheduleActions, scheduleSelectors } from '../../../../redux';
import { date, translations } from '../../../../utils';
import { usePopupPosition } from './usePopupPosition';

import './externalVacationDetail.scss';

interface Props {
  isOpen: boolean;
  parentRef?: HTMLElement;
  scrollRef: HTMLElement;
  close: () => void;
  vacationRequest: IExternalVacationRequest
}

const getVacationStatusLabel = (status: RequestStatus) => {
  switch (status) {
    case RequestStatus.Denied: return translations.getLabel('lblRefused');
    case RequestStatus.Completed:
    case RequestStatus.Accepted: return translations.getLabel('lblApproved');
    case RequestStatus.Open:
    default: return translations.getLabel('lblRequested');
  }
};
const ExternalVacationDetailPopup: FC<Props> = ({ isOpen, parentRef, scrollRef, close, vacationRequest }) => {
  const dispatch = useDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useToggle(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef(null);
  const isDeleting = useSelector(scheduleSelectors.isDeletingExternalVacationRequest);
  const {popupStyle, popupPosition} = usePopupPosition(isOpen, parentRef, scrollRef, containerRef?.current);

  const cancelRequest = () => {
    dispatch(new scheduleActions.DeleteUserPlanningExternalVacationAction(
      {
        id: vacationRequest.shiftId,
        startDate: vacationRequest.startDate,
        onSuccess: () => toast(translations.getLabel('lblRequestCancelled')),
        onError: (error) => { toast.error(error?.detail || translations.getLabel('lblGenericError')); },
      }));
    setIsConfirmModalOpen();
    close();
  };

  const closePopup = () => {
    if (isConfirmModalOpen) setIsConfirmModalOpen();
  };

  return (
    <>
      <div className="roster-popup-container" ref={containerRef}>
        <Popup open={isOpen} requestClose={close} style={popupStyle} position={popupPosition} excludePressArea={[popupRef.current, parentRef]}>
        <div className="external-vacation-detail" >
          <h4>{`${translations.getLabel('lblVacation')} ${getVacationStatusLabel(vacationRequest.status)}`}</h4>
          <p><Icon customSize={1.8} tag="CalendarIcon" />{date.format(vacationRequest.startDate, 'dd MMM yyyy')}</p>
          <p><Icon customSize={1.8} tag="DashboardIcon" />{vacationRequest.description}</p>
          {!!vacationRequest.requesterNote && <p><Icon customSize={1.8} tag="ChatIcon" />{vacationRequest.requesterNote}</p>}
          {vacationRequest.status === RequestStatus.Open && <Button onClick={setIsConfirmModalOpen}>{translations.getLabel('btnCancelRequest')}</Button>}
        </div>
        </Popup>
      </div>
      <div ref={popupRef}>
        <Modal
          title={translations.getLabel('lblCancelRequest')}
          requestClose={closePopup}
          leftButtonProps={{
            label: translations.getLabel('btnBack'),
            onClick: closePopup,
          }}
          rightButtonProps={{
            label: translations.getLabel('btnCancelRequest'),
            isLoading: isDeleting,
            onClick: cancelRequest,
          }}
          open={isConfirmModalOpen}>
          <p className="modal-confirm-content">{translations.getLabel('lblConfirmCancelRequest')}</p>
        </Modal>
      </div>
    </>
  );
};

export default ExternalVacationDetailPopup;
