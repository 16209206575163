import React, { FC } from 'react';
import { Icon } from 'react-ess-components';
import { parseISO } from 'date-fns';

import { IShift } from '../../../../models';
import { date } from '../../../../utils';
import Interval from './Interval';

interface Props {
  shift: IShift;
  isCollapsed: boolean;
}

const Shift: FC<Props> = ({ shift, isCollapsed }) => {
  const startDate = parseISO(shift.startDate);
  const endDate = parseISO(shift.endDate);

  return (
    <div className="shift">
      <h3>{shift.shiftName}</h3>
      {(isCollapsed || !shift.intervals?.length) && <div className="timestamp">{` ${date.formatFromUntilTime(date.format, { startDate, endDate })}`}</div>}
      {shift.departmentSwitch && <div className="department">{shift.department} <Icon small tag="SwitchIcon" /></div>}
      {!isCollapsed && <div className="shit-interval-spacer" />}
      {!isCollapsed && shift.intervals.map((interval, i) => <Interval key={i} interval={interval} />)}
    </div>
  );
};

export default Shift;
