import { HttpClient } from '../http';
import { IResetPassword } from '../models/resetPassword';

export const requestForgotPassword = async (username: string, holding: string): Promise<IResetPassword> => {
  return HttpClient.post('/v1/auth/forgot-password/init', { username }, { url: holding });
};

export const resetPassword = async (password: string, token: string, holding: string, shouldPersistSession: boolean): Promise<void> => {
  return HttpClient.put('/v1/auth/forgot-password/confirm', { password, shouldPersistSession }, { url: holding }, { token });
};
