export enum ActionType {
  GetAvailabilities = 'GET_AVAILABILITIES',
  GetAvailabilitiesRejected = 'GET_AVAILABILITIES_REJECTED',
  GetAvailabilitiesFulfilled = 'GET_AVAILABILITIES_FULFILLED',

  PutAvailabilities = 'PUT_AVAILABILITIES',
  PutAvailabilitiesRejected = 'PUT_AVAILABILITIES_REJECTED',
  PutAvailabilitiesFulfilled = 'PUT_AVAILABILITIES_FULFILLED',
}

export default ActionType;