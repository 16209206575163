import { HttpClient } from '../http';
import { IChatConversation, IChatParticipantList } from '../models';

export const getConversations = async (search?: string): Promise<{ count: number; conversations: IChatConversation[] }> => {
  const params = search ? { search } : null;
  const result = await HttpClient.get<{ data: IChatConversation[], meta: { totalCount: number } }>('/v1/chat', params);
  return {
    conversations: result.data,
    count: result.meta.totalCount,
  };
};

export const markAsRead = async (id: string): Promise<{ id: string }> => {
  await HttpClient.post(`/v1/chat/conversation/${id}/read`);
  return { id };
};

export const getUnread = async (): Promise<number> => {
  const result = await HttpClient.get<{ count: number }>('/v1/chat/unread');
  return result.count;
};

export const getParticipants = async (search?: string): Promise<IChatParticipantList[]> => {
  const params = search ? { search } : null;
  const result = await HttpClient.get<{ data: IChatParticipantList[] }>('/v1/chat/available-participants', params);
  return result.data;
};

export const createConversation = async (params: { employmentIds?: string[], departmentId?: string }): Promise<IChatConversation> => {
  const result = await HttpClient.post<{ data: IChatConversation }>('/v1/chat/conversation', params);
  return result.data;
};

export const leaveConversation = async (id: string): Promise<void> => {
  return HttpClient.delete(`/v1/chat/conversation/${id}`);
};

export const getPresignedUrl = async (roomId: string, contentType: string, conversationId: string): Promise<{ uploadUrl: string, storageId: string }> => {
  const result = await HttpClient.post<{ data: { uploadUrl: string, storageId: string } }>(`/v1/chat/conversation/${conversationId}/attachment`, { roomId, contentType });
  return result.data;
};

export const getAttachment = async (url: string): Promise<{ data: { downloadUrl: string } }> => {
  return HttpClient.get<{ data: { downloadUrl: string } }>(url);
};