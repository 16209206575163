import React from 'react';
import DocumentTitle from 'react-document-title';

import { ErrorCloud } from '../../assets/images';
import { translations } from '../../utils';

import './errorview.scss';

const ErrorView = () => (
  <DocumentTitle title={translations.getLabel('lblErrorView')}>
    <div className="errorview-container">
      <div className="cloud">
        <ErrorCloud />
      </div>
      <p className="errorview-text">{translations.getLabel('lblErrorBackEnd')}</p>
    </div>
  </DocumentTitle>
);

export default ErrorView;
