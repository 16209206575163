import React from 'react';

import { CounterType, IRequestCounter } from '../../../../models';
import { translations } from '../../../../utils';

import './counters.scss';

type Props = {
  counters: IRequestCounter[];
}

const Counters = ({ counters }: Props) => {
  const renderBeforeTime = (counter: IRequestCounter) => {
    return (
        <span className="bold">{counter.value?.hours || 0}:{counter.value?.minutes || 0} {translations.getLabel('lblBefore')}</span>
    );
  };

  const renderAfterTime = (counter: IRequestCounter) => {
    return (
      <span className="bold">{counter.valueAfter?.hours || 0}:{counter.valueAfter?.minutes || 0} {translations.getLabel('lblAfter')}</span>
    );
  };

    const renderBeforeDays = (counter: IRequestCounter) => {
    return (
      <span className="bold">{`${counter.value?.days.toString()} ${translations.getLabel('lblDays')} ${translations.getLabel('lblBefore')}`}</span>
    );
  };

  const renderAfterDays = (counter: IRequestCounter) => {
    return (
      <span className="bold">{`${counter.valueAfter?.days.toString()} ${translations.getLabel('lblDays')} ${translations.getLabel('lblAfter')}`}</span>
    );
  };
  

  return (
    <ul className="request-counters">
      {counters.map((counter, i) => (
        <li key={i}>
          {`${counter.description}: `}
          {counter.value === CounterType.Time && renderBeforeTime(counter)}
          {counter.value === CounterType.Days && renderBeforeDays(counter)}
          {` ${translations.getLabel('lblApproval')}, `}
          {counter.value === CounterType.Time && renderAfterTime(counter)}
          {counter.value === CounterType.Days && renderAfterDays(counter)}
          {` ${translations.getLabel('lblApproval')}`}
        </li>
      ))}
    </ul>
  );
};

export default Counters;
