export enum Period {
  AvailabilityFrom = 'AVAILABILITY_FROM',
  AvailabilityRequestPeriod = 'AVAILABILITY_REQUEST_PERIOD',
  AvailabilityUntil = 'AVAILABILITY_UNTIL',
  VacationRequestPeriod = 'VACATION_REQUEST_PERIOD',
  VacationFrom = 'VACATION_FROM',
  VacationUntil = 'VACATION_UNTIL',
  ScheduleVisibilityPeriod = 'SCHEDULE_VISIBILITY_PERIOD',
  ShiftTimeAdjustmentPeriod = 'SHIFT_TIME_ADJUSTMENTS_REQUEST_PERIOD',
};

export interface IPeriodResponse {
  [Period.AvailabilityRequestPeriod]?: IPeriodDetail[],
  [Period.VacationRequestPeriod]?: IPeriodDetail[],
  [Period.ScheduleVisibilityPeriod]?: IPeriodDetail[],
  [Period.ShiftTimeAdjustmentPeriod]?: IPeriodDetail[],
}

export interface IPeriod {
  [Period.AvailabilityRequestPeriod]?: IPeriodDetail,
  [Period.VacationRequestPeriod]?: IPeriodDetail,
  [Period.ScheduleVisibilityPeriod]?: IPeriodDetail,
  [Period.ShiftTimeAdjustmentPeriod]?: IPeriodDetail,
}

export interface IPeriodDetail {
  daysBefore?: number;
  endDate?: string;
  startDate?: string;
  type?: Period;
  updatedAt?: string;
}