import { produce } from 'immer';
import ActionType from './types';
import { Actions } from './actions';
import { IRequestCounts, IVacationRequest, IVacationRequests, IVacationType, IRequestDepartment, RequestStatus, IShiftOfferRequests, IShiftOfferRequest, IShiftSwapRequest, IShiftSwapRequests, RequestType, ITransactionRequest, IDepartmentTransactions, ITransactionRequestResponse, ITransactionRequests, IDepartmentShiftTimes, IShiftTimeRequest, IShiftTimeRequests, IRequestCounter } from '../../models';
import { HttpMetadataPagingResponse } from '../../http/HttpMetadata';

type Filters = {
  vacation: RequestStatus,
  shiftOffer: RequestStatus,
  shiftSwap: RequestStatus,
  transaction: RequestStatus,
  shiftTime: RequestStatus,
};
export interface RequestState {
  vacationRequestCounters: IRequestCounter[]
  activeDepartment: string;
  activeInbox: RequestType;
  counts: IRequestCounts;
  departmentMetaData: HttpMetadataPagingResponse;
  departments: IRequestDepartment[];
  departmentShiftOffers: IShiftOfferRequests;
  departmentShiftSwaps: IShiftSwapRequests;
  departmentShiftTimeRequests: IShiftTimeRequests;
  departmentShiftTimes: IDepartmentShiftTimes;
  departmentTransactionRequests: ITransactionRequests;
  departmentTransactions: IDepartmentTransactions;
  departmentVacationRequests: IVacationRequests;
  didVacationRequestLoad: boolean;
  didVacationTypesLoad: boolean,
  filter: Filters;
  hasShiftTimeRequestError: boolean,
  hasTransactionRequestError: boolean,
  hasVacationRequestError: boolean,
  hasVacationTypesError: boolean;
  isDepartmentShiftOfferLoading?: boolean;
  isDepartmentShiftOffersLoading: boolean;
  isDepartmentShiftSwapLoading?: boolean;
  isDepartmentShiftSwapsLoading: boolean;
  isDepartmentShiftTimeLoading: boolean;
  isDepartmentShiftTimesLoading: boolean;
  isDepartmentsLoading: boolean;
  isDepartmentTransactionLoading: boolean;
  isDepartmentTransactionsLoading: boolean;
  isDepartmentVacationRequestLoading: boolean;
  isMoreDepartmentShiftOffersLoading: boolean;
  isMoreDepartmentShiftSwapsLoading: boolean;
  isMoreDepartmentShiftTimesLoading: boolean;
  isMoreShiftTimeRequestLoading: boolean;
  isMoreDepartmentTransactionsLoading: boolean;
  isMoreDepartmentVacationRequestLoading: boolean;
  isMoreTransactionRequestLoading: boolean;
  isMoreVacationRequestLoading: boolean;
  isShiftOfferDepartmentsLoading: boolean;
  isShiftOfferUpdateLoading: boolean;
  isShiftSwapDepartmentsLoading: boolean;
  isShiftSwapUpdateLoading: boolean;
  isShiftTimeDepartmentsLoading: boolean;
  isShiftTimeRequestLoading: boolean;
  isShiftTimeUpdateLoading: boolean;
  isTransactionDepartmentsLoading: boolean;
  isTransactionRequestLoading: boolean;
  isVacationRequestCountersLoading: boolean;
  isTransactionUpdateLoading: boolean;
  isVacationRequestLoading: boolean;
  isVacationUpdateLoading?: boolean;
  requestDate: Date
  ruleViolated: string[];
  shiftOffer: IShiftOfferRequest;
  shiftOfferDepartments: IRequestDepartment[],
  shiftSwap: IShiftSwapRequest;
  shiftSwapDepartments: IRequestDepartment[],
  shiftTime: IShiftTimeRequest;
  shiftTimeDepartments: IRequestDepartment[];
  shiftTimeRequest: IShiftTimeRequest;
  shiftTimeRequests: IShiftTimeRequests;
  shiftTimeRequestsMetaData: HttpMetadataPagingResponse;
  showVacationRequestModal: boolean;
  transaction: ITransactionRequest;
  transactionDepartments: IRequestDepartment[];
  transactionRequests: ITransactionRequests;
  transactionRequestsMetaData: HttpMetadataPagingResponse;
  vacationRequest: IVacationRequest;
  vacationRequests: IVacationRequests;
  vacationRequestsMetaData: HttpMetadataPagingResponse;
  vacationTypes: IVacationType;
}

export const initialState: RequestState = {
  vacationRequestCounters: [],
  isVacationRequestCountersLoading: false,
  activeDepartment: '',
  activeInbox: RequestType.Vacation,
  counts: null,
  departmentMetaData: null,
  departments: null,
  departmentVacationRequests: null,
  departmentShiftOffers: null,
  departmentShiftSwaps: null,
  departmentShiftTimeRequests: null,
  departmentShiftTimes: null,
  departmentTransactionRequests: null,
  departmentTransactions: null,
  didVacationRequestLoad: false,
  didVacationTypesLoad: false,
  filter: {
    vacation: RequestStatus.Open,
    shiftOffer: RequestStatus.Open,
    shiftSwap: RequestStatus.Pending,
    transaction: RequestStatus.Open,
    shiftTime: RequestStatus.Open
  },
  hasShiftTimeRequestError: false,
  hasTransactionRequestError: false,
  hasVacationRequestError: false,
  hasVacationTypesError: false,
  isDepartmentShiftOffersLoading: false,
  isDepartmentShiftSwapsLoading: false,
  isDepartmentShiftTimesLoading: false,
  isDepartmentsLoading: false,
  isDepartmentTransactionLoading: false,
  isDepartmentShiftTimeLoading: false,
  isDepartmentTransactionsLoading: false,
  isDepartmentVacationRequestLoading: false,
  isMoreDepartmentShiftOffersLoading: false,
  isMoreDepartmentShiftSwapsLoading: false,
  isMoreDepartmentShiftTimesLoading: false,
  isMoreDepartmentTransactionsLoading: false,
  isMoreDepartmentVacationRequestLoading: false,
  isMoreShiftTimeRequestLoading: false,
  isMoreTransactionRequestLoading: false,
  isMoreVacationRequestLoading: false,
  isShiftOfferDepartmentsLoading: false,
  isShiftOfferUpdateLoading: false,
  isShiftSwapDepartmentsLoading: false,
  isShiftSwapUpdateLoading: false,
  isShiftTimeRequestLoading: false,
  isTransactionDepartmentsLoading: false,
  isShiftTimeDepartmentsLoading: false,
  isTransactionRequestLoading: false,
  isTransactionUpdateLoading: false,
  isShiftTimeUpdateLoading: false,
  isVacationRequestLoading: false,
  isVacationUpdateLoading: false,
  requestDate: null,
  ruleViolated: null,
  shiftOffer: null,
  shiftOfferDepartments: null,
  shiftSwap: null,
  shiftSwapDepartments: null,
  shiftTime: null,
  shiftTimeDepartments: null,
  shiftTimeRequest: null,
  shiftTimeRequestsMetaData: null,
  showVacationRequestModal: false,
  transaction: null,
  transactionDepartments: null,
  transactionRequests: null,
  shiftTimeRequests: null,
  transactionRequestsMetaData: null,
  vacationRequest: null,
  vacationRequests: null,
  vacationRequestsMetaData: null,
  vacationTypes: null,
};

const requests = produce((draft: RequestState, action: Actions) => {
  switch (action.type) {
    case ActionType.GetRequestCountsFulfilled:
      draft.counts = action.payload.data;
      break;

    case ActionType.GetVacationRequestFulfilled:
      draft.vacationRequest = action.payload.data;
      break;

    case ActionType.GetTransactionRequestFulfilled:
      draft.transaction = action.payload.data;
      break;

    case ActionType.GetShiftTimeRequestFulfilled:
      draft.shiftTimeRequest = action.payload.data;
      break;

    case ActionType.GetVacationRequestCounters:
      draft.isVacationRequestCountersLoading = true;
      break;

    case ActionType.GetVacationRequestCountersFulfilled:
      draft.isVacationRequestCountersLoading = false;
      draft.vacationRequestCounters = action.payload.data;
      break;

    case ActionType.GetVacationRequestCountersRejected:
      draft.isVacationRequestCountersLoading = false;
      break;

    case ActionType.GetVacationRequests:
      if (action.payload.offset === 0) {
        draft.vacationRequestsMetaData = null;
        draft.isVacationRequestLoading = true;
      } else {
        draft.isMoreVacationRequestLoading = true;
      }
      break;

    case ActionType.GetTransactionRequests:
      if (action.payload.offset === 0) {
        draft.transactionRequestsMetaData = null;
        draft.isTransactionRequestLoading = true;
      } else {
        draft.isMoreTransactionRequestLoading = true;
      }
      break;

    case ActionType.GetShiftTimeRequests:
      if (action.payload.offset === 0) {
        draft.shiftTimeRequestsMetaData = null;
        draft.isShiftTimeRequestLoading = true;
      } else {
        draft.isMoreShiftTimeRequestLoading = true;
      }
      break;

    case ActionType.CreateVacationRequest:
      draft.isVacationRequestLoading = true;
      break;

    case ActionType.CreateVacationRequestFulfilled:
      draft.isVacationRequestLoading = false;
      draft.showVacationRequestModal = false;
      draft.requestDate = null;
      break;

    case ActionType.GetVacationRequestsFulfilled:
      if (action.payload.isInitialLoad) {
        draft.isVacationRequestLoading = false;
        draft.vacationRequests = {};
      } else {
        draft.isMoreVacationRequestLoading = false;
      }

    case ActionType.GetVacationRequestsFulfilled:
      if (action.payload.isInitialLoad) {
        draft.isVacationRequestLoading = false;
        draft.vacationRequests = {};
      } else {
        draft.isMoreVacationRequestLoading = false;
      }

      draft.vacationRequestsMetaData = action.payload.meta;
      const vacationRequests = action.payload.data.reduce((accu: IVacationRequests, vacationRequest: IVacationRequest) => {
        accu[vacationRequest.id] = vacationRequest;
        return accu;
      }, {});

      draft.vacationRequests = { ...draft.vacationRequests, ...vacationRequests };
      break;

    case ActionType.GetTransactionRequestsFulfilled:
      if (action.payload.isInitialLoad) {
        draft.isTransactionRequestLoading = false;
        draft.transactionRequests = {};
      } else {
        draft.isMoreTransactionRequestLoading = false;
      }

      draft.transactionRequestsMetaData = action.payload.meta;
      const transactionRequests = action.payload.data.reduce((accu: ITransactionRequests, transactionRequest: ITransactionRequest) => {
        accu[transactionRequest.id] = transactionRequest;
        return accu;
      }, {});

      draft.transactionRequests = { ...draft.transactionRequests, ...transactionRequests };
      break;

    case ActionType.GetShiftTimeRequestsFulfilled:
      if (action.payload.isInitialLoad) {
        draft.isShiftTimeRequestLoading = false;
        draft.shiftTimeRequests = {};
      } else {
        draft.isMoreShiftTimeRequestLoading = false;
      }

      draft.shiftTimeRequestsMetaData = action.payload.meta;
      const shiftTimeRequests = action.payload.data.reduce((accu: IShiftTimeRequests, shiftTimeRequest: IShiftTimeRequest) => {
        accu[shiftTimeRequest.id] = shiftTimeRequest;
        return accu;
      }, {});

      draft.shiftTimeRequests = { ...draft.shiftTimeRequests, ...shiftTimeRequests };
      break;

    case ActionType.GetDepartmentVacationRequests:
      if (action.payload.offset === 0) {
        draft.departmentMetaData = null;
        draft.isDepartmentVacationRequestLoading = true;
      } else {
        draft.isMoreDepartmentVacationRequestLoading = true;
      }
      break;

    case ActionType.GetDepartmentVacationRequestsRejected:
      draft.isDepartmentVacationRequestLoading = false;
      draft.isMoreDepartmentVacationRequestLoading = false;
      break;

    case ActionType.GetDepartmentVacationRequestsFulfilled:
      if (action.payload.isInitialLoad) {
        draft.isDepartmentVacationRequestLoading = false;
        draft.departmentVacationRequests = {};
      } else {
        draft.isMoreDepartmentVacationRequestLoading = false;
      }

      draft.departmentMetaData = action.payload.meta;
      draft.departmentVacationRequests = action.payload.data.reduce((accu: IVacationRequests, vacationRequest: IVacationRequest) => {
        accu[vacationRequest.id] = vacationRequest;
        return accu;
      }, draft.departmentVacationRequests);
      break;

    case ActionType.GetDepartmentVacationRequest:
      draft.isVacationRequestLoading = true;
      break;

    case ActionType.GetDepartmentVacationRequestFulfilled:
      draft.isVacationRequestLoading = false;

      draft.vacationRequest = action.payload;
      break;

    case ActionType.GetDepartmentVacationRequestRejected:
      draft.isVacationRequestLoading = false;
      break;

    case ActionType.GetDepartmentShiftTimeRejected:
      draft.isDepartmentShiftTimesLoading = false;
      draft.isMoreDepartmentShiftTimesLoading = false;
      break;

    case ActionType.GetDepartmentShiftOffers:
      if (action.payload.offset === 0) {
        draft.departmentMetaData = null;
        draft.isDepartmentShiftOffersLoading = true;
      } else {
        draft.isMoreDepartmentShiftOffersLoading = true;
      }
      break;

    case ActionType.GetDepartmentShiftOffersRejected:
      draft.isDepartmentShiftOffersLoading = false;
      draft.isMoreDepartmentShiftOffersLoading = false;
      if (action.payload.isInitialLoad) {
        draft.departmentShiftOffers = {};
      }
      break;

    case ActionType.GetDepartmentShiftOffersFulfilled:
      if (action.payload.isInitialLoad) {
        draft.isDepartmentShiftOffersLoading = false;
        draft.departmentShiftOffers = {};
      } else {
        draft.isMoreDepartmentShiftOffersLoading = false;
      }

      draft.departmentMetaData = action.payload.meta;
      draft.departmentShiftOffers = action.payload.data.reduce((accu: IShiftOfferRequests, shiftOffer: IShiftOfferRequest) => {
        accu[shiftOffer.id] = shiftOffer;
        return accu;
      }, draft.departmentShiftOffers);
      break;

    case ActionType.GetDepartmentShiftOffer:
      draft.isDepartmentShiftOfferLoading = true;
      break;

    case ActionType.GetDepartmentShiftOfferFulfilled:
      if (draft.shiftOffer?.id !== action.payload.data.id) draft.ruleViolated = [];
      draft.isDepartmentShiftOfferLoading = false;
      const shiftOffer = action.payload.data;
      if (draft.departmentShiftOffers?.[shiftOffer.id]) {
        draft.departmentShiftOffers[shiftOffer.id] = shiftOffer;
      }
      draft.shiftOffer = shiftOffer;
      break;

    case ActionType.GetDepartmentShiftSwaps:
      if (action.payload.offset === 0) {
        draft.departmentMetaData = null;
        draft.isDepartmentShiftSwapsLoading = true;
      } else {
        draft.isMoreDepartmentShiftSwapsLoading = true;
      }
      break;

    case ActionType.GetDepartmentShiftSwapsRejected:
      draft.isDepartmentShiftSwapsLoading = false;
      draft.isMoreDepartmentShiftSwapsLoading = false;
      if (action.payload.isInitialLoad) {
        draft.departmentShiftSwaps = {};
      }
      break;

    case ActionType.GetDepartmentShiftSwapsFulfilled:
      if (action.payload.isInitialLoad) {
        draft.isDepartmentShiftSwapsLoading = false;
        draft.departmentShiftSwaps = {};
      } else {
        draft.isMoreDepartmentShiftSwapsLoading = false;
      }

      draft.departmentMetaData = action.payload.meta;
      draft.departmentShiftSwaps = action.payload.data.reduce((accu: IShiftSwapRequests, shiftSwap: IShiftSwapRequest) => {
        accu[shiftSwap.id] = shiftSwap;
        return accu;
      }, draft.departmentShiftSwaps);
      break;

    case ActionType.GetDepartmentShiftSwap:
      draft.isDepartmentShiftSwapLoading = true;
      break;

    case ActionType.GetDepartmentShiftSwapFulfilled:
      draft.isDepartmentShiftSwapLoading = false;
      const shiftSwap = action.payload.data;
      if (draft.departmentShiftSwaps?.[shiftSwap.id]) {
        draft.departmentShiftSwaps[shiftSwap.id] = shiftSwap;
      }
      draft.shiftSwap = shiftSwap;
      break;


    case ActionType.GetRequestDepartmentsRejected:
      draft.isDepartmentsLoading = false;
      break;

    case ActionType.GetRequestDepartments:
      draft.isDepartmentsLoading = true;
      break;

    case ActionType.GetRequestDepartmentsFulfilled:
      draft.isDepartmentsLoading = false;
      draft.departments = action.payload.data;
      break;

    case ActionType.GetRequestDepartmentsRejected:
      draft.isDepartmentsLoading = false;
      break;

    case ActionType.GetShiftOfferDepartments:
      draft.isShiftOfferDepartmentsLoading = true;
      break;

    case ActionType.GetShiftOfferDepartmentsFulfilled:
      draft.isShiftOfferDepartmentsLoading = false;
      draft.shiftOfferDepartments = action.payload.data;
      break;

    case ActionType.GetShiftOfferDepartmentsRejected:
      draft.isShiftOfferDepartmentsLoading = false;
      break;

    case ActionType.GetShiftSwapDepartments:
      draft.isShiftSwapDepartmentsLoading = true;
      break;

    case ActionType.GetShiftSwapDepartmentsFulfilled:
      draft.isShiftSwapDepartmentsLoading = false;
      draft.shiftSwapDepartments = action.payload.data;
      break;

    case ActionType.GetShiftSwapDepartmentsRejected:
      draft.isShiftSwapDepartmentsLoading = false;
      break;

    case ActionType.CreateVacationRequestRejected:
      draft.isVacationRequestLoading = false;
      draft.hasVacationRequestError = true;
      break;

    case ActionType.GetVacationRequestsRejected:
      draft.isVacationRequestLoading = false;
      draft.hasVacationRequestError = true;
      break;

    case ActionType.GetTransactionRequestsRejected:
      draft.isTransactionRequestLoading = false;
      draft.hasTransactionRequestError = true;
      break;

    case ActionType.GetShiftTimeRequestsRejected:
      draft.isShiftTimeRequestLoading = false;
      draft.hasShiftTimeRequestError = true;
      break;

    case ActionType.GetVacationTypesFulfilled:
      draft.hasVacationRequestError = false;
      draft.didVacationTypesLoad = true;
      draft.vacationTypes = action.payload.data;
      break;

    case ActionType.GetVacationTypesRejected:
      draft.didVacationTypesLoad = false;
      draft.hasVacationTypesError = true;
      break;

    case ActionType.GetVacationTypes:
      draft.didVacationTypesLoad = false;
      draft.hasVacationRequestError = false;
      break;

    case ActionType.ToggleVacationRequestModal:
      if (action.payload) {
        draft.requestDate = action.payload.date;
      } else {
        draft.requestDate = null;
      }
      draft.showVacationRequestModal = !draft.showVacationRequestModal;
      break;

    case ActionType.ReadVacationRequestFulfilled:
      if (action.payload.isDepartmentRequest) {
        draft.departmentVacationRequests[action.payload.id].hasRead = true;
      } else {
        draft.vacationRequests[action.payload.id].hasRead = true;
      }
      break;

    case ActionType.ReadTransactionRequestFulfilled:
      if (action.payload.isDepartmentRequest) {
        draft.departmentTransactions[action.payload.id].hasRead = true;
      } else {
        draft.transactionRequests[action.payload.id].hasRead = true;
      }
      break;
    case ActionType.SetRequestFilter:
      draft.filter[action.payload.type] = action.payload.filter;
      break;
    case ActionType.SetActiveInbox:
      draft.activeInbox = action.payload.type;
      break;
    case ActionType.SetActiveDepartment:
      draft.activeDepartment = action.payload.departmentId;
      break;

    case ActionType.ReadShiftTimeRequestFulfilled:
      if (action.payload.isDepartmentRequest) {
        draft.departmentShiftTimeRequests[action.payload.id].hasRead = true;
      } else {
        draft.shiftTimeRequests[action.payload.id].hasRead = true;
      }
      break;
    case ActionType.SetRequestFilter:
      draft.filter[action.payload.type] = action.payload.filter;
      break;
    case ActionType.SetActiveInbox:
      draft.activeInbox = action.payload.type;
      break;
    case ActionType.SetActiveDepartment:
      draft.activeDepartment = action.payload.departmentId;
      break;

    case ActionType.DeleteVacationRequestFulfilled:
      if (draft.vacationRequests?.[action.payload.id]) delete draft.vacationRequests[action.payload.id];
      if (draft?.vacationRequestsMetaData?.totalCount) {
        draft.vacationRequestsMetaData = { count: draft?.vacationRequestsMetaData?.count - 1, totalCount: draft?.vacationRequestsMetaData?.totalCount - 1 };
      }
      break;

    case ActionType.DeleteTransactionRequestFulfilled:
      if (draft.transactionRequests?.[action.payload.id]) delete draft.transactionRequests[action.payload.id];
      if (draft?.transactionRequestsMetaData?.totalCount) {
        draft.transactionRequestsMetaData = { count: draft?.transactionRequestsMetaData?.count - 1, totalCount: draft?.transactionRequestsMetaData?.totalCount - 1 };
      }
      break;

    case ActionType.DeleteShiftTimeRequestFulfilled:
      if (draft.shiftTimeRequests?.[action.payload.id]) delete draft.shiftTimeRequests[action.payload.id];
      if (draft?.shiftTimeRequestsMetaData?.totalCount) {
        draft.shiftTimeRequestsMetaData = { count: draft?.shiftTimeRequestsMetaData?.count - 1, totalCount: draft?.shiftTimeRequestsMetaData?.totalCount - 1 };
      }
      break;

    case ActionType.GetDepartmentTransactions:
      if (action.payload.offset === 0) {
        draft.departmentMetaData = null;
        draft.isDepartmentTransactionsLoading = true;
      } else {
        draft.isMoreDepartmentTransactionsLoading = true;
      }
      break;

    case ActionType.GetDepartmentTransactionsFulfilled:
      if (action.payload.isInitialLoad) {
        draft.isDepartmentTransactionsLoading = false;
        draft.departmentTransactions = {};
      } else {
        draft.isMoreDepartmentTransactionsLoading = false;
      }

      draft.departmentMetaData = action.payload.meta;
      draft.departmentTransactions = action.payload.data.reduce((accu: IDepartmentTransactions, transaction: ITransactionRequest) => {
        accu[transaction.id] = transaction;
        return accu;
      }, draft.departmentTransactions);
      break;

    case ActionType.GetDepartmentTransactionsRejected:
      draft.isDepartmentTransactionsLoading = false;
      draft.isMoreDepartmentTransactionsLoading = false;
      if (action.payload.isInitialLoad) {
        draft.departmentTransactions = {};
      }
      break;

    case ActionType.GetTransactionDepartments:
      draft.isTransactionDepartmentsLoading = true;
      break;

    case ActionType.GetTransactionDepartmentsFulfilled:
      draft.isTransactionDepartmentsLoading = false;
      draft.transactionDepartments = action.payload.data;
      break;

    case ActionType.GetTransactionDepartmentsRejected:
      draft.isTransactionDepartmentsLoading = false;
      break;

    case ActionType.GetDepartmentTransaction:
      draft.isDepartmentTransactionLoading = true;
      break;

    case ActionType.GetDepartmentTransactionFulfilled:
      draft.isDepartmentTransactionLoading = false;
      const transaction = action.payload.data;
      if (draft.departmentTransactions?.[transaction.id]) {
        draft.departmentTransactions[transaction.id] = transaction;
      }
      draft.transaction = transaction;
      break;

    case ActionType.GetDepartmentShiftTimes:
      if (action.payload.offset === 0) {
        draft.departmentMetaData = null;
        draft.isDepartmentShiftTimesLoading = true;
      } else {
        draft.isMoreDepartmentShiftTimesLoading = true;
      }
      break;

    case ActionType.GetDepartmentShiftTimesFulfilled:
      if (action.payload.isInitialLoad) {
        draft.isDepartmentShiftTimesLoading = false;
        draft.departmentShiftTimes = {};
      } else {
        draft.isMoreDepartmentShiftTimesLoading = false;
      }

      draft.departmentMetaData = action.payload.meta;
      draft.departmentShiftTimes = action.payload.data.reduce((accu: IDepartmentShiftTimes, shiftTime: IShiftTimeRequest) => {
        accu[shiftTime.id] = shiftTime;
        return accu;
      }, draft.departmentShiftTimes);
      break;

    case ActionType.GetDepartmentShiftTimesRejected:
      draft.isDepartmentShiftTimesLoading = false;
      draft.isMoreDepartmentShiftTimesLoading = false;
      if (action.payload.isInitialLoad) {
        draft.departmentShiftTimes = {};
      }
      break;

    case ActionType.GetShiftTimeDepartments:
      draft.isShiftTimeDepartmentsLoading = true;
      break;

    case ActionType.GetShiftTimeDepartmentsFulfilled:
      draft.isShiftTimeDepartmentsLoading = false;
      draft.shiftTimeDepartments = action.payload.data;
      break;

    case ActionType.GetShiftTimeDepartmentsRejected:
      draft.isShiftTimeDepartmentsLoading = false;
      break;

    case ActionType.GetDepartmentShiftTime:
      draft.isDepartmentShiftTimeLoading = true;
      break;

    case ActionType.GetDepartmentShiftTimeFulfilled:
      draft.isDepartmentShiftTimeLoading = false;
      const shiftTime = action.payload.data;
      if (draft.departmentShiftTimes?.[shiftTime.id]) {
        draft.departmentShiftTimes[shiftTime.id] = shiftTime;
      }
      draft.shiftTime = shiftTime;
      break;

    case ActionType.FillInShiftOffer:
    case ActionType.CancelShiftOffer:
      draft.isShiftOfferUpdateLoading = true;
      draft.ruleViolated = null;
      break;

    case ActionType.ApproveShiftSwap:
    case ActionType.DeclineShiftSwap:
      draft.isShiftSwapUpdateLoading = true;
      break;

    case ActionType.ApproveTransaction:
    case ActionType.DeclineTransaction:
      draft.isTransactionUpdateLoading = true;
      break;

    case ActionType.ApproveShiftTime:
    case ActionType.DeclineShiftTime:
      draft.isShiftTimeUpdateLoading = true;
      break;

    case ActionType.UpdateVacationRequest:
      draft.isVacationUpdateLoading = true;
      break;

    case ActionType.UpdateVacationRequestFulfilled:
    case ActionType.UpdateVacationRequestRejected:
      draft.isVacationUpdateLoading = false;
      break;

    case ActionType.FillInShiftOfferFulfilled:
      draft.isShiftOfferUpdateLoading = false;
      draft.ruleViolated = action.payload.ruleViolated;
      break;
    case ActionType.FillInShiftOfferRejected:
    case ActionType.CancelShiftOfferFulfilled:
    case ActionType.CancelShiftOfferRejected:
      draft.isShiftOfferUpdateLoading = false;
      draft.ruleViolated = null;
      break;

    case ActionType.ApproveShiftSwapFulfilled:
    case ActionType.ApproveShiftSwapRejected:
    case ActionType.DeclineShiftSwapFulfilled:
    case ActionType.DeclineShiftSwapRejected:
      draft.isShiftSwapUpdateLoading = false;
      break;

    case ActionType.ApproveTransactionFulfilled:
    case ActionType.ApproveTransactionRejected:
    case ActionType.DeclineTransactionFulfilled:
    case ActionType.DeclineTransactionRejected:
      draft.isTransactionUpdateLoading = false;
      break;

    case ActionType.ApproveShiftTimeFulfilled:
    case ActionType.ApproveShiftTimeRejected:
    case ActionType.DeclineShiftTimeFulfilled:
    case ActionType.DeclineShiftTimeRejected:
      draft.isShiftTimeUpdateLoading = false;
      break;
  }
}, initialState);

export default requests;