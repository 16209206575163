import React, { FC, useEffect } from 'react';
import { Icon } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { RequestStatus } from '../../../../models';
import { requestsActions, requestsSelectors } from '../../../../redux';
import { date, requestsUtils, transactionUtils, translations } from '../../../../utils';
import Footer from './RequestFooter';

interface Props {
  id: string;
  isLoading: boolean;
  status: RequestStatus;
  departmentId?: string;
}

const RequestDetail: FC<Props> = ({ id, isLoading, departmentId }) => {
  const dispatch = useDispatch();

  const request = useSelector((state: AppState) => requestsSelectors.getDepartmentTransaction(state, id));

  useEffect(() => {
    if (id) {
      dispatch(new requestsActions.GetDepartmentTransactionAction({
        id,
        departmentId,
        onError: (error) => {
          toast.error(error.detail);
        },
      }));
    }
  }, [id]);

  if (!request) return <section className="request-detail empty">{translations.getLabel('lblEmpyRequestInbox')}</section>;

  const relativeCreatedDate = requestsUtils.getRelativeDate(request.createdAt, date.format);
  return (
    <div className="request-detail department-request-detail">
      <div>
        <p>{isLoading ? <Skeleton width={300} /> : (relativeCreatedDate.shouldTranslate ? `${translations.getLabel(relativeCreatedDate.value)} ${relativeCreatedDate.time}` : `${relativeCreatedDate.value}`)}</p>
        <p className="title">{isLoading ? <Skeleton width={400} /> : translations.getLabel('titleTransactionRequest')}</p>
        <p className="sub-title">{isLoading ? <Skeleton width={200} /> : translations.getLabel('lblBy')} {`${request.requesterFirstName} ${request.requesterLastName}`}</p>
        <div className="request-message-container">
          <div className="data-field">
            <p className="shift-numbers"><Icon tag="CalendarIcon" color="primaryDark" /> {translations.getLabel('lblDate')}:</p>
            <p> {format(parseISO(request.date), 'dd/MM/yyyy')}</p>
          </div>
          <div className="data-field">
            <p className="shift-numbers"><Icon tag="DashboardIcon" color="primaryDark" /> {translations.getLabel('lblType')}:</p>
            <p> {request.name}</p>
          </div>
          <div className="data-field">
            <p className="shift-numbers"><div className="empty-icon icon" />{translations.getLabel('lblValue')}:</p>
            <p>{transactionUtils.getTransactionValue(request)}</p>
          </div>
          <div className="data-field">
            <p className="shift-numbers"><Icon tag="ChatIcon" color="primaryDark" /> {translations.getLabel('lblReason')}:</p>
            <p> {request.reason ? request.reason : translations.getLabel('lblNoRequestReason')}</p>
          </div>
        </div>
      </div>
      <div className="hr" />
      {request &&
        <div className="footer">
          <Footer id={id} departmentId={departmentId} />
        </div>
      }
    </div >
  );
};

export default RequestDetail;
