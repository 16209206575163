import React, { FC } from 'react';
import { Icon } from 'react-ess-components';
import { IconName } from 'react-ess-components/build/assets/icons';
import { NavLink } from 'react-router-dom';

import { translations } from '../../utils';

type Props = {
  label: string;
  icon?: IconName;
  to: string;
}

const NavBarButton: FC<Props> = ({ label, icon = null, to }) => {
  return (
    <NavLink activeClassName="active" className="navbar-button" to={to}>
      {
        icon &&
        <Icon tag={icon} title={label} />
      }
      {
        !icon && label &&
        <span className="label">{translations.getLabel(label)}</span>
      }
      <span className="hidden">{translations.getLabel(label)}</span>
    </NavLink>
  );
};

export default NavBarButton;
