import { ISOLanguage } from '../models';

export const getLanguageLabel = (language: ISOLanguage) => {
  switch (language) {
    case ISOLanguage.En:
      return 'lblEnglish';
    case ISOLanguage.Be:
    case ISOLanguage.Nl:
      return 'lblDutch';
    case ISOLanguage.Fr:
      return 'lblFrench';
    case ISOLanguage.De:
      return 'lblGerman';
    default:
      break;
  }
};