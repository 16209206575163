import React, { FC } from 'react';
import { Button, Icon } from 'react-ess-components';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { IShiftTimeAdjustmentRequest, RequestStatus } from '../../../models';
import { adjustedShiftTimeActions } from '../../../redux';
import { date, requestsUtils, translations } from '../../../utils';

interface Props {
  request: IShiftTimeAdjustmentRequest;
  departmentId?: string;
  closeParentPopup?: () => void
}
const Footer: FC<Props> = ({ request, departmentId, closeParentPopup }) => {
  const dispatch = useDispatch();

  const className = classNames('status', {
    accepted: request.status === RequestStatus.Accepted,
    denied: request.status === RequestStatus.Denied,
  });

  const renderCommentSection = () => {
    const updatedAt = requestsUtils.getRelativeDate(request.updatedAt, date.format);
    let startDate = updatedAt.value;
    if (updatedAt.shouldTranslate) {
      startDate = `${translations.getLabel(updatedAt.value)} ${updatedAt.time}`;
    }
    return (
      <>
        <p> {translations.getLabel('lblRequestUpdateMessage', { startDate, updater: request.reviewerName })}</p>
        {!!request.reviewerNote && <p>{translations.getLabel('lblRequestComment', { comment: request.reviewerNote })}</p>}
      </>
    );
  };

  const handleCancelAdjustedShiftTimeRequest = () => {
    dispatch(new adjustedShiftTimeActions.CancelAdjustShiftTimeRequestAction({
      shiftTimeAdjustmentId: request.id,
      startDate: request.startDate,
      endDate: request.endDate,
      onSuccess: () => closeParentPopup(),
    }));
  };

  if (request.status === RequestStatus.Open) {
    return (
      <Button theme="inverse" className="adjust-button" title={translations.getLabel('cancel')} onClick={handleCancelAdjustedShiftTimeRequest} >{translations.getLabel('cancel')}</Button>
    );
  }

  if (request.status === RequestStatus.Denied) {
    return (
      <>
        <div className={className}>
          <Icon tag="CrossIcon" small color="error" large />
          <p>{requestsUtils.getRequestStatus(request.status, translations.getLabel)}</p>
        </div>
        {renderCommentSection()}
      </>
    );
  }

  if (request.status === RequestStatus.Accepted) {
    return (
      <>
        <div className={className}>
          <Icon tag="CheckIcon" small color="success" />
          <p>{requestsUtils.getRequestStatus(request.status, translations.getLabel)}</p>
        </div>
        {renderCommentSection()}
      </>
    );
  }

  return null;
};

export default Footer;
