import { HttpClient } from '../http';

import { Period, IPeriodDetail, IDepartment, IEmployee, ILabelAndValue, UserRight } from '../models';

export const getMyDepartments = async (type: UserRight): Promise<ILabelAndValue[]> => {
  const result = await HttpClient.get<{ data: IDepartment[] }>('/v1/management/departments/access', { type });
  return (result.data || []).map((department) => ({ value: department.id, label: department.name }));
};

export const getCompanyDepartments = async (): Promise<ILabelAndValue[]> => {
  const result = await HttpClient.get<{ data: IDepartment[] }>('/v1/management/departments/company-departments');
  return (result.data || []).map((department) => ({ value: department.id, label: department.name }));
};

export const getPeriods = async (id: string): Promise<IPeriodDetail[][]> => {
  const response = await Promise.all([Period.AvailabilityRequestPeriod, Period.VacationRequestPeriod, Period.ScheduleVisibilityPeriod, Period.ShiftTimeAdjustmentPeriod].map(type => HttpClient.get<{ data: IPeriodDetail[] }>(`/v1/requests/departments/${id}/request-periods`, { type })));
  return response.map(res => res.data);
};

export const getAbsolutePeriod = async (startDate: string, endDate: string): Promise<{ startDate?: string, endDate?: string }> => {
  const response = await HttpClient.get<{ data: IPeriodDetail }>('/v1/requests/relative-to-absolute', { startDate, endDate });
  return response?.data;
};

export const updatePeriods = async (id: string, periods: IPeriodDetail[]): Promise<void> => {
  return HttpClient.put(`/v1/requests/departments/${id}/request-periods`, periods.filter(period => !!Object.keys(period).length));
};

export const resetPeriod = async (companyId: string, type: Period): Promise<{ data: IPeriodDetail }> => {
  const response = await HttpClient.get<{ data: IPeriodDetail[] }>(`/v1/management/companies/${companyId}/request-periods`, { type });
  return { data: response.data[0] };
};

export const getEmployeesByDepartment = async (search?: string): Promise<IEmployee[]> => {
  const params: { search?: string } = {};
  if (search) params.search = search;
  const result = await HttpClient.get<{ data: IEmployee[] }>('/v1/departments/co-workers', params);
  return result.data;
};
