import { createSelector } from 'reselect';
import { DepartmentState } from './reducer';
import { AppState } from '../rootReducer';

import { Period, ILabelAndValue, UserRight, IEmployee } from '../../models';
import { identifiers } from '../../constants';
import { getUser } from '../user/selectors';

const selectNode = (state: AppState) => state.departments;

export const getEmployees = createSelector(
  selectNode,
  (state: DepartmentState): ILabelAndValue<IEmployee>[] => state.employees.map(employee => ({ label: `${employee.firstName} ${employee.lastName}`, value: employee.employmentId, data: employee })),
);

export const getEmployee = (state: AppState, id: string) => {
  const departments = selectNode(state);
  const user = getUser(state);
  if (id === identifiers.me || id === user?.employmentId) return user;
  return departments.employees?.find(employee => employee.employmentId === id);
};

export const getDepartmentsForRight = (state: AppState, right: UserRight) => state.departments.departmentsPerRight?.[right] || [];

export const getPeriods = (state: AppState, type: Period.AvailabilityRequestPeriod | Period.VacationRequestPeriod | Period.ScheduleVisibilityPeriod | Period.ShiftTimeAdjustmentPeriod) => state.departments.periods[type];

export const hasServerError = createSelector(
  selectNode,
  (state: DepartmentState) => state.hasServerError,
);

export const getCompanyDepartments = createSelector(
  selectNode,
  (state: DepartmentState) => state.companyDepartments,
);

export const getCompanyDepartmentsError = createSelector(
  selectNode,
  (state: DepartmentState) => state.companyDepartmentsError,
);

export const isEmployeesLoading = createSelector(
  selectNode,
  (state: DepartmentState) => state.isEmployeesLoading,
);

export const isLoading = createSelector(
  selectNode,
  (state: DepartmentState) => state.isLoading,
);

export const isPeriodLoading = createSelector(
  selectNode,
  (state: DepartmentState) => state.isPeriodLoading,
);

export const isAbsolutePeriodLoading = createSelector(
  selectNode,
  (state: DepartmentState) => state.isAbsolutePeriodLoading,
);

export const getAbsolutePeriod = createSelector(
  selectNode,
  (state: DepartmentState) => state.absolutePeriod,
);