import React, { FC, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { differenceInCalendarDays, eachDayOfInterval } from 'date-fns';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { identifiers } from '../../../../constants';
import { useToggle } from '../../../../hooks';
import { IAvailability, IDayPlanning, ITimeType, TimeScale } from '../../../../models';
import { configSelectors, scheduleSelectors } from '../../../../redux';
import { scheduleUtils } from '../../../../utils';
import { OpenAvailabilitiesFunction, OpenPlanningFunction } from '../../schedule.models';
import DetailPopup from '../detailPopup/DetailPopup';
import DayScaffold from './DayScaffold';
import MonthDay from './MonthDay';

import './monthRoster.scss';

interface Props {
  selectedDate: Date;
  userId: string;
  changeTimeScale: (timeScale: TimeScale, date: Date) => void;
}

const MonthRoster: FC<Props> = ({ selectedDate, userId, changeTimeScale }) => {
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const hasError = useSelector(scheduleSelectors.hasPlanningError);
  const { startDate, endDate } = scheduleUtils.getBoundsOfRange(TimeScale.Month, selectedDate, true, weekStartsOn);
  const planning = useSelector((state: AppState) => scheduleSelectors.getPlanningForUser(state, userId, startDate, endDate, true));
  const [isPopupOpen, setIsPopupOpen] = useToggle(false);
  const [openPlanning, setOpenPlanning] = useState<IDayPlanning>(null);
  const [openAvailabilities, setOpenAvailabilities] = useState<{ availabilities: IAvailability[]; timeTypes: ITimeType[] }>(null);
  const [dayRef, setDayRef] = useState<HTMLElement>(null);
  const rosterRef = useRef(null);

  const isLoading = planning.length < differenceInCalendarDays(endDate, startDate);

  const togglePlanning: OpenPlanningFunction = (shifts, ref) => {
    if (isPopupOpen && ref === dayRef) {
      closeShift();
    } else {
      setIsPopupOpen(true);
      setOpenPlanning(shifts);
      setDayRef(ref);
    }
  };

  const toggleAvailabilities: OpenAvailabilitiesFunction = (availabilities, timeTypes, ref) => {
    if (isPopupOpen && ref === dayRef) {
      closeShift();
    } else {
      setIsPopupOpen(true);
      setOpenAvailabilities({ availabilities, timeTypes });
      setDayRef(ref);
    }
  };

  const closeShift = () => {
    setIsPopupOpen(false);
    setOpenPlanning(null);
    setOpenAvailabilities(null);
    setDayRef(null);
  };

  return (
    <div className="month-roster" ref={rosterRef}>
      <DetailPopup isMyRoster={userId === identifiers.me} isOpen={isPopupOpen} scrollRef={rosterRef.current} planning={openPlanning} {...openAvailabilities} parentRef={dayRef} close={closeShift} />
      <DayScaffold selectedDate={selectedDate} weekStartsOn={weekStartsOn} />
      {eachDayOfInterval({ start: startDate, end: endDate }).map((day, i) => (
        <MonthDay currentDate={day} changeTimeScale={changeTimeScale} selectedDate={selectedDate} planning={planning[i]} key={i} openPlanning={togglePlanning} openAvailabilities={toggleAvailabilities} isLoading={isLoading && !hasError} />
      ))}
    </div>
  );
};

export default MonthRoster;
