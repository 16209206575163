import React, { useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AppState } from '../../../../shared/redux/rootReducer';
import { BackButton, NewsItem } from '../../components';
import { newsActions, newsSelectors } from '../../redux';
import { translations } from '../../utils';

import './newsDetails.scss';

const NewsDetails: React.FC<RouteComponentProps<{ newsId: string }>> = ({ match }) => {
  const dispatch = useDispatch();
  const newsDetail = useSelector(newsSelectors.getNewsDetails);
  const newsItem = useSelector((state: AppState) => newsSelectors.getNewsItem(state, newsDetail?.id));
  const isLoading = useSelector(newsSelectors.isLoading);

  useEffect(() => {
    dispatch(new newsActions.GetNewsDetailAction({
      id: match.params.newsId,
      onError: () => toast.error(translations.getLabel('lblGenericError')),
    }));
  }, [match.params.newsId]);

  return (
    <DocumentTitle title={newsItem ? `${translations.getLabel('titleNews')} - ${newsItem.title}` : translations.getLabel('titleNews')}>
      <div className="page fixed-width">
        <div className="back-button-container">
          <BackButton label={translations.getLabel('btnGoBack')} />
        </div>
        <NewsItem isLoading={isLoading} newsItem={newsDetail} isDetails />
      </div>
    </DocumentTitle>
  );
};

export default NewsDetails;
