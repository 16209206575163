import React, { FC } from 'react';

import { IEmployment } from '../../../../shared/models/employments';

import './EmploymentSelect.scss';

type Props = {
  employments?: IEmployment[];
  switchEmployment: (id: string) => void;
}

const EmploymentSelect: FC<Props> = ({ employments = [], switchEmployment }) => {
  const renderEmployment = (employment: IEmployment) => {
    return (
      <button key={employment.name} className="employment" onClick={() => switchEmployment(employment.id)}>
        <h4>{employment.name}</h4>
        <p>{employment.companyName} - {employment.departmentName}</p>
        <p className="function">{employment.functionName}</p>
      </button>
    );
  };

  return (
    <div className="employments">
      {
        employments.map(renderEmployment)
      }
    </div>
  );
};

export default EmploymentSelect;