import { RegistrationType, ITimeRegistrationType, IInternalTimeRegistration } from '../models';

export const getAvailableTimeTypes = (clockings: IInternalTimeRegistration[], timeRegistrationTypes: ITimeRegistrationType[]): ITimeRegistrationType[] => {
  // Get the active clockings
  const activeClockings = clockings.filter(c => !c.isRemoving && c.isClockedIn);
  let availableTypes = [];
  // If it's the first clocking, it should be a start
  if (activeClockings.length === 0) {
    availableTypes = timeRegistrationTypes.filter(type => type.isStart);
  } else {
    // Do a check if last clocking is a pause
    const pauseClockings = activeClockings.map(clock => clock.isPauze).filter(isPauze => isPauze !== undefined);
    const isLastClockPause = pauseClockings[pauseClockings.length - 1];
    // Filter the available types. 1. No overlap with already running clockings - 2. No informative clockings during pause
    availableTypes = timeRegistrationTypes
      .filter(type => !clockings.find(clock => clock.typeId === type.id && !clock.isRemoving))
      .filter(type => isLastClockPause ? type.type !== RegistrationType.Informative : true);
  }
  return availableTypes;
};
