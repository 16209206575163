import { HttpClient } from '../http';
import { IDocument, IEditDocument, IFileUploadRequestParams } from '../models';

export const deleteDocument = async (documentId: string): Promise<void> => {
  return HttpClient.delete(`/v1/documents/admin/${documentId}`);
};

export const getDocuments = async (search?: string): Promise<IDocument[]> => {
  const params = search ? { search } : null;
  const result = await HttpClient.get<{ data: IDocument[] }>('/v1/documents', params);
  return result.data;
};

export const getDocumentDownloadUrl = async (documentId: string): Promise<string> => {
  const result = await HttpClient.get<{ data: { downloadUrl: string } }>(`/v1/documents/${documentId}/download`);
  return result.data.downloadUrl;
};

interface UpsertDocumentResult { uploadUrl: string };

export const updateDocument = async (documentId: string, body: IEditDocument, fileUploadUtil?: IFileUploadRequestParams): Promise<UpsertDocumentResult> => {
  const result = await HttpClient.put<{ data: UpsertDocumentResult }>(`/v1/documents/admin/${documentId}`, body);

  if (fileUploadUtil) fileUploadUtil.uploadFunction(result.data.uploadUrl, fileUploadUtil.file);
  return result.data;
};

export const uploadDocument = async (document: IEditDocument, fileUploadUtil?: IFileUploadRequestParams): Promise<UpsertDocumentResult> => {
  const result = await HttpClient.post<{ data: UpsertDocumentResult }>('/v1/documents/admin', document);

  if (fileUploadUtil) fileUploadUtil.uploadFunction(result.data.uploadUrl, fileUploadUtil.file);
  return result.data;
};