import React, { FC, useState } from 'react';
import { Button, Orb } from 'react-ess-components';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { addDays, endOfWeek, isSameDay, isSameWeek, parseISO, startOfWeek } from 'date-fns';

import { ISelfRosteringShift, TimeScale } from '../../../../models';
import { useSelfRosterContext, useSelfRosterSelectedShifts } from '../../../../modules';
import { configSelectors } from '../../../../redux';
import { date, scheduleUtils, selfRosterUtils, translations } from '../../../../utils';
import DayScaffold from '../../../schedule/components/monthSchedule/DayScaffold';
import Calendar from '../calendar/Calendar';
import SidebarSelfRosterShift from '../SelfRosterShift/SidebarSelfRosterShift';
import SelfRosterShiftDetail from '../selfRosterShiftDetail/SelfRosterShiftDetail';
import WeekCounter from '../WeekCounter/WeekCounter';

import './sidebar.scss';

type Props = {
  onSave: () => void;
}

const Sidebar: FC<Props> = ({ onSave }) => {
  const [selectedShift, setSelectedShift] = useState<ISelfRosteringShift>(null);
  const { selectedShifts, selectedDate, isMultiDepartment, selectedIteration, hasChanges, setSelectedShifts } = useSelfRosterContext();

  const { data: originalSelectedShifts } = useSelfRosterSelectedShifts({ startDate: selectedIteration?.startDate, endDate: selectedIteration?.endDate, iterationId: selectedIteration?.id });

  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const { startDate, endDate } = scheduleUtils.getBoundsOfRange(TimeScale.Week, selectedDate, false, weekStartsOn);

  if (!selectedDate) return null;

  const { isRound2, isRound3 } = selfRosterUtils.getIterationChecks(selectedIteration);

  const roster = selfRosterUtils.getFilteredRoster(startDate, endDate, selectedShifts, []);

  const renderDay = (day: Date) => {
    const shifts = selectedShifts.filter((shift) => isSameDay(parseISO(shift.startDate), day));
    const className = classnames('day', {
      'active-week': isSameWeek(selectedDate, day, { weekStartsOn }),
      'first-day-of-week': isSameDay(startOfWeek(day, { weekStartsOn }), day),
      'last-day-of-week': isSameDay(endOfWeek(day, { weekStartsOn }), day),
    });
    // When we have an overstaffed shift we need to apply a gradient on the day
    const overstaffedShift = shifts.find(shift => shift.isOverstaffed);
    const style = overstaffedShift && { backgroundImage: selfRosterUtils.getShiftColors(overstaffedShift, 2).gradientCss };
    return (
      <div className={className} key={day.toISOString()} style={style}>
        {date.format(day, 'd')}
        <div className="day-orb">
          {shifts.map((shift) => shift.isOverstaffed ? null : <Orb key={shift.uuid} size={0.5} filled color={selfRosterUtils.getShiftColors(shift).darkColor} />)}
        </div>
      </div>
    );
  };

  const isLocked = (shift: ISelfRosteringShift) => {
    if ((isRound2 && !shift.isOverstaffed) || isRound3) {
      if (originalSelectedShifts?.flatMap((roster) => roster.shifts).some(originalSelectedShift => originalSelectedShift.uuid === shift.uuid)) {
        return true;
      }
    }
    return false;
  };

  const reset = () => {
    setSelectedShifts(originalSelectedShifts.flatMap(roster => roster.shifts));
  };

  const firstDayOfWeek = startOfWeek(selectedDate, { weekStartsOn });
  const middleOfWeek = addDays(firstDayOfWeek, 3);

  return (
    <aside className="self-roster-sidebar">
      {selectedShift ? <>
        <SelfRosterShiftDetail shift={selectedShift} isLocked={isLocked(selectedShift)} onClose={() => setSelectedShift(null)} />
      </>
        :
        <>
          <div className="calendar-header">
            <div>
              {date.format(middleOfWeek, 'MMMM yyyy')}
            </div>
          </div>
          <div className="day-header">
            <DayScaffold format="EEEEE" selectedDate={selectedDate} weekStartsOn={weekStartsOn} />
          </div>
          <Calendar selectedDate={middleOfWeek} renderDay={renderDay} />
          {!isRound3 && <WeekCounter selectedDate={selectedDate} selectedShifts={selectedShifts} />}
          <div className="hr" />
          {selectedShifts.length === 0 && <p className="empty-label">{translations.getLabel('lblEmptySelfRosterSidebar')}</p>}
          {roster.map(day => (
            <div className="roster-day" key={day?.date?.toISOString()}>
              <h4>{date.format(day.date, 'EEEEEEE d MMMM')}: {day.hours}{translations.getLabel('lblHourUnit')}</h4>
              {day.shifts.map(shift => <SidebarSelfRosterShift isLocked={isLocked(shift)} key={shift.uuid} shift={shift} onClick={setSelectedShift} showDepartment={isMultiDepartment} />)}
            </div>
          ))}
          <div className="spacer" />
          <div className="actions">
            {isRound3 ? <p>{translations.getLabel('lblRosterWaitingForPublication')}</p> : <Button disabled={!hasChanges} onClick={() => onSave()}>{translations.getLabel('btnSavePlanning')}</Button>}
            {hasChanges && isRound2 && <Button theme="transparent" onClick={() => reset()}>{translations.getLabel('btnResetPlanning')}</Button>}
          </div>
        </>
      }
    </aside>
  );
};

export default Sidebar;
