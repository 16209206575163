import React, { FC, useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { Button, Icon } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Route, RouteComponentProps, Switch, useLocation } from 'react-router-dom';

import { AppState } from '../../../../shared/redux/rootReducer';
import { routes } from '../../constants';
import { UserRight } from '../../models';
import { configSelectors, requestsActions, requestsSelectors, userSelectors } from '../../redux';
import { translations } from '../../utils';
import ShiftTimeInbox from './components/shiftTimeManagerInbox/ManagerInbox';
import { departmentInboxTabStructure, ITabStructure, personalInboxTabStructure } from './inboxTabStructure';

import './requestInboxTabNavigator.scss';

enum RequestOption {
  OwnRequests = 'own-requests',
  OtherRequests = 'other-requests',
}

const RequestInboxTabNavigator: FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const hasVacationRequestRight = useSelector((state: AppState) => userSelectors.hasRight(state, UserRight.VacationRequests));
  const hasShiftOfferRights = useSelector((state: AppState) => userSelectors.hasRight(state, UserRight.ShiftOffer));
  const hasShiftSwapRights = useSelector((state: AppState) => userSelectors.hasRight(state, UserRight.ShiftSwap));
  const hasShiftTimeRights = useSelector((state: AppState) => userSelectors.hasRight(state, UserRight.AdjustedShiftTimes));
  const hasTransactionRights = useSelector((state: AppState) => userSelectors.hasRight(state, UserRight.Transactions));
  const hasTimeRegistrationRights = useSelector((state: AppState) => userSelectors.hasRight(state, UserRight.TimeRegistration));
  const isShiftTimeAdjustmentsEnabled = useSelector(configSelectors.isShiftTimeAdjustmentsEnabled);
  const counts = useSelector(requestsSelectors.getCounts);
  const companyConfig = useSelector(configSelectors.getCompanyConfig);
  const location = useLocation();

  const isOwnRequests = personalInboxTabStructure.some(tab => location.pathname.includes(tab.url[0]));

  useEffect(() => {
    if (hasVacationRequestRight) {
      dispatch(new requestsActions.GetRequestCountsAction());
    }
  }, []);

  const renderHorizontalTab = (horizontalTab: ITabStructure, index: number): JSX.Element => {
    const count = counts?.[horizontalTab.count];
    return (
      <NavLink className="horizontal-tab" activeClassName="selected-horizontal" key={index} to={horizontalTab.url[0]}>
        {`${translations.getLabel(horizontalTab.label)}${count ? ` (${count})` : ''}`}
      </NavLink>
    );
  };

  const switchRequestOption = (value: RequestOption) => {
    const structure = value === RequestOption.OwnRequests ? personalInboxTabStructure : departmentInboxTabStructure;
    history.push(structure[0].url[0]);
  };

  const getTabs = () => {
    let tabs = isOwnRequests ? personalInboxTabStructure : departmentInboxTabStructure;
    if (!hasVacationRequestRight) tabs = tabs.filter(tab => tab.restriction !== UserRight.VacationRequests);
    if (!hasShiftOfferRights) tabs = tabs.filter(tab => tab.restriction !== UserRight.ShiftOffer);
    if (!hasShiftSwapRights) tabs = tabs.filter(tab => tab.restriction !== UserRight.ShiftSwap);
    if (!hasShiftTimeRights) tabs = tabs.filter(tab => tab.restriction !== UserRight.AdjustedShiftTimes);
    if (!hasTransactionRights) tabs = tabs.filter(tab => tab.restriction !== UserRight.Transactions);
    if (!isShiftTimeAdjustmentsEnabled) tabs = tabs.filter(tab => tab.component !== ShiftTimeInbox);
    tabs = tabs.filter(tab => tab.moduleCheck ? companyConfig?.modules?.[tab.moduleCheck] : true);

    return (
      <>
        {tabs.length > 1 && (
          <div className="tabs-container">
            {tabs.map((horizontalTab, index) => renderHorizontalTab(horizontalTab, index))}
          </div>
        )}
        <div className="component-wrapper">
          <Switch>
            {tabs.map(tab => <Route key={tab.url[0]} path={tab.url} exact component={tab.component} />)}
          </Switch>
        </div>
      </>
    );
  };

  const isManagerWithRights = hasVacationRequestRight || (hasShiftOfferRights && companyConfig?.modules.shiftOffer);

  return (
    <DocumentTitle title={translations.getLabel('titleRequestInbox')}>
      <div className="page fixed-width inbox">
        <h2>{translations.getLabel('titleRequestInbox')}</h2>
        {isManagerWithRights && <div className="tab-container">
          <div className="tab-buttons">
            <button className={isOwnRequests ? 'stripped-button active' : 'stripped-button'} onClick={() => switchRequestOption(RequestOption.OwnRequests)}>{translations.getLabel('lblOwnRequests')}</button>
            <button className={!isOwnRequests ? 'stripped-button active' : 'stripped-button'} onClick={() => switchRequestOption(RequestOption.OtherRequests)}>{translations.getLabel('lblOtherRequests')}</button>
          </div>
          {
            hasTimeRegistrationRights &&
            <Button className="check-doubles" onClick={() => history.push(routes.doubleUsage)} >
              <Icon className="button-icon" tag="PhoneIcon" />
              {translations.getLabel('lblCheckDoubleUse')}
            </Button>
          }
        </div>}
        <div className="container">
          {getTabs()}
        </div>
      </div>
    </DocumentTitle>
  );
};

export default RequestInboxTabNavigator;
