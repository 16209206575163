import { QueryClient } from 'react-query';

import { HttpStatus } from '../http';
import { IServerError } from '../http/HttpError';

const handleUnauthorizedCalls = (error: IServerError) => {
  if (error.status === HttpStatus.Unauthorized) {
    new QueryClient().invalidateQueries();
  }
};

export const queryClientConfig = {
  defaultOptions: {
    mutation: {
      onError: handleUnauthorizedCalls,
      retry: false,
    },
    queries: {
      onError: handleUnauthorizedCalls,
      retry: false,
      staleTime: 1000 * 60, // 1 minute
    },
  },
};
