import { ITimeRegistration, ITimeRegistrationType, ITimeRegistrationClock, IActiveTimeRegistration, ValidationType, IValidator, IDoubleTimeRegistration } from '../models';
import { HttpClient } from '../http';

export const getTimeRegistrations = async (): Promise<{ data: ITimeRegistration[] }> => {
  const result = await HttpClient.get<{ data: ITimeRegistration[] }>('/v1/time-registration/history');
  return result;
};

export const getDoubleTimeRegistrations = async (departmentId: string, startDate: string, endDate: string): Promise<{ data: IDoubleTimeRegistration[] }> => {
  const result = await HttpClient.get<{ data: IDoubleTimeRegistration[] }>(`/v1/management/departments/${departmentId}/time-registrations/overview/flagged`, { startDate, endDate });
  return result;
};

export const getActiveTimeRegistrationsAndTypes = async (departmentId: string): Promise<{ registrations: IActiveTimeRegistration[], types: ITimeRegistrationType[], validationTypes: ValidationType[] }> => {
  const registrationsResult = await HttpClient.get<{ data: { registrations: IActiveTimeRegistration[] } }>('/v1/time-registration');
  const typesResult = await HttpClient.get<{ data: ITimeRegistrationType[] }>('/v1/time-registration/types');
  const validationTypes = await HttpClient.get<{ data: { type: ValidationType }[] }>(`/v1/time-registration/validation-types/${departmentId}`);
  return {
    registrations: registrationsResult?.data?.registrations,
    types: typesResult?.data,
    validationTypes: validationTypes?.data?.map(validationType => validationType.type),
  };
};

export const clock = async (type: string, typeId: string, validators?: IValidator[], clockAnyway: boolean = false): Promise<{ data: ITimeRegistrationClock }> => {
  const body = { type, typeId, clockAnyway, validators: undefined };
  if (validators?.length) body.validators = validators;
  const result = await HttpClient.post<{ data: ITimeRegistrationClock }>('/v1/time-registration/clock', body);
  return result;
};

export const registerDevice = async (deviceIdentifier: string, code: string, deviceName: string): Promise<void> => {
  const body: { deviceIdentifier: string, code?: string, deviceName: string } = { deviceIdentifier, deviceName };
  if (code) body.code = code;
  await HttpClient.post('/v1/time-registration/register-device', body);
};

export const checkRegisteredDevice = async (deviceIdentifier: string): Promise<boolean> => {
  try {
    await HttpClient.get(`/v1/time-registration/register-device/${deviceIdentifier}`);
    return true;
  } catch (err) {
    return false;
  }
};