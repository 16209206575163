import { createSelector } from 'reselect';
import { AppState } from '../rootReducer';
import { ForgotPasswordState } from './reducer';

const selectNode = (state: AppState) => state.forgotPassword;

export const isRequestLoading = createSelector(
  selectNode,
  (state: ForgotPasswordState) => state.isRequestLoading,
);
