import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { startOfDay, endOfDay, setMinutes, setHours, setSeconds, isBefore, addDays, addHours } from 'date-fns';

import { regexes } from '../../constants';
import { requestsUtils } from '../../utils';
import { useInput } from '../../hooks';

const DEFAULT_START_TIME = '09:00';
const DEFAULT_END_TIME = '17:00';

const RequestVacationForm = (props) => {
  const startDate = !!props.vacationPeriod && requestsUtils.isValidRequest({ requestPeriod: props.vacationPeriod, forDate: props.startDate }) ? props.startDate : addDays(new Date(), props.vacationPeriod.daysBefore + 1);

  const [valueAllDay, setAllDayValue] = useState(true);
  const [valueFrom, setValueFrom] = useState({ date: startDate, time: DEFAULT_START_TIME });
  const [valueUntil, setValueUntil] = useState({ date: props.endDate, time: DEFAULT_END_TIME });
  const valueTypeOfVacation = useInput('');
  const valueRemarks = useInput('');

  useEffect(() => {
    if (isBefore(valueUntil.date, valueFrom.date)) setValueUntil({ ...valueUntil, date: valueFrom.date });

    let startDate = '';
    let endDate = '';
    if (!valueAllDay) {
      if (!regexes.time.test(valueFrom.time) || !regexes.time.test(valueUntil.time)) {
        startDate = null;
        endDate = null;
      } else {
        const startTime = valueFrom.time;
        const [startHours, startMinutes] = startTime.split(':');
        startDate = setSeconds(setMinutes(setHours(valueFrom.date, startHours), startMinutes), 0);
        const endTime = valueUntil.time;
        let [endHours, endMinutes] = endTime.split(':');
        endDate = setSeconds(setMinutes(setHours(valueFrom.date, endHours), endMinutes), 0);
        if (valueUntil.date !== valueFrom.date) {
          setValueUntil({ ...valueUntil, date: valueFrom.date });
        }
      }
    } else {
      startDate = startOfDay(valueFrom.date);
      endDate = endOfDay(valueUntil.date);
    }

    const vacationRequest = {
      startDate,
      endDate,
      vacationTypeId: valueTypeOfVacation.value,
    };
    if (valueRemarks.value) {
      vacationRequest.requesterNote = valueRemarks.value;
    }

    props.onChange(vacationRequest);
  }, [valueAllDay, valueFrom, valueUntil, valueTypeOfVacation.value, valueRemarks.value]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleCheckbox = () => {
    // If we change the checkbox, reset the select
    valueTypeOfVacation.setValue('');
    setAllDayValue(!valueAllDay);
  };

  return props.children({
    valueAllDay,
    setAllDayValue,
    valueFrom,
    setValueFrom,
    valueUntil,
    setValueUntil,
    valueTypeOfVacation,
    valueRemarks,
    toggleCheckbox,
  });
};

RequestVacationForm.propTypes = {
  children: PropTypes.any,
  endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  vacationPeriod: PropTypes.object,
  vacationTypes: PropTypes.object,
};

RequestVacationForm.defaultProps = {
  children: null,
  endDate: new Date(),
  startDate: new Date(),
  vacationPeriod: {},
  vacationTypes: {},
};

export default RequestVacationForm;
