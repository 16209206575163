import { availabilitiesSelectors, userSelectors, configSelectors } from '../../redux';
import { startOfWeek, endOfWeek } from 'date-fns';

import AvailabilitiesBase from './Availabilities.base';
import { Period } from '../../models';
import { date } from '../../utils';

const mapStateToProps = (state) => {
  const weekStartsOn = configSelectors.getStartDayOfWeek(state);
  return {
    availabilities: availabilitiesSelectors.getAvailabilities(state),
    availabilityPeriod: userSelectors.getPeriods(state, Period.AvailabilityRequestPeriod),
    endDate: endOfWeek(userSelectors.getPeriods(state, Period.AvailabilityRequestPeriod) && date.parseDate(userSelectors.getPeriods(state, Period.AvailabilityRequestPeriod).endDate), { weekStartsOn }),
    startDate: startOfWeek(userSelectors.getPeriods(state, Period.AvailabilityRequestPeriod) && date.parseDate(userSelectors.getPeriods(state, Period.AvailabilityRequestPeriod).startDate), { weekStartsOn }),
    timeTypes: availabilitiesSelectors.getTimeTypes(state),
    inSchoolUntil: userSelectors.inSchoolUntil(state),
    weekStartsOn,
  };
};

export {
  AvailabilitiesBase,
  mapStateToProps,
};
