import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import { INewsItem } from '../models';


export const createNewsItemForDb = (dataFromView, departments, isStartDateChecked, isEndDateChecked, startDate, endDate) => {
  const content = [];
  const originalAttachments = [];
  for (const key in dataFromView) {
    const dataCopy = { ...dataFromView[key] };
    dataCopy.attachments = dataFromView[key].attachments.map(att => ({ name: att.name, contentType: att.type || att.contentType, id: att.id }));
    dataCopy.message = draftToHtml(convertToRaw(dataFromView[key].message.getCurrentContent()));
    originalAttachments.push(dataFromView[key].attachments);
    content.push({ ...dataCopy, language: key });
  }
  // If nothing is selected, take all the ids from all the departments
  const departmentIds = departments;
  const newsItem = {
    data: {
      departmentIds,
      content,
      startDate: (isStartDateChecked) ? startDate : null,
      endDate: (isEndDateChecked) ? endDate : null,
    },
    originalAttachments,
  };

  return newsItem;
};

export const amountOfLinesInItem = (item: INewsItem): number => !!item.imageUrl ? 3 : 5;
