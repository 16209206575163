import React, { FC } from 'react';
import { FileUpload, Icon } from 'react-ess-components';

import { useToggle } from '../../hooks';
import { translations } from '../../utils';

import './addAttachment.scss';

type Props = {
  handleUpload: (file: File) => void;
}

const AddAttachment: FC<Props> = ({ handleUpload }) => {
  const [isModalVisible, toggleIsModalVisible] = useToggle(false);


  const onHandleUpload = (file: File) => {
    toggleIsModalVisible();
    handleUpload(file);
  };

  return (
    <React.Fragment>
      <button className="stripped-button add-attachment-container" onClick={toggleIsModalVisible}>
        <Icon tag="DocumentIcon" color="textGray" small />
        <p>{translations.getLabel('lblAttachment')}</p>
      </button>
      {
        isModalVisible &&
        <FileUpload
          acceptFileTypes="image/png, image/jpeg, application/pdf"
          translationLabels={{
            lblTitle: translations.getLabel('titleAddAttachment'),
            lblDrag: translations.getLabel('lblDragAttachment'),
            lblBtn: translations.getLabel('btnChooseAttachment'),
          }}
          isLoading={false}
          handleUpload={onHandleUpload}
          isVisible={isModalVisible}
          toggleModal={toggleIsModalVisible}
        />
      }
    </React.Fragment>
  );
};

export default AddAttachment;
