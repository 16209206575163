import React, { FC } from 'react';
import classNames from 'classnames';

import { ISelfRosterVacationShift } from '../../../../models';
import SelfRosterShift from '../SelfRosterShift/SelfRosterShift';

import './selfRosterDay.scss';

interface Props {
  vacation: ISelfRosterVacationShift[];
}

const SelfRosterVacationDay: FC<Props> = ({ vacation }) => {
  return (
    <div className={classNames('self-roster-vacation-day', { empty: !vacation?.length })}>
      {vacation?.map((shift) => <SelfRosterShift key={shift.id} vacation={shift} />)}
    </div>
  );
};

export default SelfRosterVacationDay;
