import React, { FC } from 'react';
import { parseISO } from 'date-fns';

import { ITimeRegistration } from '../../../models';
import { date } from '../../../utils';

interface Props {
  timeRegistration: ITimeRegistration;
}

const TimeRegistrationRow: FC<Props> = ({ timeRegistration }) => {
  const { times, activities, locations } = timeRegistration.tickingList.reduce((accu, ticking, index): { times: React.ReactNode; activities: React.ReactNode; locations: React.ReactNode } => {
    accu.times.push(<p key={`${ticking.time}-${index}`}>{ticking.time || <br />}</p>);
    accu.activities.push(<p key={`${ticking.time}-${index}`}>{ticking.label || <br />}</p>);
    accu.locations.push(<p key={`${ticking.time}-${index}`}>{ticking.locationId || <br />}</p>);
    return accu;
  }, { times: [], activities: [], locations: [] });

  return (
    <tr>
      <td className="date">{date.format(parseISO(timeRegistration.date), 'EE dd/MM')}</td>
      <td>{times}</td>
      <td>{activities}</td>
      <td>{locations}</td>
    </tr>
  );
};

export default TimeRegistrationRow;

