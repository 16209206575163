import Axios, { CancelTokenSource } from 'axios';
import { whitelist } from '../constants';

export interface ITokenByRoute {
  route: string;
  cancelToken: CancelTokenSource;
};

export const CANCEL_MESSAGE = 'CALL_CANCELLED';
export const LOGGED_OUT_ERROR = 'LOGGED_OUT_ERROR';
export const SPX_IS_DOWN = 'SPX_IS_DOWN';

export const parseRoute = (route: string): string => {
  if (route.indexOf('?') > -1) {
    return route.split('?')[0];
  }
  return route;
};

export const isWhitelisted = (route: string): boolean => {
  return !!whitelist.find((entry) => route.includes(entry));
};

export const cancelPreviousRequestIfneeded = (route: string, tokensByRoutes: ITokenByRoute[], cancelToken: CancelTokenSource): ITokenByRoute[] => {
  const parsedRoute = parseRoute(route);

  if (isWhitelisted(parsedRoute)) {
    tokensByRoutes.push({ route: parsedRoute, cancelToken });
  } else {
    const foundIndex = tokensByRoutes.findIndex(tokenByRoute => tokenByRoute.route === parsedRoute);

    if (foundIndex < 0) {
      tokensByRoutes.push({ route: parsedRoute, cancelToken });
    } else {
      tokensByRoutes[foundIndex].cancelToken.cancel(CANCEL_MESSAGE);
      tokensByRoutes[foundIndex] = { route: parsedRoute, cancelToken };
    }
  }

  return tokensByRoutes;
};

export const setHttpClientAuthInterceptor = () => {
  Axios.interceptors.response.use(function (response) {
    return response;
  }, (error) => {
    if (error.response && error.response.status === 401) {
      return Promise.reject({ message: LOGGED_OUT_ERROR });
    } else {
      return Promise.reject(error);
    }
  });
};