import { MutateOptions, useMutation, useQueryClient } from 'react-query';

import { HttpClient } from '../../http';
import { ISelfRosterSaveError, IServerError } from '../../http/HttpError';
import { IPostSelfRoster, ISelfRosteringRuleViolation } from '../../models';
import { selfRosterQueryKeys } from './queryKeys';
import { useSelfRosterContext } from './SelfRosterContext';

const postSelfRoster = async (iterationId: string, body: IPostSelfRoster[]): Promise<void> => {
  return await HttpClient.post<void>(`/v1/self-roster/schedule/${iterationId}`, body);
};

export const useSaveSelfRoster = (iterationId: string, { onSuccess, onError }: MutateOptions<void, ISelfRosterSaveError, IPostSelfRoster[]>) => {
  const { setHasChanges } = useSelfRosterContext();
  const queryClient = useQueryClient();
  return useMutation<void, ISelfRosterSaveError, IPostSelfRoster[]>(selfRosterQueryKeys.saveSchedule(), (body) => postSelfRoster(iterationId, body), {
    onError,
    onSuccess: (data: void, variables: IPostSelfRoster[], context: unknown) => {
      setHasChanges(false);
      queryClient.invalidateQueries(selfRosterQueryKeys.counters());
      onSuccess(data, variables, context);
    }
  });
};

export enum SelfRosterSaveErrorCodes {
  ConflictedSave = 'SELF_ROSTER_REQUESTED_SHIFT_NOT_FOUND',
  RuleViolation = 'SELF_ROSTER_RULE_VIOLATION'
};
