import { isSameMinute, startOfDay, endOfDay, isValid, isBefore, parseISO, isAfter } from 'date-fns';

import colors from './colors';
import * as date from './date';

const SCHOOL_TIMETYPE_CODE = 'SCHOOL';

export const findColorForAvailability = (timeTypes, code) => {
  if (!timeTypes || !code) return colors.white;
  const timeType = timeTypes.find((timeType) => timeType.code === code);
  return timeType ? timeType.color : colors.white;
};

export const isAllDay = (availability) => {
  const { startDate, endDate } = availability;
  return isSameMinute(date.parseDate(startDate), startOfDay(date.parseDate(startDate))) && isSameMinute(date.parseDate(endDate), endOfDay(date.parseDate(endDate)));
};

export const isVisible = (availability, timeTypes) => {
  const timeType = timeTypes.find(timeType => timeType.code === availability.code);
  return !timeType || timeType.visible;
};

export const getVisibleTimeTypes = (timeTypes, inSchoolUntil, dateToCompare) => {
  const reducer = (accu, timeType) => {
    if (timeType.visible) {
      // Extra check to filter school time types after a specific date
      if (timeType.code === SCHOOL_TIMETYPE_CODE && inSchoolUntil && dateToCompare) {
        const inSchoolUntilDate = parseISO(inSchoolUntil);
        const currentDate = typeof dateToCompare !== 'string' ? dateToCompare : parseISO(dateToCompare);
        if (isValid(inSchoolUntilDate) && isValid(currentDate) && !isAfter(inSchoolUntilDate, currentDate)) {
          return accu;
        }
      }
      return [...accu, timeType];
    }
    return accu;
  };
  return timeTypes.reduce(reducer, []);
};