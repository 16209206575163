import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect,Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { routes, storageItems } from '../constants';
import { Period } from '../models';
import { configSelectors, userSelectors } from '../redux';
import { requestsUtils } from '../utils';

class AuthorizedRoute extends Component {
  renderRoute = () => {
    const RouteComponent = this.props.component;
    // On every route except for the schedule route we want to remove the search value so the user can start fresh.
    if (!window.location.pathname.includes(routes.schedule[0])) localStorage.removeItem(storageItems.SEARCH_VALUE);
    if (this.props.user?.id) {
      const { availabilityPeriod, vacationPeriod, user, ...otherProps } = this.props; // eslint-disable-line @typescript-eslint/no-unused-vars
      const isAvailabilityPeriodActive = !!availabilityPeriod && requestsUtils.isValidRequest({ requestPeriod: availabilityPeriod, isPeriodCheck: true });
      const isVacationPeriodActive = !!vacationPeriod && requestsUtils.isValidRequest({ requestPeriod: vacationPeriod, isPeriodCheck: true });
      return <RouteComponent {...otherProps} user={user} isAvailabilityPeriodActive={isAvailabilityPeriodActive} isVacationPeriodActive={isVacationPeriodActive} />;
    } else {
      if (window.location.pathname.includes(routes.notifications)) {
        return <Redirect to={`/auth/notification${window.location.search}`} />;
      } else {
        return <Redirect to="/auth/login" />;
      }
    }
  };

  render() {
    // These 2 props shouldn't be passed to the route, all the other props will be passed
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { component: Component, pending, ...otherProps } = this.props;

    return (
      <Route
        {...otherProps}
        render={this.renderRoute}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  availabilityPeriod: userSelectors.getPeriods(state, Period.AvailabilityRequestPeriod),
  companyConfig: configSelectors.getCompanyConfig(state),
  vacationPeriod: userSelectors.getPeriods(state, Period.VacationRequestPeriod),
  user: userSelectors.getUser(state),
  isSpxDown: configSelectors.isSpxDown(state),
});

export default connect(mapStateToProps)(AuthorizedRoute);

AuthorizedRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

