import { connect } from 'react-redux';

import { configSelectors, loginSelectors } from '../../redux';
import Employments from './Employments.view';
import LoginPage from './Login.view';
import NotificationHandler from './NotificationHandler';

const mapStateToProps = state => ({
  build: configSelectors.getBuild(state),
  employments: loginSelectors.getEmployments(state),
  holdingPreferences: configSelectors.getHoldingPreferences(state),
});

const EmploymentsPage = connect(mapStateToProps)(Employments);

export { LoginPage, EmploymentsPage, NotificationHandler };
