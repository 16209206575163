import React, { FC } from 'react';
import Color from 'color';
import { parseISO } from 'date-fns';

import { IInterval } from '../../../../models';
import { colors, date } from '../../../../utils';

interface Props {
  interval: IInterval;
}

const Interval: FC<Props> = ({ interval }) => {
  const startDate = parseISO(interval.startDate);
  const endDate = parseISO(interval.endDate);
  const timestamp = date.formatFromUntilTime(date.format, { startDate, endDate });

  const background = interval.color || colors.primary;
  const color = Color(background).isDark() ? colors.white : colors.text;
  return (
    <div className="interval" style={{ background, color }}>
      <p className="interval-timestamp">{timestamp}</p>
      {!!interval.timeType && <p>{interval.timeType}</p>}
    </div>

  );
};

export default Interval;
