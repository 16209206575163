import { createSelector } from 'reselect';
import { AppState } from '../rootReducer';
import { NotificationState } from './reducer';

const selectNode = (state: AppState) => state.notifications;

export const notifications = createSelector(
  selectNode,
  (state: NotificationState) => state.notifications,
);

export const amountUnread = createSelector(
  selectNode,
  (state: NotificationState) => state.amountUnread,
);

export const isNotificationsLoading = createSelector(
  selectNode,
  (state: NotificationState) => state.isNotificationsLoading,
);

export const totalCount = createSelector(
  selectNode,
  (state: NotificationState) => state.totalCount,
);

export const metadata = createSelector(
  selectNode,
  (state: NotificationState) => state.metadata,
);

