import React, { useEffect,useState } from 'react';
import { format } from 'date-fns';

const getTime = () => format(new Date(), 'HH:mm');

const Clock = () => {
  const [time, setTime] = useState<string>(getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(getTime());
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="clock-component">
      <span className="clock-time">{time}</span>
    </div>
  );
};

export default Clock;
