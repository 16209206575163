import React, { FC, useEffect } from 'react';
import { Icon } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { requestsActions, requestsSelectors } from '../../../../redux';
import { date, requestsUtils, transactionUtils, translations } from '../../../../utils';
import Footer from './RequestFooter';

interface Props {
    id: string;
    isLoading: boolean;
}

const RequestDetail: FC<Props> = ({ id }) => {
    const dispatch = useDispatch();
    const request = useSelector((state: AppState) => requestsSelectors.getTransactionRequest(state, id));
    const relativeCreatedDate = requestsUtils.getRelativeDate(request?.createdAt, date.format);
    const isLoading = useSelector(requestsSelectors.isTransactionRequestLoading);

    useEffect(() => {
        if (id && !request) {
            dispatch(new requestsActions.GetTransactionRequestAction({ id }));
        }
    }, [id]);

    if (!request && !isLoading) return <section className="request-detail empty">{translations.getLabel('lblEmpyRequestInbox')}</section>;

    return (
        <div className="request-detail department-request-detail">
            <div>
                <p>{isLoading ? <Skeleton width={300} /> : (relativeCreatedDate.shouldTranslate ? `${translations.getLabel(relativeCreatedDate.value)} ${relativeCreatedDate.time}` : `${relativeCreatedDate.value}`)}</p>
                <p className="title">{isLoading ? <Skeleton width={400} /> : translations.getLabel('titleTransactionRequestDetail')}</p>
                <div className="request-message-container">

                    <div className="data-field">
                        <p className="shift-numbers"><Icon tag="CalendarIcon" color="primaryDark" /> {translations.getLabel('lblDate')}:</p>
                        <p>{request && format(parseISO(request.date), 'dd/MM/yyyy')}</p>
                    </div>
                    <div className="data-field">
                        <p className="shift-numbers"><Icon tag="DashboardIcon" color="primaryDark" /> {translations.getLabel('lblType')}:</p>
                        <p> {request?.name}</p>
                    </div>
                    <div className="data-field">
                        <p className="shift-numbers"><div className="empty-icon icon" />{translations.getLabel('lblValue')}:</p>
                        <p>{transactionUtils.getTransactionValue(request)}</p>
                    </div>
                    <div className="data-field">
                        <p className="shift-numbers"><Icon tag="ChatIcon" color="primaryDark" /> {translations.getLabel('lblReason')}:</p>
                        <p> {request?.reason ? request?.reason : translations.getLabel('lblNoRequestReason')}</p>
                    </div>

                </div>
            </div>
            <div className="hr" />
            {request &&
                <div className="footer">
                    <Footer id={id} />
                </div>
            }
        </div >
    );
};

export default RequestDetail;
