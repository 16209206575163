import { produce } from 'immer';

import ActionType from './types';
import UserActionType from '../user/types';
import { GetUserFulfilledAction } from '../user/actions';
import { IEmployment } from '../../models';
import { HttpError } from '../../http';
import { Actions } from './actions';
export interface LoginState {
  employments: IEmployment[];
  isEmploymentsLoading: boolean,
  isLoading: boolean,
  isLoadingLogout: boolean,
  isLoggedIn: boolean,
  isLoggedOutAutomatically: boolean,
  isUpdateEmploymentLoading: boolean,
  serverError: HttpError.IServerError,
  status: string,
  token: string,
}

export const initialState: LoginState = {
  employments: [],
  isEmploymentsLoading: false,
  isLoading: false,
  isLoadingLogout: false,
  isLoggedIn: false,
  isLoggedOutAutomatically: true,
  isUpdateEmploymentLoading: false,
  serverError: null,
  status: null,
  token: null,
};

const login = produce((draft: LoginState, action: Actions | GetUserFulfilledAction) => {
  switch (action.type) {
    case ActionType.Login:
      draft.isLoading = true;
      break;

    case ActionType.LoginWithSsoFulfilled:
    case ActionType.LoginFulfilled:
      draft.isLoggedIn = true;
      draft.isLoading = false;
      draft.isLoggedOutAutomatically = true;
      draft.employments = action.payload.employments;
      draft.status = action.payload.status;
      draft.token = action.payload.token;
      break;

    case ActionType.LoginRejected:
      draft.serverError = action.payload.error;
      draft.isLoading = false;
      draft.status = null;
      draft.token = null;
      break;

    case ActionType.GetEmployments:
      draft.isEmploymentsLoading = true;
      break;

    case ActionType.GetEmploymentsFulfilled:
      draft.isEmploymentsLoading = false;
      draft.employments = action.payload.data;
      break;

    case ActionType.GetEmploymentsRejected:
      draft.serverError = action.payload.error;
      draft.isEmploymentsLoading = false;
      break;

    case ActionType.UpdateEmployment:
      draft.isUpdateEmploymentLoading = true;
      break;

    case ActionType.UpdateEmploymentFulfilled:
      draft.isUpdateEmploymentLoading = false;
      break;

    case ActionType.UpdateEmploymentRejected:
      draft.serverError = action.payload.error;
      draft.isUpdateEmploymentLoading = false;
      break;

    case ActionType.Logout:
      draft.isLoadingLogout = true;
      break;

    case ActionType.LogoutFulfilled:
      draft.isLoggedIn = false;
      draft.isLoadingLogout = false;
      break;

    case ActionType.LogoutRejected:
      draft.serverError = action.payload.error;
      draft.isLoadingLogout = false;
      break;

    case UserActionType.GetUserFulfilled:
      draft.isLoggedOutAutomatically = false; // Once the user details are fetched, we enable the option to be logged out automatically
      break;

    case ActionType.LogoutAutomatically:
      draft.isLoggedOutAutomatically = true;
      break;

    // No default case is needed, since produce returns by default the draft state
  }
}, initialState);

export default login;
