import { createSelector } from 'reselect';
import { AppState } from '../rootReducer';
import { LoginState } from './reducer';

const selectNode = (state: AppState) => state.login;

export const isLoading = createSelector(
  selectNode,
  (state: LoginState) => state.isLoading,
);

export const isLoadingLogout = createSelector(
  selectNode,
  (state: LoginState) => state.isLoadingLogout,
);

export const isLoadingUpdateEmployment = createSelector(
  selectNode,
  (state: LoginState) => state.isUpdateEmploymentLoading,
);

export const isLoggedIn = createSelector(
  selectNode,
  (state: LoginState) => state.isLoggedIn,
);

export const isLoggedOutAutomatically = createSelector(
  selectNode,
  (state: LoginState) => state.isLoggedOutAutomatically,
);

export const getServerError = createSelector(
  selectNode,
  (state: LoginState) => state.serverError,
);

export const getEmployments = createSelector(
  selectNode,
  (state: LoginState) => state.employments,
);

export const getPasswordStatus = createSelector(
  selectNode,
  (state: LoginState) => state.status,
);

export const getToken = createSelector(
  selectNode,
  (state: LoginState) => state.token,
);

