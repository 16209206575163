import { chatTypes } from '../constants';
import store from '../redux/createStore';
import * as chatActions from '../redux/chat/actions';
import * as notificationsActions from '../redux/notifications/actions';
import { NotificationTrigger } from '../models';

let unreadInterval = null;

export const getTagForType = (type: NotificationTrigger) => {
  switch (type) {
    case NotificationTrigger.UserScheduleUpdated:
    case NotificationTrigger.UserScheduleUpdatedGeneric:
    case NotificationTrigger.VacationRequest:
    case NotificationTrigger.VacationRequestDepartment:
    case NotificationTrigger.ShiftOfferNew:
    case NotificationTrigger.ShiftOfferFilled:
    case NotificationTrigger.ShiftOfferCancelled:
    case NotificationTrigger.ShiftOfferDepartment:
    case NotificationTrigger.ShiftOfferAcceptedByOtherManager:
      return 'CalendarIcon';
    case NotificationTrigger.ShiftSwapDepartment:
    case NotificationTrigger.ShiftSwapReviewed:
    case NotificationTrigger.ShiftSwapCancelled:
    case NotificationTrigger.ShiftSwapRequested:
    case NotificationTrigger.ShiftSwapChosen:
      return 'ChangeIcon';
    case NotificationTrigger.DocumentAdded:
      return 'DocumentIcon';
    case NotificationTrigger.NewsArticleAdded:
      return 'NewsIcon';
    case NotificationTrigger.TransactionToApprove:
    case NotificationTrigger.TransactionApproved:
    case NotificationTrigger.TransactionDenied:
      return 'TransactionIcon';
    case NotificationTrigger.ShiftTimeToApprove:
    case NotificationTrigger.ShiftTimeReviewed:
      return 'ShiftTimeIcon';
    case NotificationTrigger.SelfRosterIterationChanged:
    case NotificationTrigger.SelfRosterNewIteration:
    case NotificationTrigger.SelfRosterNextIteration:
      return 'SelfRosteringIcon';
    default:
      return '';
  }
};

export const setNotificationInterval = (): void => {
  if (!unreadInterval) {
    unreadInterval = setInterval(() => {
      getUnreadNotificationsAndChats();
    }, chatTypes.POLL_UNREAD_GENERAL_TIMER);
  }
  getUnreadNotificationsAndChats();
};

export const removeNotificationInterval = (): void => {
  if (unreadInterval) clearInterval(unreadInterval);
};

const getUnreadNotificationsAndChats = () => {
  store.dispatch(new chatActions.GetUnreadCountAction());
  store.dispatch(new notificationsActions.GetUnreadCountAction());
};
