import React, { FC } from 'react';
import { Button, Icon } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { IDayShiftOffer, ShiftOfferApplicantStatus, UpdateApplicantStatus } from '../../../../models';
import { shiftOfferActions, shiftOfferSelectors } from '../../../../redux';
import { date, requestsUtils, translations } from '../../../../utils';
import ShiftOfferShift from '../../../shift/Shift';

import './shiftOfferDetail.scss';

type Props = {
  shiftOffer: IDayShiftOffer;
  hideActions?: boolean;
}

const ShiftOfferDetail: FC<Props> = ({ shiftOffer, hideActions }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(shiftOfferSelectors.isUpdateApplicantStatusLoading);

  const removeFromOffer = () => updateApplicantStatus(UpdateApplicantStatus.Withdraw);
  const applyForOffer = () => updateApplicantStatus(UpdateApplicantStatus.Apply);
  const updateApplicantStatus = (type: UpdateApplicantStatus) => {
    dispatch(new shiftOfferActions.UpdateShiftOfferApplicantStatusAction({
      id: shiftOffer.id,
      type,
      date: shiftOffer.date,
      onError: (error) => {
        toast.error(error.detail);
      },
    }));
  };

  const lastUpdatedDate = requestsUtils.getRelativeDate(shiftOffer?.updatedAt, date.format);

  const isDenied = shiftOffer.applicantStatus === ShiftOfferApplicantStatus.Denied;
  const isPending = shiftOffer.applicantStatus === ShiftOfferApplicantStatus.Pending;
  const isAccepted = shiftOffer.applicantStatus === ShiftOfferApplicantStatus.Accepted;
  const isNew = !shiftOffer.applicantStatus;

  const showSubdetails = (isPending || isNew);
  return (
    <div className="shift-offer-detail">
      {shiftOffer.shifts.map(shift => <ShiftOfferShift key={shift.id} shift={shift} />)}

      {shiftOffer.departmentSwitch &&
        <div className="shift-offer-shift">
          <div className="row">
            <span className="label">{translations.getLabel('lblDepartment')}</span>
            <span className="strong with-icon">{shiftOffer?.departmentNameOfExecution} <Icon tag="SwitchIcon" customSize={1.6} /></span>
          </div>
        </div>
      }

      <div className="hr" />

      {showSubdetails && <>
        <div className="subdetails">
          <Icon tag="UsersIcon" customSize={2} color="primaryDark" />
          <span>{translations.getLabel('lblAmountOfPeopleNeeded', { amount: shiftOffer.numberOfPeople || 0 })}</span>
        </div>

        <div className="subdetails">
          <Icon tag="CandidatsIcon" customSize={2} color="primaryDark" />
          <span>{translations.getLabel('lblShiftOfferNumberOfApplicants', { amount: shiftOffer.numberOfApplicants || 0 })}</span>
        </div>
      </>
      }

      {isPending &&
        <div className="subdetails">
          <Icon tag="CheckIcon" customSize={2} color="primaryDark" />
          <span>{translations.getLabel('lblYoureCandidate')}</span>
        </div>
      }

      {(isDenied || isAccepted) &&
        <div className="applicant-status">
          <p>
            <Icon tag={isDenied ? 'CrossIcon' : 'CheckIcon'} customSize={2} color={isDenied ? 'error' : 'success'} />
            <span className={isDenied ? 'error' : 'success'}>{translations.getLabel(isDenied ? 'lblRefused' : 'lblApproved')}</span>
          </p>
          <p className="timestamp">{lastUpdatedDate.shouldTranslate ? `${translations.getLabel(lastUpdatedDate.value)} ${lastUpdatedDate.time}` : `${lastUpdatedDate.value}`} {translations.getLabel('lblBy')} {shiftOffer?.reviewerName || '-'}</p>        </div>
      }

      {showSubdetails && !hideActions &&
        <div className="action">
          {isNew && <Button isLoading={isLoading} onClick={applyForOffer}>{translations.getLabel('btnApplyForOffer')}</Button>}
          {isPending && <Button isLoading={isLoading} theme="inverse" onClick={removeFromOffer}>{translations.getLabel('btnRemoveFromOffer')}</Button>}
        </div>
      }
    </div>
  );
};

export default ShiftOfferDetail;


