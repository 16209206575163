import { createSelector } from 'reselect';
import { AppState } from '../rootReducer';
import { ConfigState } from './reducer';
import { ILabeledLanguage, IHoldingPreferences, ICompanyPreferences, WeekDay } from '../../models';
import * as languageUtils from '../../utils/language';

const selectNode = (state: AppState) => state.config;


export const defaultLanguage = createSelector(
  selectNode,
  (state: ConfigState): ILabeledLanguage => {
    const value = state.holding?.defaultLanguage;
    return {
      label: languageUtils.getLanguageLabel(value),
      value
    };
  }
);

export const getBuild = createSelector(
  selectNode,
  (state: ConfigState) => state.build,
);

export const getCalendarLinks = createSelector(
  selectNode,
  (state: ConfigState) => state.calendarLinks,
);

export const getCompanyConfig = createSelector(
  selectNode,
  (state: ConfigState) => state.companyConfig,
);

export const isCrossDepartmentsShiftOffersEnabled = createSelector(
  selectNode,
  (state: ConfigState) => state.companyConfig.enableCrossDepartmentsShiftOffers,
);

export const isShiftTimeAdjustmentsEnabled = createSelector(
  selectNode,
  (state: ConfigState) => state.companyConfig?.enableShiftTimeAdjustments,
);

export const getCompanyPreferences = createSelector(
  selectNode,
  (state: ConfigState) => state.companyPreferences,
);

export const getStartDayOfWeek = createSelector(
  getCompanyConfig,
  (config) => config?.startOfWeek === WeekDay.Monday ? 1 : 0,
);

export const getHoldingId = createSelector(
  selectNode,
  (state: ConfigState) => state.holding?.holdingId,
);

export const getHoldingPreferences = createSelector(
  selectNode,
  (state: ConfigState) => state.holding,
);

export const getLatestVersion = createSelector(
  selectNode,
  (state: ConfigState) => state.latestVersion,
);

export const getMinVersion = createSelector(
  selectNode,
  (state: ConfigState) => state.minVersion,
);

export const hasHoldingError = createSelector(
  selectNode,
  (state: ConfigState) => state.hasHoldingError,
);

export const hasHealthError = createSelector(
  selectNode,
  (state: ConfigState) => state.hasHealthError,
);

export const getVersion = createSelector(
  selectNode,
  (state: ConfigState) => state.version,
);

export const isCalendarLinksLoading = createSelector(
  selectNode,
  (state: ConfigState) => state.isCalendarLinksLoading,
);

export const isSpxDown = createSelector(
  selectNode,
  (state: ConfigState) => state.isSpxDown,
);

export const languages = createSelector(
  selectNode,
  (state: ConfigState): ILabeledLanguage[] => state.holding ? state.holding.languages.map(value => ({
    label: languageUtils.getLanguageLabel(value),
    value,
  }))
    : [],
);

export const termsAndConditionsUrl = createSelector(
  selectNode,
  (state: ConfigState): string => state.termsAndConditionsUrl,
);

export const getHeaderColor = createSelector(
  getHoldingPreferences,
  getCompanyPreferences,
  (holdingPref: IHoldingPreferences, companyPref: ICompanyPreferences) => (companyPref?.headerColor || holdingPref?.headerColor),
);