import React, { FC } from 'react';
import { Icon } from 'react-ess-components';
import { useSelector } from 'react-redux';
import { eachDayOfInterval, isSameDay, parseISO } from 'date-fns';

import { TimeScale } from '../../../../../models';
import { useSelfRosterContext, useSelfRosterSelectedShifts } from '../../../../../modules';
import { configSelectors } from '../../../../../redux';
import { date, scheduleUtils, translations } from '../../../../../utils';
import SelfRosterShift from '../../SelfRosterShift/SelfRosterShift';

const Round2Schedule: FC = () => {
  const { selectedDate, selectedShifts, selectedIteration, isMultiDepartment } = useSelfRosterContext();
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const { startDate, endDate } = scheduleUtils.getBoundsOfRange(TimeScale.Week, selectedDate, false, weekStartsOn);
  const { data: originalRoster } = useSelfRosterSelectedShifts({ startDate: selectedIteration?.startDate, endDate: selectedIteration?.endDate, iterationId: selectedIteration?.id });

  const originalShifts = originalRoster?.flatMap(roster => roster.shifts);

  const renderWeek = (original: boolean) => {
    const shifts = original ? originalShifts : selectedShifts;
    return (
      <div className="week original-roster">
        <p>{translations.getLabel(original ? 'lblOriginalPlanning' : 'lblNewPlanning')}</p>
        <div className="week-roster">
          {eachDayOfInterval({ start: startDate, end: endDate }).map((day) => {
            const shiftsToday = shifts?.filter(shift => isSameDay(day, parseISO(shift.startDate)));
            return (
              <div key={day.toISOString()} className="week-day">
                <p>{date.format(day, 'EEEEEEE d MMMM')}</p>
                <div className="shifts">
                  {shiftsToday?.length > 0 ?
                    shiftsToday.map(shift => {
                      let isNewShiftForThisRound = false;
                      if (!original && !originalShifts.some((_shift => shift.uuid === _shift.uuid))) {
                        isNewShiftForThisRound = true;
                      }
                      return <SelfRosterShift readOnly key={shift.uuid} shift={shift} isLocked={!isNewShiftForThisRound} showDepartment={isMultiDepartment} />;
                    })
                    :
                    <span className="empty-day">{translations.getLabel('lblNoShiftsForDay')}</span>
                  }
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
      <div className="self-roster-save-week-schedule">
        {renderWeek(true)}
        <div className="arrow">
          <Icon tag="ArrowRight" />
        </div>
        {renderWeek(false)}
      </div>
  );
};

export default Round2Schedule;
