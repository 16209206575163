import { connect } from 'react-redux';

import { chatSelectors, userSelectors } from '../../redux';
import Chat from './Chat.view';

const mapStateToProps = state => ({
  conversationIds: chatSelectors.getConversationIds(state),
  roomIds: chatSelectors.getRoomIds(state),
  userId: userSelectors.getUserId(state),
});

export default connect(mapStateToProps)(Chat);


