import React, { FC } from 'react';
import { Orb } from 'react-ess-components';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { History } from 'history';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { routes } from '../../../../constants';
import { requestsSelectors } from '../../../../redux';
import { date, requestsUtils,translations } from '../../../../utils';

interface Props extends RouteComponentProps {
  id: string;
  departmentId: string;
  history: History;
  selectedId: string;
}

const RequestPreview: FC<Props> = (props) => {
  const request = useSelector((state: AppState) => requestsSelectors.getDepartmentShiftOffer(state, props.id));

  const onSelectRequest = (): void => {
    props.history.push(`${routes.scheduleRequests.shiftOfferManagerInbox[0]}/${props.departmentId}/${request.id}`);
  };

  const relativeDate = requestsUtils.getRelativeDate(request.updatedAt, date.format);

  const className = classNames('stripped-button request', {
    'request-selected': props.selectedId === request.id,
  });

  return (
    <button className={className} onClick={onSelectRequest}>
      <div className="request-change">{relativeDate.shouldTranslate ? `${translations.getLabel(relativeDate.value)} ${relativeDate.time}` : `${relativeDate.value}`}</div>
      <div className="request-status">
        <Orb color={requestsUtils.getColorByRequestStatus(request.shiftOfferStatus)} filled small />{` ${requestsUtils.getShiftOfferRequestStatus(request.shiftOfferStatus, translations.getLabel, request.numberOfApplicants)}`}
      </div>
      <div className="request-text">{translations.getLabel('lblShiftOfferForDay', { day: date.format(request.date, 'eee dd LLL') })}</div>
    </button>
  );
};

export default withRouter(RequestPreview);
