import { Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { transactionService } from '../../services';
import { CreateTransactionAction, CreateTransactionFulfilledAction, CreateTransactionRejectedAction, GetTransactionTypesFulfilledAction, GetTransactionTypesRejectedAction, GetTransactionValueAction, GetTransactionValueFulfilledAction, GetTransactionValueRejectedAction } from './actions';

import ActionType from './types';

export const createTransactionEpic$: Epic = action$ =>
  action$.ofType(ActionType.CreateTransaction).pipe(
    switchMap(({ payload }: CreateTransactionAction) =>
      from(transactionService.createTransaction(payload.type, payload.value, payload.date, payload.reason)).pipe(
        map(() => {
          payload.onSuccess?.();
          return new CreateTransactionFulfilledAction();
        }),
        catchError(error => {
          payload.onError?.(error);
          return of(new CreateTransactionRejectedAction({ error }));
        })
      )
    ),
  );

export const getTransactionTypesEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetTransactionTypes).pipe(
    switchMap(() =>
      from(transactionService.getTransactionTypes()).pipe(
        map(data => new GetTransactionTypesFulfilledAction({ data })),
        catchError(error => of(new GetTransactionTypesRejectedAction({ error })))
      )
    ),
  );

export const getTransactionValueEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetTransactionValue).pipe(
    switchMap(({ payload }: GetTransactionValueAction) =>
      from(transactionService.getTransactionValue(payload.transactionId, payload.date)).pipe(
        map(data => new GetTransactionValueFulfilledAction({ data })),
        catchError(error => of(new GetTransactionValueRejectedAction({ error })))
      )
    ),
  );

const epics = [
  createTransactionEpic$,
  getTransactionTypesEpic$,
  getTransactionValueEpic$,
];

export default epics;


