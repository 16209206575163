import { IShift } from './schedule';
import { IDateStringRange } from './date';
import { RequestStatus } from './requests';

export enum ShiftSwapStatus {
  Accepted = 'ACCEPTED',
  Denied = 'DENIED',
  Pending = 'PENDING',
  Open = 'OPEN',
}
export interface IDayShiftSwap {
  applicantName?: string;
  applicantStatus: ShiftSwapStatus;
  counterSwapDates?: IDateStringRange[];
  createdAt?: string;
  createdBy?: string;
  date?: string;
  hasCounterSwap: boolean;
  id: string;
  numberOfApplicants: number;
  requesterEmployeeId?: string;
  requesterName: string;
  reviewerName?: string;
  reviewerNote?: string;
  selectedCounterSwapDate?: string;
  shifts: IShift[];
  status?: RequestStatus;
  updatedAt?: string;
}

export type IShiftSwaps = Record<string, IDayShiftSwap[]>
