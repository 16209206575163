import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Icon, Modal, ModalButton, Popup, Position } from 'react-ess-components';
import { IconName } from 'react-ess-components/build/assets/icons';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

type Props = {
  icon: IconName;
  label: string;
  children?: ReactNode;
  unreadBadge?: number;
  modal?: boolean;
  position?: Position;
  rightButtonProps?: ModalButton;
}

const NavBarSelect: FC<Props> = ({ icon, children, unreadBadge, modal, position, rightButtonProps, label }) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const history = useHistory();
  const container = useRef(null);

  const toggleMenu = () => {
    // Remove scrolling from the body so we can scroll in the modal.
    if (!isMenuVisible && modal && document.body.style.overflow !== 'hidden') {
      document.body.style.overflow = 'hidden';
    } else {
      if (document.body.style.overflow === 'hidden') document.body.style.overflow = 'initial';
    }

    setIsMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    return history.listen(focusChanged);
  }, [history]);


  const focusChanged = () => {
    document.body.style.overflow = 'initial';
    setIsMenuVisible(false);
  };


  // const ButtonIcon = icons[icon];
  const badgeClassNames = classNames('badge', {
    'badge-10': unreadBadge > 9,
    'badge-100': unreadBadge > 99,
  });

  return (
    <div className="navbar-select" ref={container}>
      <button className="navbar-select-button" onClick={toggleMenu}>
        {!!unreadBadge && <div className={badgeClassNames}>
          {unreadBadge > 99 ? '99+' : unreadBadge}
        </div>}
        {!!icon && <Icon tag={icon} color="white" />}
      </button>
      {!!children && !modal && (
        <Popup isDropdown position={position} open={isMenuVisible} requestClose={toggleMenu} excludePressArea={[container.current]}>
          {children}
        </Popup>
      )}

      {!!children && modal && (
        <Modal open={isMenuVisible} requestClose={toggleMenu} rightButtonProps={rightButtonProps}>
          {children}
        </Modal>
      )}
    </div >
  );
};

NavBarSelect.defaultProps = {
  children: null,
  unreadBadge: 0,
  modal: false,
  rightButtonProps: null,
  position: Position.RightTop,
};

export default NavBarSelect;
