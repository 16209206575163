import { Action } from 'redux';

import ActionType from './types';
import { IServerError } from '../../http/HttpError';
import { ICalenderLinks, ICompanyConfig, ICompanyPreferences, IHoldingPreferences, ITermsAndConditions } from '../../models';

export class GetAppVersionAction implements Action<ActionType> {
  readonly type = ActionType.GetAppVersion;
  constructor(public payload: { os: string }) { }
}

export class GetAppVersionFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetAppVersionFulfilled;
  constructor(public payload: { minVersion: string; latestVersion: string; }) { }
}

export class GetAppVersionRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetAppVersionRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetApiVersionAction implements Action<ActionType> {
  readonly type = ActionType.GetApiVersion;
}

export class GetApiVersionFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetApiVersionFulfilled;
  constructor(public payload: { build: string; version: string; }) { }
}

export class GetApiVersionRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetApiVersionRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetCalendarLinksAction implements Action<ActionType> {
  readonly type = ActionType.GetCalendarLinks;
}

export class ShowSpxIsDown implements Action<ActionType> {
  readonly type = ActionType.SpxIsDown;
  constructor(public payload: { oldAction: Action }) { }
}

export class GetCalendarLinksFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetCalendarLinksFulfilled;
  constructor(public payload: { data: ICalenderLinks }) { }
}

export class GetCalendarLinksRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetCalendarLinksRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetCompanyConfigAction implements Action<ActionType> {
  readonly type = ActionType.GetCompanyConfig;
  constructor(public payload: { companyId: string }) { }
}

export class GetCompanyConfigFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetCompanyConfigFulfilled;
  constructor(public payload: { data: ICompanyConfig; }) { }
}

export class GetCompanyConfigRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetCompanyConfigRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetCompanyPreferencesAction implements Action<ActionType> {
  readonly type = ActionType.GetCompanyPreferences;
  constructor(public payload: { companyId: string }) { }
}

export class GetCompanyPreferencesFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetCompanyPreferencesFulfilled;
  constructor(public payload: { data: ICompanyPreferences; }) { }
}

export class GetCompanyPreferencesRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetCompanyPreferencesRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetHoldingPreferencesAction implements Action<ActionType> {
  readonly type = ActionType.GetHoldingPreferences;
  constructor(public payload: { holdingName: string, onSuccess?: (preferences: IHoldingPreferences) => void, onError?: (error: IServerError) => void }) { }
}

export class GetHoldingPreferencesFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetHoldingPreferencesFulfilled;
  constructor(public payload: { data: IHoldingPreferences; onSuccess?: (preferences: IHoldingPreferences) => void }) { }
}

export class GetHoldingPreferencesRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetHoldingPreferencesRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetHealthAction implements Action<ActionType> {
  readonly type = ActionType.GetHealth;
  constructor(public payload?: { onSuccess: () => void }) { }
}

export class GetHealthFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetHealthFulfilled;
}

export class GetHealthRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetHealthRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetTermsAndConditionsAction implements Action<ActionType> {
  readonly type = ActionType.GetTermsAndConditions;
}

export class GetTermsAndConditionsFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetTermsAndConditionsFulfilled;
  constructor(public payload: { data: ITermsAndConditions }) { }
}

export class GetTermsAndConditionsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetTermsAndConditionsRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class ResetFatalErrors implements Action<ActionType> {
  readonly type = ActionType.ResetFatalErrors;
}

export type Actions =
  | GetAppVersionAction
  | GetAppVersionFulfilledAction
  | GetAppVersionRejectedAction
  | GetApiVersionAction
  | GetApiVersionFulfilledAction
  | GetApiVersionRejectedAction
  | GetCalendarLinksAction
  | GetCalendarLinksFulfilledAction
  | GetCalendarLinksRejectedAction
  | GetCompanyConfigAction
  | GetCompanyConfigFulfilledAction
  | GetCompanyConfigRejectedAction
  | GetCompanyPreferencesAction
  | GetCompanyPreferencesFulfilledAction
  | GetCompanyPreferencesRejectedAction
  | GetHoldingPreferencesAction
  | GetHoldingPreferencesFulfilledAction
  | GetHoldingPreferencesRejectedAction
  | GetHealthAction
  | GetHealthFulfilledAction
  | GetHealthRejectedAction
  | GetTermsAndConditionsAction
  | GetTermsAndConditionsFulfilledAction
  | GetTermsAndConditionsRejectedAction
  | ResetFatalErrors
  | ShowSpxIsDown;