import React from 'react';
import { Avatar } from 'react-ess-components';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { ChatItem as ChatItembase } from '../../../../shared/components';
import { chatTypes } from '../../constants';
import { chatSelectors } from '../../redux';
import { date, translations } from '../../utils';

import './chatItem.scss';
export class ChatItem extends ChatItembase {
  getAvatar = () => {
    const { type } = this.props.conversation;

    if (type === chatTypes.SINGLE) {
      const participant = this.getParticipant();
      return <Avatar firstName={participant.firstName} lastName={participant.lastName} source={participant.profilePictureUrl} />;
    } else if (type === chatTypes.GROUP) {
      return <Avatar isGroup />;
    } else if (type === chatTypes.COMPANY) {
      return <Avatar isCompany />;
    }
  }

  onClick = () => {
    this.props.onClick(this.props.conversation.id);
  }

  render() {
    const { conversation, selected } = this.props;
    const className = classNames('stripped-button chat-item', {
      'chat-item-unread': !conversation.read,
      'chat-item-selected': selected,
    });
    return (
      <button className={className} onClick={this.onClick}>
        {this.props.conversation && this.getAvatar()}
        <div className="chat-item-content">
          <div className="chat-item-title-time"><div className="chat-item-title">{conversation.title}</div> <div className="chat-item-time">{this.getTimeLabel(date.format, translations)}</div></div>
          <div className="chat-item-preview">{this.getLastMessage()}</div>
        </div>
      </button>
    );
  }
}

const mapStateToProps = (state, props) => ({
  conversation: chatSelectors.getConversation(state, props.id),
});

export default connect(mapStateToProps)(ChatItem);

