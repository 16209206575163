import React from 'react';
import DocumentTitle from 'react-document-title';
import { Button, Icon, Search } from 'react-ess-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Socket, { mapStateToProps } from '../../../../shared/components/socket/Socket.container';
import { ChatItem } from '../../components';
import { chatActions } from '../../redux';
import { notificationUtils, translations } from '../../utils';
import ChatConversation from './components/chatConversation/ChatConversation.container';
import StartConversation from './components/startConversation/StartConversation.container';

import './chat.scss';

class Chat extends Socket {
  constructor(props) {
    super(props);
    this.selectedIdFromUrl = null;
    this.state = {
      isOpen: false,
      selectedId: null,
    };
  }

  componentDidMount() {
    this.props.dispatch(new chatActions.GetConversationsAction({
      onSuccess: () => {
        this.mountSocket(process.env.REACT_APP_SOCKET_HOST);
      },
    }));
  }

  componentDidUpdate(prevProps) {
    if (this.props.conversationIds?.length && this.props.match.params?.id && this.selectedIdFromUrl !== this.props.match.params.id) this.checkUrl();

    if (this.socket && prevProps.roomIds.length !== this.props.roomIds.length) {
      // check for new conversations
      this.props.roomIds.forEach(currentConversation => {
        if (!prevProps.roomIds.find(prevConversation => prevConversation === currentConversation)) this.joinConversation(currentConversation);
      });
      // check for removed conversations
      prevProps.roomIds.forEach(prevConversation => {
        if (!this.props.roomIds.find(currentConversation => currentConversation === prevConversation)) this.leaveConversation(prevConversation);
      });
    }
  }

  componentWillUnmount() {
    this.props.dispatch(new chatActions.GetConversationsAction());
    if (this.socket?.connected) this.unmountSocket();
  };

  selectChat = selectedId => {
    notificationUtils.changeIdInUrl(selectedId);
    this.setState({ selectedId });
  };

  checkUrl = () => {
    this.selectedIdFromUrl = this.props.match.params.id;
    if (this.selectedIdFromUrl && this.props.conversationIds.includes(this.selectedIdFromUrl)) {
      this.selectChat(this.selectedIdFromUrl);
    }
  }

  renderConversation = (chatId) => <ChatItem key={chatId} id={chatId} userId={this.props.userId} onClick={this.selectChat} selected={this.state.selectedId === chatId} />

  requestClose = () => {
    this.setState({ isOpen: false });
  }

  openStartConversationModal = () => {
    this.setState({ isOpen: true });
  }

  onLeaveConversation = () => {
    this.setState({ selectedId: null });
  }

  onSearch = (value) => {
    this.props.dispatch(new chatActions.GetConversationsAction({ query: value }));
    notificationUtils.changeIdInUrl('');
    this.setState({ selectedId: null });
  }

  render() {
    return (
      <DocumentTitle title={translations.getLabel('titleChat')}>
        <div className="page fixed-width app-chat">
          <div className="header">
            <h2>{translations.getLabel('titleChat')}</h2>
          </div>
          <section className="chat-container">
            <aside className="chat-menu">
              <header>
                <Button theme="transparent" onClick={this.openStartConversationModal}><Icon tag="PlusSquareIcon" /> {translations.getLabel('btnStartNewChat')}</Button>
              </header>
              <div className="chat-conversations">
                <Search placeholder={translations.getLabel('lblSearchChat')} submit={this.onSearch} />
                {this.props.conversationIds.map(this.renderConversation)}
              </div>
            </aside>
            <ChatConversation id={this.state.selectedId} onLeaveConversation={this.onLeaveConversation} sendMessage={this.sendMessage} />
          </section>
          <StartConversation isOpen={this.state.isOpen} requestClose={this.requestClose} selectChat={this.selectChat} joinConversation={this.joinConversation} />
        </div>
      </DocumentTitle>
    );
  }
}

Chat.propTypes = {
  conversationIds: PropTypes.array.isRequired,
  conversations: PropTypes.array,
  roomIds: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export { Chat };
export default connect(mapStateToProps)(Chat);
