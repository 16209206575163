import { createSelector } from 'reselect';
import { parseISO, isSameDay, startOfDay, endOfDay, isSameMinute } from 'date-fns';

import { RequestState } from './reducer';
import { AppState } from '../rootReducer';
import { IVacationType, IVacationRequest, IShiftOfferRequest, IShiftSwapRequest, RequestStatus, RequestType, ITransaction, ITransactionRequest, IShiftTimeRequest, IRequestCounter } from '../../models';

const selectNode = (state: AppState) => state.requests;

export const isTransactionRequestLoading = createSelector(
  selectNode,
  (state: RequestState) => state.isTransactionRequestLoading,
);

export const isMoreTransactionRequestLoading = createSelector(
  selectNode,
  (state: RequestState) => state.isMoreTransactionRequestLoading,
);

export const isTransactionUpdateLoading = createSelector(
  selectNode,
  (state: RequestState) => state.isTransactionUpdateLoading,
);

export const isDepartmentTransactionsLoading = createSelector(
  selectNode,
  (state: RequestState) => state.isDepartmentTransactionsLoading,
);

export const isDepartmentShiftTimesLoading = createSelector(
  selectNode,
  (state: RequestState) => state.isDepartmentShiftTimesLoading,
);

export const isMoreDepartmentTransactionsLoading = createSelector(
  selectNode,
  (state: RequestState) => state.isMoreDepartmentTransactionsLoading,
);

export const isShiftTimeRequestLoading = createSelector(
  selectNode,
  (state: RequestState) => state.isShiftTimeRequestLoading,
);

export const isMoreShiftTimeRequestLoading = createSelector(
  selectNode,
  (state: RequestState) => state.isMoreShiftTimeRequestLoading,
);

export const isShiftTimeUpdateLoading = createSelector(
  selectNode,
  (state: RequestState) => state.isShiftTimeUpdateLoading,
);

export const isMoreDepartmentShiftTimesLoading = createSelector(
  selectNode,
  (state: RequestState) => state.isMoreDepartmentShiftTimesLoading,
);

export const didVacationTypesLoad = createSelector(
  selectNode,
  (state: RequestState) => state.didVacationTypesLoad,
);

export const isVacationRequestLoading = createSelector(
  selectNode,
  (state: RequestState) => state.isVacationRequestLoading,
);

export const isMoreVacationRequestLoading = createSelector(
  selectNode,
  (state: RequestState) => state.isMoreVacationRequestLoading,
);

export const isDepartmentVacationRequestLoading = createSelector(
  selectNode,
  (state: RequestState) => state.isDepartmentVacationRequestLoading,
);

export const isMoreDepartmentVacationRequestLoading = createSelector(
  selectNode,
  (state: RequestState) => state.isMoreDepartmentVacationRequestLoading,
);

export const isDepartmentShiftOffersLoading = createSelector(
  selectNode,
  (state: RequestState) => state.isDepartmentShiftOffersLoading,
);

export const isMoreDepartmentShiftOffersLoading = createSelector(
  selectNode,
  (state: RequestState) => state.isMoreDepartmentShiftOffersLoading,
);

export const isShiftOfferUpdateLoading = createSelector(
  selectNode,
  (state: RequestState) => state.isShiftOfferUpdateLoading,
);

export const getRuleViolated = createSelector(
  selectNode,
  (state: RequestState) => state.ruleViolated,
);

export const isDepartmentShiftSwapsLoading = createSelector(
  selectNode,
  (state: RequestState) => state.isDepartmentShiftSwapsLoading,
);

export const isMoreDepartmentShiftSwapsLoading = createSelector(
  selectNode,
  (state: RequestState) => state.isMoreDepartmentShiftSwapsLoading,
);

export const isShiftSwapUpdateLoading = createSelector(
  selectNode,
  (state: RequestState) => state.isShiftSwapUpdateLoading,
);

export const isVacationUpdateLoading = createSelector(
  selectNode,
  (state: RequestState) => state.isVacationUpdateLoading,
);

export const hasVacationTypesError = createSelector(
  selectNode,
  (state: RequestState) => state.hasVacationTypesError,
);

export const getAllAvailableRequestDepartments = createSelector(
  (state: AppState) => state.requests.departments,
  (state: AppState) => state.requests.shiftOfferDepartments,
  (state: AppState) => state.requests.shiftSwapDepartments,
  (vacationsDepartments, shiftOfferDepartments, shiftSwapDepartments) => {
    const allDepartments = (vacationsDepartments || []).concat(shiftOfferDepartments || []).concat(shiftSwapDepartments || []).filter(function (dep) {
      return this[dep.departmentId] ? false : this[dep.departmentId] = true;
    }, {});
    return allDepartments;
  }
);

export const hasRequestRightsForDepartment = createSelector(
  (state: AppState, type: RequestType) => {
    if (type === RequestType.Vacation) return state.requests.departments;
    if (type === RequestType.ShiftOffer) return state.requests.shiftOfferDepartments;
    if (type === RequestType.ShiftSwap) return state.requests.shiftSwapDepartments;
    if (type === RequestType.Transaction) return state.requests.transactionDepartments;
    if (type === RequestType.ShiftTime) return state.requests.shiftTimeDepartments;
  },
  (state: AppState, type: RequestType) => state.requests.activeDepartment,
  (departments, departmentId) => {
    return departments?.some(department => department.departmentId === departmentId);
  }
);

export const getTransactionRequest = (state: AppState, id: string): ITransactionRequest => {
  const requestFromList = state.requests.transactionRequests?.[id];
  if (requestFromList) return requestFromList;
  const transactionRequest = state.requests.transaction;
  if (transactionRequest?.id === id) return transactionRequest;
  return null;
};

export const getDepartmentTransaction = (state: AppState, id: string): ITransactionRequest => {
  const transaction = state.requests.transaction;
  if (transaction?.id === id) return transaction;
  const requestFromList = state.requests.departmentTransactions?.[id];

  if (requestFromList) return requestFromList;
  return null;
};

export const getShiftTimeRequest = (state: AppState, id: string): IShiftTimeRequest => {
  const requestFromList = state.requests.shiftTimeRequests?.[id];
  if (requestFromList) return requestFromList;
  const shiftTimeRequest = state.requests.shiftTimeRequest;
  if (shiftTimeRequest?.id === id) return shiftTimeRequest;
  return null;
};

export const getDepartmentShiftTime = (state: AppState, id: string): IShiftTimeRequest => {
  const shiftTime = state.requests.shiftTime;
  if (shiftTime?.id === id) return shiftTime;
  const requestFromList = state.requests.departmentShiftTimes?.[id];

  if (requestFromList) return requestFromList;
  return null;
};

export const getVacationRequestCounters = (state: AppState, id: string): IRequestCounter[] => {
  const vacationRequest = state.requests.vacationRequest;
  if (vacationRequest?.id === id) return vacationRequest?.counters;
  return null;
};

export const getVacationRequest = (state: AppState, id: string): IVacationRequest => {
  const vacationRequest = state.requests.vacationRequest;
  if (vacationRequest?.id === id) return vacationRequest;
  const requestFromList = state.requests.vacationRequests?.[id];
  if (requestFromList) return requestFromList;
  return null;
};

export const getDepartmentVacationRequest = (state: AppState, id: string): IVacationRequest => {
  const vacationRequest = state.requests.vacationRequest;
  if (vacationRequest?.id === id) return vacationRequest;
  const requestFromList = state.requests.departmentVacationRequests?.[id];
  if (requestFromList) return requestFromList;
  return null;
};

export const getDepartmentTransactionRequest = (state: AppState, id: string): ITransactionRequest => {
  const requestFromList = state.requests.departmentTransactions?.[id];
  if (requestFromList) return requestFromList;

  const transactionRequest = state.requests.transaction;
  if (transactionRequest?.id === id) return transactionRequest;
  return null;
};

export const getDepartmentShiftOffer = (state: AppState, id: string): IShiftOfferRequest => {
  const shiftOffer = state.requests.shiftOffer;
  if (shiftOffer?.id === id) return shiftOffer;
  const requestFromList = state.requests.departmentShiftOffers?.[id];
  if (requestFromList) return requestFromList;
  return null;
};

export const getDepartmentShiftSwap = (state: AppState, id: string): IShiftSwapRequest => {
  const shiftSwap = state.requests.shiftSwap;
  if (shiftSwap?.id === id) return shiftSwap;
  const requestFromList = state.requests.departmentShiftSwaps?.[id];
  if (requestFromList) return requestFromList;
  return null;
};

export const getTransactionRequests = createSelector(
  selectNode,
  (state: RequestState) => state.transactionRequests,
);

export const getDepartmentTransactions = createSelector(
  selectNode,
  (state: RequestState) => state.departmentTransactions,
);

export const getShiftTimeRequests = createSelector(
  selectNode,
  (state: RequestState) => state.shiftTimeRequests,
);

export const getDepartmentShiftTimes = createSelector(
  selectNode,
  (state: RequestState) => state.departmentShiftTimes,
);

export const getTransactionRequestsMetaData = createSelector(
  selectNode,
  (state: RequestState) => state.transactionRequestsMetaData,
);

export const getTransactionDepartments = createSelector(
  selectNode,
  (state: RequestState) => state.transactionDepartments,
);

export const getShiftTimeRequestsMetaData = createSelector(
  selectNode,
  (state: RequestState) => state.shiftTimeRequestsMetaData,
);

export const getShiftTimeDepartments = createSelector(
  selectNode,
  (state: RequestState) => state.shiftTimeDepartments,
);

export const getVacationRequests = createSelector(
  selectNode,
  (state: RequestState) => state.vacationRequests,
);

export const getVacationNewRequestCounters = createSelector(
  selectNode,
  (state: RequestState) => state.vacationRequestCounters,
);

export const isVacationNewRequestCountersLoading = createSelector(
  selectNode,
  (state: RequestState) => state.isVacationRequestCountersLoading,
);

export const getDepartmentVacationRequests = createSelector(
  selectNode,
  (state: RequestState) => state.departmentVacationRequests,
);

export const getDepartmentShiftOffers = createSelector(
  selectNode,
  (state: RequestState) => state.departmentShiftOffers,
);

export const getDepartmentShiftSwaps = createSelector(
  selectNode,
  (state: RequestState) => state.departmentShiftSwaps,
);

export const getVacationTypes = createSelector(
  selectNode,
  (state: RequestState): IVacationType => state.vacationTypes || { interval: null, shift: null },
);

export const isAllDay = (state: AppState, id: string): boolean => {
  const request = getVacationRequest(state, id) || state.requests.departmentVacationRequests?.[id];
  if (!request) return false;
  return (!isSameDay(parseISO(request.startDate), parseISO(request.endDate)) || (isSameMinute(parseISO(request.startDate), startOfDay(parseISO(request.startDate)))) && (isSameMinute(parseISO(request.endDate), endOfDay(parseISO(request.endDate)))));
};

export const isOneDay = (state: AppState, id: string): boolean => {
  const request = getVacationRequest(state, id) || state.requests.departmentVacationRequests?.[id];
  if (!request) return false;
  return isSameDay(parseISO(request.startDate), parseISO(request.endDate));
};

export const showVacationRequestModal = createSelector(
  selectNode,
  (state: RequestState): boolean => state.showVacationRequestModal,
);

export const getRequestDate = createSelector(
  selectNode,
  (state: RequestState): Date => state.requestDate,
);

export const getDepartmentMetaData = createSelector(
  selectNode,
  (state: RequestState) => state.departmentMetaData,
);

export const getVacationRequestsMetaData = createSelector(
  selectNode,
  (state: RequestState) => state.vacationRequestsMetaData,
);

export const getRequestDepartments = createSelector(
  selectNode,
  (state: RequestState) => state.departments,
);

export const getShiftOfferDepartments = createSelector(
  selectNode,
  (state: RequestState) => state.shiftOfferDepartments,
);

export const getShiftSwapDepartments = createSelector(
  selectNode,
  (state: RequestState) => state.shiftSwapDepartments,
);

export const getCounts = createSelector(
  selectNode,
  (state: RequestState) => state.counts,
);

export const getRequestFilter = (state: AppState, type: RequestType): RequestStatus => {
  return state.requests.filter[type];
};

export const getActiveInbox = (state: AppState): RequestType => {
  return state.requests.activeInbox;
};

export const getActiveDepartment = (state: AppState): string => {
  return state.requests.activeDepartment;
};

export const getRequestCountsForDepartment = (state: AppState): Record<RequestType, number> => {
  const vacationDepartments = getRequestDepartments(state);
  const shiftOfferDepartments = getShiftOfferDepartments(state);
  const shiftSwapDepartments = getShiftSwapDepartments(state);
  const transactionDepartments = getTransactionDepartments(state);
  const shiftTimeDepartments = getShiftTimeDepartments(state);
  const activeDepartment = getActiveDepartment(state);

  return {
    [RequestType.Vacation]: vacationDepartments?.find(department => department.departmentId === activeDepartment)?.count || 0,
    [RequestType.ShiftOffer]: shiftOfferDepartments?.find(department => department.departmentId === activeDepartment)?.count || 0,
    [RequestType.ShiftSwap]: shiftSwapDepartments?.find(department => department.departmentId === activeDepartment)?.count || 0,
    [RequestType.Transaction]: transactionDepartments?.find(department => department.departmentId === activeDepartment)?.count || 0,
    [RequestType.ShiftTime]: shiftTimeDepartments?.find(department => department.departmentId === activeDepartment)?.count || 0,
  };
};