import { HttpClient } from '../http';
import { IGetScheduleParams, IDayPlanning, ISearchPlanning, IExternalVacationRequest } from '../models';

export const get = async (params: IGetScheduleParams): Promise<{ planning: IDayPlanning[] }> => {
  const result = await HttpClient.get<{ data: { planning: IDayPlanning[] } }>('/v1/schedule', { startDate: params.startDate, endDate: params.endDate });
  return result.data;
};

export const getByDepartment = async (params: IGetScheduleParams): Promise<ISearchPlanning[]> => {
  const paramsObject: IGetScheduleParams = {
    startDate: params.startDate, endDate: params.endDate
  };

  if (params.search) {
    paramsObject.search = params.search;
  }

  if (params.departmentId) {
    paramsObject.departmentId = params.departmentId;
  }

  const result = await HttpClient.get<{ data: ISearchPlanning[] }>('/v1/schedule/department', { ...paramsObject });
  return result.data;
};

export const getShiftOfferApplicantsPlanning = async (startDate: string, endDate: string, departmentId: string, shiftOfferId: string): Promise<ISearchPlanning[]> => {
  const result = await HttpClient.get<{ data: ISearchPlanning[] }>(`/v1/management/departments/${departmentId}/shift-offers/${shiftOfferId}/applicants`, { startDate, endDate });
  return result.data;
};

export const getShiftSwapApplicantsPlanning = async (shiftSwapId: string): Promise<ISearchPlanning[]> => {
  const result = await HttpClient.get<{ data: ISearchPlanning[] }>(`/v1/shift-swaps/${shiftSwapId}/applicants`);
  return result.data;
};

export const getUserScheduleExternalVacation = async (params: { startDate: string, endDate: string }) => {
  const result = await HttpClient.get<{ data: IExternalVacationRequest[] }>('/v1/schedule/vacation', { startDate: params.startDate, endDate: params.endDate });
  return result;
};

export const deleteUserScheduleExternalVacation = async (id: string, date: string) => {
  const result = await HttpClient.delete<void>(`/v1/schedule/vacation/${id}?date=${date}`);
  return result;
};

export const getByUser = async (params: IGetScheduleParams): Promise<{ data: ISearchPlanning }> => {
  const result = await HttpClient.get<{ data: ISearchPlanning }>(`/v1/employments/${params.id}/schedule`, { startDate: params.startDate, endDate: params.endDate });

  // We need to add employmentId in the return, since the call itself does not return it
  return {
    data: {
      ...result.data,
      employmentId: params.id,
    },
  };
};
