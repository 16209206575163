import React, { FC, useEffect } from 'react';
import { Icon, Spinner } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { History } from 'history';

import { routes } from '../../../../constants';
import { IRequestDepartment, ITransactionRequest, RequestStatus } from '../../../../models';
import { requestsActions, requestsSelectors } from '../../../../redux';
import RequestPreview from './RequestPreview';

interface Props {
  department: IRequestDepartment;
  onClickDepartment: (id: string) => void;
  isOpen: boolean;
  history: History;
  selectedRequestId: string;
  status: RequestStatus;
}

const DepartmentRequestsList: FC<Props> = ({ department, onClickDepartment, isOpen, history, selectedRequestId, status }) => {
  const dispatch = useDispatch();
  const departmentTransactions = useSelector(requestsSelectors.getDepartmentTransactions);
  const requests = departmentTransactions && Object.values(departmentTransactions);
  const isDepartmentTransactionsLoading = useSelector(requestsSelectors.isDepartmentTransactionsLoading);
  const isMoreDepartmentTransactionsLoading = useSelector(requestsSelectors.isMoreDepartmentTransactionsLoading);

  useEffect(() => {
    if (isOpen) {
      dispatch(new requestsActions.GetDepartmentTransactionsAction({ departmentId: department.departmentId, offset: 0, onSuccess: onInitialLoad, status }));
    }
  }, [isOpen, status]);

  const onInitialLoad = (departmentRequests) => {
    if (!selectedRequestId) history.push(`${routes.scheduleRequests.transactionManagerInbox[0]}/${department.departmentId}/${departmentRequests.data[0]?.id || ''}`);
  };

  return (
    <div className="department-requests-list">
      <button className="department" type="button" onClick={() => onClickDepartment(isOpen ? null : department.departmentId)}>
        {department.name} ({department.count}) <Icon tag={isOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'} />
      </button>
      {isOpen && (
        (isDepartmentTransactionsLoading)
          ? [0, 1, 2].map((index) => <div key={index} className="request-content request-list-loading"><Skeleton height={75} /></div>)
          : (departmentTransactions && requests.map((request: ITransactionRequest) => <RequestPreview id={request.id} departmentId={department.departmentId} key={request.id} selectedId={selectedRequestId} />))
      )}
      {isOpen && isMoreDepartmentTransactionsLoading && <div className="loading-container"><Spinner solid /></div>}
    </div>
  );
};

export default DepartmentRequestsList;

