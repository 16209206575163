import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { eachDayOfInterval } from 'date-fns';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { StorageItem } from '../../../../constants';
import { useToggle } from '../../../../hooks';
import { IDayShiftSwap, TimeScale } from '../../../../models';
import { configSelectors, shiftSwapActions, shiftSwapSelectors, userSelectors } from '../../../../redux';
import { date, scheduleUtils } from '../../../../utils';
import { OpenShiftSwapFunction } from '../../schedule.models';
import DetailPopup from '../detailPopup/DetailPopup';
import RosterColumn from '../shiftOfferRoster/RosterColumn';
import DayHeader from '../weekSchedule/DayHeader';
import ShiftSwapDay from './ShiftSwapDay';

import './ShiftSwapRoster.scss';

interface Props {
  selectedDate: Date;
}

const ShiftSwapRoster: FC<Props> = ({ selectedDate }) => {
  const dispatch = useDispatch();
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const companyConfig = useSelector(configSelectors.getCompanyConfig);
  const userId = useSelector(userSelectors.getUserId);
  const { startDate, endDate } = scheduleUtils.getBoundsOfRange(TimeScale.Week, selectedDate, false, weekStartsOn);
  const shiftSwaps = useSelector((state: AppState) => shiftSwapSelectors.getShiftSwaps(state, startDate, endDate));
  const [openShiftSwap, setOpenShiftSwap] = useState<IDayShiftSwap>(null);
  const rosterRef = useRef(null);
  const [isCollapsed, toggleIsCollapsed] = useToggle(localStorage.getItem(`${StorageItem.IsShiftSwapsHidden}_${userId}`) === 'true');
  const [isPopupOpen, setIsPopupOpen] = useToggle(false);
  const [dayRef, setDayRef] = useState<HTMLElement>(null);

  const closeShift = () => {
    setIsPopupOpen(false);
    setOpenShiftSwap(null);
    setDayRef(null);
  };


  const toggleShiftSwap: OpenShiftSwapFunction = (shiftSwap, ref) => {
    if (isPopupOpen && ref === dayRef) {
      closeShift();
    } else {
      setIsPopupOpen(true);
      setOpenShiftSwap(shiftSwap);
      setDayRef(ref);
    }
  };

  // Hook used to update the open shift swap when a user has applied/withdrawn from it
  useEffect(() => {
    if (openShiftSwap) {
      shiftSwaps.forEach(dayShiftSwaps => dayShiftSwaps.forEach(shiftSwap => {
        if (shiftSwap.id === openShiftSwap.id) setOpenShiftSwap(shiftSwap);
      }));
    }
  }, [shiftSwaps, openShiftSwap]);

  // Get new data on change of date
  useEffect(() => {
    const formattedStartDate = date.format(startDate, 'yyyy-MM-dd');
    const formattedEndDate = date.format(endDate, 'yyyy-MM-dd');
    dispatch(new shiftSwapActions.GetShiftSwapsAction({ startDate: formattedStartDate, endDate: formattedEndDate }));
  }, [selectedDate]);

  const toggleRoster = () => {
    localStorage.setItem(`${StorageItem.IsShiftSwapsHidden}_${userId}`, `${!isCollapsed}`);
    toggleIsCollapsed();
  };

  return (
    <div className={classnames('shift-swap-roster', 'week-roster', { 'with-header': !companyConfig?.modules.shiftOffer })}>
      <DetailPopup hideActions={false} isWeek isOpen={isPopupOpen} shiftSwap={openShiftSwap} scrollRef={rosterRef.current} parentRef={dayRef} close={closeShift} />
      <RosterColumn title="lblShiftSwaps" isCollapsed={isCollapsed} toggleIsCollapsed={toggleRoster} />
      <section ref={rosterRef} className="schedule-shifts" >
        {companyConfig?.modules.shiftSwap && companyConfig.modules.shiftOffer && <div className="schedule-shifts-content week-days" >
          {shiftSwaps.map((dayShiftSwaps, i) => (
            <div className="week-day" key={`swap-${i}`}>
              <ShiftSwapDay isCollapsed={isCollapsed} shiftSwaps={dayShiftSwaps} key={i} openShiftSwap={toggleShiftSwap} />
            </div>
          ))
          }
        </div>}
        {companyConfig?.modules.shiftSwap && !companyConfig.modules.shiftOffer && <div className="schedule-shifts-content week-days" >
          {eachDayOfInterval({ start: startDate, end: endDate }).map((day, i) =>
            <div className="week-day" key={day.toISOString()}>
              <DayHeader day={day} />
              <ShiftSwapDay
                isCollapsed={isCollapsed}
                shiftSwaps={shiftSwaps[i]}
                openShiftSwap={toggleShiftSwap}
              />
            </div>
          )}
        </div>}
      </section>
    </div>
  );
};

export default ShiftSwapRoster;
