import { connect } from 'react-redux';

import { UserRight } from '../../../models';
import { newsSelectors, userSelectors } from '../../../redux';
import NewsItem from './NewsItem.view';

const mapStateToProps = state => ({
  hasNewsRight: userSelectors.hasRight(state, UserRight.News),
  isDeleting: newsSelectors.isDeleting(state),
});

export default connect(mapStateToProps)(NewsItem);
