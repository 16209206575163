import React, { FC, useRef } from 'react';
import { Icon, Orb } from 'react-ess-components';
import { IconName } from 'react-ess-components/build/assets/icons';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { format } from 'date-fns';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { availabilitiesSelectors } from '../../../../redux';
import { scheduleUtils, translations } from '../../../../utils';
import { OpenAvailabilitiesFunction } from '../../schedule.models';

interface Props {
  currentDate: Date;
  openAvailabilities: OpenAvailabilitiesFunction;
  isSelectable?: boolean;
  onSelect?: (date?: string) => void;
  isSelected?: boolean;
  isMultiSelect?: boolean;
  isSelectionDisabled?: boolean;
  hideAvailabilities?: boolean;
}

const getSelectableIcon = (isSelected: boolean, isMultiSelect: boolean): IconName => {
  if (isMultiSelect) {
    return isSelected ? 'CheckedIcon' : 'UncheckedIcon';
  } else {
    return isSelected ? 'RadioCheckedIcon' : 'RadioUncheckedIcon';
  }
};

const WeekDayFooter: FC<Props> = ({ currentDate, openAvailabilities, isSelectable, onSelect, isSelected, isMultiSelect, isSelectionDisabled, hideAvailabilities }) => {
  const timeTypes = useSelector(availabilitiesSelectors.getVisibleTimeTypes);
  const availabilities = useSelector((state: AppState) => availabilitiesSelectors.getAvailabilitiesForDay(state, currentDate));
  const ref = useRef(null);

  const onClick = () => {
    if (isSelectable && !isSelectionDisabled) {
      return onSelect(format(currentDate, 'yyyy-MM-dd'));
    }
    if (getOrbs()?.length) {
      openAvailabilities(availabilities, timeTypes, ref.current);
    }
  };

  const getLabel = () => {
    let type = { description: '' };
    if (availabilities) {
      availabilities.some((availability) => {
        type = timeTypes.find((type) => availability.code === type.code);
        return type;
      });
    }
    return type.description;
  };

  const getOrbs = () => {
    if (!availabilities) return [];
    return scheduleUtils.getAvailabilityTypes(
      availabilities,
      timeTypes,
      (color, index) => <Orb key={`orb${index}`} color={color} filled />,
    );
  };


  const orbs = getOrbs();
  const hasAvailabilities = !!orbs.length && !hideAvailabilities;
  const className = classnames('stripped-button schedule-shifts-footer week-day-footer', { 'no-availabilities': !hasAvailabilities });
  return (
    <>
      {!!hasAvailabilities ?
        <button ref={ref} className={className} onClick={onClick}>
          <>
            <div className="orbs">{orbs}</div>
            {orbs.length > 1
              ? <p>{translations.getLabel('lblMixedAvailability')}</p>
              : <p>{getLabel()}</p>
            }
          </>
          {isSelectable && <Icon tag={getSelectableIcon(isSelected, isMultiSelect)} color={isSelectionDisabled ? 'primaryTransparent' : 'primary'} />}
        </button>
        :
        <button className={className} onClick={onClick} >
          {isSelectable && <Icon tag={getSelectableIcon(isSelected, isMultiSelect)} color={isSelectionDisabled ? 'primaryTransparent' : 'primary'} />}
        </button>
      }

    </>
  );
};

export default WeekDayFooter;
