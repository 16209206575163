import { Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { switchMap, exhaustMap, tap, map, catchError } from 'rxjs/operators';

import ActionType from './types';
import {
  GetAppVersionAction,
  GetAppVersionFulfilledAction,
  GetAppVersionRejectedAction,
  GetApiVersionFulfilledAction,
  GetApiVersionRejectedAction,
  GetCalendarLinksFulfilledAction,
  GetCalendarLinksRejectedAction,
  GetCompanyConfigAction,
  GetCompanyConfigFulfilledAction,
  GetCompanyConfigRejectedAction,
  GetCompanyPreferencesAction,
  GetCompanyPreferencesFulfilledAction,
  GetCompanyPreferencesRejectedAction,
  GetHoldingPreferencesAction,
  GetHoldingPreferencesFulfilledAction,
  GetHoldingPreferencesRejectedAction,
  GetHealthFulfilledAction,
  GetHealthRejectedAction,
  GetHealthAction,
  GetTermsAndConditionsAction,
  GetTermsAndConditionsFulfilledAction,
  GetTermsAndConditionsRejectedAction,
  ShowSpxIsDown
} from './actions';
import { configService } from '../../services';

export const getAppVersionsEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetAppVersion).pipe(
    exhaustMap(({ payload }: GetAppVersionAction) =>
      from(configService.getAppVersion(payload.os)).pipe(
        map(({ minVersion, latestVersion }) => new GetAppVersionFulfilledAction({ minVersion, latestVersion })),
        catchError(error => of(new GetAppVersionRejectedAction({ error })))
      )
    ),
  );

export const getApiVersionsEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetApiVersion).pipe(
    exhaustMap(() =>
      from(configService.getApiVersion()).pipe(
        map(({ build, version }) => new GetApiVersionFulfilledAction({ build, version })),
        catchError(error => of(new GetApiVersionRejectedAction({ error })))
      )
    ),
  );

export const getCalendarLinksEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetCalendarLinks).pipe(
    exhaustMap(() =>
      from(configService.getCalendarLinks()).pipe(
        map(data => new GetCalendarLinksFulfilledAction({ data })),
        catchError(error => of(new GetCalendarLinksRejectedAction({ error })))
      )
    ),
  );

export const getCompanyConfigEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetCompanyConfig).pipe(
    exhaustMap(({ payload }: GetCompanyConfigAction) =>
      from(configService.getCompanyConfig(payload.companyId)).pipe(
        map(data => new GetCompanyConfigFulfilledAction({ data })),
        catchError(error => of(new GetCompanyConfigRejectedAction({ error })))
      )
    ),
  );

export const getCompanyPreferencesEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetCompanyPreferences).pipe(
    exhaustMap(({ payload }: GetCompanyPreferencesAction) =>
      from(configService.getCompanyPreferences(payload.companyId)).pipe(
        map(data => new GetCompanyPreferencesFulfilledAction({ data })),
        catchError(error => of(new GetCompanyPreferencesRejectedAction({ error })))
      )
    ),
  );

export const getHoldingPreferencesEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetHoldingPreferences).pipe(
    exhaustMap(({ payload }: GetHoldingPreferencesAction) =>
      from(configService.getHoldingPreferences(payload.holdingName)).pipe(
        map(data => {
          return new GetHoldingPreferencesFulfilledAction({ data, onSuccess: payload.onSuccess });
        }),
        catchError(error => {
          payload.onError?.(error);
          return of(new GetHoldingPreferencesRejectedAction({ error }));
        })
      )
    ),
  );

export const getHoldingPreferencesFulfilledEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetHoldingPreferencesFulfilled).pipe(
    tap(({ payload }: GetHoldingPreferencesFulfilledAction) => {
      payload.onSuccess?.(payload.data);
    }),
    switchMap(() => of()),
  );

export const getHealthEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetHealth).pipe(
    exhaustMap(({ payload }: GetHealthAction) =>
      from(configService.health()).pipe(
        map(() => {
          payload?.onSuccess();
          return new GetHealthFulfilledAction();
        }),
        catchError(error => of(new GetHealthRejectedAction({ error })))
      )
    ),
  );

export const getTermsAndConditionsEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetTermsAndConditions).pipe(
    exhaustMap(() =>
      from(configService.getTerms()).pipe(
        map((data) => {
          return new GetTermsAndConditionsFulfilledAction({ data });
        }),
        catchError(error => of(new GetTermsAndConditionsRejectedAction({ error })))
      )
    ),
  );

export const spxIsDown$: Epic = action$ =>
  action$.ofType(ActionType.SpxIsDown).pipe(
    exhaustMap(({ payload }: ShowSpxIsDown) => {
      return of({ type: payload.oldAction.type, payload: {} });
    }),
  );

const epics = [
  getAppVersionsEpic$,
  getApiVersionsEpic$,
  getCalendarLinksEpic$,
  getCompanyConfigEpic$,
  getCompanyPreferencesEpic$,
  getHoldingPreferencesEpic$,
  getHoldingPreferencesFulfilledEpic$,
  getHealthEpic$,
  getTermsAndConditionsEpic$,
  spxIsDown$,
];

export default epics;
