import React from 'react';
import DocumentTitle from 'react-document-title';
import { Button, Modal } from 'react-ess-components';
import { toast } from 'react-toastify';
import { addWeeks, getWeek } from 'date-fns';

import { Availabilities } from '../../../../shared/components';
import { date, translations } from '../../utils';
import AvailabilitiesWeek from './components/availabilitiesWeek/AvailabilitiesWeek';

import './availabilities.scss';

class AvailabilitiesView extends Availabilities.AvailabilitiesBase {
  setOpenPopup = (date) => {
    this.setState({ openPopup: date });
  };
  onSuccess = () => {
    toast(translations.getLabel('lblChangesSavedSuccess'));
    this.props.history.goBack();
  };

  onCancel = () => {
    // Go back if nothing has changed
    if (!this.state.hasChanged) return this.onCancelSave();

    this.setState({
      showCancelModal: true,
    });
  };

  getTimeErrorMessage = () => {
    let msg = '';
    if (this.state.timeErrors.length > 0) {
      msg += translations.getLabel('errAvailabilityTimeError');
      msg += ' ';
      this.state.timeErrors.forEach(error => {
        msg += date.format(error.split('+')[1], 'dd-MM-yyyy');
        msg += ', ';
      });
      msg = msg.slice(0, -2);
    }
    return msg;
  }

  onCancelSave = () => {
    this.setState(
      {
        showCancelModal: false,
      },
      this.props.history.goBack
    );
  };

  closeModal = () => {
    this.setState({
      showCancelModal: false,
    });
  };

  renderWeek = (beginOfWeek, index) => {
    const parsedStartDate = date.parseDate(this.props.startDate);
    const parsedEndDate = date.parseDate(this.props.endDate);

    return (
      <AvailabilitiesWeek
        availabilities={this.state.availabilities}
        defaultTimeType={this.state.defaultTimeType}
        firstWeek={getWeek(parsedStartDate, { weekStartsOn: this.props.weekStartsOn, firstWeekContainsDate: 7 })}
        firstWeekDate={parsedStartDate}
        lastWeekDate={parsedEndDate}
        isLoading={this.state.isLoading}
        key={index}
        lastWeek={getWeek(parsedEndDate, { weekStartsOn: this.props.weekStartsOn, firstWeekContainsDate: 7 })}
        onAddShift={this.onAddShift}
        onChangeShift={this.onChangeShift}
        onCopyDay={this.onCopyDay}
        onCopyWeek={this.onCopyWeek}
        onRemoveShift={this.onRemoveShift}
        openPopup={this.state.openPopup}
        overlappingDates={this.state.overlappingDates}
        setDefaultTimeType={this.setDefaultTimeType}
        setHasTimeError={this.setHasTimeError}
        setOpenPopup={this.setOpenPopup}
        startDate={addWeeks(beginOfWeek, index)}
        timeTypes={this.props.timeTypes}
        validAvailabilityDates={this.state.validAvailabilityDates}
        weekStartsOn={this.props.weekStartsOn}
        inSchoolUntil={this.props.inSchoolUntil}
      />
    );
  };

  render() {
    return (
      <DocumentTitle title={translations.getLabel('titleAvailabilities')}>
        <div className="page fixed-width availabilities">
          <div className="header">
            <h2>{translations.getLabel('titleAvailabilities')}</h2>
            <p>{translations.getLabel('lblSelectAvailabilities')}</p>
          </div>
          <Modal
            open={this.state.showCancelModal}
            requestClose={this.closeModal}
            title={translations.getLabel('titleSaveChanges')}
            leftButtonProps={{
              onClick: this.onCancelSave,
              label: translations.getLabel('btnDontSave'),
            }}
            rightButtonProps={{
              onClick: this.onSave,
              label: translations.getLabel('save'),
              isLoading: this.state.isSaving,
            }}
          >
            <p className="modal-content">
              {translations.getLabel('lblSaveChanges')}
            </p>
          </Modal>
          {this.renderWeeks()}
          <footer>
            <div>
              {!!this.state.error && <p>{this.state.error}</p>}
              {this.hasTimeErrors() && <p>{this.getTimeErrorMessage()}</p>}
            </div>
            <div>
              <Button theme="inverse" onClick={this.onCancel}>
                {translations.getLabel('cancel')}
              </Button>
              <Button
                disabled={!this.state.hasChanged || this.hasTimeErrors()}
                isLoading={this.state.isSaving}
                onClick={this.onSave}
              >
                {translations.getLabel('save')}
              </Button>
            </div>
          </footer>
        </div>
      </DocumentTitle>
    );
  }
}

AvailabilitiesView.propTypes = Availabilities.AvailabilitiesBase.propTypes;
AvailabilitiesView.defaultProps = Availabilities.AvailabilitiesBase.defaultProps;

export default AvailabilitiesView;
