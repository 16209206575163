export enum ActionType {
  GetNotificationPreferences = 'GET_NOTIFICATION_PREFERENCES',
  GetNotificationPreferencesRejected = 'GET_NOTIFICATION_PREFERENCES_REJECTED',
  GetNotificationPreferencesFulfilled = 'GET_NOTIFICATION_PREFERENCES_FULFILLED',

  GetUser = 'GET_USER',
  GetUserRejected = 'GET_USER_REJECTED',
  GetUserFulfilled = 'GET_USER_FULFILLED',

  GetUserPeriods = 'GET_MY_PERIODS',
  GetUserPeriodsRejected = 'GET_MY_PERIODS_REJECTED',
  GetUserPeriodsFulfilled = 'GET_MY_PERIODS_FULFILLED',

  UpdateAvatar = 'UPDATE_AVATAR',
  UpdateAvatarRejected = 'UPDATE_AVATAR_REJECTED',
  UpdateAvatarFulfilled = 'UPDATE_AVATAR_FULFILLED',

  DeleteAvatar = 'DELETE_AVATAR',
  DeleteAvatarRejected = 'DELETE_AVATAR_REJECTED',
  DeleteAvatarFulfilled = 'DELETE_AVATAR_FULFILLED',

  UpdateNotificationPreferences = 'UPDATE_NOTIFICATION_PREFERENCES',
  UpdateNotificationPreferencesRejected = 'UPDATE_NOTIFICATION_PREFERENCES_REJECTED',
  UpdateNotificationPreferencesFulfilled = 'UPDATE_NOTIFICATION_PREFERENCES_FULFILLED',

  UpdateUser = 'UPDATE_USER',
  UpdateUserRejected = 'UPDATE_USER_REJECTED',
  UpdateUserFulfilled = 'UPDATE_USER_FULFILLED',

  ChangePassword = 'CHANGE_PASSWORD',
  ChangePasswordRejected = 'CHANGE_PASSWORD_REJECTED',
  ChangePasswordFulfilled = 'CHANGE_PASSWORD_FULFILLED',

  GetIcsUrl = 'GET_ICS_URL',
  GetIcsUrlRejected = 'GET_ICS_URL_REJECTED',
  GetIcsUrlFulfilled = 'GET_ICS_URL_FULFILLED',

  ClearPasswordError = 'CLEAR_PASSWORD_ERROR'
}

export default ActionType;
