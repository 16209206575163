import { produce } from 'immer';

import ActionType from './types';
import { INotification } from '../../models';
import { Actions } from './actions';
import { HttpMetadataPagingResponse } from '../../http/HttpMetadata';

export interface NotificationState {
  amountUnread: number;
  isNotificationsLoading: boolean;
  notifications: INotification[];
  hasServerError: boolean; // CHECKTHIS - not implemented
  totalCount: number;
  metadata: HttpMetadataPagingResponse;
}

export const initialState: NotificationState = {
  amountUnread: 0,
  isNotificationsLoading: false,
  notifications: [],
  hasServerError: false,
  totalCount: 0,
  metadata: null,
};

const notifications = produce((draft: NotificationState, action: Actions) => {
  switch (action.type) {
    case ActionType.GetNotifications:
      draft.isNotificationsLoading = true;
      break;

    case ActionType.GetNotificationsFulfilled:
      draft.hasServerError = false;
      draft.isNotificationsLoading = false;
      draft.notifications = action.payload.data;
      draft.totalCount = action.payload.meta.totalCount;
      draft.metadata = action.payload.meta;
      break;

    case ActionType.GetNotificationsRejected:
      draft.hasServerError = true;
      draft.isNotificationsLoading = false;
      draft.totalCount = 0;
      draft.metadata = null;
      break;

    case ActionType.GetUnreadCountFulfilled:
      draft.amountUnread = action.payload.amount;
      break;

    case ActionType.MarkAsReadFulfilled:
      const index = draft.notifications.findIndex(notification => notification.id === action.payload.id);
      draft.amountUnread = draft.amountUnread - 1;
      draft.notifications[index].read = true;
      break;

    case ActionType.LoadMoreNotifications:
      draft.isNotificationsLoading = true;
      break;

    case ActionType.LoadMoreNotificationsFulfilled:
      draft.hasServerError = false;
      draft.isNotificationsLoading = false;
      draft.notifications = draft.notifications.concat(action.payload.data);
      draft.totalCount = action.payload.meta.totalCount;
      draft.metadata = action.payload.meta;
      break;

    case ActionType.LoadMoreNotificationsRejected:
      draft.hasServerError = true;
      draft.isNotificationsLoading = false;
      draft.totalCount = 0;
      break;

    // No default case is needed, since produce returns by default the draft state
  };
}, initialState);

export default notifications;
