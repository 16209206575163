import React, { FC, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import classnames from 'classnames';
import { isSameMonth, isWeekend } from 'date-fns';

import { identifiers } from '../../../../constants';
import { IDayPlanning, IShift, TimeScale } from '../../../../models';
import { date, translations } from '../../../../utils';
import { OpenAvailabilitiesFunction, OpenPlanningFunction } from '../../schedule.models';
import MonthDayHeader from './MonthDayHeader';

const LIMIT_AMOUNT_OF_SHIFTS = 2;

interface Props {
  planning: IDayPlanning;
  selectedDate: Date;
  currentDate: Date;
  openPlanning: OpenPlanningFunction;
  openAvailabilities: OpenAvailabilitiesFunction;
  changeTimeScale: (timeScale: TimeScale, date: Date) => void;
  isLoading: boolean;
}

const MonthDay: FC<Props> = ({ currentDate, planning, selectedDate, openPlanning, openAvailabilities, changeTimeScale, isLoading }) => {
  const ref = useRef(null);

  const renderShifts = (shift: IShift, i: number) => {
    return (
      <div key={`shift${i}`} className="shift">
        <p>{shift.shiftName}</p>
        {date.format(shift.startDate, 'HH:mm')} - {date.format(shift.endDate, 'HH:mm')}
      </div>
    );
  };

  const goToDaySchedule = () => {
    changeTimeScale(TimeScale.Day, currentDate);
  };

  const onClick = () => {
    if (planning?.shifts?.length && isInScope()) {
      openPlanning(planning, ref.current);
    }
  };

  const isInScope = () => isSameMonth(currentDate, selectedDate);
  const className = classnames('day', { 'off-scope': !isInScope(), 'day-weekend': isWeekend(currentDate) });
  const classNameShifts = classnames('stripped-button shifts', { 'no-shifts': !planning?.shifts?.length });
  return (
    <div className={className}>
      <MonthDayHeader showAvailabilities={planning?.employeeId === identifiers.me} currentDate={currentDate} openAvailabilities={openAvailabilities} hasSwitch={planning?.shifts?.some(shift => shift.departmentSwitch)} />
      <button ref={ref} className={classNameShifts} onClick={onClick}>
        {isLoading
          ? <div className="shift"><Skeleton count={2} height={14} /></div>
          : <>
            {planning?.shifts?.slice(0, LIMIT_AMOUNT_OF_SHIFTS).map(renderShifts)}
            {planning?.shifts?.length > LIMIT_AMOUNT_OF_SHIFTS &&
              <div className="more">
                <button onClick={goToDaySchedule}>
                  + {translations.getLabel('btnMore', { amount: planning?.shifts?.length - LIMIT_AMOUNT_OF_SHIFTS })}
                </button>
              </div>
            }
          </>
        }
      </button>
    </div>
  );
};

export default MonthDay;
