import React from 'react';
import { FileUpload } from 'react-ess-components';

import { translations } from '../../../utils';

interface Props {
  file: string;
  setNewFile: (newFile: File) => void;
}

export default function NewsFiles({ file, setNewFile }: Props) {
  return (
    <div className="image-container">
      <div className="title-container">
        <h4>{translations.getLabel('lblAddPicture')}</h4><p>&nbsp;({translations.getLabel('lblOptional')})</p>
      </div>
      <FileUpload
        acceptFileTypes="image/png, image/jpeg"
        translationLabels={{
          lblBtn: translations.getLabel('btnChooseImage'),
          lblDrag: translations.getLabel('lblDragImage'),
          lblRemoveImage: translations.getLabel('lblRemoveImage'),
          lblTitle: translations.getLabel('lblAddPicture'),
        }}
        initialFile={typeof file === 'string' ? file : null}
        isModal={false}
        isLoading={false}
        handleUpload={setNewFile} />
      <p className="image-tooltip">{translations.getLabel('lblNewsItemImageFormat')}</p>
    </div>
  );
}
