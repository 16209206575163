import axios, { AxiosError, ResponseType, AxiosResponse } from 'axios';

import { cancelPreviousRequestIfneeded, setHttpClientAuthInterceptor, ITokenByRoute } from './HttpUtils';
import { HttpStatus } from './HttpStatus';
import { IServerError } from './HttpError';

type Params = Record<string, string | number | boolean>;
type Headers = Record<string, string>;

const unexpectedError: IServerError = {
  code: '',
  status: 0,
  meta: {},
  id: '',
  detail: '',
  title: '',
};

let authToken: string = null;
let apiRootUrl: string = null;
let apiSecret: string = null;
let tokensByRoutes: ITokenByRoute[] = [];
let appVersion: string = '';
let appPlatform: string = '';
const cacheControl: string = 'no-store header';

class HttpClient {
  static setHttpClientParams(rootUrl: string, version: string, platform: string, secret?: string): void {
    apiRootUrl = rootUrl;
    apiSecret = secret;
    appVersion = version;
    appPlatform = platform;
    setHttpClientAuthInterceptor();
  }

  static setAuthToken(accessToken: string): void {
    authToken = accessToken;
  }

  static getAuthToken(): string {
    return authToken;
  }

  static getUrl(route: string): string {
    if (route.indexOf('http://') === 0 || route.indexOf('https://') === 0 || route.indexOf('www.') === 0) {
      return route;
    }
    return `${apiRootUrl}${route}`;
  }

  static getUrlWithParams(route: string, params: Params): string {
    let url = HttpClient.getUrl(route);
    if (params) {
      for (const property in params) {
        if (params.hasOwnProperty(property) && ![null, undefined].includes(params[property])) {
          url = HttpClient.addQueryStringParameter(url, property, `${params[property]}`);
        }
      }
    }
    return url;
  }

  static addQueryStringParameter(uri: string, key: string, value: string): string {
    const regex = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
    const separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(regex)) {
      return uri.replace(regex, `$1${key}=${value}$2`);
    }
    return `${uri + separator + key}=${value}`;
  }

  static parseRequestPayload<T>(object: T): T {
    return Object.keys(object).reduce((acc: T, key: string) => ({ ...acc, [key]: object[key] === '' ? null : object[key] }), {} as T);
  }

  static getBasicHeaders(): Headers {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'app-version': appVersion,
      'app-platform': appPlatform,
      'cache-control': cacheControl,
    };
    if (apiSecret) {
      headers['api-secret'] = apiSecret;
    }
    if (authToken) {
      headers['Authorization'] = `Bearer ${authToken}`;
    }
    return headers;
  }

  static createApiError(error: AxiosError): IServerError {
    if (error.response) {
      const data: IServerError = error.response.data?.errors?.[0];
      if (!data) {
        return {
          ...unexpectedError,
          code: error.response.statusText,
          status: error.response.status
        };
      }
      return data;
    }

    return {
      ...unexpectedError,
      code: error.code || error.message,
      status: HttpStatus.InternalServerError,
    };
  }

  static async getRaw<T>(route: string, params: Params = {}, headers: Headers = {}, responseType: ResponseType = 'json'): Promise<AxiosResponse<T>> {
    try {
      const cancelToken = axios.CancelToken.source();
      tokensByRoutes = cancelPreviousRequestIfneeded(route, tokensByRoutes, cancelToken);
      return await axios.get<T>(this.getUrlWithParams(route, params), { headers: { ...this.getBasicHeaders(), ...headers }, cancelToken: cancelToken.token, withCredentials: true, responseType });
    } catch (error) {
      throw this.createApiError(error);
    }
  }

  static async get<T>(route: string, params: Params = {}, headers: Headers = {}, responseType: ResponseType = 'json'): Promise<T> {
    const result = await this.getRaw<T>(route, params, headers, responseType);
    return result.data;
  }

  static async put<T>(route: string, body: object = {}, headers: Headers = {}, params: Params = {}): Promise<T> {
    try {
      const result = await axios.put<T>(this.getUrlWithParams(route, params), body, { headers: { ...this.getBasicHeaders(), ...headers }, withCredentials: true });
      return result.data;
    } catch (error) {
      throw this.createApiError(error);
    }
  }

  static async patch<T>(route: string, body: object = {}, headers: Headers = {}): Promise<T> {
    try {
      const result = await axios.patch<T>(this.getUrl(route), body, { headers: { ...this.getBasicHeaders(), ...headers }, withCredentials: true });
      return result.data;
    } catch (error) {
      throw this.createApiError(error);
    }
  }

  static async post<T>(route: string, body: object = {}, headers: Headers = {}): Promise<T> {
    try {
      const result = await axios.post<T>(this.getUrl(route), body, { headers: { ...this.getBasicHeaders(), ...headers }, withCredentials: true });
      return result.data;
    } catch (error) {
      throw this.createApiError(error);
    }
  }

  static async delete<T>(route: string, headers: Headers = {}): Promise<T> {
    try {
      const result = await axios.delete(this.getUrl(route), { headers: { ...this.getBasicHeaders(), ...headers }, withCredentials: true });
      return result.data || true;
    } catch (error) {
      throw this.createApiError(error);
    }
  }
}

export default HttpClient;
