import { produce } from 'immer';

import ActionType from './types';
import { ICounterGroup, ICounter, ICounterOverviewData, ICounterOverviewConfiguration } from '../../models';
import { Actions } from './actions';

export interface CounterState {
  counters: ICounterGroup[];
  dashboardCounters: ICounter[];
  isLoading: boolean;
  isLoadingDashboard: boolean;
  isLoadingVacationCounter: boolean;
  hasServerError: boolean;
  vacationCounters: ICounter[];
  counterOverview: ICounterOverviewData[];
  isLoadingCounterOverview: boolean;
  counterOverviewConfiguration: ICounterOverviewConfiguration[];
  isLoadingCounterOverviewConfiguration: boolean;
}

export const initialState: CounterState = {
  counters: [],
  dashboardCounters: [],
  isLoading: false,
  isLoadingDashboard: false,
  isLoadingVacationCounter: false,
  hasServerError: false,
  vacationCounters: [],
  counterOverview: [],
  isLoadingCounterOverview: false,
  counterOverviewConfiguration: [],
  isLoadingCounterOverviewConfiguration: false,
};

const counters = produce((draft: CounterState, action: Actions) => {
  switch (action.type) {
    case ActionType.GetCounters:
      draft.isLoading = true;
      break;

    case ActionType.GetDashboardCounters:
      draft.isLoadingDashboard = true;
      break;

    case ActionType.GetCountersFulfilled:
      draft.counters = action.payload.data;
      draft.isLoading = false;
      break;

    case ActionType.GetDashboardCountersFulfilled:
      draft.dashboardCounters = action.payload.data;
      draft.isLoadingDashboard = false;
      break;

    case ActionType.GetCountersRejected:
      draft.isLoading = false;
      draft.hasServerError = true;
      break;

    case ActionType.GetDashboardCountersRejected:
      draft.isLoadingDashboard = false;
      draft.hasServerError = true;
      break;

    case ActionType.GetVacationCounters:
      draft.isLoadingVacationCounter = true;
      break;

    case ActionType.GetVacationCountersRejected:
      draft.isLoadingVacationCounter = false;
      break;

    case ActionType.GetVacationCountersFulfilled:
      draft.vacationCounters = action.payload.data;
      draft.isLoadingVacationCounter = false;
      break;

    case ActionType.GetCounterOverview:
      draft.isLoadingCounterOverview = true;
      break;

    case ActionType.GetCounterOverviewFulfilled:
      draft.counterOverview = action.payload.data;
      draft.isLoadingCounterOverview = false;
      break;

    case ActionType.GetCounterOverviewRejected:
      draft.counterOverview = [];
      draft.isLoadingCounterOverview = false;
      draft.hasServerError = true;
      break;

    case ActionType.GetCounterOverviewConfiguration:
      draft.isLoadingCounterOverviewConfiguration = true;
      break;

    case ActionType.GetCounterOverviewConfigurationFulfilled:
      draft.counterOverviewConfiguration = action.payload.data;
      draft.isLoadingCounterOverviewConfiguration = false;
      break;

    case ActionType.GetCounterOverviewConfigurationRejected:
      draft.isLoadingCounterOverviewConfiguration = false;
      break;

    // No default case is needed, since produce returns by default the draft state
  }
}, initialState);

export default counters;

