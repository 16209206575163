import { Action } from 'redux';

import ActionType from './types';
import { IAvailabilitiesDayList, ITimeType } from '../../models';
import { IServerError } from '../../http/HttpError';

export class GetAvailabilitiesAction implements Action<ActionType> {
  readonly type = ActionType.GetAvailabilities;
  constructor(public payload: { startDate: string, endDate: string, onSuccess?: (availabilities: IAvailabilitiesDayList[]) => void, onError?: (error: IServerError) => void }) { }
}

export class GetAvailabilitiesFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetAvailabilitiesFulfilled;
  constructor(public payload: { data: IAvailabilitiesDayList[], timeTypes: ITimeType[], totalCount: number }) { }
}

export class GetAvailabilitiesRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetAvailabilitiesRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class UpdateAvailabilitiesAction implements Action<ActionType> {
  readonly type = ActionType.PutAvailabilities;
  constructor(public payload: { availabilities: IAvailabilitiesDayList[], onError?: (error: IServerError) => void, onSuccess?: () => void }) { }
}

export class UpdateAvailabilitiesFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.PutAvailabilitiesFulfilled;
}

export class UpdateAvailabilitiesRejectedAction implements Action<ActionType> {
  readonly type = ActionType.PutAvailabilitiesRejected;
  constructor(public payload: { error: IServerError }) { }
}

export type Actions =
  | GetAvailabilitiesAction
  | GetAvailabilitiesFulfilledAction
  | GetAvailabilitiesRejectedAction
  | UpdateAvailabilitiesAction
  | UpdateAvailabilitiesFulfilledAction
  | UpdateAvailabilitiesRejectedAction;