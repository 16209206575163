import { Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { exhaustMap, map, catchError } from 'rxjs/operators';

import ActionType from './types';
import { countersService } from '../../services';
import { GetCountersFulfilledAction, GetCountersRejectedAction, GetDashboardCountersFulfilledAction, GetDashboardCountersRejectedAction, GetVacationCountersFulfilledAction, GetVacationCountersRejectedAction, GetDashboardCountersAction, GetVacationCountersAction, GetCounterOverviewAction, GetCounterOverviewFulfilledAction, GetCounterOverviewRejectedAction, GetCounterOverviewConfigurationAction, GetCounterOverviewConfigurationFulfilledAction, GetCounterOverviewConfigurationRejectedAction } from './actions';

export const getCountersEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetCounters).pipe(
    exhaustMap(() =>
      from(countersService.getCounterGroups()).pipe(
        map(data => new GetCountersFulfilledAction({ data })),
        catchError(error => of(new GetCountersRejectedAction({ error })))
      )
    ),
  );

export const getDashboardCountersEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetDashboardCounters).pipe(
    exhaustMap(({ payload }: GetDashboardCountersAction) =>
      from(countersService.getCounters('dashboard')).pipe(
        map(data => {
          payload?.onSuccess?.();
          return new GetDashboardCountersFulfilledAction({ data });
        }),
        catchError(error => {
          payload?.onError?.(error);
          return of(new GetDashboardCountersRejectedAction({ error }));
        })
      )
    ),
  );

export const getVacationCountersEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetVacationCounters).pipe(
    exhaustMap(({ payload }: GetVacationCountersAction) =>
      from(countersService.getCounters('vacation')).pipe(
        map(data => {
          payload?.onSuccess?.();
          return new GetVacationCountersFulfilledAction({ data });
        }),
        catchError(error => {
          payload?.onError?.(error);
          return of(new GetVacationCountersRejectedAction({ error }));
        })
      )
    ),
  );

export const getCounterOverviewEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetCounterOverview).pipe(
    exhaustMap(({ payload }: GetCounterOverviewAction) =>
      from(countersService.getCounterOverview(payload.id, payload.startDate, payload.endDate)).pipe(
        map(data => new GetCounterOverviewFulfilledAction(data)),
        catchError(error => of(new GetCounterOverviewRejectedAction({ error })))
      )
    ),
  );

export const getCounterOverviewConfigurationEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetCounterOverviewConfiguration).pipe(
    exhaustMap(() =>
      from(countersService.getCounterOverviewConfiguration()).pipe(
        map(data => new GetCounterOverviewConfigurationFulfilledAction({ data })),
        catchError(error => of(new GetCounterOverviewConfigurationRejectedAction({ error })))
      )
    ),
  );

const epics = [
  getCountersEpic$,
  getDashboardCountersEpic$,
  getVacationCountersEpic$,
  getCounterOverviewEpic$,
  getCounterOverviewConfigurationEpic$,
];

export default epics;
