import { produce } from 'immer';

import ActionType from './types';
import { IChatConversation, IChatParticipantList } from '../../models';
import { Actions } from './actions';

export interface ChatState {
  amountUnread: number;
  conversations: Record<string, IChatConversation>;
  count: number;
  fileStorageId: string;
  isConversationsLoading: boolean;
  isParticipantsLoading: boolean;
  participants: IChatParticipantList[];
  presignedUrl: string;
}

export const initialState: ChatState = {
  amountUnread: null,
  conversations: null,
  count: 0,
  fileStorageId: '',
  isConversationsLoading: false,
  isParticipantsLoading: false,
  participants: [],
  presignedUrl: '',
};

const chat = produce((draft: ChatState, action: Actions) => {
  switch (action.type) {
    case ActionType.GetConversations:
      draft.isConversationsLoading = true;
      break;

    case ActionType.GetConversationsFulfilled:
      const conversations = action.payload.data.reduce((accu, conversation) => {
        return { ...accu, [conversation.id]: conversation };
      }, {});

      draft.isConversationsLoading = false;
      draft.count = action.payload.count;
      draft.conversations = conversations;
      break;

    case ActionType.GetConversationsRejected:
      draft.isConversationsLoading = false;
      break;

    case ActionType.GetUnreadCountFulfilled:
      draft.amountUnread = action.payload.amount;
      break;

    case ActionType.MarkAsReadFulfilled: {
      const id = action.payload?.id;
      // isLoading check is needed in the unique case that we load notifications AND we mark a conversation as read
      if (id && !draft.isConversationsLoading) {
        const isRead = draft.conversations[id].read;
        // If the conversation was already read
        if (isRead) return;

        draft.conversations[id].read = true;
        draft.amountUnread = draft.amountUnread - 1;
      }
      break;
    }

    case ActionType.MarkAsUnread:
      const { id } = action.payload;
      if (!draft.conversations?.[id]) break;
      const isUnread = !draft.conversations[id].read;
      // If the conversation was already unread
      if (isUnread) break;

      draft.conversations[id].read = false;
      draft.amountUnread = draft.amountUnread + 1;
      break;

    case ActionType.GetParticipants:
      draft.isParticipantsLoading = true;
      break;

    case ActionType.GetParticipantsFulfilled:
      draft.participants = action.payload.data;
      break;

    case ActionType.GetParticipantsRejected:
      draft.isParticipantsLoading = false;
      break;

    case ActionType.GetPresignedUrlFulfilled:
      draft.presignedUrl = action.payload.uploadUrl;
      draft.fileStorageId = action.payload.storageId;
      break;

    case ActionType.AddMessageToConversation:
      if (action.payload.conversationId) {
        const { conversationId } = action.payload;
        const chats = draft.conversations[conversationId].messages || [];

        const messages = [...chats, action.payload.message];
        draft.conversations[conversationId].messages = messages;
      }
      break;

    // No default case is needed, since produce returns by default the draft state
  }
}, initialState);

export default chat;
