import produce from 'immer';
import { Actions } from './actions';
import ActionType from './types';
import { IShift, ITemplate, IDayShiftOffer, IShiftOffers, UpdateApplicantStatus, ShiftOfferApplicantStatus, IDayPlanning } from '../../models';
import { formatDateForSchedule } from '../../utils/schedule';
import { eachDayOfInterval, parseISO } from 'date-fns';

export interface ShiftOfferState {
  showShiftOfferModal: boolean;
  employeeId: string;
  date: string;
  templates: ITemplate[];
  isTemplatesLoading?: boolean;
  isCreateShiftOfferLoading?: boolean;
  isCreateTemplateLoading?: boolean;
  isShiftOffersLoading?: boolean;
  shiftOffers: IShiftOffers;
  isUpdateApplicantStatusLoading?: boolean;
  isLoading?: boolean;
  isDetailLoading: boolean;
  scheduleDetail: IDayPlanning[];
}

export const initialState: ShiftOfferState = {
  showShiftOfferModal: false,
  employeeId: '',
  date: '',
  templates: [],
  isTemplatesLoading: false,
  isCreateShiftOfferLoading: false,
  isShiftOffersLoading: false,
  shiftOffers: null,
  isLoading: false,
  isDetailLoading: false,
  scheduleDetail: [],
};

const shiftOffer = produce((draft: ShiftOfferState, action: Actions) => {
  switch (action.type) {
    case ActionType.ToggleShiftOfferModal:
      if (action.payload) {
        draft.employeeId = action.payload.employeeId;
        draft.date = action.payload.date;
      } else {
        draft.employeeId = '';
        draft.date = '';
      }
      draft.showShiftOfferModal = !draft.showShiftOfferModal;
      break;

    case ActionType.CreateTemplate:
      draft.isCreateTemplateLoading = true;
      break;

    case ActionType.CreateTemplateFulfilled:
      draft.isCreateTemplateLoading = false;
      break;

    case ActionType.CreateTemplateRejected:
      draft.isCreateTemplateLoading = false;
      break;

    case ActionType.GetTemplates:
      draft.isTemplatesLoading = true;
      draft.templates = [];
      break;

    case ActionType.GetTemplatesFulfilled:
      draft.isTemplatesLoading = false;
      draft.templates = action.payload.data;
      break;

    case ActionType.GetTemplatesRejected:
      draft.isTemplatesLoading = false;
      break;

    case ActionType.CreateShiftOffer:
      draft.isCreateShiftOfferLoading = true;
      break;

    case ActionType.CreateShiftOfferFulfilled:
      draft.isCreateShiftOfferLoading = false;
      break;

    case ActionType.CreateShiftOfferRejected:
      draft.isCreateShiftOfferLoading = false;
      break;

    case ActionType.GetShiftOffers:
      draft.isShiftOffersLoading = true;
      draft.isLoading = true;
      break;

    case ActionType.GetShiftOffersFulfilled:
      draft.isShiftOffersLoading = false;
      draft.isLoading = false;

      const tmpShiftOffers = { ...draft.shiftOffers };
      eachDayOfInterval({ start: parseISO(action.payload.startDate), end: parseISO(action.payload.endDate) }).forEach((date) => {
        tmpShiftOffers[formatDateForSchedule(date)] = [];
      });

      action.payload.data.forEach(item => {
        const { date } = item;
        const formattedDate = formatDateForSchedule(date);
        const index = tmpShiftOffers[formattedDate].findIndex((shiftOffer: IDayShiftOffer) => shiftOffer.id === item.id);
        if (index >= 0) tmpShiftOffers[formattedDate][index] = item;
        else tmpShiftOffers[formattedDate].push(item);
      });
      draft.shiftOffers = tmpShiftOffers;
      break;

    case ActionType.GetShiftOffersRejected:
      draft.isShiftOffersLoading = false;
      draft.isLoading = false;
      break;

    case ActionType.GetScheduleDetail:
      draft.isDetailLoading = true;
      break;

    case ActionType.GetScheduleDetailFulfilled:
      draft.isDetailLoading = false;
      draft.scheduleDetail = action.payload.data;
      break;

    case ActionType.GetScheduleDetailRejected:
      draft.isDetailLoading = false;
      break;

    case ActionType.UpdateShiftOfferApplicantStatus:
      draft.isUpdateApplicantStatusLoading = true;
      break;

    case ActionType.UpdateShiftOfferApplicantStatusFulfilled:
      draft.isUpdateApplicantStatusLoading = false;
      draft.shiftOffers[formatDateForSchedule(action.payload.date)].forEach((shiftOffer: IDayShiftOffer) => {
        if (shiftOffer.id === action.payload.id) {
          if (action.payload.type === UpdateApplicantStatus.Apply) {
            shiftOffer.applicantStatus = ShiftOfferApplicantStatus.Pending;
            shiftOffer.numberOfApplicants = shiftOffer.numberOfApplicants + 1;
          } else {
            shiftOffer.applicantStatus = null;
            shiftOffer.numberOfApplicants = shiftOffer.numberOfApplicants - 1;
          }
        }
      });
      break;

    case ActionType.UpdateShiftOfferApplicantStatusRejected:
      draft.isUpdateApplicantStatusLoading = false;
      break;
  }
}, initialState);

export default shiftOffer;