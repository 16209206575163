import NewsWidgetBase from './NewsWidget.base';
import { newsSelectors } from '../../redux';

export const mapStateToProps = (state) => ({
  newsItems: newsSelectors.getAllNews(state),
  isLoading: newsSelectors.isLoading(state),
});

export const mapDispatchToProps = dispatch => ({
  dispatch
});

export default NewsWidgetBase;
