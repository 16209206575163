import React, { PureComponent } from 'react';
import { Avatar,Modal } from 'react-ess-components';
import PropTypes from 'prop-types';

import { translations } from '../../../../utils';

import './chatParticipants.scss';

export default class ChatParticipants extends PureComponent {
  renderParticipant = (user) => {
    return (
      <div className="user" key={user.id}>
        <Avatar firstName={user.firstName} lastName={user.lastName} source={user.profilePictureUrl} />
        <p className="name">{`${user.firstName || ''} ${user.lastName || ''}`}</p>
      </div>
    );
  }

  render() {
    return (
      <div className="participant-modal">
        <Modal open={this.props.visible} title={translations.getLabel('lblInfoConversation')} requestClose={this.props.handleClose}>
          <div className="header">
            <h3>{`${translations.getLabel('lblParticipants')} (${this.props.participants.length}):`}</h3>
          </div>
          <div className="participants">
            {this.props.participants.map(this.renderParticipant)}
          </div>
        </Modal>
      </div>
    );
  }
}

const noop = () => { };

ChatParticipants.propTypes = {
  participants: PropTypes.array,
  handleClose: PropTypes.func,
  visible: PropTypes.bool,
};

ChatParticipants.defaultProps = {
  participants: [],
  handleClose: noop,
  visible: false,
};
