import { connect } from 'react-redux';

import { chatSelectors, userSelectors } from '../../../../redux';
import StartConversation from './StartConversation.view';

const mapStateToProps = (state) => ({
  participants: chatSelectors.getParticipants(state),
  hasRight: (right) => userSelectors.hasRight(state, right),
  user: userSelectors.getUser(state),
});

export default connect(mapStateToProps)(StartConversation);

