import Fingerprint2 from 'fingerprintjs2';

export * from '../../../shared/utils/timeRegistration';

type RequestIdleCallbackHandle = () => void;
type RequestIdleCallbackOptions = {
  timeout: number;
};
type RequestIdleCallbackDeadline = {
  readonly didTimeout: boolean;
  timeRemaining: (() => number);
};

declare global {
  interface Window {
    requestIdleCallback: ((
      callback: ((deadline: RequestIdleCallbackDeadline) => void),
      opts?: RequestIdleCallbackOptions,
    ) => RequestIdleCallbackHandle);
    cancelIdleCallback: ((handle: RequestIdleCallbackHandle) => void);
  }
}

const options = {
  excludes: {
    userAgent: true,
    screenResolution: true,
    availableScreenResolution: true,
    timezoneOffset: true,
    timezone: true,
    plugins: true,
    fonts: true,
    enumerateDevices: true,
  },
};

export const getDeviceId = (): Promise<string> => {
  return new Promise((resolve) => {
    if (window.requestIdleCallback) {
      window.requestIdleCallback(() => {
        Fingerprint2.get(options, generateId(resolve));
      });
    } else {
      setTimeout(() => {
        Fingerprint2.get(options, generateId(resolve));
      }, 500);
    }
  });
};

const generateId = (resolve: (id: string) => void) => (components: { key: string; value: string }[]) => {
  const values = components.map(component => component.value);
  const murmur = Fingerprint2.x64hash128(values.join(''), 31);
  resolve(murmur);
};
