import React, { FC } from 'react';
import { Button, Modal } from 'react-ess-components';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { useToggle } from '../../../../hooks';
import { requestsActions } from '../../../../redux';
import { translations } from '../../../../utils';

interface Props {
    requestId: string;
}

const CancelAction: FC<Props> = ({ requestId }) => {
    const dispatch = useDispatch();
    const [isPopupOpen, togglePopup] = useToggle(false);
    const [isLoading, toggleIsLoading] = useToggle(false);

    const cancelRequest = () => {
        toggleIsLoading();
        dispatch(new requestsActions.DeleteTransactionRequestAction(
            {
                id: requestId,
                isFromInbox: true,
                onSuccess: () => {
                    togglePopup();
                    toggleIsLoading();
                    toast(translations.getLabel('lblRequestCancelled'));
                },
                onError: (error) => {
                    toggleIsLoading();
                    toast.error(error.title);
                },
            }));
    };

    return (
        <>
            <Button onClick={togglePopup}>{translations.getLabel('btnCancelRequest')}</Button>
            <Modal
                title={translations.getLabel('lblCancelRequest')}
                requestClose={togglePopup}
                leftButtonProps={{
                    label: translations.getLabel('btnBack'),
                    onClick: togglePopup,
                }}
                rightButtonProps={{
                    label: translations.getLabel('btnCancelRequest'),
                    onClick: cancelRequest,
                    isLoading,
                }}
                open={isPopupOpen}>
                <p className="modal-confirm-content">{translations.getLabel('lblConfirmCancelRequest')}</p>
            </Modal>
        </>
    );
};

export default CancelAction;
