export interface IAttachment {
  name: string;
  storageId: string;
  extenstion?: string;
};

export interface IDocument {
  contentType?: string;
  createdAt: string;
  createdBy: string;
  departmentIds: string[]
  firstName: string;
  id: string;
  lastName: string;
  name: string;
  originalName?: string;
  type: DocumentType;
  updatedAt: string;
  url: string;
};

export interface IEditDocument {
  contentType?: string;
  departmentIds: string[];
  name: string;
  originalName?: string;
  url?: string;
  type: DocumentType;
};

export enum DocumentType {
  Pdf = 'PDF',
  Link = 'WEBPAGE',
  Doc = 'DOCUMENT',
  File = 'FILE',
};

export const DocumentExtensions = {
  Image: ['jpg', 'jpeg', 'png'],
  Pdf: ['pdf'],
  Doc: ['docx', 'txt', 'doc'],
};

export type IFileUploadFunction = (url: string, file: File, contentType?: string) => Promise<void>;

export interface IFileUploadRequestParams {
  file: File;
  uploadFunction: IFileUploadFunction;
}