import { createSelector } from 'reselect';
import { AppState } from '../rootReducer';
import { TimeRegistrationState } from './reducer';
import { isSameDay, parseISO } from 'date-fns';

const selectNode = (state: AppState) => state.timeRegistration;

export const timeRegistrations = createSelector(
  selectNode,
  (state: TimeRegistrationState) => state.timeRegistrations,
);

export const doubleTimeRegistrations = createSelector(
  selectNode,
  (state: TimeRegistrationState) => state.doubleTimeRegistrations,
);

export const activeTimeRegistrations = createSelector(
  selectNode,
  (state: TimeRegistrationState) => state.activeTimeRegistrations,
);

export const timeRegistrationsToday = createSelector(
  selectNode,
  (state: TimeRegistrationState) => state.timeRegistrations?.find(registration => isSameDay(parseISO(registration.date), new Date()))?.tickingList,
);

export const timeRegistrationTypes = createSelector(
  selectNode,
  (state: TimeRegistrationState) => state.timeRegistrationTypes,
);

export const validationTypes = createSelector(
  selectNode,
  (state: TimeRegistrationState) => state.validationTypes,
);

export const isLoading = createSelector(
  selectNode,
  (state: TimeRegistrationState) => state.isLoading,
);

export const errorId = createSelector(
  selectNode,
  (state: TimeRegistrationState) => state.errorId,
);

export const isDeviceRegisterLoading = createSelector(
  selectNode,
  (state: TimeRegistrationState) => state.isDeviceRegisterLoading,
);

export const isDeviceRegistered = createSelector(
  selectNode,
  (state: TimeRegistrationState) => state.isDeviceRegistered,
);
