import React, { FC, ReactElement } from 'react';
import { Button, Icon } from 'react-ess-components';
import { IconName } from 'react-ess-components/build/assets/icons';
import classnames from 'classnames';

import './Widget.scss';

type Props = {
  buttonIcon?: IconName;
  buttonLabel?: string;
  center?: boolean;
  children?: ReactElement;
  left?: boolean;
  onClickMore?: () => void;
  right?: boolean;
  title?: string;
}

const Widget: FC<Props> = (props) => {
  const className = classnames('widget-container', { left: props.left || props.center, right: props.right || props.center });

  return (
    <div className={className}>
      <div className="header">
        <h3>
          {props.title}
        </h3>
        {!!props.buttonLabel &&
          <Button onClick={props.onClickMore} theme="transparent">
            {!!props.buttonIcon && <Icon tag={props.buttonIcon} style={{ paddingRight: '1rem' }} />}
            {props.buttonLabel}
          </Button>
        }
      </div>
      {props.children}
    </div>
  );
};

const noop = () => null;
Widget.defaultProps = {
  buttonIcon: null,
  buttonLabel: '',
  center: false,
  children: null,
  left: false,
  onClickMore: noop,
  right: false,
  title: '',
};

export default Widget;
