import { Action } from 'redux';

import ActionType from './types';
import { IServerError } from '../../http/HttpError';
import { ITimeRegistration, ITimeRegistrationType, IActiveTimeRegistration, ValidationType, IValidator, ITimeRegistrationClock, IDoubleTimeRegistration } from '../../models';

export class GetTimeRegistrationsAction implements Action<ActionType> {
  readonly type = ActionType.GetTimeRegistrations;
  constructor(public payload?: { onSuccess?: () => void }) { }
}

export class GetTimeRegistrationsFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetTimeRegistrationsFulfilled;
  constructor(public payload: { data: ITimeRegistration[] }) { }
}

export class GetTimeRegistrationsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetTimeRegistrationsRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetDoubleTimeRegistrationsAction implements Action<ActionType> {
  readonly type = ActionType.GetDoubleTimeRegistrations;
  constructor(public payload?: { departmentId: string; startDate: string; endDate: string; onSuccess?: () => void }) { }
}

export class GetDoubleTimeRegistrationsFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetDoubleTimeRegistrationsFulfilled;
  constructor(public payload: { data: IDoubleTimeRegistration[] }) { }
}

export class GetDoubleTimeRegistrationsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetDoubleTimeRegistrationsRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetActiveTimeRegistrationsAndTypesAction implements Action<ActionType> {
  readonly type = ActionType.GetActiveTimeRegistrationsAndTypes;
}

export class GetActiveTimeRegistrationsAndTypesFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetActiveTimeRegistrationsAndTypesFulfilled;
  constructor(public payload: { registrations: IActiveTimeRegistration[], types: ITimeRegistrationType[], validationTypes: ValidationType[] }) { }
}

export class GetActiveTimeRegistrationsAndTypesRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetActiveTimeRegistrationsAndTypesRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class ClockAction implements Action<ActionType> {
  readonly type = ActionType.Clock;
  constructor(public payload: { id: string, type: string; typeId: string, validators?: IValidator[], clockAnyway?: boolean, onSuccess?: (result: ITimeRegistrationClock) => void, onError?: (error: IServerError) => void }) { };
}

export class ClockFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.ClockFulfilled;
  constructor(public payload: { data: ITimeRegistrationClock }) { };
}

export class ClockRejectedAction implements Action<ActionType> {
  readonly type = ActionType.ClockRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class CheckRegisteredDeviceAction implements Action<ActionType> {
  readonly type = ActionType.CheckRegisteredDevice;
  constructor(public payload: { deviceIdentifier: string }) { }
}

export class CheckRegisteredDeviceFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.CheckRegisteredDeviceFulfilled;
  constructor(public payload: boolean) { }
}

export class RegisteredDeviceAction implements Action<ActionType> {
  readonly type = ActionType.RegisteredDevice;
  constructor(public payload: { deviceIdentifier: string, deviceName: string, code: string, onSuccess?: () => void, onError?: (error: IServerError) => void }) { }
}

export class RegisteredDeviceFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.RegisteredDeviceFulfilled;
}

export class RegisteredDeviceRejectedAction implements Action<ActionType> {
  readonly type = ActionType.RegisteredDeviceRejected;
  constructor(public payload: { error: IServerError }) { }
}

export type Actions =
  | GetTimeRegistrationsAction
  | GetTimeRegistrationsFulfilledAction
  | GetTimeRegistrationsRejectedAction
  | GetActiveTimeRegistrationsAndTypesAction
  | GetActiveTimeRegistrationsAndTypesFulfilledAction
  | GetActiveTimeRegistrationsAndTypesRejectedAction
  | ClockAction
  | ClockFulfilledAction
  | ClockRejectedAction
  | CheckRegisteredDeviceAction
  | CheckRegisteredDeviceFulfilledAction
  | RegisteredDeviceAction
  | RegisteredDeviceFulfilledAction
  | RegisteredDeviceRejectedAction
  | GetDoubleTimeRegistrationsAction
  | GetDoubleTimeRegistrationsFulfilledAction
  | GetDoubleTimeRegistrationsRejectedAction;