import React, { FC } from 'react';
import { Avatar, Icon } from 'react-ess-components';
import { Link } from 'react-router-dom';

import { IEmployment } from '../../models';
import { translations } from '../../utils';

type Props = {
  avatar?: string;
  employments?: IEmployment[];
  firstName?: string;
  isAdmin?: boolean;
  lastName?: string;
  logout: () => void;
  switchEmployment: () => void;
}

const ProfileMenu: FC<Props> = ({ avatar, employments, firstName, isAdmin, lastName, switchEmployment, logout }) => {
  const getLink = () => {
    if (process.env.REACT_APP_ENV !== 'development') {
      return `//admin.${window.location.hostname.split(/\.(.+)/)[1]}`;
    }
    return window.location.origin.replace('3000', '3001');
  };

  return (
    <div>
      <ul>
        <li><Link to="/profile"><Avatar firstName={firstName} lastName={lastName} source={avatar} /> <span className="name">{translations.getLabel('btnMyProfile')}</span></Link></li>
        {employments && employments.length > 1 && <li><button onClick={switchEmployment}><Icon tag="EmploymentsIcon" title={translations.getLabel('lblEmployment')} /> <span className="name">{translations.getLabel('lblEmployment')}</span></button></li>}
        {isAdmin && <li><a href={getLink()} target="_blank" rel="noopener noreferrer"><Icon tag="SettingsIcon" title={translations.getLabel('btnAdmin')} /> <span className="name">{translations.getLabel('btnAdmin')}</span></a></li>}
        <li><button onClick={logout}><Icon tag="LogoutIcon" title={translations.getLabel('btnLogout')} /> <span className="name">{translations.getLabel('btnLogout')}</span> </button></li>
      </ul>
    </div>
  );
};

ProfileMenu.defaultProps = {
  avatar: '',
  employments: [],
  firstName: '',
  isAdmin: false,
  lastName: '',
};

export default ProfileMenu;
