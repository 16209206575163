import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { endOfDay, isAfter, isBefore, isSameMonth, parseISO } from 'date-fns';

import { ISelfRosteringShift, TimeScale } from '../../../../models';
import { useSelfRosterContext, useSelfRosterCounter, useSelfRosterSelectedShifts } from '../../../../modules';
import { configSelectors } from '../../../../redux';
import { date, scheduleUtils, selfRosterUtils, translations } from '../../../../utils';

type Props = {
  selectedShifts: ISelfRosteringShift[];
  selectedDate: Date;
}

const WeekCounter: FC<Props> = ({ selectedShifts, selectedDate }) => {
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const { selectedIteration } = useSelfRosterContext();
  const { startDate, endDate } = scheduleUtils.getBoundsOfRange(TimeScale.Week, selectedDate, false, weekStartsOn);
  const { data: originalSelectedShifts } = useSelfRosterSelectedShifts({ startDate: selectedIteration?.startDate, endDate: selectedIteration?.endDate, iterationId: selectedIteration?.id });
  const { data: counter, error, isLoading } = useSelfRosterCounter({
    startDate: isAfter(startDate, parseISO(selectedIteration?.startDate)) ? date.removeTimezone(startDate) : selectedIteration?.startDate,
    endDate: isBefore(endOfDay(endDate), parseISO(selectedIteration?.endDate)) ? date.removeTimezone(endOfDay(endDate)) : selectedIteration?.endDate,
  });

  if (!selectedDate) return null;

  const sameMonth = isSameMonth(startDate, endDate);

  const getCounter = () => {
    const roster = selfRosterUtils.getFilteredRoster(startDate, endDate, selectedShifts, originalSelectedShifts);
    const hours = roster.reduce((sum, day) => sum + day.hours, counter?.currentValue || 0);
    return `${hours.toString()}/${counter?.maxValue.toString()}`;
  };

  return (
    <>
      {!error && <div className="counter-container">
        <p >{translations.getLabel('lblSelfRosterScheduleCounter', { fromDate: date.format(startDate, sameMonth ? 'dd' : 'dd MMMM'), toDate: date.format(endDate, 'dd MMMM') })}</p>
        <div className="counter-number">
          {!isLoading ? <p><strong className="large">{getCounter()}</strong> {translations.getLabel('lblHour')}</p> : <Skeleton height={20} width={85} />}
        </div>
      </div>}
    </>
  );
};

export default WeekCounter;
