import { useQuery } from 'react-query';

import { HttpClient } from '../../http';
import { IServerError } from '../../http/HttpError';
import { ISelfRosteringIteration } from '../../models';
import { selfRosterQueryKeys } from './queryKeys';

const getIterations = async (): Promise<ISelfRosteringIteration[]> => {
  const result = await HttpClient.get<{ data: ISelfRosteringIteration[] }>('/v1/self-roster/overview');
  return result.data;
};

export const useSelfRosterIterations = () => {
  return useQuery<ISelfRosteringIteration[], IServerError>(selfRosterQueryKeys.getIterations(), getIterations);
};
