export interface INotification {
  createdAt: string;
  id: string;
  identifier: string;
  read: boolean;
  type: NotificationTrigger;
  value: string;
  departmentId: string;
}

export interface INotificationPreference {
  browser: boolean;
  email: boolean;
  mobile: boolean;
  trigger: NotificationTrigger
}

// TODO: These do not seem future proof. 
// I'd prever to see categories here and specific triggers below instead of types refering to the same specific triggers.
// e.g. shift offer & vacation request
export enum NotificationType {
  ChatMessage = 'NEW_CHAT_MESSAGE',
  Document = 'NEW_DOCUMENT',
  NewsArticle = 'NEW_NEWS_ARTICLE',
  OpenShift = 'NEW_OPEN_SHIFT',
  ShiftOffer = 'SHIFT_OFFER',
  ShiftSwap = 'SHIFT_SWAP',
  UserSchedule = 'UPDATE_USER_SCHEDULE',
  VacationRequest = 'VACATION_REQUEST',
  SelfRoster = 'SELF_ROSTER',
};

export enum NotificationTrigger {
  ChatMessageReceived = 'NEW_CHAT_MESSAGE',
  DocumentAdded = 'NEW_DOCUMENT',
  NewsArticleAdded = 'NEW_NEWS_ARTICLE',
  OpenShiftAdded = 'NEW_OPEN_SHIFT',
  UserScheduleUpdated = 'UPDATE_USER_SCHEDULE',
  UserScheduleUpdatedGeneric = 'UPDATE_USER_SCHEDULE_GENERIC',
  VacationRequest = 'VACATION_REQUEST',
  VacationRequestDepartment = 'VACATION_REQUEST_DEPARTMENT',
  ShiftOfferNew = 'SHIFT_OFFER_NEW',
  ShiftOfferFilled = 'SHIFT_OFFER_FILLED',
  ShiftOfferCancelled = 'SHIFT_OFFER_CANCELLED',
  ShiftOfferDepartment = 'SHIFT_OFFER_DEPARTMENT',
  ShiftOfferAcceptedByOtherManager = 'SHIFT_OFFER_ACCEPTED_BY_OTHER_MANAGER',
  ShiftSwapReviewed = 'SHIFT_SWAP_REVIEWED',
  ShiftSwapChosen = 'SHIFT_SWAP_CHOSEN',
  ShiftSwapRequested = 'SHIFT_SWAP_REQUESTED',
  ShiftSwapCancelled = 'SHIFT_SWAP_CANCELLED',
  ShiftSwapDepartment = 'SHIFT_SWAP_DEPARTMENT',
  TransactionApproved = 'TRANSACTION_APPROVED',
  TransactionDenied = 'TRANSACTION_DENIED',
  TransactionToApprove = 'TRANSACTION_TO_APPROVE',
  ShiftTimeReviewed = 'SHIFT_TIME_ADJUSTMENT_REVIEWED',
  ShiftTimeToApprove = 'SHIFT_TIME_ADJUSTMENT_REQUEST',
  SelfRosterNewIteration = 'SELF_ROSTER_NEW_ITERATION',
  SelfRosterNextIteration = 'SELF_ROSTER_NEXT_ITERATION',
  SelfRosterIterationChanged = 'SELF_ROSTER_ITERATION_CHANGED',
};

export interface IPushNotificationRegisterBody {
  previousToken?: string;
  currentToken: string;
  type: string;
};
