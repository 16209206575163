import { Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { exhaustMap, map, catchError } from 'rxjs/operators';

import ActionType from './types';
import { employeeService } from '../../services';
import { GetEmployeeAction, GetEmployeeFulfilledAction, GetEmployeeRejectedAction } from './actions';

export const getEmployeeEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetEmployee).pipe(
    exhaustMap(({ payload }: GetEmployeeAction) =>
      from(employeeService.getEmployee(payload.id)).pipe(
        map(data => new GetEmployeeFulfilledAction({ data })),
        catchError(error => of(new GetEmployeeRejectedAction({ error })))
      )
    ),
  );

const epics = [getEmployeeEpic$];

export default epics;
