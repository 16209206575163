import { ICounterValue } from './time';
import { IDayPlanning } from './schedule';

export enum CounterType {
  Time = 'time',
  Percentage = 'percentage',
  Days = 'days'
}

export enum PlanningType {
  TargetPlanning = 'TARGET_PLANNING',
  ActualPlanning = 'ACTUAL_PLANNING',
  TimeRegistration = 'TIME_REGISTRATION',
  TickingList = 'TICKING_LIST',
}

export interface ICounter {
  description: string;
  id: string;
  type: CounterType;
  value: ICounterValue;
  wcfId?: string;
}

export interface ICounterPlanning {
  id: string;
  type: string;
  showEmployees: boolean;
  counterId?: string;
}
export interface ICounterGroup {
  counters: ICounter[];
  group: string;
  groupId: string;
}

export interface ICounterOverview {
  data: ICounterOverviewData[];
}

export interface ITicking {
  time: string;
  label?: string;
  locationId?: string;
};

export interface ICounterOverviewData {
  day: string
  initialPlanning: IDayPlanning[];
  currentPlanning: IDayPlanning[];
  timeRegistrationHours: string;
  tickingList: ITicking[];
  counters: ICounter[];
}

export interface ICounterOverviewConfiguration {
  id: string;
  name: string;
  planning: ICounterPlanning[];
  counters: ICounterPlanning[];
}

export interface ICounterDropdownValue {
  value: string;
  startDate: string;
  endDate: string;
  label: string;
}