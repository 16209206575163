import { createSelector } from 'reselect';
import { compareDesc } from 'date-fns';

import { ChatState } from './reducer';
import { AppState } from '../rootReducer';

import * as  date from '../../utils/date';

const selectNode = (state: AppState) => state.chat;

export const amountUnread = createSelector(
  selectNode,
  (state: ChatState) => state.amountUnread,
);

export const getConversation = (state: AppState, id: string) => {
  const conversation = state.chat.conversations?.[id] || {};
  return conversation;
};

export const getConversationIds = createSelector(
  selectNode,
  (state: ChatState) => {
    const conversations = state.conversations || {};
    const jsConversations = Object.values(conversations);

    jsConversations.sort((a, b) => {
      if (!a.messages.length) return -1;
      if (!b.messages.length) return 1;
      const timeStampA = a.messages[a.messages.length - 1].createdAt;
      const timeStampB = b.messages[b.messages.length - 1].createdAt;
      return compareDesc(date.parseDate(timeStampA), date.parseDate(timeStampB));
    });
    const ids = jsConversations.map((conversations) => conversations.id);
    return ids;
  }
);

export const getFileStorageId = createSelector(
  selectNode,
  (state: ChatState) => state.fileStorageId,
);

export const getParticipants = createSelector(
  selectNode,
  (state: ChatState) => state.participants,
);

export const getPreSignedUrl = createSelector(
  selectNode,
  (state: ChatState) => state.presignedUrl,
);

export const getRoomIds = createSelector(
  selectNode,
  (state: ChatState) => Object.values(state.conversations || {}).map(conversation => conversation.roomId)
);

export const isConversationsLoading = createSelector(
  selectNode,
  (state: ChatState) => state.isConversationsLoading,
);

export const isParticipantsLoading = createSelector(
  selectNode,
  (state: ChatState) => state.isParticipantsLoading,
);

export const totalCount = createSelector(
  selectNode,
  (state: ChatState) => state.count,
);