import { useQuery } from 'react-query';

import { HttpClient } from '../../http';
import { IServerError } from '../../http/HttpError';
import { ISelfRosteringIteration } from '../../models';
import { selfRosterQueryKeys } from './queryKeys';

const getIterations = async (departmentId: string): Promise<ISelfRosteringIteration[]> => {
  const result = await HttpClient.get<{ data: ISelfRosteringIteration[] }>(`/v1/management/departments/${departmentId}/self-roster-iterations`);
  return result.data;
};

export const useDepartmentSelfRosterIterations = (departmentId: string) => {
  return useQuery<ISelfRosteringIteration[], IServerError>(selfRosterQueryKeys.getDepartmentIterations(departmentId), () => getIterations(departmentId), { enabled: !!departmentId });
};
