import React, { FC, useMemo, useState } from 'react';
import { FileUpload, IconButton, InputField, InputType, Modal } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { IServerError } from '../../../../../shared/http/HttpError';
import { AppState } from '../../../../../shared/redux/rootReducer';
import { libraryDropdownValues, maxFileSizesInMb } from '../../../constants';
import { useForm } from '../../../hooks';
import { DocumentType, IDocument, IEditDocument, UserRight } from '../../../models';
import { departmentsSelectors, documentsActions, documentsSelectors } from '../../../redux';
import { fileUploadUtils, translations } from '../../../utils';

interface Props {
  document?: IDocument;
  onClose: () => void;
}

const EditLibraryItem: FC<Props> = ({ document, onClose }) => {
  const dispatch = useDispatch();
  const departments = useSelector((state: AppState) => departmentsSelectors.getDepartmentsForRight(state, UserRight.Documents));
  const hasUploadError = useSelector(documentsSelectors.hasUploadError);
  const [attachment, setAttachment] = useState<File>(null);
  const { setForm, setFormAttribute, form } = useForm<IEditDocument>({
    contentType: document?.contentType || '',
    departmentIds: document?.departmentIds || [departments?.[0]?.value],
    name: document?.name || '',
    originalName: document?.originalName || '',
    url: document?.url || '',
    type: document?.type || DocumentType.File,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isEditing = !!document;

  const isValid = (): boolean => {
    // Check on required values
    if (!form.name || !form.departmentIds?.length || !(form.originalName || form.url)) return false;
    // If new document, stop here
    if (!isEditing) return true;
    // Check if there are changes in comparisson to the original document
    if (form.type === DocumentType.Link) {
      return (
        form.departmentIds[0] !== document.departmentIds[0]
        || form.url !== document.url
        || form.name !== document.name
      );
    } else {
      return (
        form.originalName
        && (
          form.departmentIds[0] !== document.departmentIds[0]
          || form.name !== document.name
          || form.originalName !== document.originalName
          || !!attachment
        )
      );
    }
  };

  const closeModal = () => {
    onClose();
  };

  const uploadDocument = () => {
    setIsLoading(true);

    const data: IEditDocument = { ...form };

    if (form.type === DocumentType.Link) {
      data.url = !/^https?:\/\//i.test(form.url) ? `https://${form.url}` : form.url;
      delete data.originalName;
      delete data.contentType;
    } else if (attachment) {
      data.contentType = attachment.type;
      data.originalName = form.originalName;
      delete data.url;
    }

    const payload = {
      data,
      fileUploadUtil: form.type === DocumentType.File && attachment && { file: attachment, uploadFunction: fileUploadUtils.uploadFileToPresignedUrl },
      onSuccess: () => {
        toast(translations.getLabel('lblChangesSavedSuccess'));
        closeModal();
      },
      onError: (error: IServerError) => {
        toast.error(error.detail || translations.getLabel('lblErrorFileUpload'));
      },
    };

    if (isEditing) {
      dispatch(new documentsActions.UpdateDocumentAction({ id: document.id, ...payload }));
    } else {
      dispatch(new documentsActions.UploadDocumentAction(payload));
    }
  };

  const changeType = (value: DocumentType) => {
    setForm({
      ...form,
      type: value,
      url: '',
      originalName: '',
    });
    setAttachment(null);
  };

  const setFile = (file: File) => {
    setAttachment(file);
    setFormAttribute(file.name, 'originalName');
  };

  const removeFile = () => {
    setAttachment(null);
    setFormAttribute('', 'originalName');
  };

  // translate the labels for the dropdown types, but memoise, so we don't do this on every render
  const libraryModeOptions = useMemo(() => libraryDropdownValues.map(type => ({ ...type, label: translations.getLabel(type.label) })), [libraryDropdownValues]);

  return (
    <Modal
      title={isEditing ? translations.getLabel('lblEditDocument') : translations.getLabel('lblAddToLibrary')}
      requestClose={closeModal}
      leftButtonProps={{
        label: translations.getLabel('cancel'),
        onClick: closeModal,
      }}
      rightButtonProps={{
        disabled: !isValid(),
        label: translations.getLabel(isEditing ? 'save' : 'add'),
        onClick: uploadDocument,
        isLoading: isLoading,
      }}
      open={true}
    >
      <div className="add-document-container">
        <InputField
          className="input-field"
          type={InputType.Select}
          options={libraryModeOptions}
          onChange={changeType}
          name="type"
          value={form.type}
          label={translations.getLabel('lblWhatTypeLibrary')}
        />
        <InputField
          className="input-field"
          type={InputType.Select}
          options={departments}
          onChange={(value) => setFormAttribute([value], 'departmentIds')}
          value={form.departmentIds[0]}
          label={translations.getLabel('lblAddForLibrary')}
        />
        <InputField
          className="input-field"
          type={InputType.Text}
          onChange={setFormAttribute}
          name="name"
          value={form.name}
          label={translations.getLabel('lblFileName')}
        />
        <>
          {form.type === DocumentType.Link ?
            <InputField
              className="input-field"
              type={InputType.Text}
              onChange={setFormAttribute}
              name="url"
              value={form.url}
              label={translations.getLabel('lblUrl')}
            /> :
            <>
              {!!form.originalName
                ? (
                  <div className="preview">
                    <p>{form.originalName}</p>
                    <IconButton onClick={removeFile} tag="TrashIcon" color="error" title={translations.getLabel('lblDelete')} />
                  </div>
                ) : (
                  <FileUpload
                    acceptFileTypes="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    handleUpload={setFile}
                    isError={hasUploadError}
                    isLoading={false}
                    isModal={false}
                    isVisible={true}
                    showPreview={false}
                    maxFileSize={fileUploadUtils.convertMBtoByte(maxFileSizesInMb.libraryDocuments)}
                    translationLabels={{
                      lblBtn: translations.getLabel('btnChooseAttachment'),
                      lblDrag: translations.getLabel('lblDragAttachment'),
                      lblError: translations.getLabel('lblErrorFileUpload'),
                      lblRemoveImage: translations.getLabel('lblRemoveAttachment'),
                      lblTitle: translations.getLabel('titleAddAttachment'),
                      lblErrorFileUploadSize: translations.getLabel('lblErrorFileUploadSize', { maxFileSize: maxFileSizesInMb.libraryDocuments }),
                    }}
                  />
                )}
            </>
          }
        </>
      </div>
    </Modal>
  );
};

export default EditLibraryItem;
