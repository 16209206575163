import { Action } from 'redux';

import ActionType from './types';
import { IServerError } from '../../http/HttpError';
import { IVacationRequest, IVacationType, IVacationRequestDTO, IVacationRequestResponse, RequestStatus, IRequestDepartment, IRequestCounts, IManagerVacationRequestDTO, IShiftOfferRequestResponse, IShiftOfferRequest, IShiftSwapRequestResponse, IShiftSwapRequest, RequestType, IShiftOfferResponse, ITransactionRequest, ITransactionRequestResponse, IShiftTimeRequestResponse, IShiftTimeRequest, IVacationRequestCounterParams, IRequestCounter } from '../../models';

export class CreateVacationRequestAction implements Action<ActionType> {
  readonly type = ActionType.CreateVacationRequest;
  constructor(public payload: { request: IVacationRequestDTO, onSuccess?: () => void, onError?: (error: IServerError) => void }) { }
}

export class CreateVacationRequestFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.CreateVacationRequestFulfilled;
  constructor(public payload: { startDate: string, endDate: string }) { }
}

export class CreateVacationRequestRejectedAction implements Action<ActionType> {
  readonly type = ActionType.CreateVacationRequestRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class DeleteVacationRequestAction implements Action<ActionType> {
  readonly type = ActionType.DeleteVacationRequest;
  constructor(public payload: { id: string, isFromInbox?: boolean; onSuccess?: () => void, onError?: (error: IServerError) => void }) { }
}

export class DeleteVacationRequestFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.DeleteVacationRequestFulfilled;
  constructor(public payload: { isFromInbox?: boolean, startDate: string, endDate: string, id: string }) { }
}

export class DeleteVacationRequestRejectedAction implements Action<ActionType> {
  readonly type = ActionType.DeleteVacationRequestRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetVacationRequestAction implements Action<ActionType> {
  readonly type = ActionType.GetVacationRequest;
  constructor(public payload: { id: string, onError?: (error: IServerError) => void }) { }
}

export class GetVacationRequestFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetVacationRequestFulfilled;
  constructor(public payload: { data: IVacationRequest }) { }
}

export class GetVacationRequestRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetVacationRequestRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetVacationRequestCountersAction implements Action<ActionType> {
  readonly type = ActionType.GetVacationRequestCounters;
  constructor(public payload: { params: IVacationRequestCounterParams, onError?: (error: IServerError) => void }) { }
}

export class GetVacationRequestCountersFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetVacationRequestCountersFulfilled;
  constructor(public payload: { data: IRequestCounter[] }) { }
}

export class GetVacationRequestCountersRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetVacationRequestCountersRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetVacationRequestsAction implements Action<ActionType> {
  readonly type = ActionType.GetVacationRequests;
  constructor(public payload: { offset: number; onSuccess?: (response: IVacationRequestResponse) => void }) { }
}

export class GetVacationRequestsFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetVacationRequestsFulfilled;
  constructor(public payload: IVacationRequestResponse) { }
}

export class GetVacationRequestsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetVacationRequestsRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetDepartmentVacationRequestsAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentVacationRequests;
  constructor(public payload: { departmentId: string; offset: number; status: RequestStatus; onSuccess?: (response: IVacationRequestResponse) => void }) { }
}

export class GetDepartmentVacationRequestsFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentVacationRequestsFulfilled;
  constructor(public payload: IVacationRequestResponse) { }
}

export class GetDepartmentVacationRequestsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentVacationRequestsRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetDepartmentVacationRequestAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentVacationRequest;
  constructor(public payload: { departmentId: string; id: string; onError?: (error: IServerError) => void }) { }
}

export class GetDepartmentVacationRequestFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentVacationRequestFulfilled;
  constructor(public payload: IVacationRequest) { }
}

export class GetDepartmentVacationRequestRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentVacationRequestRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetDepartmentShiftOffersAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentShiftOffers;
  constructor(public payload: { departmentId: string; offset: number; status: RequestStatus; onSuccess?: (response: IShiftOfferRequestResponse) => void }) { }
}

export class GetDepartmentShiftOffersFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentShiftOffersFulfilled;
  constructor(public payload: IShiftOfferRequestResponse) { }
}

export class GetDepartmentShiftOffersRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentShiftOffersRejected;
  constructor(public payload: { error: IServerError, isInitialLoad?: boolean }) { }
}

export class GetDepartmentShiftOfferAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentShiftOffer;
  constructor(public payload: { id: string; departmentId: string; onError?: (error: IServerError) => void }) { }
}

export class GetDepartmentShiftOfferFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentShiftOfferFulfilled;
  constructor(public payload: { data: IShiftOfferRequest }) { }
}

export class GetDepartmentShiftOfferRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentShiftOfferRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetDepartmentShiftSwapsAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentShiftSwaps;
  constructor(public payload: { departmentId: string; offset: number; status: RequestStatus; onSuccess?: (response: IShiftSwapRequestResponse) => void }) { }
}

export class GetDepartmentShiftSwapsFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentShiftSwapsFulfilled;
  constructor(public payload: IShiftSwapRequestResponse) { }
}

export class GetDepartmentShiftSwapsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentShiftSwapsRejected;
  constructor(public payload: { error: IServerError, isInitialLoad?: boolean }) { }
}

export class GetDepartmentShiftSwapAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentShiftSwap;
  constructor(public payload: { id: string; departmentId: string; onError?: (error: IServerError) => void }) { }
}

export class GetDepartmentShiftSwapFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentShiftSwapFulfilled;
  constructor(public payload: { data: IShiftSwapRequest }) { }
}

export class GetDepartmentShiftSwapRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentShiftSwapRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetVacationTypesAction implements Action<ActionType> {
  readonly type = ActionType.GetVacationTypes;
}

export class GetRequestCountsAction implements Action<ActionType> {
  readonly type = ActionType.GetRequestCounts;
}

export class GetRequestCountsFulFilledAction implements Action<ActionType> {
  readonly type = ActionType.GetRequestCountsFulfilled;
  constructor(public payload: { data: IRequestCounts }) { }
}

export class GetRequestCountsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetRequestCountsRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetVacationTypesFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetVacationTypesFulfilled;
  constructor(public payload: { data: IVacationType }) { }
}

export class GetVacationTypesRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetVacationTypesRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class OnReadRequestAction implements Action<ActionType> {
  readonly type = ActionType.ReadVacationRequest;
  constructor(public payload: { id: string, isDepartmentRequest?: boolean }) { }
}

export class OnReadRequestFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.ReadVacationRequestFulfilled
  constructor(public payload: { id: string, isDepartmentRequest?: boolean }) { }
}

export class OnReadRequestRejectedAction implements Action<ActionType> {
  readonly type = ActionType.ReadVacationRequestRejected
  constructor(public payload: { error: IServerError }) { }
}

export class ToggleVacationRequestModalAction implements Action<ActionType> {
  readonly type = ActionType.ToggleVacationRequestModal;
  constructor(public payload?: { date: Date }) { }
}

export class GetRequestDepartmentsAction implements Action<ActionType> {
  readonly type = ActionType.GetRequestDepartments;
  constructor(public payload: { status: RequestStatus, onSuccess?: (response: IRequestDepartment[]) => void }) { };
}

export class GetRequestDepartmentsFulFilledAction implements Action<ActionType> {
  readonly type = ActionType.GetRequestDepartmentsFulfilled;
  constructor(public payload: { data: IRequestDepartment[] }) { };
}

export class GetRequestDepartmentsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetRequestDepartmentsRejected;
  constructor(public payload: { error: RequestStatus }) { };
}

export class GetShiftOfferDepartmentsAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftOfferDepartments;
  constructor(public payload: { status: RequestStatus, onSuccess?: (response: IRequestDepartment[]) => void }) { };
}

export class GetShiftOfferDepartmentsFulFilledAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftOfferDepartmentsFulfilled;
  constructor(public payload: { data: IRequestDepartment[] }) { };
}

export class GetShiftOfferDepartmentsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftOfferDepartmentsRejected;
  constructor(public payload: { error: RequestStatus }) { };
}

export class FillInShiftOfferAction implements Action<ActionType> {
  readonly type = ActionType.FillInShiftOffer;
  constructor(public payload: { departmentId: string, shiftOfferId: string; employeeIds: string[]; status: RequestStatus, closeShiftOffer?: boolean; onSuccess?: (data) => void, onError?: (error: IServerError) => void }) { };
}

export class FillInShiftOfferFulFilledAction implements Action<ActionType> {
  readonly type = ActionType.FillInShiftOfferFulfilled;
  constructor(public payload: { status: RequestStatus, departmentId: string; shiftOfferId: string; ruleViolated: string[] }) { };
}

export class FillInShiftOfferRejectedAction implements Action<ActionType> {
  readonly type = ActionType.FillInShiftOfferRejected;
  constructor(public payload: { error: IServerError }) { };
}

export class CancelShiftOfferAction implements Action<ActionType> {
  readonly type = ActionType.CancelShiftOffer;
  constructor(public payload: { departmentId: string, shiftOfferId: string; status: RequestStatus, onSuccess?: () => void, onError?: (error: IServerError) => void }) { };
}

export class CancelShiftOfferFulFilledAction implements Action<ActionType> {
  readonly type = ActionType.CancelShiftOfferFulfilled;
  constructor(public payload: { status: RequestStatus, departmentId: string; shiftOfferId: string }) { };
}

export class CancelShiftOfferRejectedAction implements Action<ActionType> {
  readonly type = ActionType.CancelShiftOfferRejected;
  constructor(public payload: { error: IServerError }) { };
}

export class GetShiftSwapDepartmentsAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftSwapDepartments;
  constructor(public payload: { status: RequestStatus, onSuccess?: (response: IRequestDepartment[]) => void }) { };
}

export class GetShiftSwapDepartmentsFulFilledAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftSwapDepartmentsFulfilled;
  constructor(public payload: { data: IRequestDepartment[] }) { };
}

export class GetShiftSwapDepartmentsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftSwapDepartmentsRejected;
  constructor(public payload: { error: RequestStatus }) { };
}

export class ApproveShiftSwapAction implements Action<ActionType> {
  readonly type = ActionType.ApproveShiftSwap;
  constructor(public payload: { departmentId: string; shiftSwapId: string; note: string; onSuccess?: () => void, onError?: (error: IServerError) => void }) { };
}

export class ApproveShiftSwapFulFilledAction implements Action<ActionType> {
  readonly type = ActionType.ApproveShiftSwapFulfilled;
  constructor(public payload: { departmentId: string; shiftSwapId: string }) { };
}

export class ApproveShiftSwapRejectedAction implements Action<ActionType> {
  readonly type = ActionType.ApproveShiftSwapRejected;
  constructor(public payload: { error: IServerError }) { };
}

export class DeclineShiftSwapAction implements Action<ActionType> {
  readonly type = ActionType.DeclineShiftSwap;
  constructor(public payload: { departmentId: string, shiftSwapId: string; note: string; onSuccess?: () => void, onError?: (error: IServerError) => void }) { };
}

export class DeclineShiftSwapFulFilledAction implements Action<ActionType> {
  readonly type = ActionType.DeclineShiftSwapFulfilled;
  constructor(public payload: { departmentId: string; shiftSwapId: string }) { };
}

export class DeclineShiftSwapRejectedAction implements Action<ActionType> {
  readonly type = ActionType.DeclineShiftSwapRejected;
  constructor(public payload: { error: IServerError }) { };
}

export class UpdateVacationRequestAction implements Action<ActionType> {
  readonly type = ActionType.UpdateVacationRequest;
  constructor(public payload: { id: string; departmentId: string; data: IManagerVacationRequestDTO, onSuccess?: () => void, onError?: (error: IServerError) => void }) { };
}
export class UpdateVacationRequestFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.UpdateVacationRequestFulfilled;
  constructor(public payload: { departmentId: string; }) { };
}

export class UpdateVacationRequestRejectedAction implements Action<ActionType> {
  readonly type = ActionType.UpdateVacationRequestRejected;
  constructor(public payload: { error: IServerError }) { };
}

export class SetRequestFilterAction implements Action<ActionType> {
  readonly type = ActionType.SetRequestFilter;
  constructor(public payload: { filter: RequestStatus, type: RequestType }) { };
}

export class SetActiveInboxAction implements Action<ActionType> {
  readonly type = ActionType.SetActiveInbox;
  constructor(public payload: { type: RequestType }) { };
}

export class SetActiveDepartmentAction implements Action<ActionType> {
  readonly type = ActionType.SetActiveDepartment;
  constructor(public payload: { departmentId: string }) { };
}

export class GetDepartmentTransactionsAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentTransactions;
  constructor(public payload: { departmentId: string; offset: number; status: RequestStatus; onSuccess?: (response: ITransactionRequestResponse) => void }) { }
}


export class GetDepartmentShiftTimesAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentShiftTimes;
  constructor(public payload: { departmentId: string; offset: number; status: RequestStatus; onSuccess?: (response: IShiftTimeRequestResponse) => void }) { }
}

export class GetDepartmentTransactionsFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentTransactionsFulfilled;
  constructor(public payload: ITransactionRequestResponse) { }
}

export class GetDepartmentTransactionsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentTransactionsRejected;
  constructor(public payload: { error: IServerError, isInitialLoad?: boolean }) { }
}

export class GetTransactionDepartmentsAction implements Action<ActionType> {
  readonly type = ActionType.GetTransactionDepartments;
  constructor(public payload: { status: RequestStatus, onSuccess?: (response: IRequestDepartment[]) => void }) { };
}

export class GetDepartmentShiftTimesFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentShiftTimesFulfilled;
  constructor(public payload: IShiftTimeRequestResponse) { }
}

export class GetDepartmentShiftTimesRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentShiftTimesRejected;
  constructor(public payload: { error: IServerError, isInitialLoad?: boolean }) { }
}

export class GetShiftTimeDepartmentsAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftTimeDepartments;
  constructor(public payload: { status: RequestStatus, onSuccess?: (response: IRequestDepartment[]) => void }) { };
}

export class GetTransactionDepartmentsFulFilledAction implements Action<ActionType> {
  readonly type = ActionType.GetTransactionDepartmentsFulfilled;
  constructor(public payload: { data: IRequestDepartment[] }) { };
}

export class GetTransactionDepartmentsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetTransactionDepartmentsRejected;
  constructor(public payload: { error: RequestStatus }) { };
}

export class GetShiftTimeDepartmentsFulFilledAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftTimeDepartmentsFulfilled;
  constructor(public payload: { data: IRequestDepartment[] }) { };
}

export class GetShiftTimeDepartmentsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftTimeDepartmentsRejected;
  constructor(public payload: { error: RequestStatus }) { };
}

export class GetDepartmentTransactionAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentTransaction;
  constructor(public payload: { id: string; departmentId: string; onError?: (error: IServerError) => void }) { }
}

export class GetDepartmentShiftTimeAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentShiftTime;
  constructor(public payload: { id: string; departmentId: string; onError?: (error: IServerError) => void }) { }
}

export class GetDepartmentTransactionFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentTransactionFulfilled;
  constructor(public payload: { data: ITransactionRequest }) { }
}

export class GetDepartmentTransactionRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentTransactionRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class ApproveTransactionAction implements Action<ActionType> {
  readonly type = ActionType.ApproveTransaction;
  constructor(public payload: { departmentId: string; transactionId: string; reviewerNote: string; onSuccess?: () => void, onError?: (error: IServerError) => void }) { };
}

export class ApproveTransactionFulFilledAction implements Action<ActionType> {
  readonly type = ActionType.ApproveTransactionFulfilled;
  constructor(public payload: { departmentId: string; transactionId: string }) { };
}

export class ApproveTransactionRejectedAction implements Action<ActionType> {
  readonly type = ActionType.ApproveTransactionRejected;
  constructor(public payload: { error: IServerError }) { };
}

export class DeclineTransactionFulFilledAction implements Action<ActionType> {
  readonly type = ActionType.DeclineTransactionFulfilled;
  constructor(public payload: { departmentId: string; transactionId: string }) { };
}

export class GetDepartmentShiftTimeFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentShiftTimeFulfilled;
  constructor(public payload: { data: IShiftTimeRequest }) { }
}

export class GetDepartmentShiftTimeRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentShiftTimeRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class ApproveShiftTimeAction implements Action<ActionType> {
  readonly type = ActionType.ApproveShiftTime;
  constructor(public payload: { departmentId: string; shiftTimeId: string; reviewerNote: string; onSuccess?: () => void, onError?: (error: IServerError) => void }) { };
}

export class ApproveShiftTimeFulFilledAction implements Action<ActionType> {
  readonly type = ActionType.ApproveShiftTimeFulfilled;
  constructor(public payload: { departmentId: string; shiftTimeId: string }) { };
}

export class ApproveShiftTimeRejectedAction implements Action<ActionType> {
  readonly type = ActionType.ApproveShiftTimeRejected;
  constructor(public payload: { error: IServerError }) { };
}

export class DeclineTransactionAction implements Action<ActionType> {
  readonly type = ActionType.DeclineTransaction;
  constructor(public payload: { departmentId: string, transactionId: string; reviewerNote: string; onSuccess?: () => void, onError?: (error: IServerError) => void }) { };
}

export class DeclineShiftTimeAction implements Action<ActionType> {
  readonly type = ActionType.DeclineShiftTime;
  constructor(public payload: { departmentId: string, shiftTimeId: string; reviewerNote: string; onSuccess?: () => void, onError?: (error: IServerError) => void }) { };
}

export class DeclineShiftTimeFulFilledAction implements Action<ActionType> {
  readonly type = ActionType.DeclineShiftTimeFulfilled;
  constructor(public payload: { departmentId: string; shiftTimeId: string }) { };
}

export class DeclineShiftTimeRejectedAction implements Action<ActionType> {
  readonly type = ActionType.DeclineShiftTimeRejected;
  constructor(public payload: { error: IServerError }) { };
}

export class DeclineFulFilledAction implements Action<ActionType> {
  readonly type = ActionType.DeclineTransactionFulfilled;
  constructor(public payload: { departmentId: string; transactionId: string }) { };
}

export class DeclineTransactionRejectedAction implements Action<ActionType> {
  readonly type = ActionType.DeclineTransactionRejected;
  constructor(public payload: { error: IServerError }) { };
}

export class DeleteTransactionRequestAction implements Action<ActionType> {
  readonly type = ActionType.DeleteTransactionRequest;
  constructor(public payload: { id: string, isFromInbox?: boolean; onSuccess?: () => void, onError?: (error: IServerError) => void }) { }
}

export class DeleteTransactionRequestFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.DeleteTransactionRequestFulfilled;
  constructor(public payload: { isFromInbox?: boolean, id: string }) { }
}

export class DeleteTransactionRequestRejectedAction implements Action<ActionType> {
  readonly type = ActionType.DeleteTransactionRequestRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetTransactionRequestAction implements Action<ActionType> {
  readonly type = ActionType.GetTransactionRequest;
  constructor(public payload: { id: string, onError?: (error: IServerError) => void }) { }
}

export class GetTransactionRequestFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetTransactionRequestFulfilled;
  constructor(public payload: { data: ITransactionRequest }) { }
}

export class GetTransactionRequestsAction implements Action<ActionType> {
  readonly type = ActionType.GetTransactionRequests;
  constructor(public payload: { offset: number; onSuccess?: (response: ITransactionRequestResponse) => void }) { }
}

export class GetTransactionRequestRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetVacationRequestRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetTransactionRequestsFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetTransactionRequestsFulfilled;
  constructor(public payload: ITransactionRequestResponse) { }
}

export class GetTransactionRequestsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetTransactionRequestsRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class OnReadTransactionRequestAction implements Action<ActionType> {
  readonly type = ActionType.ReadTransactionRequest;
  constructor(public payload: { id: string, isDepartmentRequest?: boolean }) { }
}

export class OnReadTransactionRequestFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.ReadTransactionRequestFulfilled
  constructor(public payload: { id: string, isDepartmentRequest?: boolean }) { }
}

export class DeleteShiftTimeRequestFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.DeleteShiftTimeRequestFulfilled;
  constructor(public payload: { isFromInbox?: boolean, id: string }) { }
}

export class DeleteShiftTimeRequestAction implements Action<ActionType> {
  readonly type = ActionType.DeleteShiftTimeRequest;
  constructor(public payload: { id: string, isFromInbox?: boolean; onSuccess?: () => void, onError?: (error: IServerError) => void }) { }
}

export class DeleteShiftTimeRequestRejectedAction implements Action<ActionType> {
  readonly type = ActionType.DeleteShiftTimeRequestRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetShiftTimeRequestAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftTimeRequest;
  constructor(public payload: { id: string, onError?: (error: IServerError) => void }) { }
}

export class GetShiftTimeRequestFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftTimeRequestFulfilled;
  constructor(public payload: { data: IShiftTimeRequest }) { }
}

export class GetShiftTimeRequestsAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftTimeRequests;
  constructor(public payload: { offset: number; onSuccess?: (response: IShiftTimeRequestResponse) => void }) { }
}

export class GetShiftTimeRequestRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetVacationRequestRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetShiftTimeRequestsFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftTimeRequestsFulfilled;
  constructor(public payload: IShiftTimeRequestResponse) { }
}

export class GetShiftTimeRequestsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftTimeRequestsRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class OnReadShiftTimeRequestAction implements Action<ActionType> {
  readonly type = ActionType.ReadShiftTimeRequest;
  constructor(public payload: { id: string, isDepartmentRequest?: boolean }) { }
}

export class OnReadShiftTimeRequestFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.ReadShiftTimeRequestFulfilled
  constructor(public payload: { id: string, isDepartmentRequest?: boolean }) { }
}

export type Actions =
  | ApproveShiftSwapAction
  | ApproveShiftSwapFulFilledAction
  | ApproveShiftSwapRejectedAction
  | ApproveShiftTimeAction
  | ApproveShiftTimeFulFilledAction
  | ApproveShiftTimeRejectedAction
  | ApproveTransactionAction
  | ApproveTransactionFulFilledAction
  | ApproveTransactionRejectedAction
  | CancelShiftOfferAction
  | CancelShiftOfferFulFilledAction
  | CancelShiftOfferRejectedAction
  | CreateVacationRequestAction
  | CreateVacationRequestFulfilledAction
  | CreateVacationRequestRejectedAction
  | DeclineShiftSwapAction
  | DeclineShiftSwapFulFilledAction
  | DeclineShiftSwapRejectedAction
  | DeclineShiftTimeAction
  | DeclineShiftTimeFulFilledAction
  | DeclineShiftTimeRejectedAction
  | DeclineTransactionAction
  | DeclineTransactionFulFilledAction
  | DeclineTransactionRejectedAction
  | DeleteTransactionRequestAction
  | DeleteTransactionRequestFulfilledAction
  | DeleteTransactionRequestRejectedAction
  | DeleteVacationRequestAction
  | DeleteVacationRequestFulfilledAction
  | DeleteVacationRequestRejectedAction
  | FillInShiftOfferAction
  | FillInShiftOfferFulFilledAction
  | FillInShiftOfferRejectedAction
  | GetDepartmentShiftOfferAction
  | GetDepartmentShiftOfferFulfilledAction
  | GetDepartmentShiftOfferRejectedAction
  | GetDepartmentShiftOffersAction
  | GetDepartmentShiftOffersFulfilledAction
  | GetDepartmentShiftOffersRejectedAction
  | GetDepartmentShiftSwapAction
  | GetDepartmentShiftSwapFulfilledAction
  | GetDepartmentShiftSwapRejectedAction
  | GetDepartmentShiftSwapsAction
  | GetDepartmentShiftSwapsFulfilledAction
  | GetDepartmentShiftSwapsRejectedAction
  | GetDepartmentShiftTimesAction
  | GetDepartmentTransactionAction
  | GetDepartmentTransactionFulfilledAction
  | GetDepartmentTransactionRejectedAction
  | GetDepartmentTransactionsAction
  | GetDepartmentTransactionsFulfilledAction
  | GetDepartmentTransactionsRejectedAction
  | GetDepartmentVacationRequestsAction
  | GetDepartmentVacationRequestsFulfilledAction
  | GetDepartmentVacationRequestsRejectedAction
  | GetRequestCountsAction
  | GetRequestCountsFulFilledAction
  | GetRequestCountsRejectedAction
  | GetRequestDepartmentsAction
  | GetRequestDepartmentsFulFilledAction
  | GetRequestDepartmentsRejectedAction
  | GetShiftOfferDepartmentsAction
  | GetShiftOfferDepartmentsFulFilledAction
  | GetShiftOfferDepartmentsRejectedAction
  | GetShiftSwapDepartmentsAction
  | GetShiftSwapDepartmentsFulFilledAction
  | GetShiftSwapDepartmentsRejectedAction
  | GetShiftTimeDepartmentsAction
  | GetTransactionDepartmentsAction
  | GetTransactionDepartmentsFulFilledAction
  | GetTransactionDepartmentsRejectedAction
  | GetTransactionRequestAction
  | GetTransactionRequestFulfilledAction
  | GetTransactionRequestRejectedAction
  | GetTransactionRequestsAction
  | GetTransactionRequestsFulfilledAction
  | GetTransactionRequestsRejectedAction
  | GetVacationRequestAction
  | GetVacationRequestFulfilledAction
  | GetVacationRequestRejectedAction
  | GetVacationRequestsAction
  | GetVacationRequestsFulfilledAction
  | GetVacationRequestsRejectedAction
  | GetVacationTypesAction
  | GetVacationTypesFulfilledAction
  | GetVacationTypesRejectedAction
  | OnReadRequestAction
  | OnReadRequestFulfilledAction
  | OnReadRequestRejectedAction
  | OnReadTransactionRequestAction
  | OnReadTransactionRequestFulfilledAction
  | SetActiveDepartmentAction
  | SetActiveInboxAction
  | SetRequestFilterAction
  | ToggleVacationRequestModalAction
  | UpdateVacationRequestAction
  | UpdateVacationRequestFulfilledAction
  | UpdateVacationRequestRejectedAction
  | DeleteShiftTimeRequestAction
  | DeleteShiftTimeRequestFulfilledAction
  | DeleteShiftTimeRequestRejectedAction
  | GetDepartmentShiftTimeAction
  | GetDepartmentShiftTimeFulfilledAction
  | GetDepartmentShiftTimeRejectedAction
  | GetDepartmentShiftTimesFulfilledAction
  | GetDepartmentShiftTimesRejectedAction
  | GetShiftTimeDepartmentsFulFilledAction
  | GetShiftTimeDepartmentsRejectedAction
  | GetShiftTimeRequestAction
  | GetShiftTimeRequestFulfilledAction
  | GetShiftTimeRequestRejectedAction
  | GetShiftTimeRequestsAction
  | GetShiftTimeRequestsFulfilledAction
  | GetShiftTimeRequestsRejectedAction
  | OnReadShiftTimeRequestAction
  | OnReadShiftTimeRequestFulfilledAction
  | GetDepartmentVacationRequestAction
  | GetDepartmentVacationRequestFulfilledAction
  | GetVacationRequestCountersAction
  | GetVacationRequestCountersFulfilledAction
  | GetVacationRequestCountersRejectedAction
  | GetDepartmentVacationRequestRejectedAction;

