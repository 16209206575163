import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { format } from 'date-fns';

import { AppState } from '../../../../shared/redux/rootReducer';
import CollapseToggle from '../../components/collapseToggle/CollapseToggle';
import { identifiers } from '../../constants';
import { IDateRange, TimeScale } from '../../models';
import { availabilitiesActions, configSelectors, scheduleActions, scheduleSelectors } from '../../redux';
import { scheduleUtils } from '../../utils';
import DayRoster from './components/daySchedule/DayRoster';
import SubRoster from './components/weekSchedule/SubRoster';
import WeekRoster from './components/weekSchedule/WeekRoster';

import './dayWeekSchedule.scss';

interface Props {
  selectedDate: Date;
  mainUserId?: string;
  showAvailabilities: boolean;
  showRequests?: boolean;
  searchEnabled?: boolean;
  timeScale: TimeScale;
  highlightRange?: IDateRange;
  highlightDates?: string[];
  departmentId?: string;
  hideShiftOffers?: boolean;
  secondaryUserId?: string;
  secondaryHighlightDates?: string[];
}

const DayWeekSchedule: FC<Props> = ({ selectedDate, mainUserId, secondaryUserId, secondaryHighlightDates, timeScale, showAvailabilities, showRequests, searchEnabled, highlightRange, highlightDates, departmentId, hideShiftOffers }) => {
  const dispatch = useDispatch();
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  // For a week or a day, the users will be the same, so we can fetch them for one day to loop over them.
  const usersToday = useSelector((state: AppState) => scheduleSelectors.getUsersForDay(state, mainUserId, departmentId, selectedDate));
  const isDepartmentSearchLoading = useSelector(scheduleSelectors.isDepartmentSearchLoading);
  const searchValue = useSelector(scheduleSelectors.getSearchValue);
  const [collapsedIds, setCollapseId] = useState<string[]>(usersToday);
  const companyConfig = useSelector(configSelectors.getCompanyConfig);
  const hideShiftSwaps = !companyConfig?.modules.shiftSwap;

  useEffect(() => {
    const openedUserId = window.location.hash.replace('#', '');
    if (openedUserId) document.getElementById(openedUserId)?.scrollIntoView(); // eslint-disable-line
    setCollapseId(usersToday.filter(id => id !== openedUserId));
  }, [usersToday.length]);

  // Get new data on change of date
  useEffect(() => {
    const { startDate, endDate } = scheduleUtils.getBoundsOfRange(timeScale, selectedDate, false, weekStartsOn);
    const formattedStartDate = format(startDate, 'yyyy-MM-dd');
    const formattedEndDate = format(endDate, 'yyyy-MM-dd');
    if (!departmentId) {
      dispatch(new scheduleActions.GetPlanningAction({ startDate: formattedStartDate, endDate: formattedEndDate }));
      dispatch(new availabilitiesActions.GetAvailabilitiesAction({ startDate: formattedStartDate, endDate: formattedEndDate }));
      if(!companyConfig?.enableSpxcVacationRequests) dispatch(new scheduleActions.GetUserPlanningExternalVacationAction({ startDate: formattedStartDate, endDate: formattedEndDate }));
    }

    if (!searchValue) dispatch(new scheduleActions.GetDepartmentPlanningAction({ startDate: formattedStartDate, endDate: formattedEndDate, departmentId }));
    else dispatch(new scheduleActions.SearchDepartmentPlanningAction({ startDate: formattedStartDate, endDate: formattedEndDate, searchValue }));
  }, [selectedDate, timeScale, searchValue, companyConfig?.enableSpxcVacationRequests]);

  const onCollapse = (userId: string) => {
    if (collapsedIds.includes(userId)) setCollapseId(collapsedIds.filter(id => id !== userId));
    else setCollapseId([...collapsedIds, userId]);
  };

  const toggleAllSchedules = (shouldCollapse: boolean) => {
    if (shouldCollapse) setCollapseId(usersToday);
    else setCollapseId([]);
  };

  const RosterComponent = timeScale === TimeScale.Week ? WeekRoster : DayRoster;
  const className = classnames({ 'day-schedule': timeScale !== TimeScale.Week, 'week-schedule': timeScale === TimeScale.Week, 'no-availabilities': !showAvailabilities, 'no-shift-offers': hideShiftOffers, 'no-requests': !showRequests });
  const isSearchingOrNoResults = isDepartmentSearchLoading || (!usersToday.length && searchValue);

  return (
    <div className={className}>
      <RosterComponent
        selectedDate={selectedDate}
        isMyRoster={mainUserId === identifiers.me}
        userId={mainUserId}
        isCollapsed={collapsedIds.includes(mainUserId)}
        onCollapse={onCollapse}
        hasSearch={false}
        ignoreSearch={true}
        highlightRange={highlightRange}
        highlightDates={highlightDates}
        departmentId={departmentId}
        hideActions={!!secondaryUserId}
      />
      {
        timeScale === TimeScale.Week &&
        <SubRoster hideShiftSwaps={hideShiftSwaps} hideShiftOffers={hideShiftOffers || !companyConfig?.modules.shiftOffer} selectedDate={selectedDate} departmentId={departmentId} />
      }
      {(usersToday.length > 0 || isSearchingOrNoResults) && <CollapseToggle isCollapsed={collapsedIds.filter(id => id !== mainUserId).length === usersToday.length} onClick={toggleAllSchedules} />}
      <section className="colleague-rosters">
        {isSearchingOrNoResults && <RosterComponent selectedDate={selectedDate} hasNoSearchResults={!usersToday.length && !!searchValue && !isDepartmentSearchLoading} isCollapsed onCollapse={onCollapse} userId={'123'} hasSearch={true} />}
        {secondaryUserId &&
          <RosterComponent
            selectedDate={selectedDate}
            userId={secondaryUserId}
            isCollapsed={collapsedIds.includes(secondaryUserId)}
            onCollapse={onCollapse}
            hasSearch={false}
            ignoreSearch={true}
            highlightRange={highlightRange}
            highlightDates={secondaryHighlightDates}
            departmentId={departmentId}
            hideActions={!!secondaryUserId}
          />
        }
        {!secondaryUserId && usersToday.map((userId: string, i: number) => {
          const extraRosterProps: { forcePrevNextDay?: boolean; hideActions?: boolean } = {};
          if (timeScale === TimeScale.Day) extraRosterProps.forcePrevNextDay = true;
          if (timeScale === TimeScale.Week) extraRosterProps.hideActions = hideShiftOffers;
          return <RosterComponent
            key={userId}
            selectedDate={selectedDate}
            userId={userId}
            isCollapsed={collapsedIds.includes(userId)}
            onCollapse={onCollapse}
            hasSearch={searchEnabled && i === 0}
            highlightRange={highlightRange}
            highlightDates={highlightDates}
            departmentId={departmentId}
            {...extraRosterProps}
          />;
        })}
      </section>
    </div>
  );
};

DayWeekSchedule.defaultProps = {
  mainUserId: identifiers.me,
  searchEnabled: false,
};

export default DayWeekSchedule;
