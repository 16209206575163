import jwtDecode from 'jwt-decode';

import { IHoldingPreferences, IStorage } from '../models';

export const NONCE_KEY = 'nonce_sso';
export const TOKEN_KEY = 'id_token';

interface JwtToken {
  sub: string;
  iss: string;
  tokenName: string;
  nonce: string;
  aud: string;
  azp: string;
  auth_time: number;
  realm: string;
  exp: number;
  tokenType: string;
  iat: number;
};

export const generateRedirectUrl = async (holdingPreferences: IHoldingPreferences, redirectUri: string, isApp: boolean, storage: IStorage, crypto: Crypto): Promise<string> => {
  // Generate unique and random string to check on rerurn from SSO env
  const nonce = createRandomString(crypto);
  await storage.setItem(NONCE_KEY, nonce);
  // Compose the url
  let url = holdingPreferences.ssoEndpoint;
  url += `?client_id=${holdingPreferences[`ssoClientId${isApp ? 'App' : 'Web'}`]}`;
  url += `&response_type=${TOKEN_KEY}`;
  url += `&redirect_uri=${encodeURIComponent(redirectUri)}`;
  url += `&nonce=${nonce}`;
  return url;
};

export const isValidResponse = async (token: string, storage: IStorage): Promise<boolean> => {
  if (!token) return false;
  try {
    const jwt = jwtDecode<JwtToken>(token);
    const nonce = await storage.getItem(NONCE_KEY);
    if (jwt.nonce !== nonce) {
      return false;
    }
    await storage.removeItem(NONCE_KEY);
    return true;
  } catch (err) {
    return false;
  }
};

export const createRandomString = (crypto: Crypto): string => {
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_~.';
  let random = '';
  const randomValues: number[] = Array.from(
    crypto.getRandomValues(new Uint8Array(43))
  );
  randomValues.forEach(value => (random += charset[value % charset.length]));
  return random;
};

export const getToken = (rawHash: string): string => {
  const hash = decodeURIComponent(rawHash);
  const cleanHash = hash.substr(1);
  const urlParts = cleanHash.split('&');
  const tokenPart = urlParts.find(part => part.startsWith(`${TOKEN_KEY}=`));
  const token = tokenPart?.replace(`${TOKEN_KEY}=`, '');
  return token || '';
};
