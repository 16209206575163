export enum ActionType {
  ToggleShiftSwapModal = 'TOGGLE_SHIFT_SWAP_MODAL',

  CreateShiftSwapRequest = 'CREATE_SHIFT_SWAP_REQUEST',
  CreateShiftSwapRequestRejected = 'CREATE_SHIFT_SWAP_REQUEST_REJECTED',
  CreateShiftSwapRequestFulfilled = 'CREATE_SHIFT_SWAP_REQUEST_FULFILLED',

  GetShiftSwaps = 'GET_SHIFT_SWAPS',
  GetShiftSwapsRejected = 'GET_SHIFT_SWAPS_REJECTED',
  GetShiftSwapsFulfilled = 'GET_SHIFT_SWAPS_FULFILLED',

  CancelShiftSwapRequest = 'CANCEL_SHIFT_SWAP_REQUEST',
  CancelShiftSwapRequestRejected = 'CANCEL_SHIFT_SWAP_REQUEST_REJECTED',
  CancelShiftSwapRequestFulfilled = 'CANCEL_SHIFT_SWAP_REQUEST_FULFILLED',

  UpdateShiftSwapApplicantStatus = 'UPDATE_SHIFT_SWAP_APPLICANT_STATUS',
  UpdateShiftSwapApplicantStatusRejected = 'UPDATE_SHIFT_SWAP_APPLICANT_STATUS_REJECTED',
  UpdateShiftSwapApplicantStatusFulfilled = 'UPDATE_SHIFT_SWAP_APPLICANT_STATUS_FULFILLED',

  ApplyToShiftSwapWithCounter = 'APPLY_TO_SHIFT_SWAP_WITH_COUNTER',
  ApplyToShiftSwapWithCounterRejected = 'APPLY_TO_SHIFT_SWAP_WITH_COUNTER_REJECTED',
  ApplyToShiftSwapWithCounterFulfilled = 'APPLY_TO_SHIFT_SWAP_WITH_COUNTER_FULFILLED',

  RequestShiftSwap = 'REQUEST_SHIFT_SWAP',
  RequestShiftSwapRejected = 'REQUEST_SHIFT_SWAP_REJECTED',
  RequestShiftSwapFulfilled = 'REQUEST_SHIFT_SWAP_FULFILLED',
}

export default ActionType;