import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { INotification } from '../../models';
import { notificationsActions, notificationsSelectors, userSelectors } from '../../redux';
import { date, translations } from '../../utils';
import NotificationItem from '../shared/notifications/notificationItem/NotificationItem';

const MAX_NOTIFICATIONS_TO_SHOW = 10;
const noop = () => null;

const NotificationsMenu = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(notificationsSelectors.notifications);
  const isLoading = useSelector(notificationsSelectors.isNotificationsLoading);
  const amountUnread = useSelector(notificationsSelectors.amountUnread);
  const departmentId = useSelector(userSelectors.getDepartmentId);

  useEffect(() => {
    if (!notifications.length && !isLoading) {
      dispatch(new notificationsActions.GetNotificationsAction());
    }
  }, []);

  const renderNotification = (notification: INotification) => <NotificationItem
    isSameDepartment={notification.departmentId === null || departmentId === notification.departmentId}
    markAsRead={() => dispatch(new notificationsActions.MarkAsReadAction({ id: notification.id }))}
    key={notification.id}
    {...notification}
    format={date.format}
    getLabel={translations.getLabel} />;
  const renderNotificationLoading = (i: number) => <NotificationItem markAsRead={noop} key={i} isLoading={true} format={date.format} getLabel={translations.getLabel} />;

  return (
    <div className="notifications-container">
      <h3 className="notifications-title">{translations.getLabel('lblNotifications')} <span className="badge">({amountUnread})</span></h3>
      {isLoading && !notifications.length
        ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(renderNotificationLoading)
        : notifications.slice(0, MAX_NOTIFICATIONS_TO_SHOW).map(renderNotification)}
    </div>
  );
};

export default NotificationsMenu;
