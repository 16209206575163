import { produce } from 'immer';

import ActionType from './types';
import { ICalenderLinks, ICompanyConfig, ICompanyPreferences, IHoldingPreferences } from '../../models';
import { Actions } from './actions';

export interface ConfigState {
  build: string;
  calendarLinks: ICalenderLinks;
  companyConfig: ICompanyConfig;
  companyPreferences: ICompanyPreferences;
  holding: IHoldingPreferences;
  isCalendarLinksLoading: boolean;
  latestVersion: string;
  minVersion: string;
  hasHealthError: boolean;
  hasHoldingError: boolean;
  isSpxDown: boolean;
  termsAndConditionsUrl: string;
  version: string;
  enableShiftTimeAdjustments: boolean;
}

export const initialState: ConfigState = {
  build: '',
  calendarLinks: null,
  companyConfig: null,
  companyPreferences: null,
  holding: null,
  isCalendarLinksLoading: false,
  latestVersion: '',
  minVersion: '',
  hasHealthError: false,
  hasHoldingError: false,
  isSpxDown: false,
  termsAndConditionsUrl: '',
  version: '',
  enableShiftTimeAdjustments: false,
};


const config = produce((draft: ConfigState, action: Actions) => {
  switch (action.type) {
    case ActionType.GetCalendarLinks:
      draft.isCalendarLinksLoading = true;
      break;

    case ActionType.GetCalendarLinksFulfilled:
      draft.isCalendarLinksLoading = false;
      draft.calendarLinks = action.payload.data;
      break;

    case ActionType.GetCalendarLinksRejected:
      draft.isCalendarLinksLoading = false;
      break;

    case ActionType.GetHoldingPreferences:
      draft.hasHoldingError = false;
      break;

    case ActionType.GetHoldingPreferencesFulfilled:
      draft.holding = action.payload.data;
      break;

    case ActionType.GetCompanyConfigFulfilled:
      draft.companyConfig = action.payload.data;
      break;

    case ActionType.GetCompanyPreferencesFulfilled:
      draft.companyPreferences = action.payload.data;
      break;

    case ActionType.GetApiVersionFulfilled:
      draft.build = action.payload.build;
      draft.version = action.payload.version;
      break;

    case ActionType.GetAppVersionFulfilled:
      draft.latestVersion = action.payload.latestVersion;
      draft.minVersion = action.payload.minVersion;
      break;

    case ActionType.GetHoldingPreferencesRejected:
      draft.hasHoldingError = true;
      break;

    case ActionType.GetHealth:
      draft.hasHealthError = false;
      break;

    case ActionType.GetHealthRejected:
      draft.hasHealthError = true;
      break;

    case ActionType.ResetFatalErrors:
      draft.hasHealthError = false;
      draft.hasHoldingError = false;
      break;

    case ActionType.SpxIsDown:
      draft.isSpxDown = true;
      break;

    case ActionType.GetTermsAndConditionsFulfilled:
      draft.termsAndConditionsUrl = action.payload.data.downloadUrl;

    // No default case is needed, since produce returns by default the draft state
  }
}, initialState);

export default config;
