import React, { useEffect, useState } from 'react';
import { Button, Icon, InputField, InputType, Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { ISelfRosteringIteration, UserRight } from '../../../../models';
import { useAbsolutePeriod, useDepartmentSelfRosterIterations, useSaveSelfRosterIterations } from '../../../../modules';
import { departmentsActions, departmentsSelectors, userSelectors } from '../../../../redux';
import { date, translations } from '../../../../utils';
import SelfRosterEditIteration from './SelfRosterEditIteration';

import './createSelfRosterIterationsModal.scss';

interface Props {
  isOpen: boolean;
  toggleModal(): void;
}

const emptyIteration: ISelfRosteringIteration = {
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
  iteration: 1,
  id: null,
};

const CreateSelfRosterIterationsModal: React.FC<Props> = ({ isOpen, toggleModal }) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.getUser);
  const departments = useSelector((state: AppState) => departmentsSelectors.getDepartmentsForRight(state, UserRight.SelfRoster));
  const [departmentId, setDepartmentId] = useState<string>(null);
  const { data: departmentIterations, isLoading } = useDepartmentSelfRosterIterations(departmentId);
  const { data: scheduleVisibleUntil, error: errorScheduleVisibleUntil, isLoading: isLoadingScheduleVisibleUntil } = useAbsolutePeriod(departmentId);
  const [iterations, setIterations] = useState<ISelfRosteringIteration[]>([]);
  const { isLoading: isSaveLoading, mutate: saveSelfRosterIterations } = useSaveSelfRosterIterations(departmentId, {
    onSuccess: () => {
      toggleModal();
      toast.success(translations.getLabel('lblSelfRosterIterationsSuccess'));
    },
    onError: (error) => {
      toast.error(error.title);
    },
  });

  useEffect(() => {
    if (isOpen) {
      if (departmentIterations) setIterations(departmentIterations);
      dispatch(new departmentsActions.GetDepartmentsAction({ type: UserRight.SelfRoster }));
    } else {
      setIterations([]);
      setDefaultDepartmentId(true);
    }
  }, [isOpen]);

  useEffect(() => {
    setDefaultDepartmentId();
  }, [departments, departmentId]);

  useEffect(() => {
    if (departmentIterations) {
      setIterations(departmentIterations);
    }
  }, [departmentIterations]);

  const setDefaultDepartmentId = (ignoreDepartmentAlreadySet = false) => {
    if (departments.length > 0 && (ignoreDepartmentAlreadySet || !departmentId)) {
      if (departments.some(department => department.value === user.departmentId)) {
        setDepartmentId(user.departmentId);
      } else {
        setDepartmentId(departments[0].value);
      }
    }
  };

  const isDisabled = (): boolean => {
    return false;
  };

  const onSave = () => {
    saveSelfRosterIterations(iterations);
  };

  const onRemoveIteration = (iterationId: string) => {
    setIterations(iterations.filter(iteration => iteration.id !== iterationId));
  };

  const onEditIteration = (editedIteration: ISelfRosteringIteration) => {
    setIterations(iterations.map(iteration => iteration.id === editedIteration.id ? editedIteration : iteration));
  };

  return (
    <Modal
      className="create-self-roster-rounds"
      requestClose={toggleModal}
      open={isOpen}
      rightButtonProps={{ isLoading: isSaveLoading, label: translations.getLabel('save'), onClick: onSave, disabled: isDisabled() }}
      leftButtonProps={{ label: translations.getLabel('cancel'), onClick: toggleModal }}
      title={translations.getLabel('titleSetSelfRosterPeriods')}
    >
      <InputField
        emptyIcon
        type={InputType.Select}
        options={departments}
        value={departmentId || ''}
        onChange={setDepartmentId}
        label={translations.getLabel('lblDepartment')}
        placeholder={translations.getLabel('lblPlaceholderDepartmentSelect')}
      />
      {!errorScheduleVisibleUntil && !!scheduleVisibleUntil && <p className="absolute-period">{isLoadingScheduleVisibleUntil ? <Skeleton height={14} /> : translations.getLabel('lblAbsolutePeriodSchedule', { endDate: date.format(scheduleVisibleUntil?.endDate, 'dd/MM/yyyy') })}</p>}
      {isLoading ? <Skeleton height={158} /> : iterations.map((iteration) => <SelfRosterEditIteration key={iteration.id} iteration={iteration} onRemove={onRemoveIteration} onChange={onEditIteration} />)}
      <Button theme="transparent" onClick={() => setIterations([...iterations, { ...emptyIteration, id: uuidv4() }])}><Icon tag="PlusSquareIcon" color="primary" /> {translations.getLabel('btnAddIteration')}</Button>
      <div className="hr" />
    </Modal>
  );
};

export default CreateSelfRosterIterationsModal;
