import { Action } from 'redux';

import ActionType from './types';
import { userService } from '../../services';
import { IUser, INotificationPreference, IPeriodDetail, IEditUser } from '../../models';
import { IServerError } from '../../http/HttpError';

export class GetUserAction implements Action<ActionType> {
  readonly type = ActionType.GetUser;
  constructor(public payload?: { onSuccess?: (user: IUser) => void, onError?: (error: IServerError) => void }) { }
}

export class GetUserFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetUserFulfilled;
  constructor(public payload: { data: IUser }) { }
}

export class GetUserRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetUserRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class UpdateUserAction implements Action<ActionType> {
  readonly type = ActionType.UpdateUser;
  constructor(public payload: { user: IEditUser, onError?: (error: IServerError) => void }) { }
}

export class UpdateUserFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.UpdateUserFulfilled;
}

export class UpdateUserRejectedAction implements Action<ActionType> {
  readonly type = ActionType.UpdateUserRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetUserPeriodsAction implements Action<ActionType> {
  readonly type = ActionType.GetUserPeriods;
}

export class GetUserPeriodsFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetUserPeriodsFulfilled;
  constructor(public payload: { data: IPeriodDetail[] }) { }
}

export class GetUserPeriodsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetUserPeriodsRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetIcsUrlAction implements Action<ActionType> {
  readonly type = ActionType.GetIcsUrl;
}

export class GetIcsUrlFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetIcsUrlFulfilled;
  constructor(public payload: { url: string }) { }
}

export class GetIcsUrlRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetIcsUrlRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetNotificationPreferencesAction implements Action<ActionType> {
  readonly type = ActionType.GetNotificationPreferences;
}

export class GetNotificationPreferencesFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetNotificationPreferencesFulfilled;
  constructor(public payload: { data: INotificationPreference[] }) { }
}

export class GetNotificationPreferencesRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetNotificationPreferencesRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class UpdateNotificationPreferencesAction implements Action<ActionType> {
  readonly type = ActionType.UpdateNotificationPreferences;
  constructor(public payload: { preferences: INotificationPreference[], onError?: (error: IServerError) => void }) { }
}

export class UpdateNotificationPreferencesFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.UpdateNotificationPreferencesFulfilled;
  constructor(public payload: { data: INotificationPreference[] }) { }
}

export class UpdateNotificationPreferencesRejectedAction implements Action<ActionType> {
  readonly type = ActionType.UpdateNotificationPreferencesRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class UpdateAvatarAction implements Action<ActionType> {
  readonly type = ActionType.UpdateAvatar;
  constructor(public payload: { file: File }) { }
}

export class UpdateAvatarFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.UpdateAvatarFulfilled;
  constructor(public payload: { url: string }) { }
}

export class UpdateAvatarRejectedAction implements Action<ActionType> {
  readonly type = ActionType.UpdateAvatarRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class DeleteAvatarAction implements Action<ActionType> {
  readonly type = ActionType.DeleteAvatar;
  constructor(public payload?: { onSuccess?: () => void }) { }
}

export class DeleteAvatarFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.DeleteAvatarFulfilled;
}

export class DeleteAvatarRejectedAction implements Action<ActionType> {
  readonly type = ActionType.DeleteAvatarRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class ChangePasswordAction implements Action<ActionType> {
  readonly type = ActionType.ChangePassword;
  constructor(public payload: { currentPassword: string, newPassword: string, onSuccess?: () => void, onError?: (error: IServerError) => void }) { }
}

export class ChangePasswordFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.ChangePasswordFulfilled;
}

export class ChangePasswordRejectedAction implements Action<ActionType> {
  readonly type = ActionType.ChangePasswordRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class ClearPasswordError implements Action<ActionType> {
  readonly type = ActionType.ClearPasswordError;
}

export type Actions =
  | GetUserAction
  | GetUserFulfilledAction
  | GetUserRejectedAction
  | UpdateUserAction
  | UpdateUserFulfilledAction
  | UpdateUserRejectedAction
  | GetUserPeriodsAction
  | GetUserPeriodsFulfilledAction
  | GetUserPeriodsRejectedAction
  | GetIcsUrlAction
  | GetIcsUrlFulfilledAction
  | GetIcsUrlRejectedAction
  | GetNotificationPreferencesAction
  | GetNotificationPreferencesFulfilledAction
  | GetNotificationPreferencesRejectedAction
  | UpdateNotificationPreferencesAction
  | UpdateNotificationPreferencesFulfilledAction
  | UpdateNotificationPreferencesRejectedAction
  | UpdateAvatarAction
  | UpdateAvatarFulfilledAction
  | UpdateAvatarRejectedAction
  | DeleteAvatarAction
  | DeleteAvatarFulfilledAction
  | DeleteAvatarRejectedAction
  | ChangePasswordAction
  | ChangePasswordFulfilledAction
  | ChangePasswordRejectedAction
  | ClearPasswordError;
