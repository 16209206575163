import React, { FC, useState } from 'react';
import { Icon, IconButton } from 'react-ess-components';
import classNames from 'classnames';
import { parseISO } from 'date-fns';

import { ISelfRosteringShift } from '../../../../models';
import { useSelfRosterContext } from '../../../../modules';
import { date, selfRosterUtils, translations } from '../../../../utils';
import OverstaffedWarning from '../overstaffed/OverstaffedWarning';
import OverStaffedShiftModal from '../OverstaffedShiftModal/OverstaffedShiftModal';

interface Props {
  shift: ISelfRosteringShift;
  onClick: (shift: ISelfRosteringShift) => void;
  showDepartment?: boolean;
  isLocked?: boolean;
}

const SelfRosterShift: FC<Props> = ({ shift, onClick, showDepartment, isLocked }) => {
  const startDate = parseISO(shift.startDate);
  const endDate = parseISO(shift.endDate);
  const { selectShift } = useSelfRosterContext();

  const [showOverstaffedShiftModal, toggleOverstaffedShiftModal] = useState(false);


  const { color, darkColor, gradientCss } = selfRosterUtils.getShiftColors(shift);

  const style = {
    backgroundColor: color,
    borderColor: darkColor,
    backgroundImage: 'none',
  };

  if (shift.isOverstaffed) {
    style.backgroundColor = 'transparent';
    style.backgroundImage = gradientCss;
  }

  const removeShift = (event: React.BaseSyntheticEvent) => {
    event.stopPropagation();
    if (shift?.isOverstaffed) {
      toggleOverstaffedShiftModal(true);
    } else {
      selectShift(shift);
    }
  };

  const toggleOverStaffedShift = (shift: ISelfRosteringShift) => {
    selectShift(shift);
    toggleOverstaffedShiftModal(false);
  };

  return (
    <>
      <button
        onClick={() => onClick(shift)}
        style={style}
        aria-label={translations.getLabel('lblOpenScheduleDetails')}
        className={classNames('self-roster-shift sidebar-shift selected', { 'locked': isLocked })}>
        <div className="shift-content">
          <h5>{shift.displayName}{showDepartment && !!shift.departmentName && <span> - {shift.departmentName}</span>}</h5>
          <p>{`${date.formatFromUntilTime(date.format, { startDate, endDate })}`}</p>
        </div>
        {!isLocked && <div><IconButton className="close-button" title={translations.getLabel('btnRemoveSelfRosterShift')} onClick={removeShift} tag="CrossIcon" /></div>}
        <div className="arrow-container" style={{ backgroundColor: darkColor }}><Icon tag="ChevronRightIcon" color="white" /></div>
      </button>
      {shift.isOverstaffed && <OverstaffedWarning />}
      <OverStaffedShiftModal
        open={showOverstaffedShiftModal}
        onClose={() => toggleOverstaffedShiftModal(false)}
        onSave={() => toggleOverStaffedShift(shift)}
      />
    </>
  );
};

export default SelfRosterShift;
