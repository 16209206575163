import React, { FC, useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { Button, Checkbox, InputDateField, Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { addDays, isAfter, isBefore, parseISO } from 'date-fns';
import { ContentState, EditorState } from 'draft-js';
import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor';

import { routes } from '../../constants';
import { errorCodes } from '../../constants';
import { useForm, useToggle } from '../../hooks';
import { Form, Locale } from '../../models';
import { configSelectors, newsActions, newsSelectors } from '../../redux';
import { fileUploadUtils, newsUtils, translations } from '../../utils';
import NewsDepartments from './components/NewsDepartments';
import NewsFiles from './components/NewsFiles';
import NewsForm from './components/NewsForm';

import './upsertNewsItem.scss';

// TODO: properly deal with language conflict userLanguage vs. holding defaultLanguage
const UpsertNewsItem: FC<RouteComponentProps<{ id: string }>> = (props) => {
  const defaultLanguage = useSelector(configSelectors.defaultLanguage);
  const isSaving = useSelector(newsSelectors.isSaving);
  const newsItem = useSelector(newsSelectors.getNewsDetailsAdmin);
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);

  const dispatch = useDispatch();

  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [isStartDateChecked, toggleIsStartDateChecked] = useToggle(false);
  const [isEndDateChecked, toggleIsEndDateChecked] = useToggle(false);
  const [file, setFile] = useState<string>('');
  const [newFile, setNewFile] = useState<File>(null);
  const [id, setId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(!!props.match?.params?.id);
  const [shouldShowCancelModal, toggleShouldShowCancelModal] = useToggle(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [departments, setDepartments] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [initialLanguages, setInitialLanguages] = useState<string[]>([]);

  const { form, setForm } = useForm<Form>({
    [defaultLanguage.value]: {
      title: '',
      attachments: [],
      message: EditorState.createEmpty(),
    },
  });

  useEffect(() => {
    const { id } = props.match.params;
    if (id) {
      setId(id);
      dispatch(new newsActions.GetAdminNewsDetailAction({
        id,
        onError: () => props.history.replace(routes.upsertNewsItemWithoutId),
      }));
    }
  }, []);

  useEffect(() => {
    if (newsItem?.content) {
      const languages = [];
      setForm({
        ...form,
        ...newsItem.content.reduce((accu, item) => {
          if (defaultLanguage.value !== item.language) languages.push(item.language);
          return {
            ...accu,
            [item.language]: {
              ...item,
              message: createEditorState(item.message),
            },
          };
        }, {}),
      });

      setDepartments(newsItem.departmentIds);
      setStartDate(newsItem.startDate ? parseISO(newsItem.startDate) : new Date());
      setEndDate(newsItem.endDate ? parseISO(newsItem.endDate) : new Date());
      setId(newsItem?.id);
      setIsLoading(false);
      setFile(newsItem.imageUrl);
      setInitialLanguages(languages);
      if (!!newsItem.startDate) toggleIsStartDateChecked();
      if (!!newsItem.endDate) toggleIsEndDateChecked();
    }
  }, [newsItem]);

  useEffect(() => {
    if (!isLoading && !hasChanged) {
      setHasChanged(true);
    }
  }, [form, departments, startDate, endDate, newFile]);

  useEffect(() => {
    setHasChanged(false);
  }, [isLoading]);

  const createEditorState = (message: string): EditorState => {
    const processedHTML = DraftPasteProcessor.processHTML(message);
    const contentState = ContentState.createFromBlockArray(processedHTML);
    let editorState = EditorState.createWithContent(contentState);
    editorState = EditorState.moveFocusToEnd(editorState);
    return editorState;
  };

  const onCancel = () => {
    // Go back if nothing has changed
    if (!hasChanged) return props.history.goBack();
    toggleShouldShowCancelModal();
  };

  const onConfirmGoBack = () => {
    toggleShouldShowCancelModal();
    props.history.goBack();
  };

  const isTitleValid = () => Object.keys(form).every(language => form[language].title);

  const isMessageValid = () => Object.keys(form).every(language => form[language].message.getCurrentContent().hasText());

  const isStateValid = () => isTitleValid() && isMessageValid();

  const onSave = async () => {
    if (!isStateValid()) {
      return setIsError(true);
    }
    const newsItem = newsUtils.createNewsItemForDb(form, departments, isStartDateChecked, isEndDateChecked, startDate, endDate);
    const files = {
      deleteCoverImage: !newFile && !file,
      coverImage: newFile || null,
      attachments: newsItem.originalAttachments.flat(),
    };

    dispatch(new newsActions.UpdateNewsItemAction({
      id: id,
      data: newsItem.data,
      files,
      fileUploadFunc: fileUploadUtils.uploadFileToPresignedUrl,
      onSuccess: () => {
        toast(translations.getLabel('lblPublishNewsItemSuccess'));
        props.history.replace(routes.news);
      },
      onError: error => {
        if (error.code === errorCodes.uploadAttachment.value) {
          toast.error(translations.getLabel(errorCodes.uploadAttachment.label));
          props.history.replace(routes.news);
        } else {
          return toast.error(translations.getLabel('lblGenericError'));
        }
      },
    }));
  };

  const setDate = (value, key: string,) => {
    let start = startDate;
    let end = endDate;
    if (key === 'start') {
      if (isAfter(value.date, end)) end = value.date;
      start = value.date;
    } else {
      if (isBefore(value.date, start)) start = value.date;
      end = value.date;
    }

    setEndDate(end);
    setStartDate(start);
  };

  const renderSeparator = () => <div className="separator" />;

  const filterEndDate = date => {
    const d = new Date(startDate);
    const daySubstracted = addDays(d, -1);
    return date > daySubstracted;
  };

  const setFiles = (fileToSet) => {
    setNewFile(fileToSet);
    if (!fileToSet) setFile('');
  };

  const classnameStartDate = classnames('input-wrapper', { 'disabled': !isStartDateChecked });
  const classnameEndDate = classnames('input-wrapper', { 'disabled': !isEndDateChecked });

  return (
    <DocumentTitle title={newsItem?.id ? translations.getLabel('titleEditNewsItem') : translations.getLabel('titleNewNewsItem')}>
      <div className="upsert-news-item page fixed-width">
        <h2>{isLoading ? <Skeleton width="50%" /> : (newsItem?.id ? translations.getLabel('titleEditNewsItem') : translations.getLabel('titleNewNewsItem'))}</h2>
        <div className="upsert-news-item-container">
          <NewsDepartments
            departments={departments}
            setDepartments={setDepartments}
          />
          {renderSeparator()}
          <div className="date-select-container">
            {isLoading
              ? <Skeleton height="3.5rem" width="50rem" />
              : <>
                <Checkbox id="startDate" onCheck={toggleIsStartDateChecked} isChecked={isStartDateChecked} label={translations.getLabel('lblStartDate')} />
                <div className={classnameStartDate}>
                  <InputDateField disabled={!isStartDateChecked} weekStartsOn={weekStartsOn} name="start" onChange={setDate} value={{ date: startDate }} dateFormat="dd MMM yyyy" locale={translations.I18n.locale as Locale} />
                </div>
                <Checkbox id="endDate" onCheck={toggleIsEndDateChecked} isChecked={isEndDateChecked} label={translations.getLabel('lblEndDate')} />
                <div className={classnameEndDate}>
                  <InputDateField disabled={!isEndDateChecked} weekStartsOn={weekStartsOn} name="end" onChange={setDate} value={{ date: endDate }} dateFormat="dd MMM yyyy" filterDate={filterEndDate} locale={translations.I18n.locale as Locale}/>
                </div>
              </>
            }
          </div>
          {renderSeparator()}
          <NewsForm
            isError={isError}
            form={form}
            setForm={setForm}
            isLoading={isLoading}
            initialLanguages={initialLanguages}
          />
          <NewsFiles
            file={file}
            setNewFile={setFiles}
          />
        </div >
        <Modal
          open={shouldShowCancelModal}
          requestClose={toggleShouldShowCancelModal}
          title={translations.getLabel('lblCloseNewsItem')}
          leftButtonProps={{
            onClick: toggleShouldShowCancelModal,
            label: translations.getLabel('cancel'),
          }}
          rightButtonProps={{
            onClick: onConfirmGoBack,
            label: translations.getLabel('lblCloseNewsItem'),
          }}
        >
          <p className="modal-content">{translations.getLabel('lblWarningCloseNewsItem')}</p>
        </Modal>

        <footer>
          <div className="content">
            <Button theme="inverse" onClick={onCancel}>{translations.getLabel('cancel')}</Button>
            <Button disabled={!hasChanged} isLoading={isSaving} onClick={onSave}>{translations.getLabel('publish')}</Button>
          </div>
        </footer>
      </div>
    </DocumentTitle>

  );
};

export default UpsertNewsItem;
