import { produce } from 'immer';

import ActionType from './types';
import { IEmployee } from '../../models';
import { Actions } from './actions';

export interface EmployeeState {
  isLoading: boolean;
  employeeDetails: Record<string, IEmployee>;
};

export const initialState: EmployeeState = {
  isLoading: false,
  employeeDetails: null,
};

const employees = produce((draft: EmployeeState, action: Actions) => {
  switch (action.type) {
    case ActionType.GetEmployee:
      draft.isLoading = true;
      break;

    case ActionType.GetEmployeeFulfilled:
      if (!draft.employeeDetails) draft.employeeDetails = {};
      draft.employeeDetails[action.payload.data.id] = action.payload.data;
      draft.isLoading = false;
      break;

    case ActionType.GetEmployeeRejected:
      draft.isLoading = true;
      break;
  }
}, initialState);

export default employees;
