import React, { FC,useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';

import { NotificationTrigger } from '../../models';
import { notificationUtils, queryParser } from '../../utils';

const NotificationHandler: FC = () => {
  useEffect(() => {
    const urlParams = queryString.parse(window.location.search);
    if (urlParams && urlParams.redirect) {
      notificationUtils.goToNotification(urlParams.employmentId as string, urlParams.type as NotificationTrigger, urlParams.identifier as string);
    }
  }, []);

  if (queryParser.isMobileDevice()) return <Redirect to={{ pathname: '/auth/download' }} />;
  return <Redirect to={{ pathname: '/auth/login' }} />;
};

export default NotificationHandler;
