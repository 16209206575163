import React, { FC, useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { isValid } from 'date-fns';

import { AppState } from '../../../../shared/redux/rootReducer';
import { ToolBar } from '../../components';
import { routes } from '../../constants';
import { useToggle } from '../../hooks';
import { Period, TimeScale, Tools, UserRight } from '../../models';
import { configSelectors, requestsActions, requestsSelectors, userSelectors } from '../../redux';
import { date, notificationUtils, requestsUtils, scheduleUtils, translations } from '../../utils';
import AdjustPeriodModal from './components/adjustPeriods/AdjustPeriodModal';
import CreateAdjustedShiftTimeModal from './components/adjustShiftPeriod/CreateAdjustedShiftTimeModal';
import CreateSelfRosterIterationsModal from './components/createSelfRosterIterations/CreateSelfRosterIterationsModal';
import RequestVacationModal from './components/requestVacation/RequestVacationModal';
import CreateShiftOfferModal from './components/shiftOfferAndSwap/CreateShiftOfferModal';
import CreateShiftSwapRequestModal from './components/shiftOfferAndSwap/CreateShiftSwapRequestModal';
import CreateTemplateModal from './components/shiftOfferAndSwap/CreateTemplateModal';
import DayWeekSchedule from './DayWeekSchedule';
import MonthSchedule from './MonthSchedule';

import './schedule.scss';

const ScheduleView: FC<RouteComponentProps<{ id: string; timescale?: TimeScale }>> = (props) => {
  const dispatch = useDispatch();
  const [isAvailabilityShown, toggleAvailability] = useToggle(true);
  const [isRequestsShown, toggleRequests] = useToggle(true);
  const [isPeriodsModalOpen, togglePeriodsModal] = useState<boolean>(false);
  const [isShiftOfferModalOpen, toggleShiftOfferModal] = useToggle(false);
  const [isSelfRosterModalOpen, toggleSelfRosterModalOpen] = useToggle(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [timeScale, setTimeScale] = useState<TimeScale>(props.match.params.timescale || TimeScale.Week);
  const [activePeriodType, setActivePeriodType] = useState<Tools>(null);

  const availabilityPeriod = useSelector((state: AppState) => userSelectors.getPeriods(state, Period.AvailabilityRequestPeriod));
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const didVacationTypesLoad = useSelector(requestsSelectors.didVacationTypesLoad);
  const hasShiftOfferRight = useSelector((state: AppState) => userSelectors.hasRight(state, UserRight.ShiftOffer));
  const hasSelfRosterRight = useSelector((state: AppState) => userSelectors.hasRight(state, UserRight.SelfRoster));
  const vacationPeriod = useSelector((state: AppState) => userSelectors.getPeriods(state, Period.VacationRequestPeriod));
  const shiftTimePeriod = useSelector((state: AppState) => userSelectors.getPeriods(state, Period.ShiftTimeAdjustmentPeriod));
  const isSpxDown = useSelector(configSelectors.isSpxDown);

  useEffect(() => {
    if (!didVacationTypesLoad) {
      dispatch(new requestsActions.GetVacationTypesAction());
    }

    if (props.match.url !== routes.scheduleAvailabilities) {
      if (props.match.params.id) {
        const queryDate = props.match.params.id;
        if (queryDate !== null && queryDate !== '') {
          const selectedDate = scheduleUtils.parseDateFromNotification(queryDate);
          if (isValid(selectedDate)) {
            return changeDate(selectedDate);
          }
        }
      }
      changeDate();
    }
  }, []);

  const changeDate = (newDate = new Date()): void => {
    // Set the date in the url
    notificationUtils.changeIdInUrl(date.format(newDate, 'dd-MM-yyy'));
    setSelectedDate(newDate);
  };

  const changeTimeScale = (newTimeScale: TimeScale, date?: Date): void => {
    setTimeScale(newTimeScale);
    changeDate(date || selectedDate);
  };

  const getDisabledTools = () => {
    const blacklist = [];

    if (!!availabilityPeriod && !requestsUtils.isValidRequest({ requestPeriod: availabilityPeriod, isPeriodCheck: true })) blacklist.push(Tools.Availabilities);
    if (!!vacationPeriod && !requestsUtils.isValidRequest({ requestPeriod: vacationPeriod, isPeriodCheck: true })) blacklist.push(Tools.Vacation);
    if (!!shiftTimePeriod && !requestsUtils.isValidRequest({ requestPeriod: shiftTimePeriod, isPeriodCheck: true })) blacklist.push(Tools.ShiftTimeAdjustment);

    return blacklist;
  };

  const toggleModal = (tool: Tools = null) => {
    setActivePeriodType(tool);
    togglePeriodsModal(!isPeriodsModalOpen);
  };

  return (
    <DocumentTitle title={translations.getLabel('titleSchedule')}>
      <div className="app-schedule page fixed-width">
        <ToolBar
          isSpxDown={isSpxDown}
          changeDate={changeDate}
          changeDateRange={changeTimeScale}
          dateRange={timeScale}
          disabledFunctions={getDisabledTools()}
          isAvailabilityShown={isAvailabilityShown}
          isRequestsShown={isRequestsShown}
          onAdjustPeriod={toggleModal}
          onAddShiftOffer={toggleShiftOfferModal}
          onVacationRequest={() => dispatch(new requestsActions.ToggleVacationRequestModalAction({ date: selectedDate }))}
          selectedDate={selectedDate}
          toggleAvailabilities={() => toggleAvailability()}
          toggleRequests={() => toggleRequests()}
          toggleSelfRoster={toggleSelfRosterModalOpen}
          weekStartsOn={weekStartsOn}
        />

        <AdjustPeriodModal maxDate={activePeriodType === Tools.ShiftTimeAdjustment && new Date()} isOpen={isPeriodsModalOpen} toggleModal={toggleModal} type={activePeriodType} />

        <RequestVacationModal />

        <CreateTemplateModal />

        <CreateShiftSwapRequestModal />

        <CreateAdjustedShiftTimeModal />

        {hasShiftOfferRight && <CreateShiftOfferModal isOpen={isShiftOfferModalOpen} toggleModal={toggleShiftOfferModal} />}

        {hasSelfRosterRight && <CreateSelfRosterIterationsModal isOpen={isSelfRosterModalOpen} toggleModal={toggleSelfRosterModalOpen} />}

        <div className="spacer" />

        {
          timeScale === TimeScale.Month
            ? <MonthSchedule changeTimeScale={changeTimeScale} timeScale={timeScale} selectedDate={selectedDate} showAvailabilities={isAvailabilityShown} />
            : <DayWeekSchedule timeScale={timeScale} selectedDate={selectedDate} showAvailabilities={isAvailabilityShown} showRequests={isRequestsShown} searchEnabled />
        }
      </div>
    </DocumentTitle>
  );
};

export default ScheduleView;
