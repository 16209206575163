import { createSelector } from 'reselect';
import { AvailabilitiesState, formatForIndex } from './reducer';
import { AppState } from '../rootReducer';
import { IAvailabilitiesDayList } from '../../models';

const selectNode = (state: AppState) => state.availabilities;

export const getAvailabilities = createSelector(
  selectNode,
  (state: AvailabilitiesState) => state.data,
);

export const getAvailabilitiesForDay = (state: AppState, date: Date) => {
  const node = selectNode(state);
  return node.availabilities[formatForIndex(date)];
};

export const getTimeTypes = createSelector(
  selectNode,
  (state: AvailabilitiesState) => state.timeTypes,
);

export const getVisibleTimeTypes = createSelector(
  selectNode,
  (state: AvailabilitiesState) => state.timeTypes.filter(type => type.visible),
);

export const getTotalCount = createSelector(
  selectNode,
  (state: AvailabilitiesState) => state.totalCount,
);

export const isLoading = createSelector(
  selectNode,
  (state: AvailabilitiesState) => state.isLoading,
);

export const getServerError = createSelector(
  selectNode,
  (state: AvailabilitiesState) => state.serverError,
);
