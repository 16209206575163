import React, { FC } from 'react';
import { Button, Icon } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { isAfter, parseISO } from 'date-fns';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { IDayPlanning, Period, RequestStatus, UserRight } from '../../../../models';
import { configSelectors, requestsActions, shiftOfferActions, shiftSwapActions, userSelectors } from '../../../../redux';
import { date, requestsUtils, translations } from '../../../../utils';
import Shift from '../../../shift/Shift';

import './PlanningDetail.scss';

type Props = {
  planning: IDayPlanning;
  toggleDeleteModal: () => void;
  isMyRoster?: boolean;
  closeParentPopup?: () => void;
  isWeek?: boolean;
  hideActions?: boolean;
}

const PlanningDetail: FC<Props> = ({ planning, toggleDeleteModal, isMyRoster, closeParentPopup, isWeek, hideActions }) => {
  const dispatch = useDispatch();
  const vacationPeriod = useSelector((state: AppState) => userSelectors.getPeriods(state, Period.VacationRequestPeriod));
  const hasShiftOfferRights = useSelector((state: AppState) => userSelectors.hasRight(state, UserRight.ShiftOffer));
  const companyConfig = useSelector(configSelectors.getCompanyConfig);

  const handleVacationRequest = () => {
    if (closeParentPopup) closeParentPopup();
    dispatch(new requestsActions.ToggleVacationRequestModalAction({ date: parseISO(planning.date) }));
  };

  const handleShiftSwap = () => {
    if (closeParentPopup) closeParentPopup();
    dispatch(new shiftSwapActions.ToggleshiftSwapModalAction({ shifts: planning.shifts }));
  };

  const handleCreateTemplate = () => {
    if (closeParentPopup) closeParentPopup();
    dispatch(new shiftOfferActions.ToggleshiftOfferModalAction({ employeeId: planning.employeeId, date: planning.date }));
  };

  const isInPast = !isAfter(parseISO(planning.date), new Date());
  const isVacationPossible = (!!vacationPeriod && requestsUtils.isValidRequest({ requestPeriod: vacationPeriod, forDate: parseISO(planning.date) }) && !isInPast) || (planning?.vacationRequest && !isInPast);
  const isShiftSwapPossible = (companyConfig?.modules.shiftSwap && !isInPast) || planning?.shiftSwapRequest;
  const isShiftOfferPossible = companyConfig?.modules.shiftOffer && hasShiftOfferRights;

  const getButtons = () => {
    const shiftSwap = planning.shiftSwapRequest;
    const hasOpenVacationRequest = planning.vacationRequest?.status === RequestStatus.Open;
    const hasOpenShiftSwap = shiftSwap?.status === RequestStatus.Open;
    const hasPendingShiftSwap = shiftSwap?.status === RequestStatus.Pending;
    const hasDeniedShiftSwap = shiftSwap?.status === RequestStatus.Denied;

    if (hasOpenVacationRequest) return (
      <div className="in-request">
        <p className="semibold">{translations.getLabel('lblRequestedVacation')}</p>
        <div className="button-wrapper single-button">
          <Button onClick={toggleDeleteModal}>{translations.getLabel('btnCancelRequest')}</Button>
        </div>
      </div>
    );

    if (hasOpenShiftSwap) return (
      <div className="in-request">
        <p><Icon tag="ChangeIcon" color="primaryDark" /> {translations.getLabel(shiftSwap.hasCounterSwap ? 'lblOfferedWithCounterSwap' : 'lblOfferedWithoutCounterSwap')}</p>
        <p><Icon tag="CandidatsIcon" color="primaryDark" /> {translations.getLabel('lblShiftSwapNumberOfApplicants', { amount: shiftSwap.numberOfApplicants || 0 })}</p>
        <div className="button-wrapper">
          <Button onClick={toggleDeleteModal}>{translations.getLabel('btnCancelRequest')}</Button>
          <Button link={`/shift-swap-candidate/${planning.date}/${shiftSwap.id}`} disabled={!shiftSwap.numberOfApplicants}>{translations.getLabel('btnChooseCandidate')}</Button>
        </div>
      </div>
    );

    if (hasPendingShiftSwap || hasDeniedShiftSwap) {
      const shiftSwapDate = shiftSwap.selectedCounterSwapDate && parseISO(shiftSwap.selectedCounterSwapDate);
      const lastUpdatedDate = requestsUtils.getRelativeDate(shiftSwap.updatedAt, date.format);

      return (
        <>
          <p><Icon tag="ChangeIcon" color="primaryDark" /> {translations.getLabel(shiftSwap.hasCounterSwap ? 'lblOfferedWithCounterSwap' : 'lblOfferedWithoutCounterSwap')}</p>
          <div className="in-request">
            <p className={classnames('request-status-title', { error: hasDeniedShiftSwap })}>
              {hasPendingShiftSwap && translations.getLabel('lblWaitingOnApprovalManager')}
              {hasDeniedShiftSwap && <><Icon tag="CrossIcon" color="error" /> {translations.getLabel('lblDenied')}</>}
            </p>
            <p className="request-status-info">
              {hasPendingShiftSwap && translations.getLabel(shiftSwap.hasCounterSwap ? 'lblShiftSwapPendingWithDate' : 'lblShiftSwapPendingWithName', { name: shiftSwap.applicantName, date: date.format(shiftSwapDate, 'dd LLLL') })}
              {hasDeniedShiftSwap && <>{lastUpdatedDate.shouldTranslate ? `${translations.getLabel(lastUpdatedDate.value)} ${lastUpdatedDate.time}` : lastUpdatedDate.value} {translations.getLabel('lblBy')} {shiftSwap.reviewerName || '-'}</>}
            </p>
            {hasDeniedShiftSwap && !!shiftSwap.reviewerNote && <p className="request-status-note">
              {translations.getLabel('lblRemarks')}: {shiftSwap.reviewerNote || '-'}
            </p>}
          </div>
          {hasDeniedShiftSwap && <div className="in-request">{renderDefaultButtons()}</div>}
        </>
      );
    }
    return renderDefaultButtons();
  };

  const renderDefaultButtons = () => {
    return (
      <div className={classnames('button-wrapper', { 'single-button': !isVacationPossible || !isShiftSwapPossible })}>
        {isVacationPossible && <Button onClick={handleVacationRequest}>{translations.getLabel('btnRequestVacation')}</Button>}
        {isShiftSwapPossible && <Button onClick={handleShiftSwap}>{translations.getLabel('btnRequestShiftSwap')}</Button>}
      </div>
    );
  };

  // Show the button if the period is active, or if we are in a request (we should always be able to cancel a request)
  return (
    <div className="planning-detail-wrapper">
      {planning.shifts.map((shift) => <Shift closeParentPopup={closeParentPopup} showAdjustedShifts={isMyRoster && !hideActions} key={`${shift.shiftName}${shift.startDate}${shift.offset}`} shift={shift} hideIntervals />)}

      {(isVacationPossible || isShiftSwapPossible) && !hideActions && isMyRoster && (
        <div className="request-wrapper">
          {getButtons()}
        </div>
      )}
      {isWeek && isShiftOfferPossible && !hideActions && !isMyRoster && (
        <div className="request-wrapper">
          <div className="button-wrapper single-button">
            <Button onClick={handleCreateTemplate}>{translations.getLabel('btnCreateTemplate')}</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlanningDetail;
