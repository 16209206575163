import { Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { exhaustMap, map, catchError, tap } from 'rxjs/operators';

import ActionType from './types';
import { newsService } from '../../services';
import {
  GetNewsAction,
  GetNewsFulfilledAction,
  GetNewsRejectedAction,
  LoadMoreNewsAction,
  LoadMoreNewsFulfilledAction,
  LoadMoreNewsRejectedAction,
  GetNewsDetailAction,
  GetNewsDetailFulfilledAction,
  GetNewsDetailRejectedAction,
  GetAdminNewsDetailAction,
  GetAdminNewsDetailFulfilledAction,
  GetAdminNewsDetailRejectedAction,
  DeleteNewsItemAction,
  DeleteNewsItemFulfilledAction,
  DeleteNewsItemRejectedAction,
  UpdateNewsItemAction,
  UpdateNewsItemFulfilledAction,
  UpdateNewsItemRejectedAction,
} from './actions';

export const getNewsEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetNews).pipe(
    exhaustMap(() =>
      from(newsService.getNews()).pipe(
        map(response => new GetNewsFulfilledAction(response)),
        catchError(error => of(new GetNewsRejectedAction({ error })))
      )
    ),
  );

export const loadMoreNewsEpic$: Epic = action$ =>
  action$.ofType(ActionType.LoadMoreNews).pipe(
    exhaustMap(({ payload }: LoadMoreNewsAction) =>
      from(newsService.getNews(payload.limit, payload.offset)).pipe(
        map(response => new LoadMoreNewsFulfilledAction(response)),
        catchError(error => of(new LoadMoreNewsRejectedAction({ error })))
      )
    ),
  );

export const getNewsDetailEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetNewsDetail).pipe(
    exhaustMap(({ payload }: GetNewsDetailAction) =>
      from(newsService.getNewsDetail(payload.id)).pipe(
        map((data) => {
          payload.onSuccess?.(data);
          return new GetNewsDetailFulfilledAction({ data });
        }),
        catchError(error => {
          payload.onError?.(error);
          return of(new GetNewsDetailRejectedAction({ error }));
        })
      )
    ),
  );

export const getAdminNewsDetailEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetAdminNewsDetail).pipe(
    exhaustMap(({ payload }: GetAdminNewsDetailAction) =>
      from(newsService.getAdminNewsDetail(payload.id)).pipe(
        map((data) => {
          payload.onSuccess?.();
          return new GetAdminNewsDetailFulfilledAction({ data });
        }),
        catchError(error => {
          payload.onError?.(error);
          return of(new GetAdminNewsDetailRejectedAction({ error }));
        })
      )
    ),
  );

export const deleteNewsItemEpic$: Epic = action$ =>
  action$.ofType(ActionType.DeleteNewsItem).pipe(
    exhaustMap(({ payload }: DeleteNewsItemAction) =>
      from(newsService.deleteNewsItem(payload.id)).pipe(
        map(() => {
          payload.onSuccess?.();
          return new DeleteNewsItemFulfilledAction({ id: payload.id });
        }),
        catchError(error => {
          payload.onError?.(error);
          return of(new DeleteNewsItemRejectedAction({ error }));
        })
      )
    ),
  );

export const updateNewsItemEpic$: Epic = action$ =>
  action$.ofType(ActionType.UpdateNewsItem).pipe(
    exhaustMap(({ payload }: UpdateNewsItemAction) => {
      const promise = payload.id ?
        newsService.updateNewsItem(payload.id, payload.data, payload.files, payload.fileUploadFunc) :
        newsService.createNewsItem(payload.data, payload.files, payload.fileUploadFunc);
      return from(promise).pipe(
        map(() => {
          payload.onSuccess?.();
          return new UpdateNewsItemFulfilledAction();
        }),
        catchError(error => {
          payload.onError?.(error);
          return of(new UpdateNewsItemRejectedAction({ error }));
        })
      );
    }),
  );

const epics = [
  getNewsEpic$,
  loadMoreNewsEpic$,
  getNewsDetailEpic$,
  getAdminNewsDetailEpic$,
  deleteNewsItemEpic$,
  updateNewsItemEpic$
];

export default epics;
