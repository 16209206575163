import React, { FC, useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';

import { timeRegistrationActions, timeRegistrationSelectors } from '../../redux';
import { translations } from '../../utils';
import TimeRegistrationRow from './components/TimeRegistrationRow';

import './timeRegistration.scss';

const TimeRegistration: FC = () => {
  const dispatch = useDispatch();
  const timeRegistrations = useSelector(timeRegistrationSelectors.timeRegistrations);
  const isLoading = useSelector(timeRegistrationSelectors.isLoading);

  useEffect(() => {
    dispatch(new timeRegistrationActions.GetTimeRegistrationsAction());
  }, []);

  return (
    <DocumentTitle title={translations.getLabel('titleTimeRegistration')}>
      <div className="page fixed-width app-time-registration">
        <h2>{translations.getLabel('titleTimeRegistration')}</h2>
        <p>{translations.getLabel('lblTimeRegistrationTimePeriod')}</p>

        <div className="table-wrapper">
          {(timeRegistrations?.length || isLoading) &&
            <table>
              <thead>
                <tr>
                  <th className="left-align">{translations.getLabel('lblDate')}</th>
                  <th className="left-align">{translations.getLabel('lblTime')}</th>
                  <th className="left-align">{translations.getLabel('lblActivity')}</th>
                  <th className="left-align">{translations.getLabel('lblLocationId')}</th>
                </tr>
              </thead>
              <tbody>
                {isLoading
                  ? <tr><td><Skeleton /></td><td><Skeleton /></td><td><Skeleton /></td><td><Skeleton /></td></tr>
                  : timeRegistrations
                    ?.filter(timeRegistration => !!timeRegistration?.tickingList?.[0].time || !!timeRegistration?.tickingList?.[0].label || !!timeRegistration?.tickingList?.[0].locationId) // Only show active days
                    ?.map((timeRegistration) => <TimeRegistrationRow key={timeRegistration.date} timeRegistration={timeRegistration} />)
                }
              </tbody>
            </table>
          }
        </div>
      </div>
    </DocumentTitle>
  );
};

export default TimeRegistration;
