import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../../../shared/redux/rootReducer';
import { UserRight } from '../../models';
import { userSelectors } from '../../redux';

type Props = {
  right: UserRight;
}

const RenderIfRight: React.FC<PropsWithChildren<Props>> = ({ children, right }) => {
  const hasRight = useSelector((state: AppState) => userSelectors.hasRight(state, right));

  if (!hasRight) return null;
  return <>{children}</>;
};

export default RenderIfRight;