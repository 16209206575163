import React, { FC } from 'react';
import { eachDayOfInterval, endOfWeek, startOfWeek } from 'date-fns';

import { WeekStartsOn } from '../../../../models';
import { date } from '../../../../utils';

interface Props {
  selectedDate: Date;
  withoutLabel?: boolean;
  weekStartsOn: WeekStartsOn;
  format?: string;
}


const DayScaffold: FC<Props> = ({ withoutLabel, selectedDate, weekStartsOn, format = 'EEEE' }) => {
  const start = startOfWeek(selectedDate, { weekStartsOn });
  const end = endOfWeek(selectedDate, { weekStartsOn });

  return (<>
    {eachDayOfInterval({ start, end }).map((time, i) => <div key={i} style={{ width: `${(1 / 7) * 100}%` }} className="week-day">{!withoutLabel && date.format(time, format)}</div>)}
  </>);
};

export default DayScaffold;
