import { ITicking } from './counters';

export interface ITimeRegistration {
  tickingList: ITicking[];
  date: string;
};

export interface IDoubleTimeRegistration {
  timeRegistrations: IDoubleTicking[];
  device: string;
};

export interface IDoubleTicking {
  description: string;
  id: string;
  location: string;
  time: string;
  username: string;
};

export interface IActiveTimeRegistration {
  id: string;
  startTime: string;
  name: string;
  type: RegistrationType;
  secondsElapsed?: number;
  typeId: string;
  controlStart?: ControlType;
  controlEnd?: ControlType;
  isPauze?: boolean;
};

// TODO: Align the model above and this one, since they are basically the same
export interface IInternalTimeRegistration {
  id: string;
  isClockedIn: boolean;
  isRemoving?: boolean;
  seconds?: number;
  typeId?: string;
  isPauze?: boolean;
  name?: string;
  controlEnd?: ControlType;
};

export interface ITimeRegistrationType {
  id: string;
  type: RegistrationType;
  description: string;
  controlStart?: ControlType;
  controlEnd?: ControlType;
  isPauze: boolean;
  isStart?: boolean;
};

export interface ITimeRegistrationClock {
  warn: boolean;
  clocked: boolean;
  strict: ControlType;
};

export enum RegistrationType {
  Time = 'TIME',
  Informative = 'INFORMATIVE',
  Shift = 'SHIFT',
};

export enum ClockType {
  Start = 'START',
  End = 'END',
};

export enum ControlType {
  Mild = 'MILD',
  Hard = 'HARD',
  None = 'NONE',
};

export enum ValidationType {
  Gps = 'GPS',
  Beacon = 'BEACON',
  Wifi = 'WIFI',
  DeviceId = 'DEVICE_ID',
}

export interface IValidator {
  validationType: ValidationType;
  validationValue: string;
};
