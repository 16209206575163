import { ActionType } from './types';
import { Action } from 'redux';
import { IChatConversation, IChatParticipantList, IChatMessage } from '../../models';
import { IServerError } from '../../http/HttpError';

export class GetConversationsAction implements Action<ActionType> {
  readonly type = ActionType.GetConversations;
  constructor(public payload?: { query?: string, onSuccess?: () => void }) { }
}

export class GetConversationsFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetConversationsFulfilled;
  constructor(public payload: { data: IChatConversation[], count: number }) { }
}

export class GetConversationsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetConversationsRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class CreateConversationAction implements Action<ActionType> {
  readonly type = ActionType.CreateConversation;
  constructor(public payload: { employmentIds?: string[], departmentId?: string, onSuccess: ({ data: IChatConversation }) => void, onError: (error: IServerError) => void }) { }
}

export class CreateConversationFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.CreateConversationFulfilled;
  constructor(public payload: { data: IChatConversation }) { }
}

export class CreateConversationRejectedAction implements Action<ActionType> {
  readonly type = ActionType.CreateConversationRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class LeaveConversationAction implements Action<ActionType> {
  readonly type = ActionType.LeaveConversation;
  constructor(public payload: { id: string, onSuccess?: () => void, onError?: (error: IServerError) => void }) { }
}

export class LeaveConversationFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.LeaveConversationFulfilled;
  constructor(public payload?: { onSuccess?: () => void }) { }
}

export class LeaveConversationRejectedAction implements Action<ActionType> {
  readonly type = ActionType.LeaveConversationRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class AddMessageToConversationAction implements Action<ActionType> {
  readonly type = ActionType.AddMessageToConversation;
  constructor(public payload: { message: IChatMessage, conversationId: string }) { }
}

export class GetPresignedUrlAction implements Action<ActionType> {
  readonly type = ActionType.GetPresignedUrl;
  constructor(public payload: { roomId: string, contentType: string, conversationId: string, onSuccess?: (url: string) => void }) { }
}

export class GetPresignedUrlFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetPresignedUrlFulfilled;
  constructor(public payload: { uploadUrl: string, storageId: string }) { }
}

export class GetPresignedUrlRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetPresignedUrlRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetUnreadCountAction implements Action<ActionType> {
  readonly type = ActionType.GetUnreadCount;
  constructor() { }
}

export class GetUnreadCountFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetUnreadCountFulfilled;
  constructor(public payload: { amount: number }) { }
}

export class GetUnreadCountRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetUnreadCountRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class MarkAsReadAction implements Action<ActionType> {
  readonly type = ActionType.MarkAsRead;
  constructor(public payload: { id: string }) { }
}

export class MarkAsReadFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.MarkAsReadFulfilled;
  constructor(public payload: { id: string }) { }
}

export class MarkAsReadRejectedAction implements Action<ActionType> {
  readonly type = ActionType.MarkAsReadRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class MarkAsUnreadAction implements Action<ActionType> {
  readonly type = ActionType.MarkAsUnread;
  constructor(public payload: { id: string }) { }
}

export class GetParticipantsAction implements Action<ActionType> {
  readonly type = ActionType.GetParticipants;
  constructor(public payload?: { query?: string, onSuccess?: () => void }) { }
}

export class GetParticipantsFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetParticipantsFulfilled;
  constructor(public payload: { data: IChatParticipantList[] }) { }
}

export class GetParticipantsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetParticipantsRejected;
  constructor(public payload: { error: IServerError }) { }
}

export type Actions =
  | GetConversationsAction
  | GetConversationsFulfilledAction
  | GetConversationsRejectedAction
  | CreateConversationAction
  | CreateConversationFulfilledAction
  | CreateConversationRejectedAction
  | LeaveConversationAction
  | LeaveConversationFulfilledAction
  | LeaveConversationRejectedAction
  | AddMessageToConversationAction
  | GetPresignedUrlAction
  | GetPresignedUrlFulfilledAction
  | GetPresignedUrlRejectedAction
  | GetUnreadCountAction
  | GetUnreadCountFulfilledAction
  | GetUnreadCountRejectedAction
  | MarkAsReadAction
  | MarkAsReadFulfilledAction
  | MarkAsReadRejectedAction
  | MarkAsUnreadAction
  | GetParticipantsAction
  | GetParticipantsFulfilledAction
  | GetParticipantsRejectedAction;