import Color from 'color';

class colors {
  constructor() {
    this._background = '#f7fbff';
    this._banner = '#AA8122';
    this._bannerBackground = '#fff8ea';
    this._bannerBackgroundError = '#f9eceb';
    this._bannerError = '#aa3352';
    this._bannerBackgroundSuccess = '#e1f5ec';
    this._bannerSuccess = '#1f593f';
    this._black = '#000000';
    this._blackSemiTransparant = '#00000088';
    this._blackTransparant = '#00000024';
    this._error = '#e84c3d';
    this._errorLight = 'rgba(230,64,46,0.15)';
    this._fixedPrimary = '#3399ff';
    this._fixedPrimaryDark = '#194d81';
    this._gray = '#efefef';
    this._gray2 = '#6f767f';
    this._iosPrimary = '#007AFF';
    this._headerColor = '#3399fe';  // Just a bit different than primary color, so we can replace it
    this._iosPrimary = '#007AFF';
    this._navbarContrast = '#2c3b45';
    this._navbarIcon = '#ffffff';
    this._navbarIconUnselected = '#ffffff88';
    this._navbarText = '#ffffff';
    this._navbarTextHover = '#ffffff';
    this._navbarTextUnselected = '#d6e7ff';
    this._phoneIcon = '#ffffff';
    this._phonePageTitle = '#ffffff';
    this._primary = '#3399ff';
    this._primaryDark = '#194d81';
    this._primaryLight = '#d6e7ff';
    this._primaryLightGray = '#e5ecf5';
    this._primaryTransparent = 'rgba(51,153,255,0.26667)';
    this._success = '#41ba84';
    this._text = '#2c3b45';
    this._textGray = '#4a515a';
    this._textGrayLight = '#6f767f';
    this._transparent = '#00000000';
    this._warning = '#ff9633';
    this._white = '#ffffff';
    this._whiteTransparent = '#ffffff88';
    this._yellow = '#f1e974';
    this._red = '#d3543f';

    this._defaultColors = {
      headerColor: '#3399fe',
      primary: '#3399ff',
      navbarIcon: '#ffffff',
      navbarText: '#ffffff',
      navbarTextHover: '#ffffff',
      phoneIcon: '#ffffff',
      phonePageTitle: '#ffffff',
    };
  }
  get background() { return this._background; }
  get banner() { return this._banner; }
  get bannerBackground() { return this._bannerBackground; }
  get bannerBackgroundError() { return this._bannerBackgroundError; }
  get bannerError() { return this._bannerError; }
  get bannerBackgroundSuccess() { return this._bannerBackgroundSuccess; }
  get bannerSuccess() { return this._bannerSuccess; }
  get black() { return this._black; }
  get blackSemiTransparant() { return this._blackSemiTransparant; }
  get blackTransparant() { return this._blackTransparant; }
  get error() { return this._error; }
  get errorLight() { return this._errorLight; }
  get fixedPrimary() { return this._fixedPrimary; }
  get fixedPrimaryDark() { return this._fixedPrimaryDark; }
  get gray() { return this._gray; }
  get gray2() { return this._gray2; }
  get headerColor() { return this._headerColor; }
  get iosPrimary() { return this._iosPrimary; }
  get navbarContrast() { return this._navbarContrast; }
  get navbarIcon() { return this._navbarIcon; }
  get navbarIconUnselected() { return this._navbarIconUnselected; }
  get navbarText() { return this._navbarText; }
  get navbarTextHover() { return this._navbarTextHover; }
  get navbarTextUnselected() { return this._navbarTextUnselected; }
  get phoneIcon() { return this._phoneIcon; }
  get phonePageTitle() { return this._phonePageTitle; }
  get primary() { return this._primary; }
  get primaryDark() { return this._primaryDark; }
  get primaryLight() { return this._primaryLight; }
  get primaryLightGray() { return this._primaryLightGray; }
  get primaryTransparent() { return this._primaryTransparent; }
  get success() { return this._success; }
  get text() { return this._text; }
  get textGray() { return this._textGray; }
  get textGrayLight() { return this._textGrayLight; }
  get transparent() { return this._transparent; }
  get warning() { return this._warning; }
  get white() { return this._white; }
  get whiteTransparent() { return this._whiteTransparent; }
  get yellow() { return this._yellow; }
  get red() { return this._red; }

  set background(_background) { this._background = _background; }
  set banner(_banner) { this._banner = _banner; }
  set bannerBackground(_bannerBackground) { this._bannerBackground = _bannerBackground; }
  set bannerBackgroundError(_bannerBackgroundError) { this._bannerBackgroundError = _bannerBackgroundError; }
  set bannerError(_bannerError) { this._bannerError = _bannerError; }
  set bannerBackgroundSuccess(_bannerBackgroundSuccess) { this._bannerBackgroundSuccess = _bannerBackgroundSuccess; }
  set bannerSuccess(_bannerSuccess) { this._bannerSuccess = _bannerSuccess; }
  set black(_black) { this._black = _black; }
  set blackSemiTransparant(_blackSemiTransparant) { this._blackSemiTransparant = _blackSemiTransparant; }
  set blackTransparant(_blackTransparant) { this._blackTransparant = _blackTransparant; }
  set error(_error) { this._error = _error; }
  set gray(_gray) { this._gray = _gray; }
  set gray2(_gray2) { this._gray2 = _gray2; }
  set headerColor(_headerColor) { this._headerColor = _headerColor; }
  set iosPrimary(_iosPrimary) { this._iosPrimary = _iosPrimary; }
  set navbarContrast(_navbarContrast) { this._navbarContrast = _navbarContrast; }
  set navbarIcon(_navbarIcon) { this._navbarIcon = _navbarIcon; }
  set navbarIconUnselected(_navbarIconUnselected) { this._navbarIconUnselected = _navbarIconUnselected; }
  set navbarText(_navbarText) { this._navbarText = _navbarText; }
  set navbarTextHover(_navbarTextHover) { this._navbarTextHover = _navbarTextHover; }
  set navbarTextUnselected(_navbarTextUnselected) { this._navbarTextUnselected = _navbarTextUnselected; }
  set phoneIcon(_phoneIcon) { this._phoneIcon = _phoneIcon; }
  set phonePageTitle(_phonePageTitle) { this._phonePageTitle = _phonePageTitle; }
  set primary(_primary) { this._primary = _primary; }
  set primaryDark(_primaryDark) { this._primaryDark = _primaryDark; }
  set primaryLight(_primaryLight) { this._primaryLight = _primaryLight; }
  set primaryLightGray(_primaryLightGray) { this._primaryLightGray = _primaryLightGray; }
  set primaryTransparent(_primaryTransparent) { this._primaryTransparent = _primaryTransparent; }
  set success(_success) { this._success = _success; }
  set text(_text) { this._text = _text; }
  set textGray(_textGray) { this._textGray = _textGray; }
  set textGrayLight(_textGrayLight) { this._textGrayLight = _textGrayLight; }
  set transparent(_transparent) { this._transparent = _transparent; }
  set warning(_warning) { this._warning = _warning; }
  set white(_white) { this._white = _white; }
  set whiteTransparent(_whiteTransparent) { this._whiteTransparent = _whiteTransparent; }
  set yellow(_yellow) { this._yellow = _yellow; }
  set red(_red) { this._red = _red; }

  setAPIColors = (colors = this._defaultColors) => {
    this.headerColor = !!colors.headerColor ? colors.headerColor : this._defaultColors.headerColor;
    this.primary = !!colors.primary ? colors.primary : this._defaultColors.primary;
    this.primaryDark = new Color(colors.primary).darken(0.5).hex();
    this.primaryLight = new Color(colors.primary).lighten(0.5).hex();
    this.primaryTransparent = new Color(colors.primary).alpha(0.2).toString();
    this.primaryLightGray = new Color(colors.primary).desaturate(0.6).alpha(0.15).toString();
    this.background = new Color(colors.primary).alpha(0.05).toString();
    this.navbarIcon = !!colors.navbarIcon ? colors.navbarIcon : this._defaultColors.navbarIcon;
    this.navbarIconUnselected = new Color(this.navbarIcon).isDark() ? new Color(this.navbarIcon).lighten(0.2).hex() : new Color(this.navbarIcon).darken(0.2).hex();
    this.navbarContrast = new Color(this.navbarIcon).isDark() ? this.white : this.text;
    this.navbarText = !!colors.navbarText ? colors.navbarText : this._defaultColors.navbarText;
    this.navbarTextHover = !!colors.navbarTextHover ? colors.navbarTextHover : this._defaultColors.navbarTextHover;
    this.navbarTextUnselected = new Color(this.navbarText).isDark() ? new Color(this.navbarText).lighten(0.1).hex() : new Color(this.navbarText).darken(0.2).hex();
    this.phoneIcon = !!colors.phoneIcon ? colors.phoneIcon : this._defaultColors.phoneIcon;
    this.phonePageTitle = !!colors.phonePageTitle ? colors.phonePageTitle : this._defaultColors.phonePageTitle;
  }
}

export default new colors();