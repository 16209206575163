import React, { BaseSyntheticEvent, FC, useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { Button } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import classnames from 'classnames';

import { IMAGES } from '../../assets';
import { errorCodes, storageItems } from '../../constants';
import { useForm } from '../../hooks';
import { LoginStatus } from '../../models';
import { configSelectors, loginActions, loginSelectors } from '../../redux';
import { sso, translations, validation } from '../../utils';
import { analytics } from '../../utils/firebase';

import './Login.scss';

let didCancel = false;
declare global {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface Window { Speakap: any }
}

const LoginView: FC<RouteComponentProps> = ({ history, location }) => {
  const dispatch = useDispatch();
  const { form, setFormAttribute } = useForm({ username: '', password: '' });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasSsoError, setHasSsoError] = useState<boolean>(false);
  const [isSsoLoading, setIsSsoLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [isLargeError, setIsLargeError] = useState<boolean>(false);
  const holdingPreferences = useSelector(configSelectors.getHoldingPreferences);
  const status = useSelector(loginSelectors.getPasswordStatus);
  const token = useSelector(loginSelectors.getToken);

  useEffect(() => {
    toast.configure();
    didCancel = false;
    checkSso();
    if (window.Speakap) checkThirdParty();
    return () => { didCancel = true; };
  }, []);

  useEffect(() => {
    if (!isLoading && status) {
      localStorage.setItem(storageItems.IS_LOGGED_IN, 'true');
      analytics?.logEvent('NEW_LOGIN_WEB', { holding: holdingPreferences.url });
      if (status === LoginStatus.MustChangePassword) {
        history.push({
          pathname: 'reset-password',
          state: { token, isChangedDefault: true },
        });
      } else {
        history.push('/auth/employments');
      }
    }
  }, [isLoading, token, status]);

  const checkThirdParty = () => {
    document.cookie = 'testThirdParty=success';
    if (!document.cookie?.split(' ')?.find(c => c === 'testThirdParty=success')) toast.error(translations.getLabel('lblThirdPartyErrorSafari'), { autoClose: false });
  };

  const checkSso = async () => {
    // Check if we are in a redirect from sso
    const token = sso.getToken(location.hash);
    if (await sso.isValidResponse(token, localStorage)) {
      setIsSsoLoading(true);
      dispatch(new loginActions.LoginWithSsoAction({
        token,
        holding: holdingPreferences.url,
        onSuccess: onLoginSuccess,
        onError: () => {
          setHasSsoError(true);
        },
      }));
      // clear url
      window.history.replaceState(null, null, ' ');
    } else {
      // If a user can only login with sso, redirect on mount
      if (!holdingPreferences.isRegularLoginEnabled) {
        openSso();
      }
    }
  };

  const onLoginUser = async (event: BaseSyntheticEvent) => {
    event.preventDefault();
    setIsLoading(true);
    dispatch(new loginActions.LoginAction({
      username: form.username,
      password: form.password,
      holding: holdingPreferences.url,
      onSuccess: onLoginSuccess,
      onError: (error) => {
        if (!didCancel) {
          if (error.code === errorCodes.requests.value) {
            setIsLargeError(true);
          } else {
            setIsLargeError(false);
          }
          setIsLoading(false);
          setError(error.detail);
        }
      },
    }));
  };

  const onLoginSuccess = () => {
    setIsLoading(false);
  };

  const onChange = (event: BaseSyntheticEvent) => {
    setFormAttribute(event.target.value, event.target.name);
    setError('');
  };

  const goToForgotPassword = () => {
    history.push('/auth/forgot-password');
  };

  const openSso = async () => {
    if (validation.isUrlValid(holdingPreferences.ssoEndpoint)) {
      const url = await sso.generateRedirectUrl(holdingPreferences, window.location.origin + window.location.pathname, false, localStorage, window.crypto);
      window.location.assign(url);
    } else {
      toast.error('lblErrorInvalidSsoEndpoint');
    }
  };

  const resetSso = () => {
    setIsSsoLoading(false);
    setHasSsoError(false);
  };

  const className = classnames({ error: !!error });
  const errorClassName = classnames('error-text', { 'error-text-custom': !!error && isLargeError });

  let { isRegularLoginEnabled, isSsoEnabled } = holdingPreferences;
  if (!isRegularLoginEnabled && !isSsoEnabled) {
    // fallback for when both booleans are false or the pref call failed
    isRegularLoginEnabled = true;
    isSsoEnabled = false;
  }
  return (
    <DocumentTitle title={translations.getLabel('titleLogin', { holding: holdingPreferences.url })}>
      <div className="login-view">
        <form className="form-container" onSubmit={onLoginUser}>
          <img src={holdingPreferences?.logoUrl || IMAGES.LogoBackground} alt={holdingPreferences?.url} className="holding-logo" />
          <h1>{hasSsoError ? translations.getLabel('lblSomethingWentWrong') : translations.getLabel('titleLogin', { holding: holdingPreferences.url })}</h1>
          {hasSsoError
            ? <>
              <p className="paragraph">{translations.getLabel('lblErrorSsoLogin')}</p>
              <div className="actions">
                <Button onClick={resetSso}>{translations.getLabel('lblClose')}</Button>
              </div>
            </>
            : <>
              {isSsoLoading
                ? <p className="paragraph">{translations.getLabel('lblWaitingLogin')}</p>
                : <>
                  {isRegularLoginEnabled && <>
                    <p className="paragraph">{translations.getLabel('lblLogin')}</p>
                    <label htmlFor="username">{translations.getLabel('lblUsername')}</label>
                    {/* We decided to opt for autofocus here */}
                    {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
                    <input autoFocus type="text" name="username" placeholder={translations.getLabel('lblUsername')} id="username" onChange={onChange} className={className} />
                    <label htmlFor="password">{translations.getLabel('<lblPassword></lblPassword>')}</label>
                    <input type="password" name="password" placeholder={translations.getLabel('lblPassword')} id="password" onChange={onChange} className={className} />
                  </>}

                  {!!error && <p className={errorClassName}>{error}</p>}
                </>}
              <div className="actions">
                {isRegularLoginEnabled && !isSsoLoading && <>
                  <Button type="submit" isLoading={isLoading} disabled={!form.username || !form.password || !!error} onClick={onLoginUser}>{translations.getLabel('btnLogin')}</Button>
                  <Button theme="transparent" onClick={goToForgotPassword}><span className="forgot-password">{translations.getLabel('btnForgotPassword')}</span></Button>
                </>}
                {isSsoEnabled && (<>
                  {isRegularLoginEnabled && !isSsoLoading && <span className="separator">{translations.getLabel('or')}</span>}
                  <Button disabled={isLoading} isLoading={isSsoLoading} onClick={openSso}>{translations.getLabel('btnLoginSso')}</Button>
                </>)}
              </div>
            </>}
        </form>
        <aside style={{ backgroundColor: holdingPreferences.backgroundColor, backgroundImage: `url(${holdingPreferences.webStartpageImageUrl})`, backgroundSize: 'cover' }} />
      </div>
    </DocumentTitle>
  );
};

export default LoginView;
