import { IDateStringRange, IGetSelfRosterScheduleParams } from '../../models';

export const selfRosterQueryKeys = {
  all: ['self-roster'] as const,
  iterations: () => [...selfRosterQueryKeys.all, 'iterations'] as const,
  getIterations: () => [...selfRosterQueryKeys.iterations(), 'get'] as const,
  getDepartmentIterations: (departmentId: string) => [...selfRosterQueryKeys.getIterations(), 'department', departmentId] as const,
  saveIterations: () => [...selfRosterQueryKeys.iterations(), 'save'] as const,
  allSchedule: () => [...selfRosterQueryKeys.all, 'schedule'] as const,
  schedule: (params: IGetSelfRosterScheduleParams) => [...selfRosterQueryKeys.allSchedule(), 'get', params] as const,
  selectedShift: (params: IGetSelfRosterScheduleParams) => [...selfRosterQueryKeys.allSchedule(), 'selected-shifts', params] as const,
  saveSchedule: () => [...selfRosterQueryKeys.allSchedule(), 'save'] as const,
  counters: () => [...selfRosterQueryKeys.all, 'counters'] as const,
  counter: (params: IDateStringRange) => [...selfRosterQueryKeys.counters(), 'get', params] as const,
};