import { Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { exhaustMap, map, catchError, mergeMap } from 'rxjs/operators';

import ActionType from './types';
import { departmentsService } from '../../services';
import {
  GetDepartmentsAction,
  GetDepartmentsFulfilledAction,
  GetDepartmentsRejectedAction,
  GetDepartmentEmployeesAction,
  GetDepartmentEmployeesFulfilledAction,
  GetDepartmentEmployeesRejectedAction,
  GetDepartmentPeriodsAction,
  GetDepartmentPeriodsFulfilledAction,
  GetDepartmentPeriodsRejectedAction,
  UpdateDepartmentPeriodsAction,
  UpdateDepartmentPeriodsFulfilledAction,
  UpdateDepartmentPeriodsRejectedAction,
  GetAbsolutePeriodAction,
  GetAbsolutePeriodFulfilledAction,
  GetAbsolutePeriodRejectedAction,
  ResetDepartmentPeriodFulfilledAction,
  ResetDepartmentPeriodRejectedAction,
  ResetDepartmentPeriodAction,
  GetCompanyDepartmentsFulfilledAction,
  GetCompanyDepartmentsRejectedAction
} from './actions';
import { GetUserPeriodsAction } from '../user/actions';
import * as userSelectors from '../user/selectors';
import { UserRight } from '../../models';

export const getDepartmentsEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetDepartments).pipe(
    mergeMap(({ payload }: GetDepartmentsAction) =>
      from(departmentsService.getMyDepartments(payload.type === UserRight.All ? null : payload.type)).pipe(
        map(data => new GetDepartmentsFulfilledAction({ data, type: payload.type })),
        catchError(error => of(new GetDepartmentsRejectedAction({ error })))
      )
    ),
  );

export const getDepartmentsFulfilledEpic$: Epic = (action$, state$) =>
  action$.ofType(ActionType.GetDepartmentsFulfilled).pipe(
    map(({ payload }: GetDepartmentsFulfilledAction) => {
      let departmentId = state$.value.user?.userDetails?.departmentId;
      if (payload.data.length > 0 && !payload.data.find(department => department.value === departmentId)) {
        departmentId = payload.data[0]?.value;
      }
      return new GetDepartmentPeriodsAction({ id: departmentId });
    })
  );

export const getCompanyDepartmentsEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetCompanyDepartments).pipe(
    mergeMap(() =>
      from(departmentsService.getCompanyDepartments()).pipe(
        map(data => new GetCompanyDepartmentsFulfilledAction({ data })),
        catchError(error => of(new GetCompanyDepartmentsRejectedAction({ error })))
      )
    ),
  );

export const getDepartmentEmployeesEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetDepartmentEmployees).pipe(
    exhaustMap(({ payload }: GetDepartmentEmployeesAction) =>
      from(departmentsService.getEmployeesByDepartment(payload?.search)).pipe(
        map(data => new GetDepartmentEmployeesFulfilledAction({ data })),
        catchError(error => of(new GetDepartmentEmployeesRejectedAction({ error })))
      )
    ),
  );

export const getDepartmentPeriodsEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetDepartmentPeriods).pipe(
    exhaustMap(({ payload }: GetDepartmentPeriodsAction) =>
      from(departmentsService.getPeriods(payload.id)).pipe(
        map(data => new GetDepartmentPeriodsFulfilledAction({ data })),
        catchError(error => of(new GetDepartmentPeriodsRejectedAction({ error })))
      )
    ),
  );

export const getAbsolutePeriodEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetAbsolutePeriod).pipe(
    exhaustMap(({ payload }: GetAbsolutePeriodAction) =>
      from(departmentsService.getAbsolutePeriod(payload.startDate, payload.endDate)).pipe(
        map(data => new GetAbsolutePeriodFulfilledAction({ data })),
        catchError(error => of(new GetAbsolutePeriodRejectedAction({ error })))
      )
    ),
  );

export const updateDepartmentPeriodsEpic$: Epic = action$ =>
  action$.ofType(ActionType.UpdateDepartmentPeriods).pipe(
    exhaustMap(({ payload }: UpdateDepartmentPeriodsAction) =>
      from(departmentsService.updatePeriods(payload.id, payload.periods)).pipe(
        map(() => {
          payload.onSuccess?.();
          return new UpdateDepartmentPeriodsFulfilledAction({ id: payload.id });
        }),
        catchError(error => {
          payload.onError?.(error);
          return of(new UpdateDepartmentPeriodsRejectedAction({ error }));
        })
      )
    ),
  );

export const updateDepartmentPeriodsFulfilledEpic$: Epic = action$ =>
  action$.ofType(ActionType.UpdateDepartmentPeriodsFulfilled).pipe(
    exhaustMap(({ payload }: UpdateDepartmentPeriodsFulfilledAction) =>
      of(
        new GetDepartmentPeriodsAction({ id: payload.id }),
        new GetUserPeriodsAction(),
      )
    ),
  );

export const resetDepartmentPeriodEpic$: Epic = (action$, state$) =>
  action$.ofType(ActionType.ResetDepartmentPeriod).pipe(
    exhaustMap(({ payload }: ResetDepartmentPeriodAction) => {
      const user = userSelectors.getUser(state$.value);
      return from(departmentsService.resetPeriod(user.companyId, payload.type)).pipe(
        map((data) => new ResetDepartmentPeriodFulfilledAction(data)),
        catchError(error => {
          return of(new ResetDepartmentPeriodRejectedAction({ error }));
        })
      );
    }),
  );

const epics = [
  getAbsolutePeriodEpic$,
  getCompanyDepartmentsEpic$,
  getDepartmentEmployeesEpic$,
  getDepartmentPeriodsEpic$,
  getDepartmentsEpic$,
  getDepartmentsFulfilledEpic$,
  resetDepartmentPeriodEpic$,
  updateDepartmentPeriodsEpic$,
  updateDepartmentPeriodsFulfilledEpic$,
];

export default epics;
