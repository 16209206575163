import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Download, ForgotPasswordPage, ResetPasswordPage } from '../scenes';
import { EmploymentsPage, LoginPage, NotificationHandler } from '../scenes/auth/Auth.container';

const UnauthorizedLayout = () => {
  return (
    <div className="unauth-layout" id="main">
      <section className="unauth-action-view">
        <Switch>
          <Route path="/auth/login" exact component={LoginPage} />
          <Route path="/auth/forgot-password" component={ForgotPasswordPage} />
          <Route path="/auth/reset-password" component={ResetPasswordPage} />
          <Route path="/auth/employments" component={EmploymentsPage} />
          <Route path="/auth/notification" component={NotificationHandler} />
          <Route path="/auth/download" component={Download} />
          <Redirect to="/auth/login" />
        </Switch>
      </section>
    </div>
  );
};

export default UnauthorizedLayout;
