import React, { FC, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { useToggle } from '../../../../hooks';
import { IDateRange, IShift, TimeScale } from '../../../../models';
import { scheduleSelectors } from '../../../../redux';
import { date } from '../../../../utils';
import { OpenShiftFunction } from '../../schedule.models';
import DetailPopup from '../detailPopup/DetailPopup';
import EmployeeColumn from '../employeeColumn/EmployeeColumn';
import Footer from './Footer';
import Shift from './Shift';

import './dayRoster.scss';

interface Props {
  selectedDate: Date;
  userId: string;
  forcePrevNextDay?: boolean;
  isCollapsed: boolean;
  hasNoSearchResults?: boolean;
  hasSearch: boolean;
  ignoreSearch?: boolean;
  highlightRange?: IDateRange;
  onCollapse: (employeeId: string) => void;
}

const DayRoster: FC<Props> = ({ selectedDate, userId, isCollapsed, onCollapse, hasSearch, ignoreSearch, hasNoSearchResults }) => {
  const planningArray = useSelector((state: AppState) => scheduleSelectors.getPlanningForUser(state, userId, selectedDate, selectedDate, ignoreSearch));
  const hasError = useSelector(scheduleSelectors.hasPlanningError);
  const [isPopupOpen, setIsPopupOpen] = useToggle(false);
  const [openShift, setOpenShift] = useState<IShift>(null);
  const [shiftRef, setShiftRef] = useState<HTMLElement>(null);
  const scrollRef = useRef(null);

  const isLoading = !planningArray.length;

  const toggleShift: OpenShiftFunction = () => {
    // TODO: Disabled for now, question is asked what to do with the popups
  };

  const closeShift = () => {
    setIsPopupOpen(false);
    setOpenShift(null);
    setShiftRef(null);
  };

  const planning = planningArray[0]; // Day roster -> So we only need first argument

  const getCollapsedLabel = (shifts: IShift[]) => {
    if (shifts.length === 1) {
      return shifts[0].shiftName;
    }
    const labels = shifts.slice(0, 2).map((shift) => shift.shiftName);
    if (shifts.length > 2) labels.push(`+${shifts.length - 2}`);
    return labels.join(', ');
  };

  return (
    <div className="day-roster">
      <DetailPopup isOpen={isPopupOpen} planning={{ ...planning, shifts: [openShift] }} scrollRef={scrollRef.current} parentRef={shiftRef} close={closeShift} />
      <EmployeeColumn timeScale={TimeScale.Day} isLoading={isLoading && !hasError} hasNoSearchResults={hasNoSearchResults} planning={planning} userId={userId} onCollapse={onCollapse} hasSearch={hasSearch} selectedDate={selectedDate} isCollapsed={isCollapsed} />
      <section ref={scrollRef} className="schedule-shifts">
        {hasSearch && <div className="schedule-shifts-header" />}
        <div className="schedule-shifts-content">
          {!isLoading && (isCollapsed ? (
            <button className={classnames('stripped-button shift collapsed', { clickable: planning?.shifts.length })} onClick={() => planning?.shifts.length ? onCollapse(userId) : null}>
              <h3><span>{getCollapsedLabel(planning.shifts)}</span><span className="timestamp">{date.formatFromUntilTime(date.format, { startDate: date.parseDate(planning.shifts[0]?.startDate), endDate: date.parseDate(planning.shifts[planning.shifts?.length - 1]?.endDate) })}</span></h3>
            </button>
          ) : (
            planning.shifts.map((shift, i) => <Shift openShift={toggleShift} key={i} shift={shift} isCollapsed={isCollapsed} />
            )))}
        </div>
        <div className="schedule-shifts-footer">
          <Footer currentDate={selectedDate} />
        </div>
      </section>
    </div>
  );
};

export default DayRoster;
