import React from 'react';

import { INewsItem } from '../../models';
import { translations } from '../../utils';
import NewsItem from '../shared/newsItem/NewsItem.container';

import './NewsList.scss';

type Props = {
  isLoading: boolean;
  limit?: number;
  newsItems: INewsItem[];
}

interface State {
  isInitialLoading: boolean;
}

class NewsList extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isInitialLoading: true, // necessary to prevent unmounting of this component when deleting item from dashboard
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isLoading && !this.props.isLoading) this.setState({ isInitialLoading: false }); // if the news items were loading, but now they're all loaded
  }

  getSplitNewsItems = () => {
    //https://stackoverflow.com/questions/8119941/split-an-array-into-two-arrays-based-on-odd-even-position
    const oddNewsItems = [],
      evenNewsItems = [],
      amountOfLoops = this.props.limit > 0 ? Math.min(this.props.newsItems.length, this.props.limit) : this.props.newsItems.length;
    for (let i = 0; i < amountOfLoops; i++) {
      if ((i + 2) % 2 === 0) {
        oddNewsItems.push(this.props.newsItems[i]);
      } else {
        evenNewsItems.push(this.props.newsItems[i]);
      }
    }
    return ({ oddNewsItems, evenNewsItems });
  }

  renderContent = (splitNewsItems) => {
    if (this.props.newsItems.length === 0) {
      return (<p className="empty-text"> {translations.getLabel('lblNoNews')}</p>);
    } else {
      return (
        <div className="columns-container">
          <div className="column">
            {splitNewsItems.oddNewsItems.map(this.renderNewsItem)}
          </div>
          <div className="column">
            {splitNewsItems.evenNewsItems.map(this.renderNewsItem)}
          </div>
        </div>
      );
    }
  }

  renderNewsItem = (newsItem: INewsItem, i: number) => {
    return <NewsItem isLoading={!newsItem} newsItem={newsItem} key={`${newsItem?.title}-${i}`} />;
  }

  renderSkeletons = () => {
    return (<div className="columns-container">
      <div className="column">
        {[0, 1].map((i) => this.renderNewsItem(null, i))}
      </div>
      <div className="column">
        {[2, 3].map((i) => this.renderNewsItem(null, i))}
      </div>
    </div>);
  }

  render() {
    const splitNewsItems = this.getSplitNewsItems();
    return (
      <div className="news-items-content">
        {
          (this.props.isLoading && this.state.isInitialLoading) ? this.renderSkeletons() :
            this.renderContent(splitNewsItems)
        }
      </div>
    );
  }
}

export default NewsList;
