import { isSameDay, startOfWeek, parseISO, startOfMonth, endOfMonth, addWeeks, subMonths, differenceInWeeks, subWeeks, isAfter, subDays } from 'date-fns';
import { ICounterOverviewData, IPayPeriod, ICounterDropdownValue, PeriodType, WeekStartsOn, ICounterValue } from '../models';
import { FormatFunction } from './date';

export const groupCounterOverViews = (counterOverviews: ICounterOverviewData[], weekStartsOn: WeekStartsOn): ICounterOverviewData[][] => counterOverviews.reduce((weeks, overview) => {
  if (isSameDay(startOfWeek(parseISO(overview.day), { weekStartsOn }), parseISO(overview.day))) weeks.push([]);
  if (!overview.initialPlanning?.find(planning => planning.shifts?.length > 0) && !overview.currentPlanning?.find(planning => planning.shifts?.length > 0) && !overview.counters.find(counter => counter.value.hours !== 0 || counter.value.minutes !== 0)) return weeks;
  weeks[weeks.length - 1].push(overview);
  return weeks;
}, [[]]);

export const getDateRange = (payPeriod: IPayPeriod, format: FormatFunction, start?: Date): ICounterDropdownValue => {
  let initialDate: Date;
  let endDate: string;
  let label: string;
  if (payPeriod?.type === PeriodType.CalendarMonth) {
    initialDate = startOfMonth(start || new Date());
    endDate = format(endOfMonth(initialDate), 'yyyy-MM-dd');
    label = format(initialDate, 'MMMM yyyy');
  }
  if (payPeriod?.type === PeriodType.FourWeekRange) {
    initialDate = start || parseISO(payPeriod?.start);
    const calculatedEndDate = subDays(addWeeks(initialDate, 4), 1);
    endDate = format(calculatedEndDate, 'yyyy-MM-dd');
    label = `${format(initialDate, 'd MMMM yyyy')} - ${format(calculatedEndDate, 'd MMMM yyyy')}`;
  }
  const startDate = format(initialDate, 'yyyy-MM-dd');
  return { value: startDate, startDate, endDate, label };
};

export const sumReducer = (sum: ICounterValue, overview: ICounterOverviewData, i: number): ICounterValue => (
  {
    hours: overview.counters[i]?.value.hours + sum.hours,
    minutes: overview.counters[i]?.value.minutes + sum.minutes
  }
);

export const getValues = (payPeriod: IPayPeriod, format: FormatFunction) => {
  if (!payPeriod) return [];

  const values = [];

  const startDate = payPeriod?.type === PeriodType.FourWeekRange ? parseISO(payPeriod.start) : new Date();
  if (payPeriod?.type === PeriodType.CalendarMonth) {
    for (let i = 0; i <= 11; i++) {
      values.push(getDateRange(payPeriod, format, subMonths(startDate, i)));
    }
  } else {
    const today = new Date();
    const weekDiff = Math.floor(differenceInWeeks(today, startDate) / 4);

    const endDate = addWeeks(startDate, weekDiff * 4);

    for (let i = 0; i <= 11; i++) {
      const date = subWeeks(endDate, i * 4);
      if (isAfter(date, startDate) || isSameDay(startDate, date)) values.push(getDateRange(payPeriod, format, date));
    }
  }
  return values.slice(0, 12);
};

