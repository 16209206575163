import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { userActions, userSelectors } from '../../redux';
import { notificationUtils } from '../../utils';

const BrowserNotifications = () => {
  const preferences = useSelector(userSelectors.getNotificationPreferences);
  const userId = useSelector(userSelectors.getUserId);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId && !isChecked) {
      dispatch(new userActions.GetNotificationPreferencesAction());
    }
  }, [userId]);

  if (preferences?.length && userId && !isChecked) {
    notificationUtils.checkBrowserNotifications(preferences).catch(() => {
      // Do nothing, we only have to check this here
    });
    setIsChecked(true);
  }

  return null;
};

export default BrowserNotifications;
