import React, { PureComponent } from 'react';
import { Avatar, Icon, Spinner } from 'react-ess-components';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { chatService } from '../../../../../../shared/services';
import { date, translations } from '../../../../utils';

import './chatMessage.scss';

class ChatMessage extends PureComponent {
  getParticipant = () => {
    return this.props.participants?.find(participant => participant.employmentId === this.props.chatMessage.senderId) || {};
  }

  getAvatar = () => {
    const participant = this.getParticipant();
    // Don't show avatar if the message is from yourself
    if (participant.id === this.props.userId) return null;
    return <Avatar medium firstName={participant.firstName} lastName={participant.lastName} source={participant.profilePictureUrl} />;
  }

  onClick = () => {
    const { storageId, isLoading } = this.props.chatMessage.attachment;
    if (isLoading) return null;
    const url = `/v1/chat/conversation/${this.props.conversationId}/attachment/${encodeURIComponent(storageId)}/download`;
    chatService.getAttachment(url)
      .then(res => {
        window.open(res.data.downloadUrl);
      })
      .catch(() => {
        toast.error(translations.getLabel('lblErrorFileOpen'));
      });
  };

  render() {
    const participant = this.getParticipant();
    const isOwnMessage = participant.id === this.props.userId;
    const className = classNames('chat-message', {
      'chat-own-message': isOwnMessage,
      'chat-status-message': this.props.chatMessage.isStatusMessage,
    });
    if (this.props.chatMessage.isStatusMessage) {
      return <div className="chat-status-message">{this.props.chatMessage.statusMessage}</div>;
    }
    const { attachment } = this.props.chatMessage;
    return (
      <div className={className}>
        {this.getAvatar()}
        {attachment &&
          <div className="chat-bubble">
            <button onClick={this.onClick} className="stripped-button clickable">
              <span>{attachment.isLoading ? <Spinner /> : <Icon tag="DocumentIcon" color={isOwnMessage ? 'white' : 'text'} />}</span>
              <span className="attachment-name">{attachment.name}</span>
            </button>
            <span className="chat-bubble-time">
              {date.format(this.props.chatMessage.createdAt, 'HH:mm')}
            </span>
          </div>}
        {!attachment && <div className="chat-bubble">{this.props.chatMessage.message} <span className="chat-bubble-time">{date.format(this.props.chatMessage.createdAt, 'HH:mm')}</span> </div>}
      </div>
    );
  }
}

ChatMessage.propTypes = {
  conversationId: PropTypes.string.isRequired,
  participants: PropTypes.array.isRequired,
  chatMessage: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
};

export default ChatMessage;
