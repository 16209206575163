import produce from 'immer';
import { Actions } from './actions';
import ActionType from './types';
import { ShiftSwapStatus, IShift, UpdateApplicantStatus, RequestStatus } from '../../models';
import { IDayShiftSwap, IShiftSwaps } from '../../models/shiftSwap';
import { eachDayOfInterval, parseISO } from 'date-fns';
import { formatDateForSchedule } from '../../utils/schedule';

export interface AdjustedShiftTimeState {
  showAdjustedShiftTimeModal: boolean;
  shift: IShift;
  isCancelRequestLoading?: boolean;
  isCreateRequestLoading?: boolean;
  isRequestLoading?: boolean;
}

export const initialState: AdjustedShiftTimeState = {
  showAdjustedShiftTimeModal: false,
  shift: null,
  isCancelRequestLoading: false,
  isCreateRequestLoading: false,
  isRequestLoading: false,
};

const adjustedShiftTime = produce((draft: AdjustedShiftTimeState, action: Actions) => {
  switch (action.type) {
    case ActionType.ToggleAdjustedShiftTimeModal:
      if (action.payload) {
        draft.shift = action.payload.shift;
      } else {
        draft.shift = null;
      }
      draft.showAdjustedShiftTimeModal = !draft.showAdjustedShiftTimeModal;
      break;

    case ActionType.CreateAdjustedShiftTimeRequest:
      draft.isCreateRequestLoading = true;
      break;

    case ActionType.CreateAdjustedShiftTimeRequestFulfilled:
      draft.isCreateRequestLoading = false;
      break;

    case ActionType.CreateAdjustedShiftTimeRequestRejected:
      draft.isCreateRequestLoading = false;
      break;

    case ActionType.CancelAdjustedShiftTimeRequest:
      draft.isCancelRequestLoading = true;
      break;

    case ActionType.CancelAdjustedShiftTimeRequestFulfilled:
      draft.isCancelRequestLoading = false;

      break;

    case ActionType.CancelAdjustedShiftTimeRequestRejected:
      draft.isCancelRequestLoading = false;
      break;
  }
}, initialState);

export default adjustedShiftTime;