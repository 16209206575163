import React from 'react';

import { routes } from '../../constants';
import { Module, UserRight } from '../../models';
import ShiftOfferInbox from './components/shiftOfferManagerInbox/ManagerInbox';
import ShiftSwapInbox from './components/shiftSwapManagerInbox/ManagerInbox';
import ShiftTimeInbox from './components/shiftTimeManagerInbox/ManagerInbox';
import TransactionRequestInbox from './components/transactionEmployeeInbox/RequestInbox';
import TransactionInbox from './components/transactionManagerInbox/ManagerInbox';
import VacationRequestInbox from './components/vacationEmployeeInbox/RequestInbox';
import VacationManagerInbox from './components/vacationManagerInbox/ManagerInbox';


export const personalInboxTabStructure: ITabStructure[] = [
  {
    url: routes.scheduleRequests.inbox,
    label: 'titleVacation',
    component: VacationRequestInbox,
    count: 'unhandledCountPersonal',
  },
  {
    url: routes.scheduleRequests.transactionInbox,
    label: 'titleTransactions',
    component: TransactionRequestInbox,
    count: 'unhandledCountPersonalTransactions',
    moduleCheck: Module.Counters,
  },
];

export const departmentInboxTabStructure: ITabStructure[] = [
  {
    url: routes.scheduleRequests.managerInbox,
    label: 'titleVacation',
    component: VacationManagerInbox,
    count: 'unhandledCountVacationRequests',
    restriction: UserRight.VacationRequests,
  },
  {
    url: routes.scheduleRequests.shiftOfferManagerInbox,
    label: 'titleShiftOffers',
    component: ShiftOfferInbox,
    count: 'unhandledCountShiftOffers',
    restriction: UserRight.ShiftOffer,
    moduleCheck: Module.ShiftOffer,
  },
  {
    url: routes.scheduleRequests.shiftSwapManagerInbox,
    label: 'titleShiftSwap',
    component: ShiftSwapInbox,
    count: 'unhandledCountShiftSwaps',
    restriction: UserRight.ShiftSwap,
    moduleCheck: Module.ShiftSwap,
  },
  {
    url: routes.scheduleRequests.transactionManagerInbox,
    label: 'titleTransactions',
    component: TransactionInbox,
    count: 'unhandledCountTransactions',
    restriction: UserRight.Transactions,
    moduleCheck: Module.Counters,
  },
  {
    url: routes.scheduleRequests.shiftTimeManagerInbox,
    label: 'titleShiftTimes',
    component: ShiftTimeInbox,
    count: 'unhandledCountShiftTimeAdjustments',
    restriction: UserRight.AdjustedShiftTimes,
  },
];

export interface ITabStructure {
  url: string[];
  label: string;
  component: React.ComponentType;
  count?: string;
  restriction?: UserRight;
  moduleCheck?: Module;
}
