import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { eachDayOfInterval } from 'date-fns';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { StorageItem } from '../../../../constants';
import { useToggle } from '../../../../hooks';
import { IDayShiftOffer, TimeScale } from '../../../../models';
import { configSelectors, shiftOfferActions, shiftOfferSelectors, userSelectors } from '../../../../redux';
import { date, scheduleUtils } from '../../../../utils';
import { OpenShiftOfferFunction } from '../../schedule.models';
import DetailPopup from '../detailPopup/DetailPopup';
import DayHeader from '../weekSchedule/DayHeader';
import RosterColumn from './RosterColumn';
import ShiftOfferDay from './ShiftOfferDay';

import './ShiftOfferRoster.scss';

interface Props {
  selectedDate: Date;
  departmentId: string;
  highlightShiftOfferId?: string;
}

const ShiftOfferRoster: FC<Props> = ({ selectedDate, departmentId, highlightShiftOfferId }) => {
  const dispatch = useDispatch();
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const userId = useSelector(userSelectors.getUserId);
  const { startDate, endDate } = scheduleUtils.getBoundsOfRange(TimeScale.Week, selectedDate, false, weekStartsOn);
  const shiftOffers = useSelector((state: AppState) => shiftOfferSelectors.getShiftOffers(state, startDate, endDate));
  const [openShiftOffer, setOpenShiftOffer] = useState<IDayShiftOffer>(null);
  const rosterRef = useRef(null);
  const [isCollapsed, toggleIsCollapsed] = useToggle(localStorage.getItem(`${StorageItem.IsShiftOffersHidden}_${userId}`) === 'true');
  const [isPopupOpen, setIsPopupOpen] = useToggle(false);
  const [dayRef, setDayRef] = useState<HTMLElement>(null);

  const closeShift = () => {
    setIsPopupOpen(false);
    setOpenShiftOffer(null);
    setDayRef(null);
  };


  const toggleShiftOffer: OpenShiftOfferFunction = (shiftOffer, ref) => {
    if (isPopupOpen && ref === dayRef) {
      closeShift();
    } else {
      setIsPopupOpen(true);
      setOpenShiftOffer(shiftOffer);
      setDayRef(ref);
    }
  };

  // Hook used to update the open shift offer when a user has applied/withdrawn from it
  useEffect(() => {
    if (openShiftOffer) {
      shiftOffers.forEach(dayShiftOffers => dayShiftOffers.forEach(shiftOffer => {
        if (shiftOffer.id === openShiftOffer.id) setOpenShiftOffer(shiftOffer);
      }));
    }
  }, [shiftOffers, openShiftOffer]);

  // Get new data on change of date
  useEffect(() => {
    const formattedStartDate = date.format(startDate, 'yyyy-MM-dd');
    const formattedEndDate = date.format(endDate, 'yyyy-MM-dd');
    dispatch(new shiftOfferActions.GetShiftOffersAction({ startDate: formattedStartDate, endDate: formattedEndDate, departmentId }));
  }, [selectedDate]);

  const toggleRoster = () => {
    localStorage.setItem(`${StorageItem.IsShiftOffersHidden}_${userId}`, `${!isCollapsed}`);
    toggleIsCollapsed();
  };

  return (
    <div className="shift-offer-roster week-roster">
      <DetailPopup hideActions={!!highlightShiftOfferId} isWeek isOpen={isPopupOpen} shiftOffer={openShiftOffer} scrollRef={rosterRef.current} parentRef={dayRef} close={closeShift} />
      <RosterColumn title="lblShiftOffers" isCollapsed={isCollapsed} toggleIsCollapsed={toggleRoster} />
      <section ref={rosterRef} className="schedule-shifts" >
        <div className="schedule-shifts-content week-days" >
          {eachDayOfInterval({ start: startDate, end: endDate }).map((day, i) =>
            <div className="week-day" key={day.toISOString()}>
              <DayHeader day={day} />
              <ShiftOfferDay
                isCollapsed={isCollapsed}
                shiftOffers={shiftOffers[i]}
                highlightShiftOfferId={highlightShiftOfferId}
                openShiftOffer={toggleShiftOffer}
              />
            </div>
          )
          }
        </div>
      </section>
    </div >
  );
};

export default ShiftOfferRoster;
