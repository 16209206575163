import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { isSameDay, parseISO } from 'date-fns';

import { TimeScale } from '../../../../../models';
import { useSelfRosterContext } from '../../../../../modules';
import { configSelectors } from '../../../../../redux';
import { date, scheduleUtils } from '../../../../../utils';
import DayScaffold from '../../../../schedule/components/monthSchedule/DayScaffold';
import Calendar from '../../calendar/Calendar';
import SelfRosterDateSwitch from '../../SelfRosterDateSwitch/SelfRosterDateSwitch';
import SelfRosterShift from '../../SelfRosterShift/SelfRosterShift';

type Props = {
  startDate: string;
  endDate: string;
}
const Round1Schedule: FC<Props> = ({ startDate, endDate }) => {
  const { selectedShifts, isMultiDepartment } = useSelfRosterContext();
  const [selectedDate, setSelectedDate] = useState<Date>(parseISO(startDate));
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const renderCalendarDay = (day: Date, isDifferentMonth: boolean) => {
    const shifts = selectedShifts.filter(shift => isSameDay(parseISO(shift.startDate), day));
    return (
      <div
        key={day.toISOString()}
        className={classNames('day', { inactive: isDifferentMonth })}
      >
        <p className="day-date">{isDifferentMonth ? '' : date.format(day, 'd MMM')}</p>
        {!isDifferentMonth && shifts.map((shift) => <SelfRosterShift readOnly showDepartment={isMultiDepartment} key={shift.uuid} shift={shift} />)}
      </div>
    );
  };

  return (
    <div className="self-roster-month-schedule">
      <SelfRosterDateSwitch
        changeDate={(modifier: number) => setSelectedDate(scheduleUtils.getModifiedDate(TimeScale.Month, selectedDate, modifier, weekStartsOn))}
        selectedDate={selectedDate}
        dateRange={TimeScale.Month}
        minDate={startDate}
        maxDate={endDate}
      />
      <div className="days"><DayScaffold selectedDate={parseISO(startDate)} weekStartsOn={weekStartsOn} /></div>
      {<Calendar selectedDate={selectedDate} renderDay={renderCalendarDay} />}
    </div>
  );
};

export default Round1Schedule;
