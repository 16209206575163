import React from 'react';
import classnames from 'classnames';
import { isSameDay } from 'date-fns';
import PropTypes from 'prop-types';

import { date } from '../../../../utils';

class DragDropDay extends React.PureComponent {
  constructor(props) {
    super(props);
    this.dropRef = React.createRef();

    this.state = {
      isDragging: false,
      isDragHover: false,
      isDropped: false,
    };
  }


  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isDropped: false });
  }

  handleDragStart = (e) => {
    e.dataTransfer.setData('text/plain', JSON.stringify(this.props.data));
    e.dataTransfer.effectAllowed = 'copy';
    this.props.onDragStart();
    this.setState({ isDragging: true });
  }

  handleDragStop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onDragStop();
    this.setState({ isDragging: false, isDragHover: false });
  }

  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!this.props.disabled) {
      this.setState({ isDragHover: true });
    }
  }

  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!this.props.disabled) {
      this.setState({ isDragHover: false });
    }
  }

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const data = JSON.parse(e.dataTransfer.getData('text/plain'));
    if (!this.props.disabled && !isSameDay(date.parseDate(this.props.data.day), date.parseDate(data.day))) {
      this.setState({ isDragging: false, isDragHover: false, isDropped: true });
      this.props.onDrop(data.day, this.props.data.day);
    }
  }

  componentDidMount() {
    const div = this.dropRef.current;
    if (div) {
      div.addEventListener('dragstart', this.handleDragStart);
      div.addEventListener('dragend', this.handleDragStop);
      div.addEventListener('dragenter', this.handleDragIn);
      div.addEventListener('dragleave', this.handleDragOut);
      div.addEventListener('dragover', this.handleDrag);
      div.addEventListener('drop', this.handleDrop);
    }
  }

  componentWillUnmount() {
    const div = this.dropRef.current;
    if (div) {
      div.removeEventListener('dragstart', this.handleDragStart);
      div.removeEventListener('dragend', this.handleDragStop);
      div.removeEventListener('dragenter', this.handleDragIn);
      div.removeEventListener('dragleave', this.handleDragOut);
      div.removeEventListener('dragover', this.handleDrag);
      div.removeEventListener('drop', this.handleDrop);
    }
  }

  render() {
    const className = classnames('day', {
      'drag-hover': this.state.isDragHover,
      'drag-dropped': this.state.isDropped,
      'dragging': this.state.isDragging,
    });
    return (
      <div className={className} ref={this.dropRef} draggable="true">
        {this.props.children}
      </div>
    );
  }
}

DragDropDay.propTypes = {
  handleDrop: PropTypes.func,
  disabled: PropTypes.bool,
  data: PropTypes.object.isRequired,
  children: PropTypes.any,
  onDragStart: PropTypes.func,
  onDragStop: PropTypes.func,
  onDrop: PropTypes.func,
};

const noop = () => { };
DragDropDay.defaultProps = {
  disabled: false,
  handleDrop: noop,
  onDragStart: noop,
  onDragStop: noop,
  onDrop: noop,
  children: null,
};

export default DragDropDay;
