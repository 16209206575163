import { useQuery, UseQueryOptions } from 'react-query';

import { HttpClient } from '../../http';
import { IServerError } from '../../http/HttpError';
import { IGetSelfRosterScheduleParams, ISelfRosteringPlanning } from '../../models';
import { selfRosterQueryKeys } from './queryKeys';
import { useSelfRosterContext } from './SelfRosterContext';

const getSchedule = async (params: IGetSelfRosterScheduleParams): Promise<ISelfRosteringPlanning[]> => {
  const result = await HttpClient.get<{ data: ISelfRosteringPlanning[] }>('/v1/self-roster/shifts', { ...params });
  return result.data;
};

export const useSelfRosterSchedule = (params: IGetSelfRosterScheduleParams) => {
  const { setIsMultiDepartment } = useSelfRosterContext();
  // Only trigger when start date is in params
  return useQuery<ISelfRosteringPlanning[], IServerError>(
    selfRosterQueryKeys.schedule(params),
    () => getSchedule(params),
    {
      enabled: !!params.startDate,
      onSuccess: (data) => {
        if (data.length > 1) setIsMultiDepartment(true);
      }
    });
};
