import { createLogger } from 'redux-logger';
import { Action } from 'redux';

// log actions in development mode
export default createLogger({
  collapsed: true,
  diff: true,
  duration: true,
  level: 'info',
  colors: {
    title: (action) => {
      if (action.type.endsWith('PENDING')) return '#9E9E9E';
      if (action.type.endsWith('FULFILLED')) return '#006400';
      if (action.type.endsWith('REJECTED')) return '#B31B1B';
      if (action.type.endsWith('CANCELLED')) return '#ffc233';
      if (action.type.endsWith('AUTOMATICALLY')) return '#ff00fc';
      return '#5E5E9E';
    },
    prevState: () => '#9E9E9E',
    action: () => '#03A9F4',
    nextState: () => '#4CAF50',
    error: () => '#F20404',
  },

  /* global __DEV__ */
  // only log in development mode
  predicate: (_, action) => __DEV__ && !action?.type?.startsWith('GET_UNREAD_'),
});