import React, { FC, useEffect, useState } from 'react';
import { SelectPopup } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../../shared/redux/rootReducer';
import { UserRight } from '../../models';
import { departmentsActions, departmentsSelectors } from '../../redux';
import { translations } from '../../utils';

type Props = {
  isOpen: boolean;
  requestClose: () => void;
  selected: string[];
  onSave: (selected: string[]) => void;
}

const SelectDepartment: FC<Props> = (props) => {
  const [selected, updateSelected] = useState<string[]>(props.selected);
  const departments = useSelector((state: AppState) => departmentsSelectors.getDepartmentsForRight(state, UserRight.News));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(new departmentsActions.GetDepartmentsAction({ type: UserRight.News }));
  }, []);

  useEffect(() => {
    if (props.isOpen) updateSelected(props.selected);
  }, [props.isOpen]);

  const onSelect = (selected: string[]) => updateSelected(selected);

  const onSave = () => props.onSave(selected);

  return (
    <SelectPopup
      data={departments}
      popupOpen={props.isOpen}
      isSearchEnabled={false}
      isMultiple={true}
      isModal={true}
      selected={selected}
      title={translations.getLabel('lblPublishFor')}
      acceptLabel={translations.getLabel('save')}
      cancelLabel={translations.getLabel('cancel')}
      requestClose={props.requestClose}
      onValueSelected={onSelect}
      onSave={onSave} />
  );
};

SelectDepartment.defaultProps = {
  isOpen: false,
};

export default SelectDepartment;
