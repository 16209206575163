import ActionType from './types';
import { Action } from 'redux';

import { IDocument, IEditDocument, IFileUploadRequestParams } from '../../models';
import { IServerError } from '../../http/HttpError';

export class DeleteDocumentAction implements Action<ActionType> {
  readonly type = ActionType.DeleteDocument;
  constructor(public payload: { id: string, onSuccess?: () => void, onError?: (error: IServerError) => void }) { }
}

export class DeleteDocumentActionFulfilled implements Action<ActionType> {
  readonly type = ActionType.DeleteDocumentFulfilled;
}

export class DeleteDocumentActionRejected implements Action<ActionType> {
  readonly type = ActionType.DeleteDocumentRejected;
}

export class GetDocumentsAction implements Action<ActionType> {
  readonly type = ActionType.GetDocuments;
  constructor(public payload?: { query: string }) { }
}

export class GetDocumentsFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetDocumentsFulfilled;
  constructor(public payload: { data: IDocument[] }) { }
}

export class GetDocumentsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetDocumentsRejected;
}

export class UpdateDocumentAction implements Action<ActionType> {
  readonly type = ActionType.UpdateDocument;
  constructor(public payload: {
    id: string,
    data: IEditDocument,
    fileUploadUtil?: IFileUploadRequestParams,
    onError?: (error: IServerError) => void,
    onSuccess?: () => void,
  }) { }
}

export class UpdateDocumentFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.UpdateDocumentFulfilled;
}

export class UpdateDocumentRejectedAction implements Action<ActionType> {
  readonly type = ActionType.UpdateDocumentRejected;
}

export class UploadDocumentAction implements Action<ActionType> {
  readonly type = ActionType.UploadDocument;
  constructor(public payload: { data: IEditDocument, fileUploadUtil?: IFileUploadRequestParams, onError?: (error: IServerError) => void, onSuccess?: () => void }) { }
}

export class UploadDocumentFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.UploadDocumentFulfilled;
}

export class UploadDocumentRejectedAction implements Action<ActionType> {
  readonly type = ActionType.UploadDocumentRejected;
}

export type Actions =
  | DeleteDocumentAction
  | DeleteDocumentActionFulfilled
  | DeleteDocumentActionRejected
  | GetDocumentsAction
  | GetDocumentsFulfilledAction
  | GetDocumentsRejectedAction
  | UpdateDocumentAction
  | UpdateDocumentFulfilledAction
  | UpdateDocumentRejectedAction
  | UploadDocumentAction
  | UploadDocumentFulfilledAction
  | UploadDocumentRejectedAction;



