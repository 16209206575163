import { Action } from 'redux';

import { GetNotificationsResponse } from '../../services/notificationsService';
import ActionType from './types';
import { IServerError } from '../../http/HttpError';

export class GetNotificationsAction implements Action<ActionType> {
  readonly type = ActionType.GetNotifications;
  constructor(public payload?: { onSuccess?: () => void }) { }
}

export class GetNotificationsFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetNotificationsFulfilled;
  constructor(public payload: GetNotificationsResponse) { }
}

export class GetNotificationsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetNotificationsRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class LoadMoreNotificationsAction implements Action<ActionType> {
  readonly type = ActionType.LoadMoreNotifications;
  constructor(public payload: { limit?: number, offset: number }) { }
}

export class LoadMoreNotificationsFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.LoadMoreNotificationsFulfilled;
  constructor(public payload: GetNotificationsResponse) { }
}

export class LoadMoreNotificationsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.LoadMoreNotificationsRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetUnreadCountAction implements Action<ActionType> {
  readonly type = ActionType.GetUnreadCount;
}

export class GetUnreadCountFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetUnreadCountFulfilled;
  constructor(public payload: { amount: number }) { }
}

export class GetUnreadCountRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetUnreadCountRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class MarkAsReadAction implements Action<ActionType> {
  readonly type = ActionType.MarkAsRead;
  constructor(public payload: { id: string }) { }
}

export class MarkAsReadFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.MarkAsReadFulfilled;
  constructor(public payload: { id: string }) { }
}

export class MarkAsReadRejectedAction implements Action<ActionType> {
  readonly type = ActionType.MarkAsReadRejected;
  constructor(public payload: { error: IServerError }) { }
}

export type Actions =
  | GetNotificationsAction
  | GetNotificationsFulfilledAction
  | GetNotificationsRejectedAction
  | LoadMoreNotificationsAction
  | LoadMoreNotificationsFulfilledAction
  | LoadMoreNotificationsRejectedAction
  | GetUnreadCountAction
  | GetUnreadCountFulfilledAction
  | GetUnreadCountRejectedAction
  | MarkAsReadAction
  | MarkAsReadFulfilledAction
  | MarkAsReadRejectedAction;