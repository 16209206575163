export enum ActionType {
  GetNews = 'GET_NEWS',
  GetNewsRejected = 'GET_NEWS_REJECTED',
  GetNewsFulfilled = 'GET_NEWS_FULFILLED',

  LoadMoreNews = 'GET_MORE_NEWS',
  LoadMoreNewsFulfilled = 'GET_MORE_NEWS_FULFILLED',
  LoadMoreNewsRejected = 'GET_MORE_NEWS_REJECTED',

  GetNewsDetail = 'GET_NEWS_DETAILS',
  GetNewsDetailRejected = 'GET_NEWS_DETAILS_REJECTED',
  GetNewsDetailFulfilled = 'GET_NEWS_DETAILS_FULFILLED',

  GetAdminNewsDetail = 'GET_ADMIN_NEWS_DETAILS',
  GetAdminNewsDetailRejected = 'GET_ADMIN_NEWS_DETAILS_REJECTED',
  GetAdminNewsDetailFulfilled = 'GET_ADMIN_NEWS_DETAILS_FULFILLED',

  DeleteNewsItem = 'DELETE_NEWS',
  DeleteNewsItemRejected = 'DELETE_NEWS_REJECTED',
  DeleteNewsItemFulfilled = 'DELETE_NEWS_FULFILLED',

  UpdateNewsItem = 'UPDATE_NEWS_ITEM',
  UpdateNewsItemRejected = 'UPDATE_NEWS_ITEM_REJECTED',
  UpdateNewsItemFulfilled = 'UPDATE_NEWS_ITEM_FULFILLED',
}

export default ActionType;
