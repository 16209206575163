import { useMemo } from 'react';
import { Position } from 'react-ess-components';

export const POPUP_MAX_WIDTH = 300;

export const usePopupPosition = (
  isOpen: boolean,
  parentRef: HTMLElement,
  scrollRef: HTMLElement,
  containerRef: HTMLElement
) => {
  const positions = useMemo(() => {
    const popupStyle = { left: 0, top: 0, transform: 'translate(-100%, -50%)' };

    let popupPosition = Position.Right;

    if (isOpen && parentRef) {
      const { left: containerLeft, right: containerRight } = scrollRef.getBoundingClientRect();
      const { left: windowLeft, top: windowTop } = containerRef?.getBoundingClientRect() || { left: 0 };
      const { left, right, bottom, top } = parentRef.getBoundingClientRect();
      if (left > containerLeft && left > POPUP_MAX_WIDTH) {
        // If the left side is visible, show it on the left of the shift
        popupStyle.left = left - 5 - windowLeft;
      } else if (containerRight - right > POPUP_MAX_WIDTH) {
        // If the right side is visible, and we are above the threshold of 300 (max-width of the popup), show on right side
        popupStyle.left = right + 5 - windowLeft;
        popupStyle.transform = 'translate(0%, -50%)';
        popupPosition = Position.Left;
      } else {
        // else show on left side of the scroll container
        popupStyle.left = containerLeft - 3;
      }

      // popupStyle.top = bottom - ((bottom - top) / 2) + window.scrollY;
      popupStyle.top = bottom - (bottom - top) / 2 - windowTop;
    }
    return { popupPosition, popupStyle };
  }, [isOpen, parentRef, scrollRef, containerRef]);

  return positions;
};
