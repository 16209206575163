import { format } from 'date-fns';

import { HttpClient } from '../http';
import * as date from '../utils/date'; // Direct import to avoid circular dependency 
import { IAvailabilitiesDayList, ITimeType } from '../models';
import { GetAvailabilitiesResponse, GetAvailabilitiesNetworkResponse } from '../models/APIresponses';


export const get = async (startDate: string, endDate: string): Promise<GetAvailabilitiesResponse> => {
  const result = await HttpClient.get<GetAvailabilitiesNetworkResponse>('/v1/schedule/availabilities', { startDate: format(date.parseDate(startDate), 'yyyy-MM-dd'), endDate: format(date.parseDate(endDate), 'yyyy-MM-dd') });
  const { timeTypes, totalCount } = result.meta;
  const data = result.data;
  return {
    timeTypes,
    totalCount,
    data,
  };
};

export const put = async (availabilities: IAvailabilitiesDayList[]): Promise<void> => {
  return HttpClient.put('/v1/schedule/availabilities', availabilities);
};
