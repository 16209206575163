import { HttpClient } from '../http';
import { IEmployment, LoginStatus } from '../models';

export const login = async (username: string, password: string, holding: string): Promise<{ employments: IEmployment[], status: LoginStatus, token: string }> => {
  const result = await HttpClient.post<{ data: { employments: IEmployment[], status: LoginStatus, token: string } }>('/v1/auth/login', { username, password }, { url: holding });
  return result.data;
};

export const loginWithSso = async (token: string, holding: string): Promise<{ employments: IEmployment[], status: LoginStatus, token: string }> => {
  const result = await HttpClient.post<{ data: { employments: IEmployment[], status: LoginStatus, token: string } }>('/v1/auth/login/sso', { token }, { url: holding });
  return result.data;
};

export const logout = async (): Promise<void> => {
  return HttpClient.post('/v1/auth/logout');
};

export const getEmployments = async (): Promise<IEmployment[]> => {
  const result = await HttpClient.get<{ data: IEmployment[] }>('/v1/employments');
  return result.data;
};

export const updateEmployment = async (id: string): Promise<void> => {
  return HttpClient.post(`/v1/employments/${id}/switch`);
};