import { IDateStringRange } from './date';
import { IRequestCounter, IVacationRequest, RequestStatus } from './requests';
import { IDayShiftSwap, ShiftSwapStatus } from './shiftSwap';

export enum TimeScale {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month'
}

export interface IDateRange {
  endDate: Date
  startDate: Date,
}

export interface IDayPlanning {
  date: string;
  employeeFirstName?: string;
  employeeId?: string;
  employmentId?: string; // EmploymentId is the internal ID for a user, employeeId the SPX ID for a user, we prefer to use the empoymentId, but most of the schedule works with employeeId for now
  employeeLastName?: string;
  id: string;
  profilePictureUrl?: string;
  shifts?: IShift[];
  hidden?: boolean; // TODO: Remove this once there shouldn't be hidden employees (when the difference between employeeId and employmentId is fixed)
  vacationRequest?: IVacationRequest;
  shiftSwapRequest?: IDayShiftSwap;
}

export interface IBaseInterval {
  id?: string;
  startDate: string;
  endDate: string;
  timeType: string;
  timeTypeId: string;
}

export interface IInterval extends IBaseInterval {
  color?: string;
  vacationRequested?: boolean;
}

export type IPlanning = Record<string, Record<string, IDayPlanning>>;

export interface IGetScheduleParams {
  id?: string;
  startDate: string;
  endDate: string;
  search?: string;
  departmentId?: string;
}

export interface ISearchPlanning {
  employeeFirstName: string;
  employeeId: string;
  employmentId?: string;
  employeeLastName: string;
  planning: IDayPlanning[];
  profilePictureUrl?: string;
  counterSwapDates?: IDateStringRange[];
  counters?: IRequestCounter[];
}

export interface IBaseShift<T> {
  id: string;
  functionName: string;
  shiftName: string;
  displayName: string;
  intervals: T[];
}

export interface IShift extends IBaseShift<IInterval> {
  department?: string;
  departmentSwitch?: boolean;
  endDate: string;
  offset?: number;
  startDate: string;
  vacationRequestId?: string;
  shiftTimeAdjustmentRequest?: IShiftTimeAdjustmentRequest;
  isAbsenceShift?: boolean;
}

export interface IReducedShift extends IBaseShift<IInterval> {
  department?: string;
  endDate: string;
  offset?: number;
  startDate: string;
}

export interface IShiftTimeAdjustmentRequest {
  id: string
  startDate?: string;
  endDate?: string;
  originalEndDate: string;
  originalStartDate: string;
  reason: string;
  updatedAt?: string;
  status?: string;
  reviewerName?: string;
  reviewerNote?: string
  reviewerUserId: string;
}

export interface IShiftOfferApplicant {
  employeeId: string;
  employmentId: string;
  counters?: IRequestCounter[];
}

export interface IShiftSwapApplicant {
  employeeId: string;
  employmentId: string;
  counterSwapDates: IDateStringRange[]
}