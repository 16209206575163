export enum ActionType {
  ToggleAdjustedShiftTimeModal = 'TOGGLE_ADJUSTED_SHIFT_TIME_MODAL',

  CreateAdjustedShiftTimeRequest = 'CREATE_ADJUSTED_SHIFT_TIME_REQUEST',
  CreateAdjustedShiftTimeRequestRejected = 'CREATE_ADJUSTED_SHIFT_TIME_REQUEST_REJECTED',
  CreateAdjustedShiftTimeRequestFulfilled = 'CREATE_ADJUSTED_SHIFT_TIME_REQUEST_FULFILLED',

  CancelAdjustedShiftTimeRequest = 'CANCEL_ADJUSTED_SHIFT_TIME_REQUEST',
  CancelAdjustedShiftTimeRequestRejected = 'CANCEL_ADJUSTED_SHIFT_TIME_REQUEST_REJECTED',
  CancelAdjustedShiftTimeRequestFulfilled = 'CANCEL_ADJUSTED_SHIFT_TIME_REQUEST_FULFILLED',
}

export default ActionType;