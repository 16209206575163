import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import classnames from 'classnames';
import { isSameDay, parseISO } from 'date-fns';

import { ISelfRosteringShift } from '../../../../models';
import { useSelfRosterContext } from '../../../../modules';
import { selfRosterUtils } from '../../../../utils';
import SelfRosterShift from '../SelfRosterShift/SelfRosterShift';

import './selfRosterDay.scss';

interface Props {
  shifts: ISelfRosteringShift[];
  date: Date;
  isLoading?: boolean;
  isWishSchedule?: boolean;
  showDepartments?: boolean;
  selectedShifts?: ISelfRosteringShift[];
}

const SelfRosterDay: FC<Props> = ({ shifts, isLoading, date, isWishSchedule, showDepartments, selectedShifts }) => {
  const { selectedIteration } = useSelfRosterContext();
  const { isRound2, isRound3 } = selfRosterUtils.getIterationChecks(selectedIteration);

  return (
    <div className={classnames('self-roster-shifts')}>
      {isLoading && !shifts && <Skeleton height={52} />}
      {shifts
        ?.filter((shift) => isSameDay(parseISO(shift.startDate), date))
        .map((shift) =>
          <SelfRosterShift
            disabled={!selectedShifts.some(_shift => _shift.uuid === shift.uuid) && shift.isOverstaffed}
            isLocked={isRound3 || (isWishSchedule && isRound2 && !shift.isOverstaffed)}
            showDepartment={showDepartments}
            key={shift.uuid}
            shift={shift} />)
      }
    </div>
  );
};

export default SelfRosterDay;
