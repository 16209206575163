import React, { FC, useRef } from 'react';
import { Modal, Popup } from 'react-ess-components';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { IAvailability, IDayPlanning, IDayShiftOffer, IDayShiftSwap, ITimeType } from '../../../../../../shared/models';
import { AvailabilityDetail, PlanningDetail, ShiftOfferDetail, ShiftSwapDetail } from '../../../../components';
import { useToggle } from '../../../../hooks';
import { requestsActions, shiftSwapActions } from '../../../../redux';
import { translations } from '../../../../utils';
import { usePopupPosition } from './usePopupPosition';

import './detailPopup.scss';


interface Props {
  isWeek?: boolean;
  isOpen: boolean;
  parentRef?: HTMLElement;
  planning?: IDayPlanning;
  availabilities?: IAvailability[];
  shiftOffer?: IDayShiftOffer;
  shiftSwap?: IDayShiftSwap;
  timeTypes?: ITimeType[];
  scrollRef: HTMLElement;
  close: () => void;
  isMyRoster?: boolean;
  hideActions?: boolean;
}

const DetailPopup: FC<Props> = ({ isWeek, isOpen, parentRef, timeTypes, availabilities, planning, shiftOffer, shiftSwap, scrollRef, close, isMyRoster, hideActions }) => {
  const dispatch = useDispatch();
  const [isVacationPopupOpen, setIsVacationPopupOpen] = useToggle(false);
  const [isShiftSwapPopupOpen, setIsShiftSwapPopupOpen] = useToggle(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef(null);
  const {popupStyle, popupPosition} = usePopupPosition(isOpen, parentRef, scrollRef, containerRef?.current);

  const cancelRequest = () => {
    if (isVacationPopupOpen) {
      dispatch(new requestsActions.DeleteVacationRequestAction(
        {
          id: planning.vacationRequest.id,
          onSuccess: () => toast(translations.getLabel('lblRequestCancelled')),
          onError: (error) => { toast.error(error.detail); },
        }));
      setIsVacationPopupOpen();
    }
    if (isShiftSwapPopupOpen) {
      dispatch(new shiftSwapActions.CancelShiftSwapRequestAction({
        id: planning.shiftSwapRequest.id,
        date: planning.date,
        onSuccess: () => toast(translations.getLabel('lblShiftSwapCancelled')),
        onError: (error) => toast.error(error.detail),
      }));
      setIsShiftSwapPopupOpen();
    }
    close();
  };

  const closePopup = () => {
    if (isVacationPopupOpen) setIsVacationPopupOpen();
    if (isShiftSwapPopupOpen) setIsShiftSwapPopupOpen();
  };
  const openDeleteModal = (): (state?: boolean) => void => {
    if (planning.shiftSwapRequest) return setIsShiftSwapPopupOpen;
    if (planning.vacationRequest) return setIsVacationPopupOpen;
    return () => null;
  };

  return (
    <>
      <div className="roster-popup-container" ref={containerRef}>
        <Popup open={isOpen} requestClose={close} style={popupStyle} position={popupPosition} excludePressArea={[popupRef.current, parentRef]}>
          {isOpen && planning && <PlanningDetail isWeek={isWeek} closeParentPopup={close} isMyRoster={isMyRoster} planning={planning} toggleDeleteModal={openDeleteModal()} hideActions={hideActions} />}
          {isOpen && availabilities && <AvailabilityDetail availabilities={availabilities} timeTypes={timeTypes} />}
          {isOpen && shiftOffer && <ShiftOfferDetail shiftOffer={shiftOffer} hideActions={hideActions} />}
          {isOpen && shiftSwap && <ShiftSwapDetail shiftSwap={shiftSwap} hideActions={hideActions} />}
        </Popup>
      </div>
      <div ref={popupRef}>
        <Modal
          title={translations.getLabel('lblCancelRequest')}
          requestClose={closePopup}
          leftButtonProps={{
            label: translations.getLabel('btnBack'),
            onClick: closePopup,
          }}
          rightButtonProps={{
            label: translations.getLabel('btnCancelRequest'),
            isLoading: false,
            onClick: cancelRequest,
          }}
          open={isVacationPopupOpen || isShiftSwapPopupOpen}>
          <p className="modal-confirm-content">{translations.getLabel('lblConfirmCancelRequest')}</p>
        </Modal>
      </div>
    </>
  );
};

export default DetailPopup;
