import { Action } from 'redux';

import ActionType from './types';
import { IServerError } from '../../http/HttpError';
import { Transactiontype } from '../../models';

export class CreateTransactionAction implements Action<ActionType> {
  readonly type = ActionType.CreateTransaction;
  constructor(public payload?: {
    date: string;
    type: string;
    value: number;
    reason?: string;
    onSuccess?: () => void;
    onError?: (error: IServerError) => void;
  }) { }
}

export class CreateTransactionFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.CreateTransactionFulfilled;
}

export class CreateTransactionRejectedAction implements Action<ActionType> {
  readonly type = ActionType.CreateTransactionRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetTransactionTypesAction implements Action<ActionType> {
  readonly type = ActionType.GetTransactionTypes;
}

export class GetTransactionTypesFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetTransactionTypesFulfilled;
  constructor(public payload: { data: Transactiontype[] }) { }
}

export class GetTransactionTypesRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetTransactionTypesRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetTransactionValueAction implements Action<ActionType> {
  readonly type = ActionType.GetTransactionValue;
  constructor(public payload?: {
    transactionId: string,
    date: string,
  }) { }
}

export class GetTransactionValueFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetTransactionValueFulfilled;
  constructor(public payload: { data: Transactiontype }) { }
}

export class GetTransactionValueRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetTransactionValueRejected;
  constructor(public payload: { error: IServerError }) { }
}


export type Actions =
  | CreateTransactionAction
  | CreateTransactionFulfilledAction
  | CreateTransactionRejectedAction
  | GetTransactionTypesAction
  | GetTransactionTypesFulfilledAction
  | GetTransactionTypesRejectedAction
  | GetTransactionValueAction
  | GetTransactionValueFulfilledAction
  | GetTransactionValueRejectedAction;
