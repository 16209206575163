
import { initialState as availabilitiesInitialState } from './availabilities/reducer';
import { initialState as chatInitialState } from './chat/reducer';
import { initialState as configInitialState } from './config/reducer';
import { initialState as countersInitialState } from './counters/reducer';
import { initialState as departmentsInitialState } from './departments/reducer';
import { initialState as documentsInitialState } from './documents/reducer';
import { initialState as employeesInitialState } from './employees/reducer';
import { initialState as forgotPasswordInitialState } from './forgotPassword/reducer';
import { initialState as loginInitialState } from './login/reducer';
import { initialState as newsInitialState } from './news/reducer';
import { initialState as notificationsInitialState } from './notifications/reducer';
import { initialState as requestsInitialState } from './requests/reducer';
import { initialState as scheduleInitialState } from './schedule/reducer';
import { initialState as shiftOfferInitialState } from './shiftOffer/reducer';
import { initialState as shiftSwapInitialState } from './shiftSwap/reducer';
import { initialState as timeRegistrationState } from './timeRegistration/reducer';
import { initialState as toasterInitialState } from './toaster/reducer';
import { initialState as transactionsInitialState } from './transactions/reducer';
import { initialState as adjustedShiftTimeInitialState } from './adjustedShiftTimes/reducer';
import { initialState as userInitialState } from './user/reducer';

import { AppState } from './rootReducer';

const initialReduxStore: AppState = {
  availabilities: availabilitiesInitialState,
  chat: chatInitialState,
  config: configInitialState,
  counters: countersInitialState,
  departments: departmentsInitialState,
  documents: documentsInitialState,
  employees: employeesInitialState,
  forgotPassword: forgotPasswordInitialState,
  login: loginInitialState,
  news: newsInitialState,
  notifications: notificationsInitialState,
  requests: requestsInitialState,
  schedule: scheduleInitialState,
  shiftOffer: shiftOfferInitialState,
  shiftSwap: shiftSwapInitialState,
  timeRegistration: timeRegistrationState,
  toaster: toasterInitialState,
  user: userInitialState,
  transactions: transactionsInitialState,
  adjustedShiftTime: adjustedShiftTimeInitialState
};

export default initialReduxStore;