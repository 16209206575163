export enum ActionType {
  GetConversations = 'GET_CONVERSATIONS',
  GetConversationsRejected = 'GET_CONVERSATIONS_REJECTED',
  GetConversationsFulfilled = 'GET_CONVERSATIONS_FULFILLED',

  CreateConversation = 'CREATE_CONVERSATION',
  CreateConversationRejected = 'CREATE_CONVERSATION_REJECTED',
  CreateConversationFulfilled = 'CREATE_CONVERSATION_FULFILLED',

  LeaveConversation = 'LEAVE_CONVERSATION',
  LeaveConversationRejected = 'LEAVE_CONVERSATION_REJECTED',
  LeaveConversationFulfilled = 'LEAVE_CONVERSATION_FULFILLED',

  AddMessageToConversation = 'ADD_MESSAGE',

  GetPresignedUrl = 'GET_PRESIGNED_URL',
  GetPresignedUrlRejected = 'GET_PRESIGNED_URL_REJECTED',
  GetPresignedUrlFulfilled = 'GET_PRESIGNED_URL_FULFILLED',

  GetUnreadCount = 'GET_UNREAD_CHAT_MESSAGES',
  GetUnreadCountRejected = 'GET_UNREAD_CHAT_MESSAGES_REJECTED',
  GetUnreadCountFulfilled = 'GET_UNREAD_CHAT_MESSAGES_FULFILLED',

  MarkAsRead = 'MARK_CHAT_AS_READ',
  MarkAsReadRejected = 'MARK_CHAT_AS_READ_REJECTED',
  MarkAsReadFulfilled = 'MARK_CHAT_AS_READ_FULFILLED',

  MarkAsUnread = 'MARK_CHAT_AS_UNREAD',

  GetParticipants = 'GET_PARTICIPANTS',
  GetParticipantsRejected = 'GET_PARTICIPANTS_REJECTED',
  GetParticipantsFulfilled = 'GET_PARTICIPANTS_FULFILLED',
}

export default ActionType;