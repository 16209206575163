import { isBefore, isAfter, subDays, isSameDay, addDays, parseISO } from 'date-fns';
import { IVacationRequestDTO, IPeriodDetail, IPeriodValidationParams, RequestStatus, IRelativeFormattedDate, ShiftOfferApplicantStatus } from '../models';
import * as date from './date';
import colors from './colors';

export const isVacationRequestValid = (vacationRequest: IVacationRequestDTO, requestPeriod: IPeriodDetail): boolean => {
  const { startDate, endDate, vacationTypeId } = vacationRequest;
  return !!startDate && !!endDate && !!vacationTypeId && isValidRequest({ requestPeriod, forDate: date.parseDate(startDate) }) && isValidRequest({ requestPeriod, forDate: date.parseDate(endDate) });
};


export const isValidRequest = (params: IPeriodValidationParams): boolean => {
  const today = new Date();
  let { requestPeriod, isPeriodCheck, forDate } = params;
  if (!forDate) forDate = today;

  // is requestPeriod an empty object?
  if (!requestPeriod?.startDate || !requestPeriod?.endDate) return false;

  const endDate = parseISO(requestPeriod.endDate);
  const startDate = parseISO(requestPeriod.startDate);
  const { daysBefore } = requestPeriod;

  // if the day for which you submit the request is before the day at which you do (i.e. improper function use)
  if (isBefore(forDate, today)) return false;

  if (isPeriodCheck) { // if it's a check to see whether the period is open for requests, not an actual request
    // if the day at which you submit the request is after the latest allowed day
    if (isAfter(today, subDays(endDate, daysBefore - 1))) return false;
  } else { // if it's a request for a specific date
    // if the day at which you submit the request is after the latest allowed day
    if (isAfter(today, subDays(endDate, daysBefore - 1))) return false;

    // if you're past the end date of the request period
    if (isAfter(forDate, endDate) && !isSameDay(forDate, endDate)) return false;

    // if the difference between the moment at which you submit the request and the date for which you want it to apply does not respect the daysBefore margin
    if (isBefore(forDate, addDays(today, daysBefore - 1))) return false;

    // the day for which you want to submit a request, needs to be between or on start and end.
    if (isBefore(forDate, startDate) && !isSameDay(forDate, startDate)) return false;
  }

  return true;
};

export const isValidAdjustment = (params: IPeriodValidationParams): boolean => {
  let { requestPeriod, forDate, isAbsenceShift } = params;
  if (!requestPeriod?.startDate || !requestPeriod?.endDate) return false;
  
  const endDate = parseISO(requestPeriod.endDate);
  const startDate = parseISO(requestPeriod.startDate);

  if (isAbsenceShift) return false;
  if (isSameDay(forDate, endDate)) return false;
  if (isAfter(forDate, endDate)) return false;
  if (isBefore(forDate, startDate)) return false;

  return true;
};

export const getRequestStatus = (status: string, translationFunction: (string: string) => string, asManager = false): string => {
  switch (status) {
    case RequestStatus.Accepted:
      return translationFunction('lblAccepted');
    case RequestStatus.Pending:
      return translationFunction('lblNeedsResponse');
    case RequestStatus.Open:
      return translationFunction(asManager ? 'lblNeedsResponse' : 'lblOpen');
    case RequestStatus.Denied:
      return translationFunction('lblDenied');
    default:
      return '';
  }
};

export const getShiftOfferRequestStatus = (status: RequestStatus, translationFunction: (string: string, replacements?: Record<string, string>) => string, numberOfApplicants?: number,): string => {
  switch (status) {
    case RequestStatus.Open:
      return translationFunction('lblShiftOfferNumberOfApplicants', { amount: String(numberOfApplicants) });
    case RequestStatus.Completed:
      return translationFunction('lblShiftOfferCompleted');
    case RequestStatus.Cancelled:
      return translationFunction('lblCancelled');
    default:
      return '';
  }
};

export const getApplicantStatus = (status: ShiftOfferApplicantStatus, translationFunction: (string: string, replacements?: Record<string, string>) => string): string => {
  switch (status) {
    case ShiftOfferApplicantStatus.Accepted:
      return translationFunction('lblAccepted');
    case ShiftOfferApplicantStatus.Pending:
      return translationFunction('lblPending');
    case ShiftOfferApplicantStatus.Denied:
      return translationFunction('lblDenied');
    case ShiftOfferApplicantStatus.RuleViolated:
      return translationFunction('lblRuleViolated');
    default:
      return '';
  }
};

export const getShiftOfferApplicantStatusColor = (status: ShiftOfferApplicantStatus,): string => {
  switch (status) {
    case ShiftOfferApplicantStatus.Accepted:
      return colors.success;
    case ShiftOfferApplicantStatus.Pending:
      return colors.primaryDark;
    case ShiftOfferApplicantStatus.Denied:
    case ShiftOfferApplicantStatus.RuleViolated:
      return colors.error;
    default:
      return '';
  }
};

export const getColorByRequestStatus = (status: string): string => {
  switch (status) {
    case RequestStatus.Completed:
    case RequestStatus.Accepted:
      return colors.success;
    case RequestStatus.Pending:
    case RequestStatus.Open:
      return colors.primaryDark;
    case RequestStatus.Cancelled:
    case RequestStatus.Denied:
      return colors.error;
    default:
      return '';
  }
};

export const getRelativeDate = (value: string, formatFunction: (date: Date | string, formatStr: string) => string): IRelativeFormattedDate => {
  return date.toRelativeDate(formatFunction, parseISO(value), 'eee dd LLL');
};

export const isRequestChanged = (requestPeriod: IPeriodDetail, previousRequestPeriod: IPeriodDetail): boolean => {
  if (!previousRequestPeriod && !requestPeriod) return false;
  if (previousRequestPeriod && !requestPeriod) return true;
  if (!previousRequestPeriod && requestPeriod) return true;
  if (previousRequestPeriod.startDate !== requestPeriod.startDate) return true;
  if (previousRequestPeriod.endDate !== requestPeriod.endDate) return true;
  if (previousRequestPeriod.daysBefore !== requestPeriod.daysBefore) return true;
  return false;
};