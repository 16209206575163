import { useState, useCallback, useMemo } from 'react';

const useInput = (initial) => {
  const [value, setValue] = useState(initial);
  const onChange = useCallback(e => setValue(e.target.value), []);
  const onChangeText = useCallback(e => setValue(e), []);
  const clear = useCallback(() => setValue(''), []);

  return useMemo(
    () => ({
      value,
      setValue,
      hasValue: value !== undefined && value !== null && value.trim() !== '',
      clear,
      onChange,
      onChangeText,
      bindToInput: {
        onChange,
        value,
      },
      bindToInputApp: {
        onChangeText,
        value,
      },
    }),
    [clear, onChangeText, onChange, value],
  );
};

export default useInput;