import { Component } from 'react';
import PropTypes from 'prop-types';
import { isToday } from 'date-fns';

import { date } from '../../utils';

// For the chat to work properly, this should be a component, PureComponent will mess with the update cycle
class ChatItem extends Component {
  getTimeLabel = (format, translations) => {
    let timestamp = date.parseDate(this.props.conversation.messages[this.props.conversation.messages.length - 1] && this.props.conversation.messages[this.props.conversation.messages.length - 1].createdAt);
    const relativeDate = date.toRelativeDate(format, timestamp, 'dd MMM');
    if (relativeDate.shouldTranslate) {
      if (isToday(timestamp)) return relativeDate.time;
      return translations.getLabel(relativeDate.value);
    }
    return relativeDate.value;
  }

  getLastMessage = () => {
    const { conversation } = this.props;
    if (!conversation.messages[this.props.conversation.messages.length - 1]) return '';
    if (conversation.messages[this.props.conversation.messages.length - 1].message) return conversation.messages[this.props.conversation.messages.length - 1].message;
    if (conversation.messages[this.props.conversation.messages.length - 1].attachment) return `📎 ${conversation.messages[this.props.conversation.messages.length - 1].attachment.name}`;
  }

  getParticipant = () => {
    const { participants } = this.props.conversation;
    if (participants && participants.length === 2) {
      return participants.find(participant => participant.id !== this.props.userId);
    }
    return {};
  }

  // Cover web and RN
  onClick = () => this.props.onClick(this.props.conversation.id);
  onPress = () => this.props.onPress(this.props.conversation.id, this.props.conversation.title);

  render() {
    return null;
  }
}

ChatItem.propTypes = {
  conversation: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onPress: PropTypes.func,
};

const noop = () => { };
ChatItem.defaultProps = {
  onClick: noop,
  onPress: noop,
};


export default ChatItem;

