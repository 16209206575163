import React, { FC, Fragment, useEffect } from 'react';
import { Button, Icon } from 'react-ess-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { eachDayOfInterval, isSameDay, parseISO } from 'date-fns';

import { routes } from '../../../../constants';
import { useSelectArray } from '../../../../hooks';
import { ISelfRosteringIteration, ISelfRosteringRoster, TimeScale } from '../../../../models';
import { useSelfRosterContext, useSelfRosterSchedule, useSelfRosterSelectedShifts } from '../../../../modules';
import { configSelectors } from '../../../../redux';
import { date, scheduleUtils, selfRosterUtils, translations } from '../../../../utils';
import DayHeader from '../../../schedule/components/weekSchedule/DayHeader';
import SelfRosterDateSwitch from '../SelfRosterDateSwitch/SelfRosterDateSwitch';
import SelfRosterDay from '../SelfRosterDay/SelfRosterDay';
import SelfRosterVacationDay from '../SelfRosterDay/SelfRosterVacationDay';

type Props = {
  iteration: ISelfRosteringIteration;
}
const SelfRosterSchedule: FC<Props> = ({ iteration }) => {
  const history = useHistory();
  const { isMultiDepartment, selectedDate, selectedShifts, setSelectedDate, selectedIteration } = useSelfRosterContext();
  const { selectArray: collapsedDepartments, select: toggleDepartment } = useSelectArray([]);
  const { data: schedules, isLoading } = useSelfRosterSchedule({ startDate: iteration?.startDate, endDate: iteration.endDate, iterationId: iteration?.id });
  const { data: originalSelectedShifts, isLoading: isSelectedShiftsLoading } = useSelfRosterSelectedShifts({ startDate: iteration?.startDate, endDate: iteration?.endDate, iterationId: iteration?.id });
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const { isRound1, isRound2, isRound3 } = selfRosterUtils.getIterationChecks(selectedIteration);
  useEffect(() => {
    if (iteration) {
      setSelectedDate(parseISO(iteration?.startDate));
    }
  }, [iteration]);

  // This effect is ONLY used to make every vacation shift the same height
  useEffect(() => {
    const everySchedule = document.getElementsByClassName('week-days');
    for (let i = 0; i < everySchedule.length; i++) {
      const elements = everySchedule[i].getElementsByClassName('self-roster-vacation-day');
      const allHeights = [];
      // Reset all the heights
      for (let j = 0; j < elements.length; j++) {
        (elements[j] as HTMLDivElement).style.height = 'auto';
      }
      // Calculate all the heights
      for (let j = 0; j < elements.length; j++) {
        allHeights.push(elements[j].clientHeight);
      }
      // Apply the maximum height to every vacation div
      const maxHeight = Math.max(...allHeights, 0);
      for (let j = 0; j < elements.length; j++) {
        (elements[j] as HTMLDivElement).style.height = maxHeight + 'px';
      }
    }
  }, [selectedDate, schedules, collapsedDepartments]);

  if (!iteration || !selectedDate) return null;

  const { startDate, endDate } = scheduleUtils.getBoundsOfRange(TimeScale.Week, selectedDate, false, weekStartsOn);
  const weekInterval = eachDayOfInterval({ start: startDate, end: endDate });

  const renderSchedule = (schedule?: ISelfRosteringRoster[], isLoading?: boolean, isWishSchedule?: boolean, departmentId?: string) => {
    return (
      <div key={departmentId} className="self-rostering-roster week-roster">
        <div className="schedule-shifts-content week-days">
          {weekInterval.map((day) => {
            const roster = schedule?.find(roster => isSameDay(parseISO(roster.date), day));
            const selectedShiftsThisDay = selectedShifts?.filter(shift => isSameDay(parseISO(shift.startDate), day));
            return (
              <div className="week-day" key={day.toISOString()}>
                <DayHeader day={day} />
                <SelfRosterVacationDay vacation={roster?.vacation} />
                <SelfRosterDay
                  showDepartments={isMultiDepartment && isWishSchedule}
                  isWishSchedule={isWishSchedule}
                  date={day}
                  isLoading={isLoading}
                  shifts={roster?.shifts}
                  selectedShifts={selectedShiftsThisDay}
                />
              </div>
            );
          })
          }
        </div>
      </div>
    );
  };

  const changeSelectedDate = (modifier: number) => {
    if (isRound2) {
      // In round2 we change the date in the URL to pop the unsaved changes popup every week change
      history.replace(`${routes.scheduleSelfRoster[0]}/${selectedIteration.id}/${date.format(scheduleUtils.getModifiedDate(TimeScale.Week, selectedDate, modifier, weekStartsOn), 'yyyy-MM-dd')}`);
    } else {
      setSelectedDate(scheduleUtils.getModifiedDate(TimeScale.Week, selectedDate, modifier, weekStartsOn));
    }
  };

  return (
    <>
      <div className="self-rostering-header">
        <h4>{translations.getLabel(isRound1 ? 'lblNecessaryShifts' : 'lblWishShifts')}</h4>
        <SelfRosterDateSwitch
          dateRange={TimeScale.Week}
          selectedDate={selectedDate}
          minDate={iteration?.startDate}
          maxDate={iteration?.endDate}
          changeDate={changeSelectedDate}
        />
        <h4 aria-hidden="true" className="invisible">{translations.getLabel('lblNecessaryShifts')}</h4>{/*  Just an invisble header to align the switch correct */}
      </div>

      {(isRound2 || isRound3) && renderSchedule(originalSelectedShifts, isSelectedShiftsLoading, true)}

      {isRound2 && <h4>{translations.getLabel('lblAvailableShifts')}</h4>}

      {!isRound3 && schedules?.map(schedule => (
        <Fragment key={schedule.departmentId}>
          {isMultiDepartment && (
            <div className="department-name">
              <Button theme="transparent" onClick={() => toggleDepartment(schedule.departmentId)}>
                {schedule.departmentName} <Icon tag={collapsedDepartments.includes(schedule.departmentId) ? 'ChevronRightIcon' : 'ChevronDownIcon'} color="text" />
              </Button>
            </div>
          )}
          {!collapsedDepartments.includes(schedule.departmentId) && renderSchedule(schedule.roster, isLoading, false, schedule.departmentId)}
        </Fragment>
      ))
      }
      {isLoading && !isRound3 && renderSchedule(null, true)}
    </>
  );
};

export default SelfRosterSchedule;
