import { Action } from 'redux';

import ActionType from './types';
import { ICounter, ICounterGroup, ICounterOverview, ICounterOverviewConfiguration } from '../../models';
import { IServerError } from '../../http/HttpError';

export class GetCountersAction implements Action<ActionType> {
  readonly type = ActionType.GetCounters;
}

export class GetCountersFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetCountersFulfilled;
  constructor(public payload: { data: ICounterGroup[] }) { }
}

export class GetCountersRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetCountersRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetDashboardCountersAction implements Action<ActionType> {
  readonly type = ActionType.GetDashboardCounters;
  constructor(public payload?: { onSuccess?: () => void, onError?: (error: IServerError) => void }) { }
}

export class GetDashboardCountersFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetDashboardCountersFulfilled;
  constructor(public payload: { data: ICounter[] }) { }
}

export class GetDashboardCountersRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetDashboardCountersRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetVacationCountersAction implements Action<ActionType> {
  readonly type = ActionType.GetVacationCounters;
  constructor(public payload?: { onSuccess?: () => void, onError?: (error?: IServerError) => void }) { }
}

export class GetVacationCountersFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetVacationCountersFulfilled;
  constructor(public payload: { data: ICounter[] }) { }
}

export class GetVacationCountersRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetVacationCountersRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetCounterOverviewAction implements Action<ActionType> {
  readonly type = ActionType.GetCounterOverview;
  constructor(public payload: { id: string, startDate: string, endDate: string }) { }
}

export class GetCounterOverviewFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetCounterOverviewFulfilled;
  constructor(public payload: ICounterOverview) { }
}

export class GetCounterOverviewRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetCounterOverviewRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetCounterOverviewConfigurationAction implements Action<ActionType> {
  readonly type = ActionType.GetCounterOverviewConfiguration;
}

export class GetCounterOverviewConfigurationFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetCounterOverviewConfigurationFulfilled;
  constructor(public payload: { data: ICounterOverviewConfiguration[] }) { }
}

export class GetCounterOverviewConfigurationRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetCounterOverviewConfigurationRejected;
  constructor(public payload: { error: IServerError }) { }
}

export type Actions =
  | GetCountersAction
  | GetCountersFulfilledAction
  | GetCountersRejectedAction
  | GetDashboardCountersAction
  | GetDashboardCountersFulfilledAction
  | GetDashboardCountersRejectedAction
  | GetVacationCountersAction
  | GetVacationCountersFulfilledAction
  | GetVacationCountersRejectedAction
  | GetCounterOverviewAction
  | GetCounterOverviewFulfilledAction
  | GetCounterOverviewRejectedAction
  | GetCounterOverviewConfigurationAction
  | GetCounterOverviewConfigurationFulfilledAction
  | GetCounterOverviewConfigurationRejectedAction;