export const errorCodes = {
  requests: {
    value: 'TOO_MANY_REQUESTS',
    label: ''
  },
  uploadAttachment: {
    value: 'UPLOAD_FAILURE',
    label: 'lblNewsItemAttachmentUploadError'
  },
};
