export enum ActionType {
  GetCounters = 'GET_COUNTERS',
  GetCountersRejected = 'GET_COUNTERS_REJECTED',
  GetCountersFulfilled = 'GET_COUNTERS_FULFILLED',
  GetDashboardCounters = 'GET_DASHBOARD_COUNTERS',
  GetDashboardCountersRejected = 'GET_DASHBOARD_COUNTERS_REJECTED',
  GetDashboardCountersFulfilled = 'GET_DASHBOARD_COUNTERS_FULFILLED',
  GetVacationCounters = 'GET_VACATION_COUNTER',
  GetVacationCountersRejected = 'GET_VACATION_COUNTER_REJECTED',
  GetVacationCountersFulfilled = 'GET_VACATION_COUNTER_FULFILLED',
  GetCounterOverview = 'GET_COUNTER_OVERVIEW',
  GetCounterOverviewRejected = 'GET_COUNTER_OVERVIEW_REJECTED',
  GetCounterOverviewFulfilled = 'GET_COUNTER_OVERVIEW_FULFILLED',
  GetCounterOverviewConfiguration = 'GET_COUNTER_OVERVIEW_CONFIGURATION',
  GetCounterOverviewConfigurationRejected = 'GET_COUNTER_OVERVIEW_CONFIGURATION_REJECTED',
  GetCounterOverviewConfigurationFulfilled = 'GET_COUNTER_OVERVIEW_CONFIGURATION_FULFILLED',
}

export default ActionType;
