import React, { FC, useEffect, useRef, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { Button } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../../shared/redux/rootReducer';
import { Clock, ClockInButton } from '../../components';
import { identifiers, routes } from '../../constants';
import { IInternalTimeRegistration } from '../../models';
import { scheduleSelectors, timeRegistrationActions, timeRegistrationSelectors } from '../../redux';
import { date, timeRegistration, translations } from '../../utils';

import './clockIn.scss';

const ClockIn: FC = () => {
  const dispatch = useDispatch();
  const errorId = useSelector(timeRegistrationSelectors.errorId);
  const timeRegistrationsToday = useSelector(timeRegistrationSelectors.activeTimeRegistrations);
  const timeRegistrationTypes = useSelector(timeRegistrationSelectors.timeRegistrationTypes);
  const isLoading = useSelector(timeRegistrationSelectors.isLoading);
  const planning = useSelector((state: AppState) => scheduleSelectors.getPlanningForUser(state, identifiers.me, new Date(), new Date()));

  const [availableTypes, setAvailableTypes] = useState([]);
  const [clocks, setClocks] = useState([]);
  const clockingsRef = useRef(clocks);
  clockingsRef.current = clocks;

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (errorId) {
      getData();
    }
  }, [errorId]);

  useEffect(() => {
    if (timeRegistrationsToday?.length) {
      const apiClocks = timeRegistrationsToday.map((registration) => {
        const timeRegistration = timeRegistrationTypes.find(registrationType => registrationType.id === registration.typeId);
        return {
          id: registration.id,
          isClockedIn: true,
          seconds: registration.secondsElapsed,
          typeId: registration.typeId,
          name: timeRegistration?.description,
          isPauze: timeRegistration?.isPauze,
          controleEnd: registration.controlEnd,
        };
      });

      setClocks(checkAddEmpty(apiClocks));
    } else {
      setClocks(checkAddEmpty([]));
    }
  }, [timeRegistrationsToday]);

  const getShift = () => {
    const shift = planning?.[0]?.shifts?.[0];

    if (shift) return `${date.format(shift.startDate, 'HH:mm')}${translations.getLabel('lblH')} - ${date.format(shift.endDate, 'HH:mm')}${translations.getLabel('lblH')} ${shift.shiftName}`;
  };


  const checkAddEmpty = (newClocks: IInternalTimeRegistration[]) => {
    const availableTypes = timeRegistration.getAvailableTimeTypes(newClocks, timeRegistrationTypes);
    setAvailableTypes(availableTypes);

    if (!newClocks.find(clock => !clock.isClockedIn)) {
      newClocks.push({ id: `newClock-${new Date().getTime()}`, isClockedIn: false, seconds: 0 });
    }
    return newClocks;
  };

  const getData = () => {
    dispatch(new timeRegistrationActions.GetActiveTimeRegistrationsAndTypesAction());
  };

  const cleanupClockings = () => {
    // use a ref here, so we can call it in a setTimeout
    setClocks(clockingsRef.current.filter(clocking => !clocking.isRemoving));
  };

  const startClockIn = (id: string, type: string) => {
    const currentType = timeRegistrationTypes.find(registrationType => registrationType.id === type);
    const newClockings = clocks.reduce((accu, clocking) => {
      // remove previous running clocking
      if (clocking.id === clocks[1]?.id && clocking.isClockedIn) {
        setTimeout(cleanupClockings, 2000);
        return [...accu, { ...clocking, isRemoving: true }];
      }
      if (clocking.id === id) {
        return [...accu, {
          ...clocking,
          name: currentType?.description,
          typeId: currentType?.id,
          isClockedIn: true,
          isPauze: currentType.isPauze,
          controleEnd: currentType.controlEnd,
        }];
      }
      return [...accu, clocking];
    }, []);

    setClocks(checkAddEmpty(newClockings));
  };

  const onClockedOut = (id: string) => {
    const newClocks = clocks.map((clocking) => {
      if (clocking.id === id) {
        return { ...clocking, isRemoving: true };
      }
      if (id === clocks[0].id && clocks[1]?.isClockedIn && clocking.id === clocks[1]?.id) {
        return { ...clocking, isRemoving: true };
      }
      return clocking;
    });
    setTimeout(cleanupClockings, 2000);

    setClocks(checkAddEmpty(newClocks));
  };

  return (
    <DocumentTitle title={translations.getLabel('titleClockIn')}>
      <div className="page fixed-width app-clock-in">
        <header>
          <h2>{translations.getLabel('titleClockIn')}</h2>
          <Button theme="transparent" ahref={routes.timeRegistration}>{translations.getLabel('btnSeeAllTimeRegistrations')}</Button>
        </header>
        <div className="user-clock-info">
          <Clock />
          <p>{translations.getLabel(clocks.length > 1 ? 'lblClockedIn' : 'lblClockedOut')}</p>
          {clocks.length < 1 && <p className="shift-info">{getShift()}</p>}
        </div>

        {!isLoading ? clocks.map((clocking) => <ClockInButton
          key={clocking.id}
          isFirst={clocking.id === clocks[0]?.id}
          isLast={clocking.id === clocks.reduce((id, c) => (c.isClockedIn) ? c.id : id, 0)}
          hidden={clocking.isRemoving}
          onStartClockIn={startClockIn}
          onClockedOut={onClockedOut}
          id={clocking.id}
          isInitClockedIn={clocking.isClockedIn}
          types={availableTypes}
          noTypesLeft={availableTypes.length === 0 && clocks.length > 0}
          typeId={clocking.typeId}
          name={clocking.name}
          initSeconds={clocking.seconds}
        />)
          :
          <div className="skeleton">
            <Skeleton height={60} width={622} />
          </div>
        }
      </div>
    </DocumentTitle>
  );
};

export default ClockIn;
