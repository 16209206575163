import React from 'react';
import { IconButton, ILabelAndValue, InputDateField, InputField, InputType } from 'react-ess-components';
import { endOfDay, parseISO, startOfDay } from 'date-fns';

import { ISelfRosteringIteration, Locale, TIterationNumber } from '../../../../models';
import { date, translations } from '../../../../utils';

interface Props {
  iteration: ISelfRosteringIteration;
  onRemove: (id: string) => void;
  onChange: (round: ISelfRosteringIteration) => void;
}

const iterations: ILabelAndValue[] = [{
  label: translations.getLabel('lblRound', { round: 1 }),
  value: '1',
}, {
  label: translations.getLabel('lblRound', { round: 2 }),
  value: '2',
}, {
  label: translations.getLabel('lblRound', { round: 3 }),
  value: '3',
}];

const SelfRosterEditIteration: React.FC<Props> = ({ iteration, onRemove, onChange }) => {
  return (
    <div className="edit-iteration">
      <IconButton onClick={() => onRemove(iteration.id)} color="error" tag="CrossIcon" title={translations.getLabel('btnRemoveIteration')} />
      <div className="row">
        <div className="input-wrapper">
          <p className="field-title">{translations.getLabel('lblFrom')}</p>
          <InputDateField
            dateFormat="dd/MM/yyyy"
            emptyIcon
            onChange={(value) => onChange({ ...iteration, startDate: date.removeTimezone(startOfDay(value.date)) })}
            value={{ date: parseISO(iteration.startDate) }}
            locale={translations.I18n.locale as Locale}
          />
        </div>
        <div className="input-wrapper">
          <p className="field-title">{translations.getLabel('lblUntil')}</p>
          <InputDateField
            minDate={parseISO(iteration.startDate)}
            dateFormat="dd/MM/yyyy"
            emptyIcon
            onChange={(value) => onChange({ ...iteration, endDate: date.removeTimezone(endOfDay(value.date)) })}
            value={{ date: parseISO(iteration.endDate) }}
            locale={translations.I18n.locale as Locale}
          />
        </div>
      </div>
      <InputField
        emptyIcon
        type={InputType.Select}
        options={iterations}
        value={String(iteration.iteration)}
        onChange={(value) => onChange({ ...iteration, iteration: parseInt(value) as TIterationNumber })}
        placeholder={translations.getLabel('lblPlaceholderIterationSelect')}
      />
    </div>
  );
};

export default SelfRosterEditIteration;
