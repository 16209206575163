import React, { FC, useEffect, useRef, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { Button, Icon, Search } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { AppState } from '../../../../shared/redux/rootReducer';
import { routes } from '../../constants';
import { useToggle } from '../../hooks';
import { IDocument, UserRight } from '../../models';
import { departmentsActions, documentsActions, documentsSelectors, userSelectors } from '../../redux';
import { translations } from '../../utils';
import EditLibraryItem from './components/EditLibraryItem';
import LibraryItem from './components/LibraryItem';

import './library.scss';

const Library: FC<RouteComponentProps<{ id: string }>> = ({ match, history }) => {
  const dispatch = useDispatch();
  const documents = useSelector(documentsSelectors.getAllDocuments);
  const isLoading = useSelector(documentsSelectors.isLoading);
  const hasDocumentsRight = useSelector((state: AppState) => userSelectors.hasRight(state, UserRight.Documents));
  const selectedDocumentRef = useRef(null);
  const [showForm, toggleShowForm] = useToggle(false);
  const [editDocument, setEditDocument] = useState<IDocument>(null);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    dispatch(new documentsActions.GetDocumentsAction());
    dispatch(new departmentsActions.GetDepartmentsAction({ type: UserRight.Documents }));
  }, []);

  const onSearch = (query: string) => {
    setSearchValue(query);
    selectedDocumentRef.current = null;
    history.replace(routes.library[0]);
    dispatch(new documentsActions.GetDocumentsAction({ query }));
  };

  const toggleEditForm = (document: IDocument) => {
    setEditDocument(document);
    toggleShowForm();
  };

  return (
    <DocumentTitle title={translations.getLabel('lblLibrary')}>
      <div className="page fixed-width app-library">
        <h2>{translations.getLabel('lblLibrary')}</h2>
        <div className="container">
          <div className="actions">
            {hasDocumentsRight && <Button onClick={toggleShowForm}><Icon small tag="PlusIcon" color="white" /> &nbsp; {translations.getLabel('add')}</Button>}
            <Search placeholder={translations.getLabel('lblSearchDocument')} submit={onSearch} />
          </div>
          <div>
            {
              (isLoading || documents.length > 0) &&
              <div className="row">
                <div className="col">
                  <h4>{translations.getLabel('lblName')}</h4>
                </div>
                <div className="col">
                  <h4>{translations.getLabel('lblAddedBy')}</h4>
                </div>
                <div className="col">
                  <h4>{translations.getLabel('lblLastModified')}</h4>
                </div>
                <div className="col">
                </div>
              </div>
            }
            {
              (!isLoading && !documents.length && searchValue) &&
              <p className="empty">{translations.getLabel('lblSearchNotFound')}</p>
            }
            {
              (!isLoading && !documents.length && !searchValue) &&
              <p className="empty">{translations.getLabel('lblEmptyLibrary')}</p>
            }
            {
              !isLoading
                ? documents.map((document) => <LibraryItem key={document.id} document={document} selectedId={match.params.id} toggleEditForm={toggleEditForm} />)
                : <><LibraryItem isLoading /><LibraryItem isLoading /><LibraryItem isLoading /></>
            }
          </div>
        </div>
        {showForm && <EditLibraryItem document={editDocument} onClose={() => toggleEditForm(null)} />}
      </div>
    </DocumentTitle>
  );
};

export default Library;
