import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';

import { DevInfo, NavBar, Toast } from '../components';
import { routes } from '../constants';
import { Availabilities, Chat, ClockIn, Counters, DoubleUsage, Home, Library, News, NewsDetails, Notifications, Profile, RequestInboxTabNavigator, Schedule, SelfRoster, ShiftSwap, ShiftSwapCandidate, Tasks, TimeRegistration, UpsertNewsItem } from '../scenes';
import { translations } from '../utils';

import 'react-toastify/dist/ReactToastify.css';

const AuthorizedLayout = (props) => {
  const modules = props.companyConfig?.modules || {};
  const hasTimeRegistration = modules.timeRegistration && props.user?.hasTimeRegistration;

  return (
    <div className="auth-layout">
      <NavBar />
      {/* Yes, the banner is twice here. Once in fixed position, the second, not fixed but hidden, to always have the correct top offset on all the pages */}
      {props.isSpxDown && <div className="error-spx-down"><p>{translations.getLabel('lblErrSPXDown')}</p></div>}
      {props.isSpxDown && <div className="error-spx-down-hidden"><p>{translations.getLabel('lblErrSPXDown')}</p></div>}
      <ToastContainer closeOnClick={false} closeButton={<Toast.CloseButton />} className="toast-container" toastClassName="toast" bodyClassName="toast-body" progressClassName="toast-progress" />
      <div className="app-wrapper" id="main">
        <Switch>
          <Route path={routes.dashboard} exact component={Home} />
          {modules.library && <Route path={routes.library} exact component={Library} />}
          <Route path={routes.profile} exact component={Profile} />
          <Route path={routes.tasks} exact component={Tasks} />
          <Route path={routes.notifications} exact component={Notifications} />
          {hasTimeRegistration && <Route path={routes.timeRegistration} exact component={TimeRegistration} />}
          {hasTimeRegistration && <Route path={routes.clockIn} exact component={ClockIn} />}
          {modules.counters && <Route path={routes.counters} component={Counters} />}
          {modules.schedule && props.isAvailabilityPeriodActive && <Route path={routes.scheduleAvailabilities} exact component={Availabilities} />}
          {modules.schedule && <Route path={routes.scheduleRequests.landingPage} component={RequestInboxTabNavigator} />}
          {modules.schedule && modules.selfRoster && <Route path={routes.scheduleSelfRoster} exact component={SelfRoster} />}
          {modules.timeRegistration && <Route path={routes.doubleUsage} component={DoubleUsage} />}
          {modules.schedule && <Route path={routes.schedule} exact component={Schedule} />}
          <Route path={routes.chat} exact component={Chat} />
          {modules.news && <Route path={routes.news} exact component={News} />}
          {modules.news && <Route path={routes.upsertNewsItem} exact component={UpsertNewsItem} />}
          {modules.news && <Route path={routes.newsDetails} exact component={NewsDetails} />}
          {modules.shiftSwap && <Route path={routes.shiftSwap} exact component={ShiftSwap} />}
          {modules.shiftSwap && <Route path={routes.shiftSwapCandidate} exact component={ShiftSwapCandidate} />}
          {/* Only redirect after modules are loaded, otherwise we'll redirect to dashboard on slow load */}
          {props.companyConfig?.modules && <Redirect to={routes.dashboard} />}
        </Switch>
      </div>
      <DevInfo />
    </div>
  );
};

AuthorizedLayout.propTypes = {
  user: PropTypes.object,
  companyConfig: PropTypes.object,
  isAvailabilityPeriodActive: PropTypes.bool.isRequired,
  isVacationPeriodActive: PropTypes.bool.isRequired,
  isSpxDown: PropTypes.bool.isRequired,
};
AuthorizedLayout.defaultProps = {
  companyConfig: null,
  user: null,
};

export default AuthorizedLayout;
