import React, { useEffect, useState } from 'react';
import { Button, Icon, IconButton, InputDateField, InputField, InputType, Modal } from 'react-ess-components';
import { ILabelAndValue } from 'react-ess-components/build/components/select/Select';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { addDays, addYears, isBefore, isValid, parseISO } from 'date-fns';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { IPeriodDetail, Locale, Period, Tools, UserRight } from '../../../../models';
import { configSelectors, departmentsActions, departmentsSelectors, userSelectors } from '../../../../redux';
import { date, translations } from '../../../../utils';

import './adjustPeriodsForm.scss';

interface Props {
  isOpen: boolean;
  toggleModal(): void;
  type: Tools;
  maxDate?: Date
}

const dateSettings = {
  DAYS: [
    { value: 'FIRST_DAY', label: 'lblFirstDay' },
    { value: 'LAST_DAY', label: 'lblLastDay' },
    { value: 'BEFORE_LAST_DAY', label: 'lblBeforeLastDay' },
    { value: 'CURRENT_DAY', label: 'lblCurrentDay' },
    { value: 'PREVIOUS_DAY', label: 'lblPreviousDay' },
    { value: 'NEXT_DAY', label: 'lblNextDay' },
  ],
  STARTPOINTS: [
    { value: 'BEFORE_LAST', label: 'lblBeforeLast' },
    { value: 'PREVIOUS', label: 'prev' },
    { value: 'CURRENT', label: 'lblCurrent' },
    { value: 'NEXT', label: 'next' },
    { value: 'AFTER_NEXT', label: 'lblAfterNext' },
  ],
  TYPE: [
    { value: 'week', label: 'lblWeek' },
    { value: 'month', label: 'lblMonth' },
    { value: 'year', label: 'lblYear' },
    { value: 'quarter', label: 'lblQuarter' },

  ],
  OFFSET_TYPES: [
    { value: 'DAYS', label: 'lblDays' },
    { value: 'WEEKS', label: 'lblWeeks' },
    { value: 'MONTHS', label: 'lblMonths' },
    { value: 'YEARS', label: 'lblYears' },
  ],
};

const FROM = 'from';
const defaultRelativeDate = {
  days: dateSettings.DAYS[0].value,
  startPoint: dateSettings.STARTPOINTS[0].value,
  type: dateSettings.TYPE[0].value,
  offset: '0',
  offsetType: dateSettings.OFFSET_TYPES[0].value,
};

const AdjustPeriodModal: React.FC<Props> = ({ isOpen, toggleModal, type, maxDate }) => {
  const dispatch = useDispatch();
  const departmentAvailabilityPeriod = useSelector((state: AppState) => departmentsSelectors.getPeriods(state, Period.AvailabilityRequestPeriod));
  const departmentVacationPeriod = useSelector((state: AppState) => departmentsSelectors.getPeriods(state, Period.VacationRequestPeriod));
  const departmentShiftTimeAdjustmentPeriod = useSelector((state: AppState) => departmentsSelectors.getPeriods(state, Period.ShiftTimeAdjustmentPeriod));
  const schedulePeriod = useSelector((state: AppState) => departmentsSelectors.getPeriods(state, Period.ScheduleVisibilityPeriod));
  const isAbsolutePeriodLoading = useSelector(departmentsSelectors.isAbsolutePeriodLoading);
  const isPeriodLoading = useSelector(departmentsSelectors.isPeriodLoading);
  const absolutePeriod = useSelector(departmentsSelectors.getAbsolutePeriod);
  const user = useSelector(userSelectors.getUser);
  const availabilityDepartments = useSelector((state: AppState) => departmentsSelectors.getDepartmentsForRight(state, UserRight.Availabilities));
  const vacationDepartments = useSelector((state: AppState) => departmentsSelectors.getDepartmentsForRight(state, UserRight.VacationRequests));
  const shiftTimeDepartments = useSelector((state: AppState) => departmentsSelectors.getDepartmentsForRight(state, UserRight.AdjustedShiftTimes));
  const allDepartments = useSelector((state: AppState) => departmentsSelectors.getDepartmentsForRight(state, UserRight.All));
  const [departmentId, setDepartmentId] = useState<string>(null);
  const [fromDate, setFromDate] = useState<string>(null);
  const [toDate, setToDate] = useState<string>(null);
  const [isFromVisible, setIsFromVisible] = useState(false);
  const [isToVisible, setIsToVisible] = useState(false);
  const [relativeFrom, setRelativeFrom] = useState(defaultRelativeDate);
  const [relativeTo, setRelativeTo] = useState(defaultRelativeDate);
  const [daysBefore, setDaysBefore] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const companyConfig = useSelector(configSelectors.getCompanyConfig);

  const showDaysInAdvance = () => {
    if (type === Tools.ShiftTimeAdjustment) {
      return false;
    } else if (type === Tools.Schedule) {
      return false;
    }

    return true;
  };

  const isOnlyToVisible = () => {
    if (type === Tools.Schedule) {
      return true;
    }

    return false;
  };

  const isOnlyFromVisible = () => {
    if (type === Tools.ShiftTimeAdjustment) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (isOpen) {
      if (type === Tools.Availabilities) {
        dispatch(new departmentsActions.GetDepartmentsAction({ type: UserRight.Availabilities }));
      } else if (type === Tools.Vacation) {
        dispatch(new departmentsActions.GetDepartmentsAction({ type: UserRight.VacationRequests }));
      } else if (type === Tools.ShiftTimeAdjustment) {
        dispatch(new departmentsActions.GetDepartmentsAction({ type: UserRight.AdjustedShiftTimes }));
      } else {
        dispatch(new departmentsActions.GetDepartmentsAction({ type: UserRight.All }));
      }
    }
  }, [isOpen]);  //eslint-disable-line react-hooks/exhaustive-deps

  const getDepartments = (): ILabelAndValue[] => {
    let departments = [];

    switch (type) {
      case Tools.Availabilities:
        departments = availabilityDepartments || [];
        break;
      case Tools.Vacation:
        departments = vacationDepartments || [];
        break;
      case Tools.ShiftTimeAdjustment:
        departments = shiftTimeDepartments || [];
        break;
      default:
        departments = allDepartments || [];
    }

    return departments;
  };

  useEffect(() => {
    const departments = getDepartments();

    if (departments.length > 0 && !departmentId) {
      setDefaultDepartmentId();
    }
  }, [availabilityDepartments, vacationDepartments]);

  useEffect(() => {
    if (departmentId) {
      dispatch(new departmentsActions.GetDepartmentPeriodsAction({ id: departmentId }));
    }
  }, [departmentId]);

  useEffect(() => {
    if (departmentAvailabilityPeriod || departmentVacationPeriod || schedulePeriod || departmentShiftTimeAdjustmentPeriod) {
      let activePeriod: IPeriodDetail = null;

      switch (type) {
        case Tools.Availabilities:
          activePeriod = departmentAvailabilityPeriod;
          break;
        case Tools.Vacation:
          activePeriod = departmentVacationPeriod;
          break;
        case Tools.Schedule:
          activePeriod = schedulePeriod;
          break;
        case Tools.ShiftOffers:
          activePeriod = schedulePeriod;
          break;
        case Tools.ShiftTimeAdjustment:
          activePeriod = departmentShiftTimeAdjustmentPeriod;
          break;
      }
      getDates(activePeriod?.startDate, activePeriod?.endDate);
      setDaysBefore(activePeriod?.daysBefore?.toString());
    }
  }, [departmentAvailabilityPeriod, departmentVacationPeriod, departmentShiftTimeAdjustmentPeriod, isOpen]);  //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isFromVisible || isToVisible) {
      const newFromDate = fromDate || `${relativeFrom.days}/${relativeFrom.startPoint}/${relativeFrom.type}/${relativeFrom.offset}/${relativeFrom.offsetType}`;
      const newToDate = toDate || `${relativeTo.days}/${relativeTo.startPoint}/${relativeTo.type}/${relativeTo.offset}/${relativeTo.offsetType}`;

      dispatch(new departmentsActions.GetAbsolutePeriodAction({ startDate: newFromDate, endDate: newToDate }));
    }
  }, [isFromVisible, isToVisible, relativeFrom, relativeTo, daysBefore]);  //eslint-disable-line react-hooks/exhaustive-deps

  const setDefaultDepartmentId = () => {
    let departments = [];

    switch (type) {
      case Tools.Availabilities:
        departments = availabilityDepartments || [];
        break;
      case Tools.Vacation:
        departments = vacationDepartments || [];
        break;
      case Tools.ShiftTimeAdjustment:
        departments = shiftTimeDepartments || [];
        break;
      default:
        departments = allDepartments || [];
    }

    if (departments.some(department => department.value === user.departmentId)) {
      setDepartmentId(user.departmentId);
    } else {
      setDepartmentId(departments[0].value);
    }
  };

  const getDates = (fromDate: string | undefined, toDate: string | undefined) => {
    const isFromVisible = fromDate?.includes('/');
    const isToVisible = toDate?.includes('/');
    if (fromDate?.includes('/') || toDate?.includes('/')) {
      const convertedFromDate = isFromVisible ? convertRelativeDate(fromDate) : relativeFrom;
      const convertedToDate = isToVisible ? convertRelativeDate(toDate) : relativeTo;
      setFromDate(isFromVisible ? '' : fromDate);
      setToDate(isToVisible ? '' : toDate);
      setRelativeFrom(convertedFromDate);
      setRelativeTo(convertedToDate);
    } else {
      setFromDate(isValid(parseISO(fromDate)) ? fromDate : null);
      setToDate(isValid(parseISO(toDate)) ? toDate : null);
    }
    setIsFromVisible(isFromVisible);
    setIsToVisible(isToVisible);
  };

  const convertRelativeDate = (convertDate: string) => {
    const relativeValues = convertDate.split('/');
    return {
      days: relativeValues[0],
      startPoint: relativeValues[1],
      type: relativeValues[2],
      offset: relativeValues[3],
      offsetType: relativeValues[4],
    };
  };

  const toggleVisible = (key) => () => {
    if (key === FROM) {
      setFromDate('');
      setIsFromVisible(!isFromVisible);
      setRelativeFrom(defaultRelativeDate);
    } else {
      setToDate('');
      setIsToVisible(!isToVisible);
      setRelativeTo(defaultRelativeDate);
    }
  };

  const handleSelect = (type, key) => (value) => {
    if (type === FROM) {
      setRelativeFrom({ ...relativeFrom, [key]: value });
    } else {
      setRelativeTo({ ...relativeTo, [key]: value });
    }
  };

  const handleSave = (startDate, endDate) => {
    setIsSaving(true);

    let periodType = null;

    switch (type) {
      case Tools.Availabilities:
        periodType = Period.AvailabilityRequestPeriod;
        break;
      case Tools.Vacation:
        periodType = Period.VacationRequestPeriod;
        break;
      case Tools.ShiftTimeAdjustment:
        periodType = Period.ShiftTimeAdjustmentPeriod;
        break;
      case Tools.Schedule:
        periodType = Period.ScheduleVisibilityPeriod;
        break;
      default:
        periodType = null;
    }

    const period = {
      type: periodType,
      daysBefore: parseInt(daysBefore),
      startDate: startDate,
      endDate: endDate,
    };

    dispatch(new departmentsActions.UpdateDepartmentPeriodsAction({
      id: departmentId,
      periods: [
        period,
      ],
      onSuccess: () => {
        toggleModal();
        setIsSaving(false);
        toast(translations.getLabel('lblChangesSavedSuccess'));
      },
      onError: (error) => {
        setIsSaving(false);
        toast.error(error.title);
      },
    }));
  };

  const filterEndDate = (filterDate) => {
    const startDate = fromDate ? new Date(fromDate) : addYears(new Date(), -1);

    const marker = fromDate ? new Date(fromDate) : new Date();
    const daysAdded = addDays(marker, 365);

    return filterDate <= daysAdded && filterDate > startDate;
  };

  const filterStartDate = filterDate => {
    const endDate = toDate ? new Date(toDate) : addYears(new Date(), 1);

    const marker = toDate ? new Date(toDate) : new Date();
    const daySubstracted = addDays(marker, -365);

    return filterDate > daySubstracted && filterDate < endDate;
  };

  const handleButtonDisable = () => {
    const isRelativeFromValid = Object.values(relativeFrom).some(value => value !== '') && !isNaN(parseInt(relativeFrom.offset));
    const isRelativeToValid = Object.values(relativeTo).some(value => value !== '') && !isNaN(parseInt(relativeTo.offset));

    const absoluteStartDate = parseISO(absolutePeriod?.startDate);
    const absoluteEndDate = parseISO(absolutePeriod?.endDate);

    if (isBefore(absoluteEndDate, absoluteStartDate)) return true;
    if (isOnlyToVisible()) {
      if (isToVisible && !isRelativeToValid) return true;
      if (!isToVisible && !toDate) return true;
      return false;
    }
    if (isOnlyFromVisible()) {
      if (isFromVisible && !isRelativeFromValid) return true;
      if (!isFromVisible && !fromDate) return true;
      return false;
    } else {
      if (showDaysInAdvance && fromDate && toDate) {
        return false;
      }
      if (isNaN(parseInt(daysBefore))) {
        return true;
      } else if (!isFromVisible && !isToVisible && fromDate && toDate) {
        return false;
      } else if (isFromVisible && isRelativeFromValid && (toDate || (isToVisible && isRelativeToValid))) {
        return (Object.values(relativeFrom).some(value => value === '') || (toDate === '' && Object.values(relativeTo).some(value => value === '')));
      } else if (isToVisible && isRelativeToValid && (fromDate || (isFromVisible && isRelativeFromValid))) {
        return (Object.values(relativeTo).some(value => value === '') || (fromDate === '' && Object.values(relativeFrom).some(value => value === '')));
      }
    }
    return true;
  };

  const handleTimeInput = (key) => (value) => {
    if (key === FROM) setFromDate(typeof value.date === 'string' ? value.date : value.date.toISOString());
    else setToDate(typeof value.date === 'string' ? value.date : value.date.toISOString());
  };

  const handleSaveModal = () => {
    let newFromDate = fromDate;
    let newToDate = toDate;

    if (isFromVisible) {
      const { days, startPoint, type, offset, offsetType } = relativeFrom;
      newFromDate = `${days}/${startPoint}/${type}/${offset}/${offsetType}`;
    }

    if (isToVisible) {
      const { days, startPoint, type, offset, offsetType } = relativeTo;
      newToDate = `${days}/${startPoint}/${type}/${offset}/${offsetType}`;
    }

    handleSave(newFromDate, newToDate);
  };

  const renderDateSelectors = (type) => {
    return (
      <div className={'dates-column'}>
        <InputField
          disabled={isPeriodLoading}
          onChange={handleSelect(type, 'days')}
          options={renderSelectOptions(dateSettings.DAYS)}
          type={InputType.Select}
          value={type === FROM ? relativeFrom.days : relativeTo.days}
        />
        <InputField
          disabled={isPeriodLoading}
          onChange={handleSelect(type, 'startPoint')}
          options={renderSelectOptions(dateSettings.STARTPOINTS)}
          type={InputType.Select}
          value={type === FROM ? relativeFrom.startPoint : relativeTo.startPoint}
        />
        <InputField
          disabled={isPeriodLoading}
          onChange={handleSelect(type, 'type')}
          options={renderSelectOptions(dateSettings.TYPE)}
          type={InputType.Select}
          value={type === FROM ? relativeFrom.type : relativeTo.type}
        />
        <p>{translations.getLabel('lblOffset')}</p>
        <InputField
          disabled={isPeriodLoading}
          onChange={handleSelect(type, 'offset')}
          type={InputType.Number}
          value={type === FROM ? relativeFrom.offset : relativeTo.offset}
        />
        <InputField
          disabled={isPeriodLoading}
          onChange={handleSelect(type, 'offsetType')}
          options={renderSelectOptions(dateSettings.OFFSET_TYPES)}
          type={InputType.Select}
          value={type === FROM ? relativeFrom.offsetType : relativeTo.offsetType}
        />
      </div >
    );
  };

  const renderSelectOptions = (options) => {
    return options.map(option => {
      return { ...option, label: translations.getLabel(option.label) };
    });
  };

  const reset = () => {
    let period = null;

    if (type === Tools.Schedule) {
      dispatch(new departmentsActions.UpdateDepartmentPeriodsAction({
        id: departmentId,
        periods: [
          departmentVacationPeriod,
          departmentAvailabilityPeriod,
          {
            endDate: companyConfig.scheduleVisibleUntil,
            type: Period.ScheduleVisibilityPeriod,
            daysBefore: 0,
          },
          departmentShiftTimeAdjustmentPeriod,
        ],
      }));
    } else {
      switch (type) {
        case Tools.Availabilities:
          period = Period.AvailabilityRequestPeriod;
          break;
        case Tools.Vacation:
          period = Period.VacationRequestPeriod;
          break;
        case Tools.ShiftTimeAdjustment:
          period = Period.ShiftTimeAdjustmentPeriod;
          break;
      }
      dispatch(new departmentsActions.ResetDepartmentPeriodAction({ id: departmentId, type: period }));
    }
  };

  const getLabel = () => {
    let label = null;

    switch (type) {
      case Tools.Availabilities:
        label = 'lblEditAvailabilitiesPeriod';
        break;
      case Tools.Vacation:
        label = 'lblEditVacationPeriod';
        break;
      case Tools.Schedule:
        label = 'lblEditSchedulePeriod';
        break;
      case Tools.ShiftTimeAdjustment:
        label = 'lblEditShiftTimeAdjustmentPeriod';
        break;
    }

    return label;
  };

  const getTranslation = () => {
    switch (type) {
      case Tools.Availabilities:
        return 'lblAbsolutePeriodAvailability';
      case Tools.Vacation:
        return 'lblAbsolutePeriodVacation';
      case Tools.Schedule:
        return 'lblAbsolutePeriodSchedule';
      case Tools.ShiftTimeAdjustment:
        return 'lblAbsolutePeriodShiftTimeAdjustments';
    }
  };

  return (
    <Modal
      className="adjust-period-form"
      requestClose={toggleModal}
      open={isOpen}
      rightButtonProps={{ isLoading: isSaving, label: translations.getLabel('save'), onClick: handleSaveModal, disabled: handleButtonDisable() }}
      leftButtonProps={{ label: translations.getLabel('cancel'), onClick: toggleModal }}
      title={translations.getLabel(getLabel())}
    >
      <div className="row">
        <InputField
          emptyIcon
          type={InputType.Select}
          options={getDepartments()}
          value={departmentId || ''}
          onChange={setDepartmentId}
          label={translations.getLabel('lblDepartment')}
          placeholder={translations.getLabel('lblPlaceholderDepartmentSelect')}
        />
      </div>

      <div className="date-field">
        {
          !isOnlyToVisible() &&
          <div className="from-column">
            <p className="field-title">{translations.getLabel('lblFrom')}</p>
            <InputDateField
              maxDate={maxDate}
              dateFormat="dd/MM/yyyy"
              disabled={isFromVisible || isPeriodLoading}
              emptyIcon
              filterDate={filterStartDate}
              onChange={handleTimeInput(FROM)}
              value={{ date: fromDate ? parseISO(fromDate) : undefined }}
              locale={translations.I18n.locale as Locale}
            />
            <div className="checkbox-tag">
              <IconButton disabled={isPeriodLoading} color="primary" tag={isFromVisible ? 'CheckedIcon' : 'UncheckedIcon'} onClick={toggleVisible(FROM)} title={translations.getLabel(isFromVisible ? 'lblChecked' : 'lblUnChecked')} />
              <p>{translations.getLabel('lblRelativeDate')}</p>
            </div>
            <div className="date-selector">
              {isFromVisible && renderDateSelectors(FROM)}
            </div>
          </div>
        }
        {
          !isOnlyFromVisible() &&
          <div className="to-column">
            <p className="field-title">{translations.getLabel('lblTo')}</p>
            <InputDateField
              maxDate={maxDate}
              dateFormat="dd/MM/yyyy"
              disabled={isToVisible || isPeriodLoading}
              emptyIcon
              filterDate={filterEndDate}
              onChange={handleTimeInput('')}
              value={{ date: toDate ? parseISO(toDate) : undefined }}
              locale={translations.I18n.locale as Locale}
            />
            <div className="checkbox-tag">
              <IconButton disabled={isPeriodLoading} color="primary" tag={isToVisible ? 'CheckedIcon' : 'UncheckedIcon'} onClick={toggleVisible('to')} title={translations.getLabel(isToVisible ? 'lblChecked' : 'lblUnChecked')} />
              <p>{translations.getLabel('lblRelativeDate')}</p>
            </div>
            <div className="date-selector">
              {isToVisible && renderDateSelectors('to')}
            </div>
          </div>}
      </div>
      {
        (showDaysInAdvance()) &&
        <div className="advanced">
          {translations.getLabel('lblAtLeast')}
          <InputField
            onChange={setDaysBefore}
            type={InputType.Number}
            value={daysBefore}
            maxLength={9}
          />
          {translations.getLabel('lblDaysInAdvance')}
        </div>
      }
      {
        (isFromVisible || isToVisible) &&
        <div className="absolute">
          {
            (absolutePeriod && !isAbsolutePeriodLoading) &&
            <div>
              {
                translations.getLabel(getTranslation(), {
                  startDate: date.format(parseISO(absolutePeriod.startDate), 'dd MMMM yyyy'),
                  endDate: date.format(parseISO(absolutePeriod.endDate), 'dd MMMM yyyy'),
                })
              }
            </div>
          }
          {
            isAbsolutePeriodLoading &&
            <div className="skeleton-container">
              <Skeleton count={2} />
            </div>
          }
        </div>
      }
      <div className="hr" />
      <div className="reset-action">
        <Button onClick={reset} theme="transparent">
          <Icon tag="ResetIcon" color="error" />
          {translations.getLabel('lblResetPeriod')}
        </Button>
      </div>
    </Modal>
  );
};

export default AdjustPeriodModal;
