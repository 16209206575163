import { useState, useEffect } from 'react';
import { IStorage, Period, IUser, IPeriodDetail } from '../models';
import { isAfter, parseISO } from 'date-fns';

const STORAGE_KEY = 'close-date';

const useDashboardNotification = (storage: IStorage, user: IUser, vacationPeriod: IPeriodDetail, availabilityPeriod: IPeriodDetail): [IPeriodDetail[], () => void, () => Promise<void>] => {
  const [dashboardNotifications, setDashboardNotifications] = useState<IPeriodDetail[]>([]);
  useEffect(() => {
    setNotifications();
  }, [availabilityPeriod, vacationPeriod]);

  const setNotifications = async () => {
    const availabilityCloseDate = await storage.getItem(`${STORAGE_KEY}-${Period.AvailabilityRequestPeriod}-${user?.departmentId}-${user?.id}`);
    const vacationCloseDate = await storage.getItem(`${STORAGE_KEY}-${Period.VacationRequestPeriod}-${user?.departmentId}-${user?.id}`);
    const openNotifications = [];
    if (vacationPeriod?.updatedAt && (!vacationCloseDate || isAfter(parseISO(vacationPeriod.updatedAt), parseISO(vacationCloseDate)))) {
      openNotifications.push(vacationPeriod);
    }
    if (availabilityPeriod?.updatedAt && (!availabilityCloseDate || isAfter(parseISO(availabilityPeriod.updatedAt), parseISO(availabilityCloseDate)))) {
      openNotifications.push(availabilityPeriod);
    }

    setDashboardNotifications(openNotifications);
  };

  const onAsyncCloseNotification = async () => {
    if (availabilityPeriod.updatedAt) await storage.setItem(`${STORAGE_KEY}-${Period.AvailabilityRequestPeriod}-${user?.departmentId}-${user?.id}`, availabilityPeriod.updatedAt);
    if (vacationPeriod.updatedAt) await storage.setItem(`${STORAGE_KEY}-${Period.VacationRequestPeriod}-${user?.departmentId}-${user?.id}`, vacationPeriod.updatedAt);
    setDashboardNotifications([]);
  };

  const onCloseNotification = () => {
    onAsyncCloseNotification();
  };

  return [
    dashboardNotifications,
    onCloseNotification,
    onAsyncCloseNotification
  ];
};

export default useDashboardNotification;