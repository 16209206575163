import React, { FC, useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import Skeleton from 'react-loading-skeleton';
import { RouteComponentProps } from 'react-router-dom';

import { BackButton } from '../../components';
import { routes } from '../../constants';
import { useBrowserPrompt, useToggle } from '../../hooks';
import { useSelfRosterContext, useSelfRosterIterations } from '../../modules';
import { date, translations } from '../../utils';
import ConflictedSaveModal from './components/conflictedSaveModal/ConflictedSaveModal';
import Iteration from './components/iteration/Iteration';
import SaveModal from './components/saveModal/SaveModal';
import SelfRosterSchedule from './components/SelfRosterSchedule/SelfRosterSchedule';
import Sidebar from './components/Sidebar/Sidebar';

import './selfRoster.scss';

export type Props = RouteComponentProps<{ iterationId: string, date?: string }, null, { from?: string }>;

const SelfRoster: FC<Props> = ({ history, match, location }) => {
  const [isSaveModalOpen, toggleIsSaveModalOpen] = useToggle(false);
  const [showConflictModal, setShowConflictModal] = useState(false);
  const { data: iterations, isLoading: isIterationsLoading, error: iterationsError } = useSelfRosterIterations();
  const { hasChanges, selectedIteration, selectedDate, setSelectedDate } = useSelfRosterContext();
  const { handleOk: continueNavigation, showPrompt } = useBrowserPrompt(hasChanges, toggleIsSaveModalOpen);

  useEffect(() => {
    if (match.params.date) {
      const parsedDate = date.parseDate(match.params.date);
      if (selectedIteration) {
        setSelectedDate(parsedDate);
      }
    }
  }, [match.params.date, selectedIteration]);

  useEffect(() => {
    // In the last if, we fallback to the URL param, to give it some time to "kick in" and set the selectedIterationId
    if (iterations?.length && !selectedIteration && !iterations.some(_iteration => _iteration.id === (selectedIteration?.id || match.params.iterationId))) {
      history.replace(`${routes.scheduleSelfRoster[0]}/${iterations[0].id}`, { from: location.state?.from });
    }
  }, [iterations, selectedIteration]);

  return (
    <DocumentTitle title={translations.getLabel('titleSelfRostering')}>
      <div className="self-roster-container">
        <div className="self-rostering-page page fixed-width">
          <BackButton label={translations.getLabel('btnBack')} to={location.state?.from === 'schedule' ? '/schedule' : '/'} />
          <h2>{translations.getLabel('titleSelfRostering')}</h2>
          {!iterationsError && !iterations?.length &&
            <div>
              <p className="no-iterations">{translations.getLabel('lblNoOpenRounds')}</p>
            </div>
          }
          {iterations?.length >= 1 && <div>
            <h4>{translations.getLabel('lblRounds')}</h4>
            {!iterationsError ?
              <div className="iterations-container">
                {isIterationsLoading ? <Skeleton count={3} height={54} width={268} /> : iterations.map((iteration) => <Iteration key={iteration.id} iteration={iteration} selectedIterationId={selectedIteration?.id} locationFrom={location.state?.from} />)}
              </div>
              : <>
                <p>{translations.getLabel('lblErrorGetRounds')}</p>
                <p>{iterationsError.title}</p>
              </>
            }
            <hr className="hr" />
          </div>}

          {!!selectedIteration && <SelfRosterSchedule iteration={selectedIteration} />}
        </div>

        {!!selectedDate && <Sidebar onSave={toggleIsSaveModalOpen} />}

        <SaveModal
          open={isSaveModalOpen}
          onClose={toggleIsSaveModalOpen}
          selectedIteration={selectedIteration}
          continueNavigation={showPrompt ? continueNavigation : null}
          onConflictedSave={() => setShowConflictModal(true)}
        />

        <ConflictedSaveModal open={showConflictModal} />

      </div>
    </DocumentTitle>
  );
};

export default SelfRoster;
