import React, { FC, useEffect } from 'react';
import { Button, Modal, Switch } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import { useToggle } from '../../hooks';
import { userActions, userSelectors } from '../../redux';
import { colors, translations } from '../../utils';
import DataItem from '../shared/dataItem/DataItem';
import Widget from '../widget/Widget';

import './ContactDetails.scss';

const ContactDetails: FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.getUser);
  const isLoading = useSelector(userSelectors.isLoading);
  const [showModal, toggleModal] = useToggle(false);
  const [isContactDataVisible, toggleIsContactDataVisible] = useToggle(user?.visibleContactData || false);

  useEffect(() => {
    if (!isLoading) {
      toggleIsContactDataVisible(user?.visibleContactData || false);
    }
  }, [isLoading]);

  const handleSave = () => {
    dispatch(new userActions.UpdateUserAction({ user: { visibleContactData: isContactDataVisible } }));
    toggleModal();
  };

  const renderSkeletons = () => {
    return (
      <Widget title={translations.getLabel('titleContactInfo')} right>
        <div className="contact-details-container">
          <div>
            <div className="skeleton-item-container">
              <Skeleton height={22} width={210} />
              <Skeleton height={22} width={132} />
            </div>
            <div className="skeleton-item-container">
              <Skeleton height={22} width={138} />
              <Skeleton height={22} width={128} />
            </div>
            <div className="skeleton-item-container">
              <Skeleton height={22} width={94} />
              <Skeleton height={22} width={226} />
            </div>
          </div>
          <div className="visibility-container">
            <Skeleton height={44} width={240} />
            <Skeleton height={22} width={74} />
          </div>
        </div>
      </Widget >
    );
  };

  const toggleIsModalVisible = () => {
    toggleIsContactDataVisible(user.visibleContactData);
    toggleModal();
  };

  const toggleContainerClass = classnames('toggle-container', { 'is-visible': isContactDataVisible, 'is-not-visible': !isContactDataVisible });
  if (isLoading) return renderSkeletons();
  return (
    <Widget title={translations.getLabel('titleContactInfo')} right>
      <div className="contact-details-container">
        {user && <div>
          <DataItem title={translations.getLabel('lblFirstAndLastName')} value={`${user.firstName} ${user.lastName}`} />
          <DataItem title={translations.getLabel('lblPhoneNumber')} value={user.phoneNumber} />
          <DataItem title={translations.getLabel('lblEmail')} value={user.email} />
        </div>}
        <div className="visibility-container">
          <p>
            {user?.visibleContactData ? translations.getLabel('lblContactInfoVisible') : translations.getLabel('lblContactInfoInvisible')}
          </p>
          <Button theme="transparent" onClick={toggleModal}>
            {translations.getLabel('btnEdit')}
          </Button>
        </div>
        <Modal
          open={showModal}
          requestClose={toggleIsModalVisible}
          title={translations.getLabel('titleContactVisibility')}
          rightButtonProps={{ label: translations.getLabel('save'), onClick: handleSave }}
          leftButtonProps={{ label: translations.getLabel('cancel'), onClick: toggleIsModalVisible }}
        >
          <div className="modal-content-container">
            <p>{translations.getLabel('lblContactInfoVisible')}</p>
            <div className={toggleContainerClass}>
              <p>{translations.getLabel('no')}</p>
              <Switch label={translations.getLabel('lblContactInfoVisible')} onChange={toggleIsContactDataVisible} checked={isContactDataVisible} onColor={colors.success} uncheckedIcon={false} checkedIcon={false} height={28} width={51} />
              <p>{translations.getLabel('yes')}</p>
            </div>
          </div>
        </Modal>
      </div>
    </Widget>
  );
};

export default ContactDetails;
