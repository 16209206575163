import React, { PureComponent } from 'react';
import { SelectUserModal } from 'react-ess-components';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { UserRight } from '../../../../models';
import { chatActions } from '../../../../redux';
import { translations } from '../../../../utils';

import './startConversation.scss';

export default class StartConversation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { isSaveLoading: false, isSearching: false };
  }

  async componentDidMount() {
    this.setState({ loadingParticipants: true });
    this.props.dispatch(new chatActions.GetParticipantsAction({
      onSuccess: () => {
        this.setState({ loadingParticipants: false });
      },
    }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen && !this.props.isOpen) {
      this.props.dispatch(new chatActions.GetParticipantsAction());
      this.setState({ isSearching: false });
    }
  }

  onSelect = async (employmentIds, departmentId) => {
    this.setState({ isSaveLoading: true });
    const params = {};
    if (!this.state.isSearching && departmentId) {
      params.departmentId = departmentId;
    } else {
      params.employmentIds = employmentIds;
    }

    this.props.dispatch(new chatActions.CreateConversationAction({
      ...params,
      onSuccess: ({ data }) => {
        this.props.dispatch(new chatActions.GetConversationsAction({
          onSuccess: () => {
            const isUserInMyDepartment = !departmentId && this.props.participants.find(participant => participant.users.find(user => user.employmentId === employmentIds[0]))?.department?.id === this.props.user.departmentId;
            if (isUserInMyDepartment || departmentId === this.props.user.departmentId) {
              this.props.joinConversation(data.roomId);
              this.props.selectChat(data.id);
            } else {
              toast(translations.getLabel('lblStartedChatOtherDepartment'));
            }
            this.props.requestClose();
            this.setState({ isSaveLoading: false });
          },
        }));
      },
      onError: (error) => {
        const errorMsg = error.detail || translations.getLabel('lblGenericError');
        this.setState({ isSaveLoading: false });
        toast.error(errorMsg);
      },
    }));
  }

  search = (query) => {
    this.setState({ isSearching: !!query });
    this.props.dispatch(new chatActions.GetParticipantsAction({ query }));
  }

  getSections = () => {
    return this.props.participants.map(section => ({ name: section.department.name, id: section.department.id, users: section.users }));
  }

  render() {
    const users = this.getSections();
    const loadingState = (!this.state.loadingParticipants && users.length === 0);

    return (
      <SelectUserModal
        cancelLabel={translations.getLabel('cancel')}
        deselectLabel={translations.getLabel('lblDeselectAll')}
        isLoading={loadingState}
        isOpen={this.props.isOpen}
        isSaveLoading={this.state.isSaveLoading}
        isSectioned
        onSearch={this.search}
        onSelect={this.onSelect}
        requestClose={this.props.requestClose}
        saveLabel={translations.getLabel('lblStartConversation')}
        searchLabel={translations.getLabel('lblSearchContact')}
        selectLabel={translations.getLabel('lblSelectAll')}
        showSelectAll={this.props.hasRight(UserRight.Chat)}
        title={translations.getLabel('lblNewConversation')}
        userIdentifier="employmentId"
        users={this.props.participants}
        warningLabel={`${translations.getLabel('lblGenericWarning')} ${translations.getLabel('api_error_no_chat_cross_departments')}`}
      />
    );
  }
}

StartConversation.propTypes = {
  isOpen: PropTypes.bool,
  requestClose: PropTypes.func.isRequired,
  selectChat: PropTypes.func.isRequired,
  joinConversation: PropTypes.func.isRequired,
  participants: PropTypes.array.isRequired,
  hasRight: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

StartConversation.defaultProps = {
  isOpen: true,
  isLoading: false,
};
