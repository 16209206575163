import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import smoothscroll from 'smoothscroll-polyfill';

import App from './App';
import { store } from './redux';

import 'core-js/features/array/flat';

// Crashlogging
if (process.env.REACT_APP_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://8da2454547574b84b713d9cc8417d4ce@sentry.io/1386391',
    environment: process.env.REACT_APP_ENV,
  });
}


// kick off the polyfill!
smoothscroll.polyfill();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
