import React, { FC, useRef } from 'react';
import { Icon } from 'react-ess-components';
import classnames from 'classnames';
import { isSameDay, parseISO } from 'date-fns';

import { IDayShiftOffer } from '../../../../models';
import { translations } from '../../../../utils';
import { OpenShiftOfferFunction } from '../../schedule.models';
import Shift from './shiftOfferShift';

interface Props {
  shiftOffers: IDayShiftOffer[];
  openShiftOffer: OpenShiftOfferFunction;
  isCollapsed: boolean;
  highlightShiftOfferId?: string;
}

const ShiftOfferDay: FC<Props> = ({ shiftOffers, openShiftOffer, isCollapsed, highlightShiftOfferId }) => {
  return (
    <>
      {
        !isCollapsed
          ? shiftOffers.map(shiftOffer => <ShiftOffer key={shiftOffer.id} shiftOffer={shiftOffer} openShiftOffer={openShiftOffer} highlightShiftOfferId={highlightShiftOfferId} />)
          : <div className="collapsed-shift-offer">{renderRequestsCount(shiftOffers)}</div>
      }
    </>
  );
};

const renderRequestsCount = (shiftOffers) => {
  if (shiftOffers.length) {
    if (shiftOffers.length > 1) {    
      return <p>{shiftOffers.length} {translations.getLabel('lblRequests')}</p>;
    } else {
      return <p>{shiftOffers.length} {translations.getLabel('lblRequest')}</p>;
    }
  } else {
    return null;
  }
};

// Split up in separate component, since we need a separate ref per shiftOffer
interface ShiftOfferProps {
  shiftOffer: IDayShiftOffer;
  openShiftOffer: OpenShiftOfferFunction;
  highlightShiftOfferId?: string;
}
const ShiftOffer: FC<ShiftOfferProps> = ({ shiftOffer, openShiftOffer, highlightShiftOfferId }) => {
  const ref = useRef(null);
  const date = parseISO(shiftOffer?.date);
  const className = classnames('stripped-button shift-offer', { 'highlight': highlightShiftOfferId ? highlightShiftOfferId === shiftOffer.id : true });

  // We filter the shifts that start on the prev day, since these shifts are only shown on the day roster
  return (
    <button ref={ref} className={className} onClick={() => openShiftOffer(shiftOffer, ref.current)}>{
      shiftOffer.shifts
        ?.filter((shift) => isSameDay(parseISO(shift.startDate), date))
        .map((shift, i) => <Shift key={`${shift.id}-${i}`} shift={shift} status={i === 0 ? shiftOffer.applicantStatus : null} />)
    }
      {shiftOffer.departmentSwitch && <Icon tag="SwitchIcon" className="switch-icon" customSize={1.6} />}
    </button>
  );
};

export default ShiftOfferDay;
