export enum ActionType {
  CreateTransaction = 'CREATE_TRANSACTION',
  CreateTransactionRejected = 'CREATE_TRANSACTION_REJECTED',
  CreateTransactionFulfilled = 'CREATE_TRANSACTION_FULFILLED',
  GetTransactionTypes = 'GET_TRANSACTION_TYPES',
  GetTransactionTypesRejected = 'GET_TRANSACTION_TYPES_REJECTED',
  GetTransactionTypesFulfilled = 'GET_TRANSACTION_TYPES_FULFILLED',
  GetTransactionValue = 'GET_TRANSACTION_VALUE',
  GetTransactionValueRejected = 'GET_TRANSACTION_VALUE_REJECTED',
  GetTransactionValueFulfilled = 'GET_TRANSACTION_VALUE_FULFILLED',
}

export default ActionType;
