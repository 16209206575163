import { IBaseShift, IBaseInterval, IShift } from './schedule';
import { RequestStatus } from './requests';

export interface IShiftOffer {
  departmentIds: string[];
  templateId: string;
  numberOfPeople: number;
  date: string;
  shifts: IBaseShift<IBaseInterval>[];
}

export enum ShiftOfferApplicantStatus {
  Accepted = 'ACCEPTED',
  Denied = 'DENIED',
  Pending = 'PENDING',
  RuleViolated = 'RULE_VIOLATED',
}

export enum UpdateApplicantStatus {
  Apply = 'APPLY',
  Withdraw = 'WITHDRAW',
}

export interface IDayShiftOffer {
  id: string
  date: string;
  shifts: IShift[];
  numberOfPeople: number;
  applicantStatus: ShiftOfferApplicantStatus;
  createdAt?: string;
  departmentId: string;
  departmentName: string;
  departmentSwitch: boolean;
  departmentNameOfExecution: string;
  numberOfApplicants: number;
  shiftOfferStatus?: RequestStatus;
  templateId?: string;
  templateName?: string;
  updatedAt?: string;
  reviewerName?: string;
}

export type IShiftOffers = Record<string, IDayShiftOffer[]>

export interface ITemplate {
  id: string;
  name: string;
  shifts: IBaseShift<IBaseInterval>[];
}

export interface IGetShiftOffersParams {
  startDate: string;
  endDate: string;
  departmentId?: string;
}
