import React, { useEffect, useState } from 'react';
import { Button, InputField, InputType, Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { IServerError } from '../../../../shared/http/HttpError';
import { useToggle } from '../../hooks';
import { timeRegistrationActions, timeRegistrationSelectors } from '../../redux';
import { timeRegistration, translations } from '../../utils';
import Widget from '../widget/Widget';

import './timeRegistrationRegister.scss';

const TimeRegistrationRegister = () => {
  const dispatch = useDispatch();
  const [isModalVisible, toggleIsModalVisible] = useToggle(false);
  const [isCodeModalVisible, toggleIsCodeModalVisible] = useToggle(false);
  const [code, setCode] = useState('');
  const [deviceName, setDeviceName] = useState('');
  const isLoading = useSelector(timeRegistrationSelectors.isDeviceRegisterLoading);
  const isRegistered = useSelector(timeRegistrationSelectors.isDeviceRegistered);
  const [deviceIdentifier, setDeviceIdentifier] = useState('');

  useEffect(() => {
    checkDeviceId();
  }, []);

  const confirmModal = async () => {
    await registerDevice(registrationSuccess, () => {
      toggleIsCodeModalVisible();
      toggleIsModalVisible(false);
    });
  };

  const confirmCodeModal = async () => {
    await registerDevice(registrationSuccess, registrationFail);
  };

  const checkDeviceId = async () => {
    const deviceIdentifier = await timeRegistration.getDeviceId();
    setDeviceIdentifier(deviceIdentifier);
    dispatch(new timeRegistrationActions.CheckRegisteredDeviceAction({ deviceIdentifier }));
  };

  const registerDevice = async (onSuccess?: () => void, onError?: (error: IServerError) => void) => {
    dispatch(new timeRegistrationActions.RegisteredDeviceAction({ deviceIdentifier, deviceName, code, onSuccess, onError }));
  };

  const registrationSuccess = () => {
    toast.success(translations.getLabel('lblDeviceRegistered'));
    toggleIsModalVisible(false);
    toggleIsCodeModalVisible(false);
    localStorage.setItem('deviceIdentifier', deviceIdentifier);
  };

  const registrationFail = (error: IServerError) => {
    toast.error(error.title);
  };

  return (
    <Widget title={translations.getLabel('titleRegisterDevice')} left>
      <div className="timeregistration-register-container">
        <p>{translations.getLabel('lblRegisterDeviceDescription')}</p>
        {isLoading === undefined || isLoading ? (
          <p className="italic"><Skeleton /></p>
        ) : (
          isRegistered ? (
            <p className="italic">{translations.getLabel('lblDeviceAlreadyRegistered')}</p>
          ) : (
            <Button theme="transparent" onClick={toggleIsModalVisible}>
              {translations.getLabel('btnRegisterDevice')}
            </Button>
          )
        )}
        <Modal
          open={isModalVisible}
          requestClose={toggleIsModalVisible}
          rightButtonProps={{ onClick: confirmModal, disabled: !deviceName, isLoading, label: translations.getLabel('btnRegisterDeviceModal') }}
          leftButtonProps={{ onClick: toggleIsModalVisible, label: translations.getLabel('cancel') }}
          title={translations.getLabel('titleRegisterDeviceConfirmation')}
        >
          <p>{translations.getLabel('lblRegisterDeviceConfirmation')}</p>
          <InputField type={InputType.Text} className="input-field-container" onChange={setDeviceName} value={deviceName} emptyIcon label={translations.getLabel('lblDeviceName')} />
        </Modal>
        <Modal
          open={isCodeModalVisible}
          requestClose={toggleIsCodeModalVisible}
          rightButtonProps={{ onClick: confirmCodeModal, isLoading, label: translations.getLabel('btnRegisterDeviceModal'), disabled: !code }}
          leftButtonProps={{ onClick: toggleIsCodeModalVisible, label: translations.getLabel('cancel') }}
          title={translations.getLabel('titleRegisterDeviceConfirmation')}
        >
          <p>{translations.getLabel('lblConfirmationCodeDescription')}</p>
          <InputField type={InputType.Text} className="input-field-container" onChange={setCode} value={code} emptyIcon label={translations.getLabel('lblConfirmationCode')} />
        </Modal>
      </div>
    </Widget>
  );
};

export default TimeRegistrationRegister;
