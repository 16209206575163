import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { configSelectors } from '../../redux';
import { colors } from '../../utils';

const ColorWatcher: FC = () => {
  const holdingPreferences = useSelector(configSelectors.getHoldingPreferences);
  const companyPreferences = useSelector(configSelectors.getCompanyPreferences);
  useEffect(() => {
    const primary = companyPreferences?.primaryColor || holdingPreferences?.primaryColor || colors.primary;
    const headerColor = companyPreferences?.headerColor || holdingPreferences?.headerColor || colors.headerColor;
    const navbarIcon = companyPreferences?.navbarIconColor || colors.navbarIcon;
    const navbarText = companyPreferences?.navbarTextColor || colors.navbarText;
    const navbarTextHover = companyPreferences?.navbarTextHoverColor || colors.navbarTextHover;
    const phoneIcon = companyPreferences?.phoneIconsColor || colors.phoneIcon;
    const phonePageTitle = companyPreferences?.phonePageTitleColor || colors.phonePageTitle;

    colors.setAPIColors({ primary, headerColor, navbarIcon, navbarText, navbarTextHover, phoneIcon, phonePageTitle });

    document.documentElement.style.setProperty('--primary_light_gray', colors.primaryLightGray);
    document.documentElement.style.setProperty('--primary_transparent', colors.primaryTransparent);
    document.documentElement.style.setProperty('--primary_dark', colors.primaryDark);
    document.documentElement.style.setProperty('--header_color', colors.headerColor);
    document.documentElement.style.setProperty('--primary_light', colors.primaryLight);
    document.documentElement.style.setProperty('--background', colors.background);
    document.documentElement.style.setProperty('--primary', colors.primary);
    document.documentElement.style.setProperty('--navbar_contrast', colors.navbarContrast);
    document.documentElement.style.setProperty('--navbar_icon', colors.navbarIcon);
    document.documentElement.style.setProperty('--navbar_icon_unselected', colors.navbarIconUnselected);
    document.documentElement.style.setProperty('--navbar_text', colors.navbarText);
    document.documentElement.style.setProperty('--navbar_text_hover', colors.navbarTextHover);
    document.documentElement.style.setProperty('--navbar_text_unselected', colors.navbarTextUnselected);
  }, [holdingPreferences, companyPreferences]);

  return null;
};

export default ColorWatcher;
