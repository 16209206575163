import React, { FC } from 'react';

import { date } from '../../../../utils';

interface Props {
  day: Date;
  withoutLabel?: boolean;
}

const DayHeader: FC<Props> = ({ day, withoutLabel }) => {
  return (
    <h2 className="time-header">
      {!withoutLabel && date.format(day, 'EEEEEE d MMM')}
    </h2>
  );
};

export default DayHeader;
