import React, { FC, useEffect } from 'react';
import { Icon } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { parseISO } from 'date-fns';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { requestsActions, requestsSelectors } from '../../../../redux';
import { date, requestsUtils, translations } from '../../../../utils';
import Footer from './RequestFooter';

interface Props {
  id: string;
  isLoading: boolean;
}

const RequestDetail: FC<Props> = ({ id, isLoading }) => {
  const dispatch = useDispatch();
  const request = useSelector((state: AppState) => requestsSelectors.getVacationRequest(state, id));
  const isAllDay = useSelector((state: AppState) => requestsSelectors.isAllDay(state, id));
  const isOneDay = useSelector((state: AppState) => requestsSelectors.isOneDay(state, id));

  const relativeCreatedDate = requestsUtils.getRelativeDate(request?.createdAt, date.format);

  useEffect(() => {
    if (id && !request) {
      dispatch(new requestsActions.GetVacationRequestAction({ id }));
    }
  }, [id]);

  if (!request && !isLoading) return <section className="request-detail empty">{translations.getLabel('lblEmpyRequestInbox')}</section>;

  const startDate = parseISO(request?.startDate);
  const endDate = parseISO(request?.endDate);

  return (
    <div className="request-detail">
      <div>
        <p>{isLoading ? <Skeleton width={300} /> : (relativeCreatedDate.shouldTranslate ? `${translations.getLabel(relativeCreatedDate.value)} ${relativeCreatedDate.time}` : `${relativeCreatedDate.value}`)}</p>
        <p className="title">{isLoading ? <Skeleton width={400} /> : translations.getLabel('titleVacationRequestDetail')}</p>
        <div className="request-date-information">
          <Icon tag="CalendarIcon" color="primaryDark" />
          <p>{isLoading ? <Skeleton width={100} /> : (isOneDay ? date.format(startDate, 'eee dd LLL') : `${date.format(startDate, 'eee dd LLL')} - ${date.format(endDate, 'eee dd LLL')}`)}</p>
          <Icon tag="ClockIcon" color="primaryDark" />
          <p>{isLoading ? <Skeleton width={100} /> : (isAllDay ? `${translations.getLabel('lblAllDay')}` : date.formatFromUntilTime(date.format, { startDate, endDate }))}</p>
           <Icon tag="DashboardIcon" color="primaryDark" />
          <p>{isLoading ? <Skeleton width={100} /> : request?.vacationType}</p>
        </div>
        <div className="request-message-container">
          {isLoading ? <Skeleton count={3} width={500} /> : (
            <p><Icon tag="ChatIcon" color="primaryDark" /> {translations.getLabel('lblRequestReason')}: {!!request.requesterNote ? request.requesterNote : translations.getLabel('lblNoPersonalRequestReason')}</p>
          )}
        </div>
      </div>
      <div className="hr" />
      {request &&
        <div className="footer">
          <Footer id={id} />
        </div>
      }
    </div>
  );
};

export default RequestDetail;
