export enum ActionType {
  GetNotifications = 'GET_ALL_NOTIFICATIONS',
  GetNotificationsFulfilled = 'GET_ALL_NOTIFICATIONS_FULFILLED',
  GetNotificationsRejected = 'GET_ALL_NOTIFICATIONS_REJECTED',

  LoadMoreNotifications = 'GET_MORE_NOTIFICATIONS',
  LoadMoreNotificationsFulfilled = 'GET_MORE_NOTIFICATIONS_FULFILLED',
  LoadMoreNotificationsRejected = 'GET_MORE_NOTIFICATIONS_REJECTED',

  GetUnreadCount = 'GET_UNREAD_NOTIFICATIONS',
  GetUnreadCountRejected = 'GET_UNREAD_NOTIFICATIONS_REJECTED',
  GetUnreadCountFulfilled = 'GET_UNREAD_NOTIFICATIONS_FULFILLED',

  MarkAsRead = 'MARK_NOTIFICATION_AS_READ',
  MarkAsReadRejected = 'MARK_NOTIFICATION_AS_READ_REJECTED',
  MarkAsReadFulfilled = 'MARK_NOTIFICATION_AS_READ_FULFILLED',
}

export default ActionType;
