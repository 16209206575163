import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { configSelectors } from '../../redux';

import './devInfo.scss';


const DevInfo: FC = () => {
  const version = useSelector(configSelectors.getVersion) as string | '-';
  const build = useSelector(configSelectors.getBuild) as string | '-';

  if (process.env.REACT_APP_ENV === 'production') {
    return null;
  }
  return (
    <footer className="dev-info">
      <div className="left">
        <h4>App info</h4>
        <span>Build type:</span><span className="value">{process.env.REACT_APP_ENV}</span>
        <span className="separator">&#8226;</span>
        <span>Build number:</span><span className="value">{process.env.HEROKU_RELEASE_VERSION}</span>
        <span className="separator">&#8226;</span>
        <span>Version number:</span><span className="value">{process.env.REACT_APP_VERSION_NR}</span>
      </div>
      <div className="right">
        <h4>API info</h4>
        <span>Build number:</span><span className="value">{build}</span>
        <span className="separator">&#8226;</span>
        <span>Version number:</span><span className="value">{version}</span>
      </div>
    </footer >
  );
};

export default DevInfo;
