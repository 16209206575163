import { produce } from 'immer';

import ActionType from './types';
import { Actions } from './actions';

export interface ForgotPasswordState {
  isRequestLoading: boolean;
}

export const initialState: ForgotPasswordState = {
  isRequestLoading: false,
};

const forgotPassword = produce((draft: ForgotPasswordState, action: Actions) => {
  switch (action.type) {
    case ActionType.RequestForgotPassword:
      draft.isRequestLoading = true;
      break;

    case ActionType.RequestForgotPasswordFulfilled:
      draft.isRequestLoading = false;
      break;

    case ActionType.RequestForgotPasswordRejected:
      draft.isRequestLoading = false;
      break;

    case ActionType.ResetPassword:
      draft.isRequestLoading = true;
      break;

    case ActionType.ResetPasswordFulfilled:
      draft.isRequestLoading = false;
      break;

    case ActionType.ResetPasswordRejected:
      draft.isRequestLoading = false;
      break;


    // No default case is needed, since produce returns by default the draft state
  }
}, initialState);

export default forgotPassword;
