import { countersSelectors, countersActions } from '../../redux';
import VacationWidgetBase from './VacationWidget.base';

export const mapStateToProps = state => ({
  vacation: countersSelectors.getVacationCounter(state),
  isLoading: countersSelectors.isLoadingVacationCounter(state),
});

export const mapDispatchToProps = (dispatch) => ({
  getVacationCounter: (onSuccess, onError) => dispatch(new countersActions.GetVacationCountersAction({ onSuccess, onError })),
});

export default VacationWidgetBase;

