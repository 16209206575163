import { produce } from 'immer';

import ActionType from './types';
import { Actions } from './actions';
import { Transactiontype } from '../../models';

export interface TransactionState {
  isLoadingCreateTransaction: boolean;
  isLoadingTypes: boolean;
  isLoadingType: boolean;
  types: Transactiontype[];
  selectedType: Transactiontype;
}

export const initialState: TransactionState = {
  isLoadingCreateTransaction: false,
  isLoadingTypes: false,
  isLoadingType: false,
  types: [],
  selectedType: null,
};

const transactions = produce((draft: TransactionState, action: Actions) => {
  switch (action.type) {
    case ActionType.CreateTransaction:
      draft.isLoadingCreateTransaction = true;
      break;

    case ActionType.CreateTransactionFulfilled:
      draft.isLoadingCreateTransaction = false;
      break;

    case ActionType.CreateTransactionRejected:
      draft.isLoadingCreateTransaction = false;
      break;

    case ActionType.GetTransactionTypes:
      draft.isLoadingTypes = true;
      break;

    case ActionType.GetTransactionTypesFulfilled:
      draft.isLoadingTypes = false;
      draft.types = action.payload.data;
      break;

    case ActionType.GetTransactionTypesRejected:
      draft.isLoadingTypes = false;
      break;

    case ActionType.GetTransactionValue:
      draft.isLoadingType = true;
      break;

    case ActionType.GetTransactionValueFulfilled:
      draft.isLoadingType = false;
      draft.selectedType = action.payload.data;
      break;

    case ActionType.GetTransactionValueRejected:
      draft.isLoadingType = false;
      break;

    // No default case is needed, since produce returns by default the draft state
  }
}, initialState);

export default transactions;

