import React, { FC, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { differenceInCalendarDays, eachDayOfInterval } from 'date-fns';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { useToggle } from '../../../../hooks';
import { IAvailability, IDateRange, IDayPlanning, ITimeType, TimeScale } from '../../../../models';
import { configSelectors, scheduleSelectors } from '../../../../redux';
import { scheduleUtils } from '../../../../utils';
import { OpenAvailabilitiesFunction, OpenPlanningFunction } from '../../schedule.models';
import DetailPopup from '../detailPopup/DetailPopup';
import EmployeeColumn from '../employeeColumn/EmployeeColumn';
import WeekDay from './WeekDay';

import './weekRoster.scss';
interface Props {
  departmentId?: string;
  hasNoSearchResults?: boolean;
  hasSearch: boolean;
  hideActions?: boolean;
  highlightRange?: IDateRange;
  highlightDates?: string[];
  ignoreSearch?: boolean;
  isCollapsed: boolean;
  isMultiSelect?: boolean;
  isMyRoster?: boolean;
  isSelectable?: boolean;
  isSelected?: boolean;
  isSelectionDisabled?: boolean;
  onCollapse: (employeeId: string) => void;
  onSelect?: (date?: string, id?: string) => void;
  selectedDate: Date;
  selectedShifts?: string[];
  selectedUsers?: string[];
  userId: string;
}

const WeekRoster: FC<Props> = ({
  departmentId,
  hasNoSearchResults,
  hasSearch,
  hideActions,
  highlightRange,
  highlightDates,
  ignoreSearch,
  isCollapsed,
  isMultiSelect,
  isMyRoster,
  isSelectable,
  isSelected,
  isSelectionDisabled,
  onCollapse,
  onSelect,
  selectedDate,
  selectedShifts,
  selectedUsers,
  userId,
}) => {
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const { startDate, endDate } = scheduleUtils.getBoundsOfRange(TimeScale.Week, selectedDate, false, weekStartsOn);
  const planning = useSelector((state: AppState) => scheduleSelectors.getPlanningForUser(state, userId, startDate, endDate, ignoreSearch, departmentId));
  const isLoading = (planning.length < differenceInCalendarDays(endDate, startDate));
  const hasError = useSelector(scheduleSelectors.hasPlanningError);
  const [openPlanning, setOpenPlanning] = useState<IDayPlanning>(null);
  const [openAvailabilities, setOpenAvailabilities] = useState<{ availabilities: IAvailability[]; timeTypes: ITimeType[] }>(null);
  const [isPopupOpen, setIsPopupOpen] = useToggle(false);
  const [dayRef, setDayRef] = useState<HTMLElement>(null);
  const rosterRef = useRef(null);

  const togglePlanning: OpenPlanningFunction = (planning, ref) => {
    if (isPopupOpen && ref === dayRef) {
      closeShift();
    } else {
      setIsPopupOpen(true);
      setOpenPlanning(planning);
      setDayRef(ref);
    }
  };

  const toggleAvailabilities: OpenAvailabilitiesFunction = (availabilities, timeTypes, ref) => {
    if (isPopupOpen && ref === dayRef) {
      closeShift();
    } else {
      setIsPopupOpen(true);
      setOpenAvailabilities({ availabilities, timeTypes });
      setDayRef(ref);
    }
  };

  const closeShift = () => {
    setIsPopupOpen(false);
    setOpenPlanning(null);
    setOpenAvailabilities(null);
    setDayRef(null);
  };

  const setSelection = (date) => {
    onSelect(date, userId);
  };

  // First planning for employee details, and all shift, so we know if it can collapse
  const planningForEmployee = { ...planning[0], shifts: planning.map(p => p.shifts).flat() };

  return (
    <div className="week-roster">
      <DetailPopup
        isWeek
        isMyRoster={isMyRoster}
        isOpen={isPopupOpen}
        scrollRef={rosterRef.current}
        planning={openPlanning}
        {...openAvailabilities}
        parentRef={dayRef}
        close={closeShift}
        hideActions={hideActions}
      />
      <EmployeeColumn
        timeScale={TimeScale.Week}
        hasNoSearchResults={hasNoSearchResults}
        isLoading={isLoading && !hasError}
        planning={planningForEmployee}
        userId={userId}
        onCollapse={onCollapse}
        hasSearch={hasSearch}
        selectedDate={selectedDate}
        isCollapsed={isCollapsed}
        departmentId={departmentId}
        showRequests={isMyRoster}
        isSelectable={isSelectable}
      />
      <div className="week-days" ref={rosterRef} >
        {
          eachDayOfInterval({ start: startDate, end: endDate }).map((date, i) => (
            <WeekDay
              day={date}
              departmentId={departmentId}
              highlightDates={highlightDates}
              highlightRange={highlightRange}
              ignoreSearch={ignoreSearch}
              isCollapsed={isCollapsed}
              isMultiSelect={isMultiSelect}
              isSelectable={isSelectable}
              isSelected={isSelected}
              isSelectionDisabled={isSelectionDisabled}
              key={i}
              parentScrollRef={rosterRef.current}
              selectedDate={selectedDate}
              selectedShifts={selectedShifts}
              selectedUsers={selectedUsers}
              setSelection={setSelection}
              toggleAvailabilities={toggleAvailabilities}
              togglePlanning={togglePlanning}
              userId={userId}
            />
          ))
        }
      </div>
    </div>
  );
};

export default WeekRoster;
