import React, { FC } from 'react';
import { Icon } from 'react-ess-components';

import { ShiftOfferApplicantStatus, ShiftSwapStatus } from '../../models';

import './statusIcon.scss';

type Props = {
  status: ShiftOfferApplicantStatus | ShiftSwapStatus;
}

const getIconTag = (status: ShiftOfferApplicantStatus | ShiftSwapStatus) => {
  switch (status) {
    case ShiftOfferApplicantStatus.Accepted: return 'CheckIcon';
    case ShiftOfferApplicantStatus.Denied: return 'CrossIcon';
    case ShiftOfferApplicantStatus.Pending:
    default: return 'QuestionmarkIcon';
  }
};

const getIconColor = (status: ShiftOfferApplicantStatus | ShiftSwapStatus) => {
  switch (status) {
    case ShiftOfferApplicantStatus.Accepted: return 'success';
    case ShiftOfferApplicantStatus.Denied: return 'error';
    case ShiftOfferApplicantStatus.Pending:
    default: return 'primary';
  }
};

const StatusIcon: FC<Props> = ({ status }) => {
  return (
    <span className="status-icon">
      <Icon tag={getIconTag(status)} color={getIconColor(status)} small />
    </span>
  );
};


export default StatusIcon;
