import React, { FC } from 'react';
import { bold, center, Editor, indent, italic, justify, left, ordered, outdent, redo, right, undo, unordered } from 'react-draft-wysiwyg';

import { translations } from '../../utils';
import AddAttachment from '../addAttachment/AddAttachment';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './wysiwigEditor.scss';

type Props = {
  handleUpload: (file: File) => void;
  onMessageChange: (value: string) => void;
  value: string;
}

// Can't test this because of the library always creating a fresh instance with new keys.
const WysiwygEditor: FC<Props> = ({ value, onMessageChange, handleUpload }) => {
  const getToolbarOptions = () => ({
    options: ['history', 'fontSize', 'inline', 'textAlign', 'list'],
    inline: {
      className: 'standard-toolbar-options',
      options: ['bold', 'italic'],
      bold: { icon: bold, className: 'standard-toolbar-button' },
      italic: { icon: italic, className: 'standard-toolbar-button' },
    },
    history: {
      className: 'standard-toolbar-options',
      undo: { icon: undo, className: 'standard-toolbar-button' },
      redo: { icon: redo, className: 'standard-toolbar-button' },
    },
    fontSize: {
      className: 'standard-toolbar-options standard-toolbar-button',
    },
    textAlign: {
      className: 'standard-toolbar-options',
      options: ['left', 'center', 'right', 'justify'],
      left: { icon: left, className: 'standard-toolbar-button' },
      center: { icon: center, className: 'standard-toolbar-button' },
      right: { icon: right, className: 'standard-toolbar-button' },
      justify: { icon: justify, className: 'standard-toolbar-button' },
    },
    list: {
      className: 'standard-toolbar-options',
      options: ['unordered', 'ordered', 'indent', 'outdent'],
      unordered: { icon: unordered, className: 'standard-toolbar-button' },
      ordered: { icon: ordered, className: 'standard-toolbar-button' },
      indent: { icon: indent, className: 'standard-toolbar-button' },
      outdent: { icon: outdent, className: 'standard-toolbar-button' },
    },
  });

  return (
    <Editor
      editorState={value}
      wrapperClassName="editor-wrapper"
      toolbarClassName="toolbar-wrapper"
      editorClassName="editor-content-wrapper"
      onEditorStateChange={onMessageChange}
      toolbar={getToolbarOptions()}
      placeholder={translations.getLabel('lblWriteNewsitem')}
      toolbarCustomButtons={[<AddAttachment key="attachment" handleUpload={handleUpload} />]}
    />
  );
};

export default WysiwygEditor;
