import { combineReducers, Action } from 'redux';

import availabilitiesReducer, { AvailabilitiesState } from './availabilities/reducer';
import chatReducer, { ChatState } from './chat/reducer';
import configReducer, { ConfigState } from './config/reducer';
import countersReducer, { CounterState } from './counters/reducer';
import departmentsReducer, { DepartmentState } from './departments/reducer';
import documentsReducer, { DocumentsState } from './documents/reducer';
import employeesReducer, { EmployeeState } from './employees/reducer';
import forgotPasswordReducer, { ForgotPasswordState } from './forgotPassword/reducer';
import loginReducer, { LoginState } from './login/reducer';
import newsReducer, { NewsState } from './news/reducer';
import notificationsReducer, { NotificationState } from './notifications/reducer';
import requestsReducer, { RequestState } from './requests/reducer';
import scheduleReducer, { ScheduleState } from './schedule/reducer';
import shiftOfferReducer, { ShiftOfferState } from './shiftOffer/reducer';
import shiftSwapReducer, { ShiftSwapState } from './shiftSwap/reducer';
import adjustedShiftTimeReducer, { AdjustedShiftTimeState } from './adjustedShiftTimes/reducer';
import timeRegistrationReducer, { TimeRegistrationState } from './timeRegistration/reducer';
import toasterReducer, { ToasterState } from './toaster/reducer';
import transactionReducer, { TransactionState } from './transactions/reducer';
import userReducer, { UserState } from './user/reducer';
import LoginActionType from './login/types';

import reduxStore from './initialReduxStore';

export interface AppState {
  adjustedShiftTime: AdjustedShiftTimeState;
  availabilities: AvailabilitiesState;
  chat: ChatState;
  config: ConfigState;
  counters: CounterState;
  departments: DepartmentState;
  documents: DocumentsState;
  employees: EmployeeState;
  forgotPassword: ForgotPasswordState;
  login: LoginState;
  news: NewsState;
  notifications: NotificationState;
  requests: RequestState;
  schedule: ScheduleState;
  shiftOffer: ShiftOfferState;
  shiftSwap: ShiftSwapState;
  timeRegistration: TimeRegistrationState;
  toaster: ToasterState;
  transactions: TransactionState;
  user: UserState;
}

const appReducer = combineReducers<AppState>({
  adjustedShiftTime: adjustedShiftTimeReducer,
  availabilities: availabilitiesReducer,
  chat: chatReducer,
  config: configReducer,
  counters: countersReducer,
  departments: departmentsReducer,
  documents: documentsReducer,
  employees: employeesReducer,
  forgotPassword: forgotPasswordReducer,
  login: loginReducer,
  news: newsReducer,
  notifications: notificationsReducer,
  requests: requestsReducer,
  schedule: scheduleReducer,
  shiftOffer: shiftOfferReducer,
  shiftSwap: shiftSwapReducer,
  timeRegistration: timeRegistrationReducer,
  toaster: toasterReducer,
  transactions: transactionReducer,
  user: userReducer,
});

const rootReducer = (state: AppState, action: Action) => {
  if (action.type === LoginActionType.LogoutFulfilled) {
    // We want to keep the holding config as we remain within the holding domain at logout
    const holding = state.config.holding;

    const newState = {
      ...reduxStore,
      config: { ...reduxStore.config, holding }
    };
    return appReducer(newState, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
