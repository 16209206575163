export interface IResetPassword {
    data: {
        contactInfo: {
            type: string,
            value: string,
        }
    };
}

export enum ContactType {
  email = 'email',
  sms = 'sms',
}

export enum ErrorCode {
  userNotFound = 'USER_NOT_FOUND',
  noContactInfo = 'NO_CONTACT_INFO',
}