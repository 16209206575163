export enum ActionType {
  GetApiVersion = 'GET_API_VERSION',
  GetApiVersionFulfilled = 'GET_API_VERSION_FULFILLED',
  GetApiVersionRejected = 'GET_API_VERSION_REJECTED',
  GetAppVersion = 'GET_APP_VERSIONS',
  GetAppVersionFulfilled = 'GET_APP_VERSIONS_FULFILLED',
  GetAppVersionRejected = 'GET_APP_VERSIONS_REJECTED',
  GetCalendarLinks = 'GET_CALENDAR_LINKS',
  GetCalendarLinksFulfilled = 'GET_CALENDAR_LINKS_FULFILLED',
  GetCalendarLinksRejected = 'GET_CALENDAR_LINKS_REJECTED',
  GetCompanyConfig = 'GET_COMPANY_CONFIG',
  GetCompanyConfigFulfilled = 'GET_COMPANY_CONFIG_FULFILLED',
  GetCompanyConfigRejected = 'GET_COMPANY_CONFIG_REJECTED',
  GetCompanyPreferences = 'GET_COMPANY_PREFERENCES',
  GetCompanyPreferencesFulfilled = 'GET_COMPANY_PREFERENCES_FULFILLED',
  GetCompanyPreferencesRejected = 'GET_COMPANY_PREFERENCES_REJECTED',
  GetHoldingPreferences = 'GET_HOLDING_PREFERENCES',
  GetHoldingPreferencesFulfilled = 'GET_HOLDING_PREFERENCES_FULFILLED',
  GetHoldingPreferencesRejected = 'GET_HOLDING_PREFERENCES_REJECTED',
  GetHealth = 'GET_HEALTH',
  GetHealthFulfilled = 'GET_HEALTH_FULFILLED',
  GetHealthRejected = 'GET_HEALTH_REJECTED',
  GetTermsAndConditions = 'GET_TERMS_AND_CONDITIONS',
  GetTermsAndConditionsFulfilled = 'GET_TERMS_AND_CONDITIONS_FULFILLED',
  GetTermsAndConditionsRejected = 'GET_TERMS_AND_CONDITIONS_REJECTED',
  ResetFatalErrors = 'RESET_FATAL_ERRORS',
  SpxIsDown = 'SPX_IS_DOWN',
}

export default ActionType;
