export enum ActionType {
  Login = 'LOGIN',
  LoginFulfilled = 'LOGIN_FULFILLED',
  LoginRejected = 'LOGIN_REJECTED',

  LoginWithSso = 'LOGIN_WITH_SSO',
  LoginWithSsoFulfilled = 'LOGIN_WITH_SSO_FULFILLED',
  LoginWithSsoRejected = 'LOGIN_WITH_SSO_REJECTED',

  Logout = 'LOGOUT',
  LogoutRejected = 'LOGOUT_REJECTED',
  LogoutFulfilled = 'LOGOUT_FULFILLED',
  LogoutAutomatically = 'LOGOUT_AUTOMATICALLY',

  GetEmployments = 'GET_EMPLOYMENTS',
  GetEmploymentsRejected = 'GET_EMPLOYMENTS_REJECTED',
  GetEmploymentsFulfilled = 'GET_EMPLOYMENTS_FULFILLED',

  UpdateEmployment = 'UPDATE_EMPLOYMENT',
  UpdateEmploymentRejected = 'UPDATE_EMPLOYMENT_REJECTED',
  UpdateEmploymentFulfilled = 'UPDATE_EMPLOYMENT_FULFILLED',
}

export default ActionType;
