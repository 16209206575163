import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO, startOfDay } from 'date-fns';

import { identifiers } from '../../constants';
import { IDateRange,TimeScale } from '../../models';
import { configSelectors, scheduleActions, userSelectors } from '../../redux';
import { scheduleUtils } from '../../utils';
import WeekRoster from '../schedule/components/weekSchedule/WeekRoster';

interface Props {
  selectedDate: Date;
  highlightRange?: IDateRange;
  onSelect: (date: string, id?: string) => void;
  selectedShifts: string[];
  employeeId: string;
  swapDate: string;
}

const ShiftSwapSchedule: FC<Props> = ({ selectedDate, highlightRange, employeeId, swapDate, onSelect, selectedShifts }) => {
  const dispatch = useDispatch();
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const user = useSelector(userSelectors.getUser);
  const { startDate, endDate } = scheduleUtils.getBoundsOfRange(TimeScale.Week, selectedDate, false, weekStartsOn);
  const [collapsedIds, setCollapseId] = useState<string[]>([]);

  // Get new data on change of date
  useEffect(() => {
    const formattedStartDate = format(startDate, 'yyyy-MM-dd');
    const formattedEndDate = format(endDate, 'yyyy-MM-dd');

    // TODO: implement specific call for user
    dispatch(new scheduleActions.GetDepartmentPlanningAction({ startDate: formattedStartDate, endDate: formattedEndDate }));
    dispatch(new scheduleActions.GetPlanningAction({ startDate: formattedStartDate, endDate: formattedEndDate }));
  }, [selectedDate]);

  const onCollapse = (userId: string) => {
    if (collapsedIds.includes(userId)) setCollapseId(collapsedIds.filter(id => id !== userId));
    else setCollapseId([...collapsedIds, userId]);
  };

  return (
    <>
      <div className="week-schedule shift-offer-schedule no-requests">
        <WeekRoster
          isSelectable
          isMultiSelect
          onSelect={onSelect}
          selectedShifts={selectedShifts}
          hasSearch={false}
          selectedDate={selectedDate}
          userId={identifiers.me}
          isCollapsed={collapsedIds.includes(user.employeeId)}
          onCollapse={onCollapse}
          highlightRange={highlightRange}
          hideActions={true}
        />
      </div>
      <div className="week-schedule shift-offer-schedule no-requests no-availabilities">
        <section className="colleague-rosters">
          <WeekRoster
            isMultiSelect
            onSelect={onSelect}
            selectedShifts={selectedShifts}
            key={employeeId}
            hasSearch={false}
            selectedDate={selectedDate}
            userId={employeeId}
            isCollapsed={collapsedIds.includes(employeeId)}
            onCollapse={onCollapse}
            highlightRange={{ startDate: startOfDay(parseISO(swapDate)), endDate: parseISO(swapDate) }}
            hideActions={true}
          />
        </section>
      </div>
    </>
  );
};

export default ShiftSwapSchedule;
