import React, { FC } from 'react';
import DocumentTitle from 'react-document-title';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { AppState } from '../../../../shared/redux/rootReducer';
import { CounterWidget, DashboardNotification, NewsWidget, ScheduleToday, SelfRosterWidget, VacationWidget } from '../../components';
import { UserRight } from '../../models';
import { configSelectors, userSelectors } from '../../redux';
import { translations } from '../../utils';

import './home.scss';

const Home: FC<RouteComponentProps> = ({ history }) => {
  const companyConfig = useSelector(configSelectors.getCompanyConfig);
  const companyPreferences = useSelector(configSelectors.getCompanyPreferences);
  const holdingPreferences = useSelector(configSelectors.getHoldingPreferences);
  const hasNewsRight = useSelector((state: AppState) => userSelectors.hasRight(state, UserRight.News));

  const navigateToCounters = () => {
    history.push('/counters');
  };

  const navigateToNews = (route) => {
    history.push(route);
  };

  const modules = companyConfig?.modules;

  return (
    <DocumentTitle title={translations.getLabel('titleDashboard')}>
      <div className="app-dashboard page fixed-width">
        <div className="background-image" style={{ backgroundImage: `url(${companyPreferences?.webBackgroundUrl || holdingPreferences?.webBackgroundUrl})` }} />
        <DashboardNotification history={history} />
        {modules?.schedule && <ScheduleToday />}
        {modules?.selfRoster && <SelfRosterWidget history={history} />}
        {modules?.counters && <div className="widgets-container">
          <VacationWidget />
          <CounterWidget onClickMore={navigateToCounters} />
        </div>}
        {modules?.news && <NewsWidget onClickMore={navigateToNews} limit={4} hasRight={hasNewsRight} />}
      </div>
    </DocumentTitle>
  );
};

export default Home;
