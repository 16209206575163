// Ignore any parameters. nextValue is any because you can pass anything (like an event of a button), but only booleans will be taken into account. Otherwise we will just toggle the current value
/* eslint @typescript-eslint/no-explicit-any: 0 */
import { useCallback, useState } from 'react';

const useToggle = (
  initialValue: boolean
): [boolean, (nextValue?: any) => void] => {
  const [value, setValue] = useState<boolean>(initialValue);

  const toggle = useCallback(
    (nextValue?: any) => {
      if (typeof nextValue === 'boolean') {
        setValue(nextValue);
      } else {
        setValue(currentValue => !currentValue);
      }
    },
    [setValue]
  );

  return [value, toggle];
};

export default useToggle;
