import { Action } from 'redux';

import { ActionType } from './types';
import { IServerError } from '../../http/HttpError';
import { IResetPassword } from '../../models/resetPassword';

export class RequestForgotPasswordAction implements Action<ActionType> {
  readonly type = ActionType.RequestForgotPassword;
  constructor(public payload: { username: string, holding: string, onError?: (error: IServerError) => void, onSuccess?: (response: IResetPassword) => void }) { }
}

export class RequestForgotPasswordFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.RequestForgotPasswordFulfilled;
}

export class RequestForgotPasswordRejectedAction implements Action<ActionType> {
  readonly type = ActionType.RequestForgotPasswordRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class ResetPasswordAction implements Action<ActionType> {
  readonly type = ActionType.ResetPassword;
  constructor(public payload: { password: string, token: string, holding: string, shouldPersistSession: boolean, onError?: (error: IServerError) => void, onSuccess?: () => void }) { }
}

export class ResetPasswordFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.ResetPasswordFulfilled;
}

export class ResetPasswordRejectedAction implements Action<ActionType> {
  readonly type = ActionType.ResetPasswordRejected;
  constructor(public payload: { error: IServerError }) { }
}

export type Actions =
  | RequestForgotPasswordAction
  | RequestForgotPasswordFulfilledAction
  | RequestForgotPasswordRejectedAction
  | ResetPasswordAction
  | ResetPasswordFulfilledAction
  | ResetPasswordRejectedAction;