import React, { PureComponent } from 'react';
import { Redirect,Route } from 'react-router-dom';
import PropTypes from 'prop-types';

class UnauthorizedRoute extends PureComponent {
  renderRoute = () => {
    const RouteComponent = this.props.component;
    return !this.props.userId ? <RouteComponent {...this.props} /> : <Redirect to={'/'} />;
  };

  render() {
    const { component, userId, ...otherProps } = this.props; // eslint-disable-line @typescript-eslint/no-unused-vars

    return (
      <Route
        {...otherProps}
        render={this.renderRoute}
      />
    );
  }
}

UnauthorizedRoute.propTypes = {
  component: PropTypes.any.isRequired,
  userId: PropTypes.string,
};

UnauthorizedRoute.defaultProps = {
  userId: '',
};

export default UnauthorizedRoute;
