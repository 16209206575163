import React, { FC } from 'react';
import { IconButton } from 'react-ess-components';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { AppState } from '../../../../shared/redux/rootReducer';
import { routes } from '../../constants';
import { useDashboardNotification } from '../../hooks';
import { IPeriodDetail, Period } from '../../models';
import { userSelectors } from '../../redux';
import { date, translations } from '../../utils';

import './DashboardNotification.scss';

const getNotificationTranslation = (type: Period) => {
  switch (type) {
    case Period.AvailabilityRequestPeriod:
      return translations.getLabel('btnAvailabilities');
    case Period.VacationRequestPeriod:
      return translations.getLabel('btnVacation');
    default:
      return '';
  }
};

type Props = Omit<RouteComponentProps, 'location' | 'match'>

const DashboardNotification: FC<Props> = ({ history }) => {
  const availabilityPeriod = useSelector((state: AppState) => userSelectors.getPeriods(state, Period.AvailabilityRequestPeriod));
  const vacationPeriod = useSelector((state: AppState) => userSelectors.getPeriods(state, Period.VacationRequestPeriod));
  const user = useSelector(userSelectors.getUser);
  const [dashboardNotifications, onCloseNotification] = useDashboardNotification(localStorage, user, vacationPeriod, availabilityPeriod);

  const navigateToScreen = (type: Period) => {
    switch (type) {
      case Period.AvailabilityRequestPeriod:
        history.push(routes.scheduleAvailabilities);
        break;
      default:
        history.push(routes.schedule[0]);
        break;
    }
  };

  const renderText = (periodDetail: IPeriodDetail) => {
    return (
      <p key={periodDetail.type}>
        {translations.getLabel('lblDashboardNotification1')}
        <button onClick={() => navigateToScreen(periodDetail.type)}>
          &nbsp;{getNotificationTranslation(periodDetail.type)}&nbsp;
        </button>
        {translations.getLabel('lblDashboardNotification2', { fromDate: date.format(periodDetail.startDate, 'd MMMM \'\'yy'), toDate: date.format(periodDetail.endDate, 'd MMMM \'\'yy') })}
      </p>
    );
  };

  if (!dashboardNotifications.length) return null;

  return (
    <section className="dashboard-notification-container" aria-label={translations.getLabel('lblNotification')} role="contentinfo">
      <div className="dashboard-notification">
        <div className="notifications">
          {dashboardNotifications.map(renderText)}
        </div>
        <IconButton tag="CloseIcon" color="white" onClick={onCloseNotification} title={translations.getLabel('lblClose')} />
      </div>
    </section>
  );
};

export default DashboardNotification;
