import { HttpClient } from '../http';
import { ICompanyConfig, ICompanyPreferences, IHoldingPreferences, ICalenderLinks, ITermsAndConditions } from '../models';

interface GetApiVersionResponse { build: string; version: string; };
export const getApiVersion = async (): Promise<GetApiVersionResponse> => {
  const result = await HttpClient.get<{ data: GetApiVersionResponse }>('/v1/config/version');
  return result.data;
};

interface GetAppVersionResponse { minVersion: string; latestVersion: string; };
export const getAppVersion = async (os: string): Promise<GetAppVersionResponse> => {
  const result = await HttpClient.get<{ data: GetAppVersionResponse }>(`/v1/config/version/${os}`);
  return result.data;
};

export const getCalendarLinks = async (): Promise<ICalenderLinks> => {
  const result = await HttpClient.get<{ data: ICalenderLinks }>('/v1/config/calendar-links');
  return result.data;
};

export const getCompanyConfig = async (companyId: string): Promise<ICompanyConfig> => {
  const result = await HttpClient.get<{ data: { config: ICompanyConfig } }>(`/v1/company/${companyId}/config`);
  return result.data.config;
};

export const getCompanyPreferences = async (companyId: string): Promise<ICompanyPreferences> => {
  const result = await HttpClient.get<{ data: ICompanyPreferences }>(`/v1/company/${companyId}/preferences`);
  return result.data;
};

export const getHoldingPreferences = async (holdingName: string): Promise<IHoldingPreferences> => {
  const result = await HttpClient.get<{ data: IHoldingPreferences }>('/v1/holding/preferences', null, { url: holdingName });
  return { ...result.data, name: holdingName };
};

export const getTerms = async (): Promise<ITermsAndConditions> => {
  const result = await HttpClient.get<{ data: ITermsAndConditions }>('/v1/holding/terms-and-conditions');
  return result.data;
};

export const health = async (): Promise<boolean> => {
  await HttpClient.get('/v1/health');
  return true;
};
