import { createSelector } from 'reselect';
import { UserState } from './reducer';
import { AppState } from '../rootReducer';
import { Period, UserRight, IUser } from '../../models';

const selectNode = (state: AppState) => state.user;

export const getDepartmentId = createSelector(
  selectNode,
  (state: UserState) => state.userDetails.departmentId,
);

export const isAdmin = createSelector(
  selectNode,
  (state: UserState) => state.userDetails?.isHoldingAdmin || state.userDetails?.isCompanyAdmin,
);

export const isLoading = createSelector(
  selectNode,
  (state: UserState) => state.isLoading,
);

export const isNotificationPreferencesLoading = createSelector(
  selectNode,
  (state: UserState) => state.isNotificationPreferencesLoading,
);

export const isUpdateAvatarLoading = createSelector(
  selectNode,
  (state: UserState) => state.isUpdateAvatarLoading,
);

export const isUpdateNotificationPreferencesLoading = createSelector(
  selectNode,
  (state: UserState) => state.isUpdateNotificationPreferencesLoading,
);

export const isUpdateUserLoading = createSelector(
  selectNode,
  (state: UserState) => state.isUpdateUserLoading,
);

export const getNotificationPreferences = createSelector(
  selectNode,
  (state: UserState) => state.notificationPreferences,
);

export const getUser = createSelector(
  selectNode,
  (state: UserState) => state.userDetails,
);

export const getUserId = createSelector(
  selectNode,
  (state: UserState) => state.userDetails?.id || '',
);

export const getIcsUrl = createSelector(
  selectNode,
  (state: UserState) => state.icsUrl,
);

export const isIcsUrlLoading = createSelector(
  selectNode,
  (state: UserState) => state.isIcsUrlLoading,
);

export const getEmploymentId = createSelector(
  selectNode,
  (state: UserState) => state.userDetails?.employmentId || '',
);

export const getServerError = createSelector(
  selectNode,
  (state: UserState) => null,
);

export const changePasswordError = createSelector(
  selectNode,
  (state: UserState) => state.changePasswordError,
);

export const inSchoolUntil = createSelector(
  selectNode,
  (state: UserState) => state.userDetails?.inSchoolUntil,
);

export const getPeriods = (state: AppState, type: Period.AvailabilityRequestPeriod | Period.VacationRequestPeriod | Period.ShiftTimeAdjustmentPeriod) => state.user.periods[type];

export const hasRight = (state: AppState, right: UserRight) => state.user.userDetails?.permissions?.includes(right) || false;