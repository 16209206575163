import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { chatSelectors, userSelectors } from '../../redux';
import { translations } from '../../utils';
import ChatItem from '../chatItem/ChatItem';

const ChatsMenu: FC = () => {
  const amountUnread = useSelector(chatSelectors.amountUnread);
  const conversations = useSelector(chatSelectors.getConversationIds);
  const userId = useSelector(userSelectors.getUserId);
  const history = useHistory();

  const renderConversation = (chatId: string) => <ChatItem key={chatId} id={chatId} userId={userId} onClick={selectChat} />;

  const selectChat = (selectedId: string) => {
    history.push(`/chat/${selectedId}`);
  };

  const messages = conversations.slice(0, 10);

  return (
    <div className="notifications-container">
      <h3 className="notifications-title">{translations.getLabel('titleChat')} <span className="badge">({amountUnread})</span></h3>
      {messages.map(renderConversation)}
    </div>
  );
};

export default ChatsMenu;

