import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { format } from 'date-fns';

import { identifiers } from '../../constants';
import { IEmployee, TimeScale } from '../../models';
import { availabilitiesActions, configSelectors,scheduleActions } from '../../redux';
import { scheduleUtils } from '../../utils';
import EmployeeSelect from './components/monthSchedule/EmployeeSelect';
import MonthRoster from './components/monthSchedule/MonthRoster';

import './monthSchedule.scss';

interface Props {
  selectedDate: Date;
  timeScale: TimeScale;
  showAvailabilities: boolean;
  changeTimeScale: (timeScale: TimeScale, date: Date) => void;
}

const MonthSchedule: FC<Props> = ({ selectedDate, timeScale, changeTimeScale, showAvailabilities }) => {
  const dispatch = useDispatch();
  const [selectedUserId, setSelectedUserId] = useState<{ employeeId: string; employmentId: string }>({ employeeId: identifiers.me, employmentId: identifiers.me });
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);

  // Get new data on change of date
  useEffect(() => {
    const { startDate, endDate } = scheduleUtils.getBoundsOfRange(timeScale, selectedDate, true, weekStartsOn);
    const formattedStartDate = format(startDate, 'yyyy-MM-dd');
    const formattedEndDate = format(endDate, 'yyyy-MM-dd');

    if (selectedUserId.employmentId === identifiers.me) {
      dispatch(new scheduleActions.GetPlanningAction({ startDate: formattedStartDate, endDate: formattedEndDate }));
      dispatch(new availabilitiesActions.GetAvailabilitiesAction({ startDate: formattedStartDate, endDate: formattedEndDate }));
    }
    else {
      dispatch(new scheduleActions.GetUserPlanningAction({ startDate: formattedStartDate, endDate: formattedEndDate, employmentId: selectedUserId.employmentId }));
    }
  }, [selectedDate, selectedUserId]);

  const onChange = (employmentId: string, employee: IEmployee) => {
    if (employmentId === identifiers.me) {
      setSelectedUserId({ employeeId: identifiers.me, employmentId });
    } else {
      setSelectedUserId({ employeeId: employee.employeeId, employmentId });
    }
  };

  const className = classnames('month-schedule', { 'no-availabilities': !showAvailabilities });
  return (
    <div className={className}>
      <div className="select-menu">
        <EmployeeSelect onChange={onChange} />
      </div>
      <MonthRoster changeTimeScale={changeTimeScale} selectedDate={selectedDate} userId={selectedUserId.employeeId} />
    </div>
  );
};

export default MonthSchedule;
