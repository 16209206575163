import { differenceInMinutes, eachDayOfInterval, isSameDay, isValid, minutesToHours, parseISO } from 'date-fns';
import Color from 'color';

import { IPostSelfRoster, ISelfRosteringFilteredRoster, ISelfRosteringCounter, ISelfRosteringIteration, ISelfRosteringPlanning, ISelfRosteringRoster, ISelfRosteringShift } from '../models';
import { FormatFunction } from './date';

export const formatIterationDate = (formatFunction: FormatFunction, data?: { startDate?: Date, endDate?: Date }): string => {
  if (!data || !data.startDate || !data.endDate || !isValid(data.startDate) || !isValid(data.endDate)) {
    return '';
  }
  return `${formatFunction(data.startDate, 'd MMMM')} - ${formatFunction(data.endDate, 'd MMMM')}`;
};

export const formatIterationDateForNavHeader = (formatFunction: FormatFunction, data?: { startDate?: Date, endDate?: Date }): string => {
  if (!data || !data.startDate || !data.endDate || !isValid(data.startDate) || !isValid(data.endDate)) {
    return '';
  }
  return `${formatFunction(data.startDate, 'EEEEEE d MMMM')} - ${formatFunction(data.endDate, 'EEEEEE d MMMM')}`;
};

export const getShiftColors = (shift: ISelfRosteringShift, gradientWhitespace = 4): { darkColor: string, color: string, gradientCss: string, gradientColor: string } => {
  const color = new Color(!shift?.color || shift?.color.toLowerCase().includes('#ffffff') ? '#8f8f8f' : shift.color);
  const gradientColor = color.fade(0.9).string();
  const gradientCss = `repeating-linear-gradient(-45deg,
    transparent,
    transparent ${gradientWhitespace}px,
    ${gradientColor} 2px,
    ${gradientColor} ${gradientWhitespace + 2}px)`;

  return {
    color: color.fade(0.7).string(),
    darkColor: color.string(),
    gradientCss,
    gradientColor,
  };
};

export const getFilteredRoster = (startDate: Date, endDate: Date, selectedShifts: ISelfRosteringShift[], originalSelectedShifts: ISelfRosteringRoster[]): ISelfRosteringFilteredRoster[] => {
  const roster = eachDayOfInterval({ start: startDate, end: endDate }).reduce<{ date: Date, hours: number, shifts: ISelfRosteringShift[] }[]>((accu, day) => {
    // Get all the original and selected shifts for this day
    const shifts = selectedShifts.filter(shift => isSameDay(parseISO(shift.startDate), day));
    const originalShifts = originalSelectedShifts?.find(roster => isSameDay(parseISO(roster.date), day))?.shifts || [];
    if (!shifts.length && !originalShifts.length) return accu;

    // Filter so we count only shifts that are already removed, or shifts that are added (already saved selected shifts will be included in the counter)
    const removedShifts = originalShifts.filter(shift => !shifts.some(_shift => _shift.uuid === shift.uuid));
    const newShifts = shifts.filter(shift => !originalShifts.some(_shift => _shift.uuid === shift.uuid));

    // Make the sum for the new shifts and subtract it with the removed shifts
    const hoursAdded = newShifts.reduce((sum, shift) => sum + shift.billableHours, 0);
    const hoursRemoved = removedShifts.reduce((sum, shift) => sum + shift.billableHours, 0);

    accu.push({
      date: day,
      hours: hoursAdded - hoursRemoved,
      shifts,
    });
    return accu;
  }, []);

  return roster;
};

export const formatSelfRosterForSave = (selectedShifts: ISelfRosteringShift[], originalRoster: ISelfRosteringRoster[], fallbackDepartmentId: string): IPostSelfRoster[] => {
  const originalShifts = originalRoster.flatMap(roster => roster.shifts);
  // Filter the shifts that are added and the shifts that are removed
  const addedShifts = selectedShifts.filter(shift => !originalShifts.some(originalShift => originalShift.uuid === shift.uuid));
  const removedShifts = originalShifts.filter(shift => !selectedShifts.some(selectedShift => selectedShift.uuid === shift.uuid));
  // Reduce them in the correct format (do it once for added shifts, and once for removed shifts);
  const resultWithAddedShifts = reduceShiftsForSave([], addedShifts, 'added', fallbackDepartmentId);
  return reduceShiftsForSave(resultWithAddedShifts, removedShifts, 'removed', fallbackDepartmentId);
};

const reduceShiftsForSave = (initialValue: IPostSelfRoster[], shifts: ISelfRosteringShift[], key: 'added' | 'removed', fallbackDepartmentId?: string): IPostSelfRoster[] => {
  return shifts.reduce<IPostSelfRoster[]>((accu, shift) => {
    const shiftDepartmentId = shift.departmentId || fallbackDepartmentId;

    const { departmentName, departmentId, ..._shift } = shift;

    // Check if department already exists, if not, create one and already fill in the first shift on the correct date
    const departmentIndex = accu.findIndex(planning => planning.departmentId === shiftDepartmentId);
    if (departmentIndex >= 0) {
      // Check if there are already shifts available for the shift date, if not create new entry and fill in with shift
      const index = accu[departmentIndex][key].findIndex(day => isSameDay(parseISO(day.date), parseISO(shift.startDate)));

      if (index >= 0) {
        accu[departmentIndex][key][index].shifts.push(_shift);
      } else {
        accu[departmentIndex][key].push({
          date: shift.startDate,
          shifts: [_shift],
        });
      }
    } else {
      const otherKey = key === 'added' ? 'removed' : 'added';
      accu.push({
        departmentId: shiftDepartmentId,
        [key]: [{
          date: shift.startDate,
          shifts: [_shift],
        }],
        [otherKey]: [],
      });
    }
    return accu;
  }, initialValue);
};

export const getIterationChecks = (iteration: ISelfRosteringIteration) => ({
  isRound1: iteration?.iteration === 1,
  isRound2: iteration?.iteration === 2,
  isRound3: iteration?.iteration === 3,
});