import React, { FC, useEffect, useRef } from 'react';
import { Spinner } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { IVacationRequest } from '../../../../../../shared/models';
import { routes } from '../../../../constants';
import { useInfiniteScroll } from '../../../../hooks';
import { requestsActions, requestsSelectors } from '../../../../redux';
import RequestDetail from './RequestDetail';
import RequestPreview from './RequestPreview';

import '../requestInbox.scss';

interface UrlId {
  id: string;
}

const RequestInboxView: FC<RouteComponentProps<UrlId>> = (props) => {
  const dispatch = useDispatch();
  const scrollRef = useRef(null);

  const vacationRequests = useSelector(requestsSelectors.getVacationRequests);
  const isVacationRequestLoading = useSelector(requestsSelectors.isVacationRequestLoading);
  const isMoreVacationRequestLoading = useSelector(requestsSelectors.isMoreVacationRequestLoading);
  const metaData = useSelector(requestsSelectors.getVacationRequestsMetaData);

  const requests = !!vacationRequests && Object.values(vacationRequests);

  useEffect(() => {
    dispatch(new requestsActions.GetVacationRequestsAction({ offset: 0 }));
  }, []);

  useEffect(() => {
    if (!isVacationRequestLoading && requests?.length && !props.match.params?.id) props.history.push(`${routes.scheduleRequests.inbox[0]}/${requests[0].id}`);
  }, [isVacationRequestLoading, props.match.params.id]);


  useInfiniteScroll(
    (offset: number) => dispatch(new requestsActions.GetVacationRequestsAction({ offset })),
    metaData,
    isVacationRequestLoading || isMoreVacationRequestLoading,
    scrollRef.current,
  );

  const renderRequestsPreview = (request: IVacationRequest): JSX.Element => {
    return (
      <RequestPreview id={request.id} key={request.id} selectedId={props.match.params.id} />
    );
  };

  return (
    <div className="inbox">
      <section className="inbox-container">
        <aside ref={scrollRef} className="inbox-menu">
          {isVacationRequestLoading ? [0, 1, 2].map((index) =>
            <div key={index} className="request-content request-list-loading" >
              <Skeleton height={75} />
            </div>
          ) : vacationRequests && requests.map(renderRequestsPreview)}
          {isMoreVacationRequestLoading && <div className="loading-container"><Spinner solid /></div>}
        </aside>
        <RequestDetail id={props.match.params.id} isLoading={isVacationRequestLoading} />
      </section>
    </div >
  );
};

export default RequestInboxView;
