import React, { PureComponent } from 'react';
import { Checkbox, IconButton, Popup, Select } from 'react-ess-components';
import classnames from 'classnames';
import Color from 'color';
import { endOfDay, setHours, setMinutes, startOfDay } from 'date-fns';
import PropTypes from 'prop-types';

import { defaultDayEndHour, defaultDayStartHour, regexes } from '../../../../constants';
import { availabilitiesUtils, date, translations } from '../../../../utils';


class AvailabilityShift extends PureComponent {
  constructor(props) {
    super(props);
    this.ref = null;
    this.timeErrorId = `${props.shift.id}+${props.shift.startDate}`;
    this.state = {
      allDay: availabilitiesUtils.isAllDay(props.shift),
      open: false,
      startTime: date.format(date.parseDate(props.shift.startDate), 'HH:mm'),
      endTime: date.format(date.parseDate(props.shift.endDate), 'HH:mm'),
      startTimeError: false,
      endTimeError: false,
    };
  }

  onChangeDate = (text, key) => {
    this.setState({
      [`${key}Time`]: text.replace(regexes.timeChars, ''),
      [`${key}TimeError`]: false,
    }, () => {
      if (regexes.time.test(text)) {
        const [hours, minutes] = text.split(':');
        this.props.setHasTimeError(this.state.startTimeError || this.state.endTimeError, this.timeErrorId);
        this.props.onChange({
          ...this.props.shift,
          [`${key}Date`]: setMinutes(setHours(date.parseDate(this.props.shift[`${key}Date`]), hours), minutes),
        });
      } else {
        this.props.setHasTimeError(true, this.timeErrorId);
        this.setState({
          [`${key}TimeError`]: true,
        });
      }
    });
  }

  onChangeStartDate = (event) => {
    this.onChangeDate(event.target.value, 'start');
  }
  onChangeEndDate = (event) => {
    this.onChangeDate(event.target.value, 'end');
  }

  onChangeTimeType = (code) => {
    this.props.onChange({
      ...this.props.shift,
      code,
    });
  }

  toggleAllDay = () => {
    const { startDate, endDate } = this.props.shift;
    const { allDay, startTime, endTime } = this.state;

    let startHours = defaultDayStartHour;
    let startMinutes = 0;
    let endHours = defaultDayEndHour;
    let endMinutes = 0;
    let start = '';
    let end = '';
    if (allDay) {
      if (regexes.time.test(startTime)) {
        [startHours, startMinutes] = startTime.split(':');
      }
      if (regexes.time.test(endTime)) {
        [endHours, endMinutes] = endTime.split(':');
      }
      start = setHours(setMinutes(date.parseDate(startDate), startMinutes), startHours);
      end = setHours(setMinutes(date.parseDate(endDate), endMinutes), endHours);
    } else {
      start = startOfDay(date.parseDate(startDate));
      end = endOfDay(date.parseDate(endDate));
    }
    this.props.setHasTimeError(false, this.timeErrorId);
    this.props.onChange({
      ...this.props.shift,
      startDate: start,
      endDate: end,
    });
    this.setState({
      allDay: !allDay,
    });
  }

  onMouseDown = (event) => {
    if (this.buttonRef && this.buttonRef.contains(event.target)) {
      return false;
    }
    this.setState({ open: true });
  }

  requestClose = () => {
    this.setState({ open: false });
  }

  setRef = (component) => {
    this.ref = component;
  }

  setButtonRef = (component) => {
    this.buttonRef = component;
  }

  onRemove = () => {
    this.props.setHasTimeError(false, this.timeErrorId);
    this.props.onRemove(this.props.shift.id, this.props.shift.startDate);
  }

  render() {
    const { shift, shiftsCount, index, isDisabled } = this.props;
    const color = availabilitiesUtils.findColorForAvailability(this.props.timeTypes, shift.code);

    const threshold = shiftsCount % 4; // Amount of elements on the last line
    const classNames = classnames('shift', { 'bottom-row': shiftsCount - index <= threshold });
    if (!availabilitiesUtils.isVisible(shift, this.props.timeTypes)) {
      return null;
    }
    return (
      <button className={classNames} style={{ backgroundColor: Color(color).lighten(0.9) }} onMouseDown={this.onMouseDown} ref={this.setRef}>
        {this.state.allDay
          ? <span className="all-day">{translations.getLabel('lblAllDay')}</span>
          : (
            <React.Fragment>
              <span>{translations.getLabel('lblFrom')}</span>
              <input disabled={isDisabled} type="text" onChange={this.onChangeStartDate} className={classnames('from-time', { 'error': this.state.startTimeError })} value={this.state.startTime} />
              <span>{translations.getLabel('lblTo')}</span>
              <input disabled={isDisabled} type="text" onChange={this.onChangeEndDate} className={classnames('to-time', { 'error': this.state.endTimeError })} value={this.state.endTime} />
            </React.Fragment>
          )}
        <span className="spacer" />
        <IconButton className={`${isDisabled && 'disabled'}`} disabled={isDisabled} tag="CrossIcon" onClick={this.onRemove} ref={this.setButtonRef} />
        <Popup isDropdown open={!isDisabled && this.state.open} requestClose={this.requestClose} excludePressArea={[this.ref]}>
          <div className="checkbox-container">
            <Checkbox id="allDay" isChecked={availabilitiesUtils.isAllDay(this.props.shift)} label={translations.getLabel('lblAllDay')} onCheck={this.toggleAllDay} />
          </div>
          <Select selected={this.props.shift.code} values={availabilitiesUtils.getVisibleTimeTypes(this.props.timeTypes, this.props.inSchoolUntil, shift.startDate).map(timeType => ({ label: timeType.description, value: timeType.code, orb: timeType.color }))} onChange={this.onChangeTimeType} />
        </Popup>
      </button>
    );
  }
}

AvailabilityShift.propTypes = {
  isDisabled: PropTypes.bool,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  setHasTimeError: PropTypes.func.isRequired,
  shift: PropTypes.object.isRequired,
  shiftsCount: PropTypes.number.isRequired,
  timeTypes: PropTypes.array.isRequired,
  inSchoolUntil: PropTypes.string.isRequired,
};

AvailabilityShift.defaultProps = {
  isDisabled: false,
};

export default AvailabilityShift;
