import React, { FC, useEffect, useState } from 'react';
import { Button, Icon, Modal } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';

import { AppState } from '../../../../shared/redux/rootReducer';
import { SchedulePageSwitch } from '../../components';
import { identifiers } from '../../constants';
import { TimeScale } from '../../models';
import { configSelectors, scheduleActions, scheduleSelectors, shiftSwapActions } from '../../redux';
import { date, scheduleUtils, translations } from '../../utils';
import ShiftSwapCandidateSchedule from './ShiftSwapCandidateSchedule';

const ShiftSwapCandidate: FC<RouteComponentProps<{ id: string; date: string }>> = ({ match, history }) => {
  const dispatch = useDispatch();

  const { date: swapDate, id } = match.params;
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const applicants = useSelector((state: AppState) => scheduleSelectors.getShiftSwapApplicants(state, id));
  const myPlanning = useSelector((state: AppState) => scheduleSelectors.getPlanningForUser(state, identifiers.me, parseISO(swapDate), parseISO(swapDate), true))?.[0];

  const [selectedDate, setSelectedDate] = useState(parseISO(swapDate));
  const [selectedEmployeeId, setSelectedEmployeeId] = useState('');
  const [selectedSwapDate, setSelectedSwapDate] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const planning = useSelector((state: AppState) => scheduleSelectors.getPlanningForUser(state, selectedEmployeeId, parseISO(selectedSwapDate || swapDate), parseISO(selectedSwapDate || swapDate), true))?.[0];

  useEffect(() => {
    dispatch(new scheduleActions.GetShiftSwapApplicantsPlanningAction(
      {
        shiftSwapId: id,
      }));
  }, []);

  const select = (swapDate, id) => {
    setSelectedEmployeeId(id);
    setSelectedSwapDate(swapDate);
  };

  const onCancel = () => history.goBack();
  const onSave = () => setIsModalVisible(true);

  const onSaveConfirm = () => {
    setIsSaving(true);
    dispatch(new shiftSwapActions.RequestShiftSwapAction(
      {
        id,
        employmentId: applicants.find(a => a.employeeId === selectedEmployeeId)?.employmentId,
        selectedCounterSwapDate: selectedSwapDate,
        onSuccess: () => {
          setIsSaving(false);
          setIsModalVisible(false);
          history.goBack();
        },
        onError: (error) => {
          toast.error(error.detail);
          setIsSaving(false);
          setIsModalVisible(false);
        },
      }));
  };

  const myTimeSpan = myPlanning && date.formatFromUntilTime(date.format, { startDate: parseISO(myPlanning.shifts[0].startDate), endDate: parseISO(myPlanning.shifts[myPlanning.shifts.length - 1].endDate) });
  const tradeTimeSpan = planning && planning?.shifts?.length > 0 && date.formatFromUntilTime(date.format, { startDate: parseISO(planning.shifts[0].startDate), endDate: parseISO(planning.shifts[planning.shifts.length - 1].endDate) });

  return (
    <div className="page fixed-width availabilities shift-swap">
      <div className="schedule-toolbar">
        <SchedulePageSwitch
          dateRange={TimeScale.Week}
          selectedDate={selectedDate}
          changeDate={(modifier: number) => setSelectedDate(scheduleUtils.getModifiedDate(TimeScale.Week, selectedDate, modifier, weekStartsOn))}
          setSpecificDate={(swapDate: Date) => setSelectedDate(swapDate)}
        />
      </div>

      {
        applicants &&
        <ShiftSwapCandidateSchedule
          swapDate={swapDate}
          onSelect={select}
          selectedShifts={[selectedSwapDate]}
          selectedUsers={[selectedEmployeeId]}
          selectedDate={selectedDate}
          swapId={id}
        />
      }

      <Modal
        open={isModalVisible}
        requestClose={() => setIsModalVisible(false)}
        title={translations.getLabel('titleSendSwap')}
        leftButtonProps={{
          onClick: () => setIsModalVisible(false),
          label: translations.getLabel('cancel'),
        }}
        rightButtonProps={{
          onClick: onSaveConfirm,
          label: translations.getLabel('btnSend'),
          isLoading: isSaving,
        }}
      >
        <p className="modal-content">{translations.getLabel('lblConfirmSwapWith', { name: `${planning?.employeeFirstName} ${planning?.employeeLastName}` })}</p>
        <div className="date-time">
          <Icon tag="CalendarIcon" customSize={2} color="primaryDark" />
          <span className="label">{format(parseISO(swapDate), 'EEEEEE d MMM')}</span>
          <Icon tag="ClockIcon" customSize={2} color="primaryDark" />
          <span className="label">{myTimeSpan}</span>
        </div>
        {applicants?.find(a => a.employeeId === selectedEmployeeId)?.counterSwapDates?.length > 0 &&
          <>
            <p className="dates-separator">{translations.getLabel('lblWith')}</p>
            <div className="date-time">
              <Icon tag="CalendarIcon" customSize={2} color="primaryDark" />
              <span className="label">{!!selectedSwapDate && format(parseISO(selectedSwapDate), 'EEEEEE d MMM')}</span>
              {!!tradeTimeSpan && <>
                <Icon tag="ClockIcon" customSize={2} color="primaryDark" />
                <span className="label">{tradeTimeSpan}</span>
              </>}
            </div>
          </>
        }
        <p className="subtext">{translations.getLabel('lblShiftSwapApproval')}</p>
      </Modal>

      <footer>
        <div>
          <Button theme="inverse" onClick={onCancel}>{translations.getLabel('cancel')}</Button>
          <Button disabled={!selectedEmployeeId} onClick={onSave}>{translations.getLabel('lblRequestShiftSwap')}</Button>
        </div>
      </footer>
    </div>
  );
};

export default ShiftSwapCandidate;
