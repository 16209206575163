import { produce } from 'immer';
import { format, isValid, parseISO } from 'date-fns';

import ActionType from './types';
import { IAvailabilitiesDayList, ITimeType, IAvailability } from '../../models';
import { HttpError } from '../../http';
import { Actions } from './actions';

export const formatForIndex = (date: Date) => format(date, 'yyyy-MM-dd');

// TODO: remove data attribute
export interface AvailabilitiesState {
  data: IAvailabilitiesDayList[]
  availabilities: Record<string, IAvailability[]>
  isLoading: Boolean;
  serverError: HttpError.IServerError;
  timeTypes: ITimeType[];
  totalCount: number;
}

export const initialState: AvailabilitiesState = {
  data: [],
  availabilities: {},
  isLoading: false,
  serverError: null,
  timeTypes: [],
  totalCount: 0,
};

const availabilities = produce((draft: AvailabilitiesState, action: Actions) => {
  switch (action.type) {
    case ActionType.GetAvailabilities:
      draft.isLoading = true;
      break;

    case ActionType.GetAvailabilitiesFulfilled:
      draft.isLoading = false;
      draft.data = action.payload.data;
      draft.timeTypes = action.payload.timeTypes;
      draft.totalCount = action.payload.totalCount;
      action.payload.data.forEach((availabilitiesDay: IAvailabilitiesDayList) => {
        if (availabilitiesDay.day && isValid(parseISO(availabilitiesDay.day))) draft.availabilities[formatForIndex(parseISO(availabilitiesDay.day))] = availabilitiesDay.availabilities;
      });
      break;

    case ActionType.GetAvailabilitiesRejected:
    case ActionType.PutAvailabilitiesRejected:
      draft.isLoading = false;
      draft.serverError = action.payload.error;
      break;

    // No default case is needed, since produce returns by default the draft state
  }
}, initialState);

export default availabilities;
