import React, { FC, useEffect, useRef, useState } from 'react';
import { SelectPopup } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { routes } from '../../../../constants';
import { useInfiniteScroll,useToggle } from '../../../../hooks';
import { IRequestDepartment, RequestStatus, RequestType } from '../../../../models';
import { requestsActions,requestsSelectors } from '../../../../redux';
import { translations } from '../../../../utils';
import DepartmentRequestsList from './DepartmentRequestsList';
import RequestDetail from './RequestDetail';

import '../requestInbox.scss';

const ShiftOfferManagerInboxView: FC<RouteComponentProps<{ departmentId: string; id: string }>> = ({ match, history }) => {
  const dispatch = useDispatch();
  const [showFilter, toggleShowFilter] = useToggle(false);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState<string>(match.params.departmentId);
  const departments = useSelector(requestsSelectors.getShiftOfferDepartments);
  const isDepartmentShiftOffersLoading = useSelector(requestsSelectors.isDepartmentShiftOffersLoading);
  const isMoreDepartmentShiftOffersLoading = useSelector(requestsSelectors.isMoreDepartmentShiftOffersLoading);
  const metaData = useSelector(requestsSelectors.getDepartmentMetaData);
  const filter = useSelector((state: AppState) => requestsSelectors.getRequestFilter(state, RequestType.ShiftOffer));
  const scrollRef = useRef(null);
  const filterRef = useRef(null);
  useEffect(() => {
    if (!departments?.length) {
      dispatch(new requestsActions.GetShiftOfferDepartmentsAction({ status: filter, onSuccess: onLoadDepartments }));
    } else {
      onLoadDepartments(departments);
    }
  }, []);

  useEffect(() => {
    dispatch(new requestsActions.GetShiftOfferDepartmentsAction({ status: filter, onSuccess: onLoadDepartments }));
    resetOffset();
  }, [filter]);

  const onLoadDepartments = (departmentsResponse: IRequestDepartment[]) => {
    if (!selectedDepartmentId) setSelectedDepartmentId(departmentsResponse[0]?.departmentId);
  };

  const { resetOffset } = useInfiniteScroll(
    (offset: number) => dispatch(new requestsActions.GetDepartmentShiftOffersAction({ departmentId: selectedDepartmentId, offset, status: filter })),
    metaData,
    isDepartmentShiftOffersLoading || isMoreDepartmentShiftOffersLoading,
    scrollRef.current,
  );

  const onSwitchDepartment = (id: string) => {
    resetOffset();
    setSelectedDepartmentId(id);

    history.replace(`${routes.scheduleRequests.shiftOfferManagerInbox[0]}/${id || ''}`);
  };


  return (
    <div className="inbox">
      <section className="inbox-container">
        <div className="inbox-department-menu-wrapper">
          <div className="request-filter">
            <button ref={filterRef} type="button" onClick={toggleShowFilter} className="request-filter">
              {translations.getLabel('lblStatus')}: {filter === RequestStatus.Open ? translations.getLabel('lblUncomplete') : translations.getLabel('lblAll')}
            </button>
            <SelectPopup
              excludePressArea={filterRef.current}
              requestClose={toggleShowFilter}
              data={[{ value: RequestStatus.All, label: translations.getLabel('lblAll') }, { value: RequestStatus.Open, label: translations.getLabel('lblUncomplete') }]}
              isSearchEnabled={false}
              popupOpen={showFilter}
              selected={filter}
              onValueSelected={(filter: RequestStatus) => { dispatch(new requestsActions.SetRequestFilterAction({ filter, type: RequestType.ShiftOffer })); toggleShowFilter(); }} />
          </div>
          <aside ref={scrollRef} className="inbox-menu department-inbox-menu">
            {departments?.map(department => (
              <DepartmentRequestsList
                key={department.departmentId}
                isOpen={department.departmentId === selectedDepartmentId}
                onClickDepartment={onSwitchDepartment}
                history={history}
                status={filter}
                selectedRequestId={match.params.id}
                department={department} />
            ))}
          </aside >
        </div>
        <RequestDetail id={match.params.id} isLoading={isDepartmentShiftOffersLoading} departmentId={selectedDepartmentId} status={filter} />
      </section>
    </div>

  );
};

export default ShiftOfferManagerInboxView;
