import { produce } from 'immer';

import ActionType from './types';
import { INotificationPreference, IUser, IPeriod, Period } from '../../models';
import { Actions } from './actions';
import { IServerError } from '../../http/HttpError';

export interface UserState {
  icsUrl: string;
  isIcsUrlLoading: boolean;
  isLoading: boolean;
  isNotificationPreferencesLoading: boolean;
  isUpdateAvatarLoading: boolean;
  isUpdateNotificationPreferencesLoading: boolean;
  isUpdateUserLoading: boolean;
  isUserPeriodsLoading: boolean;
  notificationPreferences: INotificationPreference[];
  periods: IPeriod;
  userDetails: IUser;
  changePasswordError?: IServerError;
}

export const initialState: UserState = {
  icsUrl: '',
  isIcsUrlLoading: false,
  isLoading: false,
  isNotificationPreferencesLoading: false,
  isUpdateAvatarLoading: false,
  isUpdateNotificationPreferencesLoading: false,
  isUpdateUserLoading: false,
  isUserPeriodsLoading: false,
  changePasswordError: null,
  notificationPreferences: [],
  periods: {
    [Period.AvailabilityRequestPeriod]: null,
    [Period.VacationRequestPeriod]: null,
    [Period.ShiftTimeAdjustmentPeriod]: null,
  },
  userDetails: null,
};

const user = produce((draft: UserState, action: Actions) => {
  switch (action.type) {
    case ActionType.ChangePassword:
      draft.changePasswordError = null;
      break;

    case ActionType.ChangePasswordRejected:
      draft.changePasswordError = action.payload.error;
      break;

    case ActionType.ClearPasswordError:
      draft.changePasswordError = null;
      break;

    case ActionType.GetNotificationPreferences:
      draft.isNotificationPreferencesLoading = true;
      break;

    case ActionType.GetNotificationPreferencesFulfilled:
      draft.isNotificationPreferencesLoading = false;
      draft.notificationPreferences = action.payload.data;
      break;

    case ActionType.GetNotificationPreferencesRejected:
      draft.isNotificationPreferencesLoading = false;
      break;

    case ActionType.GetUser:
      draft.isLoading = true;
      break;

    case ActionType.GetUserFulfilled:
      draft.isLoading = false;
      draft.userDetails = action.payload.data;
      break;

    case ActionType.GetUserRejected:
    case ActionType.GetUserPeriodsRejected:
      draft.isLoading = false;
      break;

    case ActionType.GetUserPeriods:
      draft.isUserPeriodsLoading = true;
      break;
    case ActionType.GetUserPeriodsFulfilled:
      draft.isUserPeriodsLoading = false;
      action.payload.data.map(period => draft.periods[period?.[0].type] = period?.[0]);
      break;
    case ActionType.GetUserPeriodsRejected:
      draft.isUserPeriodsLoading = false;
      break;

    case ActionType.UpdateNotificationPreferences:
      draft.isUpdateNotificationPreferencesLoading = true;
      break;

    case ActionType.UpdateNotificationPreferencesFulfilled:
      draft.notificationPreferences = action.payload.data;
      draft.isUpdateNotificationPreferencesLoading = false;
      break;

    case ActionType.UpdateNotificationPreferencesRejected:
      draft.isUpdateNotificationPreferencesLoading = false;
      break;

    case ActionType.UpdateUser:
      draft.isUpdateUserLoading = true;
      break;

    case ActionType.UpdateUserFulfilled:
      draft.isUpdateUserLoading = false;
      break;

    case ActionType.UpdateUserRejected:
      draft.isUpdateUserLoading = false;
      break;

    case ActionType.UpdateAvatar:
      draft.isUpdateAvatarLoading = true;
      break;

    case ActionType.UpdateAvatarFulfilled:
      draft.userDetails.profilePictureUrl = action.payload.url;
      draft.isUpdateAvatarLoading = false;
      break;

    case ActionType.DeleteAvatarFulfilled:
      draft.userDetails.profilePictureUrl = null;
      draft.isUpdateAvatarLoading = false;
      break;

    case ActionType.UpdateAvatarRejected:
      draft.isUpdateAvatarLoading = false;
      break;

    case ActionType.GetIcsUrl:
      draft.isIcsUrlLoading = true;
      break;

    case ActionType.GetIcsUrlRejected:
      draft.isIcsUrlLoading = false;
      break;

    case ActionType.GetIcsUrlFulfilled:
      draft.icsUrl = action.payload.url;
      draft.isIcsUrlLoading = false;
      break;
    // No default case is needed, since produce returns by default the draft state
  }
}, initialState);

export default user;
