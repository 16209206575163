import React, { FC, useCallback, useRef } from 'react';
import { Icon } from 'react-ess-components';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { useToggle } from '../../../../hooks';
import { IDayPlanning, RequestStatus, ShiftSwapStatus } from '../../../../models';
import { configSelectors, scheduleSelectors } from '../../../../redux';
import { translations } from '../../../../utils';
import ExternalVacationDetailPopup from '../detailPopup/ExternalVacationDetailPopup';

import './weekDayRequest.scss';

interface Props {
  planning: IDayPlanning;
  parentScrollRef: HTMLElement;
}

const getIconTag = (status: RequestStatus | ShiftSwapStatus) => {
  switch (status) {
    case ShiftSwapStatus.Accepted: return 'CheckIcon';
    case ShiftSwapStatus.Denied: return 'CrossIcon';
    case ShiftSwapStatus.Open: return 'ChangeIcon';
    case ShiftSwapStatus.Pending:
    default: return 'QuestionmarkIcon';
  }
};

const getStatusColor = (status: RequestStatus | ShiftSwapStatus) => {
  switch (status) {
    case RequestStatus.Completed:
    case ShiftSwapStatus.Accepted: return 'success';
    case ShiftSwapStatus.Denied: return 'error';
    default: return 'primaryDark';
  }
};

const getShiftSwapStatusLabel = (status: RequestStatus, numberOfApplicants = 0) => {
  switch (status) {
    case RequestStatus.Denied: return translations.getLabel('lblRefused');
    case RequestStatus.Open: return translations.getLabel('lblShiftSwapNumberOfApplicants', { amount: numberOfApplicants });
    case RequestStatus.Accepted: return translations.getLabel('lblApproved');
    case RequestStatus.Pending:
    default: return translations.getLabel('lblRequested');
  }
};

const getVacationStatusLabel = (status: RequestStatus) => {
  switch (status) {
    case RequestStatus.Denied: return translations.getLabel('lblRefused');
    case RequestStatus.Completed:
    case RequestStatus.Accepted: return translations.getLabel('lblApproved');
    case RequestStatus.Open:
    default: return translations.getLabel('lblRequested');
  }
};

const WeekDayRequests: FC<Props> = ({ planning, parentScrollRef }) => {
  const companyConfig = useSelector(configSelectors.getCompanyConfig);
  const [isPopupOpen, togglePopupOpen] = useToggle(false);
  const { shiftSwapRequest, vacationRequest: planningVacationRequest } = planning;
  const planningVacationOutsideSPX = useSelector((state: AppState) => scheduleSelectors.getExternalVacationRequestForDate(state, planning.date));
  const ref = useRef(null);

  const vacationRequest = planningVacationRequest || planningVacationOutsideSPX;

  const renderContent = useCallback(() => {
    const status = shiftSwapRequest?.status || vacationRequest?.status;
    return <>
      <span className={classnames('status-icon', getStatusColor(status))}>
        <Icon tag={getIconTag(status)} color="white" small />
      </span>
      <div>
        <span className="type">{translations.getLabel(shiftSwapRequest ? 'lblSwap' : 'lblVacation')}</span>
        <span className="status">{shiftSwapRequest ? getShiftSwapStatusLabel(shiftSwapRequest.status, shiftSwapRequest?.numberOfApplicants) : getVacationStatusLabel(vacationRequest.status)}</span>
      </div>
    </>;
  }, [shiftSwapRequest, vacationRequest, translations]);

  const className = classnames('week-day-request');
  // If no requests available, return empty div
  if (!shiftSwapRequest && !vacationRequest) return <div className={className} />;

  if(!companyConfig.enableSpxcVacationRequests) {
    return (
      <>
        <button ref={ref} className={`${className} stripped-button`} onClick={togglePopupOpen}>
          {renderContent()}
        </button>
        {!companyConfig?.enableSpxcVacationRequests && <ExternalVacationDetailPopup isOpen={isPopupOpen} vacationRequest={planningVacationOutsideSPX} scrollRef={parentScrollRef} close={togglePopupOpen} parentRef={ref.current} />}
      </>
    );
  }

  return (
    <div className={className}>{renderContent()}</div>
  );
};

export default WeekDayRequests;
