import React, { FC } from 'react';
import { Icon, Modal, Position } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, RouteComponentProps, withRouter } from 'react-router-dom';

import { IMAGES } from '../../assets';
import { routes, storageItems } from '../../constants';
import { useToggle } from '../../hooks';
import { chatSelectors, configSelectors, loginActions, loginSelectors, notificationsSelectors, userSelectors } from '../../redux';
import { firebase, notificationUtils, translations } from '../../utils';
import EmploymentSelect from '../employmentSelect/EmploymentSelect';
import ChatsMenu from './ChatsMenu';
import NavBarButton from './NavBarButton';
import NavBarSelect from './NavBarSelect';
import NotificationsMenu from './NotificationsMenu';
import ProfileMenu from './ProfileMenu';

import './navbar.scss';

export const NAV_BAR_HEIGHT = 64;

const NavBar: FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const amountUnreadChats = useSelector(chatSelectors.amountUnread);
  const amountUnreadNotifications = useSelector(notificationsSelectors.amountUnread);
  const companyConfig = useSelector(configSelectors.getCompanyConfig);
  const companyPreferences = useSelector(configSelectors.getCompanyPreferences);
  const employments = useSelector(loginSelectors.getEmployments);
  const holdingPreferences = useSelector(configSelectors.getHoldingPreferences);
  const user = useSelector(userSelectors.getUser);
  const isAdmin = useSelector(userSelectors.isAdmin);
  const [isSwitchEmploymentModalShown, toggleIsSwitchEmploymentModalShown] = useToggle(false);

  const handleLogout = () => {
    firebase.unRegister();
    dispatch(new loginActions.LogoutAction({
      onSuccess: () => {
        localStorage.setItem(storageItems.IS_LOGGED_IN, 'false');
        notificationUtils.removeNotificationInterval();
      },
    }));
  };

  const selectEmployment = async (id) => {
    dispatch(new loginActions.UpdateEmploymentAction({
      id,
      onSuccess: () => {
        toggleIsSwitchEmploymentModalShown();
        // we reload the whole page, because otherwise we'll have leftovers in the schedule and other pages
        window.location.assign('/');
      },
    }));
  };

  const goToNotifications = () => {
    history.push(routes.notifications);
  };

  const goToChats = () => {
    history.push(routes.chat[0]);
  };

  const employment = employments.find(employment => employment.id === user?.employmentId);
  const modules = companyConfig?.modules;
  const noInboxModulesActive = () => {
    if (!modules.counters && !modules.schedule && !modules.shiftOffer && !modules.shiftSwap && !modules.timeRegistration) return true;
    return false;
  };

  return (
    <div className="app-header" style={{
      height: `${NAV_BAR_HEIGHT / 10}rem`,
    }}>
      <a className="skip-main" href="#main">{translations.getLabel('lblSkipToContent')}</a>

      <div className="app-header-left" >
        <div className="hamburger-menu">
          <NavBarSelect label={translations.getLabel('lblMenu')} position={Position.TopLeft} icon="HamburgerIcon">
            <div>
              <ul>
                <li><NavBarButton label="navDashboard" to={routes.dashboard} /></li>
                <li>{modules?.schedule && <NavBarButton label="navSchedule" to={routes.schedule[0]} />}</li>
                <li>{modules?.counters && <NavBarButton label="navCounters" to={routes.counters} />}</li>
                <li>{modules?.timeRegistration && user?.hasTimeRegistration && <NavBarButton label="navTimeRegistration" to={routes.timeRegistration} />}</li>
                <li>{modules?.library && <NavBarButton label="navLibrary" to={routes.library[0]} />}</li>
              </ul>
            </div>
          </NavBarSelect>
        </div>
        <NavLink className="logo" activeClassName="active" to={routes.dashboard}>
          <img src={companyPreferences?.logoUrl || IMAGES.Logo} alt={`Logo ${holdingPreferences?.name}`} />
          <h2>{employment?.companyName || holdingPreferences?.name}</h2>
        </NavLink>
      </div>
      <div className="app-header-center">
        <ul className="horizontal">
          <li><NavBarButton label="navDashboard" to={routes.dashboard} /></li>
          <li>{modules?.schedule && <NavBarButton label="navSchedule" to={routes.schedule[0]} />}</li>
          <li>{modules?.counters && <NavBarButton label="navCounters" to={routes.counters} />}</li>
          <li>{modules?.timeRegistration && user?.hasTimeRegistration && <NavBarButton label="navTimeRegistration" to={routes.timeRegistration} />}</li>
          <li>{modules?.library && <NavBarButton label="navLibrary" to={routes.library[0]} />}</li>
        </ul>
      </div>
      <div className="app-header-right">
        <ul className="horizontal">
          {modules?.timeRegistration && user?.hasTimeRegistration &&
            <li>
              <Link aria-label={translations.getLabel('titleClockIn')} title={translations.getLabel('titleClockIn')} className="clock-in" to={routes.clockIn}><Icon customSize={2.2} tag="ClockIcon" title="" /> <span>{translations.getLabel('btnClockIn')}</span></Link>
            </li>
          }
          <li>
            <NavBarSelect label={translations.getLabel('lblNotifications')} icon="BellIcon" modal unreadBadge={amountUnreadNotifications} rightButtonProps={{ label: translations.getLabel('lblViewAll'), onClick: goToNotifications }}>
              <NotificationsMenu />
            </NavBarSelect>
          </li>
          <li>
            <NavBarSelect label={translations.getLabel('titleChat')} icon="ChatIcon" modal unreadBadge={amountUnreadChats} rightButtonProps={{ label: translations.getLabel('lblViewAll'), onClick: goToChats }}>
              <ChatsMenu />
            </NavBarSelect>
          </li>
          <li>
            {!noInboxModulesActive() && <NavBarButton label={translations.getLabel('titleRequestInbox')} icon="MailboxIcon" to={routes.scheduleRequests.inbox[0]} />}
          </li>
          <li>
            <NavBarSelect label={translations.getLabel('titleProfile')} icon="UserIcon" position={Position.Top}>
              <ProfileMenu firstName={user?.firstName} lastName={user?.lastName} avatar={user?.profilePictureUrl} employments={employments} switchEmployment={toggleIsSwitchEmploymentModalShown} isAdmin={isAdmin} logout={handleLogout} />
            </NavBarSelect>
          </li>
        </ul>
      </div>

      <Modal
        open={isSwitchEmploymentModalShown}
        requestClose={toggleIsSwitchEmploymentModalShown}
        title={translations.getLabel('lblChangeEmployment')}
        leftButtonProps={{ label: translations.getLabel('btnLogout'), onClick: handleLogout, className: 'full-width' }}
      >
        <div className="modal-content-container">
          <EmploymentSelect employments={employments} switchEmployment={selectEmployment} />
        </div>
      </Modal>
    </div>
  );
};

export default withRouter(NavBar);
