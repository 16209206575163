import React, { FC } from 'react';
import { FormattedValue } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';

import { ErrorCloud } from '../../../assets/images';
import { ICounter } from '../../../models';
import { countersSelectors } from '../../../redux';
import { translations } from '../../../utils';

const CountersTab: FC = () => {
  const isLoading = useSelector(countersSelectors.isLoading);
  const counters = useSelector(countersSelectors.getCounters);
  const serverError = useSelector(countersSelectors.getServerError);

  const renderSection = (counterGroup) => {
    return (
      <div className="group-container" key={counterGroup.group} >
        <h3 className="title">{counterGroup.group}</h3>
        <div className="counters-container" >
          {counterGroup.counters.map(renderCounter)}
        </div>
      </div>
    );
  };

  const renderCounter = (counter: ICounter, i: number) => {
    return (
      <div className="counter" key={`${counter.description}-${i}`}>
        <p className="description">{counter.description}</p><FormattedValue value={counter.value} type={counter.type} />
      </div>
    );
  };

  const renderError = () => {
    return <div className="error-container">
      <div className="cloud">
        <ErrorCloud />
      </div>
      <p className="error-title">{translations.getLabel('lblOops')}</p>
      <p className="error-text">{translations.getLabel('lblCouldNotLoadInfo')}</p>
      <p className="error-text">{translations.getLabel('lbllRefreshBrowser')}</p>
    </div>;
  };

  const renderSkeleton = (key) => (
    <div className="counter loading" key={key}>
      <Skeleton width="100%" height="5.3rem" />
    </div>
  );
  
  if (serverError) return renderError();

  return (
    <div className="page fixed-width counters">
      {
        isLoading
          ?
          <div className="group-container">
            <h3 className="title"><Skeleton width="25rem" /></h3>
            <div className="counters-container">
              {[0, 1, 2].map(renderSkeleton)}
            </div>
          </div>
          :
          counters.map(renderSection)
      }
    </div >
  );
};

export default CountersTab;
