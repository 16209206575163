export enum ActionType {
  GetDepartments = 'GET_DEPARTMENTS',
  GetDepartmentsRejected = 'GET_DEPARTMENTS_REJECTED',
  GetDepartmentsFulfilled = 'GET_DEPARTMENTS_FULFILLED',
  
  GetCompanyDepartments = 'GET_COMPANY_DEPARTMENTS',
  GetCompanyDepartmentsRejected = 'GET_COMPANY_DEPARTMENTS_REJECTED',
  GetCompanyDepartmentsFulfilled = 'GET_COMPANY_DEPARTMENTS_FULFILLED',

  GetDepartmentEmployees = 'GET_DEPARTMENT_EMPLOYEES',
  GetDepartmentEmployeesFulfilled = 'GET_DEPARTMENT_EMPLOYEES_FULFILLED',
  GetDepartmentEmployeesRejected = 'GET_DEPARTMENT_EMPLOYEES_REJECTED',

  GetDepartmentPeriods = 'GET_PERIODS',
  GetDepartmentPeriodsRejected = 'GET_PERIODS_REJECTED',
  GetDepartmentPeriodsFulfilled = 'GET_PERIODS_FULFILLED',

  GetAbsolutePeriod = 'GET_ABSOLUTE_PERIOD',
  GetAbsolutePeriodRejected = 'GET_ABSOLUTE_PERIOD_REJECTED',
  GetAbsolutePeriodFulfilled = 'GET_ABSOLUTE_PERIOD_FULFILLED',

  UpdateDepartmentPeriods = 'UPDATE_PERIOD',
  UpdateDepartmentPeriodsRejected = 'UPDATE_PERIOD_REJECTED',
  UpdateDepartmentPeriodsFulfilled = 'UPDATE_PERIOD_FULFILLED',

  ResetDepartmentPeriod = 'RESET_PERIOD',
  ResetDepartmentPeriodRejected = 'RESET_PERIOD_REJECTED',
  ResetDepartmentPeriodFulfilled = 'RESET_PERIOD_FULFILLED',
}

export default ActionType;
