import { useLayoutEffect, useState } from 'react';

import { HttpMetadataPagingResponse } from '../http/HttpMetadata';

const SCROLL_OFFSET = 150;

interface Response {
  resetOffset: () => void;
}

const useInfiniteScroll = (fetchData: (offset: number) => void, metadata: HttpMetadataPagingResponse, isLoading: boolean, element?: HTMLElement): Response => {
  const [offset, setOffset] = useState<number>(metadata?.count || 0);

  const didFetchTotal = (): boolean => {
    return metadata && offset >= metadata?.totalCount;
  };

  const startFetchData = () => {
    const newOffset = offset + (metadata?.count || 0);
    setOffset(newOffset);
    if (newOffset < metadata?.totalCount) {
      fetchData(newOffset);
    }
  };

  const shouldFetchData = (): boolean => {
    if (isLoading) return false;
    if (didFetchTotal()) return false;
    if (element) {
      return element.scrollTop + element.getBoundingClientRect().height >= element.scrollHeight - SCROLL_OFFSET;
    }
    return window.scrollY + window.innerHeight >= document.documentElement.scrollHeight - SCROLL_OFFSET;
  };

  // Make sure we re-fetch, when there are too little elements to scroll
  useLayoutEffect(() => {
    if (element?.scrollHeight <= element?.getBoundingClientRect().height && !isLoading && !didFetchTotal()) {
      startFetchData();
    }
  }, [metadata]);

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (shouldFetchData()) {
        startFetchData();
      }
    };
    (element || window).addEventListener('scroll', handleScroll);
    return () => (element || window).removeEventListener('scroll', handleScroll);
  }, [isLoading, metadata, offset]);

  return {
    resetOffset: () => setOffset(0),
  };
};

export default useInfiniteScroll;