export enum ActionType {
  ToggleShiftOfferModal = 'TOGGLE_SHIFT_OFFER_MODAL',

  CreateTemplate = 'CREATE_TEMPLATE',
  CreateTemplateRejected = 'CREATE_TEMPLATE_REJECTED',
  CreateTemplateFulfilled = 'CREATE_TEMPLATE_FULFILLED',

  GetTemplates = 'GET_TEMPLATES',
  GetTemplatesRejected = 'GET_TEMPLATES_REJECTED',
  GetTemplatesFulfilled = 'GET_TEMPLATES_FULFILLED',

  GetShiftOffers = 'GET_SHIFT_OFFERS',
  GetShiftOffersRejected = 'GET_SHIFT_OFFERS_REJECTED',
  GetShiftOffersFulfilled = 'GET_SHIFT_OFFERS_FULFILLED',

  CreateShiftOffer = 'CREATE_SHIFT_OFFER',
  CreateShiftOfferRejected = 'CREATE_SHIFT_OFFER_REJECTED',
  CreateShiftOfferFulfilled = 'CREATE_SHIFT_OFFER_FULFILLED',

  UpdateShiftOfferApplicantStatus = 'UPDATE_SHIFT_OFFER_APPLICANT_STATUS',
  UpdateShiftOfferApplicantStatusRejected = 'UPDATE_SHIFT_OFFER_APPLICANT_STATUS_REJECTED',
  UpdateShiftOfferApplicantStatusFulfilled = 'UPDATE_SHIFT_OFFER_APPLICANT_STATUS_FULFILLED',

  GetScheduleDetail = 'GET_SCHEDULE_DETAIL',
  GetScheduleDetailFulfilled = 'GET_SCHEDULE_DETAIL_FULFILLED',
  GetScheduleDetailRejected = 'GET_SCHEDULE_DETAIL_REJECTED',
}

export default ActionType;