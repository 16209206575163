import { eachDayOfInterval } from 'date-fns';
import { createSelector } from 'reselect';
import { adjustedShiftTimeActions } from '..';
import { IDayShiftSwap, IShift } from '../../models';
import { scheduleUtils } from '../../utils';
import { AppState } from '../rootReducer';
import { AdjustedShiftTimeState } from './reducer';

const selectNode = (state: AppState) => state.adjustedShiftTime;

export const showAdjustedShiftTimeModal = createSelector(
  selectNode,
  (state: AdjustedShiftTimeState): boolean => state.showAdjustedShiftTimeModal,
);

export const getShift = createSelector(
  selectNode,
  (state: AdjustedShiftTimeState): IShift => state.shift
);

export const isCreateRequestLoading = createSelector(
  selectNode,
  (state: AdjustedShiftTimeState) => state.isCreateRequestLoading,
);