import { Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { exhaustMap, map, catchError } from 'rxjs/operators';

import ActionType from './types';
import { forgotPasswordService } from '../../services';
import {
  RequestForgotPasswordAction,
  RequestForgotPasswordFulfilledAction,
  RequestForgotPasswordRejectedAction,
  ResetPasswordAction,
  ResetPasswordFulfilledAction,
  ResetPasswordRejectedAction
} from './actions';
import { IResetPassword } from '../../models/resetPassword';

export const requestForgotPasswordEpic$: Epic = action$ =>
  action$.ofType(ActionType.RequestForgotPassword).pipe(
    exhaustMap(({ payload }: RequestForgotPasswordAction) =>
      from(forgotPasswordService.requestForgotPassword(payload.username, payload.holding)).pipe(
        map(result => {
          payload.onSuccess?.(result);
          return new RequestForgotPasswordFulfilledAction();
        }),
        catchError(error => {
          payload.onError?.(error);
          return of(new RequestForgotPasswordRejectedAction({ error }));
        })
      )
    ),
  );

export const resetPasswordEpic$: Epic = action$ =>
  action$.ofType(ActionType.ResetPassword).pipe(
    exhaustMap(({ payload }: ResetPasswordAction) =>
      from(forgotPasswordService.resetPassword(payload.password, payload.token, payload.holding, payload.shouldPersistSession)).pipe(
        map(() => {
          payload.onSuccess?.();
          return new ResetPasswordFulfilledAction();
        }),
        catchError(error => {
          payload.onError?.(error);
          return of(new ResetPasswordRejectedAction({ error }));
        })
      )
    ),
  );

const epics = [
  requestForgotPasswordEpic$,
  resetPasswordEpic$
];

export default epics;
