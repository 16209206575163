export interface ILabeledLanguage {
  label: string;
  value: ISOLanguage;
}

export type Locale = 'en' | 'de' | 'fr' | 'nl';

export enum ISOLanguage {
  Be = 'nl_BE',
  De = 'de_DE',
  En = 'en_US',
  Fr = 'fr_FR',
  Nl = 'nl_NL',
}

export enum UserLanguage {
  Be = 'nl',
  De = 'de',
  En = 'en',
  Fr = 'fr',
  Nl = 'nl',
}