import React from 'react';
import { FormattedValue, Orb } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';

import VacationWidgetBase from '../../../../../shared/components/vacationWidget/VacationWidget.container';
import { CounterType } from '../../../models';
import { translations } from '../../../utils';
import CircleGraph, { COLORS } from '../../shared/circleGraph/CircleGraph';
import Widget from '../../widget/Widget';

import './vacationWidget.scss';

class VacationWidget extends VacationWidgetBase {
  renderLegend = (item, i) => {
    if (item.value) {
      return (
        <div className="legend-item" key={i}>
          <FormattedValue type={CounterType.Time} value={item.value} description={item.description} />
          <Orb color={COLORS[i].color} />
        </div>
      );
    }
  }

  render() {
    return (
      <Widget title={translations.getLabel('titleWidgetVacation')} left>
        <div className="vacation-content">
          {(!this.state.isLoading && this.props.vacation.length === 0)
            ? <p className="empty-text">{translations.getLabel('lblNoVacation')}</p>
            : <React.Fragment>
              {this.state.isLoading
                ? <Skeleton circle={true} height={180} width={180} />
                : (
                  <div className="graph-container">
                    <CircleGraph values={this.getValues()} />
                    <div className="inner-graph">
                      <FormattedValue large value={this.getTotal()} type={CounterType.Time} description={translations.getLabel('lblTotal')} />
                    </div>
                  </div>
                )}
              <div className="vacation-legend">
                {this.state.isLoading
                  ? <Skeleton width={300} height={30} />
                  : this.props.vacation.map(this.renderLegend)
                }
              </div>
            </React.Fragment>
          }
        </div>
      </Widget>
    );
  }
}

export default VacationWidget;
