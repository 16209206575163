import React, { FC } from 'react';
import { Button, Icon } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';
import { isValid, parseISO } from 'date-fns';

import { AppState } from '../../../../shared/redux/rootReducer';
import { IInterval, IShift, Period, RequestStatus } from '../../models';
import { adjustedShiftTimeActions, configSelectors, userSelectors } from '../../redux';
import { date, requestsUtils, translations } from '../../utils';
import Footer from './shiftTime/shiftTimeStatus';

import './shift.scss';

type Props = {
  shift: IShift;
  hideIntervals?: boolean;
  showAdjustedShifts?: boolean;
  closeParentPopup?: () => void;
}

const Shift: FC<Props> = ({ shift, hideIntervals, showAdjustedShifts, closeParentPopup }) => {
  const dispatch = useDispatch();
  const shiftTimePeriod = useSelector((state: AppState) => userSelectors.getPeriods(state, Period.ShiftTimeAdjustmentPeriod));
  const isShiftTimeAdjustmentsEnabled = useSelector(configSelectors.isShiftTimeAdjustmentsEnabled);

  const renderInterval = (interval: IInterval, i: number) => {
    return (
      <div className="interval" key={`interval-${i}`}>
        {date.formatFromUntilTime(date.format, { startDate: parseISO(interval.startDate), endDate: parseISO(interval.endDate) })} {interval.timeType}
      </div>
    );
  };

  const startDate = parseISO(shift.startDate);
  const timeSpan = date.formatFromUntilTime(date.format, { startDate, endDate: parseISO(shift.endDate) });

  const adjustedshiftStartDate = parseISO(shift?.shiftTimeAdjustmentRequest?.startDate);
  const adjustedShiftTimeSpan = date.formatFromUntilTime(date.format, { startDate: adjustedshiftStartDate, endDate: parseISO(shift?.shiftTimeAdjustmentRequest?.endDate) });

  const handleAdjustedShiftTimeRequest = () => {
    dispatch(new adjustedShiftTimeActions.ToggleAdjustedShiftTimeModalAction({ shift: shift }));
  };

  const renderStatus = () => {
    if (shiftTimePeriod && isShiftTimeAdjustmentsEnabled) {
      if (requestsUtils.isValidAdjustment({ requestPeriod: shiftTimePeriod, forDate: new Date(shift.startDate), isAbsenceShift: shift.isAbsenceShift })) {
        if (!shift.shiftTimeAdjustmentRequest) {
          return <Button className="adjust-button" title={translations.getLabel('btnAdjustShiftTime')} onClick={handleAdjustedShiftTimeRequest}>{translations.getLabel('btnAdjustShiftTime')}</Button>;
        } else if (shift.shiftTimeAdjustmentRequest.status === RequestStatus.Denied) {
          return (
            <>
              <Footer request={shift.shiftTimeAdjustmentRequest} />
              <Button className="adjust-button" title={translations.getLabel('btnAdjustShiftTime')} onClick={handleAdjustedShiftTimeRequest}>{translations.getLabel('btnAdjustShiftTime')}</Button>
            </>
          );
        } else {
          return <Footer request={shift.shiftTimeAdjustmentRequest} closeParentPopup={closeParentPopup} />;
        }
      }
    }
  };

  return (
    <div className="shift-offer-shift">
      <h4>{shift.shiftName}</h4>
      <div className="date-time">
        <Icon tag="CalendarIcon" customSize={2} color="primaryDark" />
        <span className="label">{isValid(startDate) ? date.format(startDate, 'EEEEEE d MMM') : '-'}</span>
        <Icon tag="ClockIcon" customSize={2} color="primaryDark" />
        <span className="label">{timeSpan}</span>
      </div>
      {
        (showAdjustedShifts && shift.shiftTimeAdjustmentRequest && requestsUtils.isValidAdjustment({ requestPeriod: shiftTimePeriod, forDate: new Date(shift.startDate), isAbsenceShift: shift.isAbsenceShift })) &&
        <div className="adjusted-time">
          <span className="label">{translations.getLabel('lblAdjustedTime')}</span>
          <span className="date">&nbsp;{adjustedShiftTimeSpan}</span>
          <Icon tag="QuestionmarkIcon" />
        </div>
      }
      {!hideIntervals && <div className="row">
        <span className="label">{translations.getLabel('lblTasks')}</span>
        {shift.intervals && shift.intervals.length > 0 &&
          <div key={shift.id} className="interval-rows">
            {shift.intervals.map(renderInterval)}
          </div>
        }
      </div>}
      {shift.department && <div className="row">
        <span className="label">{translations.getLabel('lblDepartment')}</span>
        <span>{shift.department}</span>
      </div>}
      {shift.functionName && <div className="row">
        <span className="label">{translations.getLabel('lblFunction')}</span>
        <span>{shift.functionName}</span>
      </div>}
      {
        showAdjustedShifts &&
        <div className="shift-times">
          {
            renderStatus()
          }
        </div>
      }
    </div>
  );
};

export default Shift;

