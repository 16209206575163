import React, { FC, useEffect, useRef, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { Button } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import classnames from 'classnames';

import { IMAGES } from '../../assets';
import BackButton from '../../components/backButton/BackButton';
import { ContactType, ErrorCode } from '../../models';
import { configSelectors, forgotPasswordActions } from '../../redux';
import { queryParser, translations } from '../../utils';

import './Login.scss';

const ForgotPasswordView: FC<RouteComponentProps> = ({
  location,
  history,
}) => {
  const dispatch = useDispatch();
  const holdingPreferences = useSelector(configSelectors.getHoldingPreferences);

  const [username, setUsername] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [contactType, setContactType] = useState<ContactType>(ContactType.email);
  const [contactValue, setContactValue] = useState('');
  const ref = useRef(null);

  const token = queryParser.parseQuery(location.search).token;
  const isInit = queryParser.parseQuery(location.search).init === 'true';

  const goToReset = () => {
    history.push({
      pathname: 'reset-password',
      state: { token, isInit },
    });
  };

  useEffect(() => {
    if (ref?.current) {
      ref.current.focus();
    }
    if (token) {
      if (queryParser.isMobileDevice()) {
        // If the app is not installed wait 2s and redirect to web
        const loadedAt = new Date().getTime();
        setTimeout(function () {
          if (new Date().getTime() - loadedAt < 2000)
            history.push('/auth/download');
        }, 25);
        // Try launching the app
        try {
          window.location.replace(`ESS://forgot-password/${token}/${isInit}`);
        } catch (error) {
          // If the intent is not found
          history.push('/auth/download');
        }
      } else {
        goToReset();
      }
    }
  }, []);

  const onReset = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    dispatch(new forgotPasswordActions.RequestForgotPasswordAction({
      username,
      holding: holdingPreferences.name,
      onError: (error) => {
        setHasError(true);
        setIsLoading(false);
        if (error.code === ErrorCode.userNotFound) {
          setError(translations.getLabel('api_error_forgot_password_user_not_found'));
        }
        else if (error.code === ErrorCode.noContactInfo) {
          setError(translations.getLabel('api_error_no_contact_info'));
        }
        else {
          setError(error.detail);
        }
      },
      onSuccess: (response) => {
        setIsLoading(false);
        if (response.data.contactInfo.type === ContactType.email) {
          setContactType(ContactType.email);
        }
        else {
          setContactType(ContactType.sms);
        }
        setContactValue(response.data.contactInfo.value);
        setIsSuccess(true);
      },
    }));
  };

  const onChange = (event) => {
    setUsername(event.target.value);
    setHasError(false);
    setError('');
  };

  const goToLogin = () => {
    history.push('/auth/login');
  };

  // Don't render a view on mobile, since we will redirect to the app
  if (queryParser.isMobileDevice()) return null;

  const className = classnames({ error: hasError });
  return (
    <DocumentTitle title={translations.getLabel('titleForgotPassword')}>
      <div className="login-view">
        <form className="form-container" onSubmit={onReset}>
          <BackButton to="/auth/login" label="" />
          <img src={holdingPreferences?.logoUrl || IMAGES.LogoBackground} alt={holdingPreferences?.name} className="holding-logo" />
          <h1>{translations.getLabel('titleLogin', { holding: holdingPreferences.url })}</h1>
          {isSuccess
            ? (
              <React.Fragment>
                <p className="paragraph">{contactType === ContactType.email ? translations.getLabel('lblForgotPasswordSuccessMail') : translations.getLabel('lblForgotPasswordSuccessSMS')}
                  <span>{' ' + contactValue}</span>
                </p>
                <div className="actions">
                  <Button type="button" onClick={goToLogin}>{translations.getLabel('btnLogin')}</Button>
                </div>
              </React.Fragment>
            )
            : (
              <React.Fragment>
                <p className="paragraph">{translations.getLabel('lblForgotPassword')}</p>
                <input ref={ref} type="text" name="username" placeholder={translations.getLabel('lblUsername')} id="username" onChange={onChange} className={className} />
                <div className="actions-forgot-password">
                  <Button type="submit" disabled={!username || hasError} isLoading={isLoading}>{translations.getLabel('btnNext')}</Button>
                </div>
                {
                  hasError &&
                  <p className="error-text-user-not-found">{error}</p>
                }
              </React.Fragment>
            )}
        </form>
        <aside style={{ backgroundColor: holdingPreferences.backgroundColor, backgroundImage: `url(${holdingPreferences.webStartpageImageUrl})` }} />
      </div>
    </DocumentTitle>
  );
};

export default ForgotPasswordView;
