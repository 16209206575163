import React from 'react';

import { DownloadAndroid, DownloadIos, Logo } from '../../assets/images';
import { translations } from '../../utils';

import './Download.scss';

const Download = () => {
  return (
    <div className="download-page">
      <img src={Logo} className="logo" alt="Logo Sp-Expert Connect" />
      <h2>{translations.getLabel('lblRedirecting')}</h2>
      <h4>{translations.getLabel('lblDownloadApp')}</h4>
      <a href={process.env.REACT_APP_ANDROID_DOWNLOAD_LINK} className="link"><img src={DownloadAndroid} alt="Logo Android download" /></a>
      <a href={process.env.REACT_APP_IOS_DOWNLOAD_LINK} className="link"><img src={DownloadIos} alt="Logo Android download" /></a>
    </div>
  );
};


export default Download;
