import { colors,scheduleUtils } from '../../../shared/utils';
import * as date from './date';
import * as fileUploadUtils from './fileUploadUtils';
import * as firebase from './firebase';
import * as imageUtils from './imageUtils';
import * as newsUtils from './newsUtils';
import * as notificationUtils from './notificationUtils';
import * as popups from './popups';
import * as queryParser from './queryParser';
import * as timeRegistration from './timeRegistration';
import translations from './translations';
import * as versionUtils from './versionUtils';

scheduleUtils.setDateUtil(date);
// Shared utils
export * from '../../../shared/utils';
// Utils
export { translations, date, fileUploadUtils, firebase, queryParser, imageUtils, newsUtils, notificationUtils, popups, colors, timeRegistration, versionUtils };
