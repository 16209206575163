import { isSameDay, parseISO } from 'date-fns';
import { useQuery } from 'react-query';

import { HttpClient } from '../../http';
import { IServerError } from '../../http/HttpError';
import { IGetSelfRosterScheduleParams, ISelfRosteringRoster, ISelfRosteringShift } from '../../models';
import { selfRosterQueryKeys } from './queryKeys';
import { useSelfRosterContext } from './SelfRosterContext';

const getSelectedShifts = async (params: IGetSelfRosterScheduleParams, setIsMultiDepartment: (enabled: boolean) => void): Promise<ISelfRosteringRoster[]> => {
  const result = await HttpClient.get<{ data: ISelfRosteringRoster[][] }>('/v1/self-roster/selected-shifts', { ...params });

  if (result.data.length > 1) setIsMultiDepartment(true);

  const shifts = result.data.flatMap(roster => roster).flatMap(roster => roster.shifts);
  const roster = shifts.reduce((roster, shift) => {
    const shiftDate = parseISO(shift.startDate);
    const day = roster.find((day) => isSameDay(parseISO(day.date), shiftDate));
    if (day) {
      day.shifts.push(shift);
    } else {
      roster.push({ date: shiftDate.toISOString(), shifts: [shift] });
    }
    return roster;
  }, []);

  return roster;
};

export const useSelfRosterSelectedShifts = (params: IGetSelfRosterScheduleParams) => {
  const { setSelectedShifts, setIsMultiDepartment } = useSelfRosterContext();
  // Only trigger when start date is in params
  return useQuery<ISelfRosteringRoster[], IServerError, ISelfRosteringRoster[]>(
    selfRosterQueryKeys.selectedShift(params),
    async () => getSelectedShifts(params, setIsMultiDepartment),
    {
      enabled: !!params.startDate,
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000 * 60,
      onSuccess: (data) => {
        setSelectedShifts(data.flatMap((roster) => roster.shifts));
      }
    });
};
