import Color from 'color';

import { IInterval } from '../../../../models';
import constants from '../../../../styling/constants.module.scss';
import { colors } from '../../../../utils';

const scheduleHourWidth = parseFloat(constants.scheduleHourWidth);

export const getDateOffset = (date: Date, hourWidth = scheduleHourWidth): number => {
  const hour = date.getHours();
  const minutes = date.getMinutes() / 60;
  return (hour + minutes) * hourWidth;
};

export const getIntervalBackground = (interval: IInterval): string => {
  const initialColor = interval.color || colors.primary;
  const color = Color(initialColor);

  return interval.vacationRequested ? `repeating-linear-gradient(
    -55deg,
    transparent,
    transparent 4px,
    ${color} 4px,
    ${color} 6px
    ),
    ${color.fade(0.5)}
    ` : color.toString();
};
