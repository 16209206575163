import React, { FC } from 'react';

import { TimeScale } from '../../models';
import { translations } from '../../utils';

import './RangeSwitch.scss';
type Props = {
  activeRange?: TimeScale;
  setActiveRange: (range: TimeScale) => void
}

const RangeSwitch: FC<Props> = ({ activeRange = TimeScale.Day, setActiveRange }) => {
  const renderToggle = (range: TimeScale) => (

    <li key={range}>
      <button
        className={`stripped-button toggle ${activeRange === range ? 'active' : ''}`}
        onClick={() => setActiveRange(range)}
        aria-pressed={activeRange === range}
      >
        <span>
          {translations.getLabel(`btn${range}`)}
        </span>
      </button>
    </li>
  );

  return (
    <ul className="switch-container">
      {
        [TimeScale.Day, TimeScale.Week, TimeScale.Month].map(renderToggle)
      }
    </ul>
  );
};

export default RangeSwitch;

