import { HttpClient } from '../http';
import { errorCodes } from '../constants';
import { INewsItem, INewsItemAdmin, INewsFiles, IFileUploadFunction } from '../models';
import { HttpPagingResponse } from '../http/HttpMetadata';

export const getNews = (limit = 10, offset = 0): Promise<HttpPagingResponse<INewsItem[]>> => {
  return HttpClient.get<HttpPagingResponse<INewsItem[]>>('/v1/news', { limit, offset });
};

export const getNewsDetail = async (id: string): Promise<INewsItem> => {
  const result = await HttpClient.get<{ data: INewsItem }>(`/v1/news/${id}`);
  return result.data;
};

export const getAdminNewsDetail = async (id: string): Promise<INewsItemAdmin> => {
  const result = await HttpClient.get<{ data: INewsItemAdmin }>(`/v1/news/admin/${id}`);
  return result.data;
};

export const deleteNewsItem = async (id: string): Promise<void> => {
  return HttpClient.delete(`/v1/news/admin/${id}`);
};

export const deleteCoverImage = async (newsId: string): Promise<void> => {
  return HttpClient.delete(`/v1/news/admin/cover-image/${newsId}`);
};

export const downloadAttachment = async (attachmentId: string): Promise<string> => {
  const result = await HttpClient.get<{ data: { downloadUrl: string } }>(`/v1/news/attachment/${attachmentId}/download`);
  if (result?.data?.downloadUrl) return result.data.downloadUrl;
  throw new Error();
};

const uploadFile = async (url: string, file: File, fileUploadFunc: IFileUploadFunction, optionalContentType?: string) => {
  return fileUploadFunc(url, file, optionalContentType);
};

interface GetAdminNewsDetailResponse { data: INewsItemAdmin }
export const createNewsItem = async (data: INewsItemAdmin, files: INewsFiles, fileUploadFunc: IFileUploadFunction): Promise<void> => {
  let newsResult: GetAdminNewsDetailResponse;
  try {
    newsResult = await HttpClient.post('/v1/news/admin', data);
  } catch (e) {
    throw new Error(e);
  }

  try {
    const { coverUploadUrl } = newsResult.data;
    const fileData = newsResult.data.content.map(element => element.attachments).flat();
    if (files.coverImage) await uploadFile(coverUploadUrl, files.coverImage, fileUploadFunc, 'image/png');
    const promisePool: Promise<void>[] = [];
    fileData.forEach((element, i) => {
      promisePool.push(uploadFile(element.uploadUrl, files.attachments[i], fileUploadFunc));
    });
    await Promise.all(promisePool);
  } catch (e) {
    return Promise.reject(errorCodes.uploadAttachment.value);
  }
};

export const updateNewsItem = async (id: string, data: INewsItemAdmin, files: INewsFiles, fileUploadFunc: IFileUploadFunction) => {
  let newsResult: GetAdminNewsDetailResponse;
  try {
    newsResult = await HttpClient.put(`/v1/news/admin/${id}`, data);
  } catch (e) {
    throw new Error(e);
  }


  try {
    const attachmentIds = data.content.reduce((acc, contentByLanguage) => {
      contentByLanguage.attachments.forEach(attachment => {
        if (attachment.id) acc.push(attachment.id);
      });
      return acc;
    }, []);

    const { coverUploadUrl } = newsResult.data;
    const fileData = newsResult.data.content.map(element => element.attachments).flat();
    if (files.coverImage) await uploadFile(coverUploadUrl, files.coverImage, fileUploadFunc, 'image/png');
    else if (files.deleteCoverImage) await deleteCoverImage(id);
    const promisePool = [];
    fileData.forEach((element, i) => {
      // Don't re-upload files that are already in there
      if (attachmentIds.indexOf(element.id) === -1) {
        promisePool.push(uploadFile(element.uploadUrl, files.attachments[i], fileUploadFunc));
      }
    });
    await Promise.all(promisePool);
    return Promise.resolve(newsResult);
  } catch (e) {
    return Promise.reject(errorCodes.uploadAttachment.value);
  }
};