import React, { FC, useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { Button, Icon, Modal } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addYears, format, parseISO } from 'date-fns';

import { AppState } from '../../../../shared/redux/rootReducer';
import { formatDateForSchedule, getDayShiftsRange } from '../../../../shared/utils/schedule';
import { SchedulePageSwitch } from '../../components';
import { identifiers } from '../../constants';
import { useSelectArray } from '../../hooks';
import { TimeScale } from '../../models';
import { configSelectors, scheduleSelectors, shiftSwapActions, shiftSwapSelectors } from '../../redux';
import { scheduleUtils, translations } from '../../utils';
import { formatFromUntilTime } from '../../utils/date';
import ShiftSwapSchedule from './ShiftSwapSchedule';

import './shiftSwap.scss';

const ShiftSwap: FC<RouteComponentProps<{ id: string; date: string }>> = ({ match, history }) => {
  const dispatch = useDispatch();
  const { date, id } = match.params;
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const request = useSelector((state: AppState) => shiftSwapSelectors.getShiftSwap(state, id, date));
  const planning = useSelector(scheduleSelectors.getPlanningWithoutSearch);

  const [isSaving, setIsSaving] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(parseISO(date));
  const {
    selectArray,
    select,
    hasChanged,
  } = useSelectArray([], 10);

  useEffect(() => {
    if (!request) {
      dispatch(new shiftSwapActions.GetShiftSwapsAction({ startDate: date, endDate: date }));
    }
  }, []);

  const onCancel = () => history.goBack();
  const onSave = () => setIsModalVisible(true);

  const onSaveConfirm = () => {
    setIsSaving(true);
    dispatch(new shiftSwapActions.ApplyToShiftSwapWithCounterAction(
      {
        id,
        shifts: getDayShiftsRange(selectArray, planning),
        onSuccess: () => {
          setIsSaving(false);
          setIsModalVisible(false);
          history.goBack();
        },
        onError: (error) => {
          toast.error(error.detail);
          setIsSaving(false);
          setIsModalVisible(false);
        },
      }));
  };

  const renderDay = (date) => {
    const day = planning[formatDateForSchedule(date)]?.[identifiers.me];
    return (
      (
        <div key={date} className="shift-row">
          <div>
            <Icon tag="CalendarIcon" color="primaryDark" />
            <p>
              {
                format(parseISO(date), 'E dd MMM')
              }
            </p>
          </div>
          <div>
            {day?.shifts?.length > 0 && <>
              <Icon tag="ClockIcon" color="primaryDark" />
              <p>{formatFromUntilTime(format, { startDate: parseISO(day.shifts[0].startDate), endDate: parseISO(day.shifts[day.shifts.length - 1].endDate) })}</p>
            </>}
          </div>
        </div>
      )
    );
  };

  return (
    <DocumentTitle title={translations.getLabel('titleShiftSwap')}>
      <div className="page fixed-width availabilities shift-swap">
        <div className="schedule-toolbar">
          <SchedulePageSwitch
            dateRange={TimeScale.Week}
            selectedDate={selectedDate}
            changeDate={(modifier: number) => setSelectedDate(scheduleUtils.getModifiedDate(TimeScale.Week, selectedDate, modifier, weekStartsOn))}
            setSpecificDate={(date: Date) => setSelectedDate(date)}
          />
        </div>

        {
          request &&
          <ShiftSwapSchedule
            swapDate={request.date}
            employeeId={request.requesterEmployeeId}
            onSelect={select}
            selectedShifts={selectArray}
            selectedDate={selectedDate}
            highlightRange={{ startDate: new Date(), endDate: addYears(parseISO(date), 1) }}
          />
        }

        <Modal
          open={isModalVisible}
          requestClose={() => setIsModalVisible(false)}
          title={translations.getLabel('titleSendSwap')}
          leftButtonProps={{
            onClick: () => setIsModalVisible(false),
            label: translations.getLabel('cancel'),
          }}
          rightButtonProps={{
            onClick: onSaveConfirm,
            label: translations.getLabel('btnSend'),
            isLoading: isSaving,
          }}
        >
          <p className="modal-content">{translations.getLabel('lblSendSwap')}</p>
          {
            selectArray.map(renderDay)
          }
        </Modal>

        <footer>
          <div>
            <Button theme="inverse" onClick={onCancel}>{translations.getLabel('cancel')}</Button>
            <Button disabled={!hasChanged} onClick={onSave}>{translations.getLabel('lblConfirmCounterSwap', { days: selectArray.length })}</Button>
          </div>
        </footer>
      </div>
    </DocumentTitle>
  );
};

export default ShiftSwap;
