import React, { FC, useEffect, useState } from 'react';
import { InputDateField, InputDateType, InputField, InputType, Modal, MultiSelect } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isPast, isToday, parseISO } from 'date-fns';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { regexes } from '../../../../constants';
import { useForm, useSelectArray } from '../../../../hooks';
import { IBaseInterval, IBaseShift, IShiftOffer, Locale, UserRight } from '../../../../models';
import { configSelectors, departmentsActions, departmentsSelectors, shiftOfferActions, shiftOfferSelectors, userSelectors } from '../../../../redux';
import { date, translations } from '../../../../utils';
import Shift from './Shift';

import './shiftOfferModals.scss';

interface Props {
  isOpen: boolean;
  toggleModal: () => void;
}

const CreateShiftOfferModal: FC<Props> = ({ isOpen, toggleModal }) => {
  const isLoading = useSelector(shiftOfferSelectors.isCreateShiftOfferLoading);
  const isTemplatesLoading = useSelector(shiftOfferSelectors.isTemplatesLoading);
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const user = useSelector(userSelectors.getUser);
  const departments = useSelector((state: AppState) => departmentsSelectors.getDepartmentsForRight(state, UserRight.ShiftOffer));
  const allDepartments = useSelector(departmentsSelectors.getCompanyDepartments);
  const departmentsError = useSelector(departmentsSelectors.getCompanyDepartmentsError);
  const templates = useSelector(shiftOfferSelectors.templates);
  const isCrossDepartmentsShiftOffersEnabled = useSelector(configSelectors.isCrossDepartmentsShiftOffersEnabled);
  const [departmentId, setDepartmentId] = useState<string>(null);
  const { setFormAttribute, setForm, form } = useForm<IShiftOffer>(null);
  const dispatch = useDispatch();
  const { select: selectDepartmentIds, set: setDepartmentIds, clear: clearDepartmentIds, selectArray: departmentIds } = useSelectArray([]);

  useEffect(() => {
    if (isOpen) {
      dispatch(new departmentsActions.GetCompanyDepartmentsAction());
      dispatch(new departmentsActions.GetDepartmentsAction({ type: UserRight.ShiftOffer }));
    }
  }, [isOpen]);

  useEffect(() => {
    if (departments.length > 0 && !departmentId) {
      setDefaultDepartmentId();
    }
  }, [departments]);

  useEffect(() => {
    if (isOpen && departmentId) {
      setForm(null);
      dispatch(new shiftOfferActions.GetTemplatesAction({ departmentId: departmentId }));
      if (!isCrossDepartmentsShiftOffersEnabled) {
        setDepartmentIds([departmentId]);
      }
    }
  }, [isOpen, departmentId]);

  const setDefaultDepartmentId = () => {
    if (departments.some(department => department.value === user.departmentId)) {
      setDepartmentId(user.departmentId);
    } else {
      setDepartmentId(departments[0].value);
    }
  };

  const closeModal = () => {
    clearDepartmentIds();
    toggleModal();
    setForm(null);
    setDefaultDepartmentId();
  };

  const isValid = () => {
    return !!(
      form &&
      departmentIds.length > 0 &&
      form.templateId &&
      form.date &&
      form.numberOfPeople > 0 &&
      form.shifts &&
      form.shifts.every(shift => shift.intervals.every(interval => regexes.time.test(interval.startDate) && regexes.time.test(interval.endDate)))
    );
  };

  const createShiftOffer = () => {
    dispatch(new shiftOfferActions.CreateShiftOfferAction({
      departmentId: departmentId,
      shiftOffer: { ...form, departmentIds },
      onSuccess: () => {
        toast(translations.getLabel('lblShiftOfferCreated'));
        closeModal();
      },
      onError: (error) => {
        toast.error(typeof error.detail === 'string' ? error.detail : error.title);
      },
    }));
  };

  const changeTemplate = (templateId: string) => {
    const template = templates.find(template => template.id === templateId);
    const { id, name, ...shiftOffer } = template; // eslint-disable-line @typescript-eslint/no-unused-vars
    setForm({ ...shiftOffer, templateId, departmentIds, numberOfPeople: null, date: new Date().toISOString() });
  };

  const changeShift = (index: number, shift: IBaseShift<IBaseInterval>, isRemoved?: boolean) => {
    const shifts = [...form.shifts];
    if (isRemoved) {
      shifts.splice(index, 1);
    } else {
      shifts[index] = shift;
    }
    setFormAttribute(shifts, 'shifts');
  };

  return (
    <Modal
      title={translations.getLabel('titleNewShiftOffer')}
      open={isOpen}
      requestClose={closeModal}
      leftButtonProps={{
        label: translations.getLabel('cancel'),
        onClick: closeModal,
      }}
      rightButtonProps={{
        label: translations.getLabel('btnCreateShiftOffer'),
        onClick: createShiftOffer,
        disabled: !isValid(),
        isLoading,
      }}
    >
      <div className="create-shift-offer-modal">
        {!!departmentsError
          ? <p className="input-group">{departmentsError.detail || departmentsError.title}</p>
          :
          <div className="selects">
            <InputField
              emptyIcon
              type={InputType.Select}
              options={departments}
              value={departmentId}
              onChange={setDepartmentId}
              label={translations.getLabel('lblSourceDepartment')}
              placeholder={translations.getLabel('lblPlaceholderDepartmentSelect')}
            />
            {isCrossDepartmentsShiftOffersEnabled && <MultiSelect
              options={allDepartments}
              values={departmentIds}
              onChange={selectDepartmentIds}
              label={translations.getLabel('lblSelectedDepartment')}
              placeholder={translations.getLabel('lblPlaceholderDepartmentSelect')}
            />}
            {!templates.length && !isTemplatesLoading
              ? <span className="warning italic">{translations.getLabel('lblNoTemplatesFound')}</span>
              : <InputField
                emptyIcon
                type={InputType.Select}
                options={templates.map(template => ({ label: template.name, value: template.id }))}
                value={form?.templateId || ''}
                disabled={isTemplatesLoading}
                onChange={changeTemplate}
                label={translations.getLabel('lblTemplate')}
                placeholder={translations.getLabel('lblPlaceholderTemplateSelect')}
              />}
          </div>
        }
        {form?.shifts?.length > 0 && <div className="separator" />}
        <section className="shifts">
          {
            form?.shifts?.map((shift, index) => <Shift changeShift={changeShift} key={index} shift={shift} index={index} />)
          }
        </section>
        {form && <>
          <div className="separator" />
          <section className="shift-offer-details">
            <InputField
              label={translations.getLabel('lblAmountOfPeople')}
              type={InputType.Text}
              icon="UserIcon"
              name="numberOfPeople"
              onChange={setFormAttribute}
              value={String(form.numberOfPeople ?? '')}
            />
            <InputDateField
              label={translations.getLabel('lblOnDate')}
              type={InputDateType.Date}
              filterDate={date => !isPast(date) || isToday(date)}
              icon="CalendarIcon"
              name="date"
              weekStartsOn={weekStartsOn}
              onChange={(dateTime) => setFormAttribute(date.format(dateTime.date, 'yyyy-MM-dd'), 'date')}
              value={{ date: parseISO(form.date) }}
              locale={translations.I18n.locale as Locale}
            />
          </section>
        </>}
      </div>
    </Modal>
  );
};

export default CreateShiftOfferModal;
