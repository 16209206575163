import React, { useEffect, useState } from 'react';
import { Button, InputDateField, InputField, InputType } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { differenceInMonths, isAfter, isBefore, parseISO } from 'date-fns';

import { getPeriodTypes } from '../../../../constants';
import { useForm, usePrevious } from '../../../../hooks';
import { ICounter, ICounterOverviewData, Locale, PlanningType } from '../../../../models';
import { configSelectors, countersActions, countersSelectors } from '../../../../redux';
import { counters, date, translations } from '../../../../utils';
import Total from './components/Total';
import Week from './components/Week';

import './OverviewTab.scss';

export default function OverviewTab() {
  const dispatch = useDispatch();
  const counterOverviews = useSelector(countersSelectors.getCounterOverview);
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const companyConfig = useSelector(configSelectors.getCompanyConfig);
  const counterOverviewConfiguration = useSelector(countersSelectors.getCounterOverviewConfiguration);
  const isLoading = useSelector(countersSelectors.isLoadingCounterOverview);

  const periodTypes = getPeriodTypes(translations.getLabel);
  const options = counters.getValues(companyConfig?.payPeriod, date.format);

  const [isDisabled, setIsDisabled] = useState(true);
  const [activeOverview, setActiveOverview] = useState(counterOverviewConfiguration[0]);
  const [headers, setHeaders] = useState([]);

  const { form, setFormAttribute, setForm } = useForm({
    period: counters.getDateRange(companyConfig?.payPeriod, date.format, parseISO(options[0]?.value)),
    periodType: periodTypes[0]?.value,
    startDatePeriod: undefined,
    endDatePeriod: undefined,
    overview: counterOverviewConfiguration[0]?.id,
  });

  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const prevIsloading = usePrevious({ isLoading });

  const renderWeek = (days: ICounterOverviewData[], i: number) => <Week days={days} key={`week${i}`} columns={headers} />;

  const groupedOverviews = counters.groupCounterOverViews(counterOverviews, weekStartsOn);

  const overviewOptions = counterOverviewConfiguration.map(config => ({ value: config.id, label: config.name }));

  useEffect(() => {
    if (counterOverviewConfiguration.length > 0) {
      setFormAttribute(counterOverviewConfiguration[0].id, 'overview');
      getData(counterOverviewConfiguration[0].id);
      setActiveOverview(counterOverviewConfiguration[0]);
    }
  }, [counterOverviewConfiguration]);

  useEffect(() => {
    if (activeOverview) setHeaders(getColumns());
  }, [activeOverview]);

  useEffect(() => {
    if (prevIsloading && !isLoading) setIsInitialLoading(false);
  }, [isLoading]);

  useEffect(() => {
    if (!isInitialLoading && (form.periodType === periodTypes[0]?.value || (form.startDatePeriod && form.endDatePeriod))) setIsDisabled(false);
    else setIsDisabled(true);
  }, [form.period, form.periodType, form.startDatePeriod, form.endDatePeriod, form.overview]);

  const getData = async (id?: string) => {
    if (differenceInMonths(form.endDatePeriod, form.startDatePeriod) >= 2) return toast.error(translations.getLabel('lblMaxTwoMonths'));

    setIsDisabled(true);
    let start, end;
    if (form.periodType === periodTypes[0].value) {
      const { startDate, endDate } = counters.getDateRange(companyConfig?.payPeriod, date.format, parseISO(form.period.startDate));
      start = startDate;
      end = endDate;
    } else {
      start = date.format(form.startDatePeriod, 'yyyy-MM-dd');
      end = date.format(form.endDatePeriod, 'yyyy-MM-dd');
    }

    if (counterOverviewConfiguration.length > 0) {
      dispatch(new countersActions.GetCounterOverviewAction({ id: form.overview || id, startDate: start, endDate: end }));
      setActiveOverview(counterOverviewConfiguration.find(conf => conf.id === form.overview));
    }
  };

  const renderCounterHeader = (counter: ICounter) => <th key={counter.wcfId} className="th counter">{counter.description}</th>;

  const changePeriod = (selected: string) => {
    setFormAttribute(counters.getDateRange(companyConfig?.payPeriod, date.format, parseISO(selected)), 'period');
  };

  const setDate = (key: string, value: Date) => {
    let startDatePeriod = form.startDatePeriod;
    let endDatePeriod = form.endDatePeriod;
    if (key === 'start') {
      if (isAfter(value, form.endDatePeriod)) endDatePeriod = value;
      startDatePeriod = value;
    } else {
      if (isBefore(value, form.startDatePeriod)) startDatePeriod = value;
      endDatePeriod = value;
    }

    setForm({
      ...form,
      startDatePeriod,
      endDatePeriod,
    });
  };

  const getColumns = () => {
    const columns = [];
    activeOverview.planning.forEach(column => {
      if (column.showEmployees) columns.push(column.type);
    });

    return columns;
  };

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!isDisabled) getData();
  };

  const filterDate = (date) => isBefore(date, new Date());
  return (
    <div className="page fixed-width counter-overviews">
      <form className="overview-selectors" onSubmit={onSubmit}>
        <div className="select-overview">
          <InputField disabled={isInitialLoading} emptyIcon type={InputType.Select} options={overviewOptions} onChange={setFormAttribute} name="overview" value={form.overview} label={translations.getLabel('lblOverview')} />
        </div>
        <div className="select-period">
          <InputField disabled={isInitialLoading} emptyIcon type={InputType.Select} options={periodTypes} onChange={setFormAttribute} name="periodType" value={form.periodType} label={translations.getLabel('lblCheck')} />
          {
            form.periodType === periodTypes[0]?.value
              ?
              <InputField disabled={isInitialLoading} emptyIcon type={InputType.Select} options={options} onChange={changePeriod} value={form.period?.value || options[0]?.value} label={translations.getLabel('lblOf')} />
              :
              <div className="free-period">
                <div className="inputs">
                  <InputDateField
                    emptyIcon
                    weekStartsOn={weekStartsOn}
                    onChange={(value) => setDate('start', value.date)}
                    value={{ date: form.startDatePeriod }}
                    dateFormat="dd MMM yyyy"
                    label={translations.getLabel('lblFrom')}
                    filterDate={(date) => filterDate(date)}
                    locale={translations.I18n.locale as Locale}
                  />
                  <InputDateField
                    emptyIcon
                    weekStartsOn={weekStartsOn}
                    onChange={(value) => setDate('end', value.date)}
                    value={{ date: form.endDatePeriod }}
                    dateFormat="dd MMM yyyy"
                    label={translations.getLabel('lblUntil')}
                    filterDate={(date) => filterDate(date)}
                    locale={translations.I18n.locale as Locale}
                  />
                </div>
                <p className="italic">{translations.getLabel('lblMaxTwoMonths')}</p>
              </div>
          }
          <Button type="submit" disabled={isDisabled}>{translations.getLabel('lblShowOverview')}</Button>
        </div>
      </form>
      {
        (isLoading || isInitialLoading)
          ?
          <div className="skeleton-wrapper">
            <Skeleton width="100%" height="3.7rem" count={8} />
            <div className="loading-text">
              <p>{translations.getLabel('lblCounterOverviewsLoading1')}</p>
              <p>{translations.getLabel('lblCounterOverviewsLoading2')}</p>
            </div>
          </div>
          :
          <div className="table-wrapper">
            <table className="table" >
              <thead className="thead">
                <tr>
                  <th className="th left-align">{translations.getLabel('lblDate')}</th>
                  {headers.includes(PlanningType.TargetPlanning) && <th className="th">{translations.getLabel('lblInitialPlanning')}</th>}
                  {headers.includes(PlanningType.ActualPlanning) && <th className="th">{translations.getLabel('lblCurrentPlanning')}</th>}
                  {headers.includes(PlanningType.TimeRegistration) && <th className="th">{translations.getLabel('lblTimeRegistration')}</th>}
                  {headers.includes(PlanningType.TickingList) && <th className="th ticking-col">{translations.getLabel('lblTickingList')}</th>}
                  {counterOverviews[0]?.counters.map(renderCounterHeader)}
                </tr>
              </thead>
              <tbody>
                {
                  counterOverviews.length > 0
                  &&
                  <>
                    {groupedOverviews.map(renderWeek)}
                    <Total counterOverviews={counterOverviews} isFinalTotal columnCount={headers.length} />
                  </>

                }
              </tbody>
            </table>
            {
              (!isLoading && !isInitialLoading && !counterOverviews.length)
              &&
              <div className="empty-state">
                <p className="italic">{translations.getLabel('lblNoOverviews')}</p>
              </div>
            }
          </div>
      }
    </div>
  );
}
