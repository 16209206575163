import React from 'react';
import { Button } from 'react-ess-components';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import AccountDetailsBase, { mapStateToProps } from '../../../../../shared/components/accountDetails/AccountDetails.container';
import { translations } from '../../../utils';
import Widget from '../../widget/Widget';
import AvatarPicker from '../avatarPicker/AvatarPicker';
import DataItem from '../dataItem/DataItem';

import './AccountDetails.scss';

class AccountDetails extends AccountDetailsBase {
  render() {
    const className = classnames('avatar-container', { 'no-picture': !!this.props.user && !this.props.user.profilePictureUrl });
    return (
      <Widget title={translations.getLabel('titleMyAccount')} left>
        <div className="account-details-container">
          <div className={className}>
            {this.props.user &&
              <div>
                <AvatarPicker source={this.props.user.profilePictureUrl} isLoading={this.props.isAvatarLoading} large firstName={this.props.user.firstName} lastName={this.props.user.lastName} handleUpload={this.props.handleUpload} />
                {!!this.props.user.profilePictureUrl && <Button className="delete-button" onClick={this.props.handleDelete} theme="transparent">{translations.getLabel('btnDeleteAvatar')}</Button>}
              </div>
            }
          </div>
          {this.props.user && <DataItem title={translations.getLabel('lblUsername')} value={this.props.user.username} />}
          <h4>
            {translations.getLabel('lblPassword')}
          </h4>
          <div className="password-value">
            <p>
              {translations.getLabel('lblPasswordValue')}
            </p>
            <Button onClick={this.props.handleChangePasswordClick} theme="transparent">
              {translations.getLabel('lblChangePassword')}
            </Button>
          </div>
        </div>
      </Widget>
    );
  }
}

const noop = () => { };
AccountDetails.propTypes = {
  ...AccountDetailsBase.proptypes,
  handleChangePasswordClick: PropTypes.func,
  handleDelete: PropTypes.func,
  handleUpload: PropTypes.func,
};

AccountDetails.defaultProps = {
  ...AccountDetailsBase.defaultProps,
  handleChangePasswordClick: noop,
  handleDelete: noop,
  handleUpload: noop,
};

export { AccountDetails };
export default connect(mapStateToProps)(AccountDetails);
