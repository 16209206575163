export enum ActionType {
  ApproveShiftSwap = 'APPROVE_SHIFT_SWAP',
  ApproveShiftSwapFulfilled = 'APPROVE_SHIFT_SWAP_FULFILLED',
  ApproveShiftSwapRejected = 'APPROVE_SHIFT_SWAP_REJECTED',

  ApproveShiftTime = 'APPROVE_SHIFT_TIME',
  ApproveShiftTimeFulfilled = 'APPROVE_SHIFT_TIME_FULFILLED',
  ApproveShiftTimeRejected = 'APPROVE_SHIFT_TIME_REJECTED',

  ApproveTransaction = 'APPROVE_TRANSACTION',
  ApproveTransactionFulfilled = 'APPROVE_TRANSACTION_FULFILLED',
  ApproveTransactionRejected = 'APPROVE_TRANSACTION_REJECTED',

  CancelShiftOffer = 'CANCEL_SHIFT_OFFER',
  CancelShiftOfferFulfilled = 'CANCEL_SHIFT_OFFER_FULFILLED',
  CancelShiftOfferRejected = 'CANCEL_SHIFT_OFFER_REJECTED',

  CreateVacationRequest = 'CREATE_VACATION_REQUEST',
  CreateVacationRequestFulfilled = 'CREATE_VACATION_REQUEST_FULFILLED',
  CreateVacationRequestRejected = 'CREATE_VACATION_REQUEST_REJECTED',

  DeclineShiftSwap = 'DECLINE_SHIFT_SWAP',
  DeclineShiftSwapFulfilled = 'DECLINE_SHIFT_SWAP_FULFILLED',
  DeclineShiftSwapRejected = 'DECLINE_SHIFT_SWAP_REJECTED',

  DeclineShiftTime = 'DECLINE_SHIFT_TIME',
  DeclineShiftTimeFulfilled = 'DECLINE_SHIFT_TIME_FULFILLED',
  DeclineShiftTimeRejected = 'DECLINE_SHIFT_TIME_REJECTED',

  DeclineTransaction = 'DECLINE_TRANSACTION',
  DeclineTransactionFulfilled = 'DECLINE_TRANSACTION_FULFILLED',
  DeclineTransactionRejected = 'DECLINE_TRANSACTION_REJECTED',

  DeleteShiftTimeRequest = 'DELETE_SHIFT_TIME_REQUEST',
  DeleteShiftTimeRequestFulfilled = 'DELETE_SHIFT_TIME_REQUEST_FULFILLED',
  DeleteShiftTimeRequestRejected = 'DELETE_SHIFT_TIME_REQUEST_REJECTED',

  DeleteTransactionRequest = 'DELETE_TRANSACTION_REQUEST',
  DeleteTransactionRequestFulfilled = 'DELETE_TRANSACTION_REQUEST_FULFILLED',
  DeleteTransactionRequestRejected = 'DELETE_TRANSACTION_REQUEST_REJECTED',

  DeleteVacationRequest = 'DELETE_VACATION_REQUEST',
  DeleteVacationRequestFulfilled = 'DELETE_VACATION_REQUEST_FULFILLED',
  DeleteVacationRequestRejected = 'DELETE_VACATION_REQUEST_REJECTED',

  FillInShiftOffer = 'FILL_IN_SHIFT_OFFER',
  FillInShiftOfferFulfilled = 'FILL_IN_SHIFT_OFFER_FULFILLED',
  FillInShiftOfferRejected = 'FILL_IN_SHIFT_OFFER_REJECTED',

  GetDepartmentShiftOffer = 'GET_DEPARTMENT_SHIFT_OFFER',
  GetDepartmentShiftOfferFulfilled = 'GET_DEPARTMENT_SHIFT_OFFER_FULFILLED',
  GetDepartmentShiftOfferRejected = 'GET_DEPARTMENT_SHIFT_OFFER_REJECTED',

  GetDepartmentShiftOffers = 'GET_DEPARTMENT_SHIFT_OFFERS',
  GetDepartmentShiftOffersFulfilled = 'GET_DEPARTMENT_SHIFT_OFFERS_FULFILLED',
  GetDepartmentShiftOffersRejected = 'GET_DEPARTMENT_SHIFT_OFFERS_REJECTED',

  GetDepartmentShiftSwap = 'GET_DEPARTMENT_SHIFT_SWAP',
  GetDepartmentShiftSwapFulfilled = 'GET_DEPARTMENT_SHIFT_SWAP_FULFILLED',
  GetDepartmentShiftSwapRejected = 'GET_DEPARTMENT_SHIFT_SWAP_REJECTED',

  GetDepartmentShiftSwaps = 'GET_DEPARTMENT_SHIFT_SWAPS',
  GetDepartmentShiftSwapsFulfilled = 'GET_DEPARTMENT_SHIFT_SWAPS_FULFILLED',
  GetDepartmentShiftSwapsRejected = 'GET_DEPARTMENT_SHIFT_SWAPS_REJECTED',

  GetDepartmentShiftTime = 'GET_DEPARTMENT_SHIFT_TIME',
  GetDepartmentShiftTimeFulfilled = 'GET_DEPARTMENT_SHIFT_TIME_FULFILLED',
  GetDepartmentShiftTimeRejected = 'GET_DEPARTMENT_SHIFT_TIME_REJECTED',

  GetDepartmentShiftTimes = 'GET_DEPARTMENT_SHIFT_TIMES',
  GetDepartmentShiftTimesFulfilled = 'GET_DEPARTMENT_SHIFT_TIMES_FULFILLED',
  GetDepartmentShiftTimesRejected = 'GET_DEPARTMENT_SHIFT_TIMES_REJECTED',

  GetDepartmentTransaction = 'GET_DEPARTMENT_TRANSACTION',
  GetDepartmentTransactionFulfilled = 'GET_DEPARTMENT_TRANSACTION_FULFILLED',
  GetDepartmentTransactionRejected = 'GET_DEPARTMENT_TRANSACTION_REJECTED',

  GetDepartmentTransactions = 'GET_DEPARTMENT_TRANSACTIONS',
  GetDepartmentTransactionsFulfilled = 'GET_DEPARTMENT_TRANSACTIONS_FULFILLED',
  GetDepartmentTransactionsRejected = 'GET_DEPARTMENT_TRANSACTIONS_REJECTED',

  GetDepartmentVacationRequests = 'GET_DEPARTMENT_VACATION_REQUESTS',
  GetDepartmentVacationRequestsFulfilled = 'GET_DEPARTMENT_VACATION_REQUESTS_FULFILLED',
  GetDepartmentVacationRequestsRejected = 'GET_DEPARTMENT_VACATION_REQUESTS_REJECTED',

  GetDepartmentVacationRequest = 'GET_DEPARTMENT_VACATION_REQUEST',
  GetDepartmentVacationRequestFulfilled = 'GET_DEPARTMENT_VACATION_REQUEST_FULFILLED',
  GetDepartmentVacationRequestRejected = 'GET_DEPARTMENT_VACATION_REQUEST_REJECTED',

  GetRequestCounts = 'GET_REQUEST_COUNTS',
  GetRequestCountsFulfilled = 'GET_REQUEST_COUNTS_FULFILLED',
  GetRequestCountsRejected = 'GET_REQUEST_COUNTS_REJECTED',

  GetRequestDepartments = 'GET_REQUEST_DEPARTMENTS',
  GetRequestDepartmentsFulfilled = 'GET_REQUEST_DEPARTMENTS_FULFILLED',
  GetRequestDepartmentsRejected = 'GET_REQUEST_DEPARTMENTS_REJECTED',

  GetShiftOfferDepartments = 'GET_SHIFT_OFFER_DEPARTMENTS',
  GetShiftOfferDepartmentsFulfilled = 'GET_SHIFT_OFFER_DEPARTMENTS_FULFILLED',
  GetShiftOfferDepartmentsRejected = 'GET_SHIFT_OFFER_DEPARTMENTS_REJECTED',

  GetShiftSwapDepartments = 'GET_SHIFT_SWAP_DEPARTMENTS',
  GetShiftSwapDepartmentsFulfilled = 'GET_SHIFT_SWAP_DEPARTMENTS_FULFILLED',
  GetShiftSwapDepartmentsRejected = 'GET_SHIFT_SWAP_DEPARTMENTS_REJECTED',

  GetShiftTimeDepartments = 'GET_SHIFT_TIME_DEPARTMENTS',
  GetShiftTimeDepartmentsFulfilled = 'GET_SHIFT_TIME_DEPARTMENTS_FULFILLED',
  GetShiftTimeDepartmentsRejected = 'GET_SHIFT_TIME_DEPARTMENTS_REJECTED',

  GetShiftTimeRequest = 'GET_SHIFT_TIME_REQUEST',
  GetShiftTimeRequestFulfilled = 'GET_SHIFT_TIME_REQUEST_FULFILLED',
  GetShiftTimeRequestRejected = 'GET_SHIFT_TIME_REQUEST_REJECTED',

  GetShiftTimeRequests = 'GET_SHIFT_TIME_REQUESTS',
  GetShiftTimeRequestsFulfilled = 'GET_SHIFT_TIME_REQUESTS_FULFILLED',
  GetShiftTimeRequestsRejected = 'GET_SHIFT_TIME_REQUESTS_REJECTED',

  GetTransactionDepartments = 'GET_TRANSACTIONS_DEPARTMENTS',
  GetTransactionDepartmentsFulfilled = 'GET_TRANSACTIONS_DEPARTMENTS_FULFILLED',
  GetTransactionDepartmentsRejected = 'GET_TRANSACTIONS_DEPARTMENTS_REJECTED',

  GetTransactionRequest = 'GET_TRANSACTION_REQUEST',
  GetTransactionRequestFulfilled = 'GET_TRANSACTION_REQUEST_FULFILLED',
  GetTransactionRequestRejected = 'GET_TRANSACTION_REQUEST_REJECTED',

  GetTransactionRequests = 'GET_TRANSACTION_REQUESTS',
  GetTransactionRequestsFulfilled = 'GET_TRANSACTION_REQUESTS_FULFILLED',
  GetTransactionRequestsRejected = 'GET_TRANSACTION_REQUESTS_REJECTED',

  GetVacationRequest = 'GET_VACATION_REQUEST',
  GetVacationRequestFulfilled = 'GET_VACATION_REQUEST_FULFILLED',
  GetVacationRequestRejected = 'GET_VACATION_REQUEST_REJECTED',

  GetVacationRequestCounters = 'GET_VACATION_REQUEST_COUNTERS',
  GetVacationRequestCountersFulfilled = 'GET_VACATION_REQUEST_COUNTERS_FULFILLED',
  GetVacationRequestCountersRejected = 'GET_VACATION_REQUEST_COUNTERS_REJECTED',

  GetVacationRequests = 'GET_VACATION_REQUESTS',
  GetVacationRequestsFulfilled = 'GET_VACATION_REQUESTS_FULFILLED',
  GetVacationRequestsRejected = 'GET_VACATION_REQUESTS_REJECTED',

  GetVacationTypes = 'GET_VACATION_TYPES',
  GetVacationTypesFulfilled = 'GET_VACATION_TYPES_FULFILLED',
  GetVacationTypesRejected = 'GET_VACATION_TYPES_REJECTED',

  ReadShiftTimeRequest = 'READ_SHIFT_TIME_REQUEST',
  ReadShiftTimeRequestFulfilled = 'READ_SHIFT_TIME_REQUEST_FULFILLED',
  ReadShiftTimeRequestRejected = 'READ_SHIFT_TIME_REQUEST_REJECTED',

  ReadTransactionRequest = 'READ_TRANSACTION_REQUEST',
  ReadTransactionRequestFulfilled = 'READ_TRANSACTION_REQUEST_FULFILLED',
  ReadTransactionRequestRejected = 'READ_TRANSACTION_REQUEST_REJECTED',

  ReadVacationRequest = 'READ_VACATION_REQUEST',
  ReadVacationRequestFulfilled = 'READ_VACATION_REQUEST_FULFILLED',
  ReadVacationRequestRejected = 'READ_VACATION_REQUEST_REJECTED',

  SetActiveDepartment = 'SET_ACTIVE_DEPARTMENT',
  SetActiveInbox = 'SET_ACTIVE_INBOX',
  SetRequestFilter = 'SET_REQUEST_FILTER',

  ToggleVacationRequestModal = 'TOGGLE_VACATION_REQUEST_MODAL',

  UpdateVacationRequest = 'UPDATE_VACATION_REQUEST',
  UpdateVacationRequestFulfilled = 'UPDATE_VACATION_REQUEST_FULFILLED',
  UpdateVacationRequestRejected = 'UPDATE_VACATION_REQUEST_REJECTED',

  CancelTransaction = 'CANCEL_TRANSACTION',
  CancelTransactionFulfilled = 'CANCEL_TRANSACTION_FULFILLED',
  CancelTransactionRejected = 'CANCEL_TRANSACTION_REJECTED',
}

export default ActionType;