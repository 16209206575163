import ActionType from './types';
import { Action } from 'redux';
import { IDateStringRange, IDayShiftSwap, IShift, UpdateApplicantStatus } from '../../models';
import { IServerError } from '../../http/HttpError';

export class ToggleshiftSwapModalAction implements Action<ActionType> {
  readonly type = ActionType.ToggleShiftSwapModal;
  constructor(public payload?: { shifts: IShift[] }) { }
}

export class CreateShiftSwapRequestAction implements Action<ActionType> {
  readonly type = ActionType.CreateShiftSwapRequest;
  constructor(public payload?: { date: string, hasCounterSwap: boolean; onSuccess?: () => void; onError?: (error: IServerError) => void }) { }
}

export class CreateShiftSwapRequestFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.CreateShiftSwapRequestFulfilled;
  constructor(public payload: { date: string }) { }
}

export class CreateShiftSwapRequestRejectedAction implements Action<ActionType> {
  readonly type = ActionType.CreateShiftSwapRequestRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetShiftSwapsAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftSwaps;
  constructor(public payload: { startDate: string, endDate: string }) { }
}

export class GetShiftSwapsFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftSwapsFulfilled;
  constructor(public payload: { data: IDayShiftSwap[], startDate: string, endDate: string, }) { }
}

export class GetShiftSwapsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftSwapsRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class CancelShiftSwapRequestAction implements Action<ActionType> {
  readonly type = ActionType.CancelShiftSwapRequest;
  constructor(public payload: { id: string; date: string; onSuccess?: () => void, onError?: (error: IServerError) => void }) { }
}

export class CancelShiftSwapRequestFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.CancelShiftSwapRequestFulfilled;
  constructor(public payload: { id: string, date: string }) { }
}

export class CancelShiftSwapRequestRejectedAction implements Action<ActionType> {
  readonly type = ActionType.CancelShiftSwapRequestRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class UpdateShiftSwapApplicantStatusAction implements Action<ActionType> {
  readonly type = ActionType.UpdateShiftSwapApplicantStatus;
  constructor(public payload: { id: string; type: UpdateApplicantStatus, date: string; onError?: (error: IServerError) => void }) { }
}

export class UpdateShiftSwapApplicantStatusFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.UpdateShiftSwapApplicantStatusFulfilled;
  constructor(public payload: { id: string, type: UpdateApplicantStatus, date: string }) { }
}

export class UpdateShiftSwapApplicantStatusRejectedAction implements Action<ActionType> {
  readonly type = ActionType.UpdateShiftSwapApplicantStatusRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class ApplyToShiftSwapWithCounterAction implements Action<ActionType> {
  readonly type = ActionType.ApplyToShiftSwapWithCounter;
  constructor(public payload: { id: string; date?: string; shifts: IDateStringRange[]; onSuccess?: () => void, onError?: (error: IServerError) => void }) { }
}

export class ApplyToShiftSwapWithCounterFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.ApplyToShiftSwapWithCounterFulfilled;
  constructor(public payload: { id: string, type: UpdateApplicantStatus, date?: string }) { }
}

export class ApplyToShiftSwapWithCounterRejectedAction implements Action<ActionType> {
  readonly type = ActionType.ApplyToShiftSwapWithCounterRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class RequestShiftSwapAction implements Action<ActionType> {
  readonly type = ActionType.RequestShiftSwap;
  constructor(public payload: { id: string; employmentId: string; selectedCounterSwapDate: string; onSuccess?: () => void, onError?: (error: IServerError) => void }) { }
}

export class RequestShiftSwapFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.RequestShiftSwapFulfilled;
  constructor(public payload: { id: string, employmentId: string }) { }
}

export class RequestShiftSwapRejectedAction implements Action<ActionType> {
  readonly type = ActionType.RequestShiftSwapRejected;
  constructor(public payload: { error: IServerError }) { }
}

export type Actions =
  | ApplyToShiftSwapWithCounterAction
  | ApplyToShiftSwapWithCounterFulfilledAction
  | ApplyToShiftSwapWithCounterRejectedAction
  | CancelShiftSwapRequestAction
  | CancelShiftSwapRequestFulfilledAction
  | CancelShiftSwapRequestRejectedAction
  | CreateShiftSwapRequestAction
  | CreateShiftSwapRequestFulfilledAction
  | CreateShiftSwapRequestRejectedAction
  | GetShiftSwapsAction
  | GetShiftSwapsFulfilledAction
  | GetShiftSwapsRejectedAction
  | RequestShiftSwapAction
  | RequestShiftSwapFulfilledAction
  | RequestShiftSwapRejectedAction
  | ToggleshiftSwapModalAction
  | UpdateShiftSwapApplicantStatusAction
  | UpdateShiftSwapApplicantStatusFulfilledAction
  | UpdateShiftSwapApplicantStatusRejectedAction;
