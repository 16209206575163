import React, { FC, useEffect, useRef } from 'react';
import { Spinner } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ITransactionRequest } from '../../../../../../shared/models';
import { routes } from '../../../../constants';
import { useInfiniteScroll } from '../../../../hooks';
import { requestsActions, requestsSelectors } from '../../../../redux';
import RequestDetail from './RequestDetail';
import RequestPreview from './RequestPreview';

import '../requestInbox.scss';

interface UrlId {
    id: string;
}

const RequestInbox: FC<RouteComponentProps<UrlId>> = (props) => {
    const dispatch = useDispatch();
    const scrollRef = useRef(null);

    const transactionRequests = useSelector(requestsSelectors.getTransactionRequests);
    const isTransactionRequestLoading = useSelector(requestsSelectors.isTransactionRequestLoading);
    const isMoreTransactionRequestLoading = useSelector(requestsSelectors.isMoreTransactionRequestLoading);
    const metaData = useSelector(requestsSelectors.getTransactionRequestsMetaData);

    const requests = !!transactionRequests && Object.values(transactionRequests);

    useEffect(() => {
        dispatch(new requestsActions.GetTransactionRequestsAction({ offset: 0 }));
    }, []);

    useEffect(() => {
        if (!isTransactionRequestLoading && requests?.length && !props.match.params?.id) props.history.push(`${routes.scheduleRequests.transactionInbox[0]}/${requests[0].id}`);
    }, [isTransactionRequestLoading, props.match.params.id]);


    useInfiniteScroll(
        (offset: number) => dispatch(new requestsActions.GetTransactionRequestsAction({ offset })),
        metaData,
        isTransactionRequestLoading || isMoreTransactionRequestLoading,
        scrollRef.current,
    );

    const renderRequestsPreview = (request: ITransactionRequest): JSX.Element => {
        return (
            <RequestPreview id={request.id} key={request.id} selectedId={props.match.params.id} />
        );
    };

    return (
        <div className="inbox">
            <section className="inbox-container">
                <aside ref={scrollRef} className="inbox-menu">
                    {isTransactionRequestLoading ? [0, 1, 2].map((index) =>
                        <div key={index} className="request-content request-list-loading" >
                            <Skeleton height={75} />
                        </div>
                    ) : transactionRequests && requests.map(renderRequestsPreview)}
                    {isMoreTransactionRequestLoading && <div className="loading-container"><Spinner solid /></div>}
                </aside>
                <RequestDetail id={props.match.params.id} isLoading={isTransactionRequestLoading} />
            </section>
        </div >
    );
};

export default RequestInbox;
