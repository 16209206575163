import { createSelector } from 'reselect';

import { EmployeeState } from './reducer';
import { AppState } from '../rootReducer';

const selectNode = (state: AppState) => state.employees;

export const isLoading = createSelector(
  selectNode,
  (state: EmployeeState) => state.isLoading,
);

export const getEmployee = (state: AppState, id: string) => state.employees.employeeDetails?.[id];
