import React, { FC, useState } from 'react';
import { Button, InputField, InputType, Modal } from 'react-ess-components';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { useToggle } from '../../../../hooks';
import { ITransactionRequest } from '../../../../models';
import { requestsActions } from '../../../../redux';
import { translations } from '../../../../utils';

interface Props {
  request: ITransactionRequest;
  departmentId?: string;
}

const ApproveDenyModal: FC<Props> = ({ request, departmentId }) => {
  const dispatch = useDispatch();
  const [isConfirmModalOpen, toggleConfirmModal] = useToggle(false);
  const [isDenyAction, setIsDenyAction] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reviewerNote, setReviewerNote] = useState<string>('');

  const toggleModal = (isDeny = false) => {
    setIsDenyAction(isDeny);
    toggleConfirmModal();
    setReviewerNote('');
    setIsLoading(false);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const Action = isDenyAction ? requestsActions.DeclineTransactionAction : requestsActions.ApproveTransactionAction;
    dispatch(new Action({
      transactionId: request.id,
      departmentId,
      reviewerNote,
      onSuccess: () => {
        setIsLoading(false);
        toggleModal();
      },
      onError: (error) => {
        setIsLoading(false);
        toggleModal();
        toast.error(error.title);
      },
    }));
  };

  return (
    <>
      <Button onClick={() => toggleModal(true)} theme="inverse">{translations.getLabel('btnDeny')}</Button>
      <Button onClick={() => toggleModal()}>{translations.getLabel('btnApprove')}</Button>

      <Modal
        open={isConfirmModalOpen}
        requestClose={toggleModal}
        title={translations.getLabel(isDenyAction ? 'titleDenyRequest' : 'titleApproveRequest')}
        rightButtonProps={{ label: translations.getLabel(isDenyAction ? 'btnDeny' : 'btnApprove'), onClick: handleSubmit, isLoading: isLoading }}
        leftButtonProps={{ label: translations.getLabel('cancel'), onClick: () => toggleModal(true) }}
      >
        <div className="approve-deny-container">
          <InputField
            type={InputType.Textarea}
            value={reviewerNote}
            onChange={setReviewerNote}
            className="large-padding"
            icon="ChatIcon"
            label={translations.getLabel('lblRemarks')}
          />
        </div>
      </Modal>
    </>
  );
};

export default ApproveDenyModal;
