import { createSelector } from 'reselect';
import { AppState } from '../rootReducer';
import { ShiftOfferState } from './reducer';
import { IDayShiftOffer } from '../../models';
import { eachDayOfInterval } from 'date-fns';
import { scheduleUtils } from '../../utils';

const selectNode = (state: AppState) => state.shiftOffer;


export const showShiftOffersModal = createSelector(
  selectNode,
  (state: ShiftOfferState): boolean => state.showShiftOfferModal,
);

export const getEmployeeId = createSelector(
  selectNode,
  (state: ShiftOfferState) => state.employeeId,
);

export const getDate = createSelector(
  selectNode,
  (state: ShiftOfferState) => state.date,
);

export const isLoading = createSelector(
  selectNode,
  (state: ShiftOfferState) => state.isLoading,
);

export const isCreateTemplateLoading = createSelector(
  selectNode,
  (state: ShiftOfferState) => state.isCreateTemplateLoading,
);

export const isCreateShiftOfferLoading = createSelector(
  selectNode,
  (state: ShiftOfferState) => state.isCreateShiftOfferLoading,
);

export const isTemplatesLoading = createSelector(
  selectNode,
  (state: ShiftOfferState) => state.isTemplatesLoading,
);

export const templates = createSelector(
  selectNode,
  (state: ShiftOfferState) => state.templates,
);

export const getShiftOffers = (state: AppState, start: Date, end: Date): IDayShiftOffer[][] => {
  const daysBetween = eachDayOfInterval({ start, end });
  const shiftOffers = [];
  daysBetween.forEach(day => {
    const foundShiftOffers = state.shiftOffer.shiftOffers?.[scheduleUtils.formatDateForSchedule(day)];
    if (foundShiftOffers) shiftOffers.push(foundShiftOffers);
    else shiftOffers.push([]);
  });
  return shiftOffers;
};

export const getShiftOffer = (state: AppState, id: string, date: string): IDayShiftOffer => {
  return state.shiftOffer.shiftOffers?.[scheduleUtils.formatDateForSchedule(date)]?.find((shiftOffer: IDayShiftOffer) => shiftOffer.id === id);
};

export const isUpdateApplicantStatusLoading = createSelector(
  selectNode,
  (state: ShiftOfferState) => state.isUpdateApplicantStatusLoading,
);

export const getScheduleDetail = createSelector(
  selectNode,
  (state: ShiftOfferState) => state.scheduleDetail?.[0],
);

export const isDetailLoading = createSelector(
  selectNode,
  (state: ShiftOfferState) => state.isDetailLoading,
);
