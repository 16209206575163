import React, { Component } from 'react';

import './tasks.scss';

class Tasks extends Component {
  render() {
    return (
      <div className="page fixed-width app-tasks">
        <p>TASKS</p>
      </div>
    );
  }
}

export default Tasks;
