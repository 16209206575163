import React, { useEffect, useState } from 'react';
import { Modal } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isBefore, parseISO } from 'date-fns';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { IVacationRequestDTO, Period } from '../../../../models';
import { requestsActions, requestsSelectors, userSelectors } from '../../../../redux';
import { date, requestsUtils, translations } from '../../../../utils';
import RequestVacationForm from '../requestVacation/RequestVacationForm';

const RequestVacationModal: React.FC = () => {
  const dispatch = useDispatch();
  const [vacationRequest, updateVacationRequest] = useState<IVacationRequestDTO>(null);
  const [isRequestValid, setRequestValidity] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  const isVacationRequestLoading = useSelector(requestsSelectors.isVacationRequestLoading);
  const requestDate = useSelector(requestsSelectors.getRequestDate);
  const showVacationRequestModal = useSelector(requestsSelectors.showVacationRequestModal);
  const vacationPeriod = useSelector((state: AppState) => userSelectors.getPeriods(state, Period.VacationRequestPeriod));
  const vacationTypes = useSelector(requestsSelectors.getVacationTypes);

  useEffect(() => {
    if (!showVacationRequestModal) {
      setRequestValidity(true);
      updateVacationRequest(null);
      setError('');
    }
  }, [showVacationRequestModal]);

  const onChangeRequestVacation = (vacationRequest) => {
    if (!vacationRequest.startDate || !vacationRequest.endDate) {
      return setRequestValidity(false);
    }

    if (isBefore(vacationRequest.endDate, vacationRequest.startDate)) {
      return setRequestValidity(false);
    }

    updateVacationRequest(vacationRequest);
    if (!isRequestValid) setRequestValidity(true);
  };

  const handleRequestVacation = () => {
    const startDate = (typeof vacationRequest.startDate === 'string' ? parseISO(vacationRequest.startDate) : vacationRequest.startDate);
    const endDate = (typeof vacationRequest.endDate === 'string' ? parseISO(vacationRequest.endDate) : vacationRequest.endDate);
    const request: IVacationRequestDTO = { ...vacationRequest, startDate: date.format(startDate, 'yyyy-MM-dd\'T\'HH:mm:ss'), endDate: date.format(endDate, 'yyyy-MM-dd\'T\'HH:mm:ss') };
    setError('');

    dispatch(new requestsActions.CreateVacationRequestAction({
      request,
      onSuccess: () => toast(translations.getLabel('lblVacationRequestSuccess')),
      onError: (error) => setError(error.detail),
    }));
  };

  if (!showVacationRequestModal) return null;

  return (
    <div className="request-vacation-modal">
      <Modal
        title={translations.getLabel('titleRequestVacation')}
        requestClose={() => dispatch(new requestsActions.ToggleVacationRequestModalAction())}
        leftButtonProps={{
          label: translations.getLabel('cancel'),
          onClick: () => dispatch(new requestsActions.ToggleVacationRequestModalAction()),
        }}
        rightButtonProps={{
          label: translations.getLabel('btnRequest'),
          isLoading: isVacationRequestLoading,
          disabled: (!!vacationRequest && !requestsUtils.isVacationRequestValid(vacationRequest, vacationPeriod)) || !isRequestValid,
          onClick: handleRequestVacation,
        }}
        open={showVacationRequestModal}>
        {
          error &&
          <div className="error-message" aria-live="assertive" role="alert">
            <p>{error}</p>
          </div>
        }
        <RequestVacationForm
          endDate={requestDate}
          onChange={onChangeRequestVacation}
          startDate={requestDate}
          vacationPeriod={vacationPeriod}
          vacationTypes={vacationTypes}
        />
      </Modal>
    </div>
  );
};

export default RequestVacationModal;
