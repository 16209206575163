import React, { FC } from 'react';
import { Icon } from 'react-ess-components';

import { translations } from '../../utils';

import './collapseToggle.scss';

export enum ChevronLocation { Left = 'left', Right = 'right' }
enum ChevronType { Up = 'up', Down = 'down' }

type Props = {
  onClick: (isCollapsed: boolean) => void;
  chevronLocation?: ChevronLocation;
  label?: string;
  value?: string;
  isCollapsed?: boolean;
}

const CollapseToggle: FC<Props> = ({ chevronLocation = ChevronLocation.Left, label, value, isCollapsed = false, onClick }) => {
  const getValueForSpan = (chevronType) => {
    if (value) return value;
    if (label) return translations.getLabel(label);

    // defaults
    if (chevronType === ChevronType.Down) return translations.getLabel('lblExpandAll');
    if (chevronType === ChevronType.Up) return translations.getLabel('lblCollapseAll');
  };

  const handleClick = () => {
    onClick(!isCollapsed);
  };

  return (
    <button className="collapse-wrapper" onClick={handleClick} >
      {
        isCollapsed
          ?
          <React.Fragment>
            {chevronLocation === ChevronLocation.Left && <Icon tag="ChevronDownIcon" title={translations.getLabel('lblExpand')} />}
            <span className={`label chevron-${chevronLocation}`}>{getValueForSpan(ChevronType.Down)}</span>
            {chevronLocation === ChevronLocation.Right && <Icon tag="ChevronDownIcon" title={translations.getLabel('lblExpand')} />}
          </React.Fragment>
          :
          <React.Fragment>
            {chevronLocation === ChevronLocation.Left && <Icon tag="ChevronUpIcon" title={translations.getLabel('lblCollapse')} />}
            <span className={`label chevron-${chevronLocation}`}>{getValueForSpan(ChevronType.Up)}</span>
            {chevronLocation === ChevronLocation.Right && <Icon tag="ChevronUpIcon" title={translations.getLabel('lblCollapse')} />}
          </React.Fragment>
      }
    </button>
  );
};

export default CollapseToggle;
