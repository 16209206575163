import React, { FC, useState } from 'react';
import { Modal } from 'react-ess-components';
import { toast } from 'react-toastify';

import { ISelfRosterSaveError } from '../../../../../../shared/http/HttpError';
import { ISelfRosteringIteration } from '../../../../models';
import { SelfRosterSaveErrorCodes, useSaveSelfRoster, useSelfRosterContext, useSelfRosterSchedule, useSelfRosterSelectedShifts } from '../../../../modules';
import { date, selfRosterUtils, translations } from '../../../../utils';
import RuleViolationError from '../ruleViolationError/RuleViolationError';
import Round1Schedule from './components/Round1Schedule';
import Round2Schedule from './components/Round2Schedule';

import './saveModal.scss';

type Props = {
  open: boolean;
  onClose: () => void;
  onConflictedSave: () => void;
  continueNavigation?: () => void;
  selectedIteration: ISelfRosteringIteration;
};

const SaveModal: FC<Props> = ({ open, onClose, onConflictedSave, continueNavigation = null, selectedIteration }) => {
  const { selectedShifts, setSelectedShifts } = useSelfRosterContext();
  const [serverError, setServerError] = useState<ISelfRosterSaveError>();
  const { data: schedules, refetch: refetchSchedule } = useSelfRosterSchedule({ startDate: selectedIteration?.startDate, endDate: selectedIteration?.endDate, iterationId: selectedIteration?.id });
  const { data: originalRoster, refetch: refetchOriginalSchedule } = useSelfRosterSelectedShifts({ startDate: selectedIteration?.startDate, endDate: selectedIteration?.endDate, iterationId: selectedIteration?.id });
  const { isLoading, mutate: saveSelfRoster} = useSaveSelfRoster(selectedIteration?.id, {
    onSuccess: () => {
      onClose();
      continueNavigation?.();
      if (isRound2) { 
        refetchSchedule();
        refetchOriginalSchedule();
      }
    },
    onError: (error) => {
      if (error.code === SelfRosterSaveErrorCodes.ConflictedSave) {
        onClose();
        onConflictedSave();
      }
      else if (error.code === SelfRosterSaveErrorCodes.RuleViolation) {
        setServerError(error);
      } else {
        toast.error(error[0].title);
      }
    },
  });

  if (!open || !selectedIteration) return null;
  const { isRound1, isRound2 } = selfRosterUtils.getIterationChecks(selectedIteration);

  const onSave = () => {
    const body = selfRosterUtils.formatSelfRosterForSave(selectedShifts, originalRoster, schedules[0].departmentId);
    saveSelfRoster(body);
  };

  const timePeriodTitle = translations.getLabel('titleSaveSelfRoster', { startDate: date.format(selectedIteration?.startDate, 'd MMMM'), endDate: date.format(selectedIteration?.endDate, 'd MMMM') });

  const getTitleForRound = (() => {
    if (isRound1) return continueNavigation ? translations.getLabel('titleUnsavedSelfRoster') : timePeriodTitle;
    if (isRound2) return translations.getLabel('titleSaveSelfRosterWishPlanning');
    return '';
  });

  const resetChanges = () => {
    onClose();
    setSelectedShifts(originalRoster.flatMap(roster => roster.shifts));
    continueNavigation?.();
  };

  const toggleSaveModal = () => {
    setServerError(null);
    onClose();
  };

  return (
    <Modal
      title={getTitleForRound()}
      open={open}
      className="self-roster-save-modal"
      requestClose={toggleSaveModal}
      leftButtonProps={continueNavigation || isRound2 ? {
        label: translations.getLabel('btnCloseWithoutSaving'),
        onClick: isRound2 ? resetChanges : continueNavigation,
      } : null}
      rightButtonProps={{
        label: translations.getLabel('btnConfirmSave'),
        isLoading,
        onClick: onSave,
      }}
    >
      {!!continueNavigation && isRound1 && <>
        <p className="unsaved-label">{translations.getLabel('lblUnsavedSelfRoster')}</p>
        <h4>{timePeriodTitle}</h4>
      </>}
      <RuleViolationError serverError={serverError} />
      {isRound2 && <p className="unsaved-label">{translations.getLabel('lblUnsavedChangesAreLost')}</p>}
      {isRound1 && <Round1Schedule startDate={selectedIteration.startDate} endDate={selectedIteration.endDate} />}
      {isRound2 && <Round2Schedule />}
    </Modal>
  );
};

export default SaveModal;
