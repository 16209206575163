import React from 'react';
import { DatePicker, IconButton } from 'react-ess-components';
import { useSelector } from 'react-redux';

import { Locale, TimeScale } from '../../models';
import { configSelectors } from '../../redux';
import { date, scheduleUtils, translations } from '../../utils';

import './SchedulePageSwitch.scss';

type Props = {
  changeDate: (amount: number) => void;
  selectedDate: Date;
  dateRange: TimeScale;
  setSpecificDate: (date: Date) => void;
}

const SchedulePageSwitch = ({ changeDate, selectedDate, dateRange, setSpecificDate }: Props) => {
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const decreaseDateRange = () => changeDate(-1);
  const increaseDateRange = () => changeDate(1);

  const getLabel = () => {
    const label = scheduleUtils.getScheduleHeader(dateRange, selectedDate, date.format, weekStartsOn);
    if (label.shouldTranslate) return translations.getLabel(label.value);
    return label.value;
  };

  return (
    <div className="schedule-page-switch">
      <IconButton tag="ChevronLeftIcon" onClick={decreaseDateRange} title={translations.getLabel('lblPrevious')} />
      <span className="label inline">{getLabel()}</span>
      <IconButton tag="ChevronRightIcon" onClick={increaseDateRange} title={translations.getLabel('lblNext')} />
      <DatePicker
        weekStartsOn={weekStartsOn}
        selected={selectedDate}
        locale={translations.I18n.locale as Locale}
        customInput={
          <IconButton onClick={() => null} tag="CalendarIcon" className="calendar-icon" color="primary" aria-label={translations.getLabel('lblDatepicker')} title={translations.getLabel('lblDatePicker')}/>
        }
        onChange={setSpecificDate}
        popperPlacement="top"
      />
    </div>
  );
};

SchedulePageSwitch.defaultProps = {
  label: 'Today',
  dateRange: TimeScale.Day,
};

export default SchedulePageSwitch;

