import { produce } from 'immer';

import ActionType from './types';
import { identifiers } from '../../constants';
import { IDayPlanning, ISearchPlanning, IPlanning, IShiftOfferApplicant, IShiftSwapApplicant, IRequestCounter, CounterType, IExternalVacationRequest } from '../../models';
import { formatDateForSchedule } from '../../utils/schedule';
import { Actions } from './actions';
import { wrapPayload } from '../../utils/test';
import { eachDayOfInterval, isSameDay, parseISO } from 'date-fns';

export interface ScheduleState {
  fullPlanning: boolean;
  isDepartmentSearchLoading: boolean;
  planningExternalVacationRequests: Record<string, IExternalVacationRequest>;
  isDeletingExternalVacationRequest: boolean;
  planning: IPlanning;
  planningDepartment?: Record<string, IDayPlanning[]>
  planningSearch: IPlanning;
  searchValue?: string;
  hasPlanningTodayError?: boolean;
  hasPlanningError?: boolean;
  shiftOfferApplicants?: Record<string, IShiftOfferApplicant[]>;
  shiftSwapApplicants?: Record<string, IShiftSwapApplicant[]>;
  isLoading: boolean;
  isShiftOfferApplicantsLoading?: boolean;
  isDepartmentPlanningLoading?: boolean;
}

export const initialState: ScheduleState = {
  fullPlanning: false,
  isDepartmentSearchLoading: false,
  planningExternalVacationRequests: {},
  isDeletingExternalVacationRequest: false,
  planning: null,
  planningDepartment: null,
  planningSearch: null,
  searchValue: null,
  shiftOfferApplicants: {},
  shiftSwapApplicants: {},
  isLoading: false,
  isShiftOfferApplicantsLoading: false,
  isDepartmentPlanningLoading: false,
};

const setPlanningInStore = (draft: ScheduleState, planning: IDayPlanning[], employee, indexKey = 'planning', departmentId?: string) => {
  const tmpPlanning = departmentId ? { ...draft[indexKey]?.[departmentId] } : { ...draft[indexKey] };
  planning.forEach(item => {
    const { date } = item;
    if (!tmpPlanning[formatDateForSchedule(date)]) tmpPlanning[formatDateForSchedule(date)] = {};
    tmpPlanning[formatDateForSchedule(date)][employee.employeeId] = { ...item, ...employee };
  });
  if (departmentId) {
    if (!draft[indexKey]) draft[indexKey] = {};
    draft[indexKey][departmentId] = tmpPlanning;
  } else {
    draft[indexKey] = tmpPlanning;
  }
  return draft;
};

const schedule = produce((draft: ScheduleState, action: Actions) => {
  switch (action.type) {
    case ActionType.GetDepartmentPlanning:
      draft.isDepartmentPlanningLoading = true;
      draft.isDepartmentSearchLoading = false;
      draft.searchValue = null;
      break;

    case ActionType.GetPlanningToday:
      draft.hasPlanningTodayError = false;
      break;

    case ActionType.GetPlanningTodayRejected:
      draft.hasPlanningTodayError = true;
      break;

    case ActionType.GetPlanning:
      draft.hasPlanningError = false;
      draft.isLoading = true;
      break;

    case ActionType.GetPlanningRejected:
      draft.hasPlanningError = true;
      break;

    case ActionType.GetPlanningTodayFulfilled:
    case ActionType.GetPlanningFulfilled:
      setPlanningInStore(draft, action.payload.data, { employeeId: identifiers.me });
      draft.isLoading = false;
      break;

    case ActionType.GetUserPlanningFulfilled:
      const { planning, ...employee } = action.payload.data;
      setPlanningInStore(draft, planning, employee);
      break;
  
    case ActionType.GetUserPlanningExternalVacationFulfilled:
      const externalVacations = [...action.payload.data];
      eachDayOfInterval({ start: parseISO(action.payload.startDate), end: parseISO(action.payload.endDate) }).forEach(day => {
        const strDay = formatDateForSchedule(day);
        const index = externalVacations.findIndex(vacation => isSameDay(parseISO(vacation.startDate), day));
        if(index >= 0) {
          // Use splice to remove the item from the array so on the next loop we make it easier to iterate
          draft.planningExternalVacationRequests[strDay] = externalVacations.splice(index, 1)[0];
        }
      });
      break;
    
    case ActionType.DeleteUserPlanningExternalVacation:
      draft.isDeletingExternalVacationRequest = true;
      break;
    
    case ActionType.DeleteUserPlanningExternalVacationRejected:
      draft.isDeletingExternalVacationRequest = false;
      break;
      
    case ActionType.DeleteUserPlanningExternalVacationFulfilled:
      delete draft.planningExternalVacationRequests[formatDateForSchedule(parseISO(action.payload.date))];
      draft.isDeletingExternalVacationRequest = false;
      break;

    case ActionType.GetDepartmentPlanningRejected:
      draft.isDepartmentPlanningLoading = false;
      break;

    case ActionType.GetDepartmentPlanningFulfilled:
      draft.isDepartmentSearchLoading = false;
      draft.isDepartmentPlanningLoading = false;
      action.payload.data.forEach(employee => {
        const { employeeId, employeeFirstName, employeeLastName, planning, profilePictureUrl, employmentId } = employee;
        setPlanningInStore(draft, planning, { employeeId, employeeFirstName, employeeLastName, profilePictureUrl, employmentId }, action.payload.departmentId ? 'planningDepartment' : 'planning', action.payload.departmentId);
      });
      draft.fullPlanning = action.payload.data.length > 0;
      break;

    case ActionType.SearchDepartmentPlanning:
      // Reset planningSearch before we set a new search
      draft.planningSearch = null;
      draft.isDepartmentSearchLoading = true;
      draft.searchValue = action.payload.searchValue;
      break;

    case ActionType.SearchDepartmentPlanningFulfilled:
      draft.isDepartmentSearchLoading = false;
      action.payload.data.forEach((employeePlanning: ISearchPlanning) => {
        const { planning, ...employee } = employeePlanning;
        setPlanningInStore(draft, planning, employee, 'planningSearch');
      });
      break;

    case ActionType.SearchDepartmentPlanningRejected:
      draft.isDepartmentSearchLoading = false;
      draft.searchValue = null;
      draft.planningSearch = null;
      break;

    case ActionType.GetShiftOfferApplicantsPlanning:
      draft.isShiftOfferApplicantsLoading = true;
      break;

    case ActionType.GetShiftOfferApplicantsPlanningRejected:
      draft.isShiftOfferApplicantsLoading = false;
      break;

    case ActionType.GetShiftOfferApplicantsPlanningFulfilled:
      const employees: IShiftOfferApplicant[] = [];
      action.payload.data.forEach(employee => {
        const { employeeId, employeeFirstName, employeeLastName, planning, counters, profilePictureUrl, employmentId } = employee;
        employees.push({ employeeId, employmentId, counters });
        setPlanningInStore(draft, planning, { employeeId, employeeFirstName, employeeLastName, profilePictureUrl, employmentId }, 'planningDepartment', action.payload.departmentId);
      });
      draft.fullPlanning = action.payload.data.length > 0;
      draft.shiftOfferApplicants[action.payload.shiftOfferId] = employees;
      draft.isShiftOfferApplicantsLoading = false;
      break;

    case ActionType.GetShiftSwapApplicantsPlanning:
      draft.isLoading = true;
      break;
    case ActionType.GetShiftSwapApplicantsPlanningFulfilled:
      const applicants = [];
      action.payload.data.forEach(employee => {
        const { employeeId, employeeFirstName, employeeLastName, planning, profilePictureUrl, employmentId, counterSwapDates } = employee;
        applicants.push({ employeeId, employmentId, counterSwapDates });
        setPlanningInStore(draft, planning, { employeeId, employeeFirstName, employeeLastName, profilePictureUrl, employmentId }, 'planning');
      });
      draft.fullPlanning = action.payload.data.length > 0;
      draft.shiftSwapApplicants[action.payload.shiftSwapId] = applicants;
      draft.isLoading = false;
      break;
    case ActionType.GetShiftSwapApplicantsPlanningRejected:
      draft.isLoading = false;
      break;
  }
}, initialState);

export default schedule;
