import React, { FC } from 'react';
import { Icon, Modal } from 'react-ess-components';

import { translations } from '../../../../utils';

import './conflictedSaveModal.scss';

type Props = {
  open: boolean;
}
const ConflictedSaveModal: FC<Props> = ({ open }) => {
  return (
    <Modal open={open} className="save-conflict-modal" rightButtonProps={{ label: translations.getLabel('btnReloadPlanning'), onClick: () => window.location.reload() }}>
      <h4><Icon tag="Alert" /> {translations.getLabel('titleConflictedSelfRosterSave')}</h4>
      <p>{translations.getLabel('lblConflictedSelfRosterSave')}</p>
    </Modal>
  );
};

export default ConflictedSaveModal;
