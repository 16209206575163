import { produce } from 'immer';

import ActionType from './types';
import { ITimeRegistration, ITimeRegistrationType, IActiveTimeRegistration, ValidationType, IDoubleTimeRegistration } from '../../models';
import { Actions } from './actions';

export interface TimeRegistrationState {
  timeRegistrations: ITimeRegistration[];
  doubleTimeRegistrations: IDoubleTimeRegistration[];
  activeTimeRegistrations: IActiveTimeRegistration[];
  timeRegistrationTypes: ITimeRegistrationType[];
  validationTypes: ValidationType[];
  isLoading: boolean;
  isTypesLoading: boolean;
  lastClockId: string;
  errorId: string;
  isDeviceRegistered?: boolean;
  isDeviceRegisterLoading?: boolean;
}

export const initialState: TimeRegistrationState = {
  timeRegistrations: null,
  doubleTimeRegistrations: null,
  activeTimeRegistrations: null,
  validationTypes: null,
  isLoading: false,
  timeRegistrationTypes: [],
  isTypesLoading: false,
  lastClockId: '',
  errorId: '',
};

const timeRegistration = produce((draft: TimeRegistrationState, action: Actions) => {
  switch (action.type) {
    case ActionType.GetTimeRegistrations:
      draft.isLoading = true;
      break;

    case ActionType.GetTimeRegistrationsFulfilled:
      draft.isLoading = false;
      draft.timeRegistrations = action.payload.data;
      break;

    case ActionType.GetTimeRegistrationsRejected:
      draft.isLoading = false;
      break;

    case ActionType.GetDoubleTimeRegistrations:
      draft.isLoading = true;
      break;

    case ActionType.GetDoubleTimeRegistrationsFulfilled:
      draft.isLoading = false;
      draft.doubleTimeRegistrations = action.payload.data;
      break;

    case ActionType.GetDoubleTimeRegistrationsRejected:
      draft.isLoading = false;
      break;

    case ActionType.GetActiveTimeRegistrationsAndTypes:
      draft.isLoading = true;
      draft.activeTimeRegistrations = [];
      break;

    case ActionType.GetActiveTimeRegistrationsAndTypesFulfilled:
      draft.isLoading = false;
      draft.activeTimeRegistrations = action.payload?.registrations;
      draft.timeRegistrationTypes = action.payload?.types;
      draft.validationTypes = action.payload?.validationTypes;
      break;

    case ActionType.GetActiveTimeRegistrationsAndTypesRejected:
      draft.isLoading = false;
      break;

    case ActionType.Clock:
      draft.lastClockId = action.payload.id;
      draft.errorId = '';
      break;

    case ActionType.ClockRejected:
      draft.errorId = draft.lastClockId;
      break;

    case ActionType.CheckRegisteredDevice:
      draft.isDeviceRegisterLoading = true;
      break;

    case ActionType.CheckRegisteredDeviceFulfilled:
      draft.isDeviceRegisterLoading = false;
      draft.isDeviceRegistered = action.payload;
      break;

    case ActionType.RegisteredDevice:
      draft.isDeviceRegisterLoading = true;
      break;

    case ActionType.RegisteredDeviceFulfilled:
      draft.isDeviceRegisterLoading = false;
      draft.isDeviceRegistered = true;
      break;

    case ActionType.RegisteredDeviceRejected:
      draft.isDeviceRegisterLoading = false;
      break;

    // No default case is needed, since produce returns by default the draft state
  };
}, initialState);

export default timeRegistration;
