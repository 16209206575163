import { produce } from 'immer';

import ActionType from './types';
import ScheduleType from '../schedule/types';
import UserType from '../user/types';
import DepartmentsType from '../departments/types';
import AvailabilitiesType from '../availabilities/types';
import CounterType from '../counters/types';
import RequestsType from '../requests/types';
import ShiftOfferType from '../shiftOffer/types';
import ShiftSwapType from '../shiftSwap/types';
import ChatType from '../chat/types';
import TimeRegistrationType from '../timeRegistration/types';
import { ToasterType } from '../../models';

export interface ToasterState {
  shouldShow: boolean;
  label: string;
  message: string;
  type: ToasterType;
}

export const initialState: ToasterState = {
  shouldShow: false,
  label: '',
  message: '',
  type: ToasterType.Confirmation,
};

// Use this reducer to dynamically add toasters ;) all the rest will be taken care of.
const toaster = produce((draft: ToasterState, action) => {
  const errorTitle = typeof action.payload?.error?.detail === 'string' ? action.payload?.error?.detail : action.payload?.error?.title;
  switch (action.type) {
    case ActionType.DismissToaster:
      return initialState;

    case ActionType.ShowToaster:
      const { type, label, message } = action.payload;
      draft.shouldShow = true;
      draft.type = type;
      draft.label = label || '';
      draft.message = message || '';
      break;

    case RequestsType.CreateVacationRequestFulfilled:
      draft.label = 'lblVacationRequestSuccess';
      draft.message = '';
      draft.type = ToasterType.Confirmation;
      draft.shouldShow = true;
      break;

    case RequestsType.CreateVacationRequestRejected:
      draft.message = action.payload?.error?.detail;
      draft.type = ToasterType.Error;
      draft.shouldShow = true;
      break;

    case RequestsType.CancelShiftOfferFulfilled:
    case RequestsType.DeleteVacationRequestFulfilled:
      draft.label = 'lblRequestCancelled';
      draft.message = '';
      draft.type = ToasterType.Confirmation;
      draft.shouldShow = true;
      break;

    case RequestsType.CancelShiftOfferRejected:
    case RequestsType.DeleteVacationRequestRejected:
      draft.message = errorTitle;
      draft.type = ToasterType.Error;
      draft.shouldShow = true;
      break;

    case RequestsType.GetVacationRequestRejected:
      draft.message = action.payload?.error?.detail;
      draft.type = ToasterType.Error;
      draft.shouldShow = true;
      break;

    case AvailabilitiesType.PutAvailabilitiesFulfilled:
    case DepartmentsType.UpdateDepartmentPeriodsFulfilled:
      draft.label = 'lblChangesSavedSuccess';
      draft.message = '';
      draft.type = ToasterType.Confirmation;
      draft.shouldShow = true;
      break;

    case DepartmentsType.UpdateDepartmentPeriodsRejected:
      draft.label = 'lblGenericError';
      draft.type = ToasterType.Error;
      draft.shouldShow = true;
      break;

    case AvailabilitiesType.PutAvailabilitiesRejected:
    case AvailabilitiesType.GetAvailabilitiesRejected:
      draft.message = errorTitle;
      draft.type = ToasterType.Error;
      draft.shouldShow = true;
      break;

    case ScheduleType.GetUserPlanningRejected:
      draft.label = 'lblUserScheduleNotFound';
      draft.message = '';
      draft.type = ToasterType.Error;
      draft.shouldShow = true;
      break;

    case ChatType.CreateConversationRejected:
      draft.label = '';
      draft.message = action.payload.error.detail;
      draft.type = ToasterType.Error;
      draft.shouldShow = true;
      break;

    case ShiftOfferType.CreateTemplateFulfilled:
      draft.label = 'lblTemplateCreated';
      draft.message = '';
      draft.type = ToasterType.Confirmation;
      draft.shouldShow = true;
      break;

    case ShiftOfferType.CreateShiftOfferFulfilled:
      draft.label = 'lblShiftOfferCreated';
      draft.message = '';
      draft.type = ToasterType.Confirmation;
      draft.shouldShow = true;
      break;

    case ShiftOfferType.CreateTemplateRejected:
    case ShiftOfferType.UpdateShiftOfferApplicantStatusRejected:
    case RequestsType.FillInShiftOfferRejected:
    case RequestsType.ApproveShiftSwapRejected:
    case RequestsType.DeclineShiftSwapRejected:
    case ShiftOfferType.CreateShiftOfferRejected:
    case ShiftSwapType.UpdateShiftSwapApplicantStatusRejected:
    case ShiftSwapType.ApplyToShiftSwapWithCounterRejected:
    case ShiftSwapType.CreateShiftSwapRequestRejected:
    case ShiftSwapType.CancelShiftSwapRequestRejected:
    case ShiftSwapType.RequestShiftSwapRejected:
    case TimeRegistrationType.ClockRejected:
    case RequestsType.GetDepartmentShiftTimeRejected:
      if (action.payload?.error?.detail && typeof action.payload.error.detail === 'string') {
        draft.message = action.payload.error.detail;
        draft.label = '';
      } else {
        draft.message = '';
        draft.label = 'lblGenericError';
      }
      draft.type = ToasterType.Error;
      draft.shouldShow = true;
      break;

    case TimeRegistrationType.RegisteredDeviceRejected:
      draft.label = '';
      draft.message = action.payload.error.detail;
      draft.type = ToasterType.Error;
      draft.shouldShow = true;
      break;

    case CounterType.GetCounterOverviewRejected:
    case UserType.UpdateNotificationPreferencesRejected:
    case UserType.UpdateUserRejected:
      draft.label = '';
      draft.message = action.payload.error.detail;
      draft.type = ToasterType.Error;
      draft.shouldShow = true;
      break;
  }
}, initialState);

export default toaster;

