import { PureComponent } from 'react';
import PropTypes from 'prop-types';

class DataItem extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return null;
  }
}

DataItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};

DataItem.defaultProps = {
  title: '',
  value: '',
};

export default DataItem;