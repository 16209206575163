import { useState, useRef, useEffect } from 'react';

const useClock = (initSeconds) => {
  const [seconds, setSeconds] = useState(initSeconds);
  const [isClockStarted, setIsClockStarted] = useState(false);
  const clockInterval = useRef(null);

  useEffect(() => {
    return clearInterval(clockInterval.current);
  }, []);

  const startClock = () => {
    setIsClockStarted(true);
    clockInterval.current = setInterval(tick, 1000);
  };

  const tick = () => setSeconds((prevSeconds) => (prevSeconds + 1));

  const pauseClock = () => {
    clearInterval(clockInterval.current);
  };

  const resetClock = () => {
    clearInterval(clockInterval.current);
    setSeconds(0);
  };

  return {
    seconds, startClock, pauseClock, resetClock, isClockStarted,
  };
};

export default useClock;