import React from 'react';
import { Icon } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import NotificationItemBase from '../../../../../../shared/components/notifications/notificationItem/NotificationItem.base';
import { routes } from '../../../../constants';
import { notificationUtils } from '../../../../utils';

import './notificationItem.scss';

class NotificationItem extends NotificationItemBase {
  onClick = async () => {
    if (!this.props.read) {
      this.props.markAsRead();
    }

    const url = notificationUtils.getRedirectUrl(this.props.type, this.props.location.pathname, this.props.identifier);

    if (url) {
      const samePage = this.props.location.pathname.split('/')[1] === url.split('/')[1];
      this.props.history.push(url);
      //Reload the page when you navigate to the same page but change the query
      if (samePage && !this.props.location.pathname.includes(routes.scheduleRequests.landingPage)) window.location.reload();
    }
  }

  render() {
    if (!this.props.isLoading) {
      const containerClassNames = classNames('stripped-button notification-item-container', {
        'unread': !this.props.read,
      });
      return (
        <button className={containerClassNames} onClick={this.onClick}>
          <div className="icon-container">
            <Icon tag={this.iconTag} color={this.props.isSameDepartment ? 'primary' : 'textGray'} />
            {!this.props.isSameDepartment && <Icon tag="CrossDepartmentIcon" keepOriginalColor small />}
          </div>
          <div className="text-container">
            <p>{this.props.value}</p>
            <span className="date">{this.createdDate}</span>
          </div>
        </button>
      );
    }

    return (
      <div className="notification-item-container">
        <div className="text-container">
          <p><Skeleton width={350} /></p>
          <span className="date"><Skeleton width={100} /></span>
        </div>
      </div>
    );
  }
}

NotificationItem.propTypes = {
  ...NotificationItemBase.proptypes,
};

NotificationItem.defaultProps = {
  ...NotificationItemBase.defaultProps,
};

export { NotificationItem };
export default withRouter(NotificationItem);
