import { Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { switchMap, exhaustMap, map, catchError, filter } from 'rxjs/operators';

import ActionType from './types';
import { notificationsService } from '../../services';
import * as notificationsSelectors from './selectors';
import {
  GetNotificationsFulfilledAction,
  GetNotificationsRejectedAction,
  LoadMoreNotificationsAction,
  LoadMoreNotificationsFulfilledAction,
  LoadMoreNotificationsRejectedAction,
  GetUnreadCountFulfilledAction,
  GetUnreadCountRejectedAction,
  MarkAsReadAction,
  MarkAsReadFulfilledAction,
  MarkAsReadRejectedAction,
  GetNotificationsAction,
} from './actions';

export const getNotificationEpic$: Epic = (action$) =>
  action$.ofType(ActionType.GetNotifications).pipe(
    exhaustMap(({ payload }: GetNotificationsAction) =>
      from(notificationsService.getAll()).pipe(
        map(response => {
          payload?.onSuccess?.();
          return new GetNotificationsFulfilledAction(response);
        }),
        catchError(error => of(new GetNotificationsRejectedAction({ error })))
      )
    ),
  );

export const loadMoreNotificationsEpic$: Epic = action$ =>
  action$.ofType(ActionType.LoadMoreNotifications).pipe(
    exhaustMap(({ payload }: LoadMoreNotificationsAction) =>
      from(notificationsService.getAll(payload.limit, payload.offset)).pipe(
        map(response => new LoadMoreNotificationsFulfilledAction(response)),
        catchError(error => of(new LoadMoreNotificationsRejectedAction({ error })))
      )
    ),
  );

export const getUnreadCountEpic$: Epic = (action$, state$) =>
  action$.ofType(ActionType.GetUnreadCount).pipe(
    exhaustMap(() =>
      from(notificationsService.getUnread()).pipe(
        switchMap(amount => {
          const oldAmount = notificationsSelectors.amountUnread(state$.value);
          if (amount !== oldAmount) {
            return of(
              new GetUnreadCountFulfilledAction({ amount }),
              new GetNotificationsAction(),
            );
          }
          return of(new GetUnreadCountFulfilledAction({ amount }));
        }),
        catchError(error => of(new GetUnreadCountRejectedAction({ error })))
      )
    ),
  );

export const markAsReadEpic$: Epic = action$ =>
  action$.ofType(ActionType.MarkAsRead).pipe(
    exhaustMap(({ payload }: MarkAsReadAction) =>
      from(notificationsService.markAsRead(payload.id)).pipe(
        map(id => new MarkAsReadFulfilledAction({ id })),
        catchError(error => of(new MarkAsReadRejectedAction({ error })))
      )
    ),
  );

const epics = [
  getNotificationEpic$,
  loadMoreNotificationsEpic$,
  getUnreadCountEpic$,
  markAsReadEpic$,
];

export default epics;
