import React, { FC } from 'react';
import { Search } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import { TimeScale } from '../../../../models';
import { scheduleActions, scheduleSelectors } from '../../../../redux';
import { scheduleUtils, translations } from '../../../../utils';

interface Props {
  selectedDate: Date;
  timeScale: TimeScale;
}

const SearchSchedule: FC<Props> = ({ timeScale, selectedDate }) => {
  const { startDate, endDate } = scheduleUtils.getBoundsOfRange(timeScale, selectedDate);
  const dispatch = useDispatch();
  const initialValue = useSelector(scheduleSelectors.getSearchValue);

  const onSubmit = (searchValue: string) => {
    if (searchValue) {
      dispatch(new scheduleActions.SearchDepartmentPlanningAction({ startDate: format(startDate, 'yyyy-MM-dd'), endDate: format(endDate, 'yyyy-MM-dd'), searchValue }));
    } else {
      dispatch(new scheduleActions.GetDepartmentPlanningAction({ startDate: format(startDate, 'yyyy-MM-dd'), endDate: format(endDate, 'yyyy-MM-dd') }));
    }
  };

  return <Search withBorder={false} placeholder={translations.getLabel('lblSearchEmployee')} submit={onSubmit} startValue={initialValue || ''} />;
};

export default SearchSchedule;
