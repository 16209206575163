import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { addDays, endOfMonth, endOfWeek, isSameMonth, startOfMonth, startOfWeek } from 'date-fns';

import { configSelectors } from '../../../../redux';

import './calendar.scss';

type Props = {
  selectedDate: Date;
  renderDay: (day: Date, isSameMonth: boolean) => JSX.Element;
}

const Calendar: FC<Props> = ({ selectedDate, renderDay }) => {
  // Put it in a more readable variable
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const activeMonth = selectedDate;
  const startOfTheSelectedMonth = startOfMonth(activeMonth);
  const endOfTheSelectedMonth = endOfMonth(activeMonth);
  const startDate = startOfWeek(startOfTheSelectedMonth, { weekStartsOn });
  const endDate = endOfWeek(endOfTheSelectedMonth, { weekStartsOn });

  let currentDate = startDate;

  const renderWeek = (currentWeek: Date, activeMonth: Date) => {
    let currentDate = currentWeek;
    const week = [];
    for (let day = 0; day < 7; day++) {
      const isDifferentMonth = !isSameMonth(currentDate, activeMonth);
      week.push(renderDay(currentDate, isDifferentMonth));
      currentDate = addDays(currentDate, 1);
    }
    return week;
  };

  const allWeeks = [];
  while (currentDate <= endDate) {
    allWeeks.push(
      renderWeek(currentDate, activeMonth)
    );
    currentDate = addDays(currentDate, 7);
  }

  return <div className="calendar-container">{allWeeks}</div>;
};

export default Calendar;

