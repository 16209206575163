export enum ActionType {
  GetTimeRegistrations = 'GET_TIME_REGISTRATIONS',
  GetTimeRegistrationsFulfilled = 'GET_TIME_REGISTRATIONS_FULFILLED',
  GetTimeRegistrationsRejected = 'GET_TIME_REGISTRATIONS_REJECTED',
  GetDoubleTimeRegistrations = 'GET_DOUBLE_TIME_REGISTRATIONS',
  GetDoubleTimeRegistrationsFulfilled = 'GET_DOUBLE_TIME_REGISTRATIONS_FULFILLED',
  GetDoubleTimeRegistrationsRejected = 'GET_DOUBLE_TIME_REGISTRATIONS_REJECTED',
  GetActiveTimeRegistrationsAndTypes = 'GET_ACTIVE_TIME_REGISTRATIONS_AND_TYPES',
  GetActiveTimeRegistrationsAndTypesFulfilled = 'GET_ACTIVE_TIME_REGISTRATIONS_AND_TYPES_FULFILLED',
  GetActiveTimeRegistrationsAndTypesRejected = 'GET_ACTIVE_TIME_REGISTRATIONS_AND_TYPES_REJECTED',
  GetTimeRegistrationClock = 'GET_TIME_REGISTRATION_CLOCK',
  GetTimeRegistrationClockFulfilled = 'GET_TIME_REGISTRATION_CLOCK_FULFILLED',
  GetTimeRegistrationClockRejected = 'GET_TIME_REGISTRATION_CLOCK_REJECTED',
  Clock = 'CLOCK',
  ClockFulfilled = 'CLOCK_FULFILLED',
  ClockRejected = 'CLOCK_REJECTED',
  CheckRegisteredDevice = 'CHECK_REGISTERED_DEVICE',
  CheckRegisteredDeviceFulfilled = 'CHECK_REGISTERED_DEVICE_FULFILLED',
  RegisteredDevice = 'REGISTERED_DEVICE',
  RegisteredDeviceFulfilled = 'REGISTERED_DEVICE_FULFILLED',
  RegisteredDeviceRejected = 'REGISTERED_DEVICE_REJECTED',
}

export default ActionType;
