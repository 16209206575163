import { routes } from '../constants';
import { INotificationPreference, NotificationTrigger, TimeScale } from '../models';
import { loginActions, store, userSelectors } from '../redux';
import * as date from './date';
import * as firebase from './firebase';
import { isMobileDevice } from './queryParser';
import translations from './translations';
export * from '../../../shared/utils/notificationUtils';

export const getRedirectUrl = (type: NotificationTrigger, currentPathName: string, identifier = ''): string => {
  let location = currentPathName;
  let routeName = null;
  let departmentId = null;
  let requestId = null;
  let scheduleDate = null;
  let employeeId = null;

  switch (type) {
    case NotificationTrigger.DocumentAdded:
      routeName = routes.library[0];
      break;

    case NotificationTrigger.UserScheduleUpdated:
      routeName = routes.schedule[0];
      break;

    case NotificationTrigger.VacationRequest:
      routeName = routes.scheduleRequests.inbox[0];
      break;

    case NotificationTrigger.VacationRequestDepartment:
      routeName = routes.scheduleRequests.managerInbox[0];
      // Specific case, we have a double identifier, so we return here already
      [departmentId, requestId] = identifier.split('/');
      return `${routeName}/${departmentId}/${requestId}`;

    case NotificationTrigger.ChatMessageReceived:
      routeName = routes.chat[0];
      break;

    case NotificationTrigger.NewsArticleAdded:
      routeName = routes.news;
      break;

    case NotificationTrigger.OpenShiftAdded:
      routeName = routes.scheduleAvailabilities;
      break;

    case NotificationTrigger.ShiftOfferNew:
    case NotificationTrigger.ShiftOfferCancelled:
    case NotificationTrigger.ShiftSwapCancelled:
    case NotificationTrigger.ShiftSwapRequested:
      return `${routes.schedule[0]}/${date.format(identifier, 'dd-MM-yyyy')}`;

    case NotificationTrigger.ShiftOfferFilled:
      [, scheduleDate] = identifier.split('/');
      return `${routes.schedule[0]}/${date.format(scheduleDate, 'dd-MM-yyyy')}`;

    case NotificationTrigger.ShiftOfferDepartment:
      routeName = routes.scheduleRequests.shiftOfferManagerInbox[0];
      // Specific case, we have a double identifier, so we return here already
      [departmentId, requestId] = identifier.split('/');
      return `${routeName}/${departmentId}/${requestId}`;

    case NotificationTrigger.ShiftOfferAcceptedByOtherManager:
      [employeeId, scheduleDate] = identifier.split('/');
      return `${routes.schedule[0]}/${date.format(scheduleDate, 'dd-MM-yyyy')}/${TimeScale.Day}#${employeeId}`;

    case NotificationTrigger.ShiftSwapReviewed:
    case NotificationTrigger.ShiftSwapChosen:
      [requestId, scheduleDate] = identifier.split('/');
      return `${routes.schedule[0]}/${date.format(scheduleDate, 'dd-MM-yyyy')}`;

    case NotificationTrigger.ShiftSwapDepartment:
      routeName = routes.scheduleRequests.shiftSwapManagerInbox[0];
      [departmentId, requestId] = identifier.split('/');
      return `${routeName}/${departmentId}/${requestId}`;

    case NotificationTrigger.TransactionToApprove:
      routeName = routes.scheduleRequests.transactionManagerInbox[0];
      [departmentId, requestId] = identifier.split('/');
      return `${routeName}/${departmentId}/${requestId}`;

    case NotificationTrigger.TransactionApproved:
    case NotificationTrigger.TransactionDenied:
      routeName = routes.scheduleRequests.transactionInbox[0];
      return `${routeName}/${identifier}`;

    case NotificationTrigger.ShiftTimeToApprove:
      routeName = routes.scheduleRequests.shiftTimeManagerInbox[0];
      [departmentId, requestId] = identifier.split('/');
      return `${routeName}/${departmentId}/${requestId}`;

    case NotificationTrigger.ShiftTimeReviewed:
      [requestId, scheduleDate] = identifier.split('/');
      return `${routes.schedule[0]}/${date.format(scheduleDate, 'dd-MM-yyyy')}`;

    case NotificationTrigger.SelfRosterNewIteration:
    case NotificationTrigger.SelfRosterIterationChanged:
    case NotificationTrigger.SelfRosterNextIteration:
      const [iterationId] = identifier.split('/');
      return `${routes.scheduleSelfRoster[0]}/${iterationId}`;
    default:
      break;
  }

  if (routeName) location = `${routeName}/${identifier}`;
  return location;
};

const getSplitPathname = (): string[] => {
  const currentPathname = window.location.pathname;
  return currentPathname.split('/');
};

export const removeIdFromUrl = (): void => {
  const splitPathName = getSplitPathname();
  if (splitPathName && splitPathName.length > 2) window.history.pushState({}, '', `/${splitPathName[1]}`);
};

export const changeIdInUrl = (id: string): void => {
  const splitPathName = getSplitPathname();
  if (splitPathName && splitPathName.length > 0) window.history.pushState({}, '', `/${splitPathName[1]}/${id}${window.location.hash}`);
};

export const goToNotification = async (employmentId: string, type: NotificationTrigger, identifier: string): Promise<void> => {
  const location = getRedirectUrl(type, window.location.pathname, identifier);
  if (location) {
    if (isMobileDevice()) {
      window.location.href = `ESS://notification/${type}/${identifier}`;
    } else {
      const state = store.getState();
      const user = userSelectors.getUser(state);
      if (employmentId && user.employmentId !== employmentId) {
        store.dispatch(new loginActions.UpdateEmploymentAction({
          id: employmentId,
          onSuccess: () => {
            window.location.pathname = location;
          },
        }));
      } else {
        window.location.pathname = location;
      }
    }
  }
};

export const checkBrowserNotifications = async (preferences: INotificationPreference[]): Promise<boolean> => {
  try {
    if (preferences.every(preference => !preference.browser)) {
      // Always unregister, just in case a token is hanging
      firebase.unRegister();
      return false;
    }
    if (!firebase.isSupported() || !Notification) throw new Error(translations.getLabel('lblNoDesktopNotificationsSupport'));
    if (Notification.permission !== 'granted') {
      await Notification.requestPermission();
    }
    if (Notification.permission === 'granted') {
      firebase.registerToken();
      return true;
    } else {
      throw new Error(translations.getLabel('lblNoDesktopNotificationsAllowed'));
    }
  } catch (err) {
    firebase.unRegister();
    throw err;
  }
};

export const shouldShowToaster = (type: NotificationTrigger, identifier: string): boolean => {
  // If we are in chat, and the identifier matches the selcted chat message, don't show toast
  if (type === NotificationTrigger.ChatMessageReceived && window.location.pathname.includes(identifier)) {
    return false;
  }
  return true;
};
