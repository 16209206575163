import { IEmployee } from './employee';
import { IChatUser } from './user';
import { IDepartment } from './departments';
import { IAttachment } from './document';

export interface IChatConversation {
  id: string;
  messages: IChatMessage[];
  participants: IEmployee[];
  read: boolean;
  roomId: string;
  title: string;
  type: ChatType;
}

export interface IChatMessage {
  attachment?: IAttachment;
  createdAt: string;
  firstName?: string;
  id: string;
  lastName?: string;
  message: string;
  senderId: string;
}

export interface IChatParticipantList {
  department: IDepartment;
  users: IChatUser[];
}

export enum ChatType {
  Single = 'SINGLE',
  Group = 'GROUP',
  Department = 'DEPARTMENT',
}