// NEVER CHANGE THE ORDER IN THE ARRAYS PLEASE
export const routes = {
  chat: ['/chat', '/chat/:id'],
  counters: '/counters',
  counterTabs: {
    groups: '/counters/groups',
    overviews: '/counters/overviews',
  },
  dashboard: '/dashboard',
  library: ['/library', '/library/:id'],
  news: '/news',
  upsertNewsItem: '/upsert-news-item/:id?',
  upsertNewsItemWithoutId: '/upsert-news-item',
  newsDetails: '/news/:newsId',
  notifications: '/notifications',
  profile: '/profile',
  schedule: ['/schedule', '/schedule/:id/:timescale?'],
  scheduleAvailabilities: '/schedule/availabilities',
  scheduleRequests: {
    landingPage: '/request-inbox',
    inbox: ['/request-inbox/vacation/overview', '/request-inbox/vacation/overview/:id'],
    transactionInbox: ['/request-inbox/transaction/overview', '/request-inbox/transaction/overview/:id'],
    managerInbox: ['/request-inbox/vacation/approvals', '/request-inbox/vacation/approvals/:departmentId/:id?'],
    shiftOfferManagerInbox: ['/request-inbox/shift-offer/approvals', '/request-inbox/shift-offer/approvals/:departmentId/:id?'],
    shiftSwapManagerInbox: ['/request-inbox/shift-swap/approvals', '/request-inbox/shift-swap/approvals/:departmentId/:id?'],
    transactionManagerInbox: ['/request-inbox/transaction/approvals', '/request-inbox/transaction/approvals/:departmentId/:id?'],
    shiftTimeManagerInbox: ['/request-inbox/shift-time/approvals', '/request-inbox/shift-time/approvals/:departmentId/:id?'],
  },
  scheduleSelfRoster: ['/schedule/self-roster', '/schedule/self-roster/:iterationId/:date?'],
  shiftSwap: '/shift-swap/:date/:id',
  shiftSwapCandidate: '/shift-swap-candidate/:date/:id',
  tasks: '/tasks',
  timeRegistration: '/time-registration',
  clockIn: '/clock-in',
  vacationRequest: '/vacation-request-detail',
  doubleUsage: '/doubleUsage'
};

export interface IRoute {
  route: string | string[];
}
