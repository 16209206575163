import React, { FC, useState } from 'react';
import { Icon } from 'react-ess-components';
import classnames from 'classnames';
import { parseISO } from 'date-fns';

import { ISelfRosteringShift, ISelfRosterVacationShift } from '../../../../models';
import { useSelfRosterContext } from '../../../../modules';
import { date, selfRosterUtils, translations } from '../../../../utils';
import OverstaffedWarning from '../overstaffed/OverstaffedWarning';
import OverStaffedShiftModal from '../OverstaffedShiftModal/OverstaffedShiftModal';

import './selfRosterShift.scss';

type Props = {
  shift?: ISelfRosteringShift;
  vacation?: ISelfRosterVacationShift;
  readOnly?: boolean;
  showDepartment?: boolean;
  disabled?: boolean;
  isLocked?: boolean;
}

const SelfRosterShift: FC<Props> = ({ shift, vacation, readOnly, showDepartment = false, disabled = false, isLocked = false }) => {
  const startDate = parseISO((shift || vacation).startDate);
  const endDate = parseISO((shift || vacation).endDate);

  const [showOverstaffedShiftModal, toggleOverstaffedShiftModal] = useState(false);

  const { selectShift, selectedShifts, selectedIteration } = useSelfRosterContext();
  const isSelected = selectedShifts.some(_shift => _shift.uuid === shift?.uuid);

  const { isRound2, isRound3 } = selfRosterUtils.getIterationChecks(selectedIteration);

  const { color, darkColor, gradientCss } = selfRosterUtils.getShiftColors(shift);

  const style = {
    backgroundColor: color,
    borderColor: 'transparent',
    backgroundImage: 'none',
  };

  if ((isSelected) || (shift?.isOverstaffed || disabled)) {
    style.borderColor = darkColor;
  }

  if (shift?.isOverstaffed) {
    style.backgroundColor = 'transparent';
    style.backgroundImage = gradientCss;
  }

  const renderContent = () => (
    <div className="shift-content">
      <div className="shift-header">
        <h5>{(shift || vacation).displayName}</h5>
        {isLocked && !isRound3 && <Icon tag="LockedIcon" customSize={1.5} color="gray2" />}
      </div>
      <p>{`${date.formatFromUntilTime(date.format, { startDate, endDate })}`}</p>
      {showDepartment && !!shift?.departmentName && <p className="department">{shift?.departmentName}</p>}
    </div>
  );

  if (vacation || readOnly || isLocked) {
    return (
      <>
        <div style={style} className={classnames('self-roster-shift readonly', { 'locked': isLocked })}>
          {renderContent()}
        </div>
        {shift?.isOverstaffed && <OverstaffedWarning disabled={disabled} />}
      </>
    );
  }

  const selectShiftForPlanning = (shift: ISelfRosteringShift) => {
    if (isRound2 && shift?.isOverstaffed) {
      toggleOverstaffedShiftModal(true);
    } else {
      selectShift(shift);
    }
  };

  const toggleOverStaffedShift = (shift: ISelfRosteringShift) => {
    selectShift(shift);
    toggleOverstaffedShiftModal(false);
  };

  return (
    <>
      <button
        onClick={() => selectShiftForPlanning(shift)}
        style={style}
        aria-label={translations.getLabel('lblAddSelfRosterShift')}
        className={classnames('self-roster-shift', { 'selected': isSelected })}
        disabled={disabled}>
        {renderContent()}
      </button>
      {shift?.isOverstaffed && <OverstaffedWarning disabled={disabled} />}
      <OverStaffedShiftModal
        open={showOverstaffedShiftModal}
        onClose={() => toggleOverstaffedShiftModal(false)}
        onSave={() => toggleOverStaffedShift(shift)}
      />
    </>
  );
};

export default SelfRosterShift;
