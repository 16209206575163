import { parseISO } from 'date-fns';
import { useQuery } from 'react-query';

import { IServerError } from '../../http/HttpError';
import { IDateRange, Period } from '../../models';
import { departmentsService } from '../../services';

const getAbsolutePeriodForDepartment = async (departmentId: string, periodType: Period): Promise<IDateRange> => {
  const periods = await departmentsService.getPeriods(departmentId);
  const period = periods.find(period => period?.[0]?.type === periodType);
  if (!period) return null;
  // Use start and enddate, and use the other one as fallback so both values are always filled in
  const absolutePeriod = await departmentsService.getAbsolutePeriod(period[0]?.startDate || period[0]?.endDate, period[0]?.endDate || period[0]?.startDate);
  return { startDate: parseISO(absolutePeriod.startDate), endDate: parseISO(absolutePeriod.endDate) };
};

export const useAbsolutePeriod = (departmentId: string, period: Period = Period.ScheduleVisibilityPeriod) => {
  return useQuery<IDateRange, IServerError>(['getAbsolutePeriod', departmentId, period], () => getAbsolutePeriodForDepartment(departmentId, period), { enabled: !!departmentId });
};
