import { Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { exhaustMap, map, catchError, tap } from 'rxjs/operators';

import ActionType from './types';
import { IServerError } from '../../http/HttpError';
import { documentsService } from '../../services';
import {
  GetDocumentsFulfilledAction,
  GetDocumentsRejectedAction,
  GetDocumentsAction,
  DeleteDocumentAction,
  DeleteDocumentActionFulfilled,
  DeleteDocumentActionRejected,
  UpdateDocumentAction,
  UpdateDocumentFulfilledAction,
  UpdateDocumentRejectedAction,
  UploadDocumentAction,
  UploadDocumentFulfilledAction,
  UploadDocumentRejectedAction
} from './actions';

export const deleteDocumentEpic$: Epic = action$ =>
  action$.ofType(ActionType.DeleteDocument).pipe(
    exhaustMap(({ payload }: DeleteDocumentAction) =>
      from(documentsService.deleteDocument(payload.id)).pipe(
        map(() => {
          payload.onSuccess?.();
          return new DeleteDocumentActionFulfilled();
        }
        ),
        catchError((error: IServerError) => {
          payload.onError?.(error);
          return of(new DeleteDocumentActionRejected());
        })
      )
    )
  );

export const deleteDocumentFulfilledEpic$: Epic = action$ =>
  action$.ofType(ActionType.DeleteDocumentFulfilled).pipe(
    exhaustMap(() => of(
      new GetDocumentsAction()
    ))
  );

export const getDocumentsEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetDocuments).pipe(
    exhaustMap(({ payload }: GetDocumentsAction) =>
      from(documentsService.getDocuments(payload ? payload.query : null)).pipe(
        map(data => new GetDocumentsFulfilledAction({ data })),
        catchError(() => of(new GetDocumentsRejectedAction()))
      )
    )
  );

export const updateDocumentEpic$: Epic = action$ =>
  action$.ofType(ActionType.UpdateDocument).pipe(
    exhaustMap(({ payload }: UpdateDocumentAction) =>
      from(documentsService.updateDocument(payload.id, payload.data, payload.fileUploadUtil)).pipe(
        map(() => {
          payload.onSuccess?.();
          return new UpdateDocumentFulfilledAction();
        }
        ),
        catchError((error: IServerError) => {
          payload.onError?.(error);
          return of(new UpdateDocumentRejectedAction());
        })
      )
    )
  );

export const updateDocumentFulfilledEpic$: Epic = action$ =>
  action$.ofType(ActionType.UpdateDocumentFulfilled).pipe(
    exhaustMap(() => of(
      new GetDocumentsAction()
    ))
  );


export const uploadDocumentEpic$: Epic = action$ =>
  action$.ofType(ActionType.UploadDocument).pipe(
    exhaustMap(({ payload }: UploadDocumentAction) =>
      from(documentsService.uploadDocument(payload.data, payload.fileUploadUtil
      )).pipe(
        map(() => {
          payload.onSuccess?.();
          return new UploadDocumentFulfilledAction();
        }),
        catchError((error: IServerError) => {
          payload.onError?.(error);
          return of(new UploadDocumentRejectedAction());
        })
      )
    ));

export const uploadDocumentEpicFulfilledEpic$: Epic = action$ =>
  action$.ofType(ActionType.UploadDocumentFulfilled).pipe(
    exhaustMap(() => of(
      new GetDocumentsAction()
    ))
  );

const epics = [
  deleteDocumentEpic$,
  deleteDocumentFulfilledEpic$,
  getDocumentsEpic$,
  updateDocumentEpic$,
  updateDocumentFulfilledEpic$,
  uploadDocumentEpic$,
  uploadDocumentEpicFulfilledEpic$,
];

export default epics;
