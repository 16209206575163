import { produce } from 'immer';

import ActionType from './types';
import { INewsItem, INewsItemAdmin } from '../../models';
import { Actions } from './actions';
import { HttpMetadataPagingResponse } from '../../http/HttpMetadata';

export interface NewsState {
  all: INewsItem[];
  details: INewsItem | INewsItemAdmin;
  isLoading: boolean;
  isSaving: boolean;
  isDeleting: boolean;
  metadata: HttpMetadataPagingResponse;
}
export const initialState: NewsState = {
  all: [],
  details: null,
  isLoading: false,
  isSaving: false,
  isDeleting: false,
  metadata: null,
};

const news = produce((draft: NewsState, action: Actions) => {
  switch (action.type) {
    case ActionType.GetNews:
    case ActionType.LoadMoreNews:
    case ActionType.GetNewsDetail:
    case ActionType.GetAdminNewsDetail:
      draft.details = null;
      draft.isLoading = true;
      break;

    case ActionType.GetNewsFulfilled:
      draft.isLoading = false;
      draft.all = action.payload.data;
      draft.metadata = action.payload.meta;
      break;

    case ActionType.GetNewsRejected:
    case ActionType.LoadMoreNewsRejected:
      draft.isLoading = false;
      draft.metadata = null;
      break;

    case ActionType.LoadMoreNewsFulfilled:
      draft.isLoading = false;
      draft.all = draft.all.concat(action.payload.data);
      draft.metadata = action.payload.meta;
      break;

    case ActionType.GetNewsDetailFulfilled:
    case ActionType.GetAdminNewsDetailFulfilled:
      draft.isLoading = false;
      draft.details = action.payload.data;
      break;

    case ActionType.GetNewsDetailRejected:
    case ActionType.GetAdminNewsDetailRejected:
      draft.isLoading = false;
      break;

    case ActionType.UpdateNewsItem:
      draft.isSaving = true;
      break;

    case ActionType.UpdateNewsItemFulfilled:
    case ActionType.UpdateNewsItemRejected:
      draft.isSaving = false;
      break;

    case ActionType.DeleteNewsItem:
      draft.isDeleting = true;
      break;

    case ActionType.DeleteNewsItemFulfilled:
      draft.isDeleting = false;
      draft.all = draft.all.filter(item => item.id !== action.payload.id);
      break;

    case ActionType.DeleteNewsItemRejected:
      draft.isDeleting = false;
      break;

    // No default case is needed, since produce returns by default the draft state
  }
}, initialState);

export default news;
