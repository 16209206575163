import React, { FC, useEffect } from 'react';
import { Icon } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { routes } from '../../../../constants';
import { RequestStatus } from '../../../../models';
import { requestsActions, requestsSelectors } from '../../../../redux';
import { date, requestsUtils, translations } from '../../../../utils';
import Footer from './RequestFooter';

interface Props extends RouteComponentProps {
  id: string;
  isLoading: boolean;
  status: RequestStatus;
  departmentId?: string;
}

const RequestDetail: FC<Props> = ({ id, isLoading, departmentId, history }) => {
  const dispatch = useDispatch();

  const request = useSelector((state: AppState) => requestsSelectors.getDepartmentShiftTime(state, id));

  useEffect(() => {
    if (id) {
      dispatch(new requestsActions.GetDepartmentShiftTimeAction({
        id,
        departmentId,
        onError: (error) => {
          toast.error(error.detail);
          history.replace(routes.scheduleRequests.shiftTimeManagerInbox[0]);
        },
      }));
    }
  }, [id]);

  if (!request) return null;

  const relativeCreatedDate = requestsUtils.getRelativeDate(request?.createdAt, date.format);

  return (
    <div className="request-detail department-request-detail">
      <div>
        <p>{isLoading ? <Skeleton width={300} /> : (relativeCreatedDate.shouldTranslate ? `${translations.getLabel(relativeCreatedDate.value)} ${relativeCreatedDate.time}` : `${relativeCreatedDate.value}`)}</p>
        <p className="title">{isLoading ? <Skeleton width={400} /> : translations.getLabel('lblAdjustedTime')}</p>
        <p className="sub-title">{isLoading ? <Skeleton width={200} /> : translations.getLabel('lblBy')} {request?.requesterName}</p>
        <div className="request-message-container">
          <p className="shift-name">{request?.shift?.shiftName}</p>
          <p className="shift-numbers"><Icon tag="CalendarIcon" color="primaryDark" />{date.formatMultipleDay(date.format, { startDate: parseISO(request?.shift.startDate), endDate: parseISO(request?.shift.endDate) })}</p>
          <div className="shift-container">
            <div className="shift-container-item">
              <p className="shift-label">{translations.getLabel('lblPlannedShift')}</p>
              <p className="shift-value"><Icon tag="ClockIcon" color="primaryDark" customSize={1.8} />{format(parseISO(request?.shift?.startDate), 'HH:mm')} - {format(parseISO(request?.shift?.endDate), 'HH:mm')}</p>
            </div>
            <div className="shift-container-item">
              <p className="shift-label">{translations.getLabel('lblNewAdjustedShift')}</p>
              <p className="shift-value-bold"><Icon tag="ClockIcon" color="primaryDark" customSize={1.8} />{format(parseISO(request?.startDate), 'HH:mm')} - {format(parseISO(request?.endDate), 'HH:mm')}</p>
            </div>
          </div>
          <div className="info-shift">
            <div>
              {request?.shift?.intervals && <div className="row">
                <div className="title-content">{translations.getLabel('lblTasks')}</div>
                <div className="content">{request?.shift.intervals.map(i =>
                  <div key={i.startDate + i.id + i.endDate}>
                    <p className="content-item">{date.formatFromUntilTime(date.format, { startDate: parseISO(i.startDate), endDate: parseISO(i.endDate) })}&nbsp;{i.timeType}</p>
                  </div>
                )}
                </div>
              </div>}
              {request?.shift?.department && <div className="row">
                <div className="title-content">{translations.getLabel('lblDepartment')}</div>
                <div className="content">{request?.shift?.department}</div>
              </div>}
              {request?.shift?.functionName && <div className="row">
                <div className="title-content">{translations.getLabel('lblFunction')}</div>
                <div className="content">{request?.shift?.functionName}</div>
              </div>}
            </div>
          </div>
          <div className="data-field">
            <p className="shift-numbers"><Icon tag="ChatIcon" color="primaryDark" /> {translations.getLabel('lblReason')}:</p>
            <p> {request?.reason ? request?.reason : translations.getLabel('lblNoRequestReason')}</p>
          </div>
        </div>
      </div>
      <div>
        <div className="hr" />
      </div>
      {request &&
        <div className="footer">
          <Footer id={id} departmentId={departmentId} />
        </div>
      }
    </div >
  );
};

export default withRouter(RequestDetail);
