import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { date } from '../../utils';
import { scheduleActions } from '../../redux';

class ScheduleToday extends PureComponent {
  constructor(props) {
    super(props);

    this.SKELETON_INTERVALS = [1, 2, 3]; // Dummy data. We show 3 skeleton rows for intervals
  }

  componentDidMount() {
    this.props.dispatch(new scheduleActions.GetPlanningTodayAction());
  }

  formatTime = (format, startDate, endDate) => {
    return date.formatFromUntilTime(format, { startDate: date.parseDate(startDate), endDate: date.parseDate(endDate) });
  }

  getColor = (shift) => {
    return (shift.intervals && shift.intervals.length) ? shift.intervals[0].color : undefined;
  }

  render() {
    return null;
  }
}

ScheduleToday.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  schedule: PropTypes.array,
  user: PropTypes.object.isRequired,
  hasPlanningTodayError: PropTypes.bool,
};

ScheduleToday.defaultProps = {
  isLoading: false,
  schedule: [],
  hasPlanningTodayError: false,
};

export default ScheduleToday;
