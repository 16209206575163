import React from 'react';
import { Checkbox, IconButton, Popup, Position } from 'react-ess-components';

import { useToggle } from '../../../../hooks';
import { translations } from '../../../../utils';

type Props = {
  isAvailabilityShown: boolean;
  toggleIsAvailabilityShown: () => void;
  isRequestsShown: boolean;
  toggleIsRequestsShown: () => void;
}

export const ScheduleFilter = ({ isAvailabilityShown, toggleIsAvailabilityShown, isRequestsShown, toggleIsRequestsShown }: Props) => {
  const [isPopupOpen, toggleIsPopupOpen] = useToggle(false);
  return (
    <div className="schedule-filter-container">
    <IconButton tag="FilterIcon" title={translations.getLabel('btnScheduleFilters')} color="primary" className="filter-button" onClick={toggleIsPopupOpen} />

    <Popup isDropdown position={Position.TopCenter} open={isPopupOpen} requestClose={toggleIsPopupOpen}>
      <ul className="conditional-popup">
        <li><Checkbox id="availability" label={translations.getLabel('btnShowAvailability')} isChecked={isAvailabilityShown} onCheck={toggleIsAvailabilityShown} /></li>
        <li><Checkbox id="requests" label={translations.getLabel('btnShowRequests')} isChecked={isRequestsShown} onCheck={toggleIsRequestsShown} /></li>
      </ul>
    </Popup>
    </div>
  );
};
