import { HttpClient } from '../http';
import { IDateStringRange, IReducedShift, IShift, UpdateApplicantStatus } from '../models';
import { IDayShiftSwap } from '../models/shiftSwap';

export const createAdjustedShiftTimeRequest = async (shift: IReducedShift, startDate: string, endDate: string, reason: string): Promise<void> => {
    return await HttpClient.post<void>('/v1/shift-time-adjustments', { shift, startDate, endDate, reason });
};

export const cancelAdjustedShiftTimeRequest = async (id: string): Promise<void> => {
    const result = await HttpClient.put<void>(`/v1/shift-time-adjustments/${id}/cancel`);
    return result;
};



