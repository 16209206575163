export enum Tools {
  Availabilities = 'availabilities',
  Vacation = 'vacation',
  Switch = 'switch',
  Periods = 'periods',
  ShiftOffers = 'shiftOffer',
  Schedule = 'Schedule',
  ShiftTimeAdjustment = 'ShiftTimeAdjustment',
  SelfRoster = 'SelfRoster'
};
