import { IModules } from './config';
import { WeekDay } from './date';

export interface IPayPeriod {
  type: PeriodType;
  start: string;
}
export interface ICompanyConfig {
  enableCrossDepartmentsShiftOffers?: boolean;
  enableSpxcVacationRequests?: boolean;
  modules: IModules;
  startOfWeek: WeekDay;
  payPeriod: IPayPeriod;
  scheduleVisibleUntil: string;
  enableShiftTimeAdjustments?: boolean;
}

export interface ICompanyPreferences {
  companyId: string;
  headerColor: string;
  id: string;
  logoUrl: string;
  navbarIconColor: string;
  navbarTextColor: string;
  navbarTextHoverColor: string;
  phoneHeaderImageUrl: string;
  phoneIconsColor: string;
  phonePageTitleColor: string;
  primaryColor: string;
  webBackgroundUrl: string;
  webStartpageImageUrl: string;
}

export enum PeriodType {
  CalendarMonth = 'CALENDAR_MONTH',
  FourWeekRange = 'FOUR_WEEK_RANGE',
}
