import { Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

import ActionType from './types';
import { shiftOfferService } from '../../services';
import { CreateTemplateAction, CreateTemplateFulfilledAction, CreateTemplateRejectedAction, CreateShiftOfferAction, CreateShiftOfferFulfilledAction, CreateShiftOfferRejectedAction, GetTemplatesAction, GetTemplatesFulfilledAction, GetTemplatesRejectedAction, GetShiftOffersAction, GetShiftOffersFulfilledAction, GetShiftOffersRejectedAction, UpdateShiftOfferApplicantStatusAction, UpdateShiftOfferApplicantStatusFulfilledAction, UpdateShiftOfferApplicantStatusRejectedAction, GetScheduleDetailAction, GetScheduleDetailFulfilledAction, GetScheduleDetailRejectedAction } from './actions';
import { parseISO, format } from 'date-fns';

export const createTemplateEpic$: Epic = action$ =>
  action$.ofType(ActionType.CreateTemplate).pipe(
    switchMap(({ payload }: CreateTemplateAction) =>
      from(shiftOfferService.createTemplate(payload.name, payload.shifts, payload.departmentId)).pipe(
        map(() => {
          payload.onSuccess?.();
          return new CreateTemplateFulfilledAction();
        }),
        catchError(error => {
          payload.onError?.(error);
          return of(new CreateTemplateRejectedAction({ error }));
        })
      )
    ),
  );

export const getTemplatesEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetTemplates).pipe(
    switchMap(({ payload }: GetTemplatesAction) =>
      from(shiftOfferService.getTemplates(payload.departmentId)).pipe(
        map((data) => new GetTemplatesFulfilledAction({ data })),
        catchError(error => of(new GetTemplatesRejectedAction({ error })))
      )
    ),
  );

export const getShiftOffersEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetShiftOffers).pipe(
    switchMap(({ payload }: GetShiftOffersAction) =>
      from(shiftOfferService.getShiftOffers({ startDate: payload.startDate, endDate: payload.endDate, departmentId: payload.departmentId })).pipe(
        map((data) => new GetShiftOffersFulfilledAction({ data, startDate: payload.startDate, endDate: payload.endDate })),
        catchError(error => of(new GetShiftOffersRejectedAction({ error })))
      )
    ),
  );

export const createShiftOfferEpic$: Epic = action$ =>
  action$.ofType(ActionType.CreateShiftOffer).pipe(
    switchMap(({ payload }: CreateShiftOfferAction) =>
      from(shiftOfferService.createShiftOffer(payload.departmentId, payload.shiftOffer)).pipe(
        map(() => {
          payload.onSuccess?.();
          return new CreateShiftOfferFulfilledAction({ startDate: payload.shiftOffer.date, departmentId: payload.departmentId });
        }),
        catchError(error => {
          payload.onError?.(error);
          return of(new CreateShiftOfferRejectedAction({ error }));
        })
      )
    ),
  );

export const createShiftOfferSuccessEpic$: Epic = action$ =>
  action$.ofType(ActionType.CreateShiftOfferFulfilled).pipe(
    map(({ payload }: CreateShiftOfferFulfilledAction) => {
      const date = parseISO(payload.startDate);
      return new GetShiftOffersAction({ startDate: format(date, 'yyyy-MM-dd'), endDate: format(date, 'yyyy-MM-dd') });
    }),
  );

export const updateShiftOfferApplicantStatus$: Epic = action$ =>
  action$.ofType(ActionType.UpdateShiftOfferApplicantStatus).pipe(
    switchMap(({ payload }: UpdateShiftOfferApplicantStatusAction) =>
      from(shiftOfferService.updateShiftOfferApplicantStatus(payload.id, payload.type)).pipe(
        map(() => new UpdateShiftOfferApplicantStatusFulfilledAction({ id: payload.id, type: payload.type, date: payload.date })),
        catchError(error => {
          payload?.onError?.(error);
          return of(new UpdateShiftOfferApplicantStatusRejectedAction({ error }));
        })
      )
    ),
  );

export const getScheduleDetailEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetScheduleDetail).pipe(
    switchMap(({ payload }: GetScheduleDetailAction) =>
      from(shiftOfferService.getScheduleDetail(payload.date, payload.employeeId)).pipe(
        map((data) => new GetScheduleDetailFulfilledAction({ data, date: payload.date })),
        catchError(error => of(new GetScheduleDetailRejectedAction({ error })))
      )
    ),
  );


const epics = [
  createTemplateEpic$,
  getTemplatesEpic$,
  createShiftOfferEpic$,
  createShiftOfferSuccessEpic$,
  getScheduleDetailEpic$,
  getShiftOffersEpic$,
  updateShiftOfferApplicantStatus$,
];

export default epics;
