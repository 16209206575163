import React, { BaseSyntheticEvent, FC, useEffect, useRef, useState } from 'react';
import { IconButton, Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import classnames from 'classnames';

import { documentsService } from '../../../../../shared/services';
import { IMAGES } from '../../../assets';
import { RenderIfRight } from '../../../components';
import { useToggle } from '../../../hooks';
import { DocumentExtensions, DocumentType, IDocument, UserRight } from '../../../models';
import { documentsActions } from '../../../redux';
import { date, libraryUtils, translations } from '../../../utils';

interface Props {
  selectedId?: string;
  document?: IDocument;
  isLoading?: boolean;
  toggleEditForm?: (document: IDocument) => void;
}

const getIcon = (name: string, type: DocumentType) => {
  if (type === DocumentType.Link) return 'Web';
  const extension = libraryUtils.checkExtension(name);
  if (!extension) return 'Unknown';
  if (DocumentExtensions.Pdf.includes(extension)) return 'Pdf';
  if (DocumentExtensions.Doc.includes(extension)) return 'Doc';
  return 'Unknown';
};

const DOCUMENT_OFFSET_TOP = 100;

const LibraryItem: FC<Props> = ({ document, selectedId, toggleEditForm, isLoading }) => {
  const dispatch = useDispatch();
  const [showRemoveModal, toggleShowRemoveModal] = useToggle(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedId === document?.id) {
      window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop - DOCUMENT_OFFSET_TOP });
    }
  }, [selectedId]);

  const openDocument = async () => {
    const downloadUrl = await documentsService.getDocumentDownloadUrl(document.id);
    downloadUrl ? window.open(downloadUrl) : toast.error(translations.getLabel('lblErrorFileOpen'));
  };

  const deleteDocument = () => {
    setIsDeleting(true);
    dispatch(new documentsActions.DeleteDocumentAction({
      id: document.id,
      onSuccess: () => {
        toggleShowRemoveModal();
        toast(translations.getLabel('lblFileDeleteSuccess'));
      },
      onError: (error) => {
        toggleShowRemoveModal();
        toast.error(error.title || translations.getLabel('lblGenericError'));
      },
    }));
  };


  const rowClassnames = classnames(['row', 'item'],
    { 'selected': document?.id === selectedId }
  );

  const toggleRemove = (event: BaseSyntheticEvent) => {
    event.stopPropagation();
    toggleShowRemoveModal();
  };

  const toggleEdit = (event: BaseSyntheticEvent) => {
    event.stopPropagation();
    toggleEditForm(document);
  };

  return (
    <>
      <div className={rowClassnames} ref={ref}>
        <div className="col">
          {isLoading
            ? <Skeleton height={48} width={40} />
            : <img src={IMAGES[getIcon(document.originalName, document.type)]} alt={document.type.toLowerCase() + ' icon'} className="document-icon" />
          }
          <p className="document-name">{isLoading ? <Skeleton width={150} /> : document.name}</p>
        </div>
        <div className="col">
          <p>{isLoading ? <Skeleton width={100} /> : `${document.firstName} ${document.lastName}`}</p>
        </div>
        <div className="col">
          <p>{isLoading ? <Skeleton width={135} /> : date.format(document.updatedAt, 'd MMMM yyyy HH:mm')}</p>
        </div>
        <div className="col">
          {!isLoading && <>
            <RenderIfRight right={UserRight.Documents}>
              <IconButton tag="TrashIcon" color="error" onClick={toggleRemove} title={translations.getLabel('lblDelete')} />
              <IconButton tag="EditIcon" onClick={toggleEdit} title={translations.getLabel('btnEdit')} />
            </RenderIfRight>
            <IconButton tag="DownloadIcon" onClick={openDocument} title={translations.getLabel('lblDownload')} />
          </>}
        </div>
      </div>
      {showRemoveModal && <Modal
        title={translations.getLabel('lblDeleteDocument')}
        requestClose={toggleShowRemoveModal}
        leftButtonProps={{
          label: translations.getLabel('cancel'),
          onClick: toggleShowRemoveModal,
        }}
        rightButtonProps={{
          label: translations.getLabel('lblDelete'),
          onClick: deleteDocument,
          isLoading: isDeleting,
        }}
        open={showRemoveModal}>
        <p className="remove-document-message">{translations.getLabel('lblConfirmDeleteDocument')}</p>
      </Modal>}
    </>
  );
};

LibraryItem.defaultProps = {
  selectedId: '0',
};

export default LibraryItem;
