import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

type UnregisterCallback = () => void;

export const useBrowserPrompt = (when: boolean, onTogglePrompt = (show: boolean) => { return; }, onOk = () => true, onCancel = () => true) => {
  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');
  const unblockRef = useRef<UnregisterCallback>();

  useEffect(() => {
    unblockRef.current = history.block((prompt) => {
      if (when) {
        setCurrentPath(prompt.pathname);
        onTogglePrompt(true);
        setShowPrompt(true);
        return 'true';
      } else {
        return;
      }
    });

    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [history, when]);

  const handleOk = useCallback(async () => {
    if (onOk) {
      const canRoute = await Promise.resolve(onOk());
      if (canRoute) {
        if (unblockRef) {
          unblockRef.current();
        }
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
    onTogglePrompt(false);
  }, [currentPath, history, onOk]);

  const handleCancel = useCallback(async () => {
    if (unblockRef) {
      unblockRef.current();
    }
    setShowPrompt(false);
    onTogglePrompt(false);
  }, [currentPath, history, onCancel]);

  return {
    showPrompt,
    handleOk,
    handleCancel,
  };
};
