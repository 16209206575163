import React, { FC, useEffect, useState } from 'react';
import { Button, Icon, IconButton, InputField, InputType, Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { EditorState } from 'draft-js';

import { SelectLanguage, WisiwygEditor } from '../../../components';
import { useToggle } from '../../../hooks';
import { Form, Locale } from '../../../models';
import { configSelectors } from '../../../redux';
import { translations } from '../../../utils';

interface Props {
  form: Form;
  setForm: (form: Form) => void;
  isLoading: boolean;
  isError: boolean;
  initialLanguages: string[];
}

const NewsForm: FC<Props> = ({ isError, form, setForm, isLoading, initialLanguages }) => {
  const defaultLanguage = useSelector(configSelectors.defaultLanguage);
  const [shouldShowRemoveLanguageWarning, toggleShouldShowRemoveLanguageWarning] = useToggle(false);
  const availableLanguages = useSelector(configSelectors.languages);
  const [languageToRemove, setLanguageToRemove] = useState('');
  const [languages, setLanguages] = useState(initialLanguages || []);
  const [isLanguageSelectModalVisible, toggleIsLanguageSelectModalVisible] = useToggle(false);
  const [languageSelected, setLanguageSelected] = useState(defaultLanguage.value);

  useEffect(() => {
    setLanguages(initialLanguages);
  }, [initialLanguages]);

  const isCurrentTitleError = () => isError && !getActiveContent().title;

  const isCurrentMessageError = () => isError && !getActiveContent().message.getCurrentContent().hasText();

  const onChangeProperty = (value: string, key: string) => {
    setForm({
      ...form,
      [languageSelected]: {
        ...form[languageSelected],
        [key]: value,
      },
    });
  };

  const removeLanguage = (language: Locale) => () => {
    toggleShouldShowRemoveLanguageWarning();
    setLanguageToRemove(language);
  };

  const isSpecificLanguageError = (lang: string) => isError && form[lang] && (!form[lang].title || !form[lang].message.getCurrentContent().hasText());

  const removeAttachment = (attachment: File) => () => {
    const filteredAttachments = getActiveContent().attachments.filter(att => att.name !== attachment.name);

    setForm({
      ...form,
      [languageSelected]: {
        ...form[languageSelected],
        attachments: filteredAttachments,
      },
    }
    );
  };

  const getActiveContent = () => form[languageSelected];

  const onRemoveLanguageConfirm = () => {
    const filteredLanguages = languages.filter(lang => lang !== languageToRemove);
    setLanguages(filteredLanguages);
    setLanguageSelected(defaultLanguage.value);

    const newLanguages = { ...form };
    delete newLanguages[languageToRemove];

    setForm({
      ...newLanguages,
    });

    toggleShouldShowRemoveLanguageWarning();
  };

  const selectLanguage = language => () => {
    if (!form[language]) {
      setForm({
        ...form,
        [language]: {
          title: '',
          message: EditorState.createEmpty(),
          attachments: [],
        },
      });

      setLanguageSelected(language);
    } else {
      setLanguageSelected(language);
    }
  };

  const handleAddAttachment = file => {
    setForm({
      ...form,
      [languageSelected]: {
        ...form[languageSelected],
        attachments: [...form[languageSelected].attachments, file],
      },
    });
  };

  const onLanguageSelect = (newLanguages: string[]) => {
    setLanguages(newLanguages);
    let newForm = { ...form };

    newLanguages.forEach(lang => {
      newForm = {
        ...newForm,
        [lang]: {
          title: '',
          message: EditorState.createEmpty(),
          attachments: [],
        },
      };
    });

    setForm({
      ...newForm,
    });
    toggleIsLanguageSelectModalVisible();
  };

  // classNames
  const classnamesDefaultLanguage = classnames('stripped-button default-language-wrapper',
    {
      'language-selected': languageSelected === defaultLanguage.value,
      'language-unselected': languageSelected !== defaultLanguage.value,
      'language-error': isSpecificLanguageError(defaultLanguage.value),
    });
  const classnamesTitleContainer = classnames('title-message-container', { 'news-item-error-container': isCurrentTitleError() });
  const classnamesMessageContainer = classnames('message-container', { 'news-item-error-container': isCurrentMessageError() });

  // renders
  const renderAttachments = attachments =>
    <div className="attachments-container">
      <p className="attachments-label">{`${translations.getLabel('lblAttachments')}:`}</p>
      {attachments.map(renderAttachment)}
    </div>;

  const renderSelectedLanguages = () => languages.map(renderLanguage);

  const renderLanguage = language => {
    const classnamesLanguage = classnames('language-item-container',
      {
        'language-selected': languageSelected === language,
        'language-unselected': languageSelected !== language,
        'language-error': isSpecificLanguageError(language),
      });
    const lang = availableLanguages.find(lang => lang.value === language);
    if (!lang) return null;
    return (<button className={classnamesLanguage} key={language} onClick={selectLanguage(language)}>
      <p className="language">{translations.getLabel(lang.label)}</p>
      <IconButton tag="CrossIcon" color="error" small onClick={removeLanguage(language)} title={translations.getLabel('lblDelete')} />
    </button>);
  };

  const renderAttachment = attachment => {
    return <div className="attachment-item-container" key={`${languageSelected}-${attachment.name}`}>
      <p className="attachment">{attachment.name}</p>
      <IconButton tag="CrossIcon" color="error" small onClick={removeAttachment(attachment)} title={translations.getLabel('lblDelete')} />
    </div>;
  };

  return (
    <div>
      <div className="language-select-container">
        <div className="selected-languages">
          <button className={classnamesDefaultLanguage} onClick={selectLanguage(defaultLanguage.value)}>
            <p className="language">{translations.getLabel(defaultLanguage.label)}</p>&nbsp;<span className="label-default">{`(${translations.getLabel('lblDefault')})`}</span>
          </button>
          {renderSelectedLanguages()}
        </div>
        {availableLanguages?.length > 1 &&
          <Button onClick={toggleIsLanguageSelectModalVisible} theme="transparent" className="stripped-button">
            <Icon tag="PlusSquareIcon" /> {translations.getLabel('lblAddLanguage')}
          </Button>
        }
      </div>
      <div lang={languageSelected} className={classnamesTitleContainer}>
        <h4>{translations.getLabel('lblTitleNewNewsItem')}</h4>
        {isCurrentTitleError() && <p className="news-item-error">{translations.getLabel('lblNewsItemTitleObligated')}</p>}
        {isLoading ? <Skeleton height="3.5rem" /> : <InputField type={InputType.Text} placeholder={translations.getLabel('lblTitleNewNewsItem')} onChange={onChangeProperty} name="title" value={getActiveContent().title} />}
      </div>

      <div lang={languageSelected} className={classnamesMessageContainer}>
        <h4>{translations.getLabel('lblMessage')}</h4>
        {isCurrentMessageError() && <p className="news-item-error">{translations.getLabel('lblNewsItemMessageNotEmpty')}</p>}
        {getActiveContent().attachments.length > 0 &&
          renderAttachments(getActiveContent().attachments)}
        {isLoading ? <Skeleton height="11rem" /> : <WisiwygEditor value={getActiveContent().message} handleUpload={handleAddAttachment} onMessageChange={(value: string) => onChangeProperty(value, 'message')} />}
      </div>

      <div className="select-department-modal">
        <SelectLanguage selected={languages} isOpen={isLanguageSelectModalVisible} requestClose={toggleIsLanguageSelectModalVisible} onSave={onLanguageSelect} defaultLanguage={defaultLanguage} />
      </div>

      <Modal
        open={shouldShowRemoveLanguageWarning}
        requestClose={toggleShouldShowRemoveLanguageWarning}
        title={translations.getLabel('lblRemoveNewsItem')}
        leftButtonProps={{
          onClick: toggleShouldShowRemoveLanguageWarning,
          label: translations.getLabel('cancel'),
        }}
        rightButtonProps={{
          onClick: onRemoveLanguageConfirm,
          label: translations.getLabel('lblRemoveNewsItem'),
        }}
      >
        <p className="modal-content">{translations.getLabel('lblWarningRemoveLanguage')}</p>
      </Modal>
    </div >
  );
};

export default NewsForm;
