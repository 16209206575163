import React from 'react';
import { Icon } from 'react-ess-components';
import PropTypes from 'prop-types';

import { date, translations } from '../../../../utils';

import './AvailabilityDetail.scss';

class AvailabilityDetail extends React.PureComponent {
  renderAvailability = (availability, i) => {
    const type = this.props.timeTypes.find((type) => type.code === availability.code);
    if (!type) return null; // In this case, this is a hidden timetype and shouldn't be shown
    return (
      <div className="availability-detail" key={`availability-${i}`}>
        <h4>{type.description}</h4>
        <p><Icon customSize={1.8} tag="ClockIcon" />{date.format(availability.startDate, 'dd MMM yyyy')}</p>
        <p><Icon customSize={1.8} tag="CalendarIcon" />{`${translations.getLabel('lblFrom')} ${translations.getLabel('lblStartUntilEnd', { startDate: date.format(availability.startDate, 'H:mm'), endDate: date.format(availability.endDate, 'H:mm') })}`}</p>
      </div>
    );
  }

  render() {
    return (
      <div className="availability-detail-wrapper">
        {
          this.props.availabilities.map(this.renderAvailability)
        }
      </div >
    );
  }
}
AvailabilityDetail.propTypes = {
  availabilities: PropTypes.array.isRequired,
  timeTypes: PropTypes.array.isRequired,
};

export default AvailabilityDetail;
