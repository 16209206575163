export interface ICalenderLinks {
  google: string;
  iCal: string;
  outlook: string;
}

export interface IModules {
  chat: boolean;
  counters: boolean;
  library: boolean;
  news: boolean;
  schedule: boolean;
  timeRegistration: boolean;
  shiftOffer: boolean;
  shiftSwap: boolean;
  selfRoster: boolean;
}

export interface ITermsAndConditions {
  downloadUrl: string;
  fileName: string;
  size: string;
}

export interface ILabelAndValue<T = null> {
  label: string;
  value: string;
  data?: T;
}

export enum Module {
  Chat = 'chat',
  Counters = 'counters',
  Library = 'library',
  News = 'news',
  Schedule = 'schedule',
  TimeRegistration = 'timeRegistration',
  ShiftOffer = 'shiftOffer',
  ShiftSwap = 'shiftSwap',
}