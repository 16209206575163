import React, { FC, useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { Button, Spinner } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { BackButton, NewsList, RenderIfRight, Widget } from '../../components';
import { routes } from '../../constants';
import { useInfiniteScroll } from '../../hooks';
import { UserRight } from '../../models';
import { newsActions, newsSelectors } from '../../redux';
import { translations } from '../../utils';

import './news.scss';

const News: FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(newsSelectors.isLoading);
  const newsItems = useSelector(newsSelectors.getAllNews);
  const metadata = useSelector(newsSelectors.metadata);

  useInfiniteScroll((offset: number) => dispatch(new newsActions.LoadMoreNewsAction({ offset })), metadata, isLoading);

  useEffect(() => {
    dispatch(new newsActions.GetNewsAction());
  }, []);

  const goToCreateNewsitem = () => history.push(routes.upsertNewsItemWithoutId);

  const isInitialLoading = isLoading && newsItems?.length === 0;

  return (
    <DocumentTitle title={translations.getLabel('titleNewsWidget')}>
      <div className="page news-page fixed-width">
        <div className="back-button-container">
          <BackButton to="/" label={translations.getLabel('btnReturnDashboard')} />
          <RenderIfRight right={UserRight.News}>
            <Button onClick={goToCreateNewsitem}>{translations.getLabel('lblCreateNewNewsItem')}</Button>
          </RenderIfRight>
        </div>
        <Widget title={translations.getLabel('titleNewsWidget')} left>
          <>
            <NewsList isLoading={isInitialLoading} newsItems={newsItems} />
            {isLoading && !isInitialLoading && <div className="loading-container"> <Spinner solid /></div>}
          </>
        </Widget>
      </div>
    </DocumentTitle>
  );
};

export default News;
