import { Action } from 'redux';
import { format } from 'date-fns';

import { ActionType } from './types';
import { IDayPlanning, IExternalVacationRequest, ISearchPlanning } from '../../models';
import { IServerError } from '../../http/HttpError';

function formatDate(date: Date = new Date()): string {
  return format(date, 'yyyy-MM-dd');
}

export class GetDepartmentPlanningAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentPlanning;
  constructor(public payload?: { startDate: string, endDate: string, departmentId?: string }) { }
}

export class GetDepartmentPlanningFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentPlanningFulfilled;
  constructor(public payload: { data: ISearchPlanning[], departmentId?: string }) { }
}

export class GetDepartmentPlanningRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentPlanningRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetShiftOfferApplicantsPlanningAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftOfferApplicantsPlanning;
  constructor(public payload?: { startDate: string, endDate: string, departmentId: string, shiftOfferId: string }) { }
}

export class GetShiftOfferApplicantsPlanningFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftOfferApplicantsPlanningFulfilled;
  constructor(public payload: { data: ISearchPlanning[], departmentId: string, shiftOfferId: string }) { }
}

export class GetShiftOfferApplicantsPlanningRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftOfferApplicantsPlanningRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetPlanningAction implements Action<ActionType> {
  readonly type = ActionType.GetPlanning;
  constructor(public payload?: { startDate: string, endDate: string }) {
    this.payload = payload || { startDate: formatDate(), endDate: formatDate() };
  }
}

export class GetPlanningFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetPlanningFulfilled;
  constructor(public payload: { data: IDayPlanning[] }) { }
}

export class GetPlanningRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetPlanningRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetPlanningTodayAction implements Action<ActionType> {
  readonly type = ActionType.GetPlanningToday;
  constructor(public payload = { startDate: formatDate(), endDate: formatDate() }) { }
}

export class GetPlanningTodayFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetPlanningTodayFulfilled;
  constructor(public payload: { data: IDayPlanning[] }) { }
}

export class GetPlanningTodayRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetPlanningTodayRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetUserPlanningAction implements Action<ActionType> {
  readonly type = ActionType.GetUserPlanning;
  constructor(public payload: { employmentId: string, startDate?: string, endDate?: string, onError?: () => void, onSuccess?: () => void }) {
    this.payload = {
      ...payload,
      startDate: payload.startDate || formatDate(),
      endDate: payload.endDate || formatDate()
    };
  }
}

export class GetUserPlanningFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetUserPlanningFulfilled;
  constructor(public payload: { data: ISearchPlanning }) { }
}

export class GetUserPlanningRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetUserPlanningRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetUserPlanningExternalVacationAction implements Action<ActionType> {
  readonly type = ActionType.GetUserPlanningExternalVacation;
  constructor(public payload: { startDate?: string, endDate?: string, onError?: () => void, onSuccess?: () => void }) { }
}

export class GetUserPlanningExternalVacationFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetUserPlanningExternalVacationFulfilled;
  constructor(public payload: { data: IExternalVacationRequest[], startDate: string, endDate: string; }) { }
}

export class GetUserPlanningExternalVacationRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetUserPlanningExternalVacationRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class DeleteUserPlanningExternalVacationAction implements Action<ActionType> {
  readonly type = ActionType.DeleteUserPlanningExternalVacation;
  constructor(public payload: { id: string, startDate: string, onError?: (error: IServerError) => void, onSuccess?: () => void }) { }
}

export class DeleteUserPlanningExternalVacationFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.DeleteUserPlanningExternalVacationFulfilled;
  constructor(public payload: { id: string, date: string }) { }
}

export class DeleteUserPlanningExternalVacationRejectedAction implements Action<ActionType> {
  readonly type = ActionType.DeleteUserPlanningExternalVacationRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class SearchDepartmentPlanningAction implements Action<ActionType> {
  readonly type = ActionType.SearchDepartmentPlanning;
  constructor(public payload: { searchValue: string, startDate?: string, endDate?: string }) {
    this.payload = {
      ...payload,
      startDate: payload.startDate || formatDate(),
      endDate: payload.endDate || formatDate()
    };
  }
}

export class SearchDepartmentPlanningFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.SearchDepartmentPlanningFulfilled;
  constructor(public payload: { data: ISearchPlanning[] }) { }
}

export class SearchDepartmentPlanningRejectedAction implements Action<ActionType> {
  readonly type = ActionType.SearchDepartmentPlanningRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetShiftSwapApplicantsPlanningAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftSwapApplicantsPlanning;
  constructor(public payload?: { shiftSwapId: string }) { }
}

export class GetShiftSwapApplicantsPlanningFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftSwapApplicantsPlanningFulfilled;
  constructor(public payload: { data: ISearchPlanning[], shiftSwapId: string }) { }
}

export class GetShiftSwapApplicantsPlanningRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftSwapApplicantsPlanningRejected;
  constructor(public payload: { error: IServerError }) { }
}

export type Actions =
  | DeleteUserPlanningExternalVacationAction
  | DeleteUserPlanningExternalVacationFulfilledAction
  | DeleteUserPlanningExternalVacationRejectedAction
  | GetDepartmentPlanningAction
  | GetDepartmentPlanningFulfilledAction
  | GetDepartmentPlanningRejectedAction
  | GetPlanningAction
  | GetPlanningFulfilledAction
  | GetPlanningRejectedAction
  | GetPlanningTodayAction
  | GetPlanningTodayFulfilledAction
  | GetPlanningTodayRejectedAction
  | GetShiftOfferApplicantsPlanningAction
  | GetShiftOfferApplicantsPlanningFulfilledAction
  | GetShiftOfferApplicantsPlanningRejectedAction
  | GetShiftSwapApplicantsPlanningAction
  | GetShiftSwapApplicantsPlanningFulfilledAction
  | GetShiftSwapApplicantsPlanningRejectedAction
  | GetUserPlanningAction
  | GetUserPlanningFulfilledAction
  | GetUserPlanningRejectedAction
  | GetUserPlanningExternalVacationAction
  | GetUserPlanningExternalVacationFulfilledAction
  | GetUserPlanningExternalVacationRejectedAction
  | SearchDepartmentPlanningAction
  | SearchDepartmentPlanningFulfilledAction
  | SearchDepartmentPlanningRejectedAction;
