import { eachDayOfInterval } from 'date-fns';
import { createSelector } from 'reselect';
import { IDayShiftSwap } from '../../models';
import { scheduleUtils } from '../../utils';
import { AppState } from '../rootReducer';
import { ShiftSwapState } from './reducer';

const selectNode = (state: AppState) => state.shiftSwap;

export const showShiftSwapModal = createSelector(
  selectNode,
  (state: ShiftSwapState): boolean => state.showShiftSwapModal,
);

export const getShifts = createSelector(
  selectNode,
  (state: ShiftSwapState) => state.shifts,
);

export const isCreateRequestLoading = createSelector(
  selectNode,
  (state: ShiftSwapState) => state.isCreateRequestLoading,
);

export const isShiftSwapsLoading = createSelector(
  selectNode,
  (state: ShiftSwapState) => state.isShiftSwapsLoading,
);

export const isUpdateApplicantStatusLoading = createSelector(
  selectNode,
  (state: ShiftSwapState) => state.isUpdateApplicantStatusLoading,
);

export const isRequestLoading = createSelector(
  selectNode,
  (state: ShiftSwapState) => state.isRequestLoading,
);

export const getShiftSwaps = (state: AppState, start: Date, end: Date): IDayShiftSwap[][] => {
  const daysBetween = eachDayOfInterval({ start, end });
  const shiftSwaps = [];
  daysBetween.forEach(day => {
    const foundShiftSwaps = state.shiftSwap.shiftSwaps?.[scheduleUtils.formatDateForSchedule(day)];
    if (foundShiftSwaps) shiftSwaps.push(foundShiftSwaps);
    else shiftSwaps.push([]);
  });
  return shiftSwaps;
};

export const getShiftSwap = (state: AppState, id: string, date: string): IDayShiftSwap => {
  return state.shiftSwap.shiftSwaps?.[scheduleUtils.formatDateForSchedule(date)]?.find((shiftSwap: IDayShiftSwap) => shiftSwap.id === id);
};