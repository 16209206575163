import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { newsActions } from '../../redux';

class NewsWidget extends PureComponent {
  componentDidMount() {
    this.props.dispatch(new newsActions.GetNewsAction());
  }

  render() {
    return null;
  }
}

NewsWidget.propTypes = {
  dispatch: PropTypes.func.isRequired,
  newsItems: PropTypes.array,
  isLoading: PropTypes.bool,
};

NewsWidget.defaultProps = {
  newsItems: [],
  isLoading: false,
};

export default NewsWidget;
