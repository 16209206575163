import { createSelector } from 'reselect';
import { AppState } from '../rootReducer';
import { TransactionState } from './reducer';

const selectNode = (state: AppState) => state.transactions;

export const isLoading = createSelector(
  selectNode,
  (state: TransactionState) => state.isLoadingTypes,
);

export const getTypes = createSelector(
  selectNode,
  (state: TransactionState) => state.types,
);

export const getValue = createSelector(
  selectNode,
  (state: TransactionState) => state.selectedType,
);

export const isLoadingCreateTransaction = createSelector(
  selectNode,
  (state: TransactionState) => state.isLoadingCreateTransaction,
);
