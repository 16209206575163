import React, { FC, useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { InputField, InputType, Modal } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { AppState } from '../../../../shared/redux/rootReducer';
import { AccountDetails, ContactDetails, NotificationPreferences, SynchronizeCalendar, TimeRegistrationRegister } from '../../components';
import { UserRight } from '../../models';
import { configSelectors, departmentsActions, departmentsSelectors, userActions, userSelectors } from '../../redux';
import { translations } from '../../utils';

import './Profile.scss';

const Profile: FC = () => {
  const user = useSelector(userSelectors.getUser);
  const isAvatarLoading = useSelector(userSelectors.isUpdateAvatarLoading);
  const changePasswordError = useSelector(userSelectors.changePasswordError);
  const companyConfig = useSelector(configSelectors.getCompanyConfig);
  const dispatch = useDispatch();
  const [currentPassword, setCurrentPassword] = useState('');
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const departments = useSelector((state: AppState) => departmentsSelectors.getDepartmentsForRight(state, UserRight.TimeRegistration));

  useEffect(() => {
    dispatch(new departmentsActions.GetDepartmentsAction({ type: UserRight.TimeRegistration }));
  }, []);

  const handleChangeCurrentPassword = (password) => {
    setCurrentPassword(password);
  };

  const handleChangeNewPassword = (password) => {
    setNewPassword(password);
    setPasswordError('');
  };

  const handleChangeRepeatPassword = (password) => {
    setRepeatPassword(password);
    setPasswordError('');
  };

  const handleDelete = async () => {
    dispatch(new userActions.DeleteAvatarAction({
      onSuccess: () => {
        setIsDeleteModalVisible(false);
      },
    }));
  };

  const onSuccessPasswordChange = () => {
    toast(translations.getLabel('lblChangesSavedSuccess'));
    togglePasswordModal();
  };

  const handleUpdatePassword = async () => {
    if (newPassword !== repeatPassword) {
      setPasswordError(translations.getLabel('lblErrorNewPasswordsSame'));
    }
    dispatch(new userActions.ChangePasswordAction({ currentPassword, newPassword, onSuccess: onSuccessPasswordChange }));
  };

  const handleUpload = (file) => {
    dispatch(new userActions.UpdateAvatarAction({ file }));
  };

  const renderChangePasswordModal = () => {
    const errorMessage = passwordError || changePasswordError?.detail;
    const isSaveEnabled = !!currentPassword && !!newPassword && !!repeatPassword;
    return (
      <Modal
        open={isPasswordModalVisible}
        requestClose={togglePasswordModal}
        title={translations.getLabel('titleChangePassword')}
        rightButtonProps={{ label: translations.getLabel('save'), onClick: handleUpdatePassword, disabled: !isSaveEnabled }}
        leftButtonProps={{ label: translations.getLabel('cancel'), onClick: togglePasswordModal }}
      >
        <div className="modal-content-container">
          <p className="conditions-label">{translations.getLabel('lblPasswordConditions')}</p>
          <InputField
            type={InputType.Password}
            error={!!changePasswordError?.detail}
            className="input-field-container"
            onChange={handleChangeCurrentPassword}
            value={currentPassword}
            emptyIcon
            name={translations.getLabel('lblEnterCurrentPassword')}
            label={translations.getLabel('lblEnterCurrentPassword')}
          />
          <InputField type={InputType.Password} error={!!passwordError || !!errorMessage} className="input-field-container" onChange={handleChangeNewPassword} value={newPassword} emptyIcon name={translations.getLabel('lblEnterNewPassword')} label={translations.getLabel('lblEnterNewPassword')} />
          <InputField type={InputType.Password} error={!!passwordError || !!errorMessage} onChange={handleChangeRepeatPassword} value={repeatPassword} emptyIcon name={translations.getLabel('lblRepeatNewPassword')} label={translations.getLabel('lblRepeatNewPassword')} />
          {!!errorMessage && <div className="conditions-warning"><p>{errorMessage}</p></div>}
        </div>
      </Modal>
    );
  };

  const renderDeleteModal = () => {
    return (
      <Modal
        requestClose={() => setIsDeleteModalVisible(false)}
        open={isDeleteModalVisible}
        rightButtonProps={{ label: translations.getLabel('lblDelete'), onClick: handleDelete, isLoading: isAvatarLoading }}
        leftButtonProps={{ label: translations.getLabel('cancel'), onClick: () => setIsDeleteModalVisible(false) }}
        title={translations.getLabel('titleDeleteAvatar')}
      >
        <div className="delete-modal-content-container">
          <p className="confirmation-message">{translations.getLabel('lblDeleteAvatar')}</p>
        </div>
      </Modal>
    );
  };

  const togglePasswordModal = () => {
    if (isPasswordModalVisible) {
      setIsPasswordModalVisible(!isPasswordModalVisible);
      setCurrentPassword('');
      setNewPassword('');
      setRepeatPassword('');
      setPasswordError('');
      dispatch(new userActions.ClearPasswordError());
    } else {
      setIsPasswordModalVisible(!isPasswordModalVisible);
    }
  };

  const modules = companyConfig?.modules;

  return (
    <DocumentTitle title={translations.getLabel('titleProfile')}>
      <div className="profile-container page fixed-width">
        <h2>{translations.getLabel('titleProfile')}</h2>
        <div className="category-container">
          <AccountDetails handleDelete={() => setIsDeleteModalVisible(true)} handleChangePasswordClick={togglePasswordModal} handleUpload={handleUpload} />
          <ContactDetails />
        </div>
        <div className="category-container">
          <NotificationPreferences />
          {modules.schedule && <SynchronizeCalendar />}
        </div>
        <div className="category-container">
          {(user?.permissions?.includes(UserRight.TimeRegistration) && departments.find((department) => department.value === user.departmentId)) && <TimeRegistrationRegister />}
          <div className="empty-placeholder widget-container right" />
        </div>
        {renderChangePasswordModal()}
        {renderDeleteModal()}
      </div>
    </DocumentTitle>
  );
};

export default Profile;
