import { HttpClient } from '../http';
import { IDateStringRange, UpdateApplicantStatus } from '../models';
import { IDayShiftSwap } from '../models/shiftSwap';

export const createShiftSwapRequest = async (date: string, hasCounterSwap: boolean): Promise<void> => {
  return await HttpClient.post<void>('/v1/shift-swaps', { date, hasCounterSwap });
};

export const getShiftSwaps = async (startDate: string, endDate: string): Promise<IDayShiftSwap[]> => {
  const result = await HttpClient.get<{ data: IDayShiftSwap[] }>('/v1/shift-swaps', { startDate, endDate });
  return result.data;
};

export const cancelShiftSwapRequest = async (id: string): Promise<void> => {
  const result = await HttpClient.put<void>(`/v1/shift-swaps/${id}/cancel`);
  return result;
};

export const requestShiftSwap = async (id: string, employmentId: string, selectedCounterSwapDate: string): Promise<void> => {
  const result = await HttpClient.put<void>(`/v1/shift-swaps/${id}`, { employmentId, selectedCounterSwapDate });
  return result;
};

export const updateShiftSwapApplicantStatus = async (id: string, type: UpdateApplicantStatus): Promise<void> => {
  const result = await HttpClient.post<void>(`/v1/shift-swaps/${id}?type=${type}`);
  return result;
};

export const applyToShiftSwapWithCounter = async (id: string, shifts: IDateStringRange[]): Promise<void> => {
  const result = await HttpClient.post<void>(`/v1/shift-swaps/${id}?type=${UpdateApplicantStatus.Apply}`, { counterSwapDates: shifts });
  return result;
};

