import { parseISO, format, isToday, isTomorrow, isYesterday, isValid, isSameDay } from 'date-fns';
import { IRelativeFormattedDate } from '../models';
import { date } from '../utils';

/**
 * Interfaces
 **/
export interface FormatFunction {
  (dateObj: Date, formatStr: string): string;
}

/** ---- **/


export const formatFromUntilTime = (formatFunction: FormatFunction, data?: { startDate?: Date, endDate?: Date }): string => {
  if (!data || !data.startDate || !data.endDate || !isValid(data.startDate) || !isValid(data.endDate)) {
    return '';
  }
  return `${formatFunction(data.startDate, 'HH:mm')}-${formatFunction(data.endDate, 'HH:mm')}`;
};

// Convert string to date object // Needs to be typed once we know how the date circulates in the app.
export const parseDate = (isoString): Date => {
  if (!isoString) return null;
  let parsedDate = isoString;
  if (typeof isoString === 'string') parsedDate = parseISO(isoString);
  return parsedDate;
};

// remove the timezone // Needs to be typed once we know how the date circulates in the app.
export const removeTimezone = (isoString) => {
  return format(parseDate(isoString), 'yyyy-MM-dd\'T\'HH:mm:ss');
};

export const toRelativeDate = (formatFunction: FormatFunction, date: Date, customFormat = 'EEEE d MMMM'): IRelativeFormattedDate => {
  if (!date || !isValid(date)) return { shouldTranslate: false, value: '', time: '' };
  if (isToday(date)) return { shouldTranslate: true, value: 'lblToday', time: formatFunction(date, 'HH:mm') };
  if (isTomorrow(date)) return { shouldTranslate: true, value: 'lblTomorrow', time: formatFunction(date, 'HH:mm') };
  if (isYesterday(date)) return { shouldTranslate: true, value: 'lblYesterday', time: formatFunction(date, 'HH:mm') };
  return { shouldTranslate: false, value: formatFunction(date, customFormat), time: formatFunction(date, 'HH:mm') };
};

export const formatMultipleDay = (formatFunction: FormatFunction, data?: { startDate: Date, endDate: Date }) => {
  if (isSameDay(data.startDate, data.endDate)) {
    return formatFunction(data.startDate, 'eee dd LLL');
  } else {
    return formatFunction(data.startDate, 'eee dd LLL') + ' - ' + formatFunction(data.endDate, 'eee dd LLL');
  }
};
