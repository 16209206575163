import React, { FC } from 'react';
import { IconButton } from 'react-ess-components';

import { translations } from '../../../../utils';

interface Props {
  title: string;
  isCollapsed: boolean;
  toggleIsCollapsed: () => void;
}

const RosterColumn: FC<Props> = ({ title, toggleIsCollapsed, isCollapsed }) => {
  return (
    <aside className="roster-col schedule-col user-col">
      <div className="schedule-user-search" />
      <div className="roster-details schedule-user-details">
        <p className="title">{translations.getLabel(title)}</p>
        <IconButton onClick={toggleIsCollapsed} tag={isCollapsed ? 'ChevronDownIcon' : 'ChevronUpIcon'} title={translations.getLabel(isCollapsed ? 'lblCollapsed' : 'lblExpanded')} />
      </div>
    </aside>
  );
};

export default RosterColumn;
