import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Immutable, { Map } from 'immutable';
import { parseISO, subWeeks, isAfter, isSameDay, differenceInCalendarWeeks, startOfWeek, endOfWeek, setHours, getTime, startOfHour, differenceInDays, addDays } from 'date-fns';

import { availabilitiesUtils, date, requestsUtils } from '../../utils';
import { defaultDayStartHour, defaultDayEndHour } from '../../constants';
import { userActions, availabilitiesActions } from '../../redux';

class Availabilities extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      availabilities: Immutable.fromJS(props.availabilities),
      defaultTimeType: '',
      hasChanged: false,
      timeErrors: [],
      isLoading: true,
      isSaving: false,
      overlappingDates: [],
      selectedDate: props.startDate,
      showCancelModal: false,
      validAvailabilityDates: []
    };
  }

  async componentDidMount() {
    this.props.dispatch(new userActions.GetUserPeriodsAction());
    // get one week before the range to enable the copying of the first week
    this.props.dispatch(new availabilitiesActions.GetAvailabilitiesAction({
      startDate: startOfWeek(subWeeks(date.parseDate(this.props.startDate), 1), { weekStartsOn: this.props.weekStartsOn }),
      endDate: endOfWeek(date.parseDate(this.props.endDate), { weekStartsOn: this.props.weekStartsOn }),
      onSuccess: (availabilities) => {
        const validAvailabilityDates = this.filterValidAvailabilityDates(availabilities);

        this.setState({
          availabilities: Immutable.fromJS(availabilities),
          defaultTimeType: availabilities.timeTypes?.[0]?.code || '',
          isLoading: false,
          validAvailabilityDates,
          selectedDate: validAvailabilityDates[0] ? parseISO(validAvailabilityDates[0]) : this.state.selectedDate,
        });
      },
      onError: () => {
        this.setState({
          isLoading: false,
        });
      }
    }));
  }

  filterValidAvailabilityDates = (availabilities) => {
    // 1. Subtract the extra week
    const filteredAvailabilities = availabilities.filter(availability => isAfter(date.parseDate(availability.day), date.parseDate(this.props.startDate)) || isSameDay(date.parseDate(this.props.startDate), date.parseDate(availability.day)));

    // 2. Filter the resulting date array for validity, and return the valid date
    const validAvailabilityDates = filteredAvailabilities.filter(availability => requestsUtils.isValidRequest({ requestPeriod: this.props.availabilityPeriod, forDate: date.parseDate(availability.day) })).map(availability => availability.day);
    return validAvailabilityDates;
  }

  setDefaultTimeType = code => this.setState({ defaultTimeType: code })

  hasTimeErrors = () => this.state.timeErrors.length > 0;
  setHasTimeError = (hasError, id) => {
    if (hasError) {
      // If not yet available, add it to the list with errors
      if (!this.state.timeErrors.includes(id)) this.setState(({ timeErrors }) => ({ timeErrors: [...timeErrors, id] }));;
    } else {
      // if no error, remove it from the list
      this.setState(({ timeErrors }) => ({ timeErrors: timeErrors.filter(errorId => errorId !== id) }));
    }
  }

  onChangeShift = (newShift) => {
    const index = this.state.availabilities.findIndex(availability => isSameDay(date.parseDate(availability.get('day')), date.parseDate(newShift.startDate)));
    const availabilities = this.state.availabilities.update(index, day => {
      const indexOfAvailability = day.get('availabilities').findIndex(availability => availability.get('id') === newShift.id);
      const newTimeType = newShift.code;
      if (newShift.code !== this.state.defaultTimeType) {
        this.setDefaultTimeType(newTimeType);
      }
      newShift.timeTypeId = this.props.timeTypes.find(type => type.code === newShift.code)?.id;
      return day.setIn(['availabilities', indexOfAvailability], new Map(newShift));
    });
    this.setState({ hasChanged: true, availabilities });
    if (this.props.navigation) this.props.navigation.setParams({ isReadyForSave: true });
  }

  onCopyWeek = (fromDate, toDate) => {
    let availabilities = this.state.availabilities;

    for (let i = 0; i < 7; i += 1) {
      availabilities = this.copyDay(addDays(date.parseDate(fromDate), i), addDays(date.parseDate(toDate), i), availabilities);
    }
    this.setState({ hasChanged: true, availabilities, openPopup: null });
    if (this.props.navigation) this.props.navigation.setParams({ isReadyForSave: true });
  }

  onCopyDay = (fromDate, toDate) => {
    const availabilities = this.copyDay(date.parseDate(fromDate), date.parseDate(toDate));
    this.setState({ hasChanged: true, availabilities });
    if (this.props.navigation) this.props.navigation.setParams({ isReadyForSave: true });
  }

  copyDay = (fromDate, toDate, availabilities = this.state.availabilities) => {
    // if the toDate lies outside of the valid period range, skip the copying
    if (!this.state.validAvailabilityDates.find(availability => isSameDay(date.parseDate(availability), toDate))) return availabilities;

    // Find the from-day (the day we need to copy)
    const fromDay = availabilities.find(availability => isSameDay(date.parseDate(fromDate), date.parseDate(availability.get('day'))));
    // Find the index of the to-day
    const toIndex = availabilities.findIndex(availability => isSameDay(date.parseDate(toDate), date.parseDate(availability.get('day'))));
    // Calculate how much we need to add or subtract to the date
    const dayDifference = differenceInDays(date.parseDate(toDate), date.parseDate(fromDate));
    // Update the availabilities of the to-day (also change the start and enddate)
    return availabilities.update(toIndex, toDay => toDay.set('availabilities', fromDay.get('availabilities').map(availability => {
      return availability.merge({
        startDate: addDays(date.parseDate(availability.get('startDate')), dayDifference),
        endDate: addDays(date.parseDate(availability.get('endDate')), dayDifference),
      });
    })));
  }

  onRemoveShift = (id, dayDate) => {
    const index = this.state.availabilities.findIndex(availability => isSameDay(date.parseDate(availability.get('day')), date.parseDate(dayDate)));
    const availabilities = this.state.availabilities.update(index, day => {
      const indexOfAvailability = day.get('availabilities').findIndex(availability => availability.get('id') === id);
      return day.deleteIn(['availabilities', indexOfAvailability]);
    });
    this.setState({ hasChanged: true, availabilities });
    if (this.props.navigation) this.props.navigation.setParams({ isReadyForSave: true });
  }

  onSave = async () => {
    this.setState({
      isSaving: true,
    });
    if (this.props.navigation) this.props.navigation.setParams({ isSaving: true });



    const JSAvailabilities = this.state.availabilities.toJS().reduce((accu, availability) => {
      const newShifts = availability.availabilities.reduce((shiftAccu, shift) => {
        return [
          ...shiftAccu,
          {
            ...shift,
            startDate: date.removeTimezone(shift.startDate),
            endDate: date.removeTimezone(shift.endDate),
          }
        ];
      }, []);

      return [...accu, { ...availability, availabilities: newShifts }];
    }, []);

    this.props.dispatch(new availabilitiesActions.UpdateAvailabilitiesAction({
      availabilities: JSAvailabilities,
      onSuccess: () => {
        this.props.dispatch(new availabilitiesActions.GetAvailabilitiesAction({ startDate: this.props.startDate, endDate: this.props.endDate }));
        this.onSuccess();
        this.setState({
          isSaving: false,
          error: false,
        });
      },
      onError: (error) => {
        if (this.props.navigation) this.props.navigation.setParams({ isSaving: false });
        const overlappingDatesResult = error.detail;

        return this.setState({
          overlappingDates: Array.isArray(overlappingDatesResult) ? overlappingDatesResult : [],
          error: error.title,
          isSaving: false,
        });
      }
    }));
  }

  // @override function
  onSuccess = () => { }

  onAddShift = (dayDateStr) => {
    const dayDate = date.parseDate(dayDateStr);
    const index = this.state.availabilities.findIndex(availability => isSameDay(date.parseDate(availability.get('day')), dayDate));
    let id = '';
    // Filter timetypes to take into account the inSchoolDate
    const timeTypes = availabilitiesUtils.getVisibleTimeTypes(this.props.timeTypes, this.props.inSchoolUntil, dayDateStr);
    const defaultTimeType = timeTypes.find(type => type.code === this.state.defaultTimeType);
    const availabilities = this.state.availabilities.update(index, day => {
      const newShift = {
        startDate: startOfHour(setHours(dayDate, defaultDayStartHour)).toISOString(),
        endDate: startOfHour(setHours(dayDate, defaultDayEndHour)).toISOString(),
        code: defaultTimeType?.code || timeTypes[0].code,
        timeTypeId: defaultTimeType?.id || timeTypes[0].id,
      };
      return day.update('availabilities', availabilities => {
        id = `${availabilities.size}-${getTime(dayDate)}-${getTime(new Date())}`;
        return availabilities.push(new Map({ ...newShift, id }));
      });
    });
    this.setState({ hasChanged: true, availabilities });
    if (this.props.navigation) this.props.navigation.setParams({ isReadyForSave: true });
    return id;
  }

  renderWeeks = () => {
    const weeks = differenceInCalendarWeeks(date.parseDate(this.props.endDate), date.parseDate(this.props.startDate), { weekStartsOn: this.props.weekStartsOn });
    const beginOfWeek = startOfWeek(date.parseDate(this.props.startDate), { weekStartsOn: this.props.weekStartsOn });
    const weekComponents = [];
    for (let i = 0; i <= weeks; i += 1) {
      weekComponents.push(this.renderWeek(beginOfWeek, i));
    }
    return weekComponents;
  }

  // @override function
  renderWeek = (beginOfWeek, index) => { }

  render() {
    return null;
  }
}

Availabilities.propTypes = {
  availabilities: PropTypes.array.isRequired,
  availabilityPeriod: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  history: PropTypes.object,
  navigation: PropTypes.object,
  startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  timeTypes: PropTypes.array.isRequired,
  weekStartsOn: PropTypes.number.isRequired,
  inSchoolUntil: PropTypes.string,
};


Availabilities.defaultProps = {
  history: {},
  navigation: null,
  inSchoolUntil: null,
};

export default Availabilities;
