import React, { FC, useEffect, useState } from 'react';
import { Button, Icon, Modal, Orb, Popup, Position } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isValid, parseISO } from 'date-fns';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { SchedulePageSwitch, Shift } from '../../../../components';
import { useToggle } from '../../../../hooks';
import { IApplicant, RequestStatus, ShiftOfferApplicantStatus, TimeScale } from '../../../../models';
import { configSelectors, requestsActions, requestsSelectors, scheduleActions } from '../../../../redux';
import { date, requestsUtils, scheduleUtils, translations } from '../../../../utils';
import { format } from '../../../../utils/date';
import ShiftOfferSchedule from '../../../schedule/ShiftOfferSchedule';
import Footer from './RequestFooter';

interface Props {
  id: string;
  isLoading: boolean;
  status: RequestStatus;
  departmentId?: string;
}

const RequestDetail: FC<Props> = ({ id, isLoading, departmentId, status }) => {
  const dispatch = useDispatch();
  const [isRosterOpen, toggleRosterOpen] = useToggle(false);
  const [isConfirmationModalOpen, toggleConfirmationModal] = useToggle(false);
  const [isCloseConfirmationModalOpen, toggleCloseConfirmationModal] = useToggle(false);
  const [isCancelModalOpen, toggleCancelModal] = useToggle(false);
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const [selectedDate, setSelectedDate] = useState<Date>(null);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const isShiftOfferUpdateLoading = useSelector(requestsSelectors.isShiftOfferUpdateLoading);
  const request = useSelector((state: AppState) => requestsSelectors.getDepartmentShiftOffer(state, id));
  const relativeCreatedDate = requestsUtils.getRelativeDate(request?.createdAt, date.format);
  const ruleViolated = useSelector(requestsSelectors.getRuleViolated);

  useEffect(() => {
    if (id) {
      dispatch(new requestsActions.GetDepartmentShiftOfferAction({
        id,
        departmentId,
        onError: (error) => {
          toast.error(error.detail);
        },
      }));
    }
    setSelectedDate(null);
    setSelectedUsers([]);
  }, [id]);

  useEffect(() => {
    if (request) {
      const formattedStartDate = format(request.date, 'yyyy-MM-dd');
      dispatch(new scheduleActions.GetShiftOfferApplicantsPlanningAction({ startDate: formattedStartDate, endDate: formattedStartDate, departmentId, shiftOfferId: request.id }));
    }
  }, [request]);

  useEffect(() => {
    if (!isRosterOpen) setSelectedUsers([]);
  }, [isRosterOpen]);

  const approveShiftOffer = () => {
    dispatch(new requestsActions.FillInShiftOfferAction({
      departmentId,
      shiftOfferId: id,
      employeeIds: selectedUsers,
      status,
      onSuccess: (data) => {
        toggleRosterOpen();
        toggleConfirmationModal();
        if (data.ruleViolated.length) toast.error(translations.getLabel('lblRuleViolated'));
      },
      onError: (error) => {
        toggleConfirmationModal();
        toast.error(typeof error.detail === 'string' && error.detail ? error.detail : translations.getLabel('lblGenericError'));
      },
    }));
  };

  const closeShiftOffer = () => {
    dispatch(new requestsActions.FillInShiftOfferAction({
      departmentId,
      shiftOfferId: id,
      employeeIds: acceptedApplicants.map(a => a.employmentId),
      status,
      closeShiftOffer: true,
      onSuccess: () => {
        toggleCloseConfirmationModal();
      },
      onError: (error) => {
        toggleCloseConfirmationModal();
        toast.error(typeof error.detail === 'string' && error.detail ? error.detail : translations.getLabel('lblGenericError'));
      },
    }));
  };

  const cancelShiftOffer = () => {
    dispatch(new requestsActions.CancelShiftOfferAction({
      shiftOfferId: id,
      departmentId,
      status,
      onSuccess: () => toggleCancelModal(),
      onError: (error) => {
        toast.error(typeof error.detail === 'string' && error.detail ? error.detail : translations.getLabel('lblGenericError'));
        toggleCancelModal();
      },
    }));
  };

  const onSelectUser = (employeeId: string) => {
    if (request.numberOfPeople === 1) {
      setSelectedUsers([employeeId]);
    } else {
      if (selectedUsers.includes(employeeId)) setSelectedUsers(selectedUsers.filter(id => id !== employeeId));
      else setSelectedUsers([...selectedUsers, employeeId]);
    }
  };

  if (!request && !isLoading) return <section className="request-detail empty">{translations.getLabel('lblEmpyRequestInbox')}</section>;

  const requestDate = parseISO(request?.date);
  const requestSelectedDate = selectedDate || requestDate;
  // Set selected date on request date when it is not yet initialized. Otherwise we will rerender the schedule every time
  if (!selectedDate && isValid(requestDate)) setSelectedDate(requestDate);

  const renderApplicant = (applicant: IApplicant) => {
    const status = ruleViolated?.find(r => r === applicant.employmentId) ? ShiftOfferApplicantStatus.RuleViolated : applicant.status;

    return (
      <li key={applicant.userId}>
        <div className="candidate">
          <span>{applicant.name}</span>
          <div className="applicant-status">
            <Orb color={requestsUtils.getShiftOfferApplicantStatusColor(status)} filled small />
            <p>{` ${requestsUtils.getApplicantStatus(status, translations.getLabel)}`}</p>
          </div>
        </div>
      </li>
    );
  };

  const acceptedApplicants = request?.applicants?.filter(a => a.status === ShiftOfferApplicantStatus.Accepted);

  return (
    <div className="request-detail department-request-detail">
      <div>
        <div className="button-header">
          <div className="title">
            <p>{isLoading ? <Skeleton width={300} /> : (relativeCreatedDate.shouldTranslate ? `${translations.getLabel(relativeCreatedDate.value)} ${relativeCreatedDate.time}` : `${relativeCreatedDate.value}`)}</p>
            <p className="title">{isLoading ? <Skeleton width={400} /> : translations.getLabel('titleShiftOffer')}</p>
          </div>
          {!!acceptedApplicants?.length && <Button theme="inverse" onClick={toggleCloseConfirmationModal}><span>{translations.getLabel('lblCloseShiftOffer')}</span></Button>}
        </div>
        <div className="request-message-container">
          {request && <>
            <p className="shift-numbers"><Icon tag="UsersIcon" color="primaryDark" /> {translations.getLabel('lblNumberOfPeopleNeeded', { amount: request?.numberOfPeople })}</p>
            <p className="shift-numbers"><Icon tag="CandidatsIcon" color="primaryDark" /> {translations.getLabel('lblShiftOfferNumberOfApplicants', { amount: `${request?.applicants?.filter(a => a.status === ShiftOfferApplicantStatus.Accepted)?.length || 0}/${request?.numberOfApplicants}` })}:</p>
            <ul className="candidates">
              {request?.applicants?.map(renderApplicant)}
            </ul>
          </>}
          {!!request?.departmentNameOfExecution && <p className="department-info">
            <span className="label">{translations.getLabel('lblDepartment')}</span>
            {request?.departmentNameOfExecution}
          </p>}
          {request?.departmentNames?.length > 0 && <p className="department-info">
            <span className="label">{translations.getLabel('lblShiftOfferFor')}</span>
            {request?.departmentNames.join(', ')}
          </p>}
        </div>
        {request?.shifts?.map(shift => <Shift key={shift.id} shift={shift} />)}
      </div>
      <div className="hr" />
      {
        request &&
        <div className="footer">
          <Footer id={id} departmentId={departmentId} openRoster={toggleRosterOpen} openCancelModal={toggleCancelModal} />
        </div>
      }

      {
        isRosterOpen && <Modal open title="-" requestClose={toggleRosterOpen}>
          <div className="schedule-toolbar">
            <SchedulePageSwitch
              dateRange={TimeScale.Week}
              selectedDate={requestSelectedDate}
              changeDate={(modifier: number) => setSelectedDate(scheduleUtils.getModifiedDate(TimeScale.Week, requestSelectedDate, modifier, weekStartsOn))}
              setSpecificDate={(date: Date) => setSelectedDate(date)}
            />
          </div>
          <ShiftOfferSchedule
            shiftOfferId={request.id}
            onSelect={onSelectUser}
            selectedUsers={selectedUsers}
            departmentId={request.departmentId || request.departmentIdOfExecution}
            selectedDate={requestSelectedDate}
            numberOfPeople={request.numberOfPeople}
            numberOfApplicants={request.numberOfApplicants}
            availableApplications={request.applicants.filter(a => a.status !== ShiftOfferApplicantStatus.Accepted)}
            highlightRange={{ startDate: requestDate, endDate: requestDate }}
          />
          <div className="actions">
            <Button theme="inverse" onClick={toggleRosterOpen}>{translations.getLabel('cancel')}</Button>
            <Button disabled={selectedUsers.length < 1} onClick={toggleConfirmationModal}>{translations.getLabel('btnConfirmChoice')}</Button>
          </div>
          {isConfirmationModalOpen && // use custom modal, since it's a modal in a modal
            <div className="modal-container confirmation-modal">
              <Popup open={isConfirmationModalOpen} position={Position.Modal} requestClose={toggleConfirmationModal}>
                <>
                  <div className="modal-header">
                    <h3>{translations.getLabel('titleConfirmShiftOfferChoice')}</h3>
                  </div>
                  <p className="confirmation-message">{translations.getLabel('lblConfirmShiftOfferChoice')}</p>
                  <div className="modal-footer">
                    <Button type="button" theme="inverse" onClick={toggleConfirmationModal}><span>{translations.getLabel('cancel')}</span></Button>
                    <Button type="submit" onClick={approveShiftOffer} isLoading={isShiftOfferUpdateLoading}>{translations.getLabel('btnConfirmChoice')}</Button>
                  </div>
                </>
              </Popup>
            </div>
          }
        </Modal>
      }
      {isCloseConfirmationModalOpen && // use custom modal, since it's a modal in a modal
        <div className="modal-container confirmation-modal">
          <Popup open={isCloseConfirmationModalOpen} position={Position.Modal} requestClose={toggleCloseConfirmationModal}>
            <>
              <div className="modal-header">
                <h3>{translations.getLabel('titleCloseConfirmationShiftOffer')}</h3>
              </div>
              <p className="confirmation-message">{translations.getLabel('lblCloseConfirmationShiftOffer')}</p>
              <div className="modal-footer">
                <Button type="button" theme="inverse" onClick={toggleCloseConfirmationModal}><span>{translations.getLabel('cancel')}</span></Button>
                <Button type="submit" onClick={closeShiftOffer} isLoading={isShiftOfferUpdateLoading}>{translations.getLabel('btnConfirmClose')}</Button>
              </div>
            </>
          </Popup>
        </div>
      }
      <Modal
        open={isCancelModalOpen}
        className="confirmation-modal"
        title={translations.getLabel('titleConfirmCancelShiftOffer')}
        requestClose={toggleCancelModal}
        leftButtonProps={{ label: translations.getLabel('no'), onClick: toggleCancelModal }}
        rightButtonProps={{ label: translations.getLabel('yes'), isLoading: isShiftOfferUpdateLoading, onClick: cancelShiftOffer }}
      >
        <p className="confirmation-message">{translations.getLabel('lblConfirmCancelShiftOffer')}</p>
      </Modal>
    </div >
  );
};

export default RequestDetail;
