import { createSelector } from 'reselect';

import { ToasterState } from './reducer';
import { AppState } from '../rootReducer';

const selectNode = (state: AppState) => state.toaster;

export const shouldShow = createSelector(
  selectNode,
  (state: ToasterState) => state.shouldShow,
);


export const type = createSelector(
  selectNode,
  (state: ToasterState) => state.type,
);

export const label = createSelector(
  selectNode,
  (state: ToasterState) => state.label,
);

export const message = createSelector(
  selectNode,
  (state: ToasterState) => state.message,
);

