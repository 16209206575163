import React from 'react';

import DataItemBase from '../../../../../shared/components/dataItem/DataItem.base';

import './DataItem.scss';

class DataItem extends DataItemBase {
  render() {
    return (
      <div className="data-item-container">
        <h4>
          {this.props.title}
        </h4>
        <p className="value">
          {this.props.value}
        </p>
      </div>
    );
  }
}

export default DataItem;
