import { Action } from 'redux';

import ActionType from './types';
import { IEmployment, LoginStatus } from '../../models';
import { IServerError } from '../../http/HttpError';

export class LoginAction implements Action<ActionType> {
  readonly type = ActionType.Login;
  constructor(public payload: { username: string, password: string, holding: string, onSuccess: (employments: IEmployment[]) => void, onError: (error: IServerError) => void }) { }
}

export class LoginFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.LoginFulfilled;
  constructor(public payload: { employments: IEmployment[], status: LoginStatus, token: string }) { }
}

export class LoginRejectedAction implements Action<ActionType> {
  readonly type = ActionType.LoginRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class LoginWithSsoAction implements Action<ActionType> {
  readonly type = ActionType.LoginWithSso;
  constructor(public payload: { token: string, holding: string, onSuccess?: (employments: IEmployment[]) => void, onError?: (error: IServerError) => void }) { }
}

export class LoginWithSsoFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.LoginWithSsoFulfilled;
  constructor(public payload: {employments: IEmployment[], status: LoginStatus, token: string }) { }
}

export class LoginWithSsoRejectedAction implements Action<ActionType> {
  readonly type = ActionType.LoginWithSsoRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class LogoutAction implements Action<ActionType> {
  readonly type = ActionType.Logout;
  constructor(public payload?: { onSuccess: () => void }) { }
}

export class LogoutFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.LogoutFulfilled;
}

export class LogoutRejectedAction implements Action<ActionType> {
  readonly type = ActionType.LogoutRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class LogoutAutomaticallyAction implements Action<ActionType> {
  readonly type = ActionType.LogoutAutomatically;
}

export class GetEmploymentsAction implements Action<ActionType> {
  readonly type = ActionType.GetEmployments;
  constructor() { }
}

export class GetEmploymentsFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetEmploymentsFulfilled;
  constructor(public payload: { data: IEmployment[] }) { }
}

export class GetEmploymentsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetEmploymentsRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class UpdateEmploymentAction implements Action<ActionType> {
  readonly type = ActionType.UpdateEmployment;
  constructor(public payload: { id: string; onSuccess?: () => void }) { }
}

export class UpdateEmploymentFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.UpdateEmploymentFulfilled;
}

export class UpdateEmploymentRejectedAction implements Action<ActionType> {
  readonly type = ActionType.UpdateEmploymentRejected;
  constructor(public payload: { error: IServerError }) { }
}

export type Actions =
  | LoginAction
  | LoginFulfilledAction
  | LoginRejectedAction
  | LoginWithSsoAction
  | LoginWithSsoFulfilledAction
  | LoginWithSsoRejectedAction
  | LogoutAction
  | LogoutFulfilledAction
  | LogoutRejectedAction
  | LogoutAutomaticallyAction
  | GetEmploymentsAction
  | GetEmploymentsFulfilledAction
  | GetEmploymentsRejectedAction
  | UpdateEmploymentAction
  | UpdateEmploymentFulfilledAction
  | UpdateEmploymentRejectedAction;