import React from 'react';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';
import firebase from 'firebase/app';

import { notificationsService } from '../../../shared/services';
import { storageItems } from '../constants';
import { notificationUtils } from '.';

import 'firebase/messaging';
import 'firebase/analytics';

const config = {
  apiKey: 'AIzaSyCQ3nOlx-7YckNW4vG1JTYvzz7jLBBwMA0',
  authDomain: 'intouch-24b8c.firebaseapp.com',
  databaseURL: 'https://intouch-24b8c.firebaseio.com',
  projectId: 'intouch-24b8c',
  storageBucket: 'intouch-24b8c.appspot.com',
  messagingSenderId: '818163708746',
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
};

firebase.initializeApp(config);

let messaging: firebase.messaging.Messaging;
let analytics: firebase.analytics.Analytics;
let removeTokenRefreshListener: firebase.Unsubscribe;

const isSupported = (): boolean => firebase.messaging.isSupported();

// we need to check if messaging is supported by the browser
if (isSupported()) {
  messaging = firebase.messaging();
}

// we need to check if analytics is supported by the browser
firebase.analytics.isSupported().then((isAnalyticsSupported) => {
  if (isAnalyticsSupported) {
    analytics = firebase.analytics();
  }
});

// register service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', async () => {
    const registration = await navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`, {
      updateViaCache: 'none',
    });
    if (messaging) {
      messaging.useServiceWorker(registration);

      messaging.onMessage((payload) => {
        const redirect = () => {
          const { data } = payload;
          return notificationUtils.goToNotification(data.employmentId, data.type, data.identifier);
        };
        if (notificationUtils.shouldShowToaster(payload?.data?.type, payload?.data?.identifier)) {
          toast(<button className="stripped-button" onClick={redirect}>{payload.notification.body}</button>);
        }
      });
    }
  });
}


const registerToken = async (isRefresh = false): Promise<void> => {
  if (!isSupported()) return;
  const token = await messaging.getToken();
  if (!isRefresh && !removeTokenRefreshListener) onRefreshToken();
  try {
    notificationsService.register(token, localStorage.getItem(storageItems.FIREBASE_TOKEN));
    localStorage.setItem(storageItems.FIREBASE_TOKEN, token);
  } catch (err) {
    Sentry.captureMessage(`Something went wrong while registering PN on WEB: ${JSON.stringify(err)}`);
  }
};

const onRefreshToken = () => {
  if (!isSupported()) return null;
  removeTokenRefreshListener = messaging.onTokenRefresh(() => {
    registerToken(true);
  });
};

const unRegister = (): void => {
  if (localStorage.getItem(storageItems.FIREBASE_TOKEN)) {
    try {
      notificationsService.unRegister(localStorage.getItem(storageItems.FIREBASE_TOKEN));
      localStorage.removeItem(storageItems.FIREBASE_TOKEN);
    } catch (err) {
      Sentry.captureMessage(`Something went wrong while unregistering PN on WEB: ${err?.description}`);
    }
  }
};

export { isSupported, messaging, registerToken, unRegister, analytics };
