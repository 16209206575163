import { createSelector } from 'reselect';
import { AppState } from '../rootReducer';
import { CounterState } from './reducer';

const selectNode = (state: AppState) => state.counters;

export const getCounters = createSelector(
  selectNode,
  (state: CounterState) => state.counters,
);

export const getDashboardCounters = createSelector(
  selectNode,
  (state: CounterState) => state.dashboardCounters,
);

export const getServerError = createSelector(
  selectNode,
  (state: CounterState) => state.hasServerError,
);

export const getVacationCounter = createSelector(
  selectNode,
  (state: CounterState) => state.vacationCounters,
);

export const isLoading = createSelector(
  selectNode,
  (state: CounterState) => state.isLoading,
);

export const isLoadingDashboard = createSelector(
  selectNode,
  (state: CounterState) => state.isLoadingDashboard,
);

export const isLoadingVacationCounter = createSelector(
  selectNode,
  (state: CounterState) => state.isLoadingVacationCounter,
);

export const getCounterOverview = createSelector(
  selectNode,
  (state: CounterState) => state.counterOverview,
);

export const isLoadingCounterOverview = createSelector(
  selectNode,
  (state: CounterState) => state.isLoadingCounterOverview,
);

export const getCounterOverviewConfiguration = createSelector(
  selectNode,
  (state: CounterState) => state.counterOverviewConfiguration,
);

export const isLoadingCounterOverviewConfiguration = createSelector(
  selectNode,
  (state: CounterState) => state.isLoadingCounterOverviewConfiguration,
);