import { PureComponent } from 'react';
import PropTypes from 'prop-types';

class AvatarPicker extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return null;
  }
}

AvatarPicker.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  source: PropTypes.string,
  handleUpload: PropTypes.func,
  isLoading: PropTypes.bool,
};

const noop = () => { };
AvatarPicker.defaultProps = {
  firstName: '',
  lastName: '',
  source: '',
  handleUpload: noop,
  isLoading: false,

};

export default AvatarPicker;