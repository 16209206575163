import { HttpClient } from '../http';
import { ICounterGroup, ICounter, ICounterOverview, ICounterOverviewConfiguration } from '../models';

export const getCounters = async (page: string): Promise<ICounter[]> => {
  const result = await HttpClient.get<{ data: ICounter[] }>(`/v1/counters/${page}`);
  return result.data;
};

export const getCounterGroups = async (): Promise<ICounterGroup[]> => {
  const result = await HttpClient.get<{ data: ICounterGroup[] }>('/v1/counters');
  return result.data;
};

export const getCounterOverview = async (id: string, startDate: string, endDate: string): Promise<ICounterOverview> => {
  const result = await HttpClient.get<ICounterOverview>(`/v1/counter-overview/${id}?startDate=${startDate}&endDate=${endDate}`);
  return result;
};

export const getCounterOverviewConfiguration = async (): Promise<ICounterOverviewConfiguration[]> => {
  const result = await HttpClient.get<{ data: ICounterOverviewConfiguration[] }>('/v1/counter-overview/configuration/overviews');
  return result.data;
};