import React, { FC, useRef } from 'react';
import { Icon } from 'react-ess-components';
import classnames from 'classnames';
import { parseISO } from 'date-fns';

import { IShift } from '../../../../models';
import { date } from '../../../../utils';
import { OpenShiftFunction } from '../../schedule.models';
import Interval from './Interval';

interface Props {
  shift: IShift;
  openShift: OpenShiftFunction;
  isCollapsed: boolean;
}

const Shift: FC<Props> = ({ shift, openShift, isCollapsed }) => {
  const ref = useRef<HTMLButtonElement>(null);
  const startDate = parseISO(shift.startDate);
  const endDate = parseISO(shift.endDate);

  return (
    <button key={shift.id} ref={ref} onClick={() => openShift(shift, ref.current)} className={classnames('stripped-button shift', { collapsed: isCollapsed })}>
      <h3>
        <span>{shift.shiftName}{!isCollapsed && ' | '}</span>
        <span className="timestamp">{` ${date.formatFromUntilTime(date.format, { startDate, endDate })}`}</span>
      </h3>
      {!isCollapsed && <>
        <div className="department">{shift.functionName}{(!!shift.functionName && shift.departmentSwitch) && ' - '}{shift.departmentSwitch && shift.department} {shift.departmentSwitch && <Icon small tag="SwitchIcon" />}</div>
        <div className="shit-interval-spacer" />
        {shift.intervals.map((interval, i) => <Interval key={i} interval={interval} />)}
      </>}
    </button>);
};

export default Shift;
