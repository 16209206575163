import { Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { switchMap, exhaustMap, map, catchError, mergeMap } from 'rxjs/operators';

import ActionType from './types';
import { chatService } from '../../services';
import * as chatSelectors from './selectors';
import {
  GetConversationsAction,
  GetConversationsFulfilledAction,
  GetConversationsRejectedAction,
  CreateConversationAction,
  CreateConversationFulfilledAction,
  CreateConversationRejectedAction,
  LeaveConversationAction,
  LeaveConversationFulfilledAction,
  LeaveConversationRejectedAction,
  GetPresignedUrlAction,
  GetPresignedUrlFulfilledAction,
  GetPresignedUrlRejectedAction,
  GetUnreadCountFulfilledAction,
  GetUnreadCountRejectedAction,
  MarkAsReadAction,
  MarkAsReadFulfilledAction,
  MarkAsReadRejectedAction,
  GetParticipantsAction,
  GetParticipantsFulfilledAction,
  GetParticipantsRejectedAction
} from './actions';

export const getConversationsEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetConversations).pipe(
    mergeMap(({ payload }: GetConversationsAction) =>
      from(chatService.getConversations(payload?.query)).pipe(
        map(({ conversations, count }) => {
          payload?.onSuccess?.();
          return new GetConversationsFulfilledAction({ data: conversations, count });
        }),
        catchError(error => of(new GetConversationsRejectedAction({ error })))
      )
    ),
  );

export const createConversationEpic$: Epic = action$ =>
  action$.ofType(ActionType.CreateConversation).pipe(
    exhaustMap(({ payload }: CreateConversationAction) =>
      from(chatService.createConversation(payload)).pipe(
        map(data => {
          payload?.onSuccess({ data });
          return new CreateConversationFulfilledAction({ data });
        }),
        catchError(error => {
          payload?.onError(error);
          return of(new CreateConversationRejectedAction({ error }));
        })
      )
    ),
  );

export const leaveConversationEpic$: Epic = action$ =>
  action$.ofType(ActionType.LeaveConversation).pipe(
    exhaustMap(({ payload }: LeaveConversationAction) =>
      from(chatService.leaveConversation(payload.id)).pipe(
        map(() => new LeaveConversationFulfilledAction({ onSuccess: payload.onSuccess })),
        catchError(error => {
          payload.onError?.(error);
          return of(new LeaveConversationRejectedAction({ error }));
        })
      )
    ),
  );

export const leaveConversationFulfilledEpic$: Epic = action$ =>
  action$.ofType(ActionType.LeaveConversationFulfilled).pipe(
    exhaustMap(({ payload }: LeaveConversationFulfilledAction) => of(
      new GetConversationsAction({ onSuccess: payload.onSuccess }),
    )),
  );

export const getPresignedUrlEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetPresignedUrl).pipe(
    exhaustMap(({ payload }: GetPresignedUrlAction) =>
      from(chatService.getPresignedUrl(payload.roomId, payload.contentType, payload.conversationId)).pipe(
        map(({ uploadUrl, storageId }) => {
          payload.onSuccess?.(uploadUrl);
          return new GetPresignedUrlFulfilledAction({ uploadUrl, storageId });
        }),
        catchError(error => of(new GetPresignedUrlRejectedAction({ error })))
      )
    ),
  );

export const getUnreadCountEpic$: Epic = (action$, state$) =>
  action$.ofType(ActionType.GetUnreadCount).pipe(
    exhaustMap(() =>
      from(chatService.getUnread()).pipe(
        switchMap(amount => {
          const oldAmount = chatSelectors.amountUnread(state$.value);
          if (amount !== oldAmount) {
            return of(
              new GetUnreadCountFulfilledAction({ amount }),
              new GetConversationsAction(),
            );
          }
          return of(new GetUnreadCountFulfilledAction({ amount }));
        }),
        catchError(error => of(new GetUnreadCountRejectedAction({ error })))
      )
    ),
  );

export const markAsReadEpic$: Epic = action$ =>
  action$.ofType(ActionType.MarkAsRead).pipe(
    exhaustMap(({ payload }: MarkAsReadAction) =>
      from(chatService.markAsRead(payload.id)).pipe(
        map(({ id }) => new MarkAsReadFulfilledAction({ id })),
        catchError(error => of(new MarkAsReadRejectedAction({ error })))
      )
    ),
  );

export const getParticipantsEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetParticipants).pipe(
    exhaustMap(({ payload }: GetParticipantsAction) =>
      from(chatService.getParticipants(payload ? payload.query : null)).pipe(
        map(data => {
          payload?.onSuccess?.();
          return new GetParticipantsFulfilledAction({ data });
        }),
        catchError(error => of(new GetParticipantsRejectedAction({ error })))
      )
    ),
  );

const epics = [
  getConversationsEpic$,
  createConversationEpic$,
  leaveConversationEpic$,
  leaveConversationFulfilledEpic$,
  getPresignedUrlEpic$,
  getUnreadCountEpic$,
  markAsReadEpic$,
  getParticipantsEpic$,
];

export default epics;
