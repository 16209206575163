import { Action } from 'redux';
import { CANCEL_MESSAGE, LOGGED_OUT_ERROR, SPX_IS_DOWN } from '../../http/HttpUtils';
import ActionType from '../login/types';
import ConfigActionType from '../config/types';
import { IServerError } from '../../http/HttpError';

interface ErrorAction extends Action {
  payload?: { error?: IServerError, oldAction?: ErrorAction };
};

const cancelAndLogoutMiddleware = () => (next: (action: ErrorAction) => ErrorAction) => (action: ErrorAction) => {
  if (action?.payload?.error) {
    if (action?.payload?.error?.code === CANCEL_MESSAGE) {
      return next({ type: action.type.replace('REJECTED', 'CANCELLED') });
    }
    if (action?.payload?.error?.code === LOGGED_OUT_ERROR) {
      return next({ type: ActionType.LogoutAutomatically });
    }
    if (action?.payload?.error?.code === SPX_IS_DOWN) {
      return next({ type: ConfigActionType.SpxIsDown, payload: { oldAction: action } });
    }
  }
  return next(action);
};

export default cancelAndLogoutMiddleware;