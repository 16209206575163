import { Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { exhaustMap, switchMap, map, catchError } from 'rxjs/operators';

import ActionType from './types';
import { availabilitiesService } from '../../services';
import { GetAvailabilitiesAction, GetAvailabilitiesFulfilledAction, GetAvailabilitiesRejectedAction, UpdateAvailabilitiesAction, UpdateAvailabilitiesFulfilledAction, UpdateAvailabilitiesRejectedAction } from './actions';

export const getAvailabilitiesEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetAvailabilities).pipe(
    switchMap(({ payload }: GetAvailabilitiesAction) =>
      from(availabilitiesService.get(payload.startDate, payload.endDate)).pipe(
        map(({ data, timeTypes, totalCount }) => {
          payload.onSuccess?.(data);
          return new GetAvailabilitiesFulfilledAction({ data, timeTypes, totalCount });
        }),
        catchError(error => {
          payload.onError?.(error);
          return of(new GetAvailabilitiesRejectedAction({ error: error }));
        })
      )
    ),
  );

export const updateAvailabilitiesEpic$: Epic = action$ =>
  action$.ofType(ActionType.PutAvailabilities).pipe(
    exhaustMap(({ payload }: UpdateAvailabilitiesAction) =>
      from(availabilitiesService.put(payload.availabilities)).pipe(
        map(() => {
          payload.onSuccess?.();
          return new UpdateAvailabilitiesFulfilledAction();
        }),
        catchError(error => {
          payload.onError?.(error);
          return of(new UpdateAvailabilitiesRejectedAction({ error }));
        })
      )
    ),
  );

const epics = [
  getAvailabilitiesEpic$,
  updateAvailabilitiesEpic$
];

export default epics;
