import { ITransactionRequest, TransactionInputType } from '../models';

export const getTransactionValue = (request: ITransactionRequest): string => {
    if (request?.transactionType === TransactionInputType.Boolean) {
        if (request?.value === 1) {
            return request?.descriptionTrue;
        } else {
            return request?.descriptionFalse;
        }
    } else {
        return request?.value.toString();
    }
};