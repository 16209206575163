import React, { FC } from 'react';

import ShiftOfferRoster from '../shiftOfferRoster/ShiftOfferRoster';
import ShiftSwapRoster from '../shiftSwapRoster/ShiftSwapRoster';

interface Props {
  hideShiftOffers: boolean;
  hideShiftSwaps: boolean;
  selectedDate: Date;
  departmentId: string;
}

const SubRoster: FC<Props> = ({ hideShiftOffers, hideShiftSwaps, selectedDate, departmentId }) => {
  return (
    <>
      {!hideShiftOffers && <ShiftOfferRoster
        departmentId={departmentId}
        selectedDate={selectedDate}
      />}
      {!hideShiftSwaps && < ShiftSwapRoster
        selectedDate={selectedDate}
      />}
    </>
  );
};

export default SubRoster;
