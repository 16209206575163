import { createSelector } from 'reselect';
import { eachDayOfInterval, isSameDay, parseISO } from 'date-fns';

import { ScheduleState } from './reducer';
import { AppState } from '../rootReducer';
import { scheduleUtils } from '../../utils';
import { IDayPlanning, IEmployee } from '../../models';
import { formatDateForSchedule } from '../../utils/schedule';

const selectNode = (state: AppState) => state.schedule;

export const isDepartmentSearchLoading = createSelector(
  selectNode,
  (state: ScheduleState) => state.isDepartmentSearchLoading,
);

export const isDepartmentPlanningLoading = createSelector(
  selectNode,
  (state: ScheduleState) => state.isDepartmentPlanningLoading,
);

export const isPlanningLoading = createSelector(
  selectNode,
  (state: ScheduleState) => state.isLoading,
);

export const getSearchValue = createSelector(
  selectNode,
  (state: ScheduleState) => state.searchValue,
);

export const getPlanningSearched = createSelector(
  selectNode,
  (state: ScheduleState) => state.planningSearch,
);

export const getPlanningForUser = (state: AppState, id: string, start: Date, end: Date, ignoreSearch = false, departmentId?: string): IDayPlanning[] => {
  const daysBetween = eachDayOfInterval({ start, end });
  const planningForUser = [];
  const planning = departmentId ? getDepartmentPlanning(state, departmentId) : ignoreSearch ? getPlanningWithoutSearch(state) : getPlanning(state);
  daysBetween.forEach(day => {
    const foundPlanning = planning?.[scheduleUtils.formatDateForSchedule(day)]?.[id];
    if (foundPlanning) planningForUser.push(foundPlanning);
  });
  return planningForUser;
};

export const getUsersForDay = (state: AppState, excludeUser?: string, departmentId?: string, day?: Date): string[] => {
  const planning = departmentId ? getDepartmentPlanning(state, departmentId) : getPlanning(state);
  // Get planning of the selected day (first day as backup)
  const foundPlanning = planning[day ? scheduleUtils.formatDateForSchedule(day) : Object.keys(planning)[0]];

  if (!foundPlanning) return [];
  return Object.keys(foundPlanning).filter(id => id !== excludeUser);
};

export const getUserDetailsFromPlanning = (state: AppState, userId: string, departmentId?: string, day?: Date): IDayPlanning => {
  const planning = departmentId ? getDepartmentPlanning(state, departmentId) : getPlanning(state);
  // Get planning of the selected day (first day as backup)
  const foundPlanning = planning[day ? scheduleUtils.formatDateForSchedule(day) : Object.keys(planning)[0]];
  if (!foundPlanning) return null;
  return foundPlanning[userId];
};

export const getPlanning = createSelector(
  selectNode,
  getSearchValue,
  (state: ScheduleState, searchvalue: string) => (searchvalue ? state.planningSearch : state.planning) || {},
);

export const getPlanningWithoutSearch = createSelector(
  selectNode,
  (state: ScheduleState) => state.planning || {},
);

export const getDepartmentPlanning = (state: AppState, departmentId: string) => {
  const node = selectNode(state);
  return node.planningDepartment?.[departmentId] || {};
};

export const hasFullPlanning = createSelector(
  selectNode,
  (state: ScheduleState) => state.fullPlanning,
);

export const hasPlanningTodayError = createSelector(
  selectNode,
  (state: ScheduleState) => state.hasPlanningTodayError,
);

export const hasPlanningError = createSelector(
  selectNode,
  (state: ScheduleState) => state.hasPlanningError,
);

export const getShiftOfferApplicants = (state: AppState, shiftOfferId: string) => {
  const node = selectNode(state);
  return node.shiftOfferApplicants?.[shiftOfferId];
};

export const isShiftOfferApplicantsLoading = createSelector(
  selectNode,
  (state: ScheduleState) => state.isShiftOfferApplicantsLoading,
);

export const isDeletingExternalVacationRequest = createSelector(
  selectNode,
  (state: ScheduleState) => state.isDeletingExternalVacationRequest,
);

export const getShiftSwapApplicants = (state: AppState, shiftSwapId: string) => {
  const node = selectNode(state);
  return node.shiftSwapApplicants?.[shiftSwapId];
};

export const getExternalVacationRequestForDate = (state: AppState, date: string) => {
  const node = selectNode(state);
  const parsedDate = parseISO(date);
  return node.planningExternalVacationRequests[formatDateForSchedule(parsedDate)];
};