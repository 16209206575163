import React, { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { ISelfRosteringIteration, ISelfRosteringShift } from '../../models';
import { selfRosterQueryKeys } from './queryKeys';

type TSelfRosterContext = {
  hasChanges: boolean;
  isMultiDepartment: boolean;
  selectedDate?: Date;
  selectedIteration: ISelfRosteringIteration;
  selectedShifts?: ISelfRosteringShift[];
  selectShift: (shift: ISelfRosteringShift) => void;
  setHasChanges: (hasChanges: boolean) => void;
  setIsMultiDepartment: (isMultiDepartment: boolean) => void;
  setSelectedDate?: (date: Date) => void;
  setSelectedIteration?: (iteration: ISelfRosteringIteration, date?: Date) => void;
  setSelectedShifts: (shifts: ISelfRosteringShift[]) => void;
};

const SelfRosterContext = createContext<TSelfRosterContext>({
  hasChanges: false,
  isMultiDepartment: false,
  selectedDate: null,
  selectedIteration: null,
  selectedShifts: [],
  selectShift: () => { },
  setHasChanges: () => { },
  setIsMultiDepartment: () => { },
  setSelectedDate: () => { },
  setSelectedIteration: () => { },
  setSelectedShifts: () => { },
});

export const useSelfRosterContext = () => useContext(SelfRosterContext);

type TProps = {
  children: ReactNode;
  iteration?: ISelfRosteringIteration;
};

export const SelfRosterContextProvider: FC<TProps> = ({ children, iteration }) => {
  const queryClient = useQueryClient();
  const [hasChanges, setHasChanges] = useState(false);
  const [isMultiDepartment, setIsMultiDepartment] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(null);
  const [selectedIteration, setSelectedIteration] = useState<ISelfRosteringIteration>(iteration);
  const [selectedShifts, setSelectedShift] = useState<ISelfRosteringShift[]>([]);

  const setIteration = (iteration: ISelfRosteringIteration, startDate?: Date) => {
    setSelectedShift([]);
    setIsMultiDepartment(false);
    setHasChanges(false);
    setSelectedIteration(iteration);
    queryClient.resetQueries(selfRosterQueryKeys.allSchedule());
    queryClient.resetQueries(selfRosterQueryKeys.counters());
    if (startDate) setSelectedDate(startDate);
  };

  const setSelectedShifts = useCallback((shifts: ISelfRosteringShift[]) => {
    setSelectedShift(shifts);
    setHasChanges(false);
  }, [setSelectedShift, setHasChanges]);

  useEffect(() => {
    if (iteration) {
      setIteration(iteration);
    }
  }, [iteration]);

  const selectShift = (shift: ISelfRosteringShift) => {
    setHasChanges(true);
    if (selectedShifts.some(_shift => _shift.uuid === shift.uuid)) {
      setSelectedShift(selectedShifts.filter(_shift => _shift.uuid !== shift.uuid));
    } else {
      setSelectedShift([...selectedShifts, shift]);
    }
  };

  return (
    <SelfRosterContext.Provider
      value={{
        hasChanges,
        isMultiDepartment,
        selectedDate,
        selectedIteration,
        selectedShifts,
        selectShift,
        setHasChanges,
        setIsMultiDepartment,
        setSelectedDate,
        setSelectedIteration: setIteration,
        setSelectedShifts,
      }}
    >
      {children}
    </SelfRosterContext.Provider>
  );
};
