import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { isSameDay, isWithinInterval, parseISO } from 'date-fns';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { IDateRange } from '../../../../models';
import { scheduleSelectors } from '../../../../redux';
import { OpenAvailabilitiesFunction, OpenPlanningFunction } from '../../schedule.models';
import DayHeader from './DayHeader';
import WeekDayFooter from './WeekDayFooter';
import WeekDayRequests from './WeekDayRequest';
import WeekDayShifts from './WeekDayShifts';

interface Props {
  day: Date;
  departmentId: string;
  highlightDates: string[];
  highlightRange: IDateRange;
  ignoreSearch: boolean;
  isCollapsed: boolean;
  isMultiSelect: boolean;
  isSelectable: boolean;
  isSelected: boolean;
  isSelectionDisabled: boolean;
  parentScrollRef: HTMLElement;
  selectedDate: Date;
  selectedShifts: string[];
  selectedUsers: string[];
  setSelection: (date: string) => void;
  toggleAvailabilities: OpenAvailabilitiesFunction;
  togglePlanning: OpenPlanningFunction;
  userId: string;
  withoutLabel?: boolean;
}

const WeekDay: FC<Props> = ({
  day,
  departmentId,
  highlightDates,
  highlightRange,
  ignoreSearch,
  isCollapsed,
  isMultiSelect,
  isSelectable,
  isSelected,
  isSelectionDisabled,
  parentScrollRef,
  selectedShifts,
  selectedUsers,
  setSelection,
  toggleAvailabilities,
  togglePlanning,
  userId,
  withoutLabel,
}) => {
  const planning = useSelector((state: AppState) => scheduleSelectors.getPlanningForUser(state, userId, day, day, ignoreSearch, departmentId));
  const isLoading = (planning.length < 1);
  const dayPlanning = planning?.[0] || null;

  return (
    <div className="week-day">
      <DayHeader day={day} withoutLabel={withoutLabel} />
      {!isLoading &&
        <>
          <WeekDayRequests planning={dayPlanning} parentScrollRef={parentScrollRef} />
          <WeekDayShifts highlightRange={highlightRange} highlightDates={highlightDates} isCollapsed={isCollapsed} planning={dayPlanning} openPlanning={togglePlanning} />
          <WeekDayFooter
            currentDate={parseISO(dayPlanning.date)}
            openAvailabilities={toggleAvailabilities}
            isSelectable={
              (isSelectable && (!highlightRange || isWithinInterval(parseISO(dayPlanning.date), { start: highlightRange.startDate, end: highlightRange.endDate })))
              && (!highlightDates || !!highlightDates.find(d => isSameDay(parseISO(d), parseISO(dayPlanning.date))))
            }
            hideAvailabilities={isSelectable}
            onSelect={setSelection}
            isSelected={
              isSelected ||
              ((!selectedUsers || selectedUsers.includes(userId)) && !!selectedShifts?.find(selectedShift => isSameDay(parseISO(selectedShift), parseISO(dayPlanning.date))))
            }
            isMultiSelect={isMultiSelect}
            isSelectionDisabled={isSelectionDisabled}
          />
        </>
      }
    </div>
  );
};

export default WeekDay;
