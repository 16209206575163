import * as date from './date';
import { addDays, isPast, startOfDay, startOfWeek, isAfter, startOfMonth, endOfMonth, getDay, addMonths, eachDayOfInterval, format, addWeeks, parse, endOfWeek, subYears, addYears, isSameDay, parseISO, endOfDay } from 'date-fns';
import { identifiers } from '../constants';
import { IDateRange, IRelativeFormattedDate, TimeScale, IDayPlanning, IAvailability, ITimeType, Period, WeekStartsOn, IPlanning } from '../models';

let dateUtil = null;
const today = new Date();

export const getBoundsOfRange = (timeScale: TimeScale, selectedDate: Date = today, shouldHavePadding: boolean = true, weekStartsOn: WeekStartsOn = 1): IDateRange => {
  const parsedDate = typeof selectedDate === 'string' ? date.parseDate(selectedDate) : selectedDate;
  switch (timeScale) {
    case TimeScale.Day:
      return {
        startDate: selectedDate,
        endDate: endOfDay(selectedDate),
      };
    case TimeScale.Week:
      return {
        startDate: startOfWeek(parsedDate, { weekStartsOn }),
        endDate: endOfDay(addDays(startOfWeek(parsedDate, { weekStartsOn }), 6)),
      };
    case TimeScale.Month:
      let startDate = startOfMonth(parsedDate);
      let endDate = endOfMonth(parsedDate);

      if (shouldHavePadding) {
        const monthOffsetBefore = getDay(startDate) - weekStartsOn;
        const monthOffsetAfter = 6 - getDay(endDate) + weekStartsOn;
        startDate = addDays(startOfMonth(parsedDate), monthOffsetBefore >= 0 ? -monthOffsetBefore : -6);
        endDate = addDays(endOfMonth(parsedDate), monthOffsetAfter <= 6 ? monthOffsetAfter : 0);
      }

      return {
        startDate,
        endDate: endOfDay(endDate),
      };
  }
};

export const getModifiedDate = (timeScale: TimeScale, selectedDate: Date, modifier: number, weekStartsOn: WeekStartsOn = 1): Date => {
  switch (timeScale) {
    case TimeScale.Day:
      return addDays(selectedDate, modifier);
    case TimeScale.Week:
      return startOfWeek(addWeeks(selectedDate, modifier), { weekStartsOn });
    case TimeScale.Month:
      return addMonths(selectedDate, modifier);
  }
};

export const getScheduleHeader = (timeScale: TimeScale, selectedDate: Date, formatFunction: date.FormatFunction, weekStartsOn: WeekStartsOn = 1): IRelativeFormattedDate => {
  switch (timeScale) {
    case TimeScale.Day:
      return date.toRelativeDate(formatFunction, selectedDate, 'EEEEEE dd/MM/yyyy');
    case TimeScale.Week:
      const startDate = startOfWeek(selectedDate, { weekStartsOn });
      const endDate = addDays(startDate, 6);
      return { value: `${formatFunction(startDate, 'EEEEEE d MMM')} - ${formatFunction(endDate, 'EEEEEE d MMM')}` };
    case TimeScale.Month:
      return { value: formatFunction(selectedDate, 'MMM yyyy') };
  }
};

export const getAvailabilityTypes = (availabilities: IAvailability[], timeTypes: ITimeType[], render: (color: string, index: number) => JSX.Element): JSX.Element[] => {
  const availableTimeTypes = availabilities.reduce((accu, availability) => {
    if (!accu.includes(availability.code)) return [...accu, availability.code];
    return [...accu];
  }, []);

  if (availableTimeTypes) {
    return timeTypes.reduce((accu, type, index) => type.visible && availableTimeTypes.includes(type.code) ? [...accu, render(type.color, index)] : accu, []);
  }
  return [];
};

export const getAvailabilityTypesAndDates = (availabilities: IAvailability[], timeTypes: ITimeType[], render: (color: string, code: string, start: string, end: string, index: number) => JSX.Element): JSX.Element[] => {
  const orbs = [];
  timeTypes.forEach((type, i) => {
    const availabilitiesPerType = availabilities.filter((availability) => type.code === availability.code && type.visible);
    if (availabilitiesPerType.length) {
      orbs.push(
        render(type.color, type.description, availabilitiesPerType[0].startDate, availabilitiesPerType[availabilitiesPerType.length - 1].endDate, i)
      );
    }
  });

  return orbs;
};

export const getInPlanning = (planning, startDate, endDate, employeeId) => {
  const daysBetween = eachDayOfInterval({ start: startDate, end: endDate });
  const correctPlanning = [];
  daysBetween.forEach(day => {
    const foundPlanning = planning?.[formatDateForSchedule(day)]?.[employeeId];
    if (foundPlanning) correctPlanning.push(foundPlanning);
  });
  return correctPlanning;
};

export const getFullPlanningInPlanning = (planning, startDate, endDate) => {
  const daysBetween = eachDayOfInterval({ start: startDate, end: endDate });
  const correctPlanning = [];

  daysBetween.forEach(day => {
    const dayPlanning = planning?.[formatDateForSchedule(day)];
    if (dayPlanning) {
      const { [identifiers.me]: planningMe, ...planningWithoutMe } = dayPlanning;
      if (Object.keys(planningWithoutMe).length > 0) {
        Object.keys(planningWithoutMe).forEach((key) => {
          const value = planningWithoutMe[key];
          const found = correctPlanning.find(planning => planning.employeeId === key);
          if (found) found.planning.push(value);
          else correctPlanning.push({ planning: [value], employeeId: key, employeeFirstName: value.employeeFirstName, employeeLastName: value.employeeLastName, profilePictureUrl: value.profilePictureUrl });
        });
      }
    }
  });

  return correctPlanning;
};

export const isPlanningLoading = (planning, startDate, endDate, employeeId) => {
  const daysBetween = eachDayOfInterval({ start: startDate, end: endDate });
  return !daysBetween.every(day => {
    return !!planning?.[formatDateForSchedule(day)]?.[employeeId];
  });
};

export const isFullPlanningLoading = (planning, startDate, endDate) => {
  const daysBetween = eachDayOfInterval({ start: startDate, end: endDate });
  return !daysBetween.every(day => {
    const dayPlanning = planning?.[formatDateForSchedule(day)];
    if (dayPlanning) {
      const { [identifiers.me]: planningMe, ...planningWithoutMe } = dayPlanning;
      return Object.keys(planningWithoutMe).length > 0;
    }
    return false;
  });
};

export const formatDateForSchedule = (originalDate: Date | string): string => {
  if (dateUtil) return dateUtil.format(originalDate, 'd/M/yyyy');
  return format(date.parseDate(originalDate), 'd/M/yyyy');
};

export const setDateUtil = util => dateUtil = util;

export const getFirstDayOfFullWeek = (weekStartsOn: WeekStartsOn = 1, startFrom = new Date()) => {
  const startDayOfWeek = startOfWeek(startFrom, { weekStartsOn });
  return isPast(startDayOfWeek) ? addWeeks(startDayOfWeek, 1) : startDayOfWeek;
};

export const getLastDayOfFullWeek = (weekStartsOn: WeekStartsOn = 1, startFrom = new Date()) => {
  const startOfWeek = getFirstDayOfFullWeek(weekStartsOn, startFrom);
  return endOfWeek(startOfWeek, { weekStartsOn });
};

export const getUntilValue = (type: Period, fromDate: { date: Date }, untilDate: { date: Date }, weekStartsOn: WeekStartsOn = 1): { date: Date } => {
  const after = isAfter(date.parseDate(fromDate.date), date.parseDate(untilDate.date));
  switch (type) {
    case Period.AvailabilityUntil:
      return after ? { date: getLastDayOfFullWeek(weekStartsOn, fromDate.date) } : untilDate;
    case Period.VacationUntil:
      return after ? { date: addDays(fromDate.date, 1) } : untilDate;
    default:
      return untilDate;
  }
};

export const parseDateFromNotification = (date) => parse(date, 'dd-MM-yyyy', new Date());

export const filterVacationFromDate = () => (fromDate: Date) => {
  return fromDate > today;
};

export const filterVacationUntilDate = (fromDate?: Date) => (toDate: Date) => {
  const startDate = fromDate || today;
  const maxDate = addYears(startDate, 1);
  return toDate <= maxDate && toDate > startDate;
};

export const filterAvailabilityFromDate = (weekStartsOn: WeekStartsOn) => (fromDate: Date) => {
  return fromDate >= today && getDay(fromDate) === weekStartsOn;
};

export const filterAvailabilityUntilDate = (weekStartsOn: WeekStartsOn, fromDate?: Date) => (toDate: Date) => {
  const endDayOfWeek = getDay(endOfWeek(today, { weekStartsOn }));
  const startDate = fromDate || subYears(today, 1);
  const marker = fromDate || today;
  const daysAdded = addYears(marker, 1);

  return toDate <= daysAdded && toDate > startDate && getDay(toDate) === endDayOfWeek;
};

export const filterShifts = (item: IDayPlanning): IDayPlanning => ({
  ...item,
  shifts: item.shifts.filter(shift => isSameDay(parseISO(shift.startDate), parseISO(item.date))),
});

export const filterOverlaps = (planning: IDayPlanning[]) => {
  return planning.reduce((accu, item) => {
    return [...accu, filterShifts(item)];
  }, []);
};

export const getDayShiftsRange = (dateArray: string[], planning: IPlanning) => {
  return dateArray.map((date) => {
    const day = planning[formatDateForSchedule(date)]?.[identifiers.me];
    if (day.shifts?.length) {
      return { startDate: day.shifts[0]?.startDate, endDate: day.shifts[day.shifts.length - 1]?.endDate };
    }
    return { startDate: dateUtil.format(startOfDay(parseISO(date)), 'yyyy-MM-dd\'T\'HH:mm:ss'), endDate: dateUtil.format(endOfDay(parseISO(date)), 'yyyy-MM-dd\'T\'HH:mm:ss') };
  });
};