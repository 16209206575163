export enum ActionType {
  RequestForgotPassword = 'REQUEST_FORGOT_PASSWORD',
  RequestForgotPasswordRejected = 'REQUEST_FORGOT_PASSWORD_REJECTED',
  RequestForgotPasswordFulfilled = 'REQUEST_FORGOT_PASSWORD_FULFILLED',

  ResetPassword = 'RESET_PASSWORD',
  ResetPasswordRejected = 'RESET_PASSWORD_REJECTED',
  ResetPasswordFulfilled = 'RESET_PASSWORD_FULFILLED',
}

export default ActionType;
