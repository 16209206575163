import { createSelector } from 'reselect';
import { AppState } from '../rootReducer';
import { NewsState } from './reducer';
import { INewsItemAdmin } from '../../models';

const selectNode = (state: AppState) => state.news;

export const getAllNews = createSelector(
  selectNode,
  (state: NewsState) => state.all,
);

export const getNewsDetails =
  (state: AppState, id?: string) => {
    const details = state.news.details;
    if (!id) return details;
    if (details && details.id === id) return details;
    return state.news.all.find(news => news.id === id);
  };

export const getNewsDetailsAdmin = createSelector(
  selectNode,
  (state: NewsState) => state.details as INewsItemAdmin,
);

export const getNewsItem = (state: AppState, id: string) => state.news.all.find((item) => item.id === id);

export const isLoading = createSelector(
  selectNode,
  (state: NewsState) => state.isLoading,
);

export const isSaving = createSelector(
  selectNode,
  (state: NewsState) => state.isSaving,
);

export const isDeleting = createSelector(
  selectNode,
  (state: NewsState) => state.isDeleting,
);

export const metadata = createSelector(
  selectNode,
  (state: NewsState) => state.metadata,
);

export const totalCount = createSelector(
  selectNode,
  (state: NewsState) => state.metadata?.totalCount,
);