import { Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { exhaustMap, map, catchError } from 'rxjs/operators';

import ActionType from './types';
import { timeRegistrationService } from '../../services';
import {
  GetTimeRegistrationsFulfilledAction,
  GetTimeRegistrationsRejectedAction,
  GetActiveTimeRegistrationsAndTypesFulfilledAction,
  GetActiveTimeRegistrationsAndTypesRejectedAction,
  ClockFulfilledAction,
  ClockRejectedAction,
  ClockAction,
  CheckRegisteredDeviceAction,
  CheckRegisteredDeviceFulfilledAction,
  RegisteredDeviceAction,
  RegisteredDeviceFulfilledAction,
  RegisteredDeviceRejectedAction,
  GetDoubleTimeRegistrationsFulfilledAction,
  GetDoubleTimeRegistrationsRejectedAction,
  GetDoubleTimeRegistrationsAction,
} from './actions';

export const getTimeRegistrationsEpic$: Epic = (action$) =>
  action$.ofType(ActionType.GetTimeRegistrations).pipe(
    exhaustMap(() =>
      from(timeRegistrationService.getTimeRegistrations()).pipe(
        map(response => new GetTimeRegistrationsFulfilledAction(response)),
        catchError(error => of(new GetTimeRegistrationsRejectedAction({ error })))
      )
    ),
  );

export const getDoubleTimeRegistrationsEpic$: Epic = (action$) =>
  action$.ofType(ActionType.GetDoubleTimeRegistrations).pipe(
    exhaustMap(({ payload }: GetDoubleTimeRegistrationsAction) =>
      from(timeRegistrationService.getDoubleTimeRegistrations(payload.departmentId, payload.startDate, payload.endDate)).pipe(
        map(response => new GetDoubleTimeRegistrationsFulfilledAction(response)),
        catchError(error => of(new GetDoubleTimeRegistrationsRejectedAction({ error })))
      )
    ),
  );

export const getActiveTimeRegistrationsAndTypesEpic$: Epic = (action$, state$) =>
  action$.ofType(ActionType.GetActiveTimeRegistrationsAndTypes).pipe(
    exhaustMap(() =>
      from(timeRegistrationService.getActiveTimeRegistrationsAndTypes(state$.value.user?.userDetails?.departmentId)).pipe(
        map(response => new GetActiveTimeRegistrationsAndTypesFulfilledAction(response)),
        catchError(error => of(new GetActiveTimeRegistrationsAndTypesRejectedAction({ error })))
      )
    ),
  );

export const clockEpic$: Epic = (action$) =>
  action$.ofType(ActionType.Clock).pipe(
    exhaustMap(({ payload }: ClockAction) => {
      return from(timeRegistrationService.clock(payload.type, payload.typeId, payload.validators, payload.clockAnyway)).pipe(
        map((result) => {
          payload.onSuccess?.(result.data);
          return new ClockFulfilledAction(result);
        }),
        catchError(error => {
          payload.onError?.(error);
          return of(new ClockRejectedAction({ error }));
        })
      );
    }
    ),
  );

export const checkRegisteredDevice$: Epic = (action$) =>
  action$.ofType(ActionType.CheckRegisteredDevice).pipe(
    exhaustMap(({ payload }: CheckRegisteredDeviceAction) =>
      from(timeRegistrationService.checkRegisteredDevice(payload.deviceIdentifier)).pipe(
        map(response => new CheckRegisteredDeviceFulfilledAction(response))
      )
    ),
  );

export const registeredDevice$: Epic = (action$) =>
  action$.ofType(ActionType.RegisteredDevice).pipe(
    exhaustMap(({ payload }: RegisteredDeviceAction) =>
      from(timeRegistrationService.registerDevice(payload.deviceIdentifier, payload.code, payload.deviceName)).pipe(
        map(() => {
          payload.onSuccess?.();
          return new RegisteredDeviceFulfilledAction();
        }),
        catchError(error => {
          payload.onError?.(error);
          return of(new RegisteredDeviceRejectedAction({ error }));
        })
      )
    ),
  );

const epics = [
  getTimeRegistrationsEpic$,
  getActiveTimeRegistrationsAndTypesEpic$,
  clockEpic$,
  checkRegisteredDevice$,
  registeredDevice$,
  getDoubleTimeRegistrationsEpic$,
];

export default epics;
