import { PureComponent } from 'react';
import PropTypes from 'prop-types';

class VacationWidget extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this._mounted = true;
    this.props.getVacationCounter(this.onSuccess, this.onError);
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  onSuccess = () => {
    if (this._mounted) {
      this.setState({ isLoading: false });
    }
  }

  onError = () => {
    if (this._mounted) {
      this.setState({ isLoading: false });
    }
  }

  getValues = () => {
    let totalTime = 0;
    const valuesWithConvertedMinutes = this.props.vacation.reduce((values, item) => {
      if (!item.value) return values;
      const { hours, minutes } = item.value;
      const minutesInPercentage = parseInt(minutes) / 60;
      const hoursWithMinutes = parseInt(hours) + minutesInPercentage;
      totalTime += hoursWithMinutes;
      values.push(hoursWithMinutes);
      return values;
    }, []);

    return valuesWithConvertedMinutes.map(value => ((value / (totalTime || 1)) * 100));
  }

  getTotal = () => {
    const [hours, minutes] = this.props.vacation.reduce((total, item) => {
      return item.value ? [total[0] + parseInt(item.value.hours), total[1] + parseInt(item.value.minutes)] : total;
    }, [0, 0]);
    return {
      hours: hours + Math.floor(minutes / 60),
      minutes: minutes % 60,
    };
  }

  render() {
    return null;
  }
}

VacationWidget.propTypes = {
  vacation: PropTypes.array.isRequired,
  getVacationCounter: PropTypes.func.isRequired,
};

export default VacationWidget;
