import React from 'react';
import { Modal } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useToggle } from '../../../../hooks';
import { IShift } from '../../../../models';
import { shiftSwapActions, shiftSwapSelectors } from '../../../../redux';
import { translations } from '../../../../utils';
import Shift from './Shift';

import './shiftOfferModals.scss';

const CreateShiftSwapRequestModal = () => {
  const showShiftOfferModal = useSelector(shiftSwapSelectors.showShiftSwapModal);
  const isLoading = useSelector(shiftSwapSelectors.isCreateRequestLoading);
  const shifts = useSelector(shiftSwapSelectors.getShifts);
  const [hasCounterSwap, toggleHasCounterSwap] = useToggle(true);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(new shiftSwapActions.ToggleshiftSwapModalAction());
    toggleHasCounterSwap(true);
  };

  const createShiftSwapRequest = () => {
    dispatch(new shiftSwapActions.CreateShiftSwapRequestAction({
      hasCounterSwap,
      date: shifts[0].startDate?.substr(0, 10),
      onSuccess: () => {
        toast(translations.getLabel('lblShiftSwapRequestCreated'));
        closeModal();
      },
      onError: (error) => {
        toast.error(typeof error.detail === 'string' ? error.detail : error.title);
      },
    }));
  };

  return (
    <Modal
      title={translations.getLabel('titleCreateShiftSwapRequest')}
      open={showShiftOfferModal}
      requestClose={closeModal}
      leftButtonProps={{
        label: translations.getLabel('cancel'),
        onClick: closeModal,
      }}
      rightButtonProps={{
        label: translations.getLabel('btnPublishShiftSwap'),
        onClick: createShiftSwapRequest,
        isLoading,
      }}
    >
      <div className="create-shift-swap-modal">
        <section className="shifts">
          {
            shifts?.map((shift: IShift) => <Shift key={shift.id} shift={shift} />)
          }
        </section>
        <div className="separator" />
        <label className="input-group">
          <input type="radio" checked={hasCounterSwap} onChange={toggleHasCounterSwap} />
          {translations.getLabel('lblWithCounterSwap')}
        </label>
        <label className="input-group">
          <input type="radio" checked={!hasCounterSwap} onChange={toggleHasCounterSwap} />
          {translations.getLabel('lblWithoutCounterSwap')}
        </label>
      </div>
    </Modal>
  );
};

export default CreateShiftSwapRequestModal;
