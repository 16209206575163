import React, { PureComponent } from 'react';
import { Button, FileUpload, IconButton, Modal } from 'react-ess-components';
import { Icon } from 'react-ess-components';
import { Prompt } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isSameDay } from 'date-fns';
import PropTypes from 'prop-types';

import { chatTypes } from '../../../../constants';
import { UserRight } from '../../../../models';
import { chatActions } from '../../../../redux';
import { date, fileUploadUtils, notificationUtils, translations } from '../../../../utils';
import ChatMessage from '../chatMessage/ChatMessage';
import ChatParticipants from '../chatParticipants/ChatParticipants';

import './chatConversation.scss';

const ATTACHMENT_LOADING_ID = 'attachment-loader';

class ChatConversation extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      chatMessage: '',
      hasAttachment: false,
      isAttachmentModalVisible: false,
      isDeleteModalVisible: false,
      isFileUploadError: false,
      isLoading: false,
      isRemovingConversation: false,
      messages: [],
      participantModalOpen: false,
    };
    this.file = null;
    this.scrollContainer = null;
    this.getUnreadInterval = null;
    this.input = null;
  }

  componentDidMount() {
    // Poll for unread chats.
    this.getUnreadInterval = setInterval(() => {
      this.props.dispatch(new chatActions.GetUnreadCountAction());
    }, chatTypes.POLL_UNREAD_CHAT_SCREEN_TIMER);

    if (this.props.id && !this.props.conversation.read) {
      this.props.dispatch(new chatActions.MarkAsReadAction({ id: this.props.id }));
    }

    if (this.scrollContainer) {
      this.scrollContainer.scrollTop = this.scrollContainer.scrollHeight;
    }
  }

  componentWillUnmount() {
    if (this.getUnreadInterval) clearInterval(this.getUnreadInterval);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      if (this.input) {
        this.input.focus();
      }
      this.setState({ chatMessage: '', hasAttachment: false });
      this.updateMessageState();
    }
    if (prevProps.conversation.messages && this.props.conversation.messages && prevProps.conversation.messages.length !== this.props.conversation.messages.length) {
      this.updateMessageState();
    } else if (this.props.id && !this.props.conversation.read) {
      this.props.dispatch(new chatActions.MarkAsReadAction({ id: this.props.id }));
    }
  }

  updateMessageState = () => {
    this.setState({ messages: this.enrichChatMessages(this.props.conversation.messages) }, () => {
      if (this.props.id) this.props.dispatch(new chatActions.MarkAsReadAction({ id: this.props.id }));
      if (this.scrollContainer) {
        this.scrollContainer.scrollTop = this.scrollContainer.scrollHeight;
      }
    });
  }

  toggleAttachmentModal = () => {
    this.setState({
      isAttachmentModalVisible: !this.state.isAttachmentModalVisible,
      isFileUploadError: false,
    });
  }

  toggleDeleteConversationModal = () => this.setState({ isDeleteModalVisible: !this.state.isDeleteModalVisible })

  handleUpload = (file) => {
    if (file.size > chatTypes.MAX_FILE_SIZE) {
      this.setState({ isFileUploadError: true, fileUploadErrorLabel: translations.getLabel('lblErrorFileUploadSize', { maxFileSize: `${chatTypes.MAX_FILE_SIZE / 1000000}MB` }) });
    } else {
      this.file = file;
      this.setState({ chatMessage: file.name, hasAttachment: true, file, isAttachmentModalVisible: false, isFileUploadError: false });
    }
  }

  addAttachment = () => this.toggleAttachmentModal();

  sendMessage = async () => {
    if (this.state.hasAttachment) {
      this.setState({
        isLoading: true, messages: [...this.state.messages, {
          id: ATTACHMENT_LOADING_ID,
          senderId: this.props.employmentId,
          createdAt: new Date().toISOString(),
          attachment: {
            isLoading: true,
            name: this.file.name,
          },
        },
        ],
      });
      this.props.dispatch(new chatActions.GetPresignedUrlAction({
        roomId: this.props.conversation.roomId,
        contentType: this.file.type,
        conversationId: this.props.id,
        onSuccess: (uploadUrl) => {
          if (uploadUrl) {
            fileUploadUtils.uploadFileToPresignedUrl(uploadUrl, this.file)
              .then(() => {
                const attachment = {
                  name: this.file.name,
                  extension: this.file.type,
                  storageId: this.props.fileStorageId,
                };
                // Second parameter null because we only send the attachment over the socket
                this.props.sendMessage(this.props.conversation.roomId, null, attachment);
                this.file = null;
                this.setState({ isLoading: false, messages: this.state.messages.filter(m => m.id !== ATTACHMENT_LOADING_ID) });
              })
              .catch(() => {
                this.file = null;
                toast.error(translations.getLabel('lblErrorFileUpload'));
                this.setState({ isLoading: false });
              });
          } else {
            toast.error(translations.getLabel('lblErrorFileUpload'));
          }
        },
      }));
    } else if (this.state.chatMessage && this.state.chatMessage.trim()) {
      this.props.sendMessage(this.props.conversation.roomId, this.state.chatMessage);
    }
    // Reset inputField
    const parentDiv = this.input?.parentElement;
    if (parentDiv) {
      parentDiv.style.height = '4rem';
      this.input.style.height = '2rem';
    }
    this.setState({ chatMessage: '', hasAttachment: false });
  }

  addContact = () => { }

  removeConversation = async () => {
    this.setState({ isRemovingConversation: true });
    this.props.dispatch(new chatActions.LeaveConversationAction({
      id: this.props.id,
      onSuccess: () => {
        notificationUtils.changeIdInUrl('');
        this.props.onLeaveConversation();

        this.setState({ isDeleteModalVisible: false, isRemovingConversation: false });
      },
      onError: (error) => {
        toast.error(error.detail);
        this.setState({ isRemovingConversation: false });
      },
    }));
  }

  enrichChatMessages = (chatMessages) => {
    if (!this.props.conversation.messages) return [];
    // We start with an early date, so the first message will sent after this date for sure.
    let prevDay = new Date('01/01/2000');
    const messages = chatMessages.reduce((accu, message) => {
      if (!isSameDay(date.parseDate(message.createdAt), date.parseDate(prevDay))) {
        const relativeDate = date.toRelativeDate(date.format, date.parseDate(message.createdAt));
        accu.push({ id: message.createdAt, statusMessage: relativeDate.shouldTranslate ? translations.getLabel(relativeDate.value) : relativeDate.value, isStatusMessage: true });
        prevDay = message.createdAt;
      }
      accu.push(message);
      return accu;
    }, []);

    return messages;
  }

  onChangeText = evt => this.setState({ chatMessage: evt.target.value, hasAttachment: false });

  submit = (e) => {
    e.preventDefault();
    this.sendMessage();
  }

  toggleParticipantModal = () => this.setState({ participantModalOpen: !this.state.participantModalOpen })

  setRef = (ref) => this.scrollContainer = ref;

  setInputRef = (ref) => this.input = ref;

  expandTextArea = () => {
    // https://stackoverflow.com/a/24676492
    this.input.style.height = '5px';
    this.input.style.height = (this.input.scrollHeight) + 'px';

    const parentDiv = this.input.parentElement;
    parentDiv.style.height = '5px';
    parentDiv.style.height = (this.input.scrollHeight) + 'px';
  }

  removeAttachment = () => {
    this.file = null;
    this.setState({ chatMessage: '', hasAttachment: false });
  }

  renderAttachmentAsInput = () =>
    <div className="attachment-container">
      <div className="attachment">
        <span>{this.state.chatMessage}</span>
        <Icon tag="CloseIcon" color="primary" customSize={2} onClick={this.removeAttachment} />
      </div>
    </div>

  renderMessage = (message) => {
    return <ChatMessage key={message.id} conversationId={this.props.id} chatMessage={message} participants={this.props.conversation.participants} userId={this.props.userId} />;
  }

  renderEmptyView = () => {
    return <section className="chat-conversation chat-conversation-empty">{translations.getLabel('lblEmptyChatConversation')}</section>;
  }

  render() {
    if (!this.props.id || !this.props.conversation) {
      return this.renderEmptyView();
    }
    const isBlocking = this.state.chatMessage !== '';
    const isDepartmentChat = this.props.conversation.type === chatTypes.COMPANY;
    return (
      <div className="chat-conversation">
        <Prompt message={translations.getLabel('lblLeaveChat')} when={isBlocking} />
        <section className="chat-conversation-header">
          <Button theme="transparent" onClick={this.toggleParticipantModal}><Icon tag="InfoIcon" title={translations.getLabel('lblConversationInfo')} /></Button>
          {(!isDepartmentChat || (this.props.hasRight(UserRight.Chat) && isDepartmentChat)) && <Button theme="transparent" className="button-remove" onClick={this.toggleDeleteConversationModal}><Icon tag="TrashIcon" title={translations.getLabel('delete')} /></Button>}
        </section>
        <React.Fragment>
          <section className="chat-conversation-content" ref={this.setRef}>
            {this.state.messages.map(this.renderMessage)}
          </section>

          <section className="chat-conversation-footer">
            <IconButton tag="DocumentIcon" small onClick={this.addAttachment} title={translations.getLabel('titleAddAttachment')} disabled={this.state.isLoading} />
            <form className="chat-input-container" onSubmit={this.submit}>
              {!this.state.hasAttachment &&
                <textarea
                  ref={this.setInputRef}
                  value={this.state.chatMessage}
                  onInput={this.expandTextArea}
                  onChange={this.onChangeText}
                  onKeyDown={this.handleKeyDown}
                  type="text"
                  className="chat-input"
                  placeholder={translations.getLabel('lblWriteChatMessage')}
                  title={translations.getLabel('lblWriteChatMessage')}
                />
              }
              {this.state.hasAttachment && this.renderAttachmentAsInput()}
              <IconButton tag="ArrowRight" small type="submit" title={translations.getLabel('btnSend')} />
            </form>
          </section>
          <FileUpload
            acceptFileTypes="image/png, image/jpeg, application/pdf"
            translationLabels={{
              lblTitle: translations.getLabel('titleAddAttachment'),
              lblDrag: translations.getLabel('lblDragAttachment'),
              lblBtn: translations.getLabel('btnChooseAttachment'),
              lblError: this.state.fileUploadErrorLabel,
            }}
            isLoading={false}
            showPreview={false}
            handleUpload={this.handleUpload}
            isVisible={this.state.isAttachmentModalVisible}
            toggleModal={this.toggleAttachmentModal}
            isError={this.state.isFileUploadError}
          />
          <ChatParticipants participants={this.props.conversation.participants} visible={this.state.participantModalOpen} handleClose={this.toggleParticipantModal} />
        </React.Fragment>

        <Modal
          title={translations.getLabel('btnRemoveChat')}
          requestClose={this.toggleDeleteConversationModal}
          leftButtonProps={{
            label: translations.getLabel('cancel'),
            onClick: this.toggleDeleteConversationModal,
          }}
          rightButtonProps={{
            label: translations.getLabel('lblDelete'),
            onClick: this.removeConversation,
            isLoading: this.state.isRemovingConversation,
          }}
          open={this.state.isDeleteModalVisible}>
          <p className="remove-conversation-message">{translations.getLabel('lblConfirmationDeleteConversation')}</p>
        </Modal>
      </div >
    );
  }
}

ChatConversation.propTypes = {
  id: PropTypes.string,
  userId: PropTypes.string.isRequired,
  employmentId: PropTypes.string.isRequired,
  holdingId: PropTypes.string.isRequired,
  fileStorageId: PropTypes.string.isRequired,
  conversation: PropTypes.object.isRequired,
  onLeaveConversation: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  hasRight: PropTypes.func.isRequired,
};

ChatConversation.defaultProps = {
  id: '',
};

export default ChatConversation;
