import ScheduleToday from './ScheduleToday.base';
import { userSelectors, scheduleSelectors } from '../../redux';
import { scheduleUtils } from '../../utils';
import { identifiers } from '../../constants';

export const mapStateToProps = state => ({
  schedule: scheduleUtils.getInPlanning(state.schedule.planning, new Date(), new Date(), identifiers.me),
  isLoading: scheduleUtils.isPlanningLoading(state.schedule.planning, new Date(), new Date(), identifiers.me),
  hasPlanningTodayError: scheduleSelectors.hasPlanningTodayError(state),
  user: userSelectors.getUser(state),
});

export const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default ScheduleToday;
