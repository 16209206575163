import React, { FC } from 'react';
import { parseISO } from 'date-fns';

import { StatusIcon } from '../../../../components';
import { IShift, ShiftOfferApplicantStatus, ShiftSwapStatus } from '../../../../models';
import { date } from '../../../../utils';

interface Props {
  shift: IShift;
  status?: ShiftOfferApplicantStatus | ShiftSwapStatus;
}

const shiftOfferShift: FC<Props> = ({ shift, status }) => {
  const startDate = parseISO(shift.startDate);
  const endDate = parseISO(shift.endDate);

  return (
    <div className="shift">
      <h3>{shift.shiftName}</h3>
      {!!status && <span className="shift-status-container"><StatusIcon status={status} /></span>}
      <div className="timestamp">{` ${date.formatFromUntilTime(date.format, { startDate, endDate })}`}</div>
    </div >
  );
};

export default shiftOfferShift;

