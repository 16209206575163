import React, { FC, useEffect } from 'react';
import { Button, Icon } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { appleCalendar,GoogleCalendar, MicrosoftCalendar } from '../../../assets/images';
import { configActions,configSelectors, userActions, userSelectors } from '../../../redux';
import { translations } from '../../../utils';
import Widget from '../../widget/Widget';

import './SynchronizeCalendar.scss';

const SynchronizeCalendar: FC = () => {
  const dispatch = useDispatch();
  const icsUrl = useSelector(userSelectors.getIcsUrl);
  const isIcsUrlLoading = useSelector(userSelectors.isIcsUrlLoading);
  const calendarLinks = useSelector(configSelectors.getCalendarLinks);

  useEffect(() => {
    dispatch(new userActions.GetIcsUrlAction());
    dispatch(new configActions.GetCalendarLinksAction());
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(icsUrl);
    toast(translations.getLabel('lblCopyToClipboard'));
  };

  return (
    <Widget title={translations.getLabel('titleSynchronizeCalendar')} right>
      <div className="synchronise-calendar-container">
        <p className="personal-calendar-label">{translations.getLabel('lblWorkHoursPersonalCalendar')}</p>
        <p>{translations.getLabel('lblClickCalender')}</p>
        <div className="calendar-links">
          <a target="_blank" rel="noopener noreferrer" href={calendarLinks?.google}><GoogleCalendar /></a>
          <a target="_blank" rel="noopener noreferrer" href={calendarLinks?.iCal}><img className="apple-calendar" src={appleCalendar} alt="appleCalendar" /></a>
          <a target="_blank" rel="noopener noreferrer" href={calendarLinks?.outlook}><MicrosoftCalendar /></a>
        </div>
        <p>{translations.getLabel('lblCopySynchronisationLink')}</p>
        {isIcsUrlLoading && <div className="synchronisation-link"><Skeleton height={19} width={312} /></div>}

        {!!icsUrl && (
          <Button theme="transparent" className="synchronisation-link" onClick={handleCopy}>
            <p>{icsUrl}</p>
            <Icon tag="CopyIcon" />
          </Button>
        )}
        {!icsUrl && !isIcsUrlLoading && <p className="ics-error">{translations.getLabel('lblErrorICSLink')}</p>}
      </div>
    </Widget >
  );
};

// export { SynchronizeCalendar };
export default SynchronizeCalendar;
