import { produce } from 'immer';

import ActionType from './types';
import { IDocument } from '../../models';
import { Actions } from './actions';

export interface DocumentsState {
  all: IDocument[];
  hasUploadError: boolean;
  isLoading: boolean;
};

export const initialState = {
  all: [],
  hasUploadError: false,
  isLoading: false,
};

const documents = produce((draft: DocumentsState, action: Actions) => {
  switch (action.type) {
    case ActionType.GetDocuments:
    case ActionType.UpdateDocument:
    case ActionType.UploadDocument:
      draft.hasUploadError = false;
      draft.isLoading = true;
      break;

    case ActionType.GetDocumentsFulfilled:
      draft.all = action.payload.data;
      draft.hasUploadError = false;
      draft.isLoading = false;
      break;

    case ActionType.DeleteDocumentFulfilled:
    case ActionType.UpdateDocumentFulfilled:
    case ActionType.UploadDocumentFulfilled:
      draft.hasUploadError = false;
      draft.isLoading = false;
      break;

    case ActionType.GetDocumentsRejected:
    case ActionType.UpdateDocumentRejected:
      draft.isLoading = false;
      break;

    case ActionType.UploadDocumentRejected:
      draft.hasUploadError = true;
      break;
  }
}, initialState);

export default documents;
