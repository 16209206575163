import { translations } from '../utils';

export const getAccessibilityLabels = () => ({
  checked: translations.getLabel('lblChecked'),
  close: translations.getLabel('close'),
  remove: translations.getLabel('lblRemove'),
  unchecked: translations.getLabel('lblUnChecked'),
  clear: translations.getLabel('lblClear'),
  collapse: translations.getLabel('lblCollapse'),
  expand: translations.getLabel('lblExpand'),
  search: translations.getLabel('lblSearch'),
});