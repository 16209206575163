import React, { FC, useEffect, useRef, useState } from 'react';
import { Icon, SelectPopup } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import lottie, { AnimationItem } from 'lottie-web';

import { ANIMATIONS } from '../../assets';
import { useClock, useToggle } from '../../hooks';
import { ClockType, ITimeRegistrationType, RegistrationType, ValidationType } from '../../models';
import { timeRegistrationActions, timeRegistrationSelectors } from '../../redux';
import { timeRegistration, translations } from '../../utils';

import './clockInButton.scss';

type Props = {
  onStartClockIn: (id: string, type: string) => void;
  id?: string;
  isFirst?: boolean;
  isLast?: boolean;
  hidden?: boolean;
  types: ITimeRegistrationType[];
  onClockedOut?: (id: string) => void;
  isInitClockedIn: boolean;
  noTypesLeft: boolean;
  typeId: string;
  name: string;
  initSeconds: number;
}

const ClockInButton: FC<Props> = ({ onStartClockIn, name, isInitClockedIn, typeId, types, id, isFirst, isLast, hidden, onClockedOut, initSeconds, noTypesLeft }) => {
  const dispatch = useDispatch();
  const timeRegistrationTypes = useSelector(timeRegistrationSelectors.timeRegistrationTypes);
  const button = useRef<HTMLButtonElement>(null);
  const typeButton = useRef<HTMLButtonElement>(null);
  const [isAppeared, setIsAppeared] = useState(false);
  const [isPressing, toggleIsPressing] = useToggle(false);
  const [showType, toggleShowType] = useToggle(false);
  const [registrationType, setRegistrationType] = useState(typeId || types[0]?.id);
  const [animation, setAnimation] = useState<AnimationItem>(null);
  const [isClockInLoading, toggleIsClockInLoading] = useToggle(false);
  const [isClockOutLoading, toggleIsClockOutLoading] = useToggle(false);
  const [isClockingTransition, toggleIsClockingTransition] = useToggle(false);
  const [isClocking, toggleIsClocking] = useToggle(isInitClockedIn);
  const { seconds, startClock, pauseClock } = useClock(initSeconds);

  useEffect(() => {
    setTimeout(() => {
      setIsAppeared(true);
    }, isFirst ? 0 : 1500);
    if (isInitClockedIn) startClock();
    return pauseClock;
  }, []);

  useEffect(() => {
    if (!isInitClockedIn && !isClocking) setRegistrationType(types[0]?.id);
  }, [types]);

  useEffect(() => {
    if (typeId) setRegistrationType(typeId);
  }, [typeId]);

  useEffect(() => {
    const _animation = lottie.loadAnimation({
      container: button.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: ANIMATIONS.clockIn,
    });
    if (isInitClockedIn) _animation.goToAndStop(113, true);
    setAnimation(_animation);
  }, [button]);

  useEffect(() => {
    if (animation) {
      animation.addEventListener('complete', startClocking);
      return () => animation.removeEventListener('complete', startClocking);
    }
  }, [animation, isPressing, isClocking, isClockInLoading, isClockingTransition, isClockOutLoading]);

  const startClocking = () => {
    if (isPressing && !isClocking) {
      toggleIsPressing();
      initiateClockIn();
      animation.playSegments([30, 90], true);
    } else if (isClockInLoading) {
      animation.playSegments([60, 90], true);
      toggleIsPressing();
    } else if (isClockingTransition) {
      toggleIsClockingTransition();
      toggleIsClocking();
      animation.playSegments([90, 113], true);
    } else if (isPressing && isClocking) {
      toggleIsPressing();
      initiateClockOut();
      animation.playSegments([164, 204], true);
    } else if (isClockOutLoading) {
      animation.playSegments([174, 204], true);
    }
  };

  const initiateClockIn = async () => {
    toggleIsClockInLoading();
    const deviceIdentifier = await timeRegistration.getDeviceId();
    dispatch(new timeRegistrationActions.ClockAction({
      id,
      type: ClockType.Start,
      typeId: registrationType,
      validators: [
        {
          validationType: ValidationType.DeviceId,
          validationValue: deviceIdentifier,
        },
      ],
      onSuccess: () => {
        setTimeout(() => {
          toggleIsClockInLoading();
          toggleIsClockingTransition();
          startClock();
          onStartClockIn(id, registrationType);
        }, 1000);
      },
      onError: (error) => {
        if (typeof error.detail === 'string') {
          toast.error(error.detail);
        } else {
          toast.error(translations.getLabel('lblGenericError'));
        }
      },
    }));
  };

  const initiateClockOut = async () => {
    toggleIsClockOutLoading(true);
    const deviceIdentifier = await timeRegistration.getDeviceId();
    dispatch(new timeRegistrationActions.ClockAction({
      id,
      type: ClockType.End,
      typeId: registrationType,
      validators: [
        {
          validationType: ValidationType.DeviceId,
          validationValue: deviceIdentifier,
        },
      ],
      onSuccess: () => {
        pauseClock();
        onClockedOut(id);
      },
      onError: (error) => {
        if (typeof error.detail === 'string') {
          toast.error(error.detail);
        } else {
          toast.error(translations.getLabel('lblGenericError'));
        }
      },
    }));
  };


  const onMouseEvent = () => {
    if (!isClockInLoading && !isClockOutLoading && !isClockingTransition) {
      if (!isClocking) {
        if (isPressing) {
          animation.playSegments([animation.currentFrame, 0], true);
        } else {
          animation.playSegments([animation.currentFrame, 30], true);
        }
        toggleIsPressing();
      } else if (isClocking) {
        let frameOffset = animation.currentFrame;
        if (animation.firstFrame !== 113) {
          frameOffset = 0;
        }
        if (isPressing) {
          animation.playSegments([113 + frameOffset, 113], true);
        } else {
          animation.playSegments([113 + frameOffset, 145], true);
        }
        toggleIsPressing();
      }
    }
  };

  const getTime = (s) => {
    const sec = s % 60;
    const min = Math.floor((s % 3600) / 60);
    const hours = Math.floor(s / 3600);

    return (<>
      {`${hours.toString().padStart(2, '0')}${translations.getLabel('lblH')} `}
      {`${min.toString().padStart(2, '0')}${translations.getLabel('lblM')} `}
      {`${sec.toString().padStart(2, '0')}${translations.getLabel('lblS')}`}
    </>
    );
  };

  const onKeyPressed = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      onMouseEvent();
    }
  };

  const onKeyReleased = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      onMouseEvent();
    }
  };

  const isInformative = isClocking && timeRegistrationTypes.find(type => type.id === typeId)?.type === RegistrationType.Informative;
  return (
    <div className={classnames(
      'clock-in-container',
      {
        transition: isClockingTransition,
        clocking: isClocking,
        appeared: isAppeared,
        'top-closed': isClocking && isFirst,
        'bottom-closed': isClocking && isLast,
        'subclocking': (isClockingTransition || isClocking) && !isFirst,
        hidden,
        'informative': isInformative,
      })}>
      <div className="row">
        <div className="header">
          <button aria-label={translations.getLabel('lblSelectClockinType')} ref={typeButton} type="button" onClick={() => isClocking ? null : toggleShowType()} className="type-selector" disabled={types.length < 2 && !isClocking}>
            <Icon color="text" tag="ClockIcon" />
            {name || types.find(t => t.id === registrationType)?.description}
            {types.length === 0 && !registrationType && <span className="italic">{translations.getLabel(noTypesLeft ? 'lblNoTimeRegistrationsTypesLeft' : 'lblNoTimeRegistrationsAvailable')}</span>}
            {types.length > 1 && <Icon color="text" tag={showType ? 'ChevronUpIcon' : 'ChevronDownIcon'} className="chevron" />}
          </button>
          {(types.length > 0 && registrationType) && !isClocking && <SelectPopup
            excludePressArea={typeButton.current}
            requestClose={toggleShowType}
            data={types.map(type => ({ value: type.id, label: type.description }))}
            isSearchEnabled={false}
            popupOpen={showType}
            selected={registrationType}
            onValueSelected={(id: string) => { setRegistrationType(id); toggleShowType(); }}
          />
          }
        </div>
        <div className="button-container">
          <div className="left"></div>
          <div className="center">
            <button
              onKeyDown={onKeyPressed}
              onKeyUp={onKeyReleased}
              onMouseDown={onMouseEvent}
              onMouseUp={onMouseEvent}
              className={classnames('stripped-button button ', { disabled: !isClocking && types.length === 0 })}
              ref={button}
              aria-describedby="access-hint"
              aria-live="assertive"
            >
              <div id="access-hint" aria-hidden="false">
                {(isClockInLoading || isClockOutLoading) && translations.getLabel('loading')}
                {(!isClockInLoading && !isClockOutLoading && isClocking) && translations.getLabel('lblClockOut')}
                {(!isClockInLoading && !isClockOutLoading && !isClocking) && translations.getLabel('lblClockButton')}
              </div>
            </button>
            <div className="labels">
              <p className="button-tip">{translations.getLabel('lblClickAndHold')}</p>
              {!isInformative && <p className="time">{getTime(seconds)}</p>}
            </div>
          </div>
          <div className="right"></div>
        </div>
      </div>
    </div>
  );
};

export default ClockInButton;



