import React, { PureComponent } from 'react';
import { Button, Icon, Popup } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import classnames from 'classnames';
import { addDays, getWeek, isSameDay } from 'date-fns';
import PropTypes from 'prop-types';

import { date, translations } from '../../../../utils';
import AvailabilityShift from '../availabilityShift/AvailabilityShift';
import CopyWeek from '../copyWeek/CopyWeek';
import DragDropDay from './DragDropDay';

class AvailabilitiesWeek extends PureComponent {
  onAddShift = (date) => () => {
    this.props.onAddShift(date);
  }

  onCopyDay = (fromDate, toDate) => () => {
    this.props.onCopyDay(fromDate, toDate);
  }

  setOpenPopup = () => {
    this.props.setOpenPopup(this.props.startDate);
  }

  clearOpenPopup = () => {
    this.props.setOpenPopup();
  }

  getOpenPopup = () => isSameDay(date.parseDate(this.props.openPopup), date.parseDate(this.props.startDate));

  renderShift = (shift, i, shifts, isDisabled = false) => {
    const shiftJS = shift.toObject();
    return <AvailabilityShift
      isDisabled={isDisabled}
      index={i}
      key={shiftJS.id}
      onChange={this.props.onChangeShift}
      onRemove={this.props.onRemoveShift}
      setHasTimeError={this.props.setHasTimeError}
      shift={shiftJS}
      shiftsCount={shifts.size}
      timeTypes={this.props.timeTypes}
      inSchoolUntil={this.props.inSchoolUntil}
    />;
  }

  renderShifts = () => {
    const { availabilities, startDate, validAvailabilityDates } = this.props;
    const days = [];
    for (let i = 0; i < 7; i += 1) {
      const dayDate = addDays(date.parseDate(startDate), i);
      const day = availabilities.find(availability => isSameDay(date.parseDate(availability.get('day')), dayDate));

      const isDisabled = !validAvailabilityDates.find(availability => isSameDay(date.parseDate(availability), dayDate));

      const isOverlapping = this.props.overlappingDates.find((overlap) => isSameDay(date.parseDate(overlap), dayDate));
      const className = classnames('day-header', {
        'overlap': isOverlapping,
      });

      days.push((
        <DragDropDay key={dayDate} disabled={isDisabled} data={{ day: dayDate }} onDrop={this.props.onCopyDay}>
          <p className="drag-drop-label"><Icon tag="CopyIcon" customSize={1.2} /> {translations.getLabel('lblDragDrop')}</p>
          <div className={className}>{date.format(dayDate, 'EEEEEE d MMMM')}</div>
          <div className="day-availabilities">
            {day && day.get('availabilities') && day.get('availabilities').map((shift, i, shifts) => this.renderShift(shift, i, shifts, isDisabled))}
            {(this.props.isLoading && !day) && <Skeleton width={200} height={42} />}
            {!isDisabled && <Button onClick={this.onAddShift(dayDate)}><Icon small tag="PlusIcon" /></Button>}
            {
              day && day.get('availabilities').toArray().length === 0 && !isDisabled &&
              <Button theme="inverse" onClick={this.onCopyDay(addDays(dayDate, -1), dayDate)}><Icon small tag="CopyIcon" /></Button>
            }
          </div>
        </DragDropDay>
      ));
    }
    return days;
  }

  render() {
    const { startDate, weekStartsOn } = this.props;
    const weekNumber = getWeek(startDate, { weekStartsOn, firstWeekContainsDate: 7 });
    return (
      <div className="week" key={weekNumber}>
        <div className="week-header">
          <p>
            {`${translations.getLabel('lblWeek')} ${weekNumber}`}
          </p>
          <Button theme="transparent" onClick={this.setOpenPopup}>
            <Icon tag="CopyIcon" />
          </Button>
        </div>
        <Popup isDropdown open={this.getOpenPopup()} requestClose={this.clearOpenPopup}>
          <CopyWeek
            firstWeekDate={this.props.firstWeekDate}
            lastWeekDate={this.props.lastWeekDate}
            startDate={this.props.startDate}
            close={this.clearOpenPopup}
            copy={this.props.onCopyWeek}
            firstWeek={this.props.firstWeek}
            lastWeek={this.props.lastWeek}
            weekStartsOn={this.props.weekStartsOn}
          />
        </Popup>
        {this.renderShifts()}
      </div>
    );
  }
}

AvailabilitiesWeek.propTypes = {
  availabilities: PropTypes.object.isRequired,
  firstWeek: PropTypes.number.isRequired,
  firstWeekDate: PropTypes.instanceOf(Date).isRequired,
  lastWeekDate: PropTypes.instanceOf(Date).isRequired,
  isLoading: PropTypes.bool.isRequired,
  inSchoolUntil: PropTypes.string.isRequired,
  lastWeek: PropTypes.number.isRequired,
  onAddShift: PropTypes.func.isRequired,
  onChangeShift: PropTypes.func.isRequired,
  onCopyDay: PropTypes.func.isRequired,
  onCopyWeek: PropTypes.func.isRequired,
  onRemoveShift: PropTypes.func.isRequired,
  openPopup: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  overlappingDates: PropTypes.array,
  setHasTimeError: PropTypes.func.isRequired,
  setOpenPopup: PropTypes.func.isRequired,
  startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  timeTypes: PropTypes.array.isRequired,
  validAvailabilityDates: PropTypes.array,
};

AvailabilitiesWeek.defaultProps = {
  openPopup: '',
  overlappingDates: [],
  validAvailabilityDates: [],
};

export default AvailabilitiesWeek;
