export const parseQuery = (query) => {
  const obj = {};
  if (!!query) {
    const properties = query.slice(1).split('&');
    properties.forEach((property) => {
      const splitProperty = property.split('=');
      obj[splitProperty[0]] = splitProperty[1];
    });
    return obj;
  }
  return obj;
};

export const isMobileDevice = () => {
  return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
};
