import React, { FC } from 'react';
import { IconButton } from 'react-ess-components';

import { translations } from '../../utils';

import './toast.scss';

type Props = {
  closeToast: () => void;
}

const CloseButton: FC<Props> = ({ closeToast }) => {
  return <IconButton onClick={closeToast} tag="CloseIcon" title={translations.getLabel('lblClose')} />;
};

export default CloseButton;
