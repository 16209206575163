import React, { FC } from 'react';
import { Avatar, IconButton, Popup, Position, Spinner } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { useToggle } from '../../../../hooks';
import { scheduleSelectors } from '../../../../redux';
import { translations } from '../../../../utils';
import EmployeeDetails from './EmployeeDetails';

interface Props {
  firstName: string;
  employeeId: string;
  lastName: string;
  profilePictureUrl?: string;
  isCollapsed: boolean;
  hasNoSearchResults?: boolean;
  onCollapse: (employeeId: string) => void;
  canCollapse: boolean;
  isLoading: boolean;
}

const EmployeeCell: FC<Props> = ({ firstName, employeeId, lastName, profilePictureUrl, isCollapsed, onCollapse, canCollapse, isLoading, hasNoSearchResults }) => {
  const [isDetailsOpen, setDetailsOpen] = useToggle(false);
  const searchValue = useSelector(scheduleSelectors.getSearchValue);
  const className = classnames('name', { 'with-action': canCollapse });

  if (hasNoSearchResults) return <div className="schedule-user-details no-search-result">{translations.getLabel('lblNoColleaguesFound', { searchValue })}</div>;

  return (
    <div className="schedule-user-details">
      <Popup open={isDetailsOpen} requestClose={setDetailsOpen} position={Position.Left}>
        <EmployeeDetails employeeId={employeeId} />
      </Popup>
      <button type="button" disabled={isLoading} onClick={setDetailsOpen}>
        {isLoading && !firstName
          ? <Skeleton circle={true} height={40} width={40} />
          : <Avatar firstName={firstName} lastName={lastName} source={profilePictureUrl} />
        }
        <div className={className}>
          <p className="first-name">{isLoading && !firstName ? <Skeleton width={90} height={18} /> : firstName}</p>
          <p className="last-name">{isLoading && !lastName ? <Skeleton width={110} height={15} /> : lastName}</p>
        </div>
      </button>
      {
        !isLoading && canCollapse && (
          <div className="action">
            <IconButton onClick={() => onCollapse(employeeId)} tag={isCollapsed ? 'ChevronDownIcon' : 'ChevronUpIcon'} title={translations.getLabel(isCollapsed ? 'lblCollapsed' : 'lblExpanded')} />
          </div>
        )
      }
      {
        isLoading && (
          <div className="action"><Spinner solid /></div>
        )
      }
    </div >
  );
};

export default EmployeeCell;
