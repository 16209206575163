import React, { FC, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import { AppState } from '../../../../shared/redux/rootReducer';
import { IApplicant, IDateRange, IShiftOfferApplicant, TimeScale } from '../../models';
import { configSelectors, scheduleActions, scheduleSelectors } from '../../redux';
import { scheduleUtils, translations } from '../../utils';
import Counters from '../requestInbox/components/counters/Counters';
import ShiftOfferRoster from './components/shiftOfferRoster/ShiftOfferRoster';
import WeekRoster from './components/weekSchedule/WeekRoster';

import './dayWeekSchedule.scss';

interface Props {
  selectedDate: Date;
  highlightRange?: IDateRange;
  departmentId: string;
  shiftOfferId: string;
  onSelect: (userId: string,) => void;
  selectedUsers: string[];
  numberOfApplicants: number;
  numberOfPeople: number;
  availableApplications?: IApplicant[];
}

const ShiftOfferSchedule: FC<Props> = ({ selectedDate, highlightRange, availableApplications, departmentId, shiftOfferId, onSelect, selectedUsers, numberOfApplicants, numberOfPeople }) => {
  const dispatch = useDispatch();
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const usersToday = useSelector((state: AppState) => scheduleSelectors.getShiftOfferApplicants(state, shiftOfferId)) || [];
  const isLoading = useSelector(scheduleSelectors.isShiftOfferApplicantsLoading);
  const [collapsedIds, setCollapseId] = useState<string[]>([]);
  const [users, setUsers] = useState<IShiftOfferApplicant[]>([]);

  // Get new data on change of date
  useEffect(() => {
    const { startDate, endDate } = scheduleUtils.getBoundsOfRange(TimeScale.Week, selectedDate, false, weekStartsOn);
    const formattedStartDate = format(startDate, 'yyyy-MM-dd');
    const formattedEndDate = format(endDate, 'yyyy-MM-dd');

    dispatch(new scheduleActions.GetShiftOfferApplicantsPlanningAction({ startDate: formattedStartDate, endDate: formattedEndDate, departmentId, shiftOfferId }));
  }, [selectedDate]);

  useEffect(() => {
    if (availableApplications && usersToday) setUsers(usersToday.filter(u => availableApplications.find(a => a.employmentId === u.employmentId)));
    else if (usersToday) setUsers(usersToday);
    else setUsers([]);
  }, [usersToday]);

  const onCollapse = (userId: string) => {
    if (collapsedIds.includes(userId)) setCollapseId(collapsedIds.filter(id => id !== userId));
    else setCollapseId([...collapsedIds, userId]);
  };

  return (
    <div className="week-schedule shift-offer-schedule">
      <ShiftOfferRoster selectedDate={selectedDate} departmentId={departmentId} highlightShiftOfferId={shiftOfferId} />

      {numberOfApplicants !== 0 && <p className="shift-offer-applicant-info">{translations.getLabel(numberOfPeople === 1 ? 'lblSelectCandidate' : 'lblSelectCandidates', { amount: numberOfPeople })}</p>}
      <section className="colleague-rosters">
        {(!isLoading && numberOfApplicants === 0)
          ? <p className="italic empty-view">{translations.getLabel('lblNoApplicantsYet')}</p>
          : users.map((user: IShiftOfferApplicant) => {
            return (
              <React.Fragment key={user.employeeId}>
                <WeekRoster
                  isSelectable
                  onSelect={() => onSelect(user.employmentId)}
                  isSelected={selectedUsers.includes(user.employmentId)}
                  isSelectionDisabled={numberOfPeople !== 1 && !selectedUsers.includes(user.employmentId) && numberOfPeople === selectedUsers.length}
                  hasSearch={false}
                  selectedDate={selectedDate}
                  userId={user.employeeId}
                  isCollapsed={collapsedIds.includes(user.employeeId)}
                  onCollapse={onCollapse}
                  highlightRange={highlightRange}
                  hideActions={true}
                  isMultiSelect={numberOfPeople > 1}
                  departmentId={departmentId} />
                {user.counters?.length && <Counters counters={user.counters} />}
              </React.Fragment>
            );
          })}

        {
          isLoading && <div className="loader-container"><Skeleton height={50} /></div>
        }
      </section>
    </div>
  );
};

export default ShiftOfferSchedule;
