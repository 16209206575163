import React from 'react';

import CircleGraphBase, { COLORS, VIEWBOX } from '../../../../../shared/components/circleGraph/CircleGraph.base';
export { COLORS };

class CircleGraph extends CircleGraphBase {
  renderCircleComponent = (dashArray, offset, color, cx, cy, radius, key) => {
    return <circle
      key={key}
      className="graph-segment"
      cx={cx}
      cy={cy}
      strokeLinecap="round"
      r={radius}
      fill="transparent"
      stroke={color}
      strokeWidth="1.5"
      strokeDasharray={dashArray}
      strokeDashoffset={offset} />;
  }

  render() {
    const values = this.getPercentagesWithSpacings();
    return (
      <svg width="100%" height="100%" viewBox={`0 0 ${VIEWBOX} ${VIEWBOX}`} className="graph">
        {values.map(this.renderCircle())}
      </svg>
    );
  }
}

export default CircleGraph;
