import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { date, notificationUtils } from '../../../utils';

class NotificationItem extends PureComponent {
  constructor(props) {
    super(props);

    this.iconTag = notificationUtils.getTagForType(props.type);
    const relativeDate = date.toRelativeDate(props.format, date.parseDate(props.createdAt));

    if (relativeDate.shouldTranslate) {
      this.createdDate = `${props.getLabel(relativeDate.value)} ${relativeDate.time}`;
    } else {
      this.createdDate = relativeDate.value;
    }
  }

  onPress = () => {
    this.props.onPress();
  }

  render() {
    return null;
  }
}

NotificationItem.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.any.isRequired, // for now use any, we need more specifics
  identifier: PropTypes.string,
  read: PropTypes.bool.isRequired,
  createdAt: PropTypes.string.isRequired,
  departmentId: PropTypes.string.isRequired,
  onPress: PropTypes.func,
  format: PropTypes.func,
  getLabel: PropTypes.func,
  isLoading: PropTypes.bool,
  markAsRead: PropTypes.func.isRequired,
  isSameDepartment: PropTypes.bool.isRequired,
};

const noop = () => { };
NotificationItem.defaultProps = {
  identifier: null,
  onPress: noop,
  format: noop,
  getLabel: noop,
  isLoading: false,
};

export default NotificationItem;
