import React, { FC } from 'react';
import { Icon } from 'react-ess-components';
import { IconName } from 'react-ess-components/build/assets/icons';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import './ToolBarButton.scss';

type Props = {
  className?: string;
  link?: string;
  onClick?: () => void;
  label: string;
  icon: IconName;
  disabled?: boolean;
  normal?: boolean;
  id?: string;
  inverse?: boolean;
  isDropDown?: boolean;
  linkState?: Record<string, string>;
}

const ToolBarButton: FC<Props> = ({ className = '', link = '', onClick = null, label, icon, disabled = false, normal = false, id = '', inverse = false, isDropDown = false, linkState = null }) => {
  const renderContent = () => (
    <React.Fragment>
      <Icon small={!normal} tag={icon} />
      <span data-text={label}>{label}</span>
      {
        isDropDown && <Icon tag="ChevronDownIcon" />
      }
    </React.Fragment>
  );


  const classname = classNames('toolbar-button', { [className]: className, disabled: disabled, inverse: inverse });
  if (link) {
    return (
      <Link id={id} to={{ pathname: link, state: linkState }} className={classname} aria-disabled={disabled}>
        {renderContent()}
      </Link >
    );
  }
  return (
    <button disabled={disabled} onClick={onClick} className={classname} id={id}>
      {renderContent()}
    </button>
  );
};

export default ToolBarButton;
