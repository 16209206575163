import { PureComponent } from 'react';
import PropTypes from 'prop-types';

export const COLORS = [
  { colorName: 'light-blue', color: '#d6e7ff' },
  { colorName: 'green', color: '#8dcc4e' },
  { colorName: 'dark-yellow', color: '#ffc233' },
  { colorName: 'pink', color: '#ce4b99' },
  { colorName: 'purple', color: '#444b99' },
  { colorName: 'lightGreen', color: '#cee299' }
];
const RADIUS = 15.91549430918954; // r = C/(2π) (C = circumference = 100 for easy calculations)
const FIRST_SEGMENT_OFFSET = 23; // We start drawing in the middle right of the circle -> 25 to start at the top -> 23 because there is a margin of 2 on the left and the right of a segment
const SPACING_BETWEEN_SEGMENTS = 4;
export const VIEWBOX = 34;

class CircleGraph extends PureComponent {
  getPercentagesWithSpacings = () => {
    // Filter out the segments with length 0
    const segmentCountWithContent = this.props.values.filter(value => value).length;
    // If there is only one segement, we do nothing
    if (segmentCountWithContent <= 1) return this.props.values;
    // We add a spacing of 4 after each segment, so our segments should be reduced
    const extraSpacing = segmentCountWithContent * SPACING_BETWEEN_SEGMENTS;
    return this.props.values.map(value => ((value * (100 - extraSpacing)) / 100));
  }

  renderCircle = (preceding_segments_length = 0) => (value, i) => {
    if (value === 0) return null;
    let offset = FIRST_SEGMENT_OFFSET;
    if (i !== 0) {
      // Current segment offset = circumference − all preceding segments total length + first segment's offset
      offset = 100 - preceding_segments_length + FIRST_SEGMENT_OFFSET;
    }
    preceding_segments_length += value + SPACING_BETWEEN_SEGMENTS;
    const dashArray = `${value} ${100 - value}`;
    return this.renderCircleComponent(dashArray, offset, COLORS[i].color, VIEWBOX / 2, VIEWBOX / 2, RADIUS, i);
  }
  // Placeholder function. Should be overridden in the real class
  renderCircleComponent = (dashArray, offset, color, cx, cy, radius) => null;

  render() {
    return null;
  }
}

CircleGraph.propTypes = {
  values: PropTypes.array.isRequired,
};

export default CircleGraph;
