export enum WeekDay {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY'
};

export interface IDayOfWeek {
  name: WeekDay;
  value: number;
}

export type WeekStartsOn = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export interface IRelativeFormattedDate {
  shouldTranslate?: boolean;
  value: string;
  time?: string;
}

export interface IDateStringRange {
  endDate: string
  startDate: string,
}