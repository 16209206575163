import React, { FC, useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { Button, IconButton, InputDateField, InputField, InputType } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { addMonths, parseISO } from 'date-fns';

import { AppState } from '../../../../shared/redux/rootReducer';
import { Locale, UserRight } from '../../models';
import { configSelectors, departmentsActions, departmentsSelectors, timeRegistrationActions, timeRegistrationSelectors } from '../../redux';
import { colors, translations } from '../../utils';
import TimeRegistrationRow from './components/TimeRegistrationRow';

import './DoubleUsage.scss';

const DoubleUsage: FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const timeRegistrations = useSelector(timeRegistrationSelectors.doubleTimeRegistrations);
  const isLoading = useSelector(timeRegistrationSelectors.isLoading);
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const departments = useSelector((state: AppState) => departmentsSelectors.getDepartmentsForRight(state, UserRight.TimeRegistration));
  const [startDate, setStartDate] = useState(addMonths(new Date(), -1).toISOString());
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const [department, setDepartment] = useState('');

  useEffect(() => {
    dispatch(new departmentsActions.GetDepartmentsAction({ type: UserRight.TimeRegistration }));
  }, []);

  useEffect(() => {
    if (departments?.length) setDepartment(departments[0].value);
  }, [departments]);

  const search = (e) => {
    e.preventDefault();
    dispatch(new timeRegistrationActions.GetDoubleTimeRegistrationsAction({ departmentId: department, startDate, endDate }));
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <DocumentTitle title={translations.getLabel('titleDoubleDeviceUsage')}>
      <div className="page fixed-width app-time-registration double-time-registration">
        <h2><IconButton onClick={goBack} tag="ArrowLeft" title={translations.getLabel('lblBack')} color={colors.primaryLight} />{translations.getLabel('titleDoubleDeviceUsage')}</h2>
        <div className="overview">
          <form className="overview-selectors" onSubmit={search}>
            <div className="inputs">
              <InputField disabled={isLoading} emptyIcon type={InputType.Select} options={departments} onChange={setDepartment} name="periodType" value={department} label={translations.getLabel('lblCheck')} />
              <InputDateField
                emptyIcon
                weekStartsOn={weekStartsOn}
                onChange={(date) => setStartDate(date.date.toISOString())}
                value={{ date: parseISO(startDate) }}
                dateFormat="dd MMM yyyy"
                label={translations.getLabel('lblFrom')}
                locale={translations.I18n.locale as Locale}
                maxDate={parseISO(endDate) || new Date()}
              />
              <InputDateField
                emptyIcon
                weekStartsOn={weekStartsOn}
                onChange={(date) => setEndDate(date.date.toISOString())}
                value={{ date: parseISO(endDate) }}
                dateFormat="dd MMM yyyy"
                label={translations.getLabel('lblUntil')}
                locale={translations.I18n.locale as Locale}
                maxDate={new Date()}
                minDate={parseISO(startDate) || new Date()}
              />
            </div>
            <Button type="submit" disabled={false}>{translations.getLabel('lblShowOverview')}</Button>
          </form>
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th className="left-align">{translations.getLabel('lblDeviceId')}</th>
                  <th className="left-align">{translations.getLabel('lblUsername')}</th>
                  <th className="left-align">{translations.getLabel('lblActivity')}</th>
                  <th className="left-align">{translations.getLabel('lblDate')}</th>
                  <th className="left-align">{translations.getLabel('lblTime')}</th>
                  <th className="left-align">{translations.getLabel('lblLocationId')}</th>
                </tr>
              </thead>
              <tbody>
                {isLoading
                  ? <tr>
                    <td><p><Skeleton /></p></td>
                    <td><p><Skeleton /></p></td>
                    <td><p><Skeleton /></p></td>
                    <td><p><Skeleton /></p></td>
                    <td><p><Skeleton /></p></td>
                    <td><p><Skeleton /></p></td>
                  </tr>
                  : timeRegistrations?.map((timeRegistration) => <TimeRegistrationRow key={timeRegistration.device} timeRegistration={timeRegistration} />)
                }
              </tbody>
            </table>
            {
              (!isLoading && !timeRegistrations?.length) &&
              <div className="empty">{translations.getLabel('lblEmptyTimeRegistrations')}</div>
            }
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
};

export default DoubleUsage;
