import React, { FC, useEffect, useRef, useState } from 'react';
import { SelectPopup } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';

import { CollapseToggle } from '../../../../components';
import { ChevronLocation } from '../../../../components/collapseToggle/CollapseToggle';
import { identifiers } from '../../../../constants';
import { useToggle } from '../../../../hooks';
import { IEmployee, ILabelAndValue } from '../../../../models';
import { departmentsActions, departmentsSelectors } from '../../../../redux';
import { translations } from '../../../../utils';

const myRosterEntry = { label: translations.getLabel('lblMyRoster'), value: identifiers.me };

interface Props {
  onChange: (id: string, employee?: IEmployee) => void;
}

const EmployeeSelect: FC<Props> = ({ onChange }) => {
  const [selected, setSelected] = useState<ILabelAndValue<IEmployee>>(myRosterEntry);
  const [isOpen, toggleOpen] = useToggle(false);
  const employees = useSelector(departmentsSelectors.getEmployees);
  const dispatch = useDispatch();
  const ref = useRef(null);

  useEffect(() => {
    dispatch(new departmentsActions.GetDepartmentEmployeesAction());
  }, []);

  const onSelect = (employeeId: string) => {
    if (employeeId === identifiers.me) {
      setSelected(myRosterEntry);
      onChange(employeeId);
    } else {
      const employee = employees.find(employee => employeeId === employee.value);
      setSelected(employees.find(employee => employeeId === employee.value));
      onChange(employeeId, employee.data);
    }
    toggleOpen(false);
  };

  const onToggle = (value: boolean) => {
    toggleOpen(!value);
  };
  return (
    <div className="employee-select" ref={ref}>
      <CollapseToggle isCollapsed={!isOpen} onClick={onToggle} chevronLocation={ChevronLocation.Right} value={selected.label} />
      <SelectPopup
        excludePressArea={ref.current}
        requestClose={() => toggleOpen(false)}
        data={[myRosterEntry, ...employees]}
        isSearchEnabled={false}
        popupOpen={isOpen}
        selected={selected.value}
        onValueSelected={onSelect} />
    </div>
  );
};

export default EmployeeSelect;
