import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { identifiers } from '../../../../constants';
import { IDayPlanning, TimeScale } from '../../../../models';
import { scheduleSelectors,userSelectors } from '../../../../redux';
import { translations } from '../../../../utils';
import EmployeeCell from './EmployeeCell';
import SearchSchedule from './SearchSchedule';

import './employeeColumn.scss';

interface Props {
  planning: IDayPlanning;
  hasSearch: boolean;
  selectedDate: Date;
  isCollapsed: boolean;
  hasNoSearchResults?: boolean;
  isLoading: boolean;
  timeScale: TimeScale;
  onCollapse: (id: string) => void;
  userId: string;
  departmentId?: string;
  showRequests?: boolean;
  isSelectable?: boolean;
}

const EmployeeColumn: FC<Props> = ({ timeScale, planning, userId, hasSearch, selectedDate, isCollapsed, onCollapse, isLoading, hasNoSearchResults, departmentId, showRequests, isSelectable }) => {
  const user = useSelector(userSelectors.getUser);
  const employee = useSelector((state: AppState) => scheduleSelectors.getUserDetailsFromPlanning(state, userId, departmentId, selectedDate));

  let { firstName, lastName, profilePictureUrl } = user;
  let employeeId = userId;
  if (userId !== identifiers.me) {
    firstName = employee?.employeeFirstName;
    lastName = employee?.employeeLastName;
    profilePictureUrl = employee?.profilePictureUrl;
    employeeId = employee?.employeeId;
  }

  return (
    <aside className={classnames('schedule-col', 'user-col', { 'has-requests': showRequests })} id={employee?.employeeId}>
      <div className="schedule-user-search">{hasSearch && <SearchSchedule timeScale={timeScale} selectedDate={selectedDate} />}</div>
      {showRequests && <div className="schedule-user-requests">{translations.getLabel('lblRequests')}</div>}
      <EmployeeCell hasNoSearchResults={hasNoSearchResults} isLoading={isLoading} employeeId={employeeId} firstName={firstName} lastName={lastName} profilePictureUrl={profilePictureUrl} isCollapsed={isCollapsed} onCollapse={onCollapse} canCollapse={planning?.shifts?.length > 0} />
      <div className="schedule-user-footer">{!isSelectable && translations.getLabel('titleAvailabilities')}</div>
    </aside>
  );
};

export default EmployeeColumn;
