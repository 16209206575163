import { Action } from 'redux';

import { ActionType } from './types';
import { IServerError } from '../../http/HttpError';
import { ILabelAndValue, IPeriodDetail, IEmployee, UserRight, Period } from '../../models';

export class GetDepartmentsAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartments;
  constructor(public payload?: { type: UserRight }) {
    this.payload = payload || { type: null };
  }
}

export class GetDepartmentsFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentsFulfilled;
  constructor(public payload: { data: ILabelAndValue[], type: UserRight }) { }
}

export class GetDepartmentsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentsRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetCompanyDepartmentsAction implements Action<ActionType> {
  readonly type = ActionType.GetCompanyDepartments;
}

export class GetCompanyDepartmentsFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetCompanyDepartmentsFulfilled;
  constructor(public payload: { data: ILabelAndValue[] }) { }
}

export class GetCompanyDepartmentsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetCompanyDepartmentsRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetDepartmentEmployeesAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentEmployees;
  constructor(public payload?: { search?: string }) { }
}

export class GetDepartmentEmployeesFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentEmployeesFulfilled;
  constructor(public payload: { data: IEmployee[] }) { }
}

export class GetDepartmentEmployeesRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentEmployeesRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetDepartmentPeriodsAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentPeriods;
  constructor(public payload?: { id: string }) { }
}

export class GetDepartmentPeriodsFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentPeriodsFulfilled;
  constructor(public payload: { data: IPeriodDetail[][] }) { }
}

export class GetDepartmentPeriodsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetDepartmentPeriodsRejected;
  constructor(public payload: { error: IServerError }) { }
}
export class GetAbsolutePeriodAction implements Action<ActionType> {
  readonly type = ActionType.GetAbsolutePeriod;
  constructor(public payload?: { startDate?: string; endDate?: string }) { }
}

export class GetAbsolutePeriodFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetAbsolutePeriodFulfilled;
  constructor(public payload: { data: { startDate?: string, endDate?: string } }) { }
}

export class GetAbsolutePeriodRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetAbsolutePeriodRejected;
  constructor(public payload: { error: IServerError }) { }
}
export class UpdateDepartmentPeriodsAction implements Action<ActionType> {
  readonly type = ActionType.UpdateDepartmentPeriods;
  constructor(public payload: { id: string, periods: IPeriodDetail[], onSuccess?: () => void, onError?: (error: IServerError) => void }) { }
}

export class UpdateDepartmentPeriodsFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.UpdateDepartmentPeriodsFulfilled;
  constructor(public payload: { id: string }) { }
}

export class UpdateDepartmentPeriodsRejectedAction implements Action<ActionType> {
  readonly type = ActionType.UpdateDepartmentPeriodsRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class ResetDepartmentPeriodAction implements Action<ActionType> {
  readonly type = ActionType.ResetDepartmentPeriod;
  constructor(public payload: { id: string, type: Period }) { }
}

export class ResetDepartmentPeriodFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.ResetDepartmentPeriodFulfilled;
  constructor(public payload: { data: IPeriodDetail }) { }
}

export class ResetDepartmentPeriodRejectedAction implements Action<ActionType> {
  readonly type = ActionType.ResetDepartmentPeriodRejected;
  constructor(public payload: { error: IServerError }) { }
}

export type Actions =
  | GetAbsolutePeriodAction
  | GetAbsolutePeriodFulfilledAction
  | GetAbsolutePeriodRejectedAction
  | GetDepartmentEmployeesAction
  | GetDepartmentEmployeesFulfilledAction
  | GetDepartmentEmployeesRejectedAction
  | GetDepartmentPeriodsAction
  | GetDepartmentPeriodsFulfilledAction
  | GetDepartmentPeriodsRejectedAction
  | GetDepartmentsAction
  | GetDepartmentsFulfilledAction
  | GetDepartmentsRejectedAction
  | GetCompanyDepartmentsAction
  | GetCompanyDepartmentsFulfilledAction
  | GetCompanyDepartmentsRejectedAction
  | ResetDepartmentPeriodAction
  | ResetDepartmentPeriodFulfilledAction
  | ResetDepartmentPeriodRejectedAction
  | UpdateDepartmentPeriodsAction
  | UpdateDepartmentPeriodsFulfilledAction
  | UpdateDepartmentPeriodsRejectedAction;