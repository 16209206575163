import { HttpClient } from '../http';
import { IShift, ITemplate, IShiftOffer, IGetShiftOffersParams, IDayShiftOffer, UpdateApplicantStatus, IDayPlanning } from '../models';

export const createTemplate = async (name: string, shifts: IShift[], departmentId: string): Promise<void> => {
  const shiftsDBO = shifts.map(shift => ({
    id: shift.id,
    function: shift.functionName,
    shiftName: shift.shiftName,
    intervals: shift.intervals.map(interval => ({
      startDate: interval.startDate,
      endDate: interval.endDate,
      timeType: interval.timeType,
      timeTypeId: interval.timeTypeId,
    })),
  }));
  return await HttpClient.post<void>(`/v1/management/departments/${departmentId}/shift-offers/templates`, { name, shifts: shiftsDBO });
};

export const getTemplates = async (departmentId: string): Promise<ITemplate[]> => {
  const result = await HttpClient.get<{ data: ITemplate[] }>(`/v1/management/departments/${departmentId}/shift-offers/templates`);
  return result.data;
};

export const createShiftOffer = async (departmentId: string, shiftOffer: IShiftOffer): Promise<void> => {
  return await HttpClient.post<void>(`/v1/management/departments/${departmentId}/shift-offers`, shiftOffer);
};

export const getShiftOffers = async (params: IGetShiftOffersParams): Promise<IDayShiftOffer[]> => {
  const paramsObject: IGetShiftOffersParams = {
    startDate: params.startDate,
    endDate: params.endDate,
  };
  if (params.departmentId) {
    paramsObject.departmentId = params.departmentId;
  }

  const result = await HttpClient.get<{ data: IDayShiftOffer[] }>('/v1/shift-offers', { ...paramsObject });
  return result.data;
};

export const updateShiftOfferApplicantStatus = async (id: string, type: UpdateApplicantStatus): Promise<void> => {
  const result = await HttpClient.post<void>(`/v1/shift-offers/${id}?type=${type}`);
  return result;
};

export const getScheduleDetail = async (date: string, employeeId: string): Promise<IDayPlanning[]> => {
  const result = await HttpClient.get<{ data: IDayPlanning[] }>('/v1/schedule/detail', { date, employeeId });
  return result.data;
};