import { Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { exhaustMap, map, catchError, switchMap } from 'rxjs/operators';

import ActionType from './types';
import { scheduleService } from '../../services';
import {
  GetPlanningAction,
  GetPlanningFulfilledAction,
  GetPlanningRejectedAction,
  GetPlanningTodayAction,
  GetPlanningTodayFulfilledAction,
  GetPlanningTodayRejectedAction,
  GetUserPlanningAction,
  GetUserPlanningFulfilledAction,
  GetUserPlanningRejectedAction,
  GetDepartmentPlanningAction,
  GetDepartmentPlanningFulfilledAction,
  GetDepartmentPlanningRejectedAction,
  SearchDepartmentPlanningAction,
  SearchDepartmentPlanningFulfilledAction,
  SearchDepartmentPlanningRejectedAction,
  GetShiftOfferApplicantsPlanningAction,
  GetShiftOfferApplicantsPlanningFulfilledAction,
  GetShiftOfferApplicantsPlanningRejectedAction,
  GetShiftSwapApplicantsPlanningAction,
  GetShiftSwapApplicantsPlanningFulfilledAction,
  GetShiftSwapApplicantsPlanningRejectedAction,
  GetUserPlanningExternalVacationRejectedAction,
  GetUserPlanningExternalVacationFulfilledAction,
  GetUserPlanningExternalVacationAction,
  DeleteUserPlanningExternalVacationAction,
  DeleteUserPlanningExternalVacationFulfilledAction,
  DeleteUserPlanningExternalVacationRejectedAction,
} from './actions';

export const getDepartmentPlanningEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetDepartmentPlanning).pipe(
    switchMap(({ payload }: GetDepartmentPlanningAction) =>
      from(scheduleService.getByDepartment({ startDate: payload.startDate, endDate: payload.endDate, departmentId: payload.departmentId })).pipe(
        map(data => new GetDepartmentPlanningFulfilledAction({ data, departmentId: payload.departmentId })),
        catchError(error => of(new GetDepartmentPlanningRejectedAction({ error })))
      )
    ),
  );

export const getShiftOfferApplicantsPlanningEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetShiftOfferApplicantsPlanning).pipe(
    switchMap(({ payload }: GetShiftOfferApplicantsPlanningAction) =>
      from(scheduleService.getShiftOfferApplicantsPlanning(payload.startDate, payload.endDate, payload.departmentId, payload.shiftOfferId)).pipe(
        map(data => new GetShiftOfferApplicantsPlanningFulfilledAction({ data, departmentId: payload.departmentId, shiftOfferId: payload.shiftOfferId })),
        catchError(error => of(new GetShiftOfferApplicantsPlanningRejectedAction({ error })))
      )
    ),
  );

export const getPlanningActionEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetPlanning).pipe(
    switchMap(({ payload }: GetPlanningAction) => {
      return from(scheduleService.get({ startDate: payload.startDate, endDate: payload.endDate })).pipe(
        map(({ planning }) => new GetPlanningFulfilledAction({ data: planning })),
        catchError(error => of(new GetPlanningRejectedAction({ error })))
      );
    }
    ),
  );

export const getPlanningTodayEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetPlanningToday).pipe(
    exhaustMap(({ payload }: GetPlanningTodayAction) =>
      from(scheduleService.get({ startDate: payload.startDate, endDate: payload.endDate })).pipe(
        map(({ planning }) => new GetPlanningTodayFulfilledAction({ data: planning })),
        catchError(error => of(new GetPlanningTodayRejectedAction({ error })))
      )
    ),
  );

export const getUserPlanningEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetUserPlanning).pipe(
    exhaustMap(({ payload }: GetUserPlanningAction) =>
      from(scheduleService.getByUser({ id: payload.employmentId, startDate: payload.startDate, endDate: payload.endDate })).pipe(
        map(({ data }) => {
          payload.onSuccess?.();
          return new GetUserPlanningFulfilledAction({ data });
        }),
        catchError(error => {
          payload.onError?.();
          return of(new GetUserPlanningRejectedAction({ error }));
        })
      )
    ),
  );

  export const getUserPlanningExternalVacationEpic$: Epic = action$ =>
    action$.ofType(ActionType.GetUserPlanningExternalVacation).pipe(
    switchMap(({ payload }: GetUserPlanningExternalVacationAction) =>
      from(scheduleService.getUserScheduleExternalVacation({ startDate: payload.startDate, endDate: payload.endDate })).pipe(
        map(({ data }) => {
          payload.onSuccess?.();
          return new GetUserPlanningExternalVacationFulfilledAction({ data, startDate: payload.startDate, endDate: payload.endDate });
        }),
        catchError(error => {
          payload.onError?.();
          return of(new GetUserPlanningExternalVacationRejectedAction({ error }));
        })
      )
    ),
  );

  export const deleteUserPlanningExternalVacationEpic$: Epic = action$ =>
    action$.ofType(ActionType.DeleteUserPlanningExternalVacation).pipe(
    exhaustMap(({ payload }: DeleteUserPlanningExternalVacationAction) =>
      from(scheduleService.deleteUserScheduleExternalVacation(payload.id, payload.startDate)).pipe(
        map(() => {
          payload.onSuccess?.();
          return new DeleteUserPlanningExternalVacationFulfilledAction({ id: payload.id, date: payload.startDate });
        }),
        catchError(error => {
          payload.onError?.(error);
          return of(new DeleteUserPlanningExternalVacationRejectedAction({ error }));
        })
      )
    ),
  );

export const searchDepartmentPlanningEpic$: Epic = action$ =>
  action$.ofType(ActionType.SearchDepartmentPlanning).pipe(
    switchMap(({ payload }: SearchDepartmentPlanningAction) =>
      from(scheduleService.getByDepartment({ search: payload.searchValue, startDate: payload.startDate, endDate: payload.endDate })).pipe(
        map(data => new SearchDepartmentPlanningFulfilledAction({ data })),
        catchError(error => of(new SearchDepartmentPlanningRejectedAction({ error })))
      )
    ),
  );

export const getShiftSwapApplicantsPlanningEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetShiftSwapApplicantsPlanning).pipe(
    switchMap(({ payload }: GetShiftSwapApplicantsPlanningAction) =>
      from(scheduleService.getShiftSwapApplicantsPlanning(payload.shiftSwapId)).pipe(
        map(data => new GetShiftSwapApplicantsPlanningFulfilledAction({ data, shiftSwapId: payload.shiftSwapId })),
        catchError(error => of(new GetShiftSwapApplicantsPlanningRejectedAction({ error })))
      )
    ),
  );

const epics = [
  getPlanningActionEpic$,
  getPlanningTodayEpic$,
  getUserPlanningEpic$,
  getUserPlanningExternalVacationEpic$,
  getDepartmentPlanningEpic$,
  searchDepartmentPlanningEpic$,
  getShiftOfferApplicantsPlanningEpic$,
  getShiftSwapApplicantsPlanningEpic$,
  deleteUserPlanningExternalVacationEpic$,
];

export default epics;
