import { ISOLanguage, UserLanguage } from './language';

export interface IChatUser {
  companyId: string;
  employmentId: string;
  firstName: string;
  id: string;
  language: ISOLanguage;
  lastName: string;
  profilePictureUrl: string;
  username: string;
}

export interface IUser {
  companyId: string;
  departmentId: string;
  email: string;
  employmentId: string;
  employeeId?: string; // Try not to use this parameter, will be deprecated in the future
  firstName: string;
  icsEnabled: boolean;
  id: string;
  isCompanyAdmin: boolean;
  isHoldingAdmin: boolean;
  inSchoolUntil?: string;
  hasTimeRegistration?: boolean; // TODO: make required and update mockdata/tests once BE has confirmed this is the correct name
  language: UserLanguage;
  lastName: string;
  permissions: UserRight[];
  phoneNumber: string;
  profilePictureUrl: string;
  username: string;
  visibleContactData: boolean;
}

export interface IEditUser {
  id?: string;
  visibleContactData?: boolean;
  currentPassword?: string;
  newPassword?: string;
}

export enum UserRight {
  Availabilities = 'AVAILABILITIES',
  Chat = 'CHAT',
  Documents = 'DOCUMENTS',
  News = 'NEWS',
  OpenShifts = 'OPEN_SHIFTS',
  Requests = 'REQUESTS',
  ShiftOffer = 'SHIFT_OFFER',
  ShiftSwap = 'SHIFT_SWAP',
  SelfRoster = 'SELF_ROSTER',
  VacationRequests = 'VACATION_REQUESTS',
  TimeRegistration = 'TIME_REGISTRATION',
  Transactions = 'TRANSACTIONS',
  AdjustedShiftTimes = 'SHIFT_TIME_ADJUSTMENT_REQUESTS',
  All = 'ALL',
};