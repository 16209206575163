import React, { FC, useRef } from 'react';
import { Orb } from 'react-ess-components';
import { Icon } from 'react-ess-components';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { availabilitiesSelectors } from '../../../../redux';
import { date, scheduleUtils } from '../../../../utils';
import { OpenAvailabilitiesFunction } from '../../schedule.models';

interface Props {
  currentDate: Date;
  hasSwitch: boolean;
  showAvailabilities: boolean;
  openAvailabilities: OpenAvailabilitiesFunction;
}

const MonthDayHeader: FC<Props> = ({ currentDate, hasSwitch, openAvailabilities, showAvailabilities }) => {
  const timeTypes = useSelector(availabilitiesSelectors.getVisibleTimeTypes);
  const availabilities = useSelector((state: AppState) => availabilitiesSelectors.getAvailabilitiesForDay(state, currentDate));
  const ref = useRef(null);

  const getOrbs = () => {
    if (!availabilities?.length) return [];
    return scheduleUtils.getAvailabilityTypes(
      availabilities,
      timeTypes,
      (color, index) => <Orb key={`orb${index}`} filled color={color} small />,
    );
  };

  const onHeaderClick = () => {
    if (availabilities?.length) {
      openAvailabilities(availabilities, timeTypes, ref.current);
    }
  };

  const className = classnames('stripped-button day-header', { 'no-availabilities': !availabilities?.length });

  return (
    <button ref={ref} className={className} onClick={onHeaderClick}>
      <p>{date.format(currentDate, 'd')}</p>
      <div className="icons">
        {hasSwitch && <Icon tag="SwitchIcon" customSize={1.2} />}
        {showAvailabilities && <div className="orbs">
          {getOrbs()}
        </div>}
      </div>
    </button>

  );
};

export default MonthDayHeader;
