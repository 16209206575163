export const libraryMode = { FILE: 'FILE', WEBPAGE: 'WEBPAGE' };

export const libraryDropdownValues = [
    {
        value: 'FILE',
        label: 'lblFile',
    },
    {
        value: 'WEBPAGE',
        label: 'lblWebsite',
    }
];