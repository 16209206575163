import React, { FC } from 'react';
import { Icon } from 'react-ess-components';
import { RouteComponentProps } from 'react-router-dom';
import { parseISO } from 'date-fns';

import { routes } from '../../../constants';
import { useSelfRosterIterations } from '../../../modules';
import { date, selfRosterUtils, translations } from '../../../utils';
import Widget from '../Widget';

import './selfRosterWidget.scss';

type Props = Omit<RouteComponentProps, 'location' | 'match'>

const SelfRosterWidget: FC<Props> = ({ history }) => {
  const { data: iterations, isLoading, error: iterationsError } = useSelfRosterIterations();

  return (
    <Widget title={translations.getLabel('titleSelfRosterWidget')} buttonLabel={iterations?.length ? translations.getLabel('btnAllOpenRounds') : null} onClickMore={() => history.push(routes.scheduleSelfRoster[0])}>
      <div className="self-roster-widget">
        {iterationsError && <p>{translations.getLabel('lblErrorGetRounds')}</p>}

        {!isLoading && !iterationsError && iterations.length === 0 && <p className="no-iterations">{translations.getLabel('lblNoOpenRounds')}</p>}

        {!isLoading && !iterationsError &&
          (
            iterations.slice(0, 3).map(iteration => {
              return (
                <button key={iteration.id} className="stripped-button iteration" onClick={() => history.push(`${routes.scheduleSelfRoster[0]}/${iteration.id}`)}>
                  <div className="iteration-number">{iteration.iteration}</div>
                  <div className="iteration-info">
                    <p className="iteration-date">{selfRosterUtils.formatIterationDate(date.format, { startDate: parseISO(iteration.startDate), endDate: parseISO(iteration.endDate) })}</p>
                    <p className="iteration-extra">{translations.getLabel('lblRound', { round: iteration.iteration })}</p>
                  </div>
                  <div className="arrow-container">
                    <Icon color="white" tag="ChevronRightIcon" />
                  </div>
                </button>
              );
            })
          )}
      </div>
    </Widget>
  );
};

export default SelfRosterWidget;
