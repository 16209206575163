import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO, startOfDay } from 'date-fns';

import { AppState } from '../../../../shared/redux/rootReducer';
import { identifiers } from '../../constants';
import { TimeScale } from '../../models';
import { configSelectors, scheduleActions, scheduleSelectors } from '../../redux';
import { scheduleUtils } from '../../utils';
import WeekRoster from '../schedule/components/weekSchedule/WeekRoster';

interface Props {
  selectedDate: Date;
  swapId: string;
  onSelect: (date: string, id?: string) => void;
  selectedShifts: string[];
  swapDate: string;
  selectedUsers?: string[];
}

const ShiftSwapCandidateSchedule: FC<Props> = ({ selectedDate, selectedUsers, swapId, swapDate, onSelect, selectedShifts }) => {
  const dispatch = useDispatch();
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const { startDate, endDate } = scheduleUtils.getBoundsOfRange(TimeScale.Week, selectedDate, false, weekStartsOn);
  const [collapsedIds, setCollapseId] = useState<string[]>([]);
  const applicants = useSelector((state: AppState) => scheduleSelectors.getShiftSwapApplicants(state, swapId));

  // Get new data on change of date
  useEffect(() => {
    const formattedStartDate = format(startDate, 'yyyy-MM-dd');
    const formattedEndDate = format(endDate, 'yyyy-MM-dd');

    // TODO: implement specific call for user
    dispatch(new scheduleActions.GetDepartmentPlanningAction({ startDate: formattedStartDate, endDate: formattedEndDate }));
    dispatch(new scheduleActions.GetPlanningAction({ startDate: formattedStartDate, endDate: formattedEndDate }));
  }, [selectedDate]);

  const onCollapse = (userId: string) => {
    if (collapsedIds.includes(userId)) setCollapseId(collapsedIds.filter(id => id !== userId));
    else setCollapseId([...collapsedIds, userId]);
  };

  return (
    <>
      <div className="week-schedule shift-offer-schedule no-requests no-availabilities">
        <WeekRoster
          selectedShifts={selectedShifts}
          hasSearch={false}
          selectedDate={selectedDate}
          userId={identifiers.me}
          isCollapsed={collapsedIds.includes(identifiers.me)}
          onCollapse={onCollapse}
          highlightRange={{ startDate: startOfDay(parseISO(swapDate)), endDate: parseISO(swapDate) }}
          hideActions={true}
        />
      </div>
      <div className="week-schedule shift-offer-schedule no-requests">
        <section className="colleague-rosters">
          {
            applicants.map((applicant) =>
              <WeekRoster
                isSelectable
                onSelect={onSelect}
                selectedShifts={selectedShifts}
                key={applicant.employeeId}
                hasSearch={false}
                selectedDate={selectedDate}
                userId={applicant.employeeId}
                isCollapsed={collapsedIds.includes(applicant.employeeId)}
                onCollapse={onCollapse}
                hideActions={true}
                selectedUsers={selectedUsers}
                highlightDates={applicant.counterSwapDates?.length > 0 ? applicant.counterSwapDates.map(d => d.startDate) : [swapDate]} // If the array with days is empty, it's a swap without counterSwap and we make the swapDate selectable
              />
            )
          }
        </section>
      </div>
    </>
  );
};

export default ShiftSwapCandidateSchedule;
