import React, { useEffect, useState } from 'react';
import { InputField, InputType,Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { IShift, UserRight } from '../../../../models';
import { departmentsActions, departmentsSelectors, shiftOfferActions, shiftOfferSelectors, userSelectors } from '../../../../redux';
import { translations } from '../../../../utils';
import Shift from './Shift';

import './shiftOfferModals.scss';

const CreateTemplateModal = () => {
  const showShiftOfferModal = useSelector(shiftOfferSelectors.showShiftOffersModal);
  const isLoading = useSelector(shiftOfferSelectors.isCreateTemplateLoading);
  const departments = useSelector((state: AppState) => departmentsSelectors.getDepartmentsForRight(state, UserRight.ShiftOffer));
  const employeeId = useSelector(shiftOfferSelectors.getEmployeeId);
  const date = useSelector(shiftOfferSelectors.getDate);
  const user = useSelector(userSelectors.getUser);
  const [departmentId, setDepartmentId] = useState(user.departmentId);
  const isDetailLoading = useSelector(shiftOfferSelectors.isDetailLoading);
  const scheduleDetail = useSelector(shiftOfferSelectors.getScheduleDetail);
  const [name, setName] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (showShiftOfferModal) {
      dispatch(new departmentsActions.GetDepartmentsAction({ type: UserRight.ShiftOffer }));
    }
  }, [showShiftOfferModal]);

  useEffect(() => {
    if (date && employeeId) {
      dispatch(new shiftOfferActions.GetScheduleDetailAction({
        date,
        employeeId,
      }));
    }
  }, [date, employeeId]);

  const closeModal = () => {
    dispatch(new shiftOfferActions.ToggleshiftOfferModalAction());
    setName('');
  };

  const createTemplate = () => {
    dispatch(new shiftOfferActions.CreateTemplateAction({
      name, shifts: scheduleDetail?.shifts,
      departmentId: departments.length === 1 ? departments[0].value : departmentId,
      onSuccess: () => {
        toast(translations.getLabel('lblTemplateCreated'));
        closeModal();
      },
      onError: (error) => {
        toast.error(typeof error.detail === 'string' ? error.detail : error.title);
      },
    }));
  };

  return (
    <Modal
      title={translations.getLabel('titleCreateTemplate')}
      open={showShiftOfferModal}
      requestClose={closeModal}
      leftButtonProps={{
        label: translations.getLabel('cancel'),
        onClick: closeModal,
      }}
      rightButtonProps={{
        label: translations.getLabel('btnCreateTemplate'),
        onClick: createTemplate,
        disabled: !name,
        isLoading,
      }}
    >
      <div className="create-template-modal">
        <InputField
          type={InputType.Text}
          value={name}
          onChange={text => setName(text)}
          emptyIcon
          label={translations.getLabel('lblName')}
        />
        {departments.length > 1 &&
          <InputField
            emptyIcon
            type={InputType.Select}
            options={departments}
            value={departmentId}
            onChange={setDepartmentId}
            label={translations.getLabel('lblDepartment')}
            placeholder={translations.getLabel('lblPlaceholderDepartmentSelect')}
          />
        }
        <div className="separator" />
        <section className="shifts">
          {
            !isDetailLoading ?
              scheduleDetail?.shifts?.map((shift: IShift) => <Shift key={shift.id} shift={shift} />)
              :
              <Skeleton count={4} height={20} />
          }
        </section>
      </div>
    </Modal>
  );
};

export default CreateTemplateModal;
