export const cloneDeep = data => {
  if (typeof data !== 'object' || data === null || data instanceof File) {
    return data;
  }

  if (data instanceof Array) {
    return data.reduce((arr, item, i) => {
      arr[i] = cloneDeep(item);
      return arr;
    }, []);
  }

  if (data instanceof Object) {
    return Object.keys(data).reduce((newObj, key) => {
      // The Wysiwyg editor has its own immutable record so we can just use the same reference.
      if (data[key] && data[key]['_immutable']) newObj[key] = data[key];
      else newObj[key] = cloneDeep(data[key]);
      return newObj;
    }, {});
  }
};

export const addCacheBreaker = (url) => {
  if (!url) return null;
  return `${url}&cache=${Math.round(Math.random() * 1000)}`;
};