import React, { FC } from 'react';
import { Button, Icon } from 'react-ess-components';

import { Shift } from '../../../../components';
import { ISelfRosteringShift } from '../../../../models';
import { useSelfRosterContext } from '../../../../modules';
import { translations } from '../../../../utils';
import OverstaffedWarning from '../overstaffed/OverstaffedWarning';

import './selfRosterShiftDetail.scss';

interface Props {
  shift: ISelfRosteringShift;
  isLocked?: boolean;
  onClose: () => void;
}

const SelfRosterShiftDetail: FC<Props> = ({ shift, isLocked, onClose }) => {
  const { selectShift, selectedShifts, selectedIteration } = useSelfRosterContext();
  const isSelected = selectedShifts.some(_shift => _shift.uuid === shift.uuid);

  return (
    <div className="self-roster-shift-detail">
      <button className="back-button" onClick={onClose}><Icon tag="ArrowLeft" color="primary" /> {translations.getLabel('btnBackToOverview')}</button>
      <div className="content">
        <Shift shift={{ ...shift, department: shift.departmentName }} /> {/* "Fix" to map selfroste shift property departmentName on property department of regular shift */}
        {shift.isOverstaffed && <OverstaffedWarning label="lblOverstaffedDetail" />}
        {isLocked && selectedIteration.iteration !== 3 && <p className="locked-warning"><Icon tag="LockedIcon" customSize={1.4} />{translations.getLabel('lblSelfRosterLockedShiftWarning')}</p>}
      </div>
      <div className="actions">
        {/* If a shift is locked, or if it overstaffed AND unselected, you can not select is again */}
        {!(isLocked || (!isSelected && shift.isOverstaffed)) && <Button onClick={() => selectShift(shift)}>{translations.getLabel(isSelected ? 'btnDeselectShift' : 'btnSelectShift')}</Button>}
        {selectedIteration.iteration === 3 && <p>{translations.getLabel('lblTentativelySelectedShift')}</p> }
      </div>
    </div >
  );
};

export default SelfRosterShiftDetail;
