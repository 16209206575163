import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { parseISO } from 'date-fns';

import { routes } from '../../../../constants';
import { ISelfRosteringIteration } from '../../../../models';
import { useSelfRosterCounter } from '../../../../modules';
import { date, selfRosterUtils, translations } from '../../../../utils';

type Props = {
  iteration: ISelfRosteringIteration;
  selectedIterationId: string;
  locationFrom?: string;
}

const Iteration: FC<Props> = ({ iteration, selectedIterationId, locationFrom }) => {
  const history = useHistory();
  const { data: hourCounter } = useSelfRosterCounter({
    startDate: iteration.startDate,
    endDate: iteration.endDate,
  });

  return (
    <button
      className={classNames('iteration stripped-button', { selected: selectedIterationId === iteration.id })}
      onClick={() => history.push(`${routes.scheduleSelfRoster[0]}/${iteration.id}`, { from: locationFrom })}
    >
      <p className="iteration-info-date">{selfRosterUtils.formatIterationDate(date.format, { startDate: parseISO(iteration.startDate), endDate: parseISO(iteration.endDate) })}</p>
      {<p>{translations.getLabel(hourCounter ? 'lblRoundAndHours' : 'lblRound', { round: iteration.iteration, currentValue: hourCounter?.currentValue, maxValue: hourCounter?.maxValue })}</p>}
    </button>
  );
};

export default Iteration;
