
import { regexes } from '../constants';

export const isPasswordValid = (stringToValidate: string) => {
  let isValid = regexes.oneSymbol.test(stringToValidate);
  if (!regexes.oneCapitalLetter.test(stringToValidate)) isValid = false;
  if (stringToValidate.length < 8) isValid = false;
  return isValid;
};

export const isUrlValid = (stringToValidate: string) => {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}' + // domain name
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(stringToValidate);
};

export const isFloat = (val: string) => {
  const floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
  if (!floatRegex.test(val))
    return false;

  const floatVal = parseFloat(val);
  if (isNaN(floatVal))
    return false;
  return true;
};
