import React from 'react';
import { Avatar, FileUpload, Icon } from 'react-ess-components';

import AvatarPickerBase from '../../../../../shared/components/avatarPicker/AvatarPicker.base';
import { translations } from '../../../utils';

import './AvatarPicker.scss';

class AvatarPicker extends AvatarPickerBase {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
  }

  toggleModal = () => {
    this.setState({
      isModalVisible: !this.state.isModalVisible,
    });
  }

  handleUpload = async (file) => {
    await this.props.handleUpload(file);
    this.setState({
      isModalVisible: false,
    });
  }

  render() {
    return (
      <div className="avatar-picker">
        <div className="avatar-container">
          <button onClick={this.toggleModal}>
            <Avatar source={this.props.source} large firstName={this.props.firstName} lastName={this.props.lastName} />
            <Icon tag="EditIcon" customSize={2} color="white" title={translations.getLabel('btnEdit')} />
          </button>
        </div>
        <FileUpload
          acceptFileTypes="image/png, image/jpeg"
          isLoading={this.props.isLoading}
          showPreview={false}
          handleUpload={this.handleUpload}
          isVisible={this.state.isModalVisible}
          toggleModal={this.toggleModal}
          translationLabels={{
            lblTitle: translations.getLabel('titleChangeProfilePicture'),
            lblDrag: translations.getLabel('lblDragImage'),
            lblBtn: translations.getLabel('btnChooseImage'),
          }} />
      </div>
    );
  }
}

export default AvatarPicker;
