import React, { FC, useRef } from 'react';
import { Icon } from 'react-ess-components';
import classnames from 'classnames';

import { IDayShiftSwap, RequestStatus, ShiftSwapStatus } from '../../../../models';
import { translations } from '../../../../utils';
import { OpenShiftSwapFunction } from '../../schedule.models';
import Shift from '../shiftOfferRoster/shiftOfferShift';

interface Props {
  shiftSwaps: IDayShiftSwap[];
  openShiftSwap: OpenShiftSwapFunction;
  isCollapsed: boolean;
  highlightShiftSwapId?: string;
}

const ShiftSwapDay: FC<Props> = ({ shiftSwaps, openShiftSwap, isCollapsed, highlightShiftSwapId }) => {
  return (
    <>
      {
        !isCollapsed
          ? shiftSwaps.map(shiftSwap => <ShiftSwap key={shiftSwap.id} shiftSwap={shiftSwap} openShiftSwap={openShiftSwap} highlightShiftSwapId={highlightShiftSwapId} />)
          : <div className="collapsed-shift-swap">{renderRequestsCount(shiftSwaps)}</div>
      }
    </>
  );
};

const renderRequestsCount = (shiftSwaps) => {
  if (shiftSwaps.length) {
    if (shiftSwaps.length > 1) {
      return <p>{shiftSwaps.length} {translations.getLabel('lblRequests')}</p>;
    } else {
      return <p>{shiftSwaps.length} {translations.getLabel('lblRequest')}</p>;
    }
  } else {
    return null;
  }
};

// Split up in separate component, since we need a separate ref per shiftSwap
interface ShiftSwapProps {
  shiftSwap: IDayShiftSwap;
  openShiftSwap: OpenShiftSwapFunction;
  highlightShiftSwapId?: string;
}
const ShiftSwap: FC<ShiftSwapProps> = ({ shiftSwap, openShiftSwap, highlightShiftSwapId }) => {
  const ref = useRef(null);
  const className = classnames('stripped-button shift-swap', { 'highlight': highlightShiftSwapId ? highlightShiftSwapId === shiftSwap.id : true });

  // We filter the shifts that start on the prev day, since these shifts are only shown on the day roster
  let shiftSwapStatus = shiftSwap.applicantStatus;
  if (shiftSwap.applicantStatus) {
    if (shiftSwap.status === RequestStatus.Open) {
      shiftSwapStatus = shiftSwap.applicantStatus;
    } else if (shiftSwap.status === RequestStatus.Pending || shiftSwap.status === RequestStatus.Accepted) {
      shiftSwapStatus = shiftSwap.applicantStatus !== ShiftSwapStatus.Accepted ? ShiftSwapStatus.Denied : ShiftSwapStatus.Pending;
    } else if (shiftSwap.status === RequestStatus.Denied) {
      shiftSwapStatus = ShiftSwapStatus.Denied;
    }
  }
  return (
    <button ref={ref} className={className} onClick={() => openShiftSwap(shiftSwap, ref.current)}>
      {shiftSwap.shifts.map((shift, i) => <Shift key={shift.id} shift={shift} status={i === 0 ? (shiftSwapStatus) : null} />)}
      <p className="requester"><Icon tag="ChangeIcon" color="text" customSize={1.8} /> <span>{shiftSwap.requesterName}</span></p>
    </button>
  );
};

export default ShiftSwapDay;
