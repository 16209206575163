export enum TransactionInputType {
  Boolean = 'BOOLEAN',
  InputField = 'INPUTFIELD',
}

export interface ITransaction {
  id: string,
  transactionTypeId: string,
  departmentId: string,
  employmentId: string,
  value: string,
  date: string,
  reason: string,
  status: string,
  description: string,
  name: string,
  createdAt: string,
  updatedAt: string,
  reviewerUserId: string,
  transactionType: TransactionInputType,
  reviewerFirstName: string,
  reviewerLastName: string,
  requesterEmployeeId: string,
  requesterFirstName: string,
  requesterLastName: string
}

export interface TransactionParams {
  date: string;
  type: string;
  value: number;
  reason?: string;
}

export interface Transactiontype {
  descriptionFalse?: string,
  descriptionTrue?: string,
  name: string,
  id: string,
  value: number,
  holdingId: string,
  externalId: string,
  transactionType: TransactionInputType
}