import { Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { map, catchError, switchMap, exhaustMap } from 'rxjs/operators';

import ActionType from './types';
import { adjustedShiftTimeService } from '../../services';
import {
  CancelAdjustShiftTimeRequestAction,
  CancelAdjustShiftTimeRequestFulfilledAction,
  CancelAdjustShiftTimeRequestRejectedAction,
  CreateAdjustShiftTimeRequestAction,
  CreateAdjustShiftTimeRequestFulfilledAction,
  CreateAdjustShiftTimeRequestRejectedAction,
} from './actions';
import { GetPlanningAction } from '../schedule/actions';

export const createAdjustedShiftTimeRequestEpic$: Epic = action$ =>
  action$.ofType(ActionType.CreateAdjustedShiftTimeRequest).pipe(
    switchMap(({ payload }: CreateAdjustShiftTimeRequestAction) =>
      from(adjustedShiftTimeService.createAdjustedShiftTimeRequest(payload.shift, payload.startDate, payload.endDate, payload.reason)).pipe(
        map(() => {
          payload.onSuccess?.();
          return new CreateAdjustShiftTimeRequestFulfilledAction({ startDate: payload.shift.startDate, endDate: payload.shift.endDate });
        }),
        catchError(error => {
          payload.onError?.(error);
          return of(new CreateAdjustShiftTimeRequestRejectedAction({ error }));
        })
      )
    ),
  );

export const createAdjustedShiftTimeRequestFulfilledEpic$: Epic = action$ =>
  action$.ofType(ActionType.CreateAdjustedShiftTimeRequestFulfilled).pipe(
    exhaustMap(({ payload }: CreateAdjustShiftTimeRequestFulfilledAction) => {
      return of(new GetPlanningAction({ startDate: payload.startDate, endDate: payload.endDate }));
    })
  );

export const cancelAdjustedShiftTimeRequest$: Epic = action$ =>
  action$.ofType(ActionType.CancelAdjustedShiftTimeRequest).pipe(
    switchMap(({ payload }: CancelAdjustShiftTimeRequestAction) =>
      from(adjustedShiftTimeService.cancelAdjustedShiftTimeRequest(payload.shiftTimeAdjustmentId)).pipe(
        map(() => {
          payload.onSuccess?.();
          return new CancelAdjustShiftTimeRequestFulfilledAction({ startDate: payload.startDate, endDate: payload.endDate });
        }),
        catchError(error => {
          payload?.onError?.(error);
          return of(new CancelAdjustShiftTimeRequestRejectedAction({ error }));
        })
      )
    ),
  );

export const cancelAdjustedShiftTimeRequestFulfilledEpic$: Epic = action$ =>
  action$.ofType(ActionType.CancelAdjustedShiftTimeRequestFulfilled).pipe(
    exhaustMap(({ payload }: CancelAdjustShiftTimeRequestFulfilledAction) => {
      return of(new GetPlanningAction({ startDate: payload.startDate, endDate: payload.endDate }));
    })
  );

const epics = [
  cancelAdjustedShiftTimeRequest$,
  cancelAdjustedShiftTimeRequestFulfilledEpic$,
  createAdjustedShiftTimeRequestEpic$,
  createAdjustedShiftTimeRequestFulfilledEpic$,
];

export default epics;
