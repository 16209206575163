import React, { FC, useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { Button, InputDateField, InputDateType, InputField, InputType, Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';

import { routes } from '../../constants';
import { TransactionInputType, TransactionParams } from '../../models';
import { configSelectors, countersActions, countersSelectors, transactionsActions, transactionsSelectors } from '../../redux';
import { date, translations, validation } from '../../utils';
import CountersTab from './tabs/CountersTab';
import OverviewTab from './tabs/overViewTab/OverviewTab';

import './counters.scss';

const Counters: FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(countersSelectors.isLoadingCounterOverviewConfiguration);
  const counterOverviewConfiguration = useSelector(countersSelectors.getCounterOverviewConfiguration);
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const types = useSelector(transactionsSelectors.getTypes);
  const selectedTransactionTypeValue = useSelector(transactionsSelectors.getValue);
  const isLoadingCreateTransaction = useSelector(transactionsSelectors.isLoadingCreateTransaction);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState<string>(null);
  const [value, setValue] = useState<string>();
  const [transactionDate, setTransactionDate] = useState(new Date());
  const [reason, setReason] = useState<string>(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    dispatch(new countersActions.GetCountersAction());
    dispatch(new countersActions.GetCounterOverviewConfigurationAction());
    dispatch(new transactionsActions.GetTransactionTypesAction());
  }, []);

  useEffect(() => {
    if (type) {
      dispatch(new transactionsActions.GetTransactionValueAction({
        transactionId: type,
        date: date.format(transactionDate, 'yyyy-MM-dd'),
      }));
    }
  }, [transactionDate, type]);

  useEffect(() => {
    if (selectedTransactionTypeValue) {
      setValue(String(selectedTransactionTypeValue.value));
    }
  }, [selectedTransactionTypeValue]);

  useEffect(() => {
    if (types?.length) {
      setType(types[0].id);
      setValue(String(types[0].value));
    }
  }, [types]);

  useEffect(() => {
    if (types?.length) {
      setType(types[0].id);
      setValue(String(types[0].value));
      setTransactionDate(new Date());
      setReason(null);
      setIsDisabled(true);
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (types?.length) {
      setValue(String(types[0].value));
    }
  }, [type]);

  const isValid = (): boolean => {
    return (!!type && validation.isFloat(value) && !!date);
  };

  const request = async () => {
    const transaction: TransactionParams = { type, value: parseFloat(value), date: transactionDate.toISOString() };
    if (reason) transaction.reason = reason;
    await dispatch(new transactionsActions.CreateTransactionAction({
      ...transaction,
      onSuccess: () => toast(translations.getLabel('lblTransactionRequestSuccess')),
      onError: (error) => setError(error.detail),
    }));
    setIsModalOpen(false);
  };

  const convertToNumber = () => {
    if (validation.isFloat(value)) setValue(value);
    else setValue('0');
  };

  return (
    <DocumentTitle title={translations.getLabel('titleCounters')}>
      <div className="page fixed-width counters">
        <h2>{translations.getLabel('titleCounters')}</h2>
        <Button theme="inverse" className="add-button" onClick={() => setIsModalOpen(true)}>+ {translations.getLabel('lblAddTransaction')}</Button>
        <div className="tab-container">
          {!isLoading && <div className="tab-buttons">
            <NavLink to={routes.counterTabs.groups}>{translations.getLabel('titleCounters')}</NavLink>
            {counterOverviewConfiguration.length > 0 && <NavLink to={routes.counterTabs.overviews}>{translations.getLabel('titleCounterOverViews')}</NavLink>}
          </div>}
          <div className="main-container">
            <div className="component-wrapper">
              <Switch>
                <Route path={routes.counterTabs.groups} exact component={CountersTab} />
                <Route path={routes.counterTabs.overviews} exact component={OverviewTab} />
                <Redirect to={routes.counterTabs.groups} />
              </Switch>
            </div>
          </div>
        </div>
        <Modal
          open={isModalOpen}
          requestClose={() => setIsModalOpen(false)}
          title={translations.getLabel('lblAddTransaction')}
          leftButtonProps={{
            onClick: () => setIsModalOpen(false),
            label: translations.getLabel('cancel'),
          }}
          rightButtonProps={{
            onClick: request,
            label: translations.getLabel('btnRequest'),
            isLoading: isLoadingCreateTransaction,
            disabled: !isValid(),
          }}
        >
          {
            error &&
            <div className="error-message" aria-live="assertive" role="alert">
              <p>{error}</p>
            </div>
          }
          <div className="transaction-container">
            {selectedTransactionTypeValue ? <div className="transactions">
              <InputDateField
                icon="CalendarIcon"
                label={translations.getLabel('lblDate')}
                onChange={(newDate) => setTransactionDate(newDate.date)}
                weekStartsOn={weekStartsOn}
                type={InputDateType.Date}
                value={{ date: transactionDate }}
              />

              <InputField
                icon="DashboardIcon"
                type={InputType.Select}
                options={types.map((t) => ({ value: t.id, label: t.name }))}
                onChange={setType}
                value={type}
                label={translations.getLabel('lblType')}
              />
              {
                types?.find(t => t.id === type)?.transactionType === TransactionInputType.InputField ?
                  <InputField
                    type={InputType.Text}
                    onChange={(value) => setValue(value)}
                    onBlur={convertToNumber}
                    value={value}
                    label={translations.getLabel('lblValue')}
                  />
                  :
                  <div className="options">
                    <div className="input-group">
                      <label>
                        <input type="radio" checked={value === '0'} onChange={() => setValue('0')} />
                        {types?.find(t => t.id === type)?.descriptionFalse}
                      </label>
                    </div>
                    <div className="input-group">
                      <label>
                        <input type="radio" checked={value === '1'} onChange={() => setValue('1')} />
                        {types?.find(t => t.id === type)?.descriptionTrue}
                      </label>
                    </div>
                  </div>
              }
              <InputField
                icon="ChatIcon"
                type={InputType.Text}
                onChange={setReason}
                value={reason}
                label={translations.getLabel('lblReason')}
              />
            </div>
              : <Skeleton height={300} width={400} />}
          </div>
        </Modal>
      </div >
    </DocumentTitle >
  );
};

export default Counters;
