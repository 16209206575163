import { createStore, applyMiddleware, Store } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';

import cancelAndLogoutMiddleware from './middleware/cancelAndLogoutMiddleware';
import loggerMiddleware from './middleware/loggerMiddleware';
import rootReducer, { AppState } from './rootReducer';
import rootEpic from './rootEpic';

const plainMapper = () => next => action => {
  return next(Object.assign({}, action));
};

const epicMiddleware = createEpicMiddleware();

const middleware = [plainMapper, cancelAndLogoutMiddleware, epicMiddleware, loggerMiddleware];

const store: Store<AppState> = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

epicMiddleware.run(rootEpic);

export default store;