import React, { FC } from 'react';
import { Icon } from 'react-ess-components';

import { ISelfRosterSaveError } from '../../../../../../shared/http/HttpError';
import { translations } from '../../../../utils';

import './ruleViolationError.scss';

type Props = {
  serverError: ISelfRosterSaveError;
}

const RuleViolationError: FC<Props> = ({ serverError }) => {
  if (!serverError) return null;

  return (
    <div className="rule-violation-error-container">
      <div className="icon">
        <Icon customSize={2.5} tag="Alert" color="error" />
      </div>
      <div className="content">
        <p className="title">{translations.getLabel('lblErrorRuleViolation')}</p>
        <ul className="error-list">
          {serverError?.detail?.ruleViolations?.map(ruleViolation => {
            return (
              <li key={ruleViolation?.ruleViolation}>{ruleViolation?.ruleViolation}</li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default RuleViolationError;
