import React, { FC } from 'react';
import { Button, Icon } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { parseISO } from 'date-fns';

import { IDayShiftSwap, RequestStatus, ShiftSwapStatus, UpdateApplicantStatus } from '../../../../models';
import { shiftSwapActions, shiftSwapSelectors } from '../../../../redux';
import { date, requestsUtils, translations } from '../../../../utils';
import ShiftOfferShift from '../../../shift/Shift';

import './shiftSwapDetail.scss';

interface Props extends RouteComponentProps {
  shiftSwap: IDayShiftSwap;
  hideActions?: boolean;
}

const ShiftSwapDetail: FC<Props> = ({ shiftSwap, hideActions, history }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(shiftSwapSelectors.isUpdateApplicantStatusLoading);

  const removeFromSwap = () => updateApplicantStatus(UpdateApplicantStatus.Withdraw);
  const applyForSwap = () => updateApplicantStatus(UpdateApplicantStatus.Apply);
  const applyForSwapWithCounter = () => history.push(`/shift-swap/${shiftSwap.date}/${shiftSwap.id}`);
  const updateApplicantStatus = (type: UpdateApplicantStatus) => {
    dispatch(new shiftSwapActions.UpdateShiftSwapApplicantStatusAction({
      id: shiftSwap.id,
      date: shiftSwap.shifts[0].startDate,
      type,
      onError: (error) => {
        toast.error(error.detail);
      },
    }));
  };

  const lastUpdatedDate = requestsUtils.getRelativeDate(shiftSwap?.updatedAt, date.format);

  // There are a lot of statusses, depending on the applicant status AND the shiftSwapStatus, handle with care
  const isNew = !shiftSwap.applicantStatus && shiftSwap.status === RequestStatus.Open;
  const isDenied = shiftSwap.applicantStatus === ShiftSwapStatus.Denied || (shiftSwap.status === RequestStatus.Denied && shiftSwap.applicantStatus) || (shiftSwap.applicantStatus === ShiftSwapStatus.Pending && shiftSwap.status !== RequestStatus.Open);
  const isPending = shiftSwap.applicantStatus === ShiftSwapStatus.Pending && shiftSwap.status === RequestStatus.Open;
  const isWaitingForApproval = shiftSwap.applicantStatus === ShiftSwapStatus.Accepted && shiftSwap.status === RequestStatus.Pending;
  const isNotChosen = (shiftSwap.applicantStatus === ShiftSwapStatus.Denied || shiftSwap.applicantStatus === ShiftSwapStatus.Pending) && shiftSwap.status !== RequestStatus.Open;
  const isAccepted = shiftSwap.applicantStatus === ShiftSwapStatus.Accepted && shiftSwap.status === RequestStatus.Accepted;

  const showSubdetails = isPending || isNew;
  return (
    <div className="shift-swap-detail">
      <span className="requester-name">{shiftSwap.requesterName}</span>
      {shiftSwap.shifts.map(shift => <ShiftOfferShift key={shift.id} shift={shift} />)}

      {(isNew || isPending) && <div className="hr" />}

      <div className="subdetails">
        <Icon tag="ChangeIcon" customSize={2} color="primaryDark" />
        <span>{translations.getLabel(shiftSwap.hasCounterSwap ? 'lblOfferedWithCounterSwap' : 'lblOfferedWithoutCounterSwap')}</span>
      </div>

      {(isWaitingForApproval || isAccepted || isDenied) && <div className="hr extra-top-margin" />}

      {showSubdetails && <>
        <div className="subdetails">
          <Icon tag="CandidatsIcon" customSize={2} color="primaryDark" />
          <span>{translations.getLabel('lblShiftSwapNumberOfApplicants', { amount: shiftSwap.numberOfApplicants || 0 })}</span>
        </div>
      </>
      }

      {isPending && <>
        <div className="subdetails">
          <Icon tag="CheckIcon" customSize={2} color="primaryDark" />
          <span>{translations.getLabel('lblYoureCandidate')}</span>
        </div>
        {shiftSwap.counterSwapDates?.length > 0 && <div className="subdetails counter-swap-days">
          <ul>
            {shiftSwap.counterSwapDates?.map((day, i) => {
              const startDate = parseISO(day.startDate);
              const endDate = parseISO(day.endDate);
              return <li key={i}>{date.format(startDate, 'EEEEEE d MMM')} <span className="counter-swap-time">{date.formatFromUntilTime(date.format, { startDate, endDate })}</span></li>;
            })}
          </ul>
        </div>}
      </>}

      {isWaitingForApproval &&
        <div className="applicant-status">
          <p>
            <span className={'primary'}>{translations.getLabel('lblWaitingOnApprovalManager')}</span>
          </p>
          <p className="timestamp">
            {translations.getLabel(shiftSwap.hasCounterSwap ? 'lblShiftSwapPendingWithDate' : 'lblShiftSwapPendingWithName', { name: shiftSwap.applicantName, date: shiftSwap.selectedCounterSwapDate && date.format(parseISO(shiftSwap.selectedCounterSwapDate), 'dd LLLL') })}
          </p>
        </div>
      }

      {(isDenied || isAccepted) &&
        <div className="applicant-status">
          <p>
            <Icon tag={isDenied ? 'CrossIcon' : 'CheckIcon'} customSize={2} color={isDenied ? 'error' : 'success'} />
            <span className={isDenied ? 'error' : 'success'}>{translations.getLabel(isDenied ? (isNotChosen ? 'lblNotChosen' : 'lblDenied') : 'lblApproved')}</span>
          </p>
          {!isNotChosen && <p className="timestamp">{lastUpdatedDate.shouldTranslate ? `${translations.getLabel(lastUpdatedDate.value)} ${lastUpdatedDate.time}` : `${lastUpdatedDate.value}`} {translations.getLabel('lblBy')} {shiftSwap?.reviewerName || '-'}</p>}
          {!isNotChosen && <p className="status-note">
            {translations.getLabel('lblRemarks')}: {shiftSwap.reviewerNote || '-'}
          </p>}
        </div>
      }

      {showSubdetails && !hideActions &&
        <div className="action">
          {isNew && !isDenied && <Button isLoading={isLoading} onClick={shiftSwap.hasCounterSwap ? applyForSwapWithCounter : applyForSwap}>{translations.getLabel('btnApplyForSwap')}</Button>}
          {isPending && !isDenied && <Button isLoading={isLoading} theme="inverse" onClick={removeFromSwap}>{translations.getLabel('btnRemoveFromSwap')}</Button>}
        </div>
      }
    </div>
  );
};

export default withRouter(ShiftSwapDetail);


