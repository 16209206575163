import { PureComponent } from 'react';
import PropTypes from 'prop-types';

class AccountDetails extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return null;
  }
}


AccountDetails.propTypes = {
  user: PropTypes.object,
  isLoading: PropTypes.bool,
  isAvatarLoading: PropTypes.bool,
  handleUpload: PropTypes.func,
  onchange: PropTypes.func,
};

const noop = () => { };
AccountDetails.defaultProps = {
  user: {},
  isLoading: false,
  isAvatarLoading: false,
  handleUpload: noop,
  onchange: noop,
};

export default AccountDetails;
