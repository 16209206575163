import React, { FC } from 'react';
import { Orb } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { parseISO } from 'date-fns';
import { History } from 'history';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { routes } from '../../../../constants';
import { requestsActions, requestsSelectors } from '../../../../redux';
import { date, requestsUtils, translations } from '../../../../utils';

interface Props extends RouteComponentProps {
    id: string;
    history: History;
    selectedId: string;
}

const RequestPreview: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const request = useSelector((state: AppState) => requestsSelectors.getTransactionRequest(state, props.id));

    const onSelectRequest = (): void => {
        if (!request.hasRead) dispatch(new requestsActions.OnReadTransactionRequestAction({ id: request.id }));
        props.history.push(`${routes.scheduleRequests.transactionInbox[0]}/${request.id}`);
    };

    const relativeDate = requestsUtils.getRelativeDate(request.updatedAt, date.format);
    const previewMessage = translations.getLabel('lblOwnTransactionRequestMessage', { createdAt: date.format(parseISO(request.createdAt), 'eee dd LLL') });

    const className = classNames('stripped-button request', {
        'request-unread': !request.hasRead,
        'request-selected': props.selectedId === request.id,
    });

    return (
        <button className={className} onClick={onSelectRequest}>
            <div className="request-change">{relativeDate.shouldTranslate ? `${translations.getLabel(relativeDate.value)} ${relativeDate.time}` : `${relativeDate.value}`}</div>
            <div className="request-status">
                <Orb color={requestsUtils.getColorByRequestStatus(request.status)} filled small />{` ${requestsUtils.getRequestStatus(request.status, translations.getLabel)}`}
            </div>
            <div className="request-text">{previewMessage}</div>
        </button>
    );
};

export default withRouter(RequestPreview);
