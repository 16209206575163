import ActionType from './types';
import { Action } from 'redux';
import { IShift, ITemplate, IShiftOffer, IGetShiftOffersParams, IDayShiftOffer, UpdateApplicantStatus, IDayPlanning } from '../../models';
import { IServerError } from '../../http/HttpError';

export class ToggleshiftOfferModalAction implements Action<ActionType> {
  readonly type = ActionType.ToggleShiftOfferModal;
  constructor(public payload?: { employeeId: string, date: string }) { }
}

export class CreateTemplateAction implements Action<ActionType> {
  readonly type = ActionType.CreateTemplate;
  constructor(public payload?: { name: string, shifts: IShift[], departmentId: string, onSuccess?: () => void; onError?: (error: IServerError) => void }) { }
}

export class CreateTemplateFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.CreateTemplateFulfilled;
}

export class CreateTemplateRejectedAction implements Action<ActionType> {
  readonly type = ActionType.CreateTemplateRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetTemplatesAction implements Action<ActionType> {
  readonly type = ActionType.GetTemplates;
  constructor(public payload?: { departmentId: string; }) { }
}

export class GetTemplatesFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetTemplatesFulfilled;
  constructor(public payload?: { data: ITemplate[]; }) { }
}

export class GetTemplatesRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetTemplatesRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class CreateShiftOfferAction implements Action<ActionType> {
  readonly type = ActionType.CreateShiftOffer;
  constructor(public payload?: { departmentId: string; shiftOffer: IShiftOffer, onSuccess?: () => void; onError?: (error: IServerError) => void }) { }
}

export class CreateShiftOfferFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.CreateShiftOfferFulfilled;
  constructor(public payload: { startDate?: string, departmentId?: string }) { }
}

export class CreateShiftOfferRejectedAction implements Action<ActionType> {
  readonly type = ActionType.CreateShiftOfferRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetShiftOffersAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftOffers;
  constructor(public payload: { startDate: string, endDate: string, departmentId?: string }) { }
}

export class GetShiftOffersFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftOffersFulfilled;
  constructor(public payload: { data: IDayShiftOffer[], startDate: string, endDate: string, }) { }
}

export class GetShiftOffersRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetShiftOffersRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class UpdateShiftOfferApplicantStatusAction implements Action<ActionType> {
  readonly type = ActionType.UpdateShiftOfferApplicantStatus;
  constructor(public payload: { id: string; type: UpdateApplicantStatus, date: string; onError?: (error: IServerError) => void }) { }
}

export class UpdateShiftOfferApplicantStatusFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.UpdateShiftOfferApplicantStatusFulfilled;
  constructor(public payload: { id: string, type: UpdateApplicantStatus, date: string }) { }
}

export class UpdateShiftOfferApplicantStatusRejectedAction implements Action<ActionType> {
  readonly type = ActionType.UpdateShiftOfferApplicantStatusRejected;
  constructor(public payload: { error: IServerError }) { }
}

export class GetScheduleDetailAction implements Action<ActionType> {
  readonly type = ActionType.GetScheduleDetail;
  constructor(public payload: { date: string, employeeId?: string }) { }
}

export class GetScheduleDetailFulfilledAction implements Action<ActionType> {
  readonly type = ActionType.GetScheduleDetailFulfilled;
  constructor(public payload: { data: IDayPlanning[], date: string, }) { }
}

export class GetScheduleDetailRejectedAction implements Action<ActionType> {
  readonly type = ActionType.GetScheduleDetailRejected;
  constructor(public payload: { error: IServerError }) { }
}

export type Actions =
  | CreateShiftOfferAction
  | CreateShiftOfferFulfilledAction
  | CreateShiftOfferRejectedAction
  | CreateTemplateAction
  | CreateTemplateFulfilledAction
  | CreateTemplateRejectedAction
  | GetScheduleDetailAction
  | GetScheduleDetailFulfilledAction
  | GetScheduleDetailRejectedAction
  | GetShiftOffersAction
  | GetShiftOffersFulfilledAction
  | GetShiftOffersRejectedAction
  | GetTemplatesAction
  | GetTemplatesFulfilledAction
  | GetTemplatesRejectedAction
  | ToggleshiftOfferModalAction
  | UpdateShiftOfferApplicantStatusAction
  | UpdateShiftOfferApplicantStatusFulfilledAction
  | UpdateShiftOfferApplicantStatusRejectedAction;
