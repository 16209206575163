import { format as formatOriginal, getDay as getDayOriginal } from 'date-fns';
import { de, enGB, fr, nl } from 'date-fns/locale';

import { parseDate } from '../../../shared/utils/date';
import translations from './translations';

export * from '../../../shared/utils/date';

const locales = {
  en: enGB,
  de,
  fr,
  nl,
};

// Cannot be properly typed yet, until we know or decide how to handle dates in app vs. utils
export const format = (date: Date | string, formatStr: string): string => {
  if (!date) return '';
  return formatOriginal(parseDate(date), formatStr, {
    locale: locales[translations.I18n.locale],
  });
};

export const getDay = (date: Date | string): number => {
  return getDayOriginal(parseDate(date));
};

