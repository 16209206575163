import { HttpClient } from '../http';
import { INotification, IPushNotificationRegisterBody } from '../models';
import { HttpMetadataPagingResponse } from '../http/HttpMetadata';

export interface GetNotificationsResponse { data: INotification[]; meta: HttpMetadataPagingResponse }
export const getAll = async (limit = 15, offset = 0): Promise<GetNotificationsResponse> => {
  // Directly return this because we need meta aswel.
  return await HttpClient.get<GetNotificationsResponse>('/v1/notifications', { limit, offset });
};

export const markAsRead = async (id: string): Promise<string> => {
  await HttpClient.post(`/v1/notifications/${id}/read`);
  return id;
};

export const getUnread = async (): Promise<number> => {
  const result = await HttpClient.get<{ count: number }>('/v1/notifications/unread');
  return result.count;
};

export const register = async (currentToken: string, previousToken: string, type = 'browser'): Promise<{ token: string }> => {
  try {
    const body: IPushNotificationRegisterBody = { currentToken, type };
    if (previousToken) body.previousToken = previousToken;

    await HttpClient.post('/v1/notifications/register', body);
    return { token: currentToken };
  } catch (err) {
    return Promise.reject(err);
  }
};

export const unRegister = (token: string): Promise<void> => HttpClient.post('/v1/notifications/unregister', { token });