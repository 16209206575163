import React from 'react';
import { IconButton } from 'react-ess-components';
import { useSelector } from 'react-redux';
import { isAfter, isBefore, parseISO } from 'date-fns';

import { TimeScale } from '../../../../models';
import { configSelectors } from '../../../../redux';
import { date, scheduleUtils, translations } from '../../../../utils';

import './selfRosterDateSwitch.scss';

type Props = {
  changeDate: (amount: number) => void;
  selectedDate: Date;
  minDate?: string;
  maxDate?: string
  dateRange: TimeScale;
}

const SelfRosterDateSwitch = ({ changeDate, selectedDate, dateRange, minDate = null, maxDate = null }: Props) => {
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const decreaseDateRange = () => changeDate(-1);
  const increaseDateRange = () => changeDate(1);
  const { startDate: firstDateOfRange, endDate: lastDateOfRange } = scheduleUtils.getBoundsOfRange(dateRange, selectedDate, false, weekStartsOn);

  const getLabel = () => {
    const label = scheduleUtils.getScheduleHeader(dateRange, selectedDate, date.format, weekStartsOn);
    if (label.shouldTranslate) return translations.getLabel(label.value);
    return label.value;
  };

  const isPreviousDisabled = !!minDate && !isAfter(firstDateOfRange, parseISO(minDate));
  const isNextDisabled = !!maxDate && !isBefore(lastDateOfRange, parseISO(maxDate));

  if (isPreviousDisabled && isNextDisabled) return null;

  return (
    <div className="self-roster-page-switch">
      <IconButton tag="ChevronLeftIcon" onClick={decreaseDateRange} title={translations.getLabel('lblPrevious')} disabled={isPreviousDisabled} />
      <span className="label inline">{getLabel()}</span>
      <IconButton tag="ChevronRightIcon" onClick={increaseDateRange} title={translations.getLabel('lblNext')} disabled={isNextDisabled} />
    </div>
  );
};

export default SelfRosterDateSwitch;

