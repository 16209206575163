import React, { FC } from 'react';

import { SelfRosterContextProvider, useSelfRosterIterations } from '../../modules';
import SelfRoster, { Props } from './SelfRoster';

const SelfRosterContainer: FC<Props> = (props) => {
  const { data: iterations } = useSelfRosterIterations();
  const iteration = iterations?.find(_iteration => _iteration.id === props.match.params.iterationId);

  return (
    <SelfRosterContextProvider iteration={iteration}>
      <SelfRoster {...props} />
    </SelfRosterContextProvider>
  );
};

export default SelfRosterContainer;
