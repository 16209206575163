import { IPeriodDetail } from './periods';
import { ILabelAndValue } from './config';
import { IShift } from './schedule';
import { IDateStringRange } from './date';
import { ShiftSwapStatus } from './shiftSwap';
import { CounterType } from './counters';
import { ICounterValue } from './time';
import { ShiftOfferApplicantStatus } from './shiftOffer';
import { TransactionInputType } from './transactions';

export type IRequestCounter = {
  description: string;
  id: string;
  type: CounterType;
  value: ICounterValue;
  valueAfter: ICounterValue;
}
export interface IVacationRequest {
  counters: IRequestCounter[];
  createdAt: string;
  employeeId: string;
  endDate: string;
  hasRead: boolean;
  id: string;
  requesterName: string;
  requesterNote?: string;
  reviewerName?: string;
  reviewerNote?: string;
  startDate: string;
  status: RequestStatus;
  updatedAt: string;
  vacationType: string;
}

export interface IExternalVacationRequest {
  id: string;
  shiftId: string;
  requesterNote?: string;
  startDate: string;
  status: RequestStatus;
  description: string;
}

export type IVacationRequestCounterParams = {
  startDate: string,
  endDate: string,
  vacationTypeId: string
}

export interface IVacationRequestDTO {
  endDate: string;
  requesterNote?: string;
  vacationTypeId: string;
  startDate: string;
}

export interface IManagerVacationRequestDTO {
  reviewerNote?: string;
  status: RequestStatus;
}

export type IVacationRequests = Record<string, IVacationRequest>;

export type ITransactionRequests = Record<string, ITransactionRequest>;

export type IShiftTimeRequests = Record<string, IShiftTimeRequest>;

export enum RequestStatus {
  Accepted = 'ACCEPTED',
  All = '',
  Open = 'OPEN',
  Pending = 'PENDING',
  Denied = 'DENIED',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
}

export enum RequestType {
  Vacation = 'vacation',
  ShiftSwap = 'shiftSwap',
  ShiftOffer = 'shiftOffer',
  Transaction = 'transaction',
  ShiftTime = 'shiftTime',
}

export interface IPeriodValidationParams {
  requestPeriod: IPeriodDetail;
  isPeriodCheck?: boolean;
  forDate?: Date;
  isAbsenceShift?: boolean;
}

export interface IRequest {
  endDate: Date;
  requesterNote?: string;
  startDate: Date;
  vacationTypeId: string;
}

export interface IVacationType {
  interval: ILabelAndValue[],
  shift: ILabelAndValue[],
}

interface RequestMetaData {
  type: string; // should probably be an enum, as more requests types are added.
  totalCount: number;
  count: number;
}

export interface IVacationRequestResponse {
  meta: RequestMetaData;
  data: IVacationRequest[];
  isInitialLoad?: boolean;
}

export interface ITransactionRequestResponse {
  meta: RequestMetaData;
  data: ITransactionRequest[];
  isInitialLoad?: boolean;
}

export interface IRequestDepartment {
  departmentId: string;
  name: string;
  count: number;
}

export interface IRequestCounts {
  unhandledCountDepartments: number;
  unhandledCountPersonal: number;
  unhandledCountShiftOffers: number;
  unhandledCountShiftTimes: number;
}

export interface IShiftOfferRequest {
  applicants?: IApplicant[],
  createdAt: string;
  date: string;
  departmentId?: string;
  departmentIdOfExecution?: string;
  departmentName?: string;
  departmentNameOfExecution?: string;
  departmentNames?: string[];
  id: string;
  numberOfApplicants: number;
  numberOfPeople: number;
  reviewerName?: string;
  reviewerUserId?: string;
  shiftOfferStatus: RequestStatus;
  shifts?: IShift[];
  templateId?: string;
  templateName?: string;
  updatedAt: string;
}

export interface IShiftSwapRequest {
  id: string;
  date: string;
  status: RequestStatus;
  updatedAt: string;
  createdAt: string;
  shifts?: IShift[];
  departmentId?: string;
  departmentName?: string;
  applicantStatus: ShiftSwapStatus;
  numberOfApplicants: number;
  hasCounterSwap: boolean;
  requesterName: string;
  reviewerName?: string;
  reviewerNote?: string;
  applicantName?: string;
  counterSwapDates?: IDateStringRange[];
  selectedCounterSwapDate?: string;
  requesterEmployeeId?: string;
  applicantEmployeeId?: string;
}

export interface IApplicant {
  userId: string;
  employmentId?: string;
  name: string;
  applicationDate: string;
  status?: ShiftOfferApplicantStatus;
}

export interface IShiftOfferResponse {
  isShiftClosed: boolean;
  accepted: string[];
  pending: string[];
  denied: string[];
  ruleViolated: string[];
}

export type IShiftOfferRequests = Record<string, IShiftOfferRequest>;

export type IShiftSwapRequests = Record<string, IShiftSwapRequest>;

export type IDepartmentTransactions = Record<string, ITransactionRequest>;

export type IDepartmentShiftTimes = Record<string, IShiftTimeRequest>;


export interface IShiftOfferRequestResponse {
  meta: RequestMetaData;
  data: IShiftOfferRequest[];
  isInitialLoad?: boolean;
}

export interface IShiftSwapRequestResponse {
  meta: RequestMetaData;
  data: IShiftSwapRequest[];
  isInitialLoad?: boolean;
}

export interface ITransactionRequestResponse {
  meta: RequestMetaData;
  data: ITransactionRequest[];
  isInitialLoad?: boolean;
}

export interface IShiftTimeRequestResponse {
  meta: RequestMetaData;
  data: IShiftTimeRequest[];
  isInitialLoad?: boolean;
}

export interface ITransactionRequest {
  description: string,
  createdAt: string;
  createdBy: string;
  date: string;
  departmentId: string;
  descriptionFalse: string;
  descriptionTrue: string;
  employmentId: string;
  id: string;
  name: string;
  reason: string;
  requesterEmployeeId: string;
  requesterFirstName: string;
  requesterLastName: string;
  reviewerFirstName: string;
  reviewerLastName: string;
  reviewerNote: string;
  reviewerUserId: string;
  status: RequestStatus;
  transactionType: TransactionInputType;
  transactionTypeId: string;
  updatedAt: string;
  value: number;
  hasRead: boolean;
}

export interface IShiftTimeRequest {
  createdAt: string;
  departmentId: string;
  name: string;
  id: string;
  reason: string;
  startDate: string;
  endDate: string;
  requesterName: string;
  reviewerName: string;
  requesterUserId: string;
  reviewerUserId: string;
  reviewerNote?: string;
  status: RequestStatus;
  updatedAt: string;
  hasRead: boolean;
  shift: IShift
}

export interface IShiftTimeIntervals {
  startDate: string,
  endDate: string,
  timeType: string,
  id: string,
}
