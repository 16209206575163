import { Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { exhaustMap, map, catchError } from 'rxjs/operators';

import ActionType from './types';
import { userService } from '../../services';
import {
  GetUserFulfilledAction,
  GetUserRejectedAction,
  UpdateUserAction,
  UpdateUserFulfilledAction,
  UpdateUserRejectedAction,
  GetUserPeriodsFulfilledAction,
  GetUserPeriodsRejectedAction,
  GetIcsUrlFulfilledAction,
  GetIcsUrlRejectedAction,
  GetNotificationPreferencesFulfilledAction,
  GetNotificationPreferencesRejectedAction,
  UpdateNotificationPreferencesAction,
  UpdateNotificationPreferencesFulfilledAction,
  UpdateNotificationPreferencesRejectedAction,
  UpdateAvatarAction,
  UpdateAvatarFulfilledAction,
  UpdateAvatarRejectedAction,
  DeleteAvatarFulfilledAction,
  DeleteAvatarRejectedAction,
  ChangePasswordAction,
  ChangePasswordFulfilledAction,
  ChangePasswordRejectedAction,
  GetUserAction,
  DeleteAvatarAction
} from './actions';

export const getUserEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetUser).pipe(
    exhaustMap(({ payload }: GetUserAction) =>
      from(userService.getUser()).pipe(
        map(data => {
          payload?.onSuccess(data);
          return new GetUserFulfilledAction({ data });
        }),
        catchError(error => {
          payload?.onError?.(error);
          return of(new GetUserRejectedAction({ error }));
        })
      )
    ),
  );

export const updateUserEpic$: Epic = action$ =>
  action$.ofType(ActionType.UpdateUser).pipe(
    exhaustMap(({ payload }: UpdateUserAction) =>
      from(userService.updateUser(payload.user)).pipe(
        map(() => new UpdateUserFulfilledAction()),
        catchError(error => {
          payload.onError?.(error);
          return of(new UpdateUserRejectedAction({ error }));
        })
      )
    ),
  );

export const updateUserFulFilledEpic$: Epic = action$ =>
  action$.ofType(ActionType.UpdateUserFulfilled).pipe(
    map(() => new GetUserAction()),
  );

export const getUserPeriodsEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetUserPeriods).pipe(
    exhaustMap(() =>
      from(userService.getPeriods()).pipe(
        map(data => new GetUserPeriodsFulfilledAction({ data })),
        catchError(error => of(new GetUserPeriodsRejectedAction({ error })))
      )
    ),
  );

export const getIcsUrlEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetIcsUrl).pipe(
    exhaustMap(() =>
      from(userService.getIcsUrl()).pipe(
        map(({ icsUrl }) => new GetIcsUrlFulfilledAction({ url: icsUrl })),
        catchError(error => of(new GetIcsUrlRejectedAction({ error })))
      )
    ),
  );

export const getNotificationPreferencesEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetNotificationPreferences).pipe(
    exhaustMap(() =>
      from(userService.getNotificationPreferences()).pipe(
        map(data => new GetNotificationPreferencesFulfilledAction({ data })),
        catchError(error => of(new GetNotificationPreferencesRejectedAction({ error })))
      )
    ),
  );

export const updateNotificationPreferencesEpic$: Epic = action$ =>
  action$.ofType(ActionType.UpdateNotificationPreferences).pipe(
    exhaustMap(({ payload }: UpdateNotificationPreferencesAction) =>
      from(userService.updateNotificationPreferences(payload.preferences)).pipe(
        map(({ preferences }) => new UpdateNotificationPreferencesFulfilledAction({ data: preferences })),
        catchError(error => {
          payload.onError?.(error);
          return of(new UpdateNotificationPreferencesRejectedAction({ error }));
        })
      )
    ),
  );

export const updateAvatarEpic$: Epic = action$ =>
  action$.ofType(ActionType.UpdateAvatar).pipe(
    exhaustMap(({ payload }: UpdateAvatarAction) =>
      from(userService.updateAvatar(payload.file)).pipe(
        map(({ profilePictureUrl }) => new UpdateAvatarFulfilledAction({ url: profilePictureUrl })),
        catchError(error => of(new UpdateAvatarRejectedAction({ error })))
      )
    ),
  );

export const deleteAvatarEpic$: Epic = action$ =>
  action$.ofType(ActionType.DeleteAvatar).pipe(
    exhaustMap(({ payload }: DeleteAvatarAction) =>
      from(userService.deleteAvatar()).pipe(
        map(() => {
          payload?.onSuccess();
          return new DeleteAvatarFulfilledAction();
        }),
        catchError(error => of(new DeleteAvatarRejectedAction({ error })))
      )
    ),
  );

export const changePasswordEpic$: Epic = action$ =>
  action$.ofType(ActionType.ChangePassword).pipe(
    exhaustMap(({ payload }: ChangePasswordAction) =>
      from(userService.updateUser({ currentPassword: payload.currentPassword, newPassword: payload.newPassword })).pipe(
        map(() => {
          payload.onSuccess?.();
          return new ChangePasswordFulfilledAction();
        }),
        catchError(error => {
          payload.onError?.(error);
          return of(new ChangePasswordRejectedAction({ error }));
        })
      )
    ),
  );

const epics = [
  changePasswordEpic$,
  getUserEpic$,
  updateUserEpic$,
  updateUserFulFilledEpic$,
  getUserPeriodsEpic$,
  getIcsUrlEpic$,
  getNotificationPreferencesEpic$,
  updateNotificationPreferencesEpic$,
  updateAvatarEpic$,
  deleteAvatarEpic$,
];

export default epics;
