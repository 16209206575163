import React, { useEffect, useState } from 'react';
import { Checkbox, IDateTime, InputDateField, InputDateType, InputField, InputType, Modal } from 'react-ess-components';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { format, isAfter, isSameDay, parseISO, setHours, setMinutes, setSeconds } from 'date-fns';

import { IReducedShift } from '../../../../models';
import { adjustedShiftTimeActions, adjustedShiftTimeSelectors, configSelectors } from '../../../../redux';
import { date, translations } from '../../../../utils';

import './adjustedShiftTimeModal.scss';

const DEFAULT_START_TIME = '09:00';
const DEFAULT_END_TIME = '17:00';

const CreateAdjustedShiftTimeModal = () => {
    const dispatch = useDispatch();
    const showAdjustedShiftTimeModal = useSelector(adjustedShiftTimeSelectors.showAdjustedShiftTimeModal);
    const shift = useSelector(adjustedShiftTimeSelectors.getShift);
    const isLoading = useSelector(adjustedShiftTimeSelectors.isCreateRequestLoading);
    const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);

    const reducedShift: IReducedShift = {
        department: shift?.department,
        endDate: shift?.endDate,
        offset: shift?.offset,
        startDate: shift?.startDate,
        id: shift?.id,
        functionName: shift?.functionName,
        shiftName: shift?.shiftName,
        displayName: shift?.displayName,
        intervals: shift?.intervals,
    };

    const [startValue, setStartValue] = useState<IDateTime>({ date: new Date(), time: DEFAULT_START_TIME });
    const [endValue, setEndValue] = useState<IDateTime>({ date: new Date(), time: DEFAULT_END_TIME });
    const [reason, setReason] = useState('');
    const [valueMultipleDayShift, setMultipleDayShiftValue] = useState(!isSameDay(parseISO(shift?.startDate), parseISO(shift?.endDate)));

    useEffect(() => {
        if (shift) {
            const start = parseISO(shift?.startDate);
            const end = parseISO(shift?.endDate);
            setMultipleDayShiftValue(!isSameDay(start, end));
            setStartValue({
                date: start,
                time: format(start, 'HH:mm'),
            });
            setEndValue({
                date: end,
                time: format(end, 'HH:mm'),
            });
        }
    }, [shift]);

    const closeModal = () => {
        dispatch(new adjustedShiftTimeActions.ToggleAdjustedShiftTimeModalAction());
        setReason('');
        setMultipleDayShiftValue(false);
    };

    const toggleCheckbox = () => {
        setMultipleDayShiftValue(!valueMultipleDayShift);
    };

    const createShiftTimeAdjustmentRequest = () => {
        const [startHours, startMinutes] = startValue.time.split(':');
        const [endHours, endMinutes] = endValue.time.split(':');
        const startDate = setSeconds(setMinutes(setHours(startValue.date, parseInt(startHours)), parseInt(startMinutes)), 0);
        const endDate = setSeconds(setMinutes(setHours(endValue.date, parseInt(endHours)), parseInt(endMinutes)), 0);

        dispatch(new adjustedShiftTimeActions.CreateAdjustShiftTimeRequestAction({
            shift: reducedShift,
            startDate: date.format(startDate, 'yyyy-MM-dd\'T\'HH:mm:ss'),
            endDate: date.format(endDate, 'yyyy-MM-dd\'T\'HH:mm:ss'),
            reason: reason,
            onSuccess: () => {
                toast(translations.getLabel('lblAdjustShiftTimeRequestCreated'));
                closeModal();
            },
            onError: (error) => {
                toast.error(typeof error.detail === 'string' ? error.detail : error.title);
            },
        }));
    };

    const validate = () => {
        const [startHours, startMinutes] = startValue.time.split(':');
        const [endHours, endMinutes] = endValue.time.split(':');
        const startDate = setSeconds(setMinutes(setHours(startValue.date, parseInt(startHours)), parseInt(startMinutes)), 0);
        const endDate = setSeconds(setMinutes(setHours(endValue.date, parseInt(endHours)), parseInt(endMinutes)), 0);
        return isAfter(endDate, startDate);
    };

    return (
        <Modal
            title={translations.getLabel('titleShiftTimeAdjustment')}
            open={showAdjustedShiftTimeModal}
            requestClose={closeModal}
            leftButtonProps={{
                label: translations.getLabel('cancel'),
                onClick: closeModal,
            }}
            rightButtonProps={{
                label: translations.getLabel('btnRequest'),
                onClick: createShiftTimeAdjustmentRequest,
                disabled: (!validate()),
                isLoading,
            }}
        >
            <div className="request-adjusted-shift-time">
                <div>
                    <p className="shift-name">{shift?.shiftName}</p>
                </div>
                <div className="checkbox">
                    <Checkbox id="partOfDay" isChecked={valueMultipleDayShift} onCheck={toggleCheckbox} label={translations.getLabel('lblOverlappingShift')} />
                </div>
                <div>
                    <div className="inputDateField">
                        <InputDateField
                            icon="CalendarIcon"
                            label={translations.getLabel('lblFrom')}
                            onChange={setStartValue}
                            weekStartsOn={weekStartsOn}
                            type={valueMultipleDayShift ? InputDateType.DateTime : InputDateType.Time}
                            value={startValue}
                        />
                        <InputDateField
                            icon="CalendarIcon"
                            label={translations.getLabel('lblUntil')}
                            onChange={setEndValue}
                            weekStartsOn={weekStartsOn}
                            type={valueMultipleDayShift ? InputDateType.DateTime : InputDateType.Time}
                            value={endValue}
                        />
                    </div>
                    <div>
                        {shift?.intervals && <div className="row">
                            <div className="title">{translations.getLabel('lblTasks')}</div>
                            <div className="content">{shift?.intervals.map(i =>
                                <div key={i.id}>
                                    <p className="content-item">{date.formatFromUntilTime(date.format, { startDate: parseISO(i.startDate), endDate: parseISO(i.endDate) })}&nbsp;{i.timeType}</p>
                                </div>
                            )}</div>
                        </div>}
                        {shift?.department && <div className="row">
                            <div className="title">{translations.getLabel('lblDepartment')}</div>
                            <div className="content">{shift?.department}</div>
                        </div>}
                        {shift?.functionName && <div className="row">
                            <div className="title">{translations.getLabel('lblFunction')}</div>
                            <div className="content">{shift?.functionName}</div>
                        </div>}
                    </div>
                    <InputField
                        type={InputType.Text}
                        value={reason}
                        onChange={setReason}
                        className="large-padding"
                        icon="ChatIcon"
                        label={translations.getLabel('lblRemarks')}
                    />

                </div>
            </div >
        </Modal >
    );
};

export default CreateAdjustedShiftTimeModal;
