import React from 'react';
import { Button, Icon, IconButton } from 'react-ess-components';
import { useSelector } from 'react-redux';

import { AppState } from '../../../../../shared/redux/rootReducer';
import { SelectDepartment } from '../../../components';
import { useToggle } from '../../../hooks';
import { UserRight } from '../../../models';
import { departmentsSelectors } from '../../../redux';
import { translations } from '../../../utils';

interface Props {
  departments: string[];
  setDepartments: (departments: string[]) => void;
}

const NewsDepartments = ({ departments, setDepartments }: Props) => {
  const availableDepartments = useSelector((state: AppState) => departmentsSelectors.getDepartmentsForRight(state, UserRight.News));
  const [isDepartmentSelectModalVisible, toggleIsDepartmentSelectModalVisible] = useToggle(false);

  const renderSelectedDepartments = () =>
    <div className="departments-container">
      {departments.map(renderDepartment)}
    </div>;

  const renderDepartment = department => {
    const label = (availableDepartments.find(item => item.value === department) || {}).label;
    return (
      <div className="department-item-container" key={department}>
        <p className="department">{label}</p>
        <IconButton tag="CrossIcon" color="error" small onClick={removeDepartment(department)} title={translations.getLabel('lblClose')} />
      </div>
    );
  };

  const onDepartmentSelect = (departments: string[]) => {
    setDepartments(departments);
    toggleIsDepartmentSelectModalVisible();
  };

  const removeDepartment = department => () => setDepartments(departments.filter(item => item !== department));

  return (
    <>
      <div className="department-select-container">
        <div className="header-container">
          <h4>{`${translations.getLabel('lblPublishFor')}:`}</h4>
          {renderSelectedDepartments()}
          <Button onClick={toggleIsDepartmentSelectModalVisible} theme="transparent">
            <Icon tag="PlusSquareIcon" /> {translations.getLabel('btnAddDepartment')}
          </Button>
        </div>
        <p className="department-select-label">{translations.getLabel('lblLeaveEmptyNewsItem')}</p>

      </div>
      <div className="select-department-modal">
        <SelectDepartment selected={departments} isOpen={isDepartmentSelectModalVisible} requestClose={toggleIsDepartmentSelectModalVisible} onSave={onDepartmentSelect} />
      </div>
    </>
  );
};

export default NewsDepartments;
