import { combineEpics } from 'redux-observable';

import adjustedShiftTimeEpics from './adjustedShiftTimes/epics';
import availibilitiesEpics from './availabilities/epics';
import chatEpics from './chat/epics';
import configEpics from './config/epics';
import countersEpics from './counters/epics';
import departmentEpics from './departments/epics';
import documentEpics from './documents/epics';
import employeesEpics from './employees/epics';
import forgotPasswordEpics from './forgotPassword/epics';
import loginEpics from './login/epics';
import newsEpics from './news/epics';
import notificationsEpics from './notifications/epics';
import requestsEpics from './requests/epics';
import scheduleEpics from './schedule/epics';
import shiftOfferEpics from './shiftOffer/epics';
import shiftSwapEpics from './shiftSwap/epics';
import timeRegistrationEpics from './timeRegistration/epics';
import transactionEpics from './transactions/epics';
import userEpics from './user/epics';

const rootEpic = combineEpics(
  ...adjustedShiftTimeEpics,
  ...availibilitiesEpics,
  ...chatEpics,
  ...configEpics,
  ...countersEpics,
  ...departmentEpics,
  ...documentEpics,
  ...employeesEpics,
  ...forgotPasswordEpics,
  ...loginEpics,
  ...newsEpics,
  ...notificationsEpics,
  ...requestsEpics,
  ...scheduleEpics,
  ...shiftOfferEpics,
  ...shiftSwapEpics,
  ...timeRegistrationEpics,
  ...transactionEpics,
  ...userEpics,
);

export default rootEpic;
