export enum ActionType {
  GetDocuments = 'GET_DOCUMENTS',
  GetDocumentsRejected = 'GET_DOCUMENTS_REJECTED',
  GetDocumentsFulfilled = 'GET_DOCUMENTS_FULFILLED',

  DeleteDocument = 'DELETE_DOCUMENT',
  DeleteDocumentRejected = 'DELETE_DOCUMENT_REJECTED',
  DeleteDocumentFulfilled = 'DELETE_DOCUMENT_FULFILLED',

  UpdateDocument = 'UPDATE_DOCUMENT',
  UpdateDocumentRejected = 'UPDATE_DOCUMENT_REJECTED',
  UpdateDocumentFulfilled = 'UPDATE_DOCUMENT_FULFILLED',

  UploadDocument = 'UPLOAD_DOCUMENT',
  UploadDocumentRejected = 'UPLOAD_DOCUMENT_REJECTED',
  UploadDocumentFulfilled = 'UPLOAD_DOCUMENT_FULFILLED',
};

export default ActionType;