import React, { FC, useEffect, useState } from 'react';
import { Button, Icon, Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { parseISO } from 'date-fns';

import { AppState } from '../../../../../../shared/redux/rootReducer';
import { SchedulePageSwitch } from '../../../../components';
import { identifiers } from '../../../../constants';
import { useToggle } from '../../../../hooks';
import { RequestStatus, TimeScale } from '../../../../models';
import { configSelectors, requestsActions, requestsSelectors, userSelectors } from '../../../../redux';
import { date, requestsUtils, scheduleUtils, translations } from '../../../../utils';
import DayWeekSchedule from '../../../schedule/DayWeekSchedule';
import Footer from './RequestFooter';

interface Props {
  id: string;
  isLoading: boolean;
  status: RequestStatus;
  departmentId?: string;
}

const RequestDetail: FC<Props> = ({ id, isLoading, departmentId }) => {
  const dispatch = useDispatch();
  const [isRosterOpen, toggleRosterOpen] = useToggle(false);
  const [selectedDate, setSelectedDate] = useState<Date>(null);

  const request = useSelector((state: AppState) => requestsSelectors.getDepartmentShiftSwap(state, id));
  const weekStartsOn = useSelector(configSelectors.getStartDayOfWeek);
  const user = useSelector(userSelectors.getUser);

  useEffect(() => {
    if (request) setSelectedDate(parseISO(request.date));
  }, [request]);

  useEffect(() => {
    if (id) {
      dispatch(new requestsActions.GetDepartmentShiftSwapAction({
        id,
        departmentId,
        onError: (error) => {
          toast.error(error.detail);
        },
      }));
    }
  }, [id]);

  if (!request) return <section className="request-detail empty">{translations.getLabel('lblEmpyRequestInbox')}</section>;

  const startDate = parseISO(request.date);
  const counterStartDate = parseISO(request.selectedCounterSwapDate || request.date);
  const relativeCreatedDate = requestsUtils.getRelativeDate(request.createdAt, date.format);

  return (
    <div className="request-detail department-request-detail">
      <div>
        <p>{isLoading ? <Skeleton width={300} /> : (relativeCreatedDate.shouldTranslate ? `${translations.getLabel(relativeCreatedDate.value)} ${relativeCreatedDate.time}` : `${relativeCreatedDate.value}`)}</p>
        <p className="title">{isLoading ? <Skeleton width={400} /> : translations.getLabel('titleShiftSwapRequest')}</p>
        <div className="row">
          <div className="item shift">
            <p className="row-title">{isLoading ? <Skeleton width={200} /> : translations.getLabel('lblDayOf')} {request?.requesterName}</p>
            <div className="request-date-information">
              <Icon tag="CalendarIcon" color="primaryDark" />
              <p>{isLoading ? <Skeleton width={100} /> : (`${date.format(startDate, 'eee dd LLL')}`)}</p>
              <Icon tag="ClockIcon" color="primaryDark" />
              <p>{isLoading ? <Skeleton width={100} /> : (`${translations.getLabel('lblAllDay')}`)}</p>
            </div>
          </div>
          <p className="item">{translations.getLabel('lblWith')}</p>
          <div className="item shift">
            <p className="row-title">{isLoading ? <Skeleton width={200} /> : translations.getLabel('lblDayOf')} {request?.applicantName}</p>
            {request.hasCounterSwap ? <div className="request-date-information">
              <Icon tag="CalendarIcon" color="primaryDark" />
              <p>{isLoading ? <Skeleton width={100} /> : (`${date.format(counterStartDate, 'eee dd LLL')}`)}</p>
              <Icon tag="ClockIcon" color="primaryDark" />
              <p>{isLoading ? <Skeleton width={100} /> : (`${translations.getLabel('lblAllDay')}`)}</p>
            </div> :
              <div className="request-date-information">
                <p className="no-icon">{translations.getLabel('lblWithoutCounterSwap')}</p>
              </div>
            }
          </div>
        </div>
      </div>
      {
        !isLoading && <div className="request-schedule-action">
          <Button onClick={toggleRosterOpen}><Icon tag="CalendarIcon" color="white" /> {translations.getLabel('btnWatchInRoster')}</Button>
        </div>
      }
      <div className="hr" />
      {request &&
        <div className="footer">
          <Footer id={id} departmentId={departmentId} />
        </div>
      }
      {
        (isRosterOpen && request) && <Modal open title="-" requestClose={toggleRosterOpen}>
          <div className="schedule-toolbar">
            <SchedulePageSwitch
              dateRange={TimeScale.Week}
              selectedDate={selectedDate}
              changeDate={(modifier: number) => setSelectedDate(scheduleUtils.getModifiedDate(TimeScale.Week, selectedDate, modifier, weekStartsOn))}
              setSpecificDate={(date: Date) => setSelectedDate(date)}
            />
          </div>
          <DayWeekSchedule
            departmentId={user.departmentId === departmentId ? undefined : departmentId}
            mainUserId={request.requesterEmployeeId === user.employeeId ? identifiers.me : request.requesterEmployeeId}
            selectedDate={selectedDate}
            hideShiftOffers
            showAvailabilities={false}
            timeScale={TimeScale.Week}
            highlightDates={[startDate.toISOString()]}
            secondaryUserId={request.applicantEmployeeId === user.employeeId ? identifiers.me : request.applicantEmployeeId}
            secondaryHighlightDates={[counterStartDate.toISOString()]} />
        </Modal>
      }
    </div >
  );
};

export default RequestDetail;
