import { HttpClient } from '../http';
import {
  IDateStringRange,
  IManagerVacationRequestDTO,
  IRequestCounter,
  IRequestCounts,
  IRequestDepartment,
  IShiftOfferRequest,
  IShiftOfferRequestResponse,
  IShiftOfferResponse,
  IShiftSwapRequest,
  IShiftSwapRequestResponse,
  IShiftTimeRequest,
  IShiftTimeRequestResponse,
  ITransactionRequest,
  ITransactionRequestResponse,
  IVacationRequest,
  IVacationRequestCounterParams,
  IVacationRequestDTO,
  IVacationRequestResponse,
  IVacationType,
  RequestStatus,
} from '../models';

export const getVacationRequests = async (offset = 0, limit = 10): Promise<IVacationRequestResponse> => {
  return await HttpClient.get<IVacationRequestResponse>('/v1/inbox/vacation-requests', { limit, offset });
};

export const getVacationRequestCounters = async (params: IVacationRequestCounterParams): Promise<IRequestCounter[]> => {
  return await HttpClient.get<IRequestCounter[]>('/v1/vacation/counters', params);
};

export const getTransactionRequests = async (offset = 0, limit = 10): Promise<ITransactionRequestResponse> => {
  return await HttpClient.get<ITransactionRequestResponse>('/v1/inbox/transaction-requests', { limit, offset });
};

export const getDepartmentVacationRequests = async (departmentId: string, status?: RequestStatus, offset = 0, limit = 10): Promise<IVacationRequestResponse> => {
  const params: { status?: RequestStatus, limit: number, offset: number } = { limit, offset };
  if (status) params.status = status;

  return await HttpClient.get<IVacationRequestResponse>(`/v1/management/departments/${departmentId}/vacation-requests`, params);
};

export const getDepartmentVacationRequest = async (id: string, departmentId: string): Promise<IVacationRequest> => {
  const result = await HttpClient.get<{ data: IVacationRequest }>(`/v1/management/departments/${departmentId}/vacation-requests/${id}`);
  return result.data;
};

export const getDepartmentShiftOffers = async (departmentId: string, status?: RequestStatus, offset = 0, limit = 10): Promise<IShiftOfferRequestResponse> => {
  const params: { status?: RequestStatus, limit: number, offset: number } = { limit, offset };
  if (status) params.status = status;
  return await HttpClient.get<IShiftOfferRequestResponse>(`/v1/management/departments/${departmentId}/shift-offers`, params);
};

export const getDepartmentShiftOffer = async (id: string, departmentId: string): Promise<IShiftOfferRequest> => {
  const result = await HttpClient.get<{ data: IShiftOfferRequest }>(`/v1/management/departments/${departmentId}/shift-offers/${id}`);
  return result.data;
};

export const getDepartmentShiftSwaps = async (departmentId: string, status?: RequestStatus, offset = 0, limit = 10): Promise<IShiftSwapRequestResponse> => {
  const params: { status?: RequestStatus, limit: number, offset: number } = { limit, offset };
  if (status) params.status = status;
  return await HttpClient.get<IShiftSwapRequestResponse>(`/v1/management/departments/${departmentId}/shift-swaps`, params);
};

export const getDepartmentShiftSwap = async (id: string, departmentId: string): Promise<IShiftSwapRequest> => {
  const result = await HttpClient.get<{ data: IShiftSwapRequest }>(`/v1/management/departments/${departmentId}/shift-swaps/${id}`);
  return result.data;
};

interface RawVacationType {
  description: string;
  externalID: string;
  holdingVacationTypeId: string;
  order: number;
  type: VacationType;
};

export const createVacationRequest = async (data: IVacationRequestDTO): Promise<void> => {
  return await HttpClient.post('/v1/vacation', data);
};

export const deleteVacationRequest = async (id: string): Promise<IDateStringRange> => {
  const result = await HttpClient.delete<{ data: IVacationRequest }>(`/v1/vacation/${id}`);
  return { startDate: result.data?.startDate, endDate: result.data?.endDate };
};

export const deleteTransactionRequest = async (id: string): Promise<void> => {
  const result = await HttpClient.put<{ data: ITransactionRequest }>(`/v1/inbox/transaction-requests/${id}/cancel`);
};

enum VacationType {
  interval = 'INTERVAL',
  shift = 'SHIFT',
}

export const getVacationRequest = async (id: string): Promise<IVacationRequest> => {
  const result = await HttpClient.get<{ data: IVacationRequest }>(`/v1/inbox/vacation-requests/${id}`);
  return result.data;
};

export const getTransactionRequest = async (id: string): Promise<ITransactionRequest> => {
  const result = await HttpClient.get<{ data: ITransactionRequest }>(`/v1/inbox/transaction-requests/${id}`);
  return result.data;
};

export const getRequestDepartments = async (status: RequestStatus): Promise<IRequestDepartment[]> => {
  const params: { status?: RequestStatus } = {};
  if (status) params.status = status;
  const result = await HttpClient.get<{ data: IRequestDepartment[] }>('/v1/management/departments/vacation-requests-count', params);
  return result.data;
};

export const getShiftOfferDepartments = async (status: RequestStatus): Promise<IRequestDepartment[]> => {
  const params: { status?: RequestStatus } = {};
  if (status) params.status = status;
  const result = await HttpClient.get<{ data: IRequestDepartment[] }>('/v1/management/departments/shift-offers-count', params);
  return result.data;
};

export const fillInShiftOffer = async (departmentId: string, shiftOfferId: string, employeeIds: string[], closeShiftOffer?: boolean): Promise<IShiftOfferResponse> => {
  const result = await HttpClient.put<{ data: IShiftOfferResponse }>(`/v1/management/departments/${departmentId}/shift-offers/${shiftOfferId}`, { employeeIds, closeShiftOffer });
  return result?.data;
};

export const cancelShiftOffer = async (departmentId: string, shiftOfferId: string): Promise<void> => {
  const result = await HttpClient.put<void>(`/v1/management/departments/${departmentId}/shift-offers/${shiftOfferId}/cancel`);
  return result;
};

export const getShiftSwapDepartments = async (status: RequestStatus): Promise<IRequestDepartment[]> => {
  const params: { status?: RequestStatus } = {};
  if (status) params.status = status;
  const result = await HttpClient.get<{ data: IRequestDepartment[] }>('/v1/management/departments/shift-swaps-count', params);
  return result.data;
};

export const approveShiftSwap = async (departmentId: string, shiftSwapId: string, note: string): Promise<void> => {
  const result = await HttpClient.patch<void>(`/v1/management/departments/${departmentId}/shift-swaps/${shiftSwapId}`, { status: RequestStatus.Accepted, note });
  return result;
};

export const declineShiftSwap = async (departmentId: string, shiftSwapId: string, note: string): Promise<void> => {
  const result = await HttpClient.patch<void>(`/v1/management/departments/${departmentId}/shift-swaps/${shiftSwapId}`, { status: RequestStatus.Denied, note });
  return result;
};

export const getVacationTypes = async (): Promise<IVacationType> => {
  const [intervalTypes, shiftTypes] = await Promise.all([
    HttpClient.get<{ data: RawVacationType[] }>('/v1/vacation/vacation-types/INTERVAL'),
    HttpClient.get<{ data: RawVacationType[] }>('/v1/vacation/vacation-types/SHIFT')
  ]);
  const formatTypes = (types: RawVacationType[]) => types.map(data => ({ value: data.holdingVacationTypeId, label: data.description }));
  return { interval: formatTypes(intervalTypes.data), shift: formatTypes(shiftTypes.data) };
};

export const readVacationRequest = async (id: string): Promise<void> => {
  return HttpClient.post(`/v1/inbox/vacation-requests/${id}/read`);
};

export const readTransactionRequest = async (id: string): Promise<void> => {
  return HttpClient.post(`/v1/inbox/transaction-requests/${id}/read`);
};

export const getRequestCounts = async (): Promise<IRequestCounts> => {
  const result = await HttpClient.get<{ data: IRequestCounts }>('/v1/management/departments/info');
  return result.data;
};

export const updateVacationRequest = async (id: string, departmentId: string, request: IManagerVacationRequestDTO): Promise<void> => {
  return await HttpClient.patch(`/v1/management/departments/${departmentId}/vacation-requests/${id}`, request);
};

export const getTransactionDepartments = async (status: RequestStatus): Promise<IRequestDepartment[]> => {
  const params: { status?: RequestStatus } = {};
  if (status) params.status = status;
  const result = await HttpClient.get<{ data: IRequestDepartment[] }>('/v1/management/departments/transactions-count', params);
  return result.data;
};

export const getShiftTimeDepartments = async (status: RequestStatus): Promise<IRequestDepartment[]> => {
  const params: { status?: RequestStatus } = {};
  if (status) params.status = status;
  const result = await HttpClient.get<{ data: IRequestDepartment[] }>('/v1/management/departments/shift-time-adjustments-count', params);
  return result.data;
};

export const getDepartmentTransactions = async (departmentId: string, status?: RequestStatus, offset = 0, limit = 10): Promise<ITransactionRequestResponse> => {
  const params: { status?: RequestStatus, limit: number, offset: number } = { limit, offset };
  if (status) params.status = status;
  return await HttpClient.get<ITransactionRequestResponse>(`/v1/management/departments/${departmentId}/transactions`, params);
};

export const getDepartmentTransaction = async (id: string, departmentId: string): Promise<ITransactionRequest> => {
  const result = await HttpClient.get<{ data: ITransactionRequest }>(`/v1/management/departments/${departmentId}/transactions/${id}`);
  return result.data;
};

export const getDepartmentShiftTimes = async (departmentId: string, status?: RequestStatus, offset = 0, limit = 10): Promise<IShiftTimeRequestResponse> => {
  const params: { status?: RequestStatus, limit: number, offset: number } = { limit, offset };
  if (status) params.status = status;
  return await HttpClient.get<IShiftTimeRequestResponse>(`/v1/management/departments/${departmentId}/shift-time-adjustments`, params);
};

export const getDepartmentShiftTime = async (id: string, departmentId: string): Promise<IShiftTimeRequest> => {
  const result = await HttpClient.get<{ data: IShiftTimeRequest }>(`/v1/management/departments/${departmentId}/shift-time-adjustments/${id}`);
  return result.data;
};

export const approveTransaction = async (departmentId: string, transactionId: string, reviewerNote: string): Promise<void> => {
  const result = await HttpClient.patch<void>(`/v1/management/departments/${departmentId}/transactions/${transactionId}`, { status: RequestStatus.Accepted, reviewerNote });
  return result;
};

export const declineTransaction = async (departmentId: string, transactionId: string, reviewerNote: string): Promise<void> => {
  const result = await HttpClient.patch<void>(`/v1/management/departments/${departmentId}/transactions/${transactionId}`, { status: RequestStatus.Denied, reviewerNote });
  return result;
};

export const approveShiftTime = async (departmentId: string, shiftTimeId: string, reviewerNote: string): Promise<void> => {
  const result = await HttpClient.patch<void>(`/v1/management/departments/${departmentId}/shift-time-adjustments/${shiftTimeId}`, { status: RequestStatus.Accepted, reviewerNote });
  return result;
};

export const declineShiftTime = async (departmentId: string, shiftTimeId: string, reviewerNote: string): Promise<void> => {
  const result = await HttpClient.patch<void>(`/v1/management/departments/${departmentId}/shift-time-adjustments/${shiftTimeId}`, { status: RequestStatus.Denied, reviewerNote });
  return result;
};
