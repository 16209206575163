import React, { FC, useState } from 'react';
import { Button, InputField, InputType,Modal } from 'react-ess-components';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { SPX_IS_DOWN } from '../../../../../../shared/http/HttpUtils';
import { useToggle } from '../../../../hooks';
import { IVacationRequest, RequestStatus } from '../../../../models';
import { requestsActions } from '../../../../redux';
import { translations } from '../../../../utils';

interface Props {
  request: IVacationRequest;
  departmentId?: string;
}

const ApproveDenyModal: FC<Props> = ({ request, departmentId }) => {
  const dispatch = useDispatch();
  const [isConfirmModalOpen, toggleConfirmModal] = useToggle(false);
  const [isDenyAction, setIsDenyAction] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reviewerNote, setReviewerNote] = useState<string>('');

  const toggleModal = (isDeny = false) => {
    setIsDenyAction(isDeny);
    toggleConfirmModal();
    setReviewerNote('');
  };

  const handleSubmit = () => {
    setIsLoading(true);
    dispatch(new requestsActions.UpdateVacationRequestAction({
      id: request.id,
      data: {
        reviewerNote,
        status: isDenyAction ? RequestStatus.Denied : RequestStatus.Accepted,
      },
      departmentId,
      onSuccess: () => {
        setIsLoading(false);
        toggleModal();
      },
      onError: (error) => {
        setIsLoading(false);
        toggleModal();
        if (error.code === SPX_IS_DOWN) {
          toast.error(translations.getLabel('lblErrSPXDownVacationRequest'));
        } else {
          toast.error(error.detail);
        }
      },
    }));
  };

  return (
    <>
      <Button onClick={() => toggleModal(true)} theme="inverse">{translations.getLabel('btnDeny')}</Button>
      <Button onClick={() => toggleModal()}>{translations.getLabel('btnApprove')}</Button>

      <Modal
        open={isConfirmModalOpen}
        requestClose={toggleModal}
        title={translations.getLabel(isDenyAction ? 'titleDenyRequest' : 'titleApproveRequest')}
        rightButtonProps={{ label: translations.getLabel(isDenyAction ? 'btnDeny' : 'btnApprove'), onClick: handleSubmit, isLoading: isLoading }}
        leftButtonProps={{ label: translations.getLabel('cancel'), onClick: () => toggleModal(true) }}
      >
        <div className="approve-deny-container">
          <InputField
            type={InputType.Textarea}
            value={reviewerNote}
            onChange={setReviewerNote}
            className="large-padding"
            icon="ChatIcon"
            label={translations.getLabel('lblRemarks')}
          />
        </div>
      </Modal>
    </>
  );
};

export default ApproveDenyModal;
