import { Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { exhaustMap, switchMap, map, catchError } from 'rxjs/operators';

import ActionType from './types';
import { requestsService } from '../../services';
import { IServerError } from '../../http/HttpError';
import {
  CreateVacationRequestAction,
  CreateVacationRequestFulfilledAction,
  CreateVacationRequestRejectedAction,
  DeleteVacationRequestAction,
  DeleteVacationRequestFulfilledAction,
  DeleteVacationRequestRejectedAction,
  GetVacationRequestAction,
  GetVacationRequestFulfilledAction,
  GetVacationRequestRejectedAction,
  GetVacationRequestsFulfilledAction,
  GetVacationRequestsRejectedAction,
  GetDepartmentVacationRequestsAction,
  GetDepartmentVacationRequestsFulfilledAction,
  GetDepartmentVacationRequestsRejectedAction,
  GetDepartmentShiftOffersAction,
  GetDepartmentShiftOffersFulfilledAction,
  GetDepartmentShiftOffersRejectedAction,
  GetVacationTypesFulfilledAction,
  GetVacationTypesRejectedAction,
  OnReadRequestAction,
  OnReadRequestFulfilledAction,
  OnReadRequestRejectedAction,
  GetRequestDepartmentsAction,
  GetRequestDepartmentsFulFilledAction,
  GetRequestDepartmentsRejectedAction,
  GetRequestCountsRejectedAction,
  GetRequestCountsFulFilledAction,
  UpdateVacationRequestAction,
  UpdateVacationRequestFulfilledAction,
  UpdateVacationRequestRejectedAction,
  GetVacationRequestsAction,
  GetRequestCountsAction,
  GetShiftOfferDepartmentsAction,
  GetShiftOfferDepartmentsFulFilledAction,
  GetShiftOfferDepartmentsRejectedAction,
  GetDepartmentShiftOfferAction,
  GetDepartmentShiftOfferFulfilledAction,
  GetDepartmentShiftOfferRejectedAction,
  FillInShiftOfferAction,
  FillInShiftOfferFulFilledAction,
  FillInShiftOfferRejectedAction,
  CancelShiftOfferAction,
  CancelShiftOfferFulFilledAction,
  CancelShiftOfferRejectedAction,
  GetShiftSwapDepartmentsAction,
  GetShiftSwapDepartmentsFulFilledAction,
  GetShiftSwapDepartmentsRejectedAction,
  GetDepartmentShiftSwapsAction,
  GetDepartmentShiftSwapsFulfilledAction,
  GetDepartmentShiftSwapsRejectedAction,
  ApproveShiftSwapFulFilledAction,
  ApproveShiftSwapRejectedAction,
  ApproveShiftSwapAction,
  DeclineShiftSwapAction,
  DeclineShiftSwapFulFilledAction,
  DeclineShiftSwapRejectedAction,
  GetDepartmentShiftSwapAction,
  GetDepartmentShiftSwapFulfilledAction,
  GetDepartmentShiftSwapRejectedAction,
  GetDepartmentTransactionsFulfilledAction,
  GetDepartmentTransactionsRejectedAction,
  GetTransactionDepartmentsAction,
  GetTransactionDepartmentsFulFilledAction,
  GetTransactionDepartmentsRejectedAction,
  GetDepartmentTransactionAction,
  GetDepartmentTransactionFulfilledAction,
  GetDepartmentTransactionRejectedAction,
  GetDepartmentTransactionsAction,
  ApproveTransactionAction,
  ApproveTransactionFulFilledAction,
  ApproveTransactionRejectedAction,
  DeclineTransactionAction,
  DeclineTransactionRejectedAction,
  DeclineTransactionFulFilledAction,
  GetTransactionRequestAction,
  GetTransactionRequestFulfilledAction,
  GetTransactionRequestRejectedAction,
  GetTransactionRequestsAction,
  GetTransactionRequestsFulfilledAction,
  GetTransactionRequestsRejectedAction,
  DeleteTransactionRequestAction,
  DeleteTransactionRequestFulfilledAction,
  DeleteTransactionRequestRejectedAction,
  GetDepartmentShiftTimesAction,
  GetDepartmentShiftTimesFulfilledAction,
  GetDepartmentShiftTimesRejectedAction,
  GetShiftTimeDepartmentsAction,
  GetShiftTimeDepartmentsFulFilledAction,
  GetShiftTimeDepartmentsRejectedAction,
  GetDepartmentShiftTimeAction,
  GetDepartmentShiftTimeFulfilledAction,
  GetDepartmentShiftTimeRejectedAction,
  ApproveShiftTimeAction,
  ApproveShiftTimeFulFilledAction,
  ApproveShiftTimeRejectedAction,
  DeclineShiftTimeAction,
  DeclineShiftTimeFulFilledAction,
  DeclineShiftTimeRejectedAction,
  OnReadTransactionRequestFulfilledAction,
  OnReadTransactionRequestAction,
  GetDepartmentVacationRequestAction,
  GetDepartmentVacationRequestFulfilledAction,
  GetDepartmentVacationRequestRejectedAction,
  GetVacationRequestCountersAction,
  GetVacationRequestCountersFulfilledAction,
  GetVacationRequestCountersRejectedAction,
} from './actions';
import { GetPlanningAction, GetUserPlanningExternalVacationAction } from '../schedule/actions';
import * as date from '../../utils/date';  // Direct import to avoid circular dependency 
import * as requestsSelectors from '../requests/selectors';
import { RequestType } from '../../models';

export const getVacationRequestEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetVacationRequest).pipe(
    exhaustMap(({ payload }: GetVacationRequestAction) =>
      from(requestsService.getVacationRequest(payload.id)).pipe(
        map(data => new GetVacationRequestFulfilledAction({ data })),
        catchError(error => {
          payload.onError?.(error);
          return of(new GetVacationRequestRejectedAction({ error }));
        })
      )
    ),
  );

export const getVacationRequestCounterEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetVacationRequestCounters).pipe(
    switchMap(({ payload }: GetVacationRequestCountersAction) =>
      from(requestsService.getVacationRequestCounters(payload.params)).pipe(
        map(data => new GetVacationRequestCountersFulfilledAction({ data })),
        catchError(error => {
          payload.onError?.(error);
          return of(new GetVacationRequestCountersRejectedAction({ error }));
        })
      )
    ),
  );

export const getTransactionRequestEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetTransactionRequest).pipe(
    exhaustMap(({ payload }: GetTransactionRequestAction) =>
      from(requestsService.getTransactionRequest(payload.id)).pipe(
        map(data => new GetTransactionRequestFulfilledAction({ data })),
        catchError(error => {
          payload.onError?.(error);
          return of(new GetTransactionRequestRejectedAction({ error }));
        })
      )
    ),
  );

export const getVacationRequestsEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetVacationRequests).pipe(
    exhaustMap(({ payload }: GetVacationRequestsAction) =>
      from(requestsService.getVacationRequests(payload.offset)).pipe(
        map(response => {
          payload.onSuccess?.(response);
          return new GetVacationRequestsFulfilledAction({ ...response, isInitialLoad: payload.offset === 0 });
        }),
        catchError(error => of(new GetVacationRequestsRejectedAction({ error })))
      )
    ),
  );

export const getTransactionRequestsEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetTransactionRequests).pipe(
    exhaustMap(({ payload }: GetTransactionRequestsAction) =>
      from(requestsService.getTransactionRequests(payload.offset)).pipe(
        map(response => {
          payload.onSuccess?.(response);
          return new GetTransactionRequestsFulfilledAction({ ...response, isInitialLoad: payload.offset === 0 });
        }),
        catchError(error => of(new GetTransactionRequestsRejectedAction({ error })))
      )
    ),
  );

export const getDepartmentVacationRequestsEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetDepartmentVacationRequests).pipe(
    exhaustMap(({ payload }: GetDepartmentVacationRequestsAction) =>
      from(requestsService.getDepartmentVacationRequests(payload.departmentId, payload.status, payload.offset)).pipe(
        map(response => {
          payload.onSuccess?.(response);
          return new GetDepartmentVacationRequestsFulfilledAction({ ...response, isInitialLoad: payload.offset === 0 });
        }),
        catchError(error => of(new GetDepartmentVacationRequestsRejectedAction({ error })))
      )
    ),
  );

export const getDepartmentVacationRequestEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetDepartmentVacationRequest).pipe(
    switchMap(({ payload }: GetDepartmentVacationRequestAction) =>
      from(requestsService.getDepartmentVacationRequest(payload.id, payload.departmentId)).pipe(
        map(response => {
          return new GetDepartmentVacationRequestFulfilledAction(response);
        }),
        catchError(error => of(new GetDepartmentVacationRequestRejectedAction({ error })))
      )
    ),
  );

export const getDepartmentShiftOffersEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetDepartmentShiftOffers).pipe(
    exhaustMap(({ payload }: GetDepartmentShiftOffersAction) =>
      from(requestsService.getDepartmentShiftOffers(payload.departmentId, payload.status, payload.offset)).pipe(
        map(response => {
          payload.onSuccess?.(response);
          return new GetDepartmentShiftOffersFulfilledAction({ ...response, isInitialLoad: payload.offset === 0 });
        }),
        catchError(error => of(new GetDepartmentShiftOffersRejectedAction({ error, isInitialLoad: payload.offset === 0 })))
      )
    ),
  );

export const getDepartmentShiftOfferEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetDepartmentShiftOffer).pipe(
    switchMap(({ payload }: GetDepartmentShiftOfferAction) =>
      from(requestsService.getDepartmentShiftOffer(payload.id, payload.departmentId)).pipe(
        map(response => {
          return new GetDepartmentShiftOfferFulfilledAction({ data: response });
        }),
        catchError((error: IServerError) => {
          payload.onError?.(error);
          return of(new GetDepartmentShiftOfferRejectedAction({ error }));
        })

      )
    ),
  );

export const getDepartmentShiftSwapsEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetDepartmentShiftSwaps).pipe(
    exhaustMap(({ payload }: GetDepartmentShiftSwapsAction) =>
      from(requestsService.getDepartmentShiftSwaps(payload.departmentId, payload.status, payload.offset)).pipe(
        map(response => {
          payload.onSuccess?.(response);
          return new GetDepartmentShiftSwapsFulfilledAction({ ...response, isInitialLoad: payload.offset === 0 });
        }),
        catchError(error => of(new GetDepartmentShiftSwapsRejectedAction({ error, isInitialLoad: payload.offset === 0 })))
      )
    ),
  );

export const getDepartmentShiftSwapEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetDepartmentShiftSwap).pipe(
    switchMap(({ payload }: GetDepartmentShiftSwapAction) =>
      from(requestsService.getDepartmentShiftSwap(payload.id, payload.departmentId)).pipe(
        map(response => {
          return new GetDepartmentShiftSwapFulfilledAction({ data: response });
        }),
        catchError((error: IServerError) => {
          payload.onError?.(error);
          return of(new GetDepartmentShiftSwapRejectedAction({ error }));
        })

      )
    ),
  );

export const createVacationRequestEpic$: Epic = action$ =>
  action$.ofType(ActionType.CreateVacationRequest).pipe(
    exhaustMap(({ payload }: CreateVacationRequestAction) =>
      from(requestsService.createVacationRequest({ ...payload.request, startDate: date.removeTimezone(payload.request.startDate), endDate: date.removeTimezone(payload.request.endDate) })).pipe(
        map(() => {
          payload.onSuccess?.();
          return new CreateVacationRequestFulfilledAction({ startDate: date.removeTimezone(payload.request.startDate), endDate: date.removeTimezone(payload.request.endDate) });
        }
        ),
        catchError((error: IServerError) => {
          payload.onError?.(error);
          return of(new CreateVacationRequestRejectedAction({ error }));
        })
      )
    ),
  );

export const createVacationRequestFulfilledEpic$: Epic = action$ =>
  action$.ofType(ActionType.CreateVacationRequestFulfilled).pipe(
    exhaustMap(({ payload }: CreateVacationRequestFulfilledAction) => {
      return of(new GetPlanningAction(payload), new GetUserPlanningExternalVacationAction(payload));
    }
    )
  );

export const deleteVacationRequestEpic$: Epic = action$ =>
  action$.ofType(ActionType.DeleteVacationRequest).pipe(
    exhaustMap(({ payload }: DeleteVacationRequestAction) =>
      from(requestsService.deleteVacationRequest(payload.id)).pipe(
        map((range) => {
          payload.onSuccess?.();
          return new DeleteVacationRequestFulfilledAction({ isFromInbox: payload.isFromInbox, startDate: range.startDate, endDate: range.endDate, id: payload.id });
        }),
        catchError((error: IServerError) => {
          payload.onError?.(error);
          return of(new DeleteVacationRequestRejectedAction({ error }));
        })
      )
    ),
  );

export const deleteVacationRequestFulfilledEpic$: Epic = action$ =>
  action$.ofType(ActionType.DeleteVacationRequestFulfilled).pipe(
    exhaustMap(({ payload }: DeleteVacationRequestFulfilledAction) => {
      if (payload.isFromInbox) {
        return of(
          new GetRequestCountsAction()
        );
      }
      return of(new GetPlanningAction(payload));
    })
  );

export const deleteTransactionRequestEpic$: Epic = action$ =>
  action$.ofType(ActionType.DeleteTransactionRequest).pipe(
    exhaustMap(({ payload }: DeleteTransactionRequestAction) =>
      from(requestsService.deleteTransactionRequest(payload.id)).pipe(
        map((range) => {
          payload.onSuccess?.();
          return new DeleteTransactionRequestFulfilledAction({ isFromInbox: payload.isFromInbox, id: payload.id });
        }),
        catchError((error: IServerError) => {
          payload.onError?.(error);
          return of(new DeleteTransactionRequestRejectedAction({ error }));
        })
      )
    ),
  );

export const deleteTransactionRequestFulfilledEpic$: Epic = action$ =>
  action$.ofType(ActionType.DeleteTransactionRequestFulfilled).pipe(
    exhaustMap(({ payload }: DeleteTransactionRequestFulfilledAction) => {
      if (payload.isFromInbox) {
        return of(
          new GetRequestCountsAction()
        );
      }
    })
  );

export const getVacationTypesEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetVacationTypes).pipe(
    exhaustMap(() =>
      from(requestsService.getVacationTypes()).pipe(
        map(data => new GetVacationTypesFulfilledAction({ data })),
        catchError(error => of(new GetVacationTypesRejectedAction({ error })))
      )
    ),
  );

export const onReadRequestEpic$: Epic = action$ =>
  action$.ofType(ActionType.ReadVacationRequest).pipe(
    exhaustMap(({ payload }: OnReadRequestAction) =>
      from(requestsService.readVacationRequest(payload.id)).pipe(
        map(() => new OnReadRequestFulfilledAction({ id: payload.id, isDepartmentRequest: payload.isDepartmentRequest })),
        catchError((error) => of(new OnReadRequestRejectedAction({ error })))
      )
    )
  );

export const onReadTransactionRequestEpic$: Epic = action$ =>
  action$.ofType(ActionType.ReadTransactionRequest).pipe(
    exhaustMap(({ payload }: OnReadTransactionRequestAction) =>
      from(requestsService.readTransactionRequest(payload.id)).pipe(
        map(() => new OnReadTransactionRequestFulfilledAction({ id: payload.id, isDepartmentRequest: payload.isDepartmentRequest })),
        catchError((error) => of(new GetTransactionRequestsRejectedAction({ error })))
      )
    )
  );

export const getRequestDepartments$: Epic = action$ =>
  action$.ofType(ActionType.GetRequestDepartments).pipe(
    exhaustMap(({ payload }: GetRequestDepartmentsAction) =>
      from(requestsService.getRequestDepartments(payload.status)).pipe(
        map(data => {
          payload.onSuccess?.(data);
          return new GetRequestDepartmentsFulFilledAction({ data });
        }),
        catchError(error => of(new GetRequestDepartmentsRejectedAction({ error })))
      )
    )
  );

export const getShiftOfferDepartments$: Epic = action$ =>
  action$.ofType(ActionType.GetShiftOfferDepartments).pipe(
    exhaustMap(({ payload }: GetShiftOfferDepartmentsAction) =>
      from(requestsService.getShiftOfferDepartments(payload.status)).pipe(
        map(data => {
          payload.onSuccess?.(data);
          return new GetShiftOfferDepartmentsFulFilledAction({ data });
        }),
        catchError(error => of(new GetShiftOfferDepartmentsRejectedAction({ error })))
      )
    )
  );

export const getShiftSwapDepartments$: Epic = action$ =>
  action$.ofType(ActionType.GetShiftSwapDepartments).pipe(
    exhaustMap(({ payload }: GetShiftSwapDepartmentsAction) =>
      from(requestsService.getShiftSwapDepartments(payload.status)).pipe(
        map(data => {
          payload.onSuccess?.(data);
          return new GetShiftSwapDepartmentsFulFilledAction({ data });
        }),
        catchError(error => of(new GetShiftSwapDepartmentsRejectedAction({ error })))
      )
    )
  );

export const getRequestCounts$: Epic = action$ =>
  action$.ofType(ActionType.GetRequestCounts).pipe(
    exhaustMap(() =>
      from(requestsService.getRequestCounts()).pipe(
        map(data => new GetRequestCountsFulFilledAction({ data })),
        catchError(error => of(new GetRequestCountsRejectedAction({ error })))
      )
    )
  );

export const updateVacationRequestEpic$: Epic = action$ =>
  action$.ofType(ActionType.UpdateVacationRequest).pipe(
    exhaustMap(({ payload }: UpdateVacationRequestAction) =>
      from(requestsService.updateVacationRequest(payload.id, payload.departmentId, payload.data)).pipe(
        map(() => {
          payload.onSuccess?.();
          return new UpdateVacationRequestFulfilledAction({ departmentId: payload.departmentId });
        }),
        catchError((error: IServerError) => {
          payload.onError?.(error);
          return of(new UpdateVacationRequestRejectedAction({ error }));
        })
      )
    ),
  );

export const updateVacationRequestFulfilledEpic$: Epic = (action$, state$) =>
  action$.ofType(ActionType.UpdateVacationRequestFulfilled).pipe(
    exhaustMap(({ payload }) => {
      const status = requestsSelectors.getRequestFilter(state$.value, RequestType.Vacation);
      return of(
        new GetDepartmentVacationRequestsAction({ departmentId: payload.departmentId, offset: 0, status }),
        new GetRequestCountsAction(),
        new GetRequestDepartmentsAction({ status }),
      );
    })
  );

export const fillInShiftOfferEpic$: Epic = action$ =>
  action$.ofType(ActionType.FillInShiftOffer).pipe(
    exhaustMap(({ payload }: FillInShiftOfferAction) =>
      from(requestsService.fillInShiftOffer(payload.departmentId, payload.shiftOfferId, payload.employeeIds, payload.closeShiftOffer)).pipe(
        map((data) => {
          payload?.onSuccess?.(data);
          return new FillInShiftOfferFulFilledAction({ status: payload.status, departmentId: payload.departmentId, shiftOfferId: payload.shiftOfferId, ruleViolated: data.ruleViolated });
        }),
        catchError((error: IServerError) => {
          payload.onError?.(error);
          return of(new FillInShiftOfferRejectedAction({ error }));
        })
      )
    ),
  );

export const fillInShiftOfferFulfilledEpic$: Epic = (action$) =>
  action$.ofType(ActionType.FillInShiftOfferFulfilled).pipe(
    exhaustMap(({ payload }) => {
      return of(
        new GetDepartmentShiftOfferAction({ id: payload.shiftOfferId, departmentId: payload.departmentId }),
        new GetDepartmentShiftOffersAction({ departmentId: payload.departmentId, status: payload.status, offset: 0 }),
        new GetRequestCountsAction(),
        new GetShiftOfferDepartmentsAction({ status: payload.status }),
      );
    })
  );

export const cancelShiftOfferEpic$: Epic = action$ =>
  action$.ofType(ActionType.CancelShiftOffer).pipe(
    exhaustMap(({ payload }: CancelShiftOfferAction) =>
      from(requestsService.cancelShiftOffer(payload.departmentId, payload.shiftOfferId)).pipe(
        map(() => {
          payload?.onSuccess?.();
          return new CancelShiftOfferFulFilledAction({ status: payload.status, departmentId: payload.departmentId, shiftOfferId: payload.shiftOfferId });
        }),
        catchError((error: IServerError) => {
          payload.onError?.(error);
          return of(new CancelShiftOfferRejectedAction({ error }));
        })
      )
    ),
  );

export const cancelShiftOfferFulfilledEpic$: Epic = (action$) =>
  action$.ofType(ActionType.CancelShiftOfferFulfilled).pipe(
    exhaustMap(({ payload }) => {
      return of(
        new GetDepartmentShiftOfferAction({ id: payload.shiftOfferId, departmentId: payload.departmentId }),
        new GetDepartmentShiftOffersAction({ departmentId: payload.departmentId, status: payload.status, offset: 0 }),
        new GetRequestCountsAction(),
        new GetShiftOfferDepartmentsAction({ status: payload.status }),
      );
    })
  );

export const approveShiftSwapEpic$: Epic = action$ =>
  action$.ofType(ActionType.ApproveShiftSwap).pipe(
    exhaustMap(({ payload }: ApproveShiftSwapAction) =>
      from(requestsService.approveShiftSwap(payload.departmentId, payload.shiftSwapId, payload.note)).pipe(
        map(() => {
          payload?.onSuccess?.();
          return new ApproveShiftSwapFulFilledAction({ departmentId: payload.departmentId, shiftSwapId: payload.shiftSwapId });
        }),
        catchError((error: IServerError) => {
          payload.onError?.(error);
          return of(new ApproveShiftSwapRejectedAction({ error }));
        })
      )
    ),
  );

export const approveShiftSwapFulfilledEpic$: Epic = (action$, state$) =>
  action$.ofType(ActionType.ApproveShiftSwapFulfilled).pipe(
    exhaustMap(({ payload }: ApproveShiftSwapFulFilledAction) => {
      const status = requestsSelectors.getRequestFilter(state$.value, RequestType.ShiftSwap);
      return of(
        new GetDepartmentShiftSwapAction({ departmentId: payload.departmentId, id: payload.shiftSwapId }),
        new GetDepartmentShiftSwapsAction({ departmentId: payload.departmentId, offset: 0, status }),
        new GetRequestCountsAction(),
        new GetShiftSwapDepartmentsAction({ status }),
      );
    })
  );

export const declineShiftSwapEpic$: Epic = action$ =>
  action$.ofType(ActionType.DeclineShiftSwap).pipe(
    exhaustMap(({ payload }: DeclineShiftSwapAction) =>
      from(requestsService.declineShiftSwap(payload.departmentId, payload.shiftSwapId, payload.note)).pipe(
        map(() => {
          payload?.onSuccess?.();
          return new DeclineShiftSwapFulFilledAction({ departmentId: payload.departmentId, shiftSwapId: payload.shiftSwapId });
        }),
        catchError((error: IServerError) => {
          payload.onError?.(error);
          return of(new DeclineShiftSwapRejectedAction({ error }));
        })
      )
    ),
  );

export const declineShiftSwapFulfilledEpic$: Epic = (action$, state$) =>
  action$.ofType(ActionType.DeclineShiftSwapFulfilled).pipe(
    exhaustMap(({ payload }: DeclineShiftSwapFulFilledAction) => {
      const status = requestsSelectors.getRequestFilter(state$.value, RequestType.ShiftSwap);
      return of(
        new GetDepartmentShiftSwapAction({ departmentId: payload.departmentId, id: payload.shiftSwapId }),
        new GetDepartmentShiftSwapsAction({ departmentId: payload.departmentId, status, offset: 0 }),
        new GetRequestCountsAction(),
        new GetShiftSwapDepartmentsAction({ status }),
      );
    })
  );

export const getDepartmentTransactionsEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetDepartmentTransactions).pipe(
    exhaustMap(({ payload }: GetDepartmentTransactionsAction) =>
      from(requestsService.getDepartmentTransactions(payload.departmentId, payload.status, payload.offset)).pipe(
        map(response => {
          payload.onSuccess?.(response);
          return new GetDepartmentTransactionsFulfilledAction({ ...response, isInitialLoad: payload.offset === 0 });
        }),
        catchError(error => of(new GetDepartmentTransactionsRejectedAction({ error, isInitialLoad: payload.offset === 0 })))
      )
    ),
  );


export const getTransactionDepartments$: Epic = action$ =>
  action$.ofType(ActionType.GetTransactionDepartments).pipe(
    exhaustMap(({ payload }: GetTransactionDepartmentsAction) =>
      from(requestsService.getTransactionDepartments(payload.status)).pipe(
        map(data => {
          payload.onSuccess?.(data);
          return new GetTransactionDepartmentsFulFilledAction({ data });
        }),
        catchError(error => of(new GetTransactionDepartmentsRejectedAction({ error })))
      )
    )
  );

export const getDepartmentTransactionEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetDepartmentTransaction).pipe(
    switchMap(({ payload }: GetDepartmentTransactionAction) =>
      from(requestsService.getDepartmentTransaction(payload.id, payload.departmentId)).pipe(
        map(response => {
          return new GetDepartmentTransactionFulfilledAction({ data: response });
        }),
        catchError((error: IServerError) => {
          payload.onError?.(error);
          return of(new GetDepartmentTransactionRejectedAction({ error }));
        })

      )
    ),
  );

export const approveTransactionEpic$: Epic = action$ =>
  action$.ofType(ActionType.ApproveTransaction).pipe(
    exhaustMap(({ payload }: ApproveTransactionAction) =>
      from(requestsService.approveTransaction(payload.departmentId, payload.transactionId, payload.reviewerNote)).pipe(
        map(() => {
          payload?.onSuccess?.();
          return new ApproveTransactionFulFilledAction({ departmentId: payload.departmentId, transactionId: payload.transactionId });
        }),
        catchError((error: IServerError) => {
          payload.onError?.(error);
          return of(new ApproveTransactionRejectedAction({ error }));
        })
      )
    ),
  );

export const approveTransactionFulfilledEpic$: Epic = (action$, state$) =>
  action$.ofType(ActionType.ApproveTransactionFulfilled).pipe(
    exhaustMap(({ payload }: ApproveTransactionFulFilledAction) => {
      const status = requestsSelectors.getRequestFilter(state$.value, RequestType.Transaction);
      return of(
        new GetDepartmentTransactionAction({ departmentId: payload.departmentId, id: payload.transactionId }),
        new GetDepartmentTransactionsAction({ departmentId: payload.departmentId, offset: 0, status }),
        new GetRequestCountsAction(),
        new GetTransactionDepartmentsAction({ status }),
      );
    })
  );

export const declineTransactionEpic$: Epic = action$ =>
  action$.ofType(ActionType.DeclineTransaction).pipe(
    exhaustMap(({ payload }: DeclineTransactionAction) =>
      from(requestsService.declineTransaction(payload.departmentId, payload.transactionId, payload.reviewerNote)).pipe(
        map(() => {
          payload?.onSuccess?.();
          return new DeclineTransactionFulFilledAction({ departmentId: payload.departmentId, transactionId: payload.transactionId });
        }),
        catchError((error: IServerError) => {
          payload.onError?.(error);
          return of(new DeclineTransactionRejectedAction({ error }));
        })
      )
    ),
  );

export const declineTransactionFulfilledEpic$: Epic = (action$, state$) =>
  action$.ofType(ActionType.DeclineTransactionFulfilled).pipe(
    exhaustMap(({ payload }: DeclineTransactionFulFilledAction) => {
      const status = requestsSelectors.getRequestFilter(state$.value, RequestType.Transaction);
      return of(
        new GetDepartmentTransactionAction({ departmentId: payload.departmentId, id: payload.transactionId }),
        new GetDepartmentTransactionsAction({ departmentId: payload.departmentId, status, offset: 0 }),
        new GetRequestCountsAction(),
        new GetTransactionDepartmentsAction({ status }),
      );
    })
  );

export const getDepartmentShiftTimesEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetDepartmentShiftTimes).pipe(
    exhaustMap(({ payload }: GetDepartmentShiftTimesAction) =>
      from(requestsService.getDepartmentShiftTimes(payload.departmentId, payload.status, payload.offset)).pipe(
        map(response => {
          payload.onSuccess?.(response);
          return new GetDepartmentShiftTimesFulfilledAction({ ...response, isInitialLoad: payload.offset === 0 });
        }),
        catchError(error => of(new GetDepartmentShiftTimesRejectedAction({ error, isInitialLoad: payload.offset === 0 })))
      )
    ),
  );


export const getShiftTimeDepartments$: Epic = action$ =>
  action$.ofType(ActionType.GetShiftTimeDepartments).pipe(
    exhaustMap(({ payload }: GetShiftTimeDepartmentsAction) =>
      from(requestsService.getShiftTimeDepartments(payload.status)).pipe(
        map(data => {
          payload.onSuccess?.(data);
          return new GetShiftTimeDepartmentsFulFilledAction({ data });
        }),
        catchError(error => of(new GetShiftTimeDepartmentsRejectedAction({ error })))
      )
    )
  );

export const getDepartmentShiftTimeEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetDepartmentShiftTime).pipe(
    switchMap(({ payload }: GetDepartmentShiftTimeAction) =>
      from(requestsService.getDepartmentShiftTime(payload.id, payload.departmentId)).pipe(
        map(response => {
          return new GetDepartmentShiftTimeFulfilledAction({ data: response });
        }),
        catchError((error: IServerError) => {
          payload.onError(error);
          return of(new GetDepartmentShiftTimeRejectedAction({ error }));
        })

      )
    ),
  );

export const approveShiftTimeEpic$: Epic = action$ =>
  action$.ofType(ActionType.ApproveShiftTime).pipe(
    exhaustMap(({ payload }: ApproveShiftTimeAction) =>
      from(requestsService.approveShiftTime(payload.departmentId, payload.shiftTimeId, payload.reviewerNote)).pipe(
        map(() => {
          payload?.onSuccess?.();
          return new ApproveShiftTimeFulFilledAction({ departmentId: payload.departmentId, shiftTimeId: payload.shiftTimeId });
        }),
        catchError((error: IServerError) => {
          payload.onError?.(error);
          return of(new ApproveShiftTimeRejectedAction({ error }));
        })
      )
    ),
  );

export const approveShiftTimeFulfilledEpic$: Epic = (action$, state$) =>
  action$.ofType(ActionType.ApproveShiftTimeFulfilled).pipe(
    exhaustMap(({ payload }: ApproveShiftTimeFulFilledAction) => {
      const status = requestsSelectors.getRequestFilter(state$.value, RequestType.ShiftTime);
      return of(
        new GetDepartmentShiftTimeAction({ departmentId: payload.departmentId, id: payload.shiftTimeId }),
        new GetDepartmentShiftTimesAction({ departmentId: payload.departmentId, offset: 0, status }),
        new GetRequestCountsAction(),
        new GetShiftTimeDepartmentsAction({ status }),
      );
    })
  );

export const declineShiftTimeEpic$: Epic = action$ =>
  action$.ofType(ActionType.DeclineShiftTime).pipe(
    exhaustMap(({ payload }: DeclineShiftTimeAction) =>
      from(requestsService.declineShiftTime(payload.departmentId, payload.shiftTimeId, payload.reviewerNote)).pipe(
        map(() => {
          payload?.onSuccess?.();
          return new DeclineShiftTimeFulFilledAction({ departmentId: payload.departmentId, shiftTimeId: payload.shiftTimeId });
        }),
        catchError((error: IServerError) => {
          payload.onError?.(error);
          return of(new DeclineShiftTimeRejectedAction({ error }));
        })
      )
    ),
  );

export const declineShiftTimeFulfilledEpic$: Epic = (action$, state$) =>
  action$.ofType(ActionType.DeclineShiftTimeFulfilled).pipe(
    exhaustMap(({ payload }: DeclineShiftTimeFulFilledAction) => {
      const status = requestsSelectors.getRequestFilter(state$.value, RequestType.ShiftTime);
      return of(
        new GetDepartmentShiftTimeAction({ departmentId: payload.departmentId, id: payload.shiftTimeId }),
        new GetDepartmentShiftTimesAction({ departmentId: payload.departmentId, status, offset: 0 }),
        new GetRequestCountsAction(),
        new GetShiftTimeDepartmentsAction({ status }),
      );
    })
  );

const epics = [
  createVacationRequestEpic$,
  createVacationRequestFulfilledEpic$,
  deleteVacationRequestEpic$,
  deleteVacationRequestFulfilledEpic$,
  getDepartmentVacationRequestsEpic$,
  getDepartmentShiftOffersEpic$,
  getDepartmentShiftOfferEpic$,
  getDepartmentShiftSwapsEpic$,
  getDepartmentShiftSwapEpic$,
  getRequestCounts$,
  getRequestDepartments$,
  getShiftOfferDepartments$,
  getShiftSwapDepartments$,
  getVacationRequestEpic$,
  getTransactionRequestEpic$,
  getTransactionRequestsEpic$,
  getVacationRequestsEpic$,
  getVacationTypesEpic$,
  onReadRequestEpic$,
  updateVacationRequestEpic$,
  updateVacationRequestFulfilledEpic$,
  fillInShiftOfferEpic$,
  fillInShiftOfferFulfilledEpic$,
  cancelShiftOfferEpic$,
  cancelShiftOfferFulfilledEpic$,
  approveShiftSwapEpic$,
  approveShiftSwapFulfilledEpic$,
  declineShiftSwapEpic$,
  declineShiftSwapFulfilledEpic$,
  getDepartmentTransactionsEpic$,
  getTransactionDepartments$,
  getDepartmentTransactionEpic$,
  approveTransactionEpic$,
  approveTransactionFulfilledEpic$,
  declineTransactionEpic$,
  declineTransactionFulfilledEpic$,
  deleteTransactionRequestEpic$,
  deleteTransactionRequestFulfilledEpic$,
  getDepartmentShiftTimesEpic$,
  getShiftTimeDepartments$,
  getDepartmentShiftTimeEpic$,
  approveShiftTimeEpic$,
  approveShiftTimeFulfilledEpic$,
  declineShiftTimeEpic$,
  declineShiftTimeFulfilledEpic$,
  onReadTransactionRequestEpic$,
  getDepartmentVacationRequestEpic$,
  getVacationRequestCounterEpic$,
];

export default epics;
