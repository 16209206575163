import { produce } from 'immer';

import ActionType from './types';

import { IEmployee, IPeriod, ILabelAndValue, UserRight, IDepartment } from '../../models';
import { Actions } from './actions';
import { IServerError } from '../../http/HttpError';

export type DepartmentPerRight = Record<UserRight, ILabelAndValue[]>;

export interface DepartmentState {
  employees: IEmployee[],
  isEmployeesLoading: boolean,
  isLoading: boolean,
  isPeriodLoading: boolean,
  periods: IPeriod,
  hasServerError: boolean,
  departmentsPerRight?: DepartmentPerRight,
  companyDepartments?: ILabelAndValue[],
  companyDepartmentsError?: IServerError,
  isAbsolutePeriodLoading: boolean,
  absolutePeriod?: { startDate?: string, endDate?: string }
}

export const initialState: DepartmentState = {
  employees: [],
  isEmployeesLoading: false,
  isLoading: false,
  isPeriodLoading: false,
  periods: {},
  departmentsPerRight: <DepartmentPerRight>{},
  hasServerError: false,
  isAbsolutePeriodLoading: false,
  absolutePeriod: null,
};

const departments = produce((draft: DepartmentState, action: Actions) => {
  switch (action.type) {
    case ActionType.UpdateDepartmentPeriods:
      draft.isPeriodLoading = true;
      draft.hasServerError = false;
      break;

    case ActionType.UpdateDepartmentPeriodsFulfilled:
      draft.isPeriodLoading = false;
      break;

    case ActionType.UpdateDepartmentPeriodsRejected:
      draft.isPeriodLoading = false;
      draft.hasServerError = true;
      break;

    case ActionType.GetDepartments:
      draft.isLoading = true;
      draft.hasServerError = false;
      break;

    case ActionType.GetDepartmentsFulfilled:
      draft.isLoading = false;
      draft.departmentsPerRight[action.payload.type] = action.payload.data;
      break;

    case ActionType.GetDepartmentsRejected:
      draft.isLoading = false;
      break;

    case ActionType.GetCompanyDepartments:
      draft.companyDepartmentsError = null;
      break;

    case ActionType.GetCompanyDepartmentsFulfilled:
      draft.isLoading = false;
      draft.companyDepartments = action.payload.data;
      break;

    case ActionType.GetCompanyDepartmentsRejected:
      draft.companyDepartmentsError = action.payload.error;
      break;

    case ActionType.GetDepartmentEmployees:
      draft.isEmployeesLoading = true;
      draft.hasServerError = false;
      break;

    case ActionType.GetDepartmentEmployeesFulfilled:
      draft.isEmployeesLoading = false;
      draft.employees = action.payload.data;
      break;

    case ActionType.GetDepartmentEmployeesRejected:
      draft.isEmployeesLoading = false;
      break;

    case ActionType.GetDepartmentPeriods:
      draft.isPeriodLoading = true;
      break;

    case ActionType.GetDepartmentPeriodsFulfilled:
      draft.isPeriodLoading = false;
      action.payload.data.map(period => {
        if (period[0]) {
          draft.periods[period[0].type] = {
            daysBefore: period[0].daysBefore,
            endDate: period[0].endDate,
            startDate: period[0].startDate,
            type: period[0].type,
          };
        }
      });
      break;

    case ActionType.GetDepartmentPeriodsRejected:
      draft.isPeriodLoading = false;
      draft.hasServerError = true;
      break;

    case ActionType.GetAbsolutePeriod:
      draft.isAbsolutePeriodLoading = true;
      break;

    case ActionType.GetAbsolutePeriodFulfilled:
      draft.isAbsolutePeriodLoading = false;
      draft.absolutePeriod = action.payload.data;
      break;

    case ActionType.GetAbsolutePeriodRejected:
      draft.isAbsolutePeriodLoading = false;
      draft.hasServerError = true;
      break;

    case ActionType.ResetDepartmentPeriod:
      draft.hasServerError = false;
      break;

    case ActionType.ResetDepartmentPeriodFulfilled:
      draft.periods[action.payload.data?.type] = action.payload.data;
      break;

    case ActionType.ResetDepartmentPeriodRejected:
      draft.hasServerError = true;
      break;

    // No default case is needed, since produce returns by default the draft state
  }
}, initialState);

export default departments;
