import React, { FC, useEffect } from 'react';
import { FormattedValue } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';

import { countersActions, countersSelectors, userSelectors } from '../../../redux';
import { translations } from '../../../utils';
import Widget from '../Widget';

import './CounterWidget.scss';


type Props = {
  onClickMore?: () => void;
}
const CounterWidget: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const isLoadingDashboard = useSelector(countersSelectors.isLoadingDashboard);
  const dashboardCounters = useSelector(countersSelectors.getDashboardCounters);
  const user = useSelector(userSelectors.getUser);

  useEffect(() => {
    getData();
  }, []);


  const getData = () => {
    dispatch(new countersActions.GetDashboardCountersAction());
  };

  const renderCounter = (counter) => {
    if (isLoadingDashboard) return <div className="formatted-value-container loading"><Skeleton height={30} width={70} /><Skeleton height={14} width={120} /></div>;
    if (!counter) return null;
    return <FormattedValue value={counter.value} type={counter.type} description={counter.description} />;
  };

  return (
    <Widget title={translations.getLabel('titleWidgetCounter')} buttonLabel={dashboardCounters.length ? translations.getLabel('btnSeeAllCounters') : undefined} onClickMore={props.onClickMore} right>
      <div className="counter-content">
        {!isLoadingDashboard && dashboardCounters.length === 0
          ? (
            <p className="empty-text">
              {user.isHoldingAdmin ? translations.getLabel('lblNoCountersAdmin') : translations.getLabel('lblNoCountersEmployee')}
            </p>
          )
          : (
            <>
              <div className="row-container">
                {renderCounter(dashboardCounters[0])}
                {renderCounter(dashboardCounters[1])}
              </div>
              {(isLoadingDashboard || dashboardCounters.length > 2) &&
                <div className="row-container">
                  {renderCounter(dashboardCounters[2])}
                  {renderCounter(dashboardCounters[3])}
                </div>
              }
            </>

          )}
      </div>
    </Widget>
  );
};

const noop = () => null;
CounterWidget.defaultProps = {
  onClickMore: noop,
};

export default CounterWidget;
