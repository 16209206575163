import { HttpClient } from '../http';
import { IFileUploadFunction } from '../models';
import { convertFileToArrayBuffer } from './imageUtils';


export const convertMBtoByte = (numberOfMb: number): number => 1024 * 1024 * numberOfMb;

const uploadFileToPresignedUrl: IFileUploadFunction = async (url: string, file: File, contentType?: string): Promise<void> => {
  const buffer = await convertFileToArrayBuffer(file);
  return HttpClient.put<void>(url, buffer, { 'Content-Type': contentType || file.type, 'Content-Disposition': `inline; rfilename=${file.name}` });
};

export { uploadFileToPresignedUrl };
