import React, { FC } from 'react';

import { IBaseInterval,IBaseShift } from '../../../../models';
import { translations } from '../../../../utils';
import Interval, { ChangeInterval } from './Interval';

export type ChangeShift = (index: number, shift: IBaseShift<IBaseInterval>, isRemoved?: boolean) => void;

interface Props {
  shift: IBaseShift<IBaseInterval>;
  isEditable?: boolean;
  index?: number;
  changeShift?: ChangeShift;
}

const Shift: FC<Props> = ({ isEditable, shift, index: shiftIndex, changeShift }) => {
  const changeInterval: ChangeInterval = (intervalIndex, interval, isRemoved) => {
    const intervals = [...shift.intervals];
    if (isRemoved) {
      intervals.splice(intervalIndex, 1);
    } else {
      intervals[intervalIndex] = interval;
    }

    changeShift(shiftIndex, { ...shift, intervals });
  };

  return (
    <div className="shift">
      <h5>{shift.shiftName}</h5>
      <section className="interval-container">
        <div className="description">{translations.getLabel('lblTasks')}</div>
        <div className="intervals">
          {shift.intervals?.map((interval, i: number) => (
            <Interval
              changeInterval={changeInterval}
              isEditable={isEditable}
              key={interval.id || i}
              interval={interval}
              index={i}
              isRemovable={shift.intervals.length > 1}
            />
          ))}
        </div>
      </section>
      {shift.functionName && !isEditable && <section className="function">
        <div className="description">{translations.getLabel('lblFunction')}</div>
        {shift.functionName}
      </section>}
    </div>
  );
};

export default Shift;
