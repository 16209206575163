import { useQuery } from 'react-query';

import { HttpClient } from '../../http';
import { IServerError } from '../../http/HttpError';
import { IDateStringRange, ISelfRosteringCounter } from '../../models';
import { UseQueryResult } from 'react-query/types/react/types';
import { selfRosterQueryKeys } from './queryKeys';

const getCounter = async (params: IDateStringRange): Promise<ISelfRosteringCounter> => {
  const result = await HttpClient.get<{ data: ISelfRosteringCounter }>('/v1/self-roster/counters', { ...params });
  return result.data;
};

export const useSelfRosterCounter = (params: IDateStringRange, enabled = true): UseQueryResult<ISelfRosteringCounter> => {
  return useQuery<ISelfRosteringCounter, IServerError>(selfRosterQueryKeys.counter(params), () => getCounter(params), { enabled: !!params.startDate && !!params.endDate && enabled });
};
