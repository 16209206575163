import { connect } from 'react-redux';

import { chatSelectors, configSelectors,userSelectors } from '../../../../redux';
import ChatConversation from './ChatConversation.view';

const mapStateToProps = (state, props) => ({
  conversation: chatSelectors.getConversation(state, props.id),
  userId: userSelectors.getUserId(state),
  employmentId: userSelectors.getEmploymentId(state),
  holdingId: configSelectors.getHoldingId(state),
  fileStorageId: chatSelectors.getFileStorageId(state),
  hasRight: (right) => userSelectors.hasRight(state, right),
});

export default connect(mapStateToProps)(ChatConversation);


